import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table as TableModule, Utils, Grid } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import * as formatters from '../../../../formatters';

const { Row, Col } = Grid;
const { Table, TableColumn, TableCell } = TableModule;
const { Text, Button: ButtonModule, Loader: LoaderModule } = Utils;

const { IconButton } = ButtonModule;
const { Loader } = LoaderModule;

const TC = styled(TableCell)`
  height: 80px;
  font-weight: ${(props) =>
    props.row.isNewlyPublished
      ? props.theme.fontWeightBold
      : props.theme.fontWeightBase};

  button {
    font-size: 20px;
  }
`;

const DocumentInfo = styled.div`
  font-size: 12px;

  .btn {
    margin-bottom: 0.3rem;
  }

  .info-col {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .border-right {
    border-right: 1px solid ${(props) => props.theme.palette.grayLight};
  }

  label {
    display: block;
    font-weight: ${(props) => props.theme.fontWeightBold};
    margin-bottom: 0;
  }
`;

const HistoryContainer = styled.div`
  margin-bottom: 2rem;
  background: #fff;
  padding: 4rem;
  margin: 0 -3rem;

  ${down('md')} {
    padding: 2rem;
    margin: 0 -1rem;
  }

  h3 {
    padding-left: 0.7rem;
  }

  .current-row {
    font-weight: ${(props) => props.theme.fontWeightMedium} !important;
    background-color: ${(props) => props.theme.palette.grayLightest} !important;

    &:hover,
    &:focus,
    &:active {
      background-color: ${(props) =>
        props.theme.palette.grayLightest} !important;
      font-weight: ${(props) => props.theme.fontWeightMedium} !important;
    }

    td {
      font-weight: ${(props) => props.theme.fontWeightMedium} !important;
      background-color: ${(props) =>
        props.theme.palette.grayLightest} !important;

      &:hover,
      &:focus,
      &:active {
        background-color: ${(props) =>
          props.theme.palette.grayLightest} !important;
        font-weight: ${(props) => props.theme.fontWeightMedium} !important;
      }
    }
  }
`;

const Errata = styled.div`
  font-size: 11px;
  background-color: #cad5dd;
  text-align: center;
  padding: 4px 10px;
  color: #fff;
  display: inline;
  margin-left: 10px;
  border-radius: 3px;
`;

class SignatureHistory extends Component {
  constructor(props) {
    super(props);
    this.onClickRow = this.onClickRow.bind(this);
    this.formatRow = this.formatRow.bind(this);
  }

  onClickRow(record) {
    const { currentId, history } = this.props;
    if (record.documentBlockchainAddress !== currentId)
      history.push(`/document/${record.documentBlockchainAddress}`);
  }

  formatRow(row) {
    const { currentId } = this.props;

    return row.documentBlockchainAddress === currentId ? 'current-row' : null;
  }

  downloadFormatter = (address, row) => {
    const { fileDownload, isDownloading } = this.props;

    if (isDownloading.includes(address || row.jobId)) {
      return <Loader width={36} />;
    }

    return (
      <IconButton
        icon="pdf"
        billonIcon={true}
        className="ignore-row-click"
        onClick={() => fileDownload(address, row.jobId)}
      />
    );
  };

  details = (record) => {
    if (!record.documentBlockchainAddress && !record.jobId) {
      return null;
    }

    return (
      <DocumentInfo>
        <Row margin={0}>
          <Col xl={10} lg={10} md={10} className="info-col" padding={0}>
            <label className="large">
              <FormattedMessage
                id="Blockchain address"
                defaultMessage="Blockchain address"
              />
            </label>
            {formatters.breakWordsFormatter(record.documentBlockchainAddress)}
          </Col>
        </Row>
      </DocumentInfo>
    );
  };

  render() {
    const { historyRecords } = this.props;

    if (!historyRecords.length) {
      return null;
    }

    return (
      <HistoryContainer>
        <Text as="h3" fontSize="24px">
          <FormattedMessage id="History" defaultMessage="History" />
        </Text>
        <Table
          responsive
          tableData={historyRecords}
          onRowClick={this.onClickRow}
          formatRow={this.formatRow}
          detailsComponent={this.details}
        >
          <TableColumn
            fieldName="title"
            formatter={(value, row) => (
              <>
                {value}{' '}
                {row.isErrata && (
                  <Errata>
                    <FormattedMessage id="Erratum" defaultMessage="Erratum" />
                  </Errata>
                )}
              </>
            )}
          >
            <FormattedMessage id="Title" defaultMessage="Title" />
          </TableColumn>
          <TableColumn
            fieldName="agreementStatus"
            formatter={formatters.historyStatusFormatter}
          >
            <FormattedMessage
              id="Document status"
              defaultMessage="Document status"
            />
          </TableColumn>
          <TableColumn
            fieldName="publicationDate"
            formatter={formatters.momentFormatterWithoutTime}
          >
            <FormattedMessage
              id="Date of transfer"
              defaultMessage="Date of transfer"
            />
          </TableColumn>
          <TableColumn
            cellClassName="text-center"
            fieldName="documentBlockchainAddress"
            formatter={this.downloadFormatter}
            renderCell={(row, props) => <TC row={row} {...props} />}
          >
            <FormattedMessage id="Download" defaultMessage="Download" />
          </TableColumn>
        </Table>
      </HistoryContainer>
    );
  }
}

SignatureHistory.propTypes = {
  historyRecords: PropTypes.array,
  history: PropTypes.object,
  currentId: PropTypes.string,
  fileDownload: PropTypes.func,
  isDownloading: PropTypes.array.isRequired,
};

export default withRouter(SignatureHistory);
