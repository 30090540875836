import React from 'react';
import { NOTIFICATION_TYPES } from '../../../../../constraints';
import useNotificationPreview from '../../../hooks/useNotificationPreview';
import {
  NotifyIcon,
  ChatIcon,
  ActionButton as StyledActionButton,
} from '../components/ActionIcons';
import NotificationContentModal from '../components/NotificationContentModal';
import { useIntl } from 'react-intl';
import renderHTML from 'react-render-html';

const ActionButton = ({ children, jobId, clientId, contactDetails, type }) => {
  const {
    content: notificationContent,
    isPreviewOpened,
    isPreviewLoading,
    showContent,
    closeContent,
  } = useNotificationPreview();

  const onActionClick = () => {
    showContent(jobId, clientId, contactDetails);
  };

  const { formatMessage } = useIntl();

  const title = () => {
    if (type === NOTIFICATION_TYPES.PHONE) {
      return formatMessage({ id: 'SMS notification' });
    } else {
      return formatMessage({ id: 'Email notification' });
    }
  };
  const modalTitle = title();

  const NotificationContent = () => {
    let contentToRender = notificationContent;
    if (type === NOTIFICATION_TYPES.EMAIL) {
      (contentToRender.attachments || []).forEach((attachment) => {
        contentToRender.content = contentToRender.content.replace(
          `cid:${attachment.name}`,
          `data:image/jpeg;charset=utf-8;base64,${attachment.source}`,
        );
      });
    }
    return renderHTML(contentToRender?.content);
  };

  return (
    <StyledActionButton onClick={onActionClick}>
      {children}
      <NotificationContentModal
        isOpen={isPreviewOpened}
        toggle={closeContent}
        isLoading={isPreviewLoading}
        onClose={closeContent}
        title={modalTitle}
      >
        <NotificationContent />
      </NotificationContentModal>
    </StyledActionButton>
  );
};

export const contentFormatter = (value, record) => {
  const StatusBasedIcon = () => {
    if (record.type === NOTIFICATION_TYPES.EMAIL) {
      return <NotifyIcon />;
    } else {
      return <ChatIcon />;
    }
  };

  return (
    <ActionButton
      jobId={record.jobId}
      clientId={record.clientId}
      contactDetails={record.contactDetails}
      type={record.type}
    >
      <StatusBasedIcon />
    </ActionButton>
  );
};
