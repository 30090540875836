import React from 'react';
import { Layout } from 'billon-ui';

import { RequestsTable } from '../../containers';

import styled from 'styled-components';

const { Content: ContentModule } = Layout;
const { ContentContainer } = ContentModule;

const ContentContainerGrey = styled(ContentContainer)`
  background: rgb(250, 250, 250);
`;

class NewRequestsHomePage extends React.Component {
  render() {
    return (
      <ContentContainerGrey fluid>
        <RequestsTable />
      </ContentContainerGrey>
    );
  }
}

export default NewRequestsHomePage;
