import apiRoutes from '../../../api-routes';
import { patchRequest } from '../../../helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';

export const usePatchPassword = (options) => {
  const token = options?.token;

  return useMutationWithLogout(async ({ passwordData }) => {
    const { data } = await patchRequest({
      url: apiRoutes.MODIFY_PASSWORD,
      data: passwordData,
      headers: {
        Authorization: token ? token : sessionStorage.getItem('token'),
      },
    });

    return data;
  }, options);
};
