import apiRoutes from 'js/api-routes';
import { getRequest } from 'js/helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';

export const useResendAuthorizationCode = (id, options) =>
  useMutationWithLogout(async () => {
    const { data } = await getRequest({
      url: apiRoutes.SINGLE_DOCUMENT_RESEND_AUTHORIZATION_CODES.replace(
        '{id}',
        id,
      ),
    });
    return data;
  }, options);
