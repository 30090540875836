import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { merge } from 'lodash';
import { Form, Grid, Utils } from 'billon-ui';
import useFilters from '../../../../hooks/useFilters';
// Components
import { IconInCircle } from '../../../../components/IconInCircle';
import { CalendarField } from '../../../../containers';
import * as Styled from './styled';
import {
  DOCUMENT_STATUS,
  MAP_DOCUMENT_STATUSES,
} from '../../../../constraints';
import { parseFilters } from '../../helpers';

const { TextField, SelectField } = Form;
const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { Col } = Grid;

const FilterForm = ({ initialValues, closeFilters, onClear }) => {
  const { navigateWithSearchQuery } = useFilters();

  const { formatMessage } = useIntl();

  const { documentStatusList, publicationDateRange } =
    initialValues.filters || {};

  const documentStatusOptions = [
    DOCUMENT_STATUS.CURRENT,
    DOCUMENT_STATUS.DEPRECATED,
    DOCUMENT_STATUS.NOT_EXIST,
    DOCUMENT_STATUS.PREPARED_TO_SIGN,
    DOCUMENT_STATUS.UPLOADING,
  ].map((status) => ({
    label: formatMessage({
      id: MAP_DOCUMENT_STATUSES[status],
    }),
    value: status,
  }));

  const formInitialValues = {
    blockchainAddress: '',
    publicationDateRange: '',
    documentStatusList: '',
    title: '',
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
  } = useForm({
    defaultValues: merge({}, formInitialValues, {
      ...initialValues.filters,
      documentStatusList:
        documentStatusList &&
        documentStatusOptions.filter((option) =>
          documentStatusList.includes(option.value),
        ),
      publicationDateRange: [
        publicationDateRange?.from,
        publicationDateRange?.to,
      ],
    }),
  });

  const onClearFilters = () => {
    onClear();
    reset(formInitialValues);
  };

  const onSubmit = (values) => {
    navigateWithSearchQuery({
      filters: parseFilters(values),
      pagination: {
        page: 1,
      },
    });
  };

  return (
    <Styled.FilterCard>
      <Styled.AbsoluteDivForExitIcon onClick={closeFilters}>
        <IconInCircle
          iconName="rejected_no_fill"
          size="31px"
          color="#cad5dd"
          hoverColor="#ACBBC7"
        />
      </Styled.AbsoluteDivForExitIcon>
      <Styled.Header>
        <FormattedMessage id="Filter by" defaultMessage="Filter by" />
      </Styled.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Styled.Row>
          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage
                id="Document title"
                defaultMessage="Document title"
              />
            </Styled.Label>

            <Controller
              name="title"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                />
              )}
            />
          </Col>

          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage
                id="Publication date from-to"
                defaultMessage="Publication date from-to"
              />
            </Styled.Label>
            <Controller
              name="publicationDateRange"
              control={control}
              render={({ field, fieldState }) => (
                <CalendarField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState?.message,
                  }}
                  enableTime={false}
                  dateFormat="Y-m-d"
                  showMonths={2}
                  range
                />
              )}
            />
          </Col>
          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage
                id="Document blockchain address"
                defaultMessage="Document blockchain address"
              />
            </Styled.Label>
            <Controller
              name="blockchainAddress"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                />
              )}
            />
          </Col>

          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage
                id="Publication status"
                defaultMessage="Publication status"
              />
            </Styled.Label>

            <Controller
              name="documentStatusList"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                  options={documentStatusOptions}
                  isClearable
                />
              )}
            />
          </Col>
        </Styled.Row>
        <Styled.ButtonsGroupRow>
          <Col lg={6} xl={3}>
            <Button onClick={onClearFilters} block outline type="button">
              <FormattedMessage id="Clear" defaultMessage="Clear" />
            </Button>
          </Col>

          <Col lg={6} xl={3}>
            {!isSubmitting ? (
              <Button type="submit" block>
                <FormattedMessage id="Filter" defaultMessage="Filter" />
              </Button>
            ) : (
              <ButtonLoader size="lg" block />
            )}
          </Col>
        </Styled.ButtonsGroupRow>
      </form>
    </Styled.FilterCard>
  );
};

export default FilterForm;
