import React from 'react';
import { DropzonePlaceholder } from '../../modules/DocumentsNew/components/PublishDocumentModal/componnets';
import { FormattedMessage } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { Form as FormModule, Utils } from 'billon-ui';
import * as Styled from './styled';
import template from './assets/template.xlsx';
import SourceSystemsExporter from './SourceSystemsExporter';

const { UploadField } = FormModule;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const UploadRecipientsField = ({ name = 'file', disabled }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const handleDownloadTemplateClick = (e) => {
    e.stopPropagation();
  };

  const handleShowSourceSystemsClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <UploadField
            input={field}
            meta={{
              touched: fieldState.invalid,
              error: errors?.[field.name]?.message,
            }}
            accept={[
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-excel',
              '.csv',
            ]}
            disabled={disabled}
            placeholder={() => (
              <>
                <DropzonePlaceholder>
                  <Styled.ActionPanel>
                    <SourceSystemsExporter
                      onClick={handleShowSourceSystemsClick}
                    >
                      <FormattedMessage
                        id="Download source systems list"
                        defaultMessage="Download source systems list"
                      />
                    </SourceSystemsExporter>
                    <Styled.ActionAnchor
                      href={template}
                      target="_blank"
                      download="template.xlsx"
                      onClick={handleDownloadTemplateClick}
                    >
                      <FormattedMessage
                        id="Download template"
                        defaultMessage="Download template"
                      />
                    </Styled.ActionAnchor>
                  </Styled.ActionPanel>
                  <div>
                    <FormattedMessage
                      id="Drag and drop here CSV file with recipient list max 2MB"
                      defaultMessage="Drag and drop here CSV file with recipient list max 2MB"
                    />
                  </div>
                  <div>
                    <FormattedMessage id="or" defaultMessage="or" />
                  </div>
                  <Button outline type="button">
                    <FormattedMessage
                      id="Select from disk"
                      defaultMessage="Select from disk"
                    />
                  </Button>
                </DropzonePlaceholder>
              </>
            )}
          />
        )}
      />
    </>
  );
};
