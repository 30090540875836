import React from 'react';
import { DropzonePlaceholder } from '../../modules/DocumentsNew/components/PublishDocumentModal/componnets';
import { FormattedMessage } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { Form as FormModule, Utils } from 'billon-ui';

const { UploadField } = FormModule;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const ControlledUploadField = ({ name = 'file', disabled }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <UploadField
          input={field}
          meta={{
            touched: fieldState.invalid,
            error: errors?.[field.name]?.message,
          }}
          accept={['application/pdf']}
          disabled={disabled}
          placeholder={() => (
            <DropzonePlaceholder>
              <div>
                <FormattedMessage
                  id="Drag and drop here PDF max 2MB"
                  defaultMessage="Drag and drop here PDF max 2MB"
                />
              </div>
              <div>
                <FormattedMessage id="or" defaultMessage="or" />
              </div>
              <Button outline type="button">
                <FormattedMessage
                  id="Select from disk"
                  defaultMessage="Select from disk"
                />
              </Button>
            </DropzonePlaceholder>
          )}
        />
      )}
    />
  )
}
