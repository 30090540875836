import React from 'react';
import { Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';

const { Card } = Utils;

const HelpCard = ({ supportEmail }) => {
  return (
    <Card>
      <Styled.Title>
        <FormattedMessage
          id="Have a question?"
          defaultMessage="Have a question?"
        />
      </Styled.Title>
      <Styled.Description>
        <FormattedMessage
          id="Write to us and we will help you find a solution."
          defaultMessage="Write to us and we will help you find a solution."
        />
      </Styled.Description>
      <a
        href={`mailto:${supportEmail}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {supportEmail}
      </a>
    </Card>
  );
};

export default HelpCard;
