import { createAction } from 'redux-act';
import { Utils } from 'billon-ui';

const { helpers } = Utils;
const { encodeQueryString } = helpers;

export const applyFilters = (history, filters, page = 'rodo') =>
  history.push(`/${page}/${encodeQueryString(filters)}`);

export const requestList = createAction(
  'Request documents list',
  (filters, ...rest) => ({ filters, ...rest }),
);
export const requestListQuiet = createAction(
  'Request documents list quietly',
  (filters, ...rest) => ({ filters, ...rest }),
);
export const receiveListSuccess = createAction(
  'Request documents list success',
);
export const receiveListFailure = createAction(
  'Request documents list failure',
);

export const requestSingle = createAction(
  'Request single document',
  (id, ...rest) => ({ id, ...rest }),
);
export const receiveSingleSuccess = createAction(
  'Request single document success',
);
export const receiveSingleFailure = createAction(
  'Request single document failure',
);

export const requestDownloadRecipientsFile = createAction(
  'Download recipients file document',
  (id, name?, ...rest) => ({
    id,
    name,
    ...rest,
  }),
);
export const receiveDownloadRecipientsFileSuccess = createAction(
  'Download recipients file success',
);
export const receiveDownloadRecipientsFileFailure = createAction(
  'Download recipients file failure',
);

// Download document
export const requestDownload = createAction(
  'Download document',
  (blockchainAddress, jobId, returnOnlyBlob, ...rest) => ({
    blockchainAddress,
    jobId,
    returnOnlyBlob,
    ...rest,
  }),
);
export const receiveDownloadSuccess = createAction('Download document success');
export const receiveDownloadFailure = createAction('Download document failure');
