import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form as FormModule, Utils, Grid } from 'billon-ui';
import DataTab from './DataTab';
import PermissionsTab from './PermissionsTab';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSchema } from './useSchema';
import * as Styled from './styled';
import { useCreateUser } from '../../hooks/useCreateUser';
import { useUpdateUser } from '../../hooks/useUpdateUser';
import {
  mapVALIDATION_USER_ERRORS_NAMES,
  mapVALIDATION_USER_ERRORS,
  USER_ACTIONS,
  CUSTOMER,
  UserRoles,
} from '../../../../constraints';
import { removeUndefinedValues } from '../../../../helpers/removeUndefinedValues';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { validateEmptyPhoneNumber } from '../../../../helpers/validateEmptyPhoneNumber';

const { Col } = Grid;
const { FormGroup } = FormModule;
const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;

const CreateForm = (props) => {
  const { mode, initialValues, enablePrivate, onSuccess, onFailure } = props;

  const { customer } = useConfigContext();
  const isPhoneNumberRequired = [CUSTOMER.DEFAULT, CUSTOMER.DIPLOMA].includes(
    customer,
  );

  const schema = useSchema({ isPhoneNumberRequired });

  const { id } = initialValues || {};

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    watch,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: {
      isActive: true,
      isRobot: false,
      notify: customer === CUSTOMER.TAURON ? undefined : true,
      role: UserRoles.VISITOR,
      ...initialValues,
    },
    resolver: yupResolver(schema),
  });

  const handleError = ({ response }) => {
    clearErrors();
    const errorInfo = response?.data?.errorInfo;
    errorInfo &&
      Object.entries(errorInfo).map(([key, value]) => {
        setError(mapVALIDATION_USER_ERRORS_NAMES[key], {
          type: 'custom',
          message: mapVALIDATION_USER_ERRORS[`${value}_${key}`],
        });
      });
    onFailure();
  };

  const handleSuccess = (data) => {
    onSuccess(data);
  };

  const { mutate: createUser, isLoading: isCreating } = useCreateUser({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const { mutate: updateUser, isLoading: isUpdating } = useUpdateUser({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const handleFormSubmit = (data) => {
    let parsedData = { ...data };

    if (!isPhoneNumberRequired && validateEmptyPhoneNumber(data.phoneNumber)) {
      parsedData = { ...parsedData, phoneNumber: undefined };
    }
    if (mode === USER_ACTIONS.CREATE) {
      createUser({ data: parsedData });
    } else {
      parsedData = { ...parsedData, notify: undefined, isRobot: undefined };
      updateUser({ id, data: removeUndefinedValues(parsedData) });
    }
  };

  const isActiveWatch = watch('isActive');

  return (
    <>
      <Styled.Form onSubmit={handleSubmit(handleFormSubmit)}>
        <DataTab
          control={control}
          initialValues={initialValues}
          enablePrivate={enablePrivate}
          customer={customer}
          errors={errors}
          isActiveWatch={isActiveWatch}
          setValue={setValue}
        />
        <PermissionsTab
          control={control}
          initialValues={initialValues}
          enablePrivate={enablePrivate}
          customer={customer}
          errors={errors}
        />
        <FormGroup>
          <Col md={{ size: 8, offset: 2 }}>
            {isSubmitting || isCreating || isUpdating ? (
              <ButtonLoader block size="lg" />
            ) : (
              <Button type="submit" size="lg" block>
                <FormattedMessage id="Save" defaultMessage="Save" />
              </Button>
            )}
          </Col>
        </FormGroup>
      </Styled.Form>
    </>
  );
};

CreateForm.propTypes = {
  mode: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  initialValues: PropTypes.object,
  enablePrivate: PropTypes.bool,
  customer: PropTypes.string,
};

export default CreateForm;
