import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { StyledTitleContainer, DropzonePlaceholder } from './index';

import {
  Utils,
  Grid,
  Modal as ModalModule,
  Form as FormModule,
} from 'billon-ui';

const { Row, Col } = Grid;

const { ModalFooter } = ModalModule;
const { FormGroup, UploadField } = FormModule;

const { Button: ButtonModule, Text } = Utils;
const { Button } = ButtonModule;

export const CreateStep1 = ({
  downloadFileToSign,
  handleSubmit,
  handlePublish,
  detailsDataFromList,
}) => {
  return (
    <>
      <StyledTitleContainer>
        <Text as="h2" fontSize="32px">
          <FormattedMessage
            id="Document signing"
            defaultMessage="Document signing"
          />
        </Text>
        <Text as="p" fontSize="16px">
          <FormattedMessage
            id="We have prepared a document for you. Download it to your computer, sign it and then add it here for publishing."
            defaultMessage="We have prepared a document for you. Download it to your computer, sign it and then add it here for publishing."
          />
        </Text>
      </StyledTitleContainer>
      <Row css={{ width: '100%' }} margin={'0'}>
        <Col xl={6} margin={'0 0 20px 0'}>
          <Text as="h4" fontSize="16px" fontWeight={700}>
            1.{' '}
            <FormattedMessage
              id="Download document to sign"
              defaultMessage="Download document to sign"
            />
          </Text>
          <DropzonePlaceholder padding={'4rem'}>
            <div>
              <Text>
                <FormattedMessage
                  id="Download document to sign"
                  defaultMessage="Download document to sign"
                />
              </Text>
            </div>
            <br />
            <Button onClick={downloadFileToSign} outline type="button">
              <FormattedMessage id="Download" defaultMessage="Download" />
            </Button>
          </DropzonePlaceholder>
        </Col>
        <Col xl={6} margin={'0 0 20px 0'}>
          <Text as="h4" fontSize="16px" fontWeight={700}>
            2.{' '}
            <FormattedMessage
              id="Add signed document"
              defaultMessage="Add signed document"
            />
          </Text>
          <FormGroup>
            <Field
              component={UploadField}
              accept={['application/pdf']}
              name="file"
              placeholder={() => (
                <DropzonePlaceholder padding={'4rem'}>
                  <Text css={{ 'text-align': 'center' }}>
                    <FormattedMessage
                      id="Drag and drop here PDF max 2MB"
                      defaultMessage="Drag and drop here PDF max 2MB"
                    />{' '}
                    <br />
                    <FormattedMessage id="or" defaultMessage="or" />
                  </Text>

                  <Button outline type="button">
                    <FormattedMessage
                      id="Select from disk"
                      defaultMessage="Select from disk"
                    />
                  </Button>
                </DropzonePlaceholder>
              )}
            />
          </FormGroup>
        </Col>
      </Row>{' '}
      <ModalFooter>
        <Row css={{ width: '50%' }}>
          <Button
            size="lg"
            width="100%"
            onClick={handleSubmit((v) => {
              detailsDataFromList && detailsDataFromList.jobId
                ? handlePublish(
                    {
                      file: v.file,
                      orderOfSigning: { value: 'SENDER-FIRST' },
                      ...detailsDataFromList,
                    },
                    detailsDataFromList.jobId,
                  )
                : handlePublish(v);
            })}
          >
            Opublikuj
          </Button>
        </Row>
      </ModalFooter>
    </>
  );
};
