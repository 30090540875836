export default (values) => {
  const errors = {};

  if (values.name) {
    if (values.name.indexOf('/') !== -1) {
      errors.name = 'Category name cannot contain "/"!';
    }

    if (values.name.length < 3) {
      errors.name =
        'Category name is too short. Name must contain min 3 characters.';
    }
  }

  return errors;
};
