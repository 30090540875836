/* global sessionStorage */
import { Utils } from 'billon-ui';
import { ajax } from 'rxjs/observable/dom/ajax';
import { Observable } from 'rxjs/Rx';
import download from 'downloadjs';
import {
  requestList,
  requestListSuccess,
  requestListFailure,
  requestSave,
  receiveSaveSuccess,
  receiveSaveFailure,
  requestDelete,
  receiveDeleteSuccess,
  receiveDeleteFailure,
  requestUploadRecipientFile,
  receiveUploadRecipientFileSuccess,
  receiveUploadRecipientFileFailure,
  requestDownloadRecipientList,
  receiveDownloadRecipientListSuccess,
  receiveDownloadRecipientListFailure,
} from './actions';
import routes from '../../api-routes';
import { parseFilters, parseSave, parseUpload } from './helpers';

const { helpers } = Utils;
const { request, encodeQueryString } = helpers;
const {
  METHOD_GET,
  HEADER_CONTENT_TYPE,
  CONTENT_TYPE_APPLICATION_JSON,
  X_ACCESS_TOKEN,
  METHOD_POST,
  METHOD_DELETE,
  METHOD_PUT,
} = request;

const getRecipientListEpic = (actions$) =>
  actions$.ofType(requestList.getType()).mergeMap((action) =>
    ajax({
      url: `${routes.RECIPIENT}`,
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => requestListSuccess(response))
      .takeUntil(actions$.ofType(requestList.getType()))
      .catch((err) => Observable.of(requestListFailure(err))),
  );

const saveRecipientEpic = (actions$) =>
  actions$.ofType(requestSave.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { values } = action.payload;

    return ajax({
      url: values.id
        ? routes.SINGLE_RECIPIENT.replace('{id}', values.id)
        : routes.RECIPIENT,
      body: JSON.stringify(parseSave(values)),
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: sessionStorage.getItem('token'),
      },
      method: values.id ? METHOD_PUT : METHOD_POST,
    })
      .map(({ response }) => {
        if (resolve) {
          resolve(response);
        }
        return receiveSaveSuccess(response);
      })
      .takeUntil(actions$.ofType(requestSave.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }

        return Observable.of(receiveSaveFailure(err));
      });
  });

const deleteRecipientEpic = (actions$) =>
  actions$.ofType(requestDelete.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { id } = action.payload;

    return ajax({
      url: routes.SINGLE_RECIPIENT.replace('{id}', id),
      body: JSON.stringify(action.payload.params),
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_DELETE,
    })
      .map(({ response }) => {
        if (resolve) {
          resolve(response);
        }
        return receiveDeleteSuccess(response);
      })
      .takeUntil(actions$.ofType(requestDelete.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }
        return Observable.of(receiveDeleteFailure(err));
      });
  });

const UploadRecipientFileEpic = (actions$) =>
  actions$.ofType(requestUploadRecipientFile.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { values } = action.payload;

    return ajax({
      url: routes.LOAD_RECIPIENT_FILE,
      body: parseUpload(values),
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_POST,
    })
      .map(({ response }) => {
        if (resolve) {
          resolve(response);
        }
        return receiveUploadRecipientFileSuccess(response);
      })
      .takeUntil(actions$.ofType(requestUploadRecipientFile.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }

        return Observable.of(receiveUploadRecipientFileFailure(err));
      });
  });

const DownloadRecipientListEpic = (actions$) =>
  actions$.ofType(requestDownloadRecipientList.getType()).mergeMap((action) => {
    return ajax({
      url: routes.DOWNLOAD_RECIPIENT_LIST,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({response, ...rest}) => {
        console.log(response, ...rest);
        const blob = new Uint8Array(response.fileBuffer.data);
        download(blob, response.fileName, 'text/csv');
        return receiveDownloadRecipientListSuccess(response);
      })
      .takeUntil(actions$.ofType(requestDownloadRecipientList.getType()))
      .catch((err) => Observable.of(receiveDownloadRecipientListFailure(err)));
  });

export default [
  getRecipientListEpic,
  saveRecipientEpic,
  deleteRecipientEpic,
  UploadRecipientFileEpic,
  DownloadRecipientListEpic,
];
