import React from 'react';
import * as Styled from './styled';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Utils } from 'billon-ui';
import { CUSTOMER, MAP_DOCUMENT_STATUSES } from '../../../../../../constraints';
import {
  momentFormatter,
  momentFormatterWithoutTime,
} from '../../../../../../formatters';
import { publishedByFormatter } from '../../../../../UsersNew/formatters';
import { formatStudent } from '../../../../formatters';
import useFeatureEnabled from '../../../../../Config/hooks/useFeatureEnabled';

const { Col, Row } = Grid;
const { Text } = Utils;

const DetailsSection = ({ document, publisherName, customer }) => {
  const { featureEnabled } = useFeatureEnabled();

  const enabledDetailsCol1 = [
    {
      title: 'Publication start date',
      data: momentFormatter(document.publicationStartDate),
      enabled: featureEnabled('enableDocumentDetails.publicationStartDate', [
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    {
      title: 'Publication date',
      data: momentFormatter(document.publicationDate),
      enabled: featureEnabled('enableDocumentDetails.publicationDate', [
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    document?.category && {
      title: 'Category',
      data: document.category.fullPath,
      enabled: featureEnabled('enableDocumentDetails.categoryFullPath', [
        CUSTOMER.DIPLOMA,
      ]),
    },
    {
      title: 'Publication status',
      data: (
        <FormattedMessage
          id={MAP_DOCUMENT_STATUSES[document.status]}
          defaultMessage={MAP_DOCUMENT_STATUSES[document.status]}
        />
      ),
      enabled: featureEnabled('enableDocumentDetails.status', [
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    {
      title: 'Publisher',
      data: publisherName,
      enabled:
        publisherName &&
        featureEnabled('enableDocumentDetails.publisherName', [
          CUSTOMER.DIPLOMA,
          CUSTOMER.DEFAULT,
          CUSTOMER.TAURON,
          CUSTOMER.TAURON_AGREEMENT,
        ]),
    },
    {
      title: 'Published by',
      data: document?.publishedBy ? publishedByFormatter('', document) : '',
      enabled: featureEnabled('enableDocumentDetails.publishedBy', [
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
  ];

  const enabledDetailsCol2 = [
    {
      title: 'Document version',
      data: document.versionName,
      enabled: featureEnabled('enableDocumentDetails.versionName', [
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    {
      title: 'Retain until',
      data: momentFormatterWithoutTime(document.retainUntil),
      enabled: featureEnabled('enableDocumentDetails.retainUntil', [
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    {
      title:
        customer === CUSTOMER.DIPLOMA
          ? 'Date of issue of the diploma'
          : 'Is valid from',
      data: momentFormatterWithoutTime(document.validSince),
      enabled: featureEnabled('enableDocumentDetails.validSince', [
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    {
      title: 'Valid until',
      data: momentFormatterWithoutTime(document.validUntil),
      enabled: featureEnabled('enableDocumentDetails.validUntil', [
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ]),
    },
    document?.identity && {
      title: 'Student',
      data: formatStudent(document.identity),
      enabled: featureEnabled('enableDocumentDetails.identity', [
        CUSTOMER.DIPLOMA,
      ]),
    },
    document?.identity && {
      title: 'Index number',
      data: document.identity.documentNumber,
      enabled: featureEnabled('enableDocumentDetails.documentNumber', [
        CUSTOMER.DIPLOMA,
      ]),
    },
  ];

  return (
    <>
      <Row margin={0} md={12}>
        <Col>
          <Text
            as={'h1'}
            fontSize={'18px'}
            margin={'0 0 11px 0'}
            fontWeight={700}
          >
            <FormattedMessage
              id="Document details"
              defaultMessage="Document details"
            />
            :
          </Text>
        </Col>
      </Row>
      <Row margin={0} md={12}>
        <Styled.DetailsCol md={6}>
          {enabledDetailsCol1
            .filter((v) => v?.enabled)
            .map((v) => (
              <Row margin={0}>
                <Text margin="0 10px 0 0" fontSize="12px" fontWeight={700}>
                  <FormattedMessage id={v.title} defaultMessage={v.title} />:
                </Text>
                <Text fontSize="12px">{v.data}</Text>
              </Row>
            ))}
        </Styled.DetailsCol>
        <Styled.DetailsCol md={6}>
          {enabledDetailsCol2
            .filter((v) => v?.enabled)
            .map((v) => (
              <Row margin={0}>
                <Text margin="0 10px 0 0" fontSize="12px" fontWeight={700}>
                  <FormattedMessage id={v.title} defaultMessage={v.title} />:
                </Text>
                <Text fontSize="12px">{v.data}</Text>
              </Row>
            ))}
        </Styled.DetailsCol>
      </Row>
    </>
  );
};

export default DetailsSection;
