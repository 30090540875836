import {
  Dropdown as DropdownModule,
  Grid,
  Layout as LayoutModule,
  Utils,
} from 'billon-ui';
import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  requestCustomReport,
  requestDownloadStatisticsExcelReport,
  requestFairUsageReport,
  requestYearlyReport,
} from '../../actions';
import ConsumptionBarChart from '../../components/ConsumptionBarChart';
import PublishedDocsBarChart from '../../components/PublishedDocsBarChart';
import ReportsHistory from '../ReportsHistory';

import CustomTooltip from 'js/ui/CustomToolTip';

const { Row, Col } = Grid;
const { PageHeader } = LayoutModule;
const { Button: ButtonModule, Text, Card, Icon } = Utils;
const { Button } = ButtonModule;
const { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } =
  DropdownModule;

const StyledCard = styled(Card)`
   {
    padding: 2rem;
    overflow: auto;
    .recharts-surface {
      margin-right: 40px;
    }
    .recharts-text.recharts-cartesian-axis-tick-value {
      font-size: 10px;
    }
    .recharts-layer.recharts-bar-rectangle {
      opacity: 0.7;
      border-radius: 10px;
    }
  }
`;

const StyledPublishedDocsCardHeader = styled.div`
   {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    .dropdown {
      height: 100%;
    }
    @media (max-width: 1023px) {
      flex-direction: column;
    }
  }
`;

export const StyledDropDownDiv = styled.div`
   {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: flex-end;
    margin-bottom: 40px;
    margin-right: 40px;
    white-space: nowrap;
  }
`;

class Statistics extends Component {
  state = {
    publishedDocumentType: 0,
    historyDocumentType: 0,
    reportType: 'DAILY',
    // reportStartDate: "2020-01-01T00:00:00Z",
    // reportEndDate: "2022-01-01T00:00:00Z",
    reportStartDate: '',
    reportEndDate: '',
    historyPage: 1,
    historyPagesSize: 6,
    numberOfHistoryPages: 10,
  };

  refToDailyButton = React.createRef();

  async componentDidMount() {
    await this.props.getYearlyReport();
    await this.props.getFairUsageReport();
    await this.getCustomReportByStateParams();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dateRange !== this.props.dateRange) {
      if (this.props.dateRange.length > 1) {
        this.setState(
          {
            reportStartDate: moment(this.props.dateRange[0]).format(),
            reportEndDate: moment(this.props.dateRange[1]).format(),
          },
          async () => {
            await this.getCustomReportByStateParams();
          },
        );
      }
    }
    if (prevProps.customReportPages !== this.props.customReportPages) {
      this.setState({
        numberOfHistoryPages: Math.ceil(
          this.props.customReportPages / this.state.historyPagesSize,
        ),
      });
    }
  }

  changePublishedDocsType = (value) => {
    this.setState({ publishedDocumentType: value });
  };

  changeHistoryDocsType = (value) => {
    this.setState({ historyDocumentType: value }, async () => {
      await this.getCustomReportByStateParams();
    });
  };

  handleChangeHistoryPeriod = (v) => {
    this.setState({ reportType: v, historyPage: 1 }, async () => {
      await this.getCustomReportByStateParams();
    });
  };

  handleHistoryPageChange = (v) => {
    this.setState({ historyPage: v }, () => {
      this.getCustomReportByStateParams();
    });
  };

  getCustomReportByStateParams = async () => {
    await this.props.getCustomReport({
      reportStartDate: this.state.reportStartDate,
      reportEndDate: this.state.reportEndDate,
      reportType: this.state.reportType,
      pageSize: this.state.historyPagesSize,
      pageOffset: this.state.historyPage,
    });
  };

  downloadStatisticReportByStateParams = async () => {
    await this.props.downloadStatisticsExcelReport({
      reportStartDate: this.state.reportStartDate,
      reportEndDate: this.state.reportEndDate,
      reportType: this.state.reportType,
    });
  };

  handleDownloadStatisticReport = async () => {
    await this.downloadStatisticReportByStateParams();
  };

  render() {
    return (
      <>
        <PageHeader
          classic
          after={
            <Button
              fontWeight={500}
              padding="0.5rem 3.5rem;"
              onClick={this.handleDownloadStatisticReport}
            >
              <FormattedMessage
                id="Download statistics"
                defaultMessage="Download statistics"
              />
            </Button>
          }
        >
          <FormattedMessage id="Statistics" defaultMessage="Statistics" />
        </PageHeader>
        <Row>
          <Col md={6}>
            <StyledCard>
              <StyledPublishedDocsCardHeader>
                <Text fontWeight={600} fontSize={'20px'} margin={'0 0 30px 0 '}>
                  <FormattedMessage
                    id="Number of published documents"
                    defaultMessage="Number of published documents"
                  />
                </Text>
                <UncontrolledDropdown>
                  <StyledDropDownDiv>
                    <FormattedMessage
                      id="Document type"
                      defaultMessage="Document type"
                    />
                    <DropdownToggle />
                  </StyledDropDownDiv>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => this.changePublishedDocsType(0)}
                    >
                      <FormattedMessage id="All" defaultMessage="All" />
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.changePublishedDocsType(1)}
                    >
                      <FormattedMessage
                        id="Private2"
                        defaultMessage="Private"
                      />
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.changePublishedDocsType(2)}
                    >
                      <FormattedMessage id="Public2" defaultMessage="Public" />
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </StyledPublishedDocsCardHeader>
              {this.state.publishedDocumentType === 0 && (
                <FormattedMessage id="All documents" />
              )}
              {this.state.publishedDocumentType === 1 && (
                <FormattedMessage id="Private documents" />
              )}
              {this.state.publishedDocumentType === 2 && (
                <FormattedMessage id="Public documents" />
              )}
              <br />
              <br />
              <br />
              {this.props.yearlyReport ? (
                <PublishedDocsBarChart
                  publishedDocumentType={this.state.publishedDocumentType}
                  data={this.props.yearlyReport}
                />
              ) : (
                <FormattedMessage id="No data" defaultMessage="No data" />
              )}
            </StyledCard>
          </Col>

          <Col md={6}>
            <StyledCard>
              <ReportsHistory
                refToDailyButton={this.refToDailyButton}
                handleChangeHistoryPeriod={this.handleChangeHistoryPeriod}
                handleHistoryPageChange={this.handleHistoryPageChange}
                changeHistoryDocsType={this.changeHistoryDocsType}
                historyDocumentType={this.state.historyDocumentType}
                customReport={this.props.customReport}
                numberOfHistoryPages={this.state.numberOfHistoryPages}
                historyPage={this.state.historyPage}
                reportType={this.state.reportType}
              />
            </StyledCard>
          </Col>
        </Row>
        <Row margin={'3rem -1rem'}>
          <Col md={12}>
            <StyledCard>
              <Text fontWeight={600} fontSize={'20px'} margin={'0 0 30px 0 '}>
                <FormattedMessage id="Fair Usage" defaultMessage="Fair Usage" />
                {'  '}

                <CustomTooltip
                  id="CustomToolTip-fair-usage-1"
                  trigger={<Icon name="info-circle" color="grey" />}
                >
                  <FormattedMessage
                    id="fair usage definition"
                    defaultMessage="fair usage definition"
                  />
                </CustomTooltip>
              </Text>
              {this.props.fairUsageReport ? (
                <ConsumptionBarChart
                  publishedDocumentType={this.state.publishedDocumentType}
                  data={this.props.fairUsageReport}
                />
              ) : (
                <FormattedMessage id="No data" defaultMessage="No data" />
              )}
            </StyledCard>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = ({ reports, form }) => ({
  yearlyReport:
    reports.yearlyReport &&
    reports.yearlyReport.data &&
    reports.yearlyReport.data.yearlyReport
      ? reports.yearlyReport.data.yearlyReport
      : null,
  fairUsageReport:
    reports.fairUsageReport &&
    reports.fairUsageReport.data &&
    reports.fairUsageReport.data.fairUsageReport
      ? reports.fairUsageReport.data.fairUsageReport
      : null,
  customReport:
    reports.customReport &&
    reports.customReport.data &&
    reports.customReport.data.report
      ? reports.customReport.data.report.report
      : null,
  customReportPages:
    reports.customReport &&
    reports.customReport.data &&
    reports.customReport.data.numberOfRecords
      ? reports.customReport.data.numberOfRecords
      : null,
  dateRange:
    form.reportsHistoryForm && form.reportsHistoryForm.values
      ? form.reportsHistoryForm.values.dateRange
      : null,
});

const mapDispatchToProps = (dispatch) => ({
  getYearlyReport: () => dispatch(requestYearlyReport()),
  getFairUsageReport: () => dispatch(requestFairUsageReport()),
  getCustomReport: (params) => dispatch(requestCustomReport(params)),
  downloadStatisticsExcelReport: (params) =>
    dispatch(requestDownloadStatisticsExcelReport(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
