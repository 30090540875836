import WebViewer from '@pdftron/webviewer';
import { getInstance } from '@pdftron/webviewer';

export const initializeWebViewerFromBlob = (blob) => {
  document.querySelector('.webviewer').style.display = 'inherit';
  window.scrollTo(0, 0);
  document.querySelector('body').style.overflow = 'hidden';

  new WebViewer(
    {
      path: '/webViewer',
      l: 'Billon Digital Services sp. z o.o.(billongroup.com/en):OEM:Trusted Document Management::B+:AMS(20220411):6DA5907D04D7460A7360B13AC9A2527860617FDDD9063A5BFB0015F44C9740FE5ABAB6F5C7',
    },
    document.querySelector('.webviewer'),
  )
    .then((instance) => {
      document.querySelector('.webviewer-button-close').style.display =
        'inherit';
      instance.loadDocument(blob);
      instance.disableElements(['ribbons']);
      instance.isReadOnly();
    })
    .catch((res) => {});
};

export const closeWebViewer = () => {
  getInstance().dispose();
  document.querySelector('.webviewer').style.display = 'none';
  document.querySelector('.webviewer').innerHTML = '';
  document.querySelector('.webviewer-button-close').style.display = 'none';
  window.scrollTo(0, 0);
  document.querySelector('body').style.overflow = '';
};
