import { Table as TableModule, Utils } from 'billon-ui';
import React from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

const { TableCell } = TableModule;
const { Card, Button: ButtonModule, Icon, Loader: LoaderModule } = Utils;
const { Loader } = LoaderModule;
const { Button } = ButtonModule;

export const TC = styled(TableCell)`
  white-space: nowrap;
  font-weight: ${(props) =>
    props.row.isNewlyPublished
      ? props.theme.fontWeightBold
      : props.theme.fontWeightBase};
`;

export const StyledCloseFilterButton = styled(Button)`
  /* height: 34px;  */
  border-radius: 65px;
  padding: 0.5rem 0 0.5rem 1.5rem;
  margin: 0.5rem 0.5rem 0;
  svg {
    margin-left: 1rem;
  }
`;

export const StyledFiltersWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  margin-bottom: 30px;
  margin-right: -3rem;

  ${down('md')} {
    margin-bottom: 10px;
    margin-right: -1rem;
  }
`;

export const StyledTableCard = styled(Card)`
  padding: 0 0 20px 0;
  box-shadow: none;
`;

export const StyledPrevButton = styled(Button)`
  padding: 0;
  padding-top: 0.5em;
  padding-left: 1em;
  svg {
    color: #212122;
  }
`;

export const StyledLoadingButton = styled(Button)`
  padding: 0;
  padding-top: 0.8em;
  padding-left: 1em;
  svg {
    color: #212122;
  }
`;

export const fileActionButtonFormatter =
  (
    currentDownload: Array<string>,
    fileDownload: (address: string, jobId: string, onlyBlob: boolean) => void,
    preview: boolean = false,
  ) =>
  (
    address: string,
    {
      jobId,
      documentBlockchainAddress,
    }: { jobId: string; documentBlockchainAddress: string },
  ) => {
    if (
      currentDownload.includes(address || jobId || documentBlockchainAddress)
    ) {
      return (
        <StyledLoadingButton color="link" className="ignore-row-click">
          <Loader width={36} />
        </StyledLoadingButton>
      );
    }
    return (
      <StyledPrevButton
        color="link"
        className="ignore-row-click"
        onClick={() =>
          fileDownload(address || documentBlockchainAddress, jobId, preview)
        }
      >
        <Icon billon={!preview} name={preview ? 'eye' : 'pdf'} />
      </StyledPrevButton>
    );
  };
