import { Form, Grid, Utils } from 'billon-ui';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  Field,
  InjectedFormProps,
  reduxForm,
  formValueSelector,
} from 'redux-form';
import { connect } from 'react-redux';

import * as Styled from './FilterForm.styled';

import useFilters from 'js/hooks/useFilters';
import { generateFilters } from 'js/components/CommonTable/helpers';

import {
  NOTIFICATION_HISTORY_STATUSES,
  NOTIFICATION_HISTORY_STATUSES_LABELS,
  NOTIFICATION_HISTORY_FILTERS_OPTIONS,
} from 'js/constraints';

import WithValueMessage from 'js/helpers/WithValueMessage';

const { SelectField, TextField } = Form;
const { Button: ButtonModule, Text } = Utils;
const { Button } = ButtonModule;
const { Row, Col } = Grid;

type FilterFormProps = {
  closeFilters: () => void;
};

const RawInlMessage = (label) => (
  <FormattedMessage id={label} defaultMessage={label} />
);

const fieldsOptions: Array<any> = [
  {
    label: RawInlMessage('Email/Phone'),
    value: NOTIFICATION_HISTORY_FILTERS_OPTIONS.CONTACT_DETAILS,
  },
  {
    label: RawInlMessage('Identifier'),
    value: NOTIFICATION_HISTORY_FILTERS_OPTIONS.CLIENT_ID,
  },
  {
    label: RawInlMessage('Status'),
    value: NOTIFICATION_HISTORY_FILTERS_OPTIONS.STATUS,
  },
];

const statusOptions = [
  {
    label: RawInlMessage(NOTIFICATION_HISTORY_STATUSES_LABELS.DELIVERED),
    value: NOTIFICATION_HISTORY_STATUSES.DELIVERED,
  },
  {
    label: RawInlMessage(NOTIFICATION_HISTORY_STATUSES_LABELS.IN_PROGRESS),
    value: NOTIFICATION_HISTORY_STATUSES.IN_PROGRESS,
  },
  {
    label: RawInlMessage(NOTIFICATION_HISTORY_STATUSES_LABELS.ERROR),
    value: NOTIFICATION_HISTORY_STATUSES.ERROR,
  },
];

export interface FilterFormFields {
  contactDetails?: string;
  clientId?: string;
  notificationStatusList?: Array<string>;
}

let FilterForm: React.FC<
  InjectedFormProps<FilterFormFields, FilterFormProps, any> &
    FilterFormProps &
    any
> = ({ initialValues, handleSubmit, closeFilters, fieldValue, reset }) => {
  const history = useHistory();
  const intl = useIntl();
  const { filters } = useFilters();

  const applyFilters = (filters2) => {
    const generatedFilters = generateFilters({ ...filters, ...filters2 });
    if (generatedFilters === '') {
      history.push(history.location.pathname + '?');
    } else {
      history.push('?' + generatedFilters);
    }
  };

  const handleFilterSubmit = (value) => {
    const { name, newValue } = value;

    const filterFormFilters = {
      contactDetails:
        name === 'contactDetails' && newValue !== '' ? newValue : undefined,
      clientId: name === 'clientId' && newValue !== '' ? newValue : undefined,
      notificationStatusList:
        name === 'notificationStatusList' && newValue
          ? newValue.map((item) => item.value)
          : undefined,
    };

    applyFilters({
      filters: filterFormFilters,
    });
  };

  const onChange = (event, newValue, previousValue, name) =>
    handleFilterSubmit({ name, newValue });

  return (
    <form>
      <Row md={12}>
        <Col md={6}>
          <Styled.Text>
            <FormattedMessage id="Search by" defaultMessage="Search by" />
            {':'}
          </Styled.Text>
          <Field component={SelectField} name="field" options={fieldsOptions} />
        </Col>

        <Col md={6}>
          <Styled.Text>
            {WithValueMessage({
              messageId: 'Type {name}',
              name: 'name',
              value: intl.formatMessage({
                id: fieldValue?.label?.props?.id.toLowerCase() || 'value',
              }),
            })}
            {':'}
          </Styled.Text>

          {fieldValue?.value ===
          NOTIFICATION_HISTORY_FILTERS_OPTIONS.CONTACT_DETAILS ? (
            <Field
              component={TextField}
              name="contactDetails"
              onChange={onChange}
            />
          ) : fieldValue?.value ===
            NOTIFICATION_HISTORY_FILTERS_OPTIONS.CLIENT_ID ? (
            <Field component={TextField} name="clientId" onChange={onChange} />
          ) : (
            NOTIFICATION_HISTORY_FILTERS_OPTIONS.STATUS && (
              <Field
                multi
                component={SelectField}
                name="notificationStatusList"
                searchable
                options={statusOptions}
                isClearable
                onChange={onChange}
              />
            )
          )}
        </Col>
      </Row>
    </form>
  );
};

const selector = formValueSelector('norificationHistoryfilterForm');

const mapStateToProps = (state) => {
  return {
    initialValues: {
      field: fieldsOptions[0],
    },
    fieldValue: selector(state, 'field'),
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'norificationHistoryfilterForm',
    enableReinitialize: true,
  })(FilterForm as any),
);
