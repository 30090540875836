import { Utils } from 'billon-ui';
import { isUndefined } from 'lodash';

const { helpers } = Utils;
const { parseFilters: superParseFilters } = helpers;

export const parseCreateValues = (values) => {
  const output = {
    name: values.name,
    isActive: !!values.isActive,
  };
  if (values.parentId) {
    output.parentId = values.parentId.value;
  }

  return output;
};

export const parseFilters = (filters) => {
  const output = superParseFilters(filters);

  if (filters.name) {
    output.where.name = filters.name;
  }

  if (filters.searchBar) {
    output.where.searchBar = filters.searchBar;
  }

  if (filters.parent) {
    output.where.parent = filters.parent;
  }

  if (!isUndefined(filters.isActive)) {
    output.where.isActive = filters.isActive;
  }

  return output;
};
