import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form as FormModule, Grid } from 'billon-ui';
import { Controller } from 'react-hook-form';
import * as Styled from './styled';
import { CUSTOMER } from '../../../../constraints';
import { UserRoles } from 'js/constraints';

const { Row, Col } = Grid;
const { FormGroup, FancyRadioField } = FormModule;

const PermissionsTab = ({ enablePrivate, customer, control }) => {
  const colWidth = customer === CUSTOMER.TAURON ? 4 : 3;

  return (
    <Styled.Permissions>
      <h4>
        <FormattedMessage id="Account type" defaultMessage="Account type" />
      </h4>

      <Row>
        <Col md={colWidth}>
          <FormGroup>
            <Controller
              name="role"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FancyRadioField
                    input={{
                      ...field,
                      value: UserRoles.VISITOR,
                      checked: field.value === UserRoles.VISITOR,
                    }}
                    meta={{
                      dirty: fieldState.isDirty,
                      touched: fieldState.invalid,
                    }}
                    label={
                      <FormattedMessage id="Basic" defaultMessage="Basic" />
                    }
                  >
                    <ul>
                      <li>
                        <FormattedMessage
                          id="Readonly"
                          defaultMessage="Readonly"
                        />
                      </li>
                    </ul>
                  </FancyRadioField>
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col md={colWidth}>
          <FormGroup>
            <Controller
              name="role"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FancyRadioField
                    input={{
                      ...field,
                      value: UserRoles.AGENT,
                      checked: field.value === UserRoles.AGENT,
                    }}
                    meta={{
                      dirty: fieldState.isDirty,
                      touched: fieldState.invalid,
                    }}
                    label={
                      <FormattedMessage
                        id="Standard"
                        defaultMessage="Standard"
                      />
                    }
                  >
                    <ul>
                      <li>
                        <FormattedMessage
                          id="Readonly"
                          defaultMessage="Readonly"
                        />
                      </li>

                      {customer && (
                        <li>
                          {
                            {
                              [CUSTOMER.DEFAULT]: (
                                <FormattedMessage
                                  id="Document publication"
                                  defaultMessage="Document publication"
                                />
                              ),
                              [CUSTOMER.TAURON]: (
                                <FormattedMessage
                                  id="Document publication"
                                  defaultMessage="Document publication"
                                />
                              ),
                              [CUSTOMER.DIPLOMA]: (
                                <FormattedMessage
                                  id="Diploma publication"
                                  defaultMessage="Diploma publication"
                                />
                              ),
                            }[customer]
                          }
                        </li>
                      )}

                      {enablePrivate && (
                        <>
                          {
                            {
                              [CUSTOMER.DEFAULT]: (
                                <li>
                                  <FormattedMessage
                                    id="Adding recipients"
                                    defaultMessage="Adding recipients"
                                  />
                                </li>
                              ),
                              [CUSTOMER.DIPLOMA]: (
                                <li>
                                  <FormattedMessage
                                    id="Adding students"
                                    defaultMessage="Adding students"
                                  />
                                </li>
                              ),
                            }[customer]
                          }
                          {[CUSTOMER.TAURON].includes(customer) && (
                            <li>
                              <FormattedMessage
                                id="Resending notifications"
                                defaultMessage="Resending notifications"
                              />
                            </li>
                          )}
                        </>
                      )}
                    </ul>
                  </FancyRadioField>
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col md={colWidth}>
          <FormGroup>
            <Controller
              name="role"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FancyRadioField
                    input={{
                      ...field,
                      value: UserRoles.ADMIN,
                      checked: field.value === UserRoles.ADMIN,
                    }}
                    meta={{
                      dirty: fieldState.isDirty,
                      touched: fieldState.invalid,
                    }}
                    label={
                      <FormattedMessage
                        id="Advanced"
                        defaultMessage="Advanced"
                      />
                    }
                  >
                    <ul>
                      <li>
                        <FormattedMessage
                          id="Readonly"
                          defaultMessage="Readonly"
                        />
                      </li>
                      {customer && (
                        <li>
                          {
                            {
                              [CUSTOMER.DEFAULT]: (
                                <FormattedMessage
                                  id="Document publication"
                                  defaultMessage="Document publication"
                                />
                              ),
                              [CUSTOMER.TAURON]: (
                                <FormattedMessage
                                  id="Document publication"
                                  defaultMessage="Document publication"
                                />
                              ),
                              [CUSTOMER.DIPLOMA]: (
                                <FormattedMessage
                                  id="Diploma publication"
                                  defaultMessage="Diploma publication"
                                />
                              ),
                            }[customer]
                          }
                        </li>
                      )}

                      {enablePrivate && (
                        <>
                          {
                            {
                              [CUSTOMER.DEFAULT]: (
                                <li>
                                  <FormattedMessage
                                    id="Adding recipients"
                                    defaultMessage="Adding recipients"
                                  />
                                </li>
                              ),
                              [CUSTOMER.DIPLOMA]: (
                                <li>
                                  <FormattedMessage
                                    id="Adding students"
                                    defaultMessage="Adding students"
                                  />
                                </li>
                              ),
                            }[customer]
                          }
                          {[CUSTOMER.TAURON].includes(customer) && (
                            <li>
                              <FormattedMessage
                                id="Resending notifications"
                                defaultMessage="Resending notifications"
                              />
                            </li>
                          )}
                        </>
                      )}
                      <li>
                        <FormattedMessage
                          id="Managing"
                          defaultMessage="Managing"
                        />
                      </li>
                    </ul>
                  </FancyRadioField>
                );
              }}
            />
          </FormGroup>
        </Col>

        {[CUSTOMER.DEFAULT, CUSTOMER.DIPLOMA].includes(customer) && (
          <Col md={colWidth}>
            <FormGroup>
              <Controller
                name="role"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <FancyRadioField
                      input={{
                        ...field,
                        value: UserRoles.DPI,
                        checked: field.value === UserRoles.DPI,
                      }}
                      meta={{
                        dirty: fieldState.isDirty,
                        touched: fieldState.invalid,
                      }}
                      label={
                        <FormattedMessage
                          id="DPI (Data Protection Inspector)"
                          defaultMessage="DPI (Data Protection Inspector)"
                        />
                      }
                    >
                      <ul>
                        <li>
                          <FormattedMessage
                            id="Readonly"
                            defaultMessage="Readonly"
                          />
                        </li>
                        <li>
                          <FormattedMessage id="RODO" defaultMessage="RODO" />
                        </li>
                        <li>
                          <FormattedMessage
                            id="Correction of personal data"
                            defaultMessage="Correction of personal data"
                          />
                        </li>
                      </ul>
                    </FancyRadioField>
                  );
                }}
              />
            </FormGroup>
          </Col>
        )}
      </Row>
    </Styled.Permissions>
  );
};

PermissionsTab.propTypes = {
  customer: PropTypes.string,
  enablePrivate: PropTypes.bool,
  control: PropTypes.object.isRequired,
};

export default PermissionsTab;
