import { Form, Grid, Utils } from 'billon-ui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { change, Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { IconInCircle } from '../../../../components/IconInCircle';
import { CalendarField } from '../../../../containers';
import { requestStatusToString } from '../../../../formatters';
// Actions
import { applyFilters } from '../../actions';

const { SelectField, TextField } = Form;
const { Button: ButtonModule, Text, Card } = Utils;
const { Button } = ButtonModule;
const { Row, Col } = Grid;

const StyledAbsoluteDivForExitIcon = styled.div`
  position: absolute;
  right: 4%;
  top: 10%;
  cursor: pointer;
`;

const StyledFilterCard = styled(Card)`
  position: relative;
  box-shadow: none;
  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

class FilterFormRequests extends Component {
  constructor(props) {
    super(props);

    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
  }

  applyFilters(filters) {
    const { history } = this.props;

    applyFilters(history, filters);
  }

  handleFilterSubmit(values) {
    const { initialValues } = this.props;
    this.applyFilters({
      ...initialValues,
      ...values,
      page: undefined,
      limit: undefined,
    });
  }

  render() {
    const { handleSubmit, statuses, closeFilters, intl } = this.props;

    const statusesFormatted = statuses.map((status) => ({
      label: intl.formatMessage({
        id: requestStatusToString(status),
        defaultMessage: requestStatusToString(status),
      }),
      value: status,
    }));

    return (
      <StyledFilterCard>
        <StyledAbsoluteDivForExitIcon onClick={closeFilters}>
          <IconInCircle
            iconName="rejected_no_fill"
            size="36px"
            color="#cad5dd"
            hoverColor="#ACBBC7"
          />
        </StyledAbsoluteDivForExitIcon>
        <form onSubmit={handleSubmit(this.handleFilterSubmit)}>
          <Row>
            <Col md={3}>
              <Text
                as="p"
                fontSize={'12px'}
                fontWeight={700}
                margin={'0 0 5px 0'}
              >
                <FormattedMessage id="Request ID" defaultMessage="Request ID" />
              </Text>
              <Field component={TextField} name="requestJobId" />
            </Col>
            {/* <Col md={3}>
              <Text
                as="p"
                fontSize={"12px"}
                fontWeight={700}
                margin={"0 0 5px 0"}
              >
                <FormattedMessage
                  id="Observer"
                  defaultMessage="Observer"
                />
              </Text>
              <Field
                component={SelectField}
                name="observer"
                searchable
                options={[]}
              />
            </Col> */}

            <Col md={3}>
              <Text
                as="p"
                fontSize={'12px'}
                fontWeight={700}
                margin={'0 0 5px 0'}
              >
                <FormattedMessage id="Request issued" defaultMessage="Issued" />
              </Text>
              <Field
                component={CalendarField}
                showMonths={2}
                name="requestCreationTime"
                range
                enableTime={false}
                dateFormat="Y-m-d"
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              {' '}
              <Text
                as="p"
                fontSize={'12px'}
                fontWeight={700}
                margin={'0 0 5px 0'}
              >
                <FormattedMessage id="Status" defaultMessage="Status" />
              </Text>
              <Field
                component={SelectField}
                isClearable
                name="requestStatus"
                searchable
                options={statusesFormatted}
              />
            </Col>

            <Col md={3}>
              <Text
                as="p"
                fontSize={'12px'}
                fontWeight={700}
                margin={'0 0 5px 0'}
              >
                <FormattedMessage
                  id="Valid until"
                  defaultMessage="Valid until"
                />
              </Text>
              <Field
                component={CalendarField}
                showMonths={2}
                name="requestValidityDateRange"
                range
                enableTime={false}
                dateFormat="Y-m-d"
              />
            </Col>
            <Col
              md={{ size: 2, offset: 4 }}
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: ' 5px',
              }}
            >
              <Button outline width="100%" className="button-static">
                <FormattedMessage id="Filter" defaultMessage="Filter" />
              </Button>
            </Col>
          </Row>
        </form>
      </StyledFilterCard>
    );
  }
}

FilterFormRequests.propTypes = {
  handleSubmit: PropTypes.func,
  publishers: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  customer: PropTypes.string.isRequired,
  resetField: PropTypes.func.isRequired,
};

const mapStateToProps = ({ config, ...state }) => {
  return {
    customer: config.customer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetField: (field) => dispatch(change('filterForm', field, '')),
});

export default withRouter(
  injectIntl(
    reduxForm({
      form: 'filterForm',
    })(connect(mapStateToProps, mapDispatchToProps)(FilterFormRequests)),
  ),
);
