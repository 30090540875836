import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Utils } from 'billon-ui';
import * as Styled from './styled';
import CustomToolTip from 'js/ui/CustomToolTip';

const { Button: ButtonModule } = Utils;
const { IconButton } = ButtonModule;

export const AddCategoryCell = ({ id, name, onClick }) => (
  <Styled.Wrapper>
    <div>{name}</div>
    <CustomToolTip
      id={'CategoryTooltip-' + id}
      trigger={<IconButton icon="plus" onClick={onClick} />}
      delay={false}
      placement="top"
    >
      <FormattedMessage
        id="Create subcategory"
        defaultMessage="Create subcategory"
      />
    </CustomToolTip>
  </Styled.Wrapper>
);
