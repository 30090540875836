import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  overflow-x: scroll;
`;

export const Row = styled.div`
  display: flex;
  ${({ bold }) => bold && `font-weight: 700;`}
`;

export const FirstRow = styled(Row)`
  border-bottom: 1px solid #abc4c6;
`;

export const Cell = styled.div`
  font-size: 12px;
  display: flex;
  flex: 0 0 200px;
  padding: 1rem;
  align-items: center;
  justify-content: center;

  .formatted-switch {
    width: auto;
  }
`;

export const FirstCell = styled(Cell)`
  flex: 0 0 75px;
`;

export const NameCell = styled(Cell)`
  flex: 1 0 auto;
  justify-content: flex-start;
  min-width: 250px;
`;
