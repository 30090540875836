import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Styled from './styled';
import {
  Form as FormModule,
  Grid,
  Utils,
  Layout as LayoutModule,
} from 'billon-ui';
// Helpers
import { PUBLICATION_FIELD_NAMES } from '../../../../constraints';
import { ControlledTextField } from '../../../../components/ControlledTextField';
import { ControlledCalendarField } from '../../../../components/ControlledCalendarField/ControlledCalendarField';
import { SelectCategoryField } from '../../../../components/SelectCategoryField/SelectCategoryField';
import { SelectIdentityField } from '../../../../components/SelectIdentityField/SelectIdentityField';
import useFilters from '../../../../hooks/useFilters';
import { CUSTOMER, DOCUMENT_TYPE } from '../../../../constraints';
import { SelectRetentionField } from '../../../../components/SelectRetentionField/SelectRetentionField';
import { FormFooter } from '../../../../components/FormFooter';
import { SummaryFileDownload } from '../SummaryFileDownload/SummaryFileDownload';
import { SummaryNotifications } from '../SummaryNotifications/SummaryNotifications';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { useDownloadRecipientsList } from '../../hooks/useDownloadRecipientsList';

const { FormGroup } = FormModule;
const { Text } = Utils;
const { Row, Col } = Grid;

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

export const DocumentPublicationSummary = ({
  onSubmit,
  onBack,
  isLoading,
  defaultValues = {},
}) => {
  const { filters } = useFilters();

  const isPublicDocument = [
    filters?.documentTypeList,
    defaultValues?.documentType,
  ].includes(DOCUMENT_TYPE.PUBLIC);

  const { customer } = useConfigContext();

  const isDiploma = customer === CUSTOMER.DIPLOMA;
  const isTauron = customer === CUSTOMER.TAURON;

  const { formatMessage } = useIntl();

  const formMethods = useForm({
    defaultValues: {
      ...defaultValues,
      file: [],
    },
  });

  const handleFormSubmit = (values) => {
    onSubmit(values);
  };

  const { handleSubmit } = formMethods;

  const { mutate: downloadRecipientsList } = useDownloadRecipientsList(
    defaultValues.jobId,
    defaultValues.title,
  );

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <Styled.Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row>
          <Col xl={4}>
            <Text as="h6" fontSize="12px" fontWeight="600">
              <FormattedMessage
                id="Published by"
                defaultMessage="Published by"
              />
            </Text>
            <ControlledTextField
              name={PUBLICATION_FIELD_NAMES.PUBLISHED_BY}
              disabled
            />
            <Text as="h6" fontSize="12px" fontWeight="600">
              {isDiploma ? (
                <FormattedMessage
                  id="Diploma number"
                  defaultMessage="Diploma number"
                />
              ) : (
                <FormattedMessage id="Title" defaultMessage="Title" />
              )}
            </Text>
            <ControlledTextField
              name={PUBLICATION_FIELD_NAMES.TITLE}
              disabled
              placeholder={formatMessage({
                id: 'Document number',
                defaultMessage: 'Document number',
              })}
            />
            <SelectCategoryField disabled />
            {isPublicDocument && (
              <>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Document version"
                    defaultMessage="Document version"
                  />
                </Text>
                <ControlledTextField name="versionName" disabled />
              </>
            )}
          </Col>

          <Col xl={4}>
            <Text as="h6" fontSize="12px" fontWeight="600">
              {isDiploma ? (
                <FormattedMessage
                  id="Date of issue of the diploma"
                  defaultMessage="Date of issue of the diploma"
                />
              ) : (
                <FormattedMessage
                  id="Valid since"
                  defaultMessage="Valid since"
                />
              )}
            </Text>
            <FormGroup>
              <ControlledCalendarField
                name="validSince"
                disabled
                range={isPublicDocument}
                maxDate={isDiploma ? new Date() : undefined}
              />
            </FormGroup>
            {!isDiploma && (
              <>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Valid until"
                    defaultMessage="Valid until"
                  />
                </Text>
                <FormGroup>
                  <ControlledCalendarField
                    name="validUntil"
                    disabled
                    range={isPublicDocument}
                  />
                </FormGroup>
              </>
            )}
            {isPublicDocument ? (
              !isTauron && <SelectRetentionField disabled />
            ) : (
              <SelectIdentityField disabled />
            )}
          </Col>

          <Col xl={4}>
            <Text as="h6" fontSize="12px" fontWeight="600">
              <FormattedMessage id="Document" defaultMessage="Document" />
            </Text>
            <FormGroup>
              <SummaryFileDownload file={defaultValues.documentFile[0]} />
            </FormGroup>
            {isPublicDocument && (
              <>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Recipients"
                    defaultMessage="Recipients"
                  />
                </Text>
                <FormGroup>
                  {defaultValues?.contactDetails ? (
                    <SummaryFileDownload
                      file={defaultValues.contactDetails[0]}
                    />
                  ) : (
                    <SummaryFileDownload onClick={downloadRecipientsList} />
                  )}
                </FormGroup>
              </>
            )}
            <Text as="h6" fontSize="12px" fontWeight="600">
              <FormattedMessage
                id="Notification contents"
                defaultMessage="Notification contents"
              />
            </Text>
            <FormGroup>
              <SummaryNotifications
                smsContentInput={defaultValues.smsContentInput}
                emailContentInput={defaultValues.emailContentInput}
              />
            </FormGroup>
          </Col>
        </Row>

        <FormFooter onBack={onBack} isLoading={isLoading} />
      </Styled.Form>
    </FormProvider>
  );
};
