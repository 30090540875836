import React from 'react';
import { useQueryClient } from 'react-query';

import * as Styled from './styled';

import { useCategoryUpdate } from '../../hooks/useCategoryUpdate';
import { QueryKeys } from '../../../../queryKeys';

export const ActiveSwitch = ({ categoryId, isActive, parentId, disabled }) => {
  const queryClient = useQueryClient();

  const { mutate: updateCategory } = useCategoryUpdate({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.CATEGORY_LIST, { parentId }]);
    },
  });

  const handleSwitch = () => {
    updateCategory({
      id: categoryId,
      data: {
        isActive: !isActive,
      },
    });
  };

  return (
    <Styled.SwitchField
      value={categoryId}
      className={'formatted-switch'}
      input={{
        checked: isActive,
        onChange: handleSwitch,
      }}
      slim
      meta={{
        dirty: true,
        submitting: disabled,
      }}
      disabled={disabled}
    />
  );
};
