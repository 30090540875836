import React from 'react';
import { connect } from 'react-redux';
import { Table as TableModule, Utils } from 'billon-ui';
import { withRouter } from 'react-router-dom';

// Actions
import {
  requestList,
  applyFilters as applyFiltersAction,
  requestDownload,
} from '../../actions';
import { requestList as requestCategoriesList } from '../../../Categories/actions';
import { requestList as requestRecipientsList } from '../../../Recipients/actions';

import { DOCUMENT_STATUS } from '../../../../constraints';
import { DocumentsTable } from '../../components/RemoteSignDocumentsPageComponents/DocumentsTable';
import StatusesAndFiltersDocumentTable from '../../components/RemoteSignDocumentsPageComponents/StatusesAndFiltersDocumentTable';
import styled from 'styled-components';

const { AbstractTable } = TableModule;
const { Loader: LoaderModule, Button: ButtonModule } = Utils;
const { IconButton } = ButtonModule;
const { Loader, PageLoader } = LoaderModule;

const DownloadIconButton = styled(IconButton)`
  font-size: 18px;
  color: #d0d0d0;
`;

class DocumentTableRemoteSignContainer extends AbstractTable {
  state = {
    order: {},
    isFiltersOpen: false,
    selectedDocumentType: 0,
    copied: false,
    copyHover: false,
  };

  constructor(props) {
    super(props);

    this.onCopied = this.onCopied.bind(this);
  }

  componentDidMount() {
    const { loadCategories, loadRecipients } = this.props;
    loadCategories();
    loadRecipients();

    const filtersFromUrl = this.getFilters();
    this.setState({
      selectedDocumentType: filtersFromUrl.documentType || 0,
      initialValues: filtersFromUrl || {},
    });
  }

  onCopied = () => {
    this.setState({
      copied: true,
    });

    setTimeout(() => this.setState({ copied: false }), 2000);
  };

  changeCopyHoverTrue = () => {
    this.setState({
      copyHover: true,
    });
  };

  changeCopyHoverFalse = () => {
    this.setState({
      copyHover: false,
    });
  };

  clickDocumentType = (number) => {
    if (number === this.state.selectedDocumentType) {
      this.setState({ selectedDocumentType: 0 }, () => {
        this.handleFilterSubmit();
      });
    } else {
      this.setState({ selectedDocumentType: number }, () => {
        this.handleFilterSubmit();
      });
    }
  };

  closeFilters = () => {
    this.setState({ isFiltersOpen: false });
  };

  toggleFilters = () => {
    this.setState({ isFiltersOpen: !this.state.isFiltersOpen });
  };

  getDefaultFilters() {
    return {
      ...super.getDefaultFilters(),
    };
  }

  applyFilters(filters) {
    const { history } = this.props;
    this.props.applyFilters(history, filters);
  }

  handleFilterSubmit = () => {
    const initialValues = this.state.initialValues;
    this.applyFilters({
      ...initialValues,
      // ...{
      ...this.props.form.filterRemoteDocumentTableForm.values,
      documentType: this.state.selectedDocumentType,
      // },
      page: undefined,
      limit: undefined,
    });
  };

  handleRemoveFilter = (filter) => {
    const initialValues = this.state.initialValues;
    const formFilters = this.props.form.filterRemoteDocumentTableForm.values;

    formFilters[filter] = undefined;

    this.applyFilters({
      ...initialValues,
      ...formFilters,
      documentType: this.state.selectedDocumentType,
      page: undefined,
      limit: undefined,
    });
  };

  openDetailsPage = (documentBlockchainAddress) => {
    if (!documentBlockchainAddress) return;
    this.props.history.push(`/agreement/${documentBlockchainAddress}`);
  };

  downloadFormatter = (address, row) => {
    const { fileDownload, isDownloading } = this.props;

    if (row.status === DOCUMENT_STATUS.NOT_AVAILABLE) {
      return null;
    }

    if (isDownloading.includes(address || row.jobId)) {
      return <Loader width={36} />;
    }

    return (
      <DownloadIconButton
        icon="pdf"
        billonIcon={true}
        onClick={(e) => {
          e.stopPropagation();
          fileDownload(address, row.jobId);
        }}
      />
    );
  };

  render() {
    const {
      records,
      isFetching,
      numberOfRecords,
      categories,
      settings,
      activeDelivery,
      agreementsCountByStatus,
      recipientsSelect,
      filtersFromForm,
    } = this.props;

    const filters = this.getFilters();

    return (
      <>
        <StatusesAndFiltersDocumentTable
          categories={categories}
          toggleCreateNewDocument={this.toggleCreateNewDocument}
          selectedDocumentType={this.state.selectedDocumentType}
          clickDocumentType={this.clickDocumentType}
          toggleFilters={this.toggleFilters}
          closeFilters={this.closeFilters}
          isFiltersOpen={this.state.isFiltersOpen}
          handleFilterSubmit={this.handleFilterSubmit}
          initialValues={this.state.initialValues}
          filters={filters}
          handleRemoveFilter={this.handleRemoveFilter}
          agreementsCountByStatus={agreementsCountByStatus}
          recipientsSelect={recipientsSelect}
          filtersFromForm={filtersFromForm}
        />
        <br />
        {!isFetching ? (
          <DocumentsTable
            openDetailsPage={this.openDetailsPage}
            handleSort={this.handleSort}
            order={this.state.order}
            history={this.props.history}
            downloadFormatter={this.downloadFormatter}
            handlePageChange={this.handlePageChange}
            records={records}
            numberOfRecords={numberOfRecords}
            filters={filters}
            openPublishModalFromList={this.props.openPublishModalFromList}
            handleDelete={this.props.handleDelete}
            onCopied={this.onCopied}
            changeCopyHoverTrue={this.changeCopyHoverTrue}
            changeCopyHoverFalse={this.changeCopyHoverFalse}
            copyHoverState={this.state.copyHover}
            copiedState={this.state.copied}
            settings={settings}
            activeDelivery={activeDelivery}
          />
        ) : (
          <PageLoader />
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  agreement,
  config,
  category,
  form,
  settings,
  recipient,
}) => ({
  records: agreement.records,
  agreementsCountByStatus: agreement.agreementsCountByStatus.reduce(
    (obj, item) => {
      obj[item.agreementStatus] = item.agreementCount;
      return obj;
    },
    {},
  ),
  isFetching: agreement.isFetching,
  numberOfRecords: agreement.numberOfRecords,
  isDownloading: agreement.isDownloading,
  customer: config.customer,
  limit: config.limit,
  form: form,
  filtersFromForm: form.filterRemoteDocumentTableForm?.values,
  enablePrivate: config.enablePrivate,
  categories: category.records.map((v) => ({ label: v.path, value: v.path })),
  initialValues: {},
  settings: settings.settings,
  activeDelivery: config.activeDelivery,
  recipientsSelect: recipient.records.reduce((arr, value) => {
    arr.push({
      value: value.externalId,
      label: `${value.firstName} ${value.lastName}`,
    });

    return arr;
  }, []),
});

const mapDispatchToProps = (dispatch) => ({
  load: (filters) => {
    return dispatch(
      requestList({
        ...filters,
      }),
    );
  },
  loadCategories: () => dispatch(requestCategoriesList({ isActive: true })),
  applyFilters: (history, filters) => applyFiltersAction(history, filters),
  loadRecipients: () => dispatch(requestRecipientsList({ isActive: true })),
  fileDownload: (address, jobId) => dispatch(requestDownload(address, jobId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DocumentTableRemoteSignContainer),
);
