import React, { Component } from 'react';
import { Field, formValueSelector, reduxForm, change } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Form as FormModule, Utils, Grid, Layout } from 'billon-ui';
import { withRouter } from 'react-router-dom';
import { TAURON_DOCUMENT_TYPE } from '../../../../constraints';

// Actions
import { requestPublish } from '../../actions';

import { formValidator } from './validators';

// Components
import { PublishingDocument, PublishingErrorDetails } from '../../components';

const { Content: ContentModule } = Layout;
const { ErrorContent } = ContentModule;
const { Form, FormGroup, TextField, UploadField } = FormModule;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;
const { Row, Col } = Grid;

const F = styled(Form)`
  h2 {
    font-size: 2rem;
    margin-bottom: 2.5rem;
    margin-top: -3.5rem;
    width: calc(100% - 35px);
  }
`;

const DropzonePlaceholder = styled.div`
  width: 100%;
  border: 1px dashed ${(props) => props.theme.palette.grayLighter};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizeSm};
  line-height: 2;
  height: 16rem;
  text-align: center;

  &:focus {
    outline: 0;
  }
`;

const H3 = styled.h3`
  font-size: 1.25rem;
  line-height: 1.25rem;
`;

const EC = styled(Row)`
  margin-top: 4rem;
`;

class CreateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      publicationError: false,
      publicationErrorDetails: null,
    };

    this.handlePublish = this.handlePublish.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.reset = this.reset.bind(this);
  }

  async handlePublish(values) {
    const { onFinished, publishDocument } = this.props;
    return new Promise((resolve, reject) => {
      publishDocument(
        {
          ...values,
          type: TAURON_DOCUMENT_TYPE.AGREEMENT,
        },
        {
          resolve,
          reject,
        },
      );
    })
      .then(() => onFinished())
      .catch((err) => {
        if (err.response.error) {
          this.setState({ publicationError: true });
        }

        if (err.response.errors) {
          this.setState({ publicationErrorDetails: err.response.errors });
        }

        return false;
      });
  }

  nextStep() {
    this.setState(
      {
        step: this.state.step + 1,
      },
      () => {
        if (this.state.step === 2) {
          const { file, setTitle } = this.props;

          const temp = file[0].dataUrl.split('base64,');
          const content = atob(temp[1]);

          const match = content.match(
            /<TD[^>]+>Nr P.*atnika<BR><B>([^<]*)<\/B><\/TD>/,
          );

          if (match && match.length === 2) {
            setTitle(match[1]);
          }
        }
      },
    );
  }

  previousStep() {
    if (this.state.step > 1) {
      this.setState({
        step: this.state.step - 1,
      });
    }
  }

  reset() {
    this.setState({
      step: 1,
      publicationError: false,
    });
  }

  render() {
    const { handleSubmit, isSaving } = this.props;

    const { step, publicationError, publicationErrorDetails } = this.state;

    if (publicationError) {
      return (
        <>
          <ErrorContent>
            {publicationErrorDetails && (
              <PublishingErrorDetails
                status={publicationErrorDetails.publicationStatus}
                details={
                  publicationErrorDetails.details
                    ? JSON.parse(publicationErrorDetails.details)
                    : {}
                }
              />
            )}
          </ErrorContent>
          <EC>
            <Col md={{ size: 6, offset: 3 }}>
              <Button type="button" onClick={this.reset} size="lg" block>
                <FormattedMessage id="Try again" defaultMessage="Try again" />
              </Button>
            </Col>
          </EC>
        </>
      );
    }

    if (isSaving) {
      return <PublishingDocument />;
    }

    let submitButton;
    switch (step) {
      case 1:
        submitButton = (
          <Col md={{ size: 6, offset: 3 }}>
            <Button
              type="button"
              onClick={handleSubmit(this.nextStep)}
              size="lg"
              block
            >
              <FormattedMessage
                id="Submit document"
                defaultMessage="Submit document"
              />
            </Button>
          </Col>
        );
        break;
      case 2:
        submitButton = (
          <>
            <Col md={{ size: 6 }}>
              <Button
                onClick={() => this.previousStep()}
                size="lg"
                block
                color="link"
                type="button"
              >
                <FormattedMessage id="Edit" defaultMessage="Edit" />
              </Button>
            </Col>
            <Col md={{ size: 6 }}>
              <Button
                type="button"
                onClick={handleSubmit(this.handlePublish)}
                size="lg"
                block
              >
                <FormattedMessage
                  id="Publish document"
                  defaultMessage="Publish document"
                />
              </Button>
            </Col>
          </>
        );
        break;
      default:
        break;
    }

    return (
      <F>
        <Row>
          <Col xl={6} lg={6} md={6}>
            <FormGroup>
              <Field
                name="publishedBy"
                component={TextField}
                label={
                  <FormattedMessage
                    id="Published by"
                    defaultMessage="Published by"
                  />
                }
                floatLabel
                disabled
              />
            </FormGroup>
            {step > 1 && (
              <FormGroup>
                <Field
                  name="title"
                  component={TextField}
                  label={
                    <FormattedMessage
                      id="Payer number"
                      defaultMessage="Payer number"
                    />
                  }
                  floatLabel
                  disabled
                />
              </FormGroup>
            )}
          </Col>
          <Col xl={6} lg={6} md={6}>
            <FormGroup>
              <Field
                name="file"
                component={UploadField}
                accept={['text/html']}
                hideDropzone={step === 2}
                disabled={step === 2}
                placeholder={(props) => (
                  <DropzonePlaceholder {...props}>
                    <H3>
                      <FormattedMessage
                        id="Document2"
                        defaultMessage="Document"
                      />
                    </H3>
                    <div>
                      <FormattedMessage
                        id="Drag and drop here HTML max 2mb"
                        defaultMessage="Drag and drop here HTML max 2mb"
                      />
                    </div>
                    <div>
                      <FormattedMessage id="or" defaultMessage="or" />
                    </div>
                    <Button color="link" type="button">
                      <FormattedMessage
                        id="Select from disk"
                        defaultMessage="Select from disk"
                      />
                    </Button>
                  </DropzonePlaceholder>
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="form-submit">{submitButton}</Row>
      </F>
    );
  }
}

CreateForm.propTypes = {
  handleSubmit: PropTypes.func,
  publishDocument: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onFinished: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  setTitle: PropTypes.func.isRequired,

  file: PropTypes.object,
};

const mapStateToProps = ({ document, ...state }) => {
  const selector = formValueSelector('saveDocumentForm');

  return {
    file: selector(state, 'file'),
    isSaving: document.isSaving,
  };
};

const mapDispatchToProps = (dispatch) => ({
  publishDocument: (values, meta) => dispatch(requestPublish(values, meta)),
  setTitle: (title) => dispatch(change('saveDocumentForm', 'title', title)),
});

export default withRouter(
  reduxForm({
    form: 'saveDocumentForm',
    validate: formValidator,
  })(connect(mapStateToProps, mapDispatchToProps)(CreateForm)),
);
