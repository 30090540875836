import { PUBLICATION_MODES } from 'js/constraints';

export const parsePublishRequestData = (
  { title, identity, validSince, validUntil, category, contactDetails, documentFile, ...rest },
  publicationMode,
  documentBlockchainAddress,
) => ({
  ...rest,
  publicationMode,
  previousDocumentBlockchainAddress:
    publicationMode !== PUBLICATION_MODES.NEW
      ? documentBlockchainAddress
      : undefined,
  title,
  identity: {
    id: identity.value,
  },
  validSince: validSince,
  validUntil: validUntil,
  category: {
    id: category.value,
  },
  contactDetails,
  documentFile,
});
