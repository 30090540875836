import React from 'react';
import PropTypes from 'prop-types';
import { Table as TableModule } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import {
  CUSTOMER,
  PUBLICATION_MODES,
  DOCUMENT_STATUS,
} from '../../../../constraints';
import * as formatters from '../../../../formatters';
import {
  SecondaryBadge,
  SuccessBadge,
  SecondaryDarkerBadge,
  FailureBadge,
} from 'js/ui/Badge';
import { PublicationWithToolTipFormatter } from 'js/modules/Documents/containers/CommonDocumentTable/statusFormatter';
import * as Styled from './styled';
import { useHistory } from 'react-router-dom';
import useFeatureEnabled from '../../../Config/hooks/useFeatureEnabled';
import { PreviewDownloadButton } from '../PreviewDownloadButton';

const { Table, TableColumn } = TableModule;

const DocumentHistory = ({ historyRecords, currentId, title, customer }) => {
  const { featureEnabled } = useFeatureEnabled();

  const history = useHistory();

  const onClickRow = (record) => {
    if (record.documentBlockchainAddress !== currentId)
      history.push(`/document/${record.documentBlockchainAddress}`);
  };

  const formatRow = (row) => {
    return row.documentBlockchainAddress === currentId ? 'current-row' : null;
  };

  const badgeFormatter = (value, row) => {
    return (
      <>
        {value}
        {row.publicationMode === PUBLICATION_MODES.CORRECTED &&
          row.status === DOCUMENT_STATUS.CURRENT && (
            <SecondaryBadge margin={'0 0 0 15px'}>
              <FormattedMessage id="Erratum" defaultMessage="Erratum" />
            </SecondaryBadge>
          )}
        {row.publicationMode === PUBLICATION_MODES.UPDATED && (
          <SuccessBadge margin={'0 0 0 15px'}>
            <FormattedMessage id="New version" defaultMessage="New version" />
          </SuccessBadge>
        )}
        {row.publicationMode === PUBLICATION_MODES.INCIDENT &&
          row.status === DOCUMENT_STATUS.CURRENT && (
            <FailureBadge margin={'0 0 0 15px'}>
              <FormattedMessage id="Incident" defaultMessage="Incident" />
            </FailureBadge>
          )}
        {row.status === DOCUMENT_STATUS.DEPRECATED && (
          <SecondaryDarkerBadge margin={'0 0 0 15px'}>
            <FormattedMessage
              id="Previous Version"
              defaultMessage="Previous Version"
            />
          </SecondaryDarkerBadge>
        )}
      </>
    );
  };

  if (!historyRecords.length) {
    return null;
  }

  return (
    <Styled.HistoryContainer>
      <h3>
        <FormattedMessage id="History" defaultMessage="History" />
      </h3>
      <Table
        responsive
        tableData={historyRecords}
        onRowClick={onClickRow}
        formatRow={formatRow}
      >
        <TableColumn
          fieldName="publicationDate"
          formatter={formatters.momentFormatterWithoutTime}
        >
          <FormattedMessage
            id="Publication date"
            defaultMessage="Publication date"
          />
        </TableColumn>
        <TableColumn fieldName="title" formatter={badgeFormatter}>
          {featureEnabled(null, [CUSTOMER.TAURON, CUSTOMER.DEFAULT]) && (
            <FormattedMessage id="Title" defaultMessage="Title" />
          )}
          {featureEnabled(null, [CUSTOMER.DIPLOMA]) && (
            <FormattedMessage
              id="Diploma number"
              defaultMessage="Diploma number"
            />
          )}
        </TableColumn>
        <TableColumn fieldName="documentBlockchainAddress">
          <FormattedMessage
            id="Blockchain address"
            defaultMessage="Blockchain address"
          />
        </TableColumn>
        {customer !== CUSTOMER.DIPLOMA && (
          <TableColumn fieldName="versionName">
            <FormattedMessage
              id="Document version"
              defaultMessage="Document version"
            />
          </TableColumn>
        )}
        <TableColumn
          fieldName="status"
          formatter={PublicationWithToolTipFormatter}
        >
          <FormattedMessage
            id="Publication status"
            defaultMessage="Publication status"
          />
        </TableColumn>

        <TableColumn
          className="text-center fixed-width"
          fieldName="Preview"
          formatter={(value, record) => (
            <PreviewDownloadButton record={record} isPreview />
          )}
        >
          <FormattedMessage id="Preview" defaultMessage="Preview" />
        </TableColumn>

        <TableColumn
          className="text-center fixed-width"
          fieldName="Download"
          formatter={(value, record) => (
            <PreviewDownloadButton record={record} />
          )}
        >
          <FormattedMessage id="Download" defaultMessage="Download" />
        </TableColumn>
      </Table>
    </Styled.HistoryContainer>
  );
};

DocumentHistory.propTypes = {
  historyRecords: PropTypes.array,
  history: PropTypes.object,
  currentId: PropTypes.string,
  customer: PropTypes.string.isRequired,
  fileDownload: PropTypes.func,
  isDownloading: PropTypes.array.isRequired,
};

export default DocumentHistory;
