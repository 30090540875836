import { Table as TableModule } from 'billon-ui';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import CommonTable, {
  CommonTableContext,
} from '../../../../components/CommonTable';
// Utils
import { CUSTOMER, DOCUMENT_TYPE } from '../../../../constraints';
import * as formatters from '../../../../formatters';

// Actions
import { requestDownload, requestList, requestListQuiet } from '../../actions';
import { mapStatuses, mapPublicationStatuses } from '../../helpers';
import FilterForm, { FilterFormFields } from '../FilterForm/FilterForm';
import { CommonDocumentTableDetails } from './CommonDocumentTableDetailsNew';
import { fileActionButtonFormatter, TC } from './components';
import useFilters from 'js/hooks/useFilters';

import { SortByColumns } from './sortByColumns';

import { requestList as requestCategoriesList } from '../../../Categories/actions';

import { Column } from 'js/components/CommonTable/helpers';

import { PublicationWithToolTipFormatter } from './statusFormatter';

const { TableColumn } = TableModule;

const CommonDocumentTable: React.FC<{
  handleCreate: (initialValues, callback, isPreparedToSign) => void;
  handleDelete: (id, callback) => void;
}> = ({ handleCreate, handleDelete }) => {
  const dispatch = useDispatch();
  const {
    categories,
    customer,
    enablePrivate,
    isDownloading,
    isFetching,
    isFetchingQuietly,
    limit,
    numberOfRecords,
    records,
  } = useSelector(({ document, config, category }: any) => ({
    categories: category.records.map((v) => ({ label: v.name, value: v.id })),
    customer: config.customer as CUSTOMER,
    enablePrivate: config.enablePrivate,
    isDownloading: document.isDownloading,
    isFetching: document.isFetching,
    isFetchingQuietly: document.isFetchingQuietly,
    limit: config.limit as number,
    numberOfRecords: document.numberOfRecords,
    records: document.records,
  }));
  const { filters: rawSearchParams } = useFilters();

  const load = useCallback(
    (searchParamsReload) =>
      dispatch(
        requestList({
          ...searchParamsReload,
        }),
      ),
    [dispatch],
  );

  const loadCategories = useCallback(
    () => dispatch(requestCategoriesList({})),
    [dispatch],
  );

  const loadQuiet = useCallback(
    (searchParamsReload) =>
      dispatch(
        requestListQuiet({
          ...searchParamsReload,
        }),
      ),
    [dispatch],
  );

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  const fileDownload = useCallback(
    (jobId?, returnOnlyBlob?: boolean, title?) =>
      dispatch(requestDownload(jobId, returnOnlyBlob, title)),
    [dispatch],
  );

  type DocumentTranslations = {
    title: string;
    addDocument: string;
  };

  let translations: {
    [CUSTOMER.DEFAULT]: DocumentTranslations;
    [CUSTOMER.DIPLOMA]: DocumentTranslations;
  } = {
    [CUSTOMER.DIPLOMA]: {
      title: 'Diploma list',
      addDocument: 'Publish diploma',
    },
    [CUSTOMER.DEFAULT]: {
      title: formatters.documentTypeFormatter(
        rawSearchParams.filters?.documentType || 'ALL',
      ),
      addDocument: 'Add document',
    },
  };

  const customerTranslations: DocumentTranslations =
    translations[customer] || translations[CUSTOMER.DEFAULT];

  const columns: Array<Column | false> = [
    {
      name: 'publicationDate',
      message: 'Publication date',
      formatter: formatters.momentFormatter,
      code: SortByColumns['publicationDate'],
      sortable: true,
    },
    {
      name: 'title',
      message: customer === CUSTOMER.DIPLOMA ? 'Diploma number' : 'Title',
      formatter: formatters.boundLengthFormatterLong,
      sortable: true,
    },
    {
      name: 'category',
      message: 'Category',
      formatter: formatters.categoryFormatter,
      code: SortByColumns['category'],
      sortable: true,
    },
    {
      name: 'validSince',
      message:
        customer === CUSTOMER.DIPLOMA ? 'Diploma issuance date' : 'Valid since',
      formatter: formatters.momentFormatterWithoutTime,
      sortable: true,
      code: SortByColumns['validSince'],
    },
    ...(customer === CUSTOMER.DIPLOMA
      ? []
      : [
          {
            name: 'validUntil',
            message: 'Valid until',
            formatter: formatters.momentFormatterWithoutTime,
            sortable: true,
            code: SortByColumns['validUntil'],
          },
        ]),
    // TODO: Uncomment once recipients are added
    // {
    //   name: 'recipients',
    //   message: 'Index number',
    //   formatter: formatters.indexNumberFormatter,
    // },
    {
      name: 'documentPublicationStatus',
      message: 'Publication status',
      formatter: PublicationWithToolTipFormatter,
    },
  ];

  return (
    <CommonTableContext.Provider
      value={{
        load,
        refresh: loadQuiet,
        isFetching,
        isFetchingQuietly,
        numberOfRecords,
        records,
      }}
    >
      <CommonTable
        limit={10}
        addButtonLabel={customerTranslations.addDocument}
        title={customerTranslations.title}
        columns={columns}
        detailsComponentRenderer={(record) => (
          <CommonDocumentTableDetails
            record={record}
            handleCreate={(initialValues, isPreparedToSign = false) =>
              handleCreate(initialValues, load, isPreparedToSign)
            }
            handleDelete={(id) => handleDelete(id, load)}
          />
        )}
        filterFormRenderer={(
          searchParams: FilterFormFields,
          closeFilters: () => void,
        ) => (
          <FilterForm
            initialValues={rawSearchParams}
            categories={categories}
            isDiploma={customer === CUSTOMER.DIPLOMA}
            closeFilters={closeFilters}
          />
        )}
        handleCreate={(initialValues, isPreparedToSign = false) =>
          handleCreate(initialValues, load, isPreparedToSign)
        }
      >
        <TableColumn
          className="text-center fixed-width"
          fieldName="Preview"
          formatter={fileActionButtonFormatter(
            isDownloading,
            fileDownload,
            true,
          )}
          renderCell={(row, props) => <TC row={row} {...props} />}
        >
          <FormattedMessage id="Preview" defaultMessage="Preview" />
        </TableColumn>
        <TableColumn
          className="text-center fixed-width"
          fieldName="jobId"
          formatter={fileActionButtonFormatter(isDownloading, fileDownload)}
          renderCell={(row, props) => <TC row={row} {...props} />}
        >
          <FormattedMessage id="Download" defaultMessage="Download" />
        </TableColumn>
      </CommonTable>
    </CommonTableContext.Provider>
  );
};

export default CommonDocumentTable;
