import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import useSettingsRequest from 'js/modules/Settings/hooks/useSettingsRequest';
import { Layout as LayoutModule, Utils } from 'billon-ui';
import { decodeJwtToken } from 'js/modules/Login/hooks';
import TwoStepPasswordSet from '../../components/TwoStepPasswordSet';
import * as Styled from './styled';

const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const SetFirstPasswordPage = () => {
  const { params } = useRouteMatch('/setPassword/:token?');
  const token = params?.token && `Bearer ${params.token}`;
  const { role } = decodeJwtToken(token);
  const { data: settings, isLoading } = useSettingsRequest({
    token: token,
  });

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <>
      <Styled.ContentContainerGrey fluid>
        <Styled.ContentRow>
          <Styled.ContentCol
            sm={{ size: 12, offset: 0 }}
            md={{ size: 9, offset: 0 }}
            lg={{ size: 5, offset: 0 }}
          >
            <TwoStepPasswordSet token={token} role={role} settings={settings} />
          </Styled.ContentCol>
        </Styled.ContentRow>
      </Styled.ContentContainerGrey>
    </>
  );
};

export default SetFirstPasswordPage;
