import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form as FormModule, Utils, Grid } from 'billon-ui';
import DataTab from './DataTab';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSchema } from './useSchema';
import * as Styled from './styled';
import {
  mapVALIDATION_USER_ERRORS_NAMES,
  mapVALIDATION_USER_ERRORS,
} from 'js/constraints';

const { Col } = Grid;
const { FormGroup } = FormModule;
const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;

const CreateForm = ({
  onSubmit,
  isSaving,
  initialValues,
  errors: backendErrors,
}) => {
  const schema = useSchema();

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    setError,
  } = useForm({
    defaultValues: {
      isActive: true,
      ...initialValues,
    },
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = (data) => {
    onSubmit(data);
  };

  useEffect(() => {
    if (backendErrors) {
      backendErrors &&
        Object.entries(backendErrors).map(([key, value]) => {
          setError(mapVALIDATION_USER_ERRORS_NAMES[key], {
            type: 'response',
            message: mapVALIDATION_USER_ERRORS[`${value}_${key}`],
          });
        });
    }
  }, [backendErrors]);

  return (
    <Col md={{ size: 8, offset: 2 }}>
      <Styled.Form onSubmit={handleSubmit(handleFormSubmit)}>
        <DataTab
          control={control}
          errors={errors}
          initialValues={initialValues}
        />
        <FormGroup>
          {isSaving || isSubmitting ? (
            <ButtonLoader block size="lg" />
          ) : (
            <Button type="submit" size="lg" block>
              <FormattedMessage id="Save" defaultMessage="Save" />
            </Button>
          )}
        </FormGroup>
      </Styled.Form>
    </Col>
  );
};

CreateForm.propTypes = {
  onSubmit: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  errors: PropTypes.object,
};

export default CreateForm;
