import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { screens as HomePageScreens } from '../../modules/HomePage';
import * as UserScreens from 'js/modules/UsersNew/screens';
import appRoutes from 'js/app-routes';

const { HelpPage } = HomePageScreens;
const { SetObsoletePassword } = UserScreens;

const PasswordlessRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route
          path={appRoutes.PASSWORD_OBSOLETE_SET}
          component={SetObsoletePassword}
        />
        <Route path={appRoutes.SUPPORT} component={HelpPage} />
        <Route path="/" component={SetObsoletePassword} />
      </Switch>
    </Router>
  );
};

export default PasswordlessRoutes;
