import { Grid, Modal as ModalModule, Utils } from 'billon-ui';

import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BlockchainVisual } from '../../../../components';
import {
  CUSTOMER,
  PUBLICATION_MODES,
  DOCUMENT_STATUS,
} from '../../../../constraints';
import {
  breakWordsFormatter,
  momentFormatter,
  momentFormatterWithoutTime,
} from '../../../../formatters';
import {
  requestDownload as fileDownloadAction,
  requestDownloadRecipientsFile as fileDownloadRecipientsFileAction,
  requestForgetDocument,
  requestResendAuthorizationCodes,
} from '../../actions';
import { mapStatuses } from '../../helpers';
import {
  CopiedInfo,
  DetailsCol,
  MyButton,
  HeaderRow,
  LineHeaderRow,
  OneThirdColBtn,
} from './components';
import { DocumentData } from './types';

import {
  SecondaryBadge,
  SuccessBadge,
  SecondaryDarkerBadge,
} from 'js/ui/Badge';

import { downloadQrCode } from './utils';

import { publishedByFormatter } from 'js/modules/Users/formatters';

import NotificationHistoryModal from '../../components/NotificationHistoryModal';
import NotificationHistory from '../NotificationHistory';

import UpdateModal from '../../components/UpdateModal';

import { UpdateForm } from '../UpdateForm';

import { ExportableErrorContent } from 'js/components/ExportableErrorContent';
import { ErrorModal } from 'js/components/ErrorModal';

import { SuccessModal as CustomSuccessModal } from 'js/components/SuccessModal';
import CustomToolTip from 'js/ui/CustomToolTip';

// Hooks
import { useResendAuthorizationCode } from './hooks/useResendAuthorizationCode';
import { PublishDocumentModal } from 'js/modules/DocumentsNew/components';

const { Button: ButtonModule, Icon, Text } = Utils;
const { BackButton, Button } = ButtonModule;
const { Row, Col } = Grid;
const { SuccessModal, ConfirmModal } = ModalModule;

const DocumentDetails: React.FC<{
  document: DocumentData;
  onBackClick;
  publisherName;
  customer;
  backButtonLabel;
  documentPreviewUrl;
}> = ({
  document,
  onBackClick,
  publisherName,
  customer,
  backButtonLabel,
  documentPreviewUrl,
}) => {
  const history = useHistory();
  const {
    isDownloading,
    isDownloadingRecipientsList,
    isForgetting,
    transferedBy,
    isResendingAuthorizationCodes,
  } = useSelector(({ document, user }: any) => {
    const transferUser = user.records.find(
      (v) => v.login === document.singleRecord.publishedBy,
    );
    return {
      isDownloading: document.isDownloading,
      isDownloadingRecipientsList: document.isDownloadingRecipientsList,
      isResendingAuthorizationCodes: document.isResendingAuthorizationCodes,
      isForgetting: document.isForgetting,
      transferedBy: transferUser
        ? `${transferUser.firstName} ${transferUser.lastName}`
        : 'n/a',
    };
  });

  const {
    mutate: resendAuthorizationCode,
    isLoading: isResendingAuthorizationCode,
  } = useResendAuthorizationCode(document.documentBlockchainAddress);

  const [copied, setcopied] = useState(false);
  const [copyHover, setcopyHover] = useState(false);
  const [
    resendAuthorizationCodesModalOpened,
    setresendAuthorizationCodesModalOpened,
  ] = useState(false);
  const [
    resendAuthorizationCodesSuccessModalOpened,
    setresendAuthorizationCodesSuccessModalOpened,
  ] = useState(false);
  const [forgetDocumentModalOpened, setforgetDocumentModalOpened] =
    useState(false);
  const [
    forgetDocumentSuccessModalOpened,
    setforgetDocumentSuccessModalOpened,
  ] = useState(false);
  const [callbackBlockchainAddress, setCallbackBlockchainAddress] =
    useState('');

  const [notificationHistoryModalOpened, setNotificationHistoryModalOpened] =
    useState(false);

  const [updateModalOpened, setUpdateModalOpened] = useState(false);
  const [currentUpdateMode, setCurrentUpdateMode] = useState(
    PUBLICATION_MODES.UPDATED,
  );
  const [updateErrorModalOpened, setUpdateErrorModalOpened] = useState(false);
  const [actionErrorStatusCode, setActionErrorStatusCode] = useState(null);
  const [actionErrorLabel, setActionErrorLabel] = useState(null);

  const [updateSuccessModalOpened, setUpdateSuccessModalOpened] =
    useState(false);
  const [actionSuccessLabel, setActionSuccessLabel] = useState(null);

  const dispatch = useDispatch();
  const fileDownload = (id: string, title: string, returnOnlyBlob?: boolean) =>
    dispatch(fileDownloadAction(id, returnOnlyBlob, title));
  const fileDownloadRecipientsFile = (id: string) =>
    dispatch(fileDownloadRecipientsFileAction(id));
  const resendAuthorizationCodes = (blockchainAddress: string) =>
    dispatch(requestResendAuthorizationCodes(blockchainAddress));
  const forgetDocument = (blockchainAddress: string) =>
    dispatch(requestForgetDocument(blockchainAddress));

  const onCopied = () => {
    setcopied(true);

    setTimeout(() => setcopied(false), 2000);
  };

  const handleResendAuthorizationCodes = (blockchainAddress) => {
    setresendAuthorizationCodesModalOpened(true);
    setCallbackBlockchainAddress(blockchainAddress);
  };

  const handleResendAuthorizationCodesFinished = () => {
    resendAuthorizationCodes(callbackBlockchainAddress);

    setresendAuthorizationCodesModalOpened(false);
    setresendAuthorizationCodesSuccessModalOpened(true);
  };

  const handleCancelResendAuthorizationCodes = () => {
    setresendAuthorizationCodesSuccessModalOpened(false);
    setresendAuthorizationCodesModalOpened(false);
  };

  const handleForgetDocument = (blockchainAddress) => {
    setforgetDocumentModalOpened(true);
    setCallbackBlockchainAddress(blockchainAddress);
  };

  const handleForgetDocumentFinished = () => {
    forgetDocument(callbackBlockchainAddress);

    setforgetDocumentModalOpened(false);
    setforgetDocumentSuccessModalOpened(true);
  };

  const handleCancelForgetDocument = () => {
    setforgetDocumentSuccessModalOpened(false);
    setforgetDocumentModalOpened(false);
  };

  const handleForgetDocumentSuccessFinished = () => {
    history.push('/documents/{"type":"PRIVATE"}');
  };

  const handleNotificationHistoryModalOpen = () => {
    setNotificationHistoryModalOpened(true);
    history.push(`/document/${document.jobId}/notificationHistory`);
  };

  const handleNotificationHistoryModalClose = () => {
    setNotificationHistoryModalOpened(false);
    history.push(`/document/${document.jobId}`);
  };

  const handleErratumClick = () => {
    handUpdateModalOpen(PUBLICATION_MODES.CORRECTED);
  };

  const handleIncidentClick = () => {
    handUpdateModalOpen(PUBLICATION_MODES.INCIDENT);
  };

  const handleNevVersionClick = () => {
    handUpdateModalOpen(PUBLICATION_MODES.UPDATED);
  };

  const handUpdateModalOpen = (mode) => {
    setUpdateModalOpened(true);
    setCurrentUpdateMode(mode);
    history.push(`/document/${document.jobId}/update`);
  };

  const handUpdateModalClose = () => {
    setUpdateModalOpened(false);
    history.push(`/document/${document.jobId}`);
  };

  const handleUpdateFailure = (errorLabel: any, statusCode: any) => {
    setActionErrorStatusCode(statusCode);
    setActionErrorLabel(errorLabel);
    handUpdateModalClose();
    handleUpdateFailureModalOpen();
  };

  const handleUpdateFailureModalOpen = () => {
    setUpdateErrorModalOpened(true);
  };

  const handleUpdateFailureModalClose = () => {
    setUpdateErrorModalOpened(false);
  };

  const handleUpdateSuccessModalOpen = () => {
    setUpdateSuccessModalOpened(true);
  };

  const handleUpdateSuccessModalClose = () => {
    setUpdateSuccessModalOpened(false);
  };

  const handleUpdateModalSuccess = (label: any) => {
    setActionSuccessLabel(label);
    handUpdateModalClose();
    handleUpdateSuccessModalOpen();
  };

  const badgeFormatter = () => (
    <>
      {document.errata && (
        <SecondaryBadge margin={'0 0 0 15px'}>
          <FormattedMessage id="Erratum" defaultMessage="Erratum" />
        </SecondaryBadge>
      )}
      {document.publicationMode === PUBLICATION_MODES.UPDATED && (
        <SuccessBadge margin={'0 0 0 15px'}>
          <FormattedMessage id="New version" defaultMessage="New version" />
        </SuccessBadge>
      )}
      {document.status === DOCUMENT_STATUS.DEPRECATED && (
        <SecondaryDarkerBadge margin={'0 0 0 15px'}>
          <FormattedMessage
            id="Previous Version"
            defaultMessage="Previous Version"
          />
        </SecondaryDarkerBadge>
      )}
    </>
  );

  if (!document) {
    return null;
  }

  const enabledDetailsCol1: Array<{
    title: string;
    data: any;
    enabled: boolean;
  }> = [
    {
      title: 'Publication date',
      data: momentFormatter(document.publicationDate),
      enabled: [
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ].includes(customer),
    },
    {
      title: 'Category',
      data: document.category.fullPath,
      enabled: [CUSTOMER.DIPLOMA].includes(customer),
    },
    {
      title: 'Publication status',
      data: mapStatuses(document.status),
      enabled: [
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ].includes(customer),
    },
    {
      title: 'Published by',
      data: publisherName ? publisherName : '---',
      enabled: [
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ].includes(customer),
    },
    {
      title: 'Transfer by',
      data: document?.publishedBy ? publishedByFormatter('', document) : '',
      enabled: [
        CUSTOMER.DIPLOMA,
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ].includes(customer),
    },
  ];

  const formatStudent = (identity) => (
    <div>
      {`${identity.firstName} ${identity.lastName}`}
      <br />
      {identity.phoneNumber}
      <br />
      {identity.email}
    </div>
  );

  const enabledDetailsCol2: Array<{
    title: string;
    data: string | JSX.Element | null;
    enabled: boolean;
  }> = [
    {
      title: 'Document version',
      data: document.versionName,
      enabled: [CUSTOMER.DEFAULT].includes(customer),
    },
    {
      title: 'Retain until',
      data: momentFormatterWithoutTime(document.retainUntil),
      enabled: [
        CUSTOMER.DEFAULT,
        CUSTOMER.TAURON,
        CUSTOMER.TAURON_AGREEMENT,
      ].includes(customer),
    },
    {
      title:
        customer === CUSTOMER.DIPLOMA
          ? 'Date of issue of the diploma'
          : 'Is valid from',
      data: momentFormatterWithoutTime(document.validSince),
      enabled: [CUSTOMER.DIPLOMA, CUSTOMER.DEFAULT, CUSTOMER.TAURON].includes(
        customer,
      ),
    },
    {
      title: 'Valid until',
      data: momentFormatterWithoutTime(document.validUntil),
      enabled: [CUSTOMER.DEFAULT].includes(customer),
    },
    {
      title: 'Student',
      data: formatStudent(document.identity),
      enabled: [CUSTOMER.DIPLOMA].includes(customer),
    },
    {
      title: 'Index number',
      data: document.identity.documentNumber,
      enabled: [CUSTOMER.DIPLOMA].includes(customer),
    },
  ];

  return (
    <>
      <Row margin={0}>
        <Col xl={9}>
          <Row margin={'0 0 20px 0 '}>
            <BackButton secondary onClick={onBackClick}>
              {backButtonLabel}
            </BackButton>
          </Row>
          <Row margin={0}>
            <HeaderRow>
              <Text
                as={'h1'}
                fontSize={'20px'}
                margin={'0 0 0 0'}
                fontWeight={700}
              >
                {document.title}
              </Text>
              {badgeFormatter()}
            </HeaderRow>
          </Row>
          <Row>
            <Col lg={6}>
              <Text fontSize={'18px'} margin={'0 0 11px 0'} fontWeight={700}>
                <FormattedMessage
                  id="Document blockchain address"
                  defaultMessage="Document blockchain address"
                />
                <CopyToClipboard
                  text={document.documentBlockchainAddress}
                  onCopy={onCopied}
                >
                  <MyButton
                    onMouseOver={() => setcopyHover(true)}
                    onMouseOut={() => setcopyHover(false)}
                  >
                    <CopiedInfo show={copied}>
                      <FormattedMessage id="Copied" defaultMessage="Copied" />
                    </CopiedInfo>

                    <CopiedInfo show={copied ? false : copyHover}>
                      <FormattedMessage id="Copy" defaultMessage="Copy" />
                    </CopiedInfo>
                    <Icon name="copy" regular color="#7e7f7f" />
                  </MyButton>
                </CopyToClipboard>
              </Text>
              <Text
                fontSize={'13px'}
                fontWeight={500}
                padding={0}
                margin={'0 16px 40px 0'}
              >
                {breakWordsFormatter(document.documentBlockchainAddress)}
              </Text>

              <Text fontSize={'18px'} margin={'0 0 11px 0'} fontWeight={700}>
                <FormattedMessage
                  id="Manage document"
                  defaultMessage="Manage document"
                />
              </Text>

              <Row margin={'0 0 0 0'} padding={0}>
                <OneThirdColBtn
                  isLoading={isDownloading.includes(
                    document.documentBlockchainAddress,
                  )}
                  onClick={() => fileDownload(document.jobId, document.title)}
                >
                  <FormattedMessage
                    id="Download document"
                    defaultMessage="Download document"
                  />
                </OneThirdColBtn>
                <OneThirdColBtn
                  isLoading={isResendingAuthorizationCode}
                  onClick={resendAuthorizationCode}
                  outline
                >
                  <FormattedMessage
                    id="Send link and access code"
                    defaultMessage="Send link and access code"
                  />
                </OneThirdColBtn>
                {customer !== CUSTOMER.DIPLOMA && (
                  <OneThirdColBtn
                    isLoading={isDownloadingRecipientsList}
                    onClick={() =>
                      fileDownloadRecipientsFile(
                        document.documentBlockchainAddress,
                      )
                    }
                    outline
                  >
                    <FormattedMessage
                      id="Download the list of recipients"
                      defaultMessage="Download the list of recipients"
                    />
                  </OneThirdColBtn>
                )}
              </Row>

              <Row margin={'0 0 30px 0'} padding={0} lg={12}>
                {customer !== CUSTOMER.DIPLOMA && (
                  <OneThirdColBtn
                    onClick={handleNotificationHistoryModalOpen}
                    outline
                  >
                    <FormattedMessage
                      id="Notification history"
                      defaultMessage="Notification history"
                    />
                  </OneThirdColBtn>
                )}
                {/*<OneThirdColBtn onClick={() => {}} outline>*/}
                {/*  <FormattedMessage*/}
                {/*    id="Resend notification"*/}
                {/*    defaultMessage="Resend notification"*/}
                {/*  />*/}
                {/*</OneThirdColBtn>*/}
                {customer !== CUSTOMER.DIPLOMA && (
                  <OneThirdColBtn onClick={() => {}} outline>
                    <FormattedMessage
                      id="Update recipient list"
                      defaultMessage="Update recipient list"
                    />
                  </OneThirdColBtn>
                )}
              </Row>

              <LineHeaderRow>
                <Text fontSize={'13px'} margin={0} fontWeight={300}>
                  <FormattedMessage
                    id="Advanced features"
                    defaultMessage="Advanced features"
                  />
                </Text>
              </LineHeaderRow>

              <Row margin={0} padding={0}>
                {/*  { customer !== CUSTOMER.DIPLOMA && (*/}
                {/*    <OneThirdColBtn onClick={handleNevVersionClick} outline>*/}
                {/*      <FormattedMessage*/}
                {/*        id="New version"*/}
                {/*        defaultMessage="New version"*/}
                {/*      />*/}
                {/*    </OneThirdColBtn>*/}
                {/*  )}*/}
                <Col lg={4} paddingLeft={0} margin={'0 0 15px 0'}>
                  <CustomToolTip
                    id="ErratumTooltip"
                    trigger={
                      <Button onClick={handleErratumClick} outline block>
                        <FormattedMessage
                          id="Erratum"
                          defaultMessage="Erratum"
                        />
                      </Button>
                    }
                    delay={false}
                    placement="bottom-start"
                  >
                    <h4>
                      <FormattedMessage id="Erratum" defaultMessage="Erratum" />
                    </h4>
                    <FormattedMessage
                      id="If there are minor errors in the text of the document, which were noticed only after publication, it is possible to correct these errors by publishing Errata to the original document."
                      defaultMessage="If there are minor errors in the text of the document, which were noticed only after publication, it is possible to correct these errors by publishing Errata to the original document."
                    />
                  </CustomToolTip>
                </Col>
                <Col lg={4} paddingLeft={0} margin={'0 0 15px 0'}>
                  <CustomToolTip
                    id="IncidentTooltip"
                    trigger={
                      <Button onClick={handleIncidentClick} outline block>
                        <FormattedMessage
                          id="Incident"
                          defaultMessage="Incident"
                        />
                      </Button>
                    }
                    delay={false}
                    placement="auto-end"
                  >
                    <h4>
                      <FormattedMessage
                        id="Incident"
                        defaultMessage="Incident"
                      />
                    </h4>
                    <FormattedMessage
                      id="If you have mistakenly published a document to the wrong recipient revealing sensitive data, you can publish the correct document for that recipient."
                      defaultMessage="If you have mistakenly published a document to the wrong recipient revealing sensitive data, you can publish the correct document for that recipient."
                    />
                  </CustomToolTip>
                </Col>

                {/*  { customer !== CUSTOMER.DIPLOMA && (*/}
                {/*    <OneThirdColBtn*/}
                {/*      onClick={() =>*/}
                {/*        downloadQrCode(*/}
                {/*          documentPreviewUrl,*/}
                {/*          document.documentBlockchainAddress,*/}
                {/*        )*/}
                {/*      }*/}
                {/*      outline*/}
                {/*    >*/}
                {/*      <Icon name="qrcode" />*/}
                {/*      <FormattedMessage id="QR Code" defaultMessage="QR Code" />*/}
                {/*    </OneThirdColBtn>*/}
                {/*  )}*/}
              </Row>
            </Col>
            <Col lg={6}>
              <Row margin={0} md={12}>
                <Col>
                  <Text
                    as={'h1'}
                    fontSize={'18px'}
                    margin={'0 0 11px 0'}
                    fontWeight={700}
                  >
                    <FormattedMessage
                      id="Document details"
                      defaultMessage="Document details"
                    />
                    :
                  </Text>
                </Col>
              </Row>
              <Row margin={0} md={12}>
                <DetailsCol md={6}>
                  {enabledDetailsCol1
                    .filter((v) => v.enabled)
                    .map((v) => (
                      <Row margin={0}>
                        <Text
                          margin="0 10px 0 0"
                          fontSize="12px"
                          fontWeight={700}
                        >
                          <FormattedMessage
                            id={v.title}
                            defaultMessage={v.title}
                          />
                          :
                        </Text>
                        <Text fontSize="12px">{v.data}</Text>
                      </Row>
                    ))}
                </DetailsCol>
                <DetailsCol md={6}>
                  {enabledDetailsCol2
                    .filter((v) => v.enabled)
                    .map((v) => (
                      <Row margin={0}>
                        <Text
                          margin="0 10px 0 0"
                          fontSize="12px"
                          fontWeight={700}
                        >
                          <FormattedMessage
                            id={v.title}
                            defaultMessage={v.title}
                          />
                          :
                        </Text>
                        <Text fontSize="12px">{v.data}</Text>
                      </Row>
                    ))}
                </DetailsCol>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col
          xl={{ size: 3, offset: 0 }}
          lg={{ size: 6, offset: 3 }}
          sm={{ size: 8, offset: 2 }}
        >
          <BlockchainVisual remoteSignAnimation size="100%" fixed />
        </Col>
        {}
      </Row>

      <ConfirmModal
        isLoading={isResendingAuthorizationCodes}
        isOpen={resendAuthorizationCodesModalOpened}
        onAccept={handleResendAuthorizationCodesFinished}
        onCancel={handleCancelResendAuthorizationCodes}
      >
        <FormattedMessage
          id="Are You sure You want to resend link and authorization code for document?"
          defaultMessage="Are You sure You want to resend link and authorization code for document?"
        />
      </ConfirmModal>
      <SuccessModal
        isOpen={resendAuthorizationCodesSuccessModalOpened}
        title={
          <FormattedMessage
            id="Authorization codes sent"
            defaultMessage="Authorization codes sent"
          />
        }
        onClose={handleCancelResendAuthorizationCodes}
      >
        <FormattedMessage
          id="Authorization codes has been sent successfully."
          defaultMessage="Authorization codes has been sent successfully."
        />
      </SuccessModal>

      <ConfirmModal
        isLoading={isForgetting}
        isOpen={forgetDocumentModalOpened}
        onAccept={handleForgetDocumentFinished}
        onCancel={handleCancelForgetDocument}
      >
        <FormattedMessage
          id="Are You sure You want to forget this document?"
          defaultMessage="Are You sure You want to forget this document?"
        />
      </ConfirmModal>
      <SuccessModal
        isOpen={forgetDocumentSuccessModalOpened}
        onClose={handleForgetDocumentSuccessFinished}
      >
        <FormattedMessage
          id="Forgetting document started."
          defaultMessage="Forgetting document started."
        />
      </SuccessModal>

      <NotificationHistoryModal
        isOpen={notificationHistoryModalOpened}
        toggle={handleNotificationHistoryModalClose}
        onClose={handleNotificationHistoryModalClose}
        closeIconEnabled
      >
        <NotificationHistory
          jobId={document.jobId}
          documentBlockchainAddress={document.documentBlockchainAddress}
        />
      </NotificationHistoryModal>

      <UpdateModal
        isOpen={updateModalOpened && customer !== CUSTOMER.DIPLOMA}
        toggle={handUpdateModalClose}
        onClose={handUpdateModalClose}
        mode={currentUpdateMode}
      >
        <UpdateForm
          mode={currentUpdateMode}
          publisherName={publisherName}
          onClose={handUpdateModalClose}
          onSuccess={handleUpdateModalSuccess}
          onError={handleUpdateFailure}
        />
      </UpdateModal>

      <PublishDocumentModal
        isOpen={updateModalOpened && customer === CUSTOMER.DIPLOMA}
        toggle={handUpdateModalClose}
        document={document}
        isPreparedToSign={false}
        publicationMode={currentUpdateMode}
      />

      <ErrorModal
        isOpen={updateErrorModalOpened}
        toggle={handleUpdateFailureModalClose}
        onClose={handleUpdateFailureModalClose}
        closeIconEnabled={false}
      >
        <ExportableErrorContent
          headerTitleId={actionErrorLabel}
          statusCode={actionErrorStatusCode}
          jobId={document.jobId}
          title={document.title}
        />
      </ErrorModal>

      <CustomSuccessModal
        isOpen={updateSuccessModalOpened}
        toggle={handleUpdateSuccessModalClose}
        onClose={handleUpdateSuccessModalClose}
        closeIconEnabled={false}
      >
        {actionSuccessLabel && (
          <FormattedMessage
            id={actionSuccessLabel}
            defaultMessage={actionSuccessLabel}
          />
        )}
      </CustomSuccessModal>
    </>
  );
};

export default DocumentDetails;
