import { Grid, Utils } from 'billon-ui';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as Styled from './styled';
import config from 'js/config';

const { Row, Col } = Grid;
const { Icon, Button: ButtonModule } = Utils;
const { Button } = ButtonModule;
const { activeDelivery } = config;

const UserTableDetails = ({ record }) => {
  const history = useHistory();
  const [isCopied, setIsCopied] = useState(false);
  const [isCopyHover, setIsCopyHover] = useState(false);

  return (
    <Styled.DocumentInfo>
      <Row noGutters margin={0} lg={12}>
        <Col
          lg={activeDelivery ? 3 : 4}
          css="margin-top: 1rem; margin-bottom: 1rem"
        >
          <label className="large">
            <FormattedMessage id="ID" defaultMessage="ID" />
            {': '}
            <span className="blockchain-address-span">{record.id}</span>
            <CopyToClipboard text={record.id} onCopy={setIsCopied}>
              <Styled.MyButton
                onMouseOver={() => setIsCopyHover(true)}
                onMouseOut={() => setIsCopyHover(false)}
              >
                <Styled.CopiedInfo show={isCopied}>
                  <FormattedMessage id="Copied" defaultMessage="Copied" />
                </Styled.CopiedInfo>

                <Styled.CopiedInfo show={isCopied ? false : isCopyHover}>
                  <FormattedMessage id="Copy" defaultMessage="Copy" />
                </Styled.CopiedInfo>
                <Icon name="copy" regular color="#7e7f7f" />
              </Styled.MyButton>
            </CopyToClipboard>
          </label>
        </Col>
        <Col lg={activeDelivery ? 9 : 8}>
          <Styled.FlexEndRow lg={12}>
            <Col md={{ size: 4, offset: 4 }} css="margin-bottom: 0.3rem;">
              <Button
                outline
                onClick={() => history.push(`/user/${record.id}`)}
                block
              >
                <FormattedMessage
                  id="See details"
                  defaultMessage="See details"
                />
              </Button>
            </Col>
          </Styled.FlexEndRow>
        </Col>
      </Row>
    </Styled.DocumentInfo>
  );
};

export default UserTableDetails;
