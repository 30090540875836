import { Form as FormModule, Grid, Utils } from 'billon-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { CalendarField } from '../../../../containers';

const { FormGroup, TextField, SelectField, UploadField, CheckboxField } =
  FormModule;
const { Button: ButtonModule, Text } = Utils;
const { Button, IconButton } = ButtonModule;
const { Row, Col } = Grid;

export const DropzonePlaceholder = styled.div`
  width: 100%;
  border: 1px dashed ${(props) => props.theme.palette.grayLighter};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizeSm};
  line-height: 2;
  text-align: center;

  &:focus {
    outline: 0;
  }
`;

export const DropzonePlaceholder2 = styled.div`
  width: 100%;
  border: 1px dashed ${(props) => props.theme.palette.grayLighter};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 1rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizeSm};
  line-height: 2;
  text-align: center;

  &:focus {
    outline: 0;
  }
`;

export const SelectCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: ${({ user }) => (user ? '6px 0 0 0' : '4px 0 0 0')};

  button {
    font-size: ${({ user }) => (user ? '14px' : '16px')};
  }
`;
