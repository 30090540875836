import base from './base';
import { CUSTOMER, PUBLICATION_TYPE, TAURON_VERIFICATION_CODE_STATUSES } from '../constraints';

export default {
  ...base,
  backendUrl: 'https://apitauronpublication.uat.tdm.bybillon.com/application/v1.0',
  enablePrivate: true,
  enableSigning: false,
  manageRecipients: true,
  customer: CUSTOMER.TAURON,
  publicationType: PUBLICATION_TYPE.ONE_STEP,
  privateDocuments: {
    enableHeaderLink: false,
    enableNewVersion: true,
    enableErratum: true,
    enableIncident: false,
    enableDownloadQR: false,
  },
  publicDocuments: {
    enableHeaderLink: true,
    enableNewVersion: true,
    enableErratum: true,
    enableIncident: false,
    enableDownloadQR: false,
  },
  enableDocumentDetails: {
    publicationStartDate: true,
    publicationDate: true,
    categoryFullPath: false,
    status: true,
    publisherName: true,
    publishedBy: true,
    versionName: true,
    retainUntil: true,
    validSince: true,
    validUntil: true,
    identity: false,
    documentNumber: false,
  },
  SMS_CODE_STATUSES: TAURON_VERIFICATION_CODE_STATUSES,
};
