import React from 'react';
import apiRoutes from 'js/api-routes';
import { QueryKeys } from 'js/queryKeys';
import useFilters from 'js/hooks/useFilters';
import { useQueryWithLogout } from 'js/hooks/useQueryWithLogout';
import { getRequest } from 'js/helpers/apiClient';

const useSettingsRequest = (options) => {
  const token = options?.token;

  const { filters } = useFilters();

  return useQueryWithLogout(
    [QueryKeys.SETTINGS, filters],
    async () => {
      const { data } = await getRequest({
        url: apiRoutes.SETTINGS,
        params: filters,
        headers: {
          Authorization: token ? token : sessionStorage.getItem('token'),
        },
      });
      return data;
    },
    options,
  );
};

export default useSettingsRequest;
