import React from 'react';
import styled from 'styled-components';

export const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const TitleHeaderWrapper = styled.h2`
  margin-right: 1rem;
  margin-bottom: 0;
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-size: 20px;
  margin: 0 1rem 0 0;
  @media (max-width: 1024px) {
    align-self: flex-start;
    margin-bottom: 1rem;
  }
`;

export const SearchBarSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
  }
`;

export const SearchBarWrapper = styled.div`
  @media (max-width: 1024px) {
    align-self: flex-start;
    width: 100%;
  }
  .btn_search {
    svg {
      color: #c9d4de;
      font-size: 14px;
    }
  }
  .btn_clear {
    svg {
      color: #c9d4de;
      font-size: 10px;
    }
  }
  .search-bar {
    margin-bottom: 0;
    width: 336px;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    .form-control-content {
      .form-control {
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 12px;
      }
    }
  }
`;
