import moment from 'moment';
import { dateToRequestDataFormatter } from '../formatters';

export const parseDateRange = (dates) => {
  if (!dates?.[0] && !dates?.[1]) {
    return;
  }

  let from = dates?.[0] && dateToRequestDataFormatter(dates[0]);
  let to = dates?.[1] && dateToRequestDataFormatter(dates[1]);

  from = moment(dates[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
  to = moment(dates[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z';

  return {
    from,
    to,
  };
};
