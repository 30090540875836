import { Form as FormModule, Grid, Utils } from 'billon-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { CalendarField } from '../../../../containers';

const { FormGroup, TextField, SelectField, UploadField, CheckboxField } =
  FormModule;
const { Button: ButtonModule, Text } = Utils;
const { Button, IconButton } = ButtonModule;
const { Row, Col } = Grid;

const DropzonePlaceholder = styled.div`
  width: 100%;
  border: 1px dashed ${(props) => props.theme.palette.grayLighter};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizeSm};
  line-height: 2;
  text-align: center;

  &:focus {
    outline: 0;
  }
`;

const DropzonePlaceholder2 = styled.div`
  width: 100%;
  border: 1px dashed ${(props) => props.theme.palette.grayLighter};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 1rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizeSm};
  line-height: 2;
  text-align: center;

  &:focus {
    outline: 0;
  }
`;

const StyledSelectCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: ${({ user }) => (user ? '6px 0 0 0' : '4px 0 0 0')};

  button {
    font-size: ${({ user }) => (user ? '14px' : '16px')};
  }
`;

const CreateFormStep1 = (props) => {
  let datePart = (
    <div>
      <Text as="h6" fontSize="12px" fontWeight="600">
        <FormattedMessage id="Valid from to" defaultMessage="Valid from to" />
      </Text>
      <FormGroup>
        <Field
          component={CalendarField}
          showMonths={2}
          name="validFromTo"
          range
          enableTime={false}
          dateFormat="Y-m-d"
        />
      </FormGroup>
      <Text as="h6" fontSize="12px" fontWeight="600">
        <FormattedMessage id="Stored for" defaultMessage="Stored for" />
      </Text>
      <FormGroup>
        <Field
          component={SelectField}
          name="retainUntil"
          options={props.retainOptions}
        />
      </FormGroup>
    </div>
  );

  return (
    <>
      <Text as="h2" fontSize="32px">
        <FormattedMessage
          id="Step 1: Prepare document"
          defaultMessage="Step 1: Prepare document"
        />
      </Text>
      <Row>
        <Col xl={4} lg={4} md={4}>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Published by" defaultMessage="Published by" />
          </Text>
          <FormGroup>
            <Field name="publishedBy" component={TextField} disabled />
          </FormGroup>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Title" defaultMessage="Title" />
          </Text>
          <FormGroup>
            <Field
              name="title"
              component={TextField}
              disabled={props.initialValues.errata ? true : false}
            />
          </FormGroup>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Category" defaultMessage="Category" />
          </Text>
          <FormGroup>
            <Row margin={0}>
              <Col padding={0} xs={11}>
                <Field
                  component={SelectField}
                  name="categoryId"
                  options={props.categories}
                />
              </Col>
              <StyledSelectCol xs={1}>
                <IconButton
                  icon="folder-plus"
                  onClick={() =>
                    props.handleCreateCategory(props.loadCategories)
                  }
                />
              </StyledSelectCol>
            </Row>
          </FormGroup>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage
              id="Document version"
              defaultMessage="Document version"
            />
          </Text>
          <FormGroup>
            <Field
              name="versionName"
              component={TextField}
              disabled={props.initialValues.errata ? true : false}
            />
          </FormGroup>
        </Col>
        <Col xl={4} lg={4} md={4}>
          {datePart}
          {props.isPrivate && (
            <>
              <Text as="h6" fontSize="12px" fontWeight="600">
                <FormattedMessage id="Recipient" defaultMessage="Recipient" />
              </Text>
              <FormGroup>
                <Row margin={0}>
                  <Col padding={0} xs={11}>
                    <Field
                      component={SelectField}
                      name="recipient"
                      options={props.recipients}
                      disabled={props.initialValues.recipient ? true : false}
                    />
                  </Col>
                  <StyledSelectCol xs={1} user>
                    <IconButton
                      icon="user-plus"
                      onClick={() =>
                        props.handleCreateRecipient(props.loadRecipients)
                      }
                    />
                  </StyledSelectCol>
                </Row>
              </FormGroup>
            </>
          )}
          {props.isPrivate && props.enableSigning && (
            <FormGroup>
              <Field
                component={CheckboxField}
                name="needsSign"
                type="checkbox"
                label={
                  <FormattedMessage
                    id="Document needs to be signed"
                    defaultMessage="Document needs to be signed"
                  />
                }
              />
            </FormGroup>
          )}
        </Col>
        <Col xl={4} lg={4} md={4}>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Document2" defaultMessage="Document" />
          </Text>
          <FormGroup>
            <Field
              name="file"
              component={UploadField}
              accept={['application/pdf']}
              placeholder={(props) => (
                <DropzonePlaceholder {...props}>
                  <div>
                    <FormattedMessage
                      id="Drag and drop here PDF max 2MB"
                      defaultMessage="Drag and drop here PDF max 2MB"
                    />
                  </div>
                  <div>
                    <FormattedMessage id="or" defaultMessage="or" />
                  </div>
                  <Button outline type="button">
                    <FormattedMessage
                      id="Select from disk"
                      defaultMessage="Select from disk"
                    />
                  </Button>
                </DropzonePlaceholder>
              )}
            />
          </FormGroup>
          {!props.isPrivate && (
            <>
              <FormGroup>
                <Field
                  name="recipientsToNotify"
                  component={UploadField}
                  accept={[
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                  ]}
                  label={
                    <Text as="h6" fontSize="12px" fontWeight="600">
                      <FormattedMessage
                        id="Recipients to notify"
                        defaultMessage="Recipients to notify"
                      />
                    </Text>
                  }
                  placeholder={(props) => (
                    <DropzonePlaceholder2 {...props}>
                      <div>
                        <FormattedMessage
                          id="Drag and drop here XLS max 2mb"
                          defaultMessage="Drag and drop here XLS max 2mb"
                        />
                      </div>
                      <div>
                        <FormattedMessage id="or" defaultMessage="or" />
                      </div>
                      <Button outline type="button">
                        <FormattedMessage
                          id="Select from disk"
                          defaultMessage="Select from disk"
                        />
                      </Button>
                    </DropzonePlaceholder2>
                  )}
                />
              </FormGroup>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CreateFormStep1;
