import styled from 'styled-components';
import { Grid } from 'billon-ui';
const { Col } = Grid;

export const HistoryWrapper = styled(Col)`
  margin-top: ${({ activeDelivery }) => (activeDelivery ? '-3rem' : 0)};

  @media (max-width: 1199px) {
    margin-top: 0;
  }

  padding: 0;
`;
