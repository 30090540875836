import React from 'react';
import * as Styled from './styled';
import { FormattedMessage } from 'react-intl';

const SingleCustomCard = ({ titleId, children }) => {
  return (
    <Styled.Card>
      {titleId && (
        <Styled.Title>
          <FormattedMessage id={titleId} defaultMessage={titleId} />
        </Styled.Title>
      )}
      <Styled.CardBodyWrapper>{children}</Styled.CardBodyWrapper>
    </Styled.Card>
  );
};

export default SingleCustomCard;
