import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Styled from './styled';
import { Form as FormModule, Grid, Utils } from 'billon-ui';
import { FormFooter } from '../../../../../../components/FormFooter';
import { useValidateRecipients } from '../../../../../RecipientsNew/hooks/useValidateRecipients';
import * as yup from 'yup';
import {
  VALIDATION_ERRORS,
  RECIPIENT_ACTIONS,
  RECIPIENT_UPLOAD_ERROR_VALUES,
  MAP_RECIPIENT_UPLOAD_ERRORS,
} from '../../../../../../constraints';
import { UploadRecipientsField } from '../../../../../../components/UploadRecipientsField/UploadRecipientsField';
import { Link } from 'react-router-dom';
import {
  RECIPIENT_ERROR_CODES,
  RECIPIENT_VALIDATION_PURPOSE,
} from '../../../../../../constants';

const { FormGroup, Form } = FormModule;
const { Text, ErrorIcon, Button: ButtonModule } = Utils;
const { Button } = ButtonModule;
const { Row, Col } = Grid;

export const RecipientsFileForm = ({
  onSubmit,
  onBack,
  isLoading,
  defaultValues = {},
  validationPurpose,
  customTitle,
  mode,
  children,
}) => {
  const formMethods = useForm({
    defaultValues: {
      contactDetails: [],
      ...defaultValues,
    },
    resolver: yupResolver(
      yup.object({
        contactDetails: yup.array().length(1, VALIDATION_ERRORS.REQUIRED),
      }),
    ),
  });

  const { handleSubmit, watch } = formMethods;

  const { formatMessage } = useIntl();

  const {
    mutate: validateRecipients,
    isError,
    error,
    reset,
  } = useValidateRecipients({
    onSuccess: () => {
      const values = watch();
      onSubmit(values);
    },
  });

  const handleFormSubmit = (values) => {
    validateRecipients({
      data: {
        file: values.contactDetails,
        purpose: validationPurpose || RECIPIENT_VALIDATION_PURPOSE.PUBLISH,
      },
    });
  };

  const handleResetMutation = () => {
    reset();
  };

  const handleIgnoreErrors = () => {
    const values = watch();
    onSubmit(values);
  };

  if (isError) {
    const { statusCode, errors, validationErrors, success, total } =
      error.response.data;

    const ReasonsForFailureColSize =
      mode === RECIPIENT_ACTIONS.INSERT_LIST ? 6 : 12;

    const ReasonsForFailures = () => (
      <>
        {statusCode === RECIPIENT_ERROR_CODES.VALIDATION_ERROR &&
          validationErrors.map((singleError) => {
            let parity = { ID: null, SYSTEM_SOURCE: null };
            return (
              <div>
                <Text margin={0}>
                  <FormattedMessage id="In row" defaultMessage="In row" />
                  &nbsp;
                  {singleError.line}
                  &nbsp; : &nbsp;
                  {Object.entries(singleError.errorInfo).map(([key, value]) => {
                    const keyToMap =
                      MAP_RECIPIENT_UPLOAD_ERRORS[`${value}_${key}`];

                    if (value === RECIPIENT_UPLOAD_ERROR_VALUES.NON_UNIQUE) {
                      parity[`${key}`] = value;
                    }
                    if (
                      ![
                        RECIPIENT_UPLOAD_ERROR_VALUES.NEEDS_UPDATE,
                        RECIPIENT_UPLOAD_ERROR_VALUES.NON_UNIQUE,
                      ].includes(value) &&
                      keyToMap
                    )
                      return (
                        <span>
                          <FormattedMessage
                            id={keyToMap}
                            defaultMessage={keyToMap}
                          />
                          {'. '}
                        </span>
                      );
                  })}
                  {(parity.ID || parity.SYSTEM_SOURCE) && (
                    <span>
                      <FormattedMessage
                        id="Such a pair (ID and source system) already exists"
                        defaultMessage="Such a pair (ID and source system) already exists"
                      />
                      {'. '}
                    </span>
                  )}
                </Text>
              </div>
            );
          })}
        {statusCode === RECIPIENT_ERROR_CODES.FILE_ERROR &&
          errors.map((singleError) => (
            <div>
              {singleError.type === 'MISSING' && (
                <Text fontWeight={800} color="red" margin={0}>
                  <FormattedMessage
                    id="File is missing a column. Accepted values:"
                    defaultMessage="File is missing a column. Accepted values:"
                  />
                  &nbsp;
                  {singleError.acceptedValues.join(', ')}
                </Text>
              )}
            </div>
          ))}
        {statusCode === RECIPIENT_ERROR_CODES.INTERNAL_SERVER_ERROR && (
          <div>
            <Text margin={0}>
              <FormattedMessage
                id="Unexpected error."
                defaultMessage="Unexpected error."
              />
            </Text>
            <Link to="/support">
              <FormattedMessage
                id="Contact the support team"
                defaultMessage="Contact the support team"
              />
            </Link>
          </div>
        )}{' '}
      </>
    );

    const MappedRowsToUpdate =
      statusCode === RECIPIENT_ERROR_CODES.VALIDATION_ERROR &&
      mode === RECIPIENT_ACTIONS.INSERT_LIST &&
      validationErrors.filter((singleError) => {
        const needsUpdateEntries = Object.entries(singleError.errorInfo).filter(
          ([key, value]) =>
            value === RECIPIENT_UPLOAD_ERROR_VALUES.NEEDS_UPDATE,
        );
        return needsUpdateEntries?.length > 0;
      });

    const MergedRowsToUpdate = () => (
      <>
        {statusCode === RECIPIENT_ERROR_CODES.VALIDATION_ERROR &&
          mode === RECIPIENT_ACTIONS.INSERT_LIST &&
          MappedRowsToUpdate.map((singleError) => (
            <div>
              <Text margin={0}>
                {formatMessage({
                  id: 'Row number {n} will be updated',
                }).replace('{n}', singleError.line)}
              </Text>
            </div>
          ))}
      </>
    );

    return (
      <div>
        <Styled.FormHeader>
          <FormattedMessage
            id="There was an error in your recipients file"
            defaultMessage="There was an error in your recipients file"
          />
        </Styled.FormHeader>

        {statusCode === RECIPIENT_ERROR_CODES.VALIDATION_ERROR && (
          <Text>
            <FormattedMessage
              id="Correctly uploaded2"
              defaultMessage="Correctly uploaded2"
            />
            &nbsp;
            {success} <FormattedMessage id="of" defaultMessage="of" /> {total}
            &nbsp;
            <FormattedMessage id="recipients" defaultMessage="recipients" />.
          </Text>
        )}

        <Row>
          <Col md={ReasonsForFailureColSize}>
            <Styled.TextDanger fontWeight={800}>
              <FormattedMessage id="Reasons" defaultMessage="Reasons" />
              {':'}
            </Styled.TextDanger>

            {mode === RECIPIENT_ACTIONS.INSERT_LIST ? (
              <Styled.EditPanel>
                <ReasonsForFailures />
              </Styled.EditPanel>
            ) : (
              <ReasonsForFailures />
            )}
          </Col>
          {mode === RECIPIENT_ACTIONS.INSERT_LIST && (
            <Col md={6}>
              <Text fontWeight={800}>
                <FormattedMessage
                  id="Added to update"
                  defaultMessage="Added to update"
                />
                {':'}
              </Text>
              <Styled.EditPanel>
                <MergedRowsToUpdate />
              </Styled.EditPanel>
            </Col>
          )}
        </Row>
        {mode !== RECIPIENT_ACTIONS.INSERT_LIST && (
          <Styled.CenterRow>
            <ErrorIcon />
          </Styled.CenterRow>
        )}

        <Styled.CenterRow>
          <Col md={4} sm={4} xs={4}>
            <Button block type="button" outline onClick={handleResetMutation}>
              <FormattedMessage id="Fix errors" defaultMessage="Fix errors" />
            </Button>
          </Col>
          <Col md={4} sm={4} xs={4}>
            <Button block type="button" onClick={handleIgnoreErrors}>
              <FormattedMessage
                id="Ignore errors"
                defaultMessage="Ignore errors"
              />
            </Button>
          </Col>
        </Styled.CenterRow>
      </div>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Styled.FormHeader>
          {customTitle ? (
            customTitle
          ) : (
            <FormattedMessage
              id="Provide recipients of the notification"
              defaultMessage="Provide recipients of the notification"
            />
          )}
        </Styled.FormHeader>
        <br />
        <FormGroup>
          <UploadRecipientsField name="contactDetails" />
        </FormGroup>
        <FormFooter isLoading={isLoading} onBack={onBack} />
      </Form>
    </FormProvider>
  );
};
