import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CalendarField } from '../../containers';

export const ControlledCalendarField = ({
  name,
  disabled,
  placeholder,
  range,
  maxDate,
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <CalendarField
          input={field}
          meta={{
            touched: fieldState.invalid,
            error: errors?.[field.name]?.message,
          }}
          enableTime={false}
          dateFormat="Y-m-d"
          minDate={undefined}
          maxDate={maxDate}
          disabled={disabled}
          placeholder={placeholder}
          range={range}
        />
      )}
    />
  )
}
