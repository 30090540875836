import { combineEpics } from 'redux-observable';
import { TechnicalBreak } from 'billon-ui';

import { epics as AuthenticationEpics } from './ui/Authentication';

import { epics as DocumentEpics } from './modules/Documents';
import { epics as AgreementsEpics } from './modules/Agreements';
import { epics as CategoryEpics } from './modules/Categories';
import { epics as UserEpics } from './modules/Users';
import { epics as RecipientEpics } from './modules/Recipients';
import { epics as SettingsEpics } from './modules/Settings';
import { epics as RequestsEpics } from './modules/Requests';
import { epics as ReportsEpics } from './modules/Reports';

const { epics: TechnicalBreakEpics } = TechnicalBreak;

export default combineEpics(
  ...[
    ...TechnicalBreakEpics,
    ...ReportsEpics,
    ...DocumentEpics,
    ...AgreementsEpics,
    ...CategoryEpics,
    ...UserEpics,
    ...RecipientEpics,
    ...SettingsEpics,
    ...AuthenticationEpics,
    ...RequestsEpics,
  ],
);
