import React from 'react';

export const passwordStructureValidator = (value, minComplexity) => {
  if (!value) {
    return undefined;
  }

  const numbersRegex = new RegExp(`^(?=.*[0-9]).{1,}$`, 'g');
  const lowerRegex = new RegExp(`^(?=.*[a-z]).{1,}$`, 'g');
  const upperRegex = new RegExp(`^(?=.*[A-Z]).{1,}$`, 'g');
  const specialsRegex = new RegExp(`^(?=.*[^[A-Za-z0-9]).{1,}$`, 'g');
  const regexes = [numbersRegex, lowerRegex, upperRegex, specialsRegex];

  const testResult = regexes
    .map((tester) => tester.test(value))
    .filter((r) => r === true).length;

  return testResult >= minComplexity;
};
