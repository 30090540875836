import { useNotificationTemplates } from '../../Notifications/hooks/useNotificationTemplates';
import { useMemo } from 'react';

export const useCompileNotificationMessages = (emailContentInput, smsContentInput) => {
  const { data: templates, isLoading, error } = useNotificationTemplates({
    type: 'PUBLIC',
  });

  const emailPreviewValue = useMemo(() => {
    if(error || isLoading || !templates) {
      return '';
    }

    let compiledTemplate = templates.emailTemplate;

    (templates.attachments || []).forEach((attachment) => {
      compiledTemplate = compiledTemplate.replace(`cid:${attachment.name}`, `data:image/jpeg;charset=utf-8;base64,${attachment.source}`);
    })

    if(!emailContentInput || !emailContentInput.length) {
      return compiledTemplate;
    }

    return compiledTemplate.replace('{{USER_INPUT}}', emailContentInput);
  }, [error, isLoading, templates, emailContentInput])

  const smsContentPreviewValue = useMemo(() => {
    if(error || isLoading || !templates) {
      return '';
    }

    if(!smsContentInput || !smsContentInput.length) {
      return templates.smsTemplate;
    }

    return templates.smsTemplate.replace('{{USER_INPUT}}', smsContentInput);
  }, [error, isLoading, templates, smsContentInput]);

  return {
    templates,
    isLoading,
    error,
    emailPreviewValue,
    smsContentPreviewValue,
  }
}
