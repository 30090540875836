import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table as TableModule } from 'billon-ui';
import { FormattedMessage } from 'react-intl';

import * as formatters from '../../../../formatters';

const { Table, TableColumn } = TableModule;

const DocumentRecipients = ({ recipients, needsSign }) => {
  if (!recipients.length) {
    return null;
  }

  return (
    <Fragment>
      <h3>
        <FormattedMessage id="Recipients" defaultMessage="Recipients" />
      </h3>
      <Table tableData={recipients}>
        <TableColumn fieldName="phoneNumber">
          <FormattedMessage id="Phone Number" defaultMessage="Phone Number" />
        </TableColumn>
        <TableColumn fieldName="firstName">
          <FormattedMessage id="First Name" defaultMessage="First Name" />
        </TableColumn>
        <TableColumn fieldName="lastName">
          <FormattedMessage id="Last Name" defaultMessage="Last Name" />
        </TableColumn>
        <TableColumn
          fieldName="DocumentRecipient.readDate"
          formatter={formatters.momentFormatter}
        >
          <FormattedMessage id="Read Date" defaultMessage="Read Date" />
        </TableColumn>
        {needsSign && (
          <TableColumn
            fieldName="DocumentRecipient.signDate"
            formatter={formatters.momentFormatter}
          >
            <FormattedMessage id="Sign Date" defaultMessage="Sign Date" />
          </TableColumn>
        )}
      </Table>
    </Fragment>
  );
};

DocumentRecipients.propTypes = {
  recipients: PropTypes.array,
  needsSign: PropTypes.bool.isRequired,
};

export default DocumentRecipients;
