import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TypeVerificationCode from '../TypeVerificationCode';
import SetPasswordSuccess from '../SetPasswordSuccess';
import useSmsCodeRequest from 'js/modules/Settings/hooks/useSmsCodeRequest';
import PasswordForm from '../PasswordForm';
import SingleCustomCard from '../SingleCustomCard';
import config from 'js/config';
import { CUSTOMER } from 'js/constraints';
import { usePatchPassword } from '../../hooks/usePatchPassword';

const { customer } = config;

const TwoStepPasswordChange = ({ mode, role, settings }) => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [passwords, setPasswords] = useState(null);
  const [reasons, setReasons] = useState([]);

  const handlePatchPasswordSuccess = () => {
    setStep(3);
  };

  const handlePatchPasswordFailure = ({ response }) => {
    setReasons(response?.data?.reasons || []);
  };

  const { mutate: patchPassword } = usePatchPassword({
    onSuccess: handlePatchPasswordSuccess,
    onError: handlePatchPasswordFailure,
  });

  const handleOnSendSmsCodeSuccess = () => {
    setStep(step + 1);
  };

  const handleOnSendSmsCodeFailure = () => {
    setStep(step + 1);
  };

  const { mutate: sendSmsCode } = useSmsCodeRequest({
    onSuccess: handleOnSendSmsCodeSuccess,
    onError: handleOnSendSmsCodeFailure,
  });

  const handleOnSubmitCodeSuccess = () => {
    setStep(step + 1);
  };

  const handleOnValidatePasswordSuccess = (passwords) => {
    setPasswords(passwords);
    if (customer === CUSTOMER.TAURON) {
      patchPassword({
        passwordData: {
          oldPassword: passwords.oldPassword,
          password: passwords.password,
        },
      });
    } else {
      sendSmsCode();
      setStep(step + 1);
    }
  };

  const handleOnBack = () => {
    setStep(step - 1);
  };

  const handleOnClose = () => {
    history.push('/');
  };

  const formConfig = {
    mode,
    handleSuccess: handleOnValidatePasswordSuccess,
    passwordLabelId: 'New password',
    repeatPasswordLabelId: 'Repeat new password',
    submitMessageId: 'Change password',
  };

  return (
    <>
      {
        {
          1: (
            <SingleCustomCard titleId="Password change">
              <PasswordForm
                role={role}
                settings={settings}
                formConfig={formConfig}
                deliveredReasons={reasons}
              />
            </SingleCustomCard>
          ),
          2: (
            <TypeVerificationCode
              passwords={passwords}
              handleOnSubmitCodeSuccess={handleOnSubmitCodeSuccess}
              handleOnBack={handleOnBack}
            />
          ),
          3: (
            <SetPasswordSuccess
              onClose={handleOnClose}
              messageId={'Password successfully changed'}
            />
          ),
        }[step]
      }
    </>
  );
};

export default TwoStepPasswordChange;
