import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import UserInfo from '../../ui/UserInfo';
import { mapUSER_STATUSES } from '../../constraints';
import { Form as FormModule } from 'billon-ui';

const { FormGroup, SwitchField } = FormModule;

export const createdAtFormatter = (id, record) =>
  moment(record.createdAt).format('DD.MM.YYYY');

export const createdAtWithHourFormatter = (id, record) =>
  moment(record.createdAt).format('DD.MM.YYYY hh:mm:ss');

export const noFormatter = (value) => value;

export const roleFormatter = (id, record) => {
  return (
    <FormattedMessage
      id={`Role: ${record.role}`}
      defaultMessage={`Role: ${record.role}`}
    />
  );
};

export const userStatusFormatter = (value, record) => {
  return (
    <FormattedMessage
      id={mapUSER_STATUSES[value]}
      defaultMessage={mapUSER_STATUSES[value]}
    />
  );
};

export const createdByFormatter = (id, record) => {
  if (record.createdBy) {
    const { firstName, lastName, username } = record.createdBy;
    return (
      <UserInfo scale="small" name={`${firstName} ${lastName}`}>
        {username}
      </UserInfo>
    );
  }
};

export const publishedByFormatter = (id, record) => {
  if (record.publishedBy) {
    const { firstName, lastName, username } = record.publishedBy;
    return (
      <UserInfo
        iconDisabled
        scale="small"
        name={`${firstName ? firstName : '---'} ${lastName ? lastName : '---'}`}
      >
        {username}
      </UserInfo>
    );
  }
};

export const updatedByFormatter = (id, record) => {
  if (record.updatedBy) {
    const { firstName, lastName, username } = record.updatedBy;
    return (
      <UserInfo scale="small" name={`${firstName} ${lastName}`}>
        {username}
      </UserInfo>
    );
  }
};

export const switchFormatter = (id, record) => {
  return (
    <FormGroup>
      <SwitchField
        value={id}
        className={'disabled'}
        input={{
          checked: record.isActive,
        }}
        slim
        meta={{
          dirty: true,
          submitting: false,
        }}
      />
    </FormGroup>
  );
};
