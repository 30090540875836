import React from 'react';
import useSettingsRequest from 'js/modules/Settings/hooks/useSettingsRequest';
import { Layout as LayoutModule, Utils } from 'billon-ui';
import { decodeJwtToken } from 'js/modules/Login/hooks';
import TwoStepPasswordChange from '../../components/TwoStepPasswordChange';
import * as Styled from './styled';
import { PASSWORD_FORM_MODES } from 'js/constraints';

const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const ChangePasswordPage = () => {
  const { role } = decodeJwtToken(sessionStorage.getItem('token'));
  const { data: settings, isLoading } = useSettingsRequest();

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <>
      <Styled.ContentContainerGrey fluid>
        <Styled.ContentRow>
          <Styled.ContentCol
            sm={{ size: 12, offset: 0 }}
            md={{ size: 9, offset: 0 }}
            lg={{ size: 5, offset: 0 }}
          >
            <TwoStepPasswordChange
              mode={PASSWORD_FORM_MODES.PASSWORD_CHANGE}
              role={role}
              settings={settings}
            />
          </Styled.ContentCol>
        </Styled.ContentRow>
      </Styled.ContentContainerGrey>
    </>
  );
};

export default ChangePasswordPage;
