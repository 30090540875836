import { routerReducer as routing } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { TechnicalBreak, Language, Config } from 'billon-ui';
import config from './config';

import { reducer as AuthenticationReducer } from 'js/ui/Authentication';

import { reducer as document } from './modules/Documents';
import { reducer as agreement } from './modules/Agreements';
import { reducer as category } from './modules/Categories';
import { reducer as user } from './modules/Users';
import { reducer as recipient } from './modules/Recipients';
import { reducer as settings } from './modules/Settings';
import { reducer as requests } from './modules/Requests';
import { reducer as reports } from './modules/Reports';

const { reducer: LanguageReducer } = Language;
const { reducer: TechnicalBreakReducer } = TechnicalBreak;
const { reducer: ConfigReducer } = Config;

const rootReducer = combineReducers({
  form,
  routing,
  authentication: AuthenticationReducer,
  language: LanguageReducer,
  config: ConfigReducer(config),
  checkConnection: TechnicalBreakReducer,
  document,
  agreement,
  category,
  user,
  recipient,
  settings,
  requests,
  reports,
});

export default rootReducer;
