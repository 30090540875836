/* global window */
import React, { useState } from 'react';
import { Layout as LayoutModule, Utils } from 'billon-ui';

import { QueryKeys } from '../../../../queryKeys';
import { useQueryClient } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';
import appRoutes from 'js/app-routes';
import { useSingleDocument } from '../../hooks/useSingleDocument';

import UpdateModal from 'js/modules/Documents/components/UpdateModal';
import UpdateForm from '../../components/UpdateForm';
import config from 'js/config';
import {
  CUSTOMER,
  PUBLICATION_MODES,
  SETTINGS_CONSTANTS,
} from 'js/constraints';

import { ExportableErrorContent } from 'js/components/ExportableErrorContent';
import { ErrorModal } from 'js/components/ErrorModal';
import { SuccessModal as CustomSuccessModal } from 'js/components/SuccessModal';
import { FormattedMessage } from 'react-intl';

import useSettingsRequest from 'js/modules/Settings/hooks/useSettingsRequest';

import { PublishDocumentModal } from '../../components';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;
const { customer } = config;

const DocumentUpdatePage = () => {
  const history = useHistory();

  const { params } = useRouteMatch(appRoutes.DOCUMENTS_DETAILS_UPDATE);

  const queryClient = useQueryClient();

  const { data: settings, isSettingsLoading } = useSettingsRequest({});

  const publisherName = settings?.find(
    (item) => item.key === SETTINGS_CONSTANTS.PUBLISHER_NAME,
  );

  const { data: document, isLoading } = useSingleDocument({ id: params?.id });

  const handleSuccess = () => {
    queryClient.invalidateQueries([QueryKeys.SINGLE_DOCUMENT, params?.id]);
  };

  const [updateModalOpened, setUpdateModalOpened] = useState(true);
  const [currentUpdateMode, setCurrentUpdateMode] = useState(params?.mode);

  const [updateErrorModalOpened, setUpdateErrorModalOpened] = useState(false);
  const [actionErrorStatusCode, setActionErrorStatusCode] = useState(null);
  const [actionErrorLabel, setActionErrorLabel] = useState(null);

  const [updateSuccessModalOpened, setUpdateSuccessModalOpened] =
    useState(false);
  const [actionSuccessLabel, setActionSuccessLabel] = useState(null);

  const handUpdateModalClose = () => {
    setUpdateModalOpened(false);
    history.push(`/document/${document.jobId}`);
  };

  const handleUpdateFailure = (errorLabel, statusCode) => {
    setActionErrorStatusCode(statusCode);
    setActionErrorLabel(errorLabel);
    setUpdateModalOpened(false);
    handleUpdateFailureModalOpen();
  };

  const handleUpdateFailureModalOpen = () => {
    setUpdateErrorModalOpened(true);
  };

  const handleUpdateFailureModalClose = () => {
    setUpdateErrorModalOpened(false);
    handUpdateModalClose();
  };

  const handleUpdateSuccessModalOpen = () => {
    setUpdateSuccessModalOpened(true);
  };

  const handleUpdateSuccessModalClose = () => {
    setUpdateSuccessModalOpened(false);
    handUpdateModalClose();
  };

  const handleUpdateModalSuccess = (label) => {
    handleSuccess();
    setActionSuccessLabel(label);
    setUpdateModalOpened(false);
    handleUpdateSuccessModalOpen();
  };

  if (isSettingsLoading || isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <>
      <UpdateModal
        isOpen={updateModalOpened}
        toggle={handUpdateModalClose}
        onClose={handUpdateModalClose}
        mode={currentUpdateMode}
      >
        <UpdateForm
          initialValues={document}
          mode={currentUpdateMode}
          publisherName={publisherName}
          onClose={handUpdateModalClose}
          onSuccess={handleUpdateModalSuccess}
          onError={handleUpdateFailure}
        />
      </UpdateModal>

      <ErrorModal
        isOpen={updateErrorModalOpened}
        toggle={handleUpdateFailureModalClose}
        onClose={handleUpdateFailureModalClose}
        closeIconEnabled={false}
      >
        <ExportableErrorContent
          headerTitleId={actionErrorLabel}
          statusCode={actionErrorStatusCode}
          jobId={document.jobId}
          title={document.title}
        />
      </ErrorModal>

      <CustomSuccessModal
        isOpen={updateSuccessModalOpened}
        toggle={handleUpdateSuccessModalClose}
        onClose={handleUpdateSuccessModalClose}
        closeIconEnabled={false}
      >
        {actionSuccessLabel && (
          <FormattedMessage
            id={actionSuccessLabel}
            defaultMessage={actionSuccessLabel}
          />
        )}
      </CustomSuccessModal>
    </>
  );
};

export default DocumentUpdatePage;
