import { Grid, Utils } from 'billon-ui';
import styled from 'styled-components';
const { Row, Col } = Grid;

export const BackBtnRow = styled(Row)`
  margin: 0 0 20px 0;
`;

export const BadgedTitleRow = styled(Row)`
  margin: 0;
`;

export const CopyBlockchainAdressWrapper = styled.div`
  margin-bottom: 40px;
`;
