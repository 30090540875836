import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Utils } from 'billon-ui';
import { Controller, useForm } from 'react-hook-form';
import { useAuthenticatedContext } from '../../../Auth/hooks/useAuthenticated';
import {
  MAP_VALIDATION_LOGIN_ERRORS,
  VALIDATION_LOGIN_ERRORS,
} from 'js/constraints';
import * as Styled from './styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSchema } from './useSchema';

const { Form: F, TextField, FormGroup, PasswordField } = Form;
const { Button: ButtonModule } = Utils;
const { ButtonLoader } = ButtonModule;

const LoginForm = ({ onBlocked }) => {
  const { handleLogin, loginError } = useAuthenticatedContext();

  const schema = useSchema();

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    setError,
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (loginError) {
      const data = loginError?.response?.data;
      const message = data?.statusCode;

      if (message === VALIDATION_LOGIN_ERRORS.ACCOUNT_TEMPORARY_BLOCKED) {
        onBlocked(data?.unblockDate);
      } else {
        setError('username', {
          type: 'custom',
          message: MAP_VALIDATION_LOGIN_ERRORS[message],
        });
      }
    }
  }, [loginError]);

  const handleSubmitLogin = (data) => {
    handleLogin({ data });
  };

  return (
    <F onSubmit={handleSubmit(handleSubmitLogin)}>
      <FormGroup>
        <Controller
          name="username"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                name="username"
                className="secondary"
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                  error: errors?.[field.name]?.message,
                }}
                label={<FormattedMessage id="Login" defaultMessage="Login" />}
              />
            );
          }}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <PasswordField
                name="password"
                type="password"
                className="secondary"
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                  error: errors?.[field.name]?.message,
                }}
                label={
                  <FormattedMessage id="Password" defaultMessage="Password" />
                }
              />
            );
          }}
        />
      </FormGroup>
      <FormGroup className="form-submit">
        {!isSubmitting ? (
          <Styled.LoginButton block size="lg">
            <FormattedMessage id="Log in" defaultMessage="Log in" />
          </Styled.LoginButton>
        ) : (
          <ButtonLoader block size="lg" />
        )}
      </FormGroup>
    </F>
  );
};

export default LoginForm;
