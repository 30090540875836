import React from 'react';
import { connect } from 'react-redux';
import {
  Layout as LayoutModule,
  Authentication,
  Table as TableModule,
  Utils,
  Grid,
} from 'billon-ui';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';

import { TariffFilterForm as FilterForm } from '..';
import { BlockchainVisual } from '../../../../components';

import * as formatters from '../../../../formatters';

// Actions
import {
  requestList,
  applyTariffFilters as applyFiltersAction,
  requestDownload,
} from '../../actions';

// Utils
import { mapStatuses } from '../../helpers';
import { DOCUMENT_STATUS, SETTINGS_CONSTANTS } from '../../../../constraints';

const { Row, Col } = Grid;
const { Table, TableColumn, TableCell, AbstractTable } = TableModule;
const { Sidebar, Content: ContentModule, PageHeader } = LayoutModule;
const {
  Loader: LoaderModule,
  Pagination,
  Button: ButtonModule,
  helpers,
  Icon,
} = Utils;
const { ProtectedComponent } = Authentication;
const { Button } = ButtonModule;
const { Loader, PageLoader } = LoaderModule;
const { Content } = ContentModule;

const { getNumberOfPages } = helpers;

const DocumentInfo = styled.div`
  .btn {
    margin-bottom: 0.3rem;
  }

  .info-col {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .border-right {
    border-right: 1px solid ${(props) => props.theme.palette.grayLight};
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      font-size: ${(props) => props.theme.fontSizeSm};
      margin-bottom: 1rem;

      label {
        display: block;
        font-weight: ${(props) => props.theme.fontWeightBold};
        margin-bottom: 0;
      }
    }
  }
`;

const TC = styled(TableCell)`
  white-space: nowrap;
  font-weight: ${(props) =>
    props.row.isNewlyPublished
      ? props.theme.fontWeightBold
      : props.theme.fontWeightBase};
`;

const TCCentered = styled(TC)`
  text-align: center;
`;

const TR = styled.tr`
  font-weight: ${(props) =>
    props.isNewlyPublished
      ? props.theme.fontWeightBold
      : props.theme.fontWeightBase};
  color: ${(props) => (props.isNotAvailable ? '#aaaaaa' : 'inherit')};
`;

class DocumentTable extends AbstractTable {
  state = {
    order: {},
  };

  downloadFormatter = (address, row) => {
    const { fileDownload, isDownloading } = this.props;

    if (isDownloading.includes(address || row.jobId)) {
      return <Loader width={36} />;
    }

    if (!row.documentBlockchainAddress) {
      return null;
    }

    return (
      <Button
        color="link"
        className="ignore-row-click"
        onClick={() => fileDownload(address)}
      >
        <Icon name="file-pdf" />
      </Button>
    );
  };

  details = (record) => {
    const { history } = this.props;

    if (!record.documentBlockchainAddress && !record.jobId) {
      return null;
    }

    return (
      <DocumentInfo>
        <Row className="align-items-center" noGutters>
          <Col xl={8} lg={8} md={8} className="info-col">
            <ul>
              {record.documentBlockchainAddress && (
                <li>
                  <label className="large">
                    <FormattedMessage
                      id="Blockchain address"
                      defaultMessage="Blockchain address"
                    />
                    :
                  </label>
                  {record.documentBlockchainAddress}
                </li>
              )}
              <li>
                <label>
                  <FormattedMessage
                    id="Publication status"
                    defaultMessage="Publication status"
                  />
                  :
                </label>
                {mapStatuses(record.status)}
              </li>
            </ul>
          </Col>
          <Col xl={2} lg={2} md={2} className="info-col">
            <BlockchainVisual secondary size={150} />
          </Col>
          <Col xl={2} lg={2} md={2} className="info-col">
            {record.documentBlockchainAddress && (
              <Button
                onClick={() =>
                  history.push(
                    `/document/tariff/${record.documentBlockchainAddress}`,
                  )
                }
                block
              >
                <FormattedMessage
                  id="Document details"
                  defaultMessage="Document details"
                />
              </Button>
            )}
          </Col>
        </Row>
      </DocumentInfo>
    );
  };

  publicOrPrivate = (value) => (
    <i className={value.length ? 'yellow-bullet' : 'blue-bullet'} />
  );

  render() {
    const {
      records,
      isFetching,
      handleCreate,
      numberOfRecords,
      customer,
      settings,
    } = this.props;

    if (isFetching) {
      return (
        <Content fluid>
          <PageLoader />
        </Content>
      );
    }

    const filters = this.getFilters();

    const initialValues = {
      customer,
    };

    return (
      <>
        <Sidebar>
          <FilterForm initialValues={filters} />
        </Sidebar>
        <Content fluid withSidebar>
          <PageHeader
            after={
              <ProtectedComponent resource="documents.manage" render={false}>
                <Button
                  onClick={() =>
                    handleCreate(initialValues, this.loadWithFilters)
                  }
                  padding="0.5rem 3.5rem;"
                >
                  <FormattedMessage
                    id="Publish tariff"
                    defaultMessage="Publish tariff"
                  />
                </Button>
              </ProtectedComponent>
            }
          >
            <FormattedMessage id="Tariffs" defaultMessage="Tariffs" />
          </PageHeader>
          <Table
            tableData={records}
            sortMethod={this.handleSort}
            detailsComponent={this.details}
            rowComponent={(row, props) => (
              <TR
                isNewlyPublished={row.isNewlyPublished}
                isNotAvailable={row.status === DOCUMENT_STATUS.NOT_AVAILABLE}
                {...props}
              />
            )}
          >
            <TableColumn
              fieldName="publicationDate"
              formatter={formatters.momentInTimezoneFormatter}
              renderCell={(row, props) => <TC row={row} {...props} />}
            >
              <FormattedMessage
                id="Publication date"
                defaultMessage="Publication date"
              />
            </TableColumn>
            <TableColumn
              fieldName="title"
              formatter={formatters.boundLengthFormatterLong}
              renderCell={(row, props) => <TC row={row} {...props} />}
            >
              <FormattedMessage id="Title" defaultMessage="Title" />
            </TableColumn>
            <TableColumn
              fieldName="validSince"
              formatter={formatters.momentFormatterWithoutTime}
              renderCell={(row, props) => <TC row={row} {...props} />}
            >
              <FormattedMessage
                id="Is valid from"
                defaultMessage="Is valid from"
              />
            </TableColumn>
            <TableColumn
              fieldName="publishedBy.login"
              renderCell={(row, props) => <TC row={row} {...props} />}
            >
              <FormattedMessage
                id="Published by"
                defaultMessage="Published by"
              />
            </TableColumn>
            <TableColumn
              fieldName="id"
              formatter={() => settings[SETTINGS_CONSTANTS.PUBLICATOR_NAME]}
              renderCell={(row, props) => <TC row={row} {...props} />}
            >
              <FormattedMessage id="Publisher" defaultMessage="Publisher" />
            </TableColumn>
            <TableColumn
              cellClassName="text-center"
              fieldName="documentBlockchainAddress"
              formatter={this.downloadFormatter}
              renderCell={(row, props) => <TCCentered row={row} {...props} />}
            >
              <FormattedMessage id="Download" defaultMessage="Download" />
            </TableColumn>
          </Table>
          <Pagination
            onClick={this.handlePageChange}
            numberOfPages={getNumberOfPages(
              numberOfRecords,
              filters.limit.value,
            )}
            currentPage={filters.page}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
          />
        </Content>
      </>
    );
  }
}

DocumentTable.propTypes = {
  records: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  handleCreate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isDownloading: PropTypes.array.isRequired,
  fileDownload: PropTypes.func,
  load: PropTypes.func.isRequired,
  customer: PropTypes.string.isRequired,
  enablePrivate: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = ({ document, config, settings }) => ({
  records: document.records,
  isFetching: document.isFetching,
  numberOfRecords: document.numberOfRecords,
  isDownloading: document.isDownloading,
  customer: config.customer,
  limit: config.limit,
  enablePrivate: config.enablePrivate,
  settings: settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
  load: (filters) =>
    dispatch(
      requestList({
        ...filters,
        order: { publicationDate: 'DESC' },
        include: ['category', 'publishedBy', 'recipients'],
      }),
    ),
  applyFilters: (history, filters) => applyFiltersAction(history, filters),
  fileDownload: (address) => dispatch(requestDownload(address)),
});

export default withRouter(
  reduxForm({
    form: 'documentTableForm',
  })(connect(mapStateToProps, mapDispatchToProps)(DocumentTable)),
);
