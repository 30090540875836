import { Form as FormModule, Grid, Utils } from 'billon-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import styled from 'styled-components';

const { Row, Col } = Grid;

const { FormGroup, UploadField } = FormModule;

const { Button: ButtonModule, Text } = Utils;
const { Button } = ButtonModule;

const DropzonePlaceholder = styled.div`
  width: 100%;
  border: 1px dashed ${(props) => props.theme.palette.grayLighter};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizeSm};
  line-height: 2;
  text-align: center;

  &:focus {
    outline: 0;
  }
`;

export const StyledTitleContainer = styled.div`
  width: 100%;
  margin-bottom: 60px;
  h2 {
    margin-bottom: 1rem !important;
  }
`;

const CreateFormStep2 = (props) => {
  return (
    <>
      {' '}
      <StyledTitleContainer>
        <Text as="h2" fontSize="32px">
          <FormattedMessage
            id="Step 2: Sign document"
            defaultMessage="Step 2: Sign document"
          />
        </Text>
        <Text as="p" fontSize="16px">
          <FormattedMessage
            id="We have prepared a document for you. Download it to your computer, sign it and then add it here for publishing."
            defaultMessage="We have prepared a document for you. Download it to your computer, sign it and then add it here for publishing."
          ></FormattedMessage>
        </Text>
      </StyledTitleContainer>
      <Row css={{ width: '100%' }} margin={'0'}>
        <Col xl={6} margin={'0 0 20px 0'}>
          <Text as="h4" fontSize="16px" fontWeight={700}>
            1.{' '}
            <FormattedMessage
              id="Download document to sign"
              defaultMessage="Download document to sign"
            />
          </Text>
          <DropzonePlaceholder padding={'4rem'}>
            <div>
              <Text>
                <FormattedMessage
                  id="Download document to sign"
                  defaultMessage="Download document to sign"
                />
              </Text>
            </div>
            <br />
            <Button onClick={props.downloadFileToSign} outline type="button">
              <FormattedMessage id="Download" defaultMessage="Download" />
            </Button>
          </DropzonePlaceholder>
        </Col>
        <Col xl={6} margin={'0 0 20px 0'}>
          <Text as="h4" fontSize="16px" fontWeight={700}>
            2.{' '}
            <FormattedMessage
              id="Add signed document"
              defaultMessage="Add signed document"
            />
          </Text>
          <FormGroup>
            <Field
              component={UploadField}
              accept={['application/pdf']}
              name="file"
              placeholder={() => (
                <DropzonePlaceholder padding={'4rem'}>
                  <Text css={{ 'text-align': 'center' }}>
                    <FormattedMessage
                      id="Drag and drop here PDF max 2MB"
                      defaultMessage="Drag and drop here PDF max 2MB"
                    />{' '}
                    <br />
                    <FormattedMessage id="or" defaultMessage="or" />
                  </Text>

                  <Button outline type="button">
                    <FormattedMessage
                      id="Select from disk"
                      defaultMessage="Select from disk"
                    />
                  </Button>
                </DropzonePlaceholder>
              )}
            />
          </FormGroup>
        </Col>
      </Row>{' '}
    </>
  );
};

export default CreateFormStep2;
