import React from 'react';
import DocumentStatusTooltip from '../components/DocumentStatusTooltip/DocumentStatusTooltip';
import StatusWithIcon from '../../../ui/StatusWithIcon';
import { mapERRORS, MAP_DOCUMENT_STATUSES } from '../../../constraints';
import generateId from '../../../utils/generateId';
import { FormattedMessage } from 'react-intl';

export const documentStatusFormatter = (value, record) => {
  const label = MAP_DOCUMENT_STATUSES[value];
  const formattedLabel = <FormattedMessage id={label} defaultMessage={label} />;

  if (mapERRORS[value]) {
    const { jobId, title } = record;
    return (
      <StatusWithIcon label={formattedLabel}>
        <DocumentStatusTooltip
          id={generateId(record)}
          label={mapERRORS[value]}
          jobId={jobId}
          title={title}
          delay={true}
        />
      </StatusWithIcon>
    );
  } else return formattedLabel;
};
