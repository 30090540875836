import React from 'react';
import ContentContainerGrey from '../../../../ui/ContentContainerGrey';
import { Utils } from 'billon-ui';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { OneStepPublication, TwoStepPublication } from '../../components/PublishDocumentModal/componnets';
import { PUBLICATION_MODES, PUBLICATION_TYPE } from '../../../../constraints';
import { Content } from '../../../../components/Content/Content';
const { Text, Card } = Utils;

export const PublishDocumentPage = () => {
  const { isOneStepPublication } = useSelector(({ config }) => ({
    isOneStepPublication: config.publicationType === PUBLICATION_TYPE.ONE_STEP,
  }));

  return (
    <ContentContainerGrey fluid>
      <Content fluid>
        <Text as="h2" fontWeight={800}>
          <FormattedMessage id="Publish document" defaultMessage="Publish document" />
        </Text>
        <Card>
          {isOneStepPublication ? (
            <OneStepPublication publicationMode={PUBLICATION_MODES.NEW} />
          ) : (
            <TwoStepPublication />
          )}
        </Card>
      </Content>
    </ContentContainerGrey>
  );
};
