import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { merge } from 'lodash';
import {
  Form as FormModule,
  Layout as LayoutModule,
  Table as TableModule,
  Utils,
} from 'billon-ui';
import { useUsersList } from '../../hooks/useUsersList';
import { useUpdateUser } from '../../hooks/useUpdateUser';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../../../queryKeys';
import useFilters from '../../../../hooks/useFilters';
import * as formatters from '../../../Users/formatters';
import { StatusWithToolTipFormatter } from '../StatusTooltip/StatusWithToolTipFormatter';
import UserTableDetails from '../UserTableDetails';
import config from 'js/config';
import * as Styled from './styled';
import { FilterForm } from '../FilterForm';
import { ProtectedComponent } from 'js/modules/Auth/components';
import { UserRoles } from 'js/constraints';

const { Table, TableColumn } = TableModule;
const { Loader: LoaderModule, Pagination, Button: ButtonModule } = Utils;
const { Content: ContentModule, PageHeader } = LayoutModule;
const { Content } = ContentModule;
const { Button } = ButtonModule;
const { PageLoader } = LoaderModule;
const { SwitchField } = FormModule;
const { enablePrivate } = config;

const UsersTable = ({ handleCreate, handleDelete }) => {
  const defaultFilters = {
    pagination: {
      limit: 10,
      page: 1,
    },
  };

  const [areFiltersOpen, setAreFiltersOpen] = useState();

  const toggleFiltersForm = () => setAreFiltersOpen(!areFiltersOpen);

  const { filters, navigateWithSearchQuery } = useFilters();
  const fullFilters = merge(defaultFilters, filters);

  const { data, isLoading } = useUsersList(fullFilters);
  const queryClient = useQueryClient();

  const { mutate: editUser } = useUpdateUser({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.USERS_LIST, fullFilters]);
    },
  });

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  const switchFormat = (value, row) => {
    return (
      <SwitchField
        input={{
          value,
          name: `isActive[${row.id}]`,
          checked: value,
          onClick: () =>
            editUser({
              id: row.id,
              data: {
                isActive: !row.isActive,
              },
            }),
        }}
        slim
        type="checkbox"
        meta={{}}
      />
    );
  };

  const numberOfPages = Math.ceil(data.count / fullFilters.pagination.limit);

  return (
    <Content fluid>
      <FormattedMessage id="Search" defaultMessage="Search">
        {([placeholder]) => (
          <PageHeader
            handleSearchBar={() => {}}
            handleReset={() => {}}
            initialValues={{}}
            classic
            searchBarPlaceholder={placeholder}
            after={
              <ProtectedComponent roles={[UserRoles.ADMIN]} render={false}>
                <Styled.AfterWrapper>
                  <Button
                    onClick={handleCreate}
                    fontWeight={500}
                    padding="0.5rem 3.5rem;"
                  >
                    <FormattedMessage
                      id="Add administrator"
                      defaultMessage="Add administrator"
                    />
                  </Button>
                </Styled.AfterWrapper>
              </ProtectedComponent>
            }
          >
            <FormattedMessage
              id="Administrators"
              defaultMessage="Administrators"
            />
            <Button
              fontWeight="700"
              fontSize="12px"
              color="link"
              type="button"
              onClick={toggleFiltersForm}
            >
              <FormattedMessage id="Filters" defaultMessage="Filters" />
            </Button>
          </PageHeader>
        )}
      </FormattedMessage>

      {areFiltersOpen && (
        <FilterForm initialValues={filters} closeFilters={toggleFiltersForm} />
      )}

      <Styled.TableCard>
        <Table
          tableData={data.rows}
          sortMethod={() => {}}
          responsive
          detailsComponent={(record) => (
            <UserTableDetails
              record={record}
              handleCreate={(initialValues, isPreparedToSign = false) =>
                handleCreate(initialValues, () => {}, isPreparedToSign)
              }
              handleDelete={(id) => handleDelete(id, () => {})}
              enablePrivate={enablePrivate}
            />
          )}
        >
          <TableColumn
            fieldName="createdAt"
            formatter={formatters.createdAtFormatter}
          >
            <FormattedMessage id="Created at" defaultMessage="Created at" />
          </TableColumn>
          <TableColumn fieldName="username">
            <FormattedMessage id="Login" defaultMessage="Login" />
          </TableColumn>
          <TableColumn fieldName="firstName">
            <FormattedMessage id="First name" defaultMessage="First name" />
          </TableColumn>
          <TableColumn fieldName="lastName">
            <FormattedMessage id="Last name" defaultMessage="Last name" />
          </TableColumn>
          <TableColumn fieldName="email">
            <FormattedMessage id="Email" defaultMessage="Email" />
          </TableColumn>
          <TableColumn fieldName="role" formatter={formatters.roleFormatter}>
            <FormattedMessage id="Account type" defaultMessage="Account type" />
          </TableColumn>
          <TableColumn
            fieldName="createdBy"
            formatter={formatters.createdByFormatter}
          >
            <FormattedMessage id="Created by" defaultMessage="Created by" />
          </TableColumn>
          <TableColumn
            fieldName="status"
            formatter={StatusWithToolTipFormatter}
          >
            <FormattedMessage id="Status" defaultMessage="Status" />
          </TableColumn>
          <TableColumn
            className="fixed-width"
            fieldName="isActive"
            formatter={switchFormat}
          >
            <FormattedMessage id="Active2" defaultMessage="Active" />
          </TableColumn>
        </Table>
        <Pagination
          onClick={handlePageChange}
          numberOfPages={numberOfPages}
          currentPage={fullFilters.pagination.page}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
        />
      </Styled.TableCard>
    </Content>
  );
};

export default UsersTable;
