import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Utils, Form } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

// Actions
import { applyFilters } from '../../actions';

const { TextField, FormGroup, CheckboxField } = Form;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

const FF = styled.div`
  h3 {
    margin-bottom: 3rem;
    height: 2.5rem;
  }
`;

class FilterForm extends Component {
  constructor(props) {
    super(props);

    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.handleResetFilters = this.handleResetFilters.bind(this);
  }

  handleFilterSubmit(values) {
    const { history, initialValues } = this.props;
    applyFilters(history, {
      ...initialValues,
      ...values,
      page: undefined,
      limit: undefined,
    });
  }

  handleResetFilters() {
    const { reset, history, initialValues } = this.props;

    reset();
    applyFilters(history, {
      public: initialValues.public,
      private: initialValues.private,
      publishedBy: initialValues.publishedBy,
    });
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <FF>
        <h3>
          <FormattedMessage id="Filters" defaultMessage="Filters" />
          <Button
            color="link"
            onClick={this.handleResetFilters}
            icon="times"
            iconRight
          >
            <FormattedMessage id="Clear" defaultMessage="Clear" />
          </Button>
        </h3>
        <form onSubmit={handleSubmit(this.handleFilterSubmit)}>
          <h6>
            <FormattedMessage
              id="Category name"
              defaultMessage="Category name"
            />
          </h6>
          <Field component={TextField} name="name" />

          <FormGroup>
            <Field
              component={CheckboxField}
              name="isActive"
              type="checkbox"
              small
              label={
                <FormattedMessage
                  id="Only active"
                  defaultMessage="Only active"
                />
              }
            />
          </FormGroup>

          <Button className="button-static">
            <FormattedMessage id="Filter" defaultMessage="Filter" />
          </Button>
        </form>
      </FF>
    );
  }
}

FilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  history: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default withRouter(
  reduxForm({
    form: 'filterForm',
  })(FilterForm),
);
