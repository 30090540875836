import React from 'react';
import StatusTooltip from '../../../../../modules/Documents/components/StatusTooltip';
import StatusWithIcon from '../../../../../ui/StatusWithIcon';
import {
  NOTIFICATION_HISTORY_STATUSES,
  NOTIFICATION_HISTORY_ERRORS_STATUSES,
} from '../../../../../constraints';
import { mapNotificationHistoryStatuses } from '../../../../../formatters';
import { mapERRORS } from '../../../../../constraints';
import { generateRecordBasedId } from '../../../../../utils';

export const StatusWithToolTipFormatter = (value, record) => {
  const label = mapNotificationHistoryStatuses(value);
  const errorCheck =
    [NOTIFICATION_HISTORY_STATUSES.ERROR].includes(value) ||
    NOTIFICATION_HISTORY_ERRORS_STATUSES[value] !== undefined;

  if (errorCheck) {
    return (
      <StatusWithIcon label={label}>
        <StatusTooltip
          label={mapERRORS[value]}
          id={generateRecordBasedId(record)}
          delay={false}
        />
      </StatusWithIcon>
    );
  } else return label;
};
