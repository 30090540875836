import { createAction } from 'redux-act';
import { Utils } from 'billon-ui';

const { helpers } = Utils;
const { encodeQueryString } = helpers;

// Apply Filters
export const applyFilters = (history, filters) =>
  history.push(`/reports/${encodeQueryString(filters)}`);

// Request documents list
export const requestList = createAction(
  'Request documents list',
  (filters, ...rest) => ({ filters, ...rest }),
);
export const receiveListSuccess = createAction(
  'Request documents list success',
);
export const receiveListFailure = createAction(
  'Request documents list failure',
);

// Download documents list
export const requestDownloadExcelReport = createAction(
  'Download documents list',
  (meta, ...rest) => ({
    meta,
    ...rest,
  }),
);
export const receiveDownloadExcelReportSuccess = createAction(
  'Download statistics  success',
);
export const receiveDownloadExcelReportFailure = createAction(
  'Download statistics  failure',
);

// Download statistics
export const requestDownloadStatisticsExcelReport = createAction(
  'Download statistics ',
  (meta, ...rest) => ({
    meta,
    ...rest,
  }),
);
export const receiveDownloadStatisticsExcelReportSuccess = createAction(
  'Download documents list success',
);
export const receiveDownloadStatisticsExcelReportFailure = createAction(
  'Download documents list failure',
);

// Download document
export const requestDownload = createAction(
  'Download document',
  (blockchainAddress, jobId, returnOnlyBlob, ...rest) => ({
    blockchainAddress,
    jobId,
    returnOnlyBlob,
    ...rest,
  }),
);
export const receiveDownloadSuccess = createAction('Download document success');
export const receiveDownloadFailure = createAction('Download document failure');

// Request reports
export const requestYearlyReport = createAction('Request yearly report');
export const requestYearlyReportSuccess = createAction(
  'Request yearly report success',
);
export const requestYearlyReportFailure = createAction(
  'Request yearly report failure',
);

export const requestFairUsageReport = createAction(
  'Request Fair Usage report',
  (params, ...rest) => ({ params, ...rest }),
);
export const requestFairUsageReportSuccess = createAction(
  'Request Fair Usage report success',
);
export const requestFairUsageReportFailure = createAction(
  'Request Fair Usage report failure',
);

export const requestCustomReport = createAction('Request Fair Usage report');
export const requestCustomReportSuccess = createAction(
  'Request Fair Usage report success',
);
export const requestCustomReportFailure = createAction(
  'Request Fair Usage report failure',
);
