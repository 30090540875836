import { VALIDATION_ERRORS } from '../../../../../constraints';

export default (values) => {
  const errors = {};

  if (!values) {
    return null;
  }

  if (!values.title) {
    errors.title = VALIDATION_ERRORS.REQUIRED;
  } else if (values.title.length > 128) {
    errors.title = VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED.replace(
      '$length',
      128,
    );
  }

  if (!values.user) {
    errors.user = VALIDATION_ERRORS.REQUIRED;
  }

  if (!values.categoryId || !values.categoryId.value) {
    errors.categoryId = VALIDATION_ERRORS.REQUIRED;
  }

  if (!values.validSince) {
    errors.validSince = VALIDATION_ERRORS.REQUIRED;
  }

  if (!values.file) {
    errors.file = VALIDATION_ERRORS.REQUIRED;
  } else if (values.file.size && values.file.size > 2 * 1024 * 1024) {
    errors.file = VALIDATION_ERRORS.FILE_SIZE_TOO_LARGE;
  }

  if (
    values.recipientsToNotify &&
    values.recipientsToNotify.size &&
    values.recipientsToNotify.size > 2 * 1024 * 1024
  ) {
    errors.recipientsToNotify = VALIDATION_ERRORS.FILE_SIZE_TOO_LARGE;
  }

  return errors;
};
