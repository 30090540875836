import React from 'react';
import {
  Form as FormModule,
  Layout as LayoutModule,
  Table as TableModule,
  Utils,
} from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { merge } from 'lodash';
// Utils
import { CUSTOMER } from '../../../../constraints';
// Actions
import { useIdentityList } from '../../hooks/useIdentityList';
import { useUpdateIdentity } from '../../hooks/useUpdateIdentity';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../../../queryKeys';
import useFilters from '../../../../hooks/useFilters';
import * as Styled from './styled';
import config from 'js/config';
import { ProtectedComponent } from 'js/modules/Auth/components';
import { UserRoles } from 'js/constraints';

const { Table, TableColumn } = TableModule;
const { Loader: LoaderModule, Pagination, Button: ButtonModule, Card } = Utils;
const { Content: ContentModule, PageHeader } = LayoutModule;
const { Content } = ContentModule;
const { Button } = ButtonModule;
const { PageLoader } = LoaderModule;
const { SwitchField } = FormModule;
const { customer } = config;

const RecipientTable = ({ handleCreate, handleManage }) => {
  const defaultFilters = {
    pagination: {
      limit: 10,
      page: 1,
    },
  };

  const { filters, navigateWithSearchQuery } = useFilters();
  const fullFilters = merge(defaultFilters, filters);

  const { data, isLoading } = useIdentityList(fullFilters);
  const queryClient = useQueryClient();

  const { mutate: editIdentity } = useUpdateIdentity({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.IDENTITY_LIST, fullFilters]);
    },
  });

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  const switchFormat = (value, row) => {
    return (
      <>
        <ProtectedComponent
          roles={[UserRoles.AGENT, UserRoles.ADMIN]}
          render={false}
        >
          <SwitchField
            input={{
              value,
              name: `isActive[${row.id}]`,
              checked: value,
              onClick: () =>
                editIdentity({
                  id: row.id,
                  data: {
                    isActive: !row.isActive,
                  },
                }),
            }}
            slim
            type="checkbox"
            meta={{}}
          />
        </ProtectedComponent>
        <ProtectedComponent
          roles={[UserRoles.AGENT, UserRoles.ADMIN]}
          reverse={true}
          render={false}
        >
          <SwitchField
            input={{
              value,
              name: `isActive[${row.id}]`,
              checked: value,
            }}
            slim
            type="checkbox"
            meta={{}}
          />
        </ProtectedComponent>
      </>
    );
  };

  const addText =
    customer === CUSTOMER.DIPLOMA ? 'Add student' : 'Add recipient';
  const manageText =
    customer === CUSTOMER.DIPLOMA ? 'Manage students' : 'Manage recipients';
  const titleText = customer === CUSTOMER.DIPLOMA ? 'Students' : 'Recipients';
  const externalIdText =
    customer === CUSTOMER.DIPLOMA ? 'Index number' : 'Pesel';

  const numberOfPages = Math.ceil(data.count / fullFilters.pagination.limit);

  return (
    <Content fluid>
      <FormattedMessage id="Search" defaultMessage="Search">
        {([placeholder]) => (
          <PageHeader
            handleSearchBar={() => {}}
            handleReset={() => {}}
            initialValues={{}}
            classic
            searchBarPlaceholder={placeholder}
            after={
              <ProtectedComponent
                roles={[UserRoles.AGENT, UserRoles.ADMIN]}
                render={false}
              >
                <Styled.AfterWrapper>
                  <Button
                    onClick={handleCreate}
                    fontWeight={500}
                    padding="0.5rem 3.5rem;"
                  >
                    <FormattedMessage id={addText} defaultMessage={addText} />
                  </Button>
                </Styled.AfterWrapper>
              </ProtectedComponent>
            }
          >
            <FormattedMessage id={titleText} defaultMessage={titleText} />
          </PageHeader>
        )}
      </FormattedMessage>

      <Styled.TableCard>
        <Table tableData={data.rows} sortMethod={() => {}} responsive>
          <TableColumn fieldName="id">
            <FormattedMessage id="ID" defaultMessage="ID" />
          </TableColumn>
          <TableColumn fieldName="documentNumber">
            <FormattedMessage
              id={externalIdText}
              defaultMessage={externalIdText}
            />
          </TableColumn>
          <TableColumn fieldName="phoneNumber">
            <FormattedMessage id="Phone number" defaultMessage="Phone number" />
          </TableColumn>
          <TableColumn fieldName="firstName">
            <FormattedMessage id="First name" defaultMessage="First name" />
          </TableColumn>
          <TableColumn fieldName="lastName">
            <FormattedMessage id="Last name" defaultMessage="Last name" />
          </TableColumn>
          <TableColumn fieldName="email">
            <FormattedMessage id="Email" defaultMessage="Email" />
          </TableColumn>
          <TableColumn
            className="fixed-width"
            fieldName="isActive"
            formatter={switchFormat}
          >
            <FormattedMessage id="Active2" defaultMessage="Active" />
          </TableColumn>
        </Table>
        <Pagination
          onClick={handlePageChange}
          numberOfPages={numberOfPages}
          currentPage={fullFilters.pagination.page}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
        />
      </Styled.TableCard>
    </Content>
  );
};

export default RecipientTable;
