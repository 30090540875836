import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Form as FormModule, Utils, Grid } from 'billon-ui';
import { withRouter } from 'react-router-dom';

import { CUSTOMER } from '../../../../constraints';

import validate from './validators/createValidation';
import validatePESEL from './validators/validatePESEL';

const {
  Form,
  FormGroup,
  TextField,
  PhoneNumberField,
  SwitchField,
} = FormModule;

const { Button: ButtonModule, validators } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { Col } = Grid;

class CreateForm extends Component {
  render() {
    const { handleSubmit, isSaving, customer, initialValues, onSubmit } = this.props;

    return (
      <Form inline onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Field
            component={TextField}
            type="email"
            name="email"
            validate={[validators.emailValidator]}
            label={
              <>
                <FormattedMessage id="Email" defaultMessage="Email" />*
              </>
            }
            placeholder="jan.kowalski@billongroup.com"
          />
        </FormGroup>
        <FormGroup>
          <Field
            component={PhoneNumberField}
            name="phoneNumber"
            validate={[validators.required, validators.phoneVerification]}
            label={
              <>
                <FormattedMessage
                  id="Phone number"
                  defaultMessage="Phone number"
                />
                *
              </>
            }
          />
        </FormGroup>
        <FormGroup>
          <Field
            component={TextField}
            name="documentNumber"
            validate={
              customer === CUSTOMER.DEFAULT
                ? [validators.required, validatePESEL]
                : [validators.required]
            }
            label={
              <>
                {
                  {
                    [CUSTOMER.DIPLOMA]: (
                      <FormattedMessage
                        id="Index number"
                        defaultMessage="Index number"
                      />
                    ),
                    [CUSTOMER.DEFAULT]: (
                      <FormattedMessage id="Pesel" defaultMessage="Pesel" />
                    ),
                  }[customer]
                }
                *
              </>
            }
            disabled={!!initialValues.externalId}
          />
        </FormGroup>
        <FormGroup>
          <Field
            component={TextField}
            name="firstName"
            validate={[validators.required]}
            label={
              <>
                <FormattedMessage id="First name" defaultMessage="First name" />
                *
              </>
            }
            placeholder="Jan"
          />
        </FormGroup>
        <FormGroup>
          <Field
            component={TextField}
            name="lastName"
            validate={[validators.required]}
            label={
              <>
                <FormattedMessage id="Last name" defaultMessage="Last name" />*
              </>
            }
            placeholder="Kowalski"
          />
        </FormGroup>
        <FormGroup>
          <Field
            name="isActive"
            component={SwitchField}
            type="checkbox"
            label={
              <FormattedMessage id="Is active?2" defaultMessage="Is active?" />
            }
          />
        </FormGroup>

        <FormGroup className="form-submit">
          <Col md={{ size: 8, offset: 2 }}>
            {isSaving ? (
              <ButtonLoader size="lg" block />
            ) : (
              <Button type="submit" size="lg" block>
                <FormattedMessage id="Save" defaultMessage="Save" />
              </Button>
            )}
          </Col>
        </FormGroup>
      </Form>
    );
  }
}

CreateForm.propTypes = {
  handleSubmit: PropTypes.func,
  customer: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
};

const mapStateToProps = ({ config }) => ({
  customer: config.customer,
});

export default withRouter(
  reduxForm({
    form: 'saveRecipientForm',
    validate,
  })(connect(mapStateToProps)(CreateForm)),
);
