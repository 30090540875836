/* global window */
import React, { useState } from 'react';
import { Layout as LayoutModule, Utils, Modal as ModalModule } from 'billon-ui';
import { useHistory, useRouteMatch } from 'react-router-dom';
import appRoutes from 'js/app-routes';
import useSettingsRequest from 'js/modules/Settings/hooks/useSettingsRequest';
import { PASSWORD_FORM_MODES } from 'js/constraints';
import PasswordForm from '../../components/PasswordForm';
import { FormattedMessage } from 'react-intl';
import { useSingleUser } from '../../hooks/useSingleUser';
import { useSetUserPassword } from '../../hooks/useSetUserPassword';
import * as Styled from './styled';
import { SuccessModal as CustomSuccessModal } from 'js/components/SuccessModal';
import { removeUndefinedValues } from 'js/helpers/removeUndefinedValues';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;
const { CreateModal } = ModalModule;

const SetTemporaryPasswordPage = () => {
  const history = useHistory();

  const [temporaryPasswordModalOpen, setTemporaryPasswordModalOpen] =
    useState(true);
  const [successModal, setSuccessModal] = useState(false);
  const [reasons, setReasons] = useState([]);

  const { params } = useRouteMatch(appRoutes.TEMPORATY_PASSWORD_SET);
  const { id } = params;

  const { data: settings, isLoading } = useSettingsRequest();
  const { data: user, isLoading: isUserLoading } = useSingleUser({ id: id });

  const handleCloseTemporaryPasswordModal = () => {
    setTemporaryPasswordModalOpen(false);
  };

  const handleSetPasswordSuccess = () => {
    handleCloseTemporaryPasswordModal();
    setSuccessModal(true);
  };

  const handleSetPasswordFailure = ({ response }) => {
    setReasons(response?.data?.reasons || []);
  };

  const handleOnCloseSuccess = () => {
    setSuccessModal(false);
    history.push(appRoutes.USERS_BLANK);
  };

  const { mutate: setPassword } = useSetUserPassword({
    onSuccess: handleSetPasswordSuccess,
    onError: handleSetPasswordFailure,
  });

  const handleSuccess = (values) => {
    setPassword({ id: id, data: removeUndefinedValues(values) });
  };

  const formConfig = {
    mode: PASSWORD_FORM_MODES.PASSWORD_SET,
    handleSuccess: handleSuccess,
    titleId: 'Set password',
    passwordLabelId: 'Password',
    repeatPasswordLabelId: 'Repeat password',
    submitMessageId: 'Next',
  };

  if (isLoading || isUserLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <>
      <CreateModal
        isOpen={temporaryPasswordModalOpen}
        onSuccess={handleSuccess}
        onCancel={handleCloseTemporaryPasswordModal}
        initialValues={{}}
        createMessage={
          <FormattedMessage
            id="Set temporary password"
            defaultMessage="Set temporary password"
          />
        }
      >
        <Styled.ContentRow>
          <Styled.ContentCol
            sm={{ size: 12, offset: 0 }}
            md={{ size: 9, offset: 0 }}
            lg={{ size: 9, offset: 0 }}
          >
            <PasswordForm
              formConfig={formConfig}
              settings={settings}
              role={user.role}
              deliveredReasons={reasons}
            />
          </Styled.ContentCol>
        </Styled.ContentRow>
      </CreateModal>

      <CustomSuccessModal
        isOpen={successModal}
        toggle={handleOnCloseSuccess}
        onClose={handleOnCloseSuccess}
        closeIconEnabled={false}
      >
        <FormattedMessage
          id={'Password has been set'}
          defaultMessage={'Password has been set'}
        />
      </CustomSuccessModal>
    </>
  );
};

export default SetTemporaryPasswordPage;
