import {
  Form as FormModule,
  Grid,
  Modal as ModalModule,
  Utils,
} from 'billon-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { DropzonePlaceholder } from './index';

const { Row, Col } = Grid;

const { ModalFooter } = ModalModule;
const { FormGroup, TextField, SelectField, UploadField } = FormModule;

const { Button: ButtonModule, Text } = Utils;
const { Button, IconButton } = ButtonModule;

const StyledSelectCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: ${({ user }) => (user ? '6px 0 0 0' : '4px 0 0 0')};

  button {
    font-size: ${({ user }) => (user ? '14px' : '16px')};
  }
`;

export const CreateStep0 = ({
  recipients,
  signingOrderOptions,
  categories,
  handleSubmit,
  handlePrepare,
  handleCreateRecipient,
  handleCreateCategory,
  loadCategories,
  loadRecipients,
  retainOptions,
}) => {
  return (
    <>
      <Row margin={'0 0 20px 0'}>
        <Text as="h2">
          <FormattedMessage id="Add document" defaultMessage="Add document" />
        </Text>
      </Row>
      <Row margin={0}>
        <Col md={6}>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Signed by" defaultMessage="Signed by" />
          </Text>
          <FormGroup>
            <Field component={TextField} name="signedBy" range />
          </FormGroup>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Title" defaultMessage="Title" />
          </Text>
          <FormGroup>
            <Field component={TextField} name="title" range />
          </FormGroup>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Recipient" defaultMessage="Recipient" />
          </Text>
          <FormGroup>
            <Row margin={0}>
              <Col padding={0} xs={11}>
                <Field
                  component={SelectField}
                  name="recipient"
                  options={recipients}
                />
              </Col>
              <StyledSelectCol xs={1} user>
                <IconButton
                  icon="user-plus"
                  onClick={() => handleCreateRecipient(loadRecipients)}
                />
              </StyledSelectCol>
            </Row>
          </FormGroup>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage
              id="Order of signing"
              defaultMessage="Order of signing"
            />
          </Text>
          <FormGroup>
            <Field
              component={SelectField}
              options={signingOrderOptions}
              name="orderOfSigning"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Stored for" defaultMessage="Stored for" />
          </Text>
          <FormGroup>
            <Field
              component={SelectField}
              name="retainUntil"
              options={retainOptions}
            />
          </FormGroup>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Category" defaultMessage="Category" />
          </Text>
          <FormGroup>
            <Row margin={0}>
              <Col padding={0} xs={11}>
                <Field
                  component={SelectField}
                  name="categoryId"
                  options={categories}
                />
              </Col>
              <StyledSelectCol xs={1}>
                <IconButton
                  icon="folder-plus"
                  onClick={() => handleCreateCategory(loadCategories)}
                />
              </StyledSelectCol>
            </Row>
          </FormGroup>
          <FormGroup>
            <Field
              name="file"
              component={UploadField}
              accept={['application/pdf']}
              placeholder={() => (
                <DropzonePlaceholder>
                  <div>
                    <FormattedMessage
                      id="Drag and drop here PDF max 2MB"
                      defaultMessage="Drag and drop here PDF max 2MB"
                    />
                  </div>
                  <div>
                    <FormattedMessage id="or" defaultMessage="or" />
                  </div>
                  <Button color="link" type="button">
                    <FormattedMessage
                      id="Select from disk"
                      defaultMessage="Select from disk"
                    />
                  </Button>
                </DropzonePlaceholder>
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <ModalFooter>
        <Row css={{ width: '50%' }}>
          <Button size="lg" width="100%" onClick={handleSubmit(handlePrepare)}>
            <FormattedMessage id="Next" defaultMessage="Next" />
          </Button>
        </Row>
      </ModalFooter>
    </>
  );
};
