import { Form, Grid, Utils } from 'billon-ui';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { IconInCircle } from '../../../../components/IconInCircle';
import { CUSTOMER } from '../../../../constraints';
import { CalendarField } from '../../../../containers';
import {
  applyFilters as applyFiltersAction,
  applyTariffFilters as applyTariffFiltersAction,
} from '../../actions';
import * as Styled from './styled';

import useFilters from 'js/hooks/useFilters';
import { generateFilters } from 'js/components/CommonTable/helpers';

const { SelectField, TextField } = Form;
const { Button: ButtonModule, Text } = Utils;
const { Button } = ButtonModule;
const { Row, Col } = Grid;

type FilterFormProps = {
  closeFilters: () => void;
  categories: Array<unknown>;
  isDiploma?: boolean;
};

export interface DateRange {
  from: Date;
  to: Date;
}

export interface Identity {
  documentNumber?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

export interface FilterFormFields {
  blockchainAddress?: string;
  categoryId?: number;
  creationDateRange?: DateRange;
  documentStatusList?: Array<string>;
  documentType?: string;
  identity?: Identity;
  publicationDateRange?: DateRange;
  publicationStatusList?: Array<string>;
  publishedBy?: string;
  retainUntilDateRange?: DateRange;
  signatoryId?: string;
  title?: string;
  validUntilDateRange?: DateRange;
  validSinceDateRange?: DateRange;
}

const FilterForm: React.FC<
  InjectedFormProps<FilterFormFields, FilterFormProps> & FilterFormProps
> = ({ initialValues, handleSubmit, categories, closeFilters, isDiploma }) => {
  const history = useHistory();
  const intl = useIntl();

  const customer: CUSTOMER = useSelector(({ config }: any) => config.customer);

  const { filters } = useFilters();

  const applyFilters = (filters2) => {
    history.push(
      '/' +
        history.location.pathname.split('/')[1] +
        '?' +
        generateFilters({ ...filters, ...filters2 }),
    );
  };

  const handleFilterSubmit = (values) => {
    const {
      blockchainAddress,
      publicationDateRange,
      publicationStatusList,
      publishedBy,
      title,
      validSinceDateRange,
      categoryId,
    } = values;

    const filterFormFilters = {
      ...filters.filters,
      blockchainAddress,
      categoryId: categoryId && categoryId.value,
      publicationDateRange: {
        from:
          publicationDateRange &&
          publicationDateRange[0].toISOString().replace('Z', ''),
        to:
          publicationDateRange &&
          publicationDateRange[1].toISOString().replace('Z', ''),
      },
      publicationStatusList:
        publicationStatusList &&
        publicationStatusList.map((item) => item.value),
      publishedBy: publishedBy && publishedBy.toLowerCase(),
      title: title && title.toLowerCase(),
      validSinceDateRange: {
        from:
          validSinceDateRange &&
          validSinceDateRange[0].toISOString().replace('Z', ''),
        to:
          validSinceDateRange &&
          validSinceDateRange[1].toISOString().replace('Z', ''),
      },
    };

    applyFilters({
      ...initialValues,
      filters: filterFormFilters,
    });
  };

  const publishedStatus = intl.formatMessage({
    id: 'Published',
    defaultMessage: 'Published',
  });

  const preparedStatus = intl.formatMessage({
    id: 'Prepared',
    defaultMessage: 'Prepared',
  });
  const uploadingStatus = intl.formatMessage({
    id: 'Uploading',
    defaultMessage: 'Uploading',
  });
  const invalidStatus = intl.formatMessage({
    id: 'Invalid',
    defaultMessage: 'Invalid',
  });

  const statusOptions = [
    { label: publishedStatus, value: 'PUBLISHING_OK' },
    {
      label: uploadingStatus,
      value: 'PUBLISHING_INITIATED',
    },
    {
      label: preparedStatus,
      value: 'PREPARED_TO_SIGN',
    },
    { label: invalidStatus, value: 'PUBLISHING_ERROR' },
  ];

  return (
    <Styled.FilterCard>
      <Styled.AbsoluteDivForExitIcon onClick={closeFilters}>
        <IconInCircle
          iconName="rejected_no_fill"
          size="36px"
          color="#cad5dd"
          hoverColor="#ACBBC7"
        />
      </Styled.AbsoluteDivForExitIcon>
      <form onSubmit={handleSubmit(handleFilterSubmit)}>
        <Row>
          {isDiploma && (
            <Col md={3}>
              <Styled.Text>
                <FormattedMessage id="Surname" defaultMessage="Surname" />
              </Styled.Text>
              <Field component={TextField} name="surname" />
            </Col>
          )}
          <Col md={3}>
            <Styled.Text>
              {isDiploma ? (
                <FormattedMessage
                  id="Diploma number"
                  defaultMessage="Diploma number"
                />
              ) : (
                <FormattedMessage
                  id="Document title"
                  defaultMessage="Document title"
                />
              )}
            </Styled.Text>
            <Field component={TextField} name="title" />
          </Col>
          <Col md={3}>
            {' '}
            <Styled.Text>
              <FormattedMessage id="Category" defaultMessage="Category" />
            </Styled.Text>
            <Field
              component={SelectField}
              name="categoryId"
              searchable
              options={categories}
              isClearable
            />
          </Col>
          <Col md={3}>
            <Styled.Text>
              <FormattedMessage
                id="Publication date from-to"
                defaultMessage="Publication date from-to"
              />
            </Styled.Text>
            <Field
              component={CalendarField}
              showMonths={2}
              name="publicationDateRange"
              range
              enableTime={false}
              dateFormat="Y-m-d"
            />
          </Col>
          <Col md={3}>
            <Styled.Text>
              <FormattedMessage id="Transfer by" defaultMessage="Transfer by" />
              {' ('}
              <FormattedMessage id="User name" defaultMessage="User name" />
              {')'}
            </Styled.Text>
            <Field component={TextField} name="publishedBy" />
          </Col>
          <Col md={3}>
            <Styled.Text>
              <FormattedMessage
                id="Document blockchain address"
                defaultMessage="Document blockchain address"
              />
            </Styled.Text>
            <Field component={TextField} name="blockchainAddress" />
          </Col>
          <Col md={3}>
            <Styled.Text>
              <FormattedMessage
                id="Publication status"
                defaultMessage="Publication status"
              />
            </Styled.Text>
            <Field
              multi
              component={SelectField}
              name="publicationStatusList"
              searchable
              options={statusOptions}
              isClearable
            />
          </Col>
          {isDiploma ? (
            <Col md={3}>
              <Styled.Text>
                <FormattedMessage
                  id="Index number"
                  defaultMessage="Index number"
                />
              </Styled.Text>
              <Field component={TextField} name="indexNumber" />
            </Col>
          ) : (
            <Col md={3}>
              <Styled.Text>
                <FormattedMessage
                  id="Valid from-to"
                  defaultMessage="Valid from-to"
                />
              </Styled.Text>
              <Field
                component={CalendarField}
                showMonths={2}
                name="validSinceDateRange"
                range
                enableTime={false}
                dateFormat="Y-m-d"
              />
            </Col>
          )}
          <Styled.FilterBtnCol md={3}>
            <Button outline width="100%" className="button-static">
              <FormattedMessage id="Filter" defaultMessage="Filter" />
            </Button>
          </Styled.FilterBtnCol>
        </Row>
      </form>
    </Styled.FilterCard>
  );
};
const FilterReduxForm = reduxForm<FilterFormFields, FilterFormProps>({
  form: 'filterForm',
})(FilterForm);

export default FilterReduxForm;
