import React from 'react';
import { Form as FormModule } from 'billon-ui';
import { connect } from 'react-redux';

const { CalendarField } = FormModule;

const CF = (props) => <CalendarField {...props} />;

const mapStateToProps = ({ config, language }) => ({
  locale: language.language || config.language,
});

export default connect(mapStateToProps)(CF);
