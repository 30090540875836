import React from 'react';
import { Grid, Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  UserInfo,
  CopyId,
  DetailsSection,
  PasswordManager,
  AccountType,
} from './components';
import { USER_STATUSES } from 'js/constraints';
import { ProtectedComponent } from 'js/modules/Auth/components';
import { UserRoles } from 'js/constraints';

const { Button: ButtonModule, Text } = Utils;
const { Button, BackButton } = ButtonModule;
const { Row, Col } = Grid;

const UserDetails = ({
  user,
  settings,
  onBackClick,
  customer,
  backButtonLabel,
  handleCreate,
  handleDelete,
}) => {
  if (!user) {
    onBackClick();
  }

  return (
    <>
      <Row margin={'0 0 20px 0 '}>
        <BackButton secondary onClick={onBackClick}>
          {backButtonLabel}
        </BackButton>
      </Row>
      <Row margin={0} md={12}>
        <Col md={5}>
          <Row margin={'0 0 20px 0 '}>
            <UserInfo
              scale="big"
              name={`${user.firstName} ${user.lastName}`}
              iconDisabled={false}
            >
              <Col padding={'0 0 0 0 '} md={12}>
                {user.isRobot && <AccountType />}
                <CopyId id={user.id} />

                <ProtectedComponent roles={[UserRoles.ADMIN]} render={false}>
                  <Button outline onClick={handleCreate} block>
                    <FormattedMessage id="Edit" defaultMessage="Edit" />
                  </Button>
                  {[USER_STATUSES.SHELL, USER_STATUSES.REGISTERED].includes(
                    user.status,
                  ) && (
                    <PasswordManager
                      scale="big"
                      settings={settings}
                      user={user}
                    />
                  )}
                </ProtectedComponent>
              </Col>
            </UserInfo>
          </Row>
        </Col>

        <Col md={7}>
          <DetailsSection user={user} />
        </Col>
      </Row>
    </>
  );
};

UserDetails.propTypes = {
  user: PropTypes.object.isRequired,
  customer: PropTypes.object,
  onBackClick: PropTypes.func,
  backButtonLabel: PropTypes.string,
  handleCreate: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default UserDetails;
