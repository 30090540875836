import React from 'react';
import * as Styled from './styled';
import { FormattedMessage } from 'react-intl';
import { Grid, Utils } from 'billon-ui';
import {
  momentFormatter,
  momentFormatterWithoutTime,
} from '../../../../../../formatters';
import { MAP_DOCUMENT_STATUSES } from '../../../../../../constraints';

const { Col, Row } = Grid;
const { Text } = Utils;

const DetailsSection = ({ document, publisherName }) => {
  const enabledDetailsCol1 = [
    {
      title: 'Publication start date',
      data: momentFormatter(document.publicationStartDate),
    },
    {
      title: 'Publication date',
      data: momentFormatter(document.publicationDate),
    },
    {
      title: 'Publication status',
      data: (
        <FormattedMessage
          id={MAP_DOCUMENT_STATUSES[document.status]}
          defaultMessage={MAP_DOCUMENT_STATUSES[document.status]}
        />
      ),
    },
    {
      title: 'Publisher',
      data: publisherName ? publisherName : '---',
    },
  ];

  const enabledDetailsCol2 = [
    {
      title: 'Retain until',
      data: momentFormatterWithoutTime(document.retainUntil),
    },
    {
      title: 'Category',
      data: document.category.fullPath,
    },
  ];

  return (
    <>
      <Row margin={0} md={12}>
        <Col>
          <Text
            as={'h1'}
            fontSize={'18px'}
            margin={'0 0 11px 0'}
            fontWeight={700}
          >
            <FormattedMessage
              id="Document details"
              defaultMessage="Document details"
            />
            :
          </Text>
        </Col>
      </Row>
      <Row margin={0} md={12}>
        <Styled.DetailsCol md={6}>
          {enabledDetailsCol1.map((v) => (
            <Row margin={0}>
              <Text margin="0 10px 0 0" fontSize="12px" fontWeight={700}>
                <FormattedMessage id={v.title} defaultMessage={v.title} />:
              </Text>
              <Text fontSize="12px">{v.data}</Text>
            </Row>
          ))}
        </Styled.DetailsCol>
        <Styled.DetailsCol md={6}>
          {enabledDetailsCol2.map((v) => (
            <Row margin={0}>
              <Text margin="0 10px 0 0" fontSize="12px" fontWeight={700}>
                <FormattedMessage id={v.title} defaultMessage={v.title} />:
              </Text>
              <Text fontSize="12px">{v.data}</Text>
            </Row>
          ))}
        </Styled.DetailsCol>
      </Row>
    </>
  );
};

export default DetailsSection;
