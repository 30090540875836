import { createAction } from 'redux-act';
import { Utils } from 'billon-ui';

const { helpers } = Utils;
const { encodeQueryString } = helpers;

// Apply Filters
export const applyFilters = (history, filters) =>
  history.push(`/requests/${encodeQueryString(filters)}`);

// Request documents list
export const requestList = createAction(
  'Request requests list',
  (filters, ...rest) => ({ filters, ...rest }),
);
export const receiveListSuccess = createAction('Request requests list success');
export const receiveListFailure = createAction('Request requests list failure');

// Request single documents
export const requestSingle = createAction(
  'Request single request',
  (id, ...rest) => ({ id, ...rest }),
);
export const receiveSingleSuccess = createAction(
  'Request single request success',
);
export const receiveSingleFailure = createAction(
  'Request single request failure',
);

// Sending request decision
export const sendRequestDecision = createAction(
  'Send request decision',
  (values, meta, ...rest) => ({
    values,
    meta,
    ...rest,
  }),
);
export const receiveSendRequestDecisionSuccess = createAction(
  'Send request decision success',
);
export const receiveSendRequestDecisionFailure = createAction(
  'Send request decision failure',
);

// Download document
export const requestDownload = createAction(
  'Download document',
  (blockchainAddress, jobId, ...rest) => ({
    blockchainAddress,
    jobId,
    ...rest,
  }),
);
export const receiveDownloadSuccess = createAction('Download document success');
export const receiveDownloadFailure = createAction('Download document failure');
