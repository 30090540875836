import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { connect } from 'react-redux';

import 'flatpickr/dist/l10n/pl';
import 'flatpickr/dist/flatpickr.min.css';

import FormControl from '../FormControl';
import { FlatpickrWrapper } from './styled';

const CalendarField = (props) => {
  const [flatpickr, setFlatpickr] = useState(null);
  const [readyToClose, setReadyToClose] = useState(false);

  const {
    input,
    meta: { touched, active, dirty, error, warning },
    placeholder,
    disabled,
    value,
    enableTime,
    maxDate,
    minDate,
    range,
    dateFormat,
    locale,
    time_24hr, // eslint-disable-line camelcase
    onPickDate,
    disableDates,
    enableDates,
    parseDate,
    showMonths,
    ...rest
  } = props;

  useEffect(() => {
    if (input?.value) setReadyToClose(true);
  }, []);

  return (
    <FormControl
      active={active}
      touched={touched}
      dirty={dirty || !!input.value}
      error={error}
      warning={warning}
      disabled={disabled}
      {...rest}
      icon="calendar-alt"
      onClick={() => {
        input.onFocus();
        flatpickr.open();
      }}
      readyToClose={readyToClose}
      onClear={() => {
        flatpickr.clear();
        setFlatpickr(flatpickr);
        setReadyToClose(false);
      }}
    >
      <FlatpickrWrapper
        onChange={(date) => {
          input.onChange(
            !range
              ? isFunction(parseDate)
                ? parseDate(date[0])
                : date[0]
              : date,
          );
          if (isFunction(onPickDate)) {
            onPickDate(date);
          }
          if (date) {
            setReadyToClose(true);
          }
        }}
        onClose={(date) => {
          input.onBlur(!range ? date[0] : date);
        }}
        onCreate={(flatpickr) => setFlatpickr(flatpickr)}
        value={input.value}
        disabled={disabled}
        placeholder={placeholder}
        options={{
          enableTime,
          disable: disableDates,
          enable:
            enableDates.length > 0
              ? enableDates
              : [
                  {
                    from: '1900-01-01',
                    to: '2200-01-01',
                  },
                ],
          altInput: true,
          maxDate,
          minDate,
          mode: range ? 'range' : 'single',
          locale,
          time_24hr,
          altFormat: dateFormat,
          defaultDate: input.value,
          disableMobile: true,
          showMonths: showMonths,
        }}
      />
    </FormControl>
  );
};

CalendarField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  enableTime: PropTypes.bool,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  range: PropTypes.bool,
  dateFormat: PropTypes.string,
  locale: PropTypes.string,
  time_24hr: PropTypes.bool,
  onPickDate: PropTypes.func,
  disableDates: PropTypes.array,
  enableDates: PropTypes.array,
  parseDate: PropTypes.func,
  showMonths: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CalendarField.defaultProps = {
  enableTime: true,
  range: false,
  dateFormat: 'Y-m-d H:i',
  locale: 'pl',
  time_24hr: true,
  disableDates: [],
  enableDates: [],
  showMonths: 1,
};

const CF = (props) => <CalendarField {...props} />;

const mapStateToProps = ({ config, language }) => ({
  locale: language.language || config.language,
});

export default connect(mapStateToProps)(CF);
