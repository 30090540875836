import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage } from 'react-intl';

import { Form as FormModule, Grid, Utils } from 'billon-ui';

import { DropzonePlaceholder } from '../DropzonePlaceholder';

import * as Styled from './styled';

import { useSchema } from './useSchema';

// Hooks
import { useDownloadSingleDocument } from '../../hooks';

const { UploadField } = FormModule;
const { Button: ButtonModule, Text } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { Row, Col } = Grid;

export const DocumentSigningForm = ({ onSuccess, documentTitle, jobId }) => {
  const schema = useSchema();

  const { mutate: downloadFile, isLoading: isDownloadingFile } =
    useDownloadSingleDocument(jobId, documentTitle);

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm({
    defaultValues: {
      file: '',
    },
    resolver: yupResolver(schema),
  });

  return (
    <>
      <Styled.Form onSubmit={handleSubmit((values) => onSuccess(values))}>
        <Text as="h2" fontSize="32px">
          <FormattedMessage id="Sign document" defaultMessage="Sign document" />
        </Text>
        <Text as="p" fontSize="15px" fontWeight={700}>
          <FormattedMessage
            id="We have prepared a document for you, download it, sign it and then add it here for publishing"
            defaultMessage="We have prepared a document for you, download it, sign it and then add it here for publishing"
          />
        </Text>
        <br />

        <Row css={{ width: '100%' }} margin={'0'}>
          <Col xl={6} margin={'0 0 20px 0'}>
            <Text as="h4" fontSize="16px" fontWeight={700}>
              1.{' '}
              <FormattedMessage
                id="Download document to sign"
                defaultMessage="Download document to sign"
              />
            </Text>
            <DropzonePlaceholder>
              <Text>{documentTitle}</Text>

              <Styled.CenterRow>
                <Col sm={6}>
                  {isDownloadingFile ? (
                    <ButtonLoader size="lg" block />
                  ) : (
                    <Button onClick={downloadFile} outline type="button" block>
                      <FormattedMessage
                        id="Download"
                        defaultMessage="Download"
                      />
                    </Button>
                  )}
                </Col>
              </Styled.CenterRow>
            </DropzonePlaceholder>
          </Col>
          <Col xl={6} margin={'0 0 20px 0'}>
            <Text as="h4" fontSize="16px" fontWeight={700}>
              2.{' '}
              <FormattedMessage
                id="Add signed document"
                defaultMessage="Add signed document"
              />
            </Text>

            <Controller
              name="file"
              control={control}
              render={({ field, fieldState }) => (
                <UploadField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  accept={['application/pdf']}
                  placeholder={() => (
                    <DropzonePlaceholder>
                      <div>
                        <FormattedMessage
                          id="Drag and drop here PDF max 2MB"
                          defaultMessage="Drag and drop here PDF max 2MB"
                        />
                      </div>
                      <div>
                        <FormattedMessage id="or" defaultMessage="or" />
                      </div>
                      <Styled.CenterRow>
                        <Col sm={6}>
                          <Button outline type="button" block>
                            <FormattedMessage
                              id="Select from disk"
                              defaultMessage="Select from disk"
                            />
                          </Button>
                        </Col>
                      </Styled.CenterRow>
                    </DropzonePlaceholder>
                  )}
                />
              )}
            />
          </Col>
        </Row>

        <Styled.SubmitButtonRow>
          <Col xl={6}>
            {!isSubmitting ? (
              <Button type="submit" size="lg" block>
                <FormattedMessage
                  id="Publish document"
                  defaultMessage="Publish document"
                />
              </Button>
            ) : (
              <ButtonLoader size="lg" block />
            )}
          </Col>
        </Styled.SubmitButtonRow>
      </Styled.Form>
    </>
  );
};
