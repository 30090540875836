import styled from 'styled-components';
import { Table as TableModule } from 'billon-ui';

const { TableCell, TableColumn } = TableModule;

export const DocumentInfo = styled.div`
  .btn {
    margin-bottom: 0.3rem;
  }

  .info-col {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .border-right {
    border-right: 1px solid ${(props) => props.theme.palette.grayLight};
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      font-size: ${(props) => props.theme.fontSizeSm};
      margin-bottom: 1rem;

      label {
        display: block;
        font-weight: ${(props) => props.theme.fontWeightBold};
        margin-bottom: 0;
      }
    }
  }
`;

export const TC = styled(TableCell)`
  white-space: nowrap;
  font-weight: ${(props) =>
    props.row.isNewlyPublished
      ? props.theme.fontWeightBold
      : props.theme.fontWeightBase};
`;

export const TCCentered = styled(TC)`
  text-align: center;
`;

export const TR = styled.tr`
  font-weight: ${(props) =>
    props.isNewlyPublished
      ? props.theme.fontWeightBold
      : props.theme.fontWeightBase};
  color: ${(props) => (props.isNotAvailable ? '#aaaaaa' : 'inherit')};
`;

export const ActionButton = styled.span`
  svg {
    fill: ${(props) => props.theme.palette.grayLighter};
  }

  svg:hover {
    fill: black;
  }
`;

export const DetailsTitle = styled.span`
  text-align: left;
  padding-bottom: 0.5rem;
  padding-left: 11.2px;
  font-size: 0.75rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export const EmbededTableWrapper = styled.div`
  margin-left: -11.2px;
  margin-right: -11.2px;
`;

export const EmbededCopiedInfoWrapper = styled.div`
  margin-bottom: 16px;
  margin-top: -16px;
`;
