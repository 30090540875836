/* global sessionStorage */
import { Utils, Authentication } from 'billon-ui';
import { ajax } from 'rxjs/observable/dom/ajax';
import { Observable } from 'rxjs/Rx';
import {
  requestList,
  requestListQuiet,
  requestListSuccess,
  requestListFailure,
  requestSingle,
  receiveSingleSuccess,
  receiveSingleFailure,
  requestSave,
  receiveSaveSuccess,
  receiveSaveFailure,
  requestDelete,
  receiveDeleteSuccess,
  receiveDeleteFailure,
} from './actions';
import routes from '../../api-routes';
import { parseFilters } from './helpers';

import qs from 'qs';
import { receiveForbidden } from 'js/ui/Authentication/actions';

const { requestLogout, receiveLogoutSuccess } = Authentication.actions;
const { helpers } = Utils;
const { request, encodeQueryString } = helpers;
const {
  METHOD_GET,
  HEADER_CONTENT_TYPE,
  CONTENT_TYPE_APPLICATION_JSON,
  METHOD_POST,
  METHOD_DELETE,
  METHOD_PATCH,
} = request;

const getUserListEpic = (actions$) =>
  actions$.ofType(requestList.getType()).mergeMap((action) =>
    ajax({
      url: `${routes.USER}?${qs.stringify(
        parseFilters(action.payload.filters),
        { allowDots: true },
      )}`,
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => requestListSuccess(response))
      .catch((err) =>
        Observable.of(receiveForbidden({ toRetryAction: action })),
      ),
  );

const getUserListQuietEpic = (actions$) =>
  actions$.ofType(requestListQuiet.getType()).mergeMap((action) =>
    ajax({
      url: `${routes.USER}?${qs.stringify(
        parseFilters(action.payload.filters),
        { allowDots: true },
      )}`,

      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => requestListSuccess(response))
      .catch((err) =>
        Observable.of(receiveForbidden({ toRetryAction: action })),
      ),
  );

const getUserSingleEpic = (actions$) =>
  actions$.ofType(requestSingle.getType()).mergeMap((action) =>
    ajax({
      url: routes.SINGLE_USER.replace('{id}', action.payload.id),
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => receiveSingleSuccess(response))
      .takeUntil(actions$.ofType(requestSingle.getType()))
      .catch((err) => Observable.of(receiveSingleFailure(err))),
  );

const saveSuccessEpic = (actions$) =>
  actions$
    .ofType(receiveSaveSuccess.getType())
    .mergeMap(() => window.location.reload());

const saveUserEpic = (actions$) =>
  actions$.ofType(requestSave.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;

    const {
      id,
      username,
      firstName,
      lastName,
      email,
      phoneNumber,
      department,
      role,
      notify,
      isActive,
    } = action.payload.values;

    const userData = {
      department,
      email,
      firstName,
      isActive,
      lastName,
      notify,
      phoneNumber,
      role,
      username,
    };

    const user = {
      department,
      email,
      firstName,
      isActive,
      lastName,
      phoneNumber,
      role,
    };

    return ajax({
      url: id ? routes.SINGLE_USER.replace('{id}', id) : routes.USER,
      body: id ? user : JSON.stringify(userData),
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: sessionStorage.getItem('token'),
      },
      method: id ? METHOD_PATCH : METHOD_POST,
    })
      .map(({ response }) => {
        if (resolve) {
          resolve(response);
        }
        return receiveSaveSuccess(response);
      })
      .takeUntil(actions$.ofType(requestSave.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }

        return Observable.of(receiveSaveFailure(err));
      });
  });

const deleteUserEpic = (actions$) =>
  actions$.ofType(requestDelete.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { id } = action.payload;

    return ajax({
      url: routes.SINGLE_USER.replace('{id}', id),
      body: JSON.stringify(action.payload.params),
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_DELETE,
    })
      .map(({ response }) => {
        if (resolve) {
          resolve(response);
        }
        return receiveDeleteSuccess(response);
      })
      .takeUntil(actions$.ofType(requestDelete.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }
        return Observable.of(receiveDeleteFailure(err));
      });
  });

export const logoutEpic = (actions$) =>
  actions$.ofType(requestLogout.getType()).mergeMap(() =>
    ajax({
      url: routes.LOGOUT,
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_POST,
    }).map(() => receiveLogoutSuccess()),
  );

export default [
  getUserListEpic,
  getUserListQuietEpic,
  getUserSingleEpic,
  saveUserEpic,
  deleteUserEpic,
  logoutEpic,
  saveSuccessEpic,
];
