import qs from 'qs';


export type SelectItem = {
  label: string;
  value: number;
};

export type Column = {
  name: string;
  code?: string;
  decodeRequired?: boolean;
  selectMap?: Array<SelectItem>;
  message: string;
  formatter: Function;
  sortable?: boolean;
  className?: string,
};

export interface CommonTableSearchPagination {
  limit: number;
  page: number;
}

export interface CommonTableSearchSort {
  order: string;
  sortBy: string;
}

export interface CommonTableSearchParams {
  pagination: CommonTableSearchPagination;
  sort?: CommonTableSearchSort;
}

export type CommonTableSearchFilterParams<T> = CommonTableSearchParams & T;

export const getSearchParams = (
  rawFilters: any,
  defaultLimit: number,
): CommonTableSearchFilterParams<any> => ({
  ...rawFilters,
  pagination: {
    limit: defaultLimit,
    page: rawFilters.pagination?.page || 1,
  }
});

export const generateFilters = (filters) => {
  return (qs.stringify(filters, { allowDots: true }));
};
