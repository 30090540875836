/* global FormData */
import { Utils } from 'billon-ui';
import { isUndefined } from 'lodash';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DOCUMENT_STATUS, RETAIN_OPTIONS, UTILS } from '../../constraints';

const { helpers } = Utils;
const { parseFilters: superParseFilters } = helpers;

export const parseFilters = (filters) => {
  const output = superParseFilters(filters);

  if (filters.order) {
    output.order = filters.order;
  }

  if (filters.include) {
    output.include = filters.include;
  }

  if (filters.searchBar) {
    output.where.title = filters.searchBar;
  }

  if (filters.type) {
    output.where.type = filters.type;
  }

  if (filters.recipientId) {
    output.where.issuedNumber = filters.recipientId;
  }

  if (filters.recipient) {
    output.where.issuedNumber = filters.recipient.value;
  }

  if (filters.title) {
    output.where.title = filters.title;
  }

  if (filters.nameAndSurname) {
    output.where.nameAndSurname = filters.nameAndSurname;
  }
  if (filters.indexNumber) {
    output.where.issuedNumber = filters.indexNumber;
  }

  if (filters.documentType) {
    let agreement = '';
    switch (filters.documentType) {
      case 1:
        agreement = 'WAITING-FOR-SENDER';
        break;
      case 2:
        agreement = 'WAITING-FOR-RECEIVER';
        break;
      case 3:
        agreement = 'PUBLISHED';
        break;
      case 4:
        agreement = 'REJECTED';
        break;
      case 5:
        agreement = 'ERROR';
        break;
      default:
        agreement = '';
    }
    output.where.agreementStatus = agreement;
  }

  // if (filters.signDate && filters.signDate.length) {

  // }

  if (filters.category) {
    output.where.categoryId = filters.category.value;
  }

  if (filters.publicationDate) {
    output.where.publicationDate = {};

    if (Array.isArray(filters.publicationDate)) {
      output.where.publicationDate.from = filters.publicationDate[0];
      output.where.publicationDate.to = filters.publicationDate[1];
    }
    if (filters.publicationDate.from) {
      output.where.publicationDate.from = filters.publicationDate.from;
    }

    if (filters.publicationDate.to) {
      output.where.publicationDate.to = filters.publicationDate.to;
    }
  }

  if (filters.issueDate && filters.issueDate.length) {
    output.where.issueDate = {};

    if (Array.isArray(filters.issueDate)) {
      output.where.issueDate.from = filters.issueDate[0];
      output.where.issueDate.to = filters.issueDate[1];
    }
  }

  if (filters.validFromTo && filters.validFromTo.length) {
    output.where.validSince = {};
    output.where.validUntil = {};

    if (Array.isArray(filters.validFromTo)) {
      output.where.validSince.from = filters.validFromTo[0];
      output.where.validUntil.to = filters.validFromTo[1];
    }
  }

  if (filters.validSince) {
    output.where.validSince = {};
    if (filters.validSince.from) {
      output.where.validSince.from = filters.validSince.from;
    }

    if (filters.validSince.to) {
      output.where.validSince.to = filters.validSince.to;
    }
  }

  if (filters.validUntil) {
    output.where.validUntil = {};
    if (filters.validUntil.from) {
      output.where.validUntil.from = filters.validUntil.from;
    }
    if (filters.validUntil.to) {
      output.where.validUntil.to = filters.validUntil.to;
    }
  }

  if (!isUndefined(filters.isOld)) {
    output.where.isOld = filters.isOld;
  }

  if (filters.status) {
    output.where.status = filters.status.value;
  }

  if (filters.statusList) {
    output.where.statusList = filters.statusList.value;
  }

  if (filters.latest) {
    output.where.latest = filters.latest;
  }

  return output;
};

export const parseSave = (values) => {
  const formData = new FormData();
  values.file && values.file[0] && formData.append('file', values.file[0]);
  formData.append('title', values.title);

  if (values.hasOwnProperty('isOneStepPublication')) {
    formData.append('isOneStepPublication', values.isOneStepPublication);
  }

  if (values.recipient) {
    if (values.recipient.value) {
      formData.append('recipient', values.recipient.value);
    }
    if (values.recipient.id) {
      formData.append('recipient', values.recipient.id);
    }

    // if(values.recipient.surname) {
    //   formData.append("surename", values.recipient.surname);
    // }
  }
  if (values.recipientsToNotify) {
    formData.append('recipientsToNotify', values.recipientsToNotify[0]);
  }

  if (values.comment) {
    formData.append('comment', values.comment);
  }

  if (values.versionName) {
    formData.append('versionName', values.versionName);
  }
  if (values.signedBy) {
    formData.append('signedBy', values.signedBy);
  }

  if (values.documentIndex) {
    formData.append('documentIndex', values.documentIndex);
  }

  if (values.documentType) {
    formData.append('documentType', values.documentType.value);
  }

  if (values.needsSign) {
    formData.append('needsSign', values.needsSign);
  }

  if (values.categoryId) {
    formData.append('categoryId', values.categoryId.value);
  }

  if (values.orderOfSigning) {
    formData.append('orderOfSigning', values.orderOfSigning.value);
  }

  if (values.validSince) {
    formData.append(
      'validSince',
      moment(values.validSince).format(UTILS.CALENDAR_DATE_FORMAT),
    );
  }

  if (values.validUntil) {
    formData.append(
      'validUntil',
      moment(values.validUntil).format(UTILS.CALENDAR_DATE_FORMAT),
    );
  }

  if (values.retainUntil) {
    // formData.append(
    //   "retainUntil",
    //   moment(values.retainUntil).format(UTILS.CALENDAR_DATE_FORMAT)
    // );
    formData.append(
      'retainUntil',
      moment(mapRetain(values.retainUntil.value)).format(
        UTILS.CALENDAR_DATE_FORMAT,
      ),
    );
  }

  if (values.type) {
    formData.append('type', values.type);
  }

  if (values.previousDocumentBlockchainAddress) {
    formData.append(
      'previousDocumentBlockchainAddress',
      values.previousDocumentBlockchainAddress,
    );
  }

  formData.append('errata', values.errata);

  return formData;
};

export const parsePublish = (values) => {
  const formData = parseSave(values);

  formData.append('jobId', values.jobId);

  return formData;
};

export const mapRetain = (value) => {
  switch (value) {
    case RETAIN_OPTIONS.YEARS_1:
      return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    case RETAIN_OPTIONS.YEARS_6:
      return new Date(new Date().setFullYear(new Date().getFullYear() + 6));
    case RETAIN_OPTIONS.YEARS_10:
      return new Date(new Date().setFullYear(new Date().getFullYear() + 10));
    case RETAIN_OPTIONS.YEARS_20:
      return new Date(new Date().setFullYear(new Date().getFullYear() + 20));
    case RETAIN_OPTIONS.YEARS_50:
      return new Date(new Date().setFullYear(new Date().getFullYear() + 50));
    default:
      return new Date(new Date().setFullYear(new Date().getFullYear() + 6));
  }
};

export const mapStatuses = (value) => {
  switch (value) {
    case DOCUMENT_STATUS.CURRENT:
      return <FormattedMessage id="Published" defaultMessage="Published" />;
    case DOCUMENT_STATUS.NOT_EXIST:
      return <FormattedMessage id="Error" defaultMessage="Error" />;
    case DOCUMENT_STATUS.PREPARED_TO_SIGN:
      return <FormattedMessage id="Prepared" defaultMessage="Prepared" />;
    case DOCUMENT_STATUS.DEPRECATED:
      return (
        <FormattedMessage
          id="Previous Version"
          defaultMessage="Previous Version"
        />
      );
    case DOCUMENT_STATUS.RETIRED:
      return <FormattedMessage id="Retired" defaultMessage="Retired" />;
    case DOCUMENT_STATUS.UPLOADING:
      return <FormattedMessage id="Uploading" defaultMessage="Uploading" />;
    case DOCUMENT_STATUS.NOT_AVAILABLE:
      return <FormattedMessage id="Forgetting" defaultMessage="Forgetting" />;
    default:
      return <FormattedMessage id="Invalid" defaultMessage="Invalid" />;
  }
};
