import React from 'react';
import download from 'downloadjs';
import qrcode from 'qrcode-generator';

export const downloadQrCode = (reader, address) => {
  const qr = qrcode(0, 'L');
  qr.addData(`${reader}/${address}`);
  qr.make();
  download(qr.createDataURL(), `${address}.gif`, 'image/gif');
};
