import React from 'react';
import { Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';

const { Text } = Utils;

const AccountType = () => (
  <Text fontSize={'13px'} margin={'7px 0 20px 0 '} fontWeight={700}>
    <FormattedMessage id="Account type2" defaultMessage="Account type2" />
    {': '}
    <Styled.LightWeight>{'Bot'}</Styled.LightWeight>
  </Text>
);

export default AccountType;
