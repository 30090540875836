import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form as FormModule, Utils } from 'billon-ui';
import {
  password_role_based_info_list,
  password_other_info_list,
  CUSTOMER,
  VALIDATION_ERRORS,
  VALIDATION_ERROR_TYPES,
  MAP_VALIDATION_ERROR_TYPES,
  MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS,
} from 'js/constraints';
import { UserRoles } from 'js/userRoles';
import { useValidatePassword } from 'js/modules/Settings/hooks/useValidatePassword';
import { useForm, Controller } from 'react-hook-form';
import * as Styled from './styled';
import ValidationInfo from '../ValidationInfo';
import { removeUndefinedValues } from 'js/helpers/removeUndefinedValues';
import { PASSWORD_FORM_MODES } from 'js/constraints';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSchema } from '../PasswordForm/useSchema';
import config from 'js/config';
import { union } from 'lodash';

const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { FormGroup, PasswordField } = FormModule;
const { customer } = config;

const PasswordForm = ({
  token,
  role,
  settings,
  formConfig,
  deliveredReasons,
}) => {
  const {
    mode,
    handleSuccess: onSuccess,
    submitMessageId,
    passwordLabelId,
    repeatPasswordLabelId,
  } = formConfig;

  const keyPrefix = role === UserRoles.ADMIN ? UserRoles.ADMIN : 'USER';

  const retrieveSettingValue = (settingsKey) =>
    settings?.find((item) => item.key === settingsKey)?.value;

  const validationConstraints = {
    oldPasswordEnabled: mode === PASSWORD_FORM_MODES.PASSWORD_CHANGE,
    minLength: retrieveSettingValue(
      `${keyPrefix}_${
        MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS[VALIDATION_ERROR_TYPES.TOO_SHORT]
      }`,
    ),
    complexity: retrieveSettingValue(
      `${keyPrefix}_${
        MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS[
          VALIDATION_ERROR_TYPES.NO_UNIQUE_CHARACTERS
        ]
      }`,
    ),
  };

  const schema = useSchema({ validationConstraints: validationConstraints });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    getValues,
    setError,
  } = useForm({ resolver: yupResolver(schema) });

  const [reasons, setReasons] = useState(deliveredReasons);

  useEffect(() => {
    setReasons(union(reasons, deliveredReasons));
    if (deliveredReasons.includes(VALIDATION_ERROR_TYPES.IDENTICAL)) {
      setError('password', {
        type: 'custom',
        message: VALIDATION_ERRORS.EMPTY,
      });
      setError('repeatPassword', {
        type: 'custom',
        message: MAP_VALIDATION_ERROR_TYPES.IDENTICAL,
      });
    }
    if (deliveredReasons.includes(VALIDATION_ERROR_TYPES.CHANGED_TOO_OFTEN)) {
      setError('password', {
        type: 'custom',
        message: VALIDATION_ERRORS.EMPTY,
      });
      setError('repeatPassword', {
        type: 'custom',
        message: VALIDATION_ERRORS.EMPTY,
      });
    }
  }, [deliveredReasons]);

  const handleError = ({ response }) => {
    setReasons(response?.data?.reasons || []);
  };

  const { mutate: validatePassword } = useValidatePassword({
    token: token,
    onSuccess: () => onSuccess(getValues()),
    onError: handleError,
  });

  const onSubmit = async (values) => {
    const { oldPassword, password, repeatPassword } = values;

    const body = { oldPassword: oldPassword, password: password };

    if (customer === CUSTOMER.TAURON) {
      onSuccess(getValues());
    } else {
      validatePassword({
        data: removeUndefinedValues(body),
      });
    }
  };

  const PasswordRoleBasedInfo = () =>
    role &&
    password_role_based_info_list.map((requirement) => {
      const keyPrefix = role === UserRoles.ADMIN ? UserRoles.ADMIN : 'USER';
      const settingsKey = `${keyPrefix}_${MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS[requirement]}`;
      const isErrorActive =
        (reasons && reasons.includes(requirement)) ||
        Object.entries(errors).find(
          ([key, value]) => value?.type === requirement,
        )
          ? true
          : false;
      return (
        <ValidationInfo
          settingsKey={settingsKey}
          requirement={requirement}
          settings={settings}
          isErrorActive={isErrorActive}
        />
      );
    });

  const PasswordOtherInfo = () =>
    password_other_info_list.map((requirement) => {
      const isErrorActive =
        (reasons && reasons.includes(requirement)) ||
        Object.entries(errors).find(
          ([key, value]) => value?.type === requirement,
        )
          ? true
          : false;
      return (
        <ValidationInfo
          settingsKey={MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS[requirement]}
          requirement={requirement}
          settings={settings}
          isErrorActive={isErrorActive}
        />
      );
    });

  return (
    <Styled.Form>
      {mode === PASSWORD_FORM_MODES.PASSWORD_CHANGE && (
        <Controller
          name="oldPassword"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <PasswordField
                input={field}
                meta={{
                  dirty: fieldState.isDirty,
                  touched: fieldState.invalid,
                  error: errors?.[field.name]?.message,
                }}
                label={
                  <FormattedMessage
                    id="Old password"
                    defaultMessage="Old password"
                  />
                }
              />
            );
          }}
        />
      )}

      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState }) => {
          return (
            <PasswordField
              input={field}
              meta={{
                dirty: fieldState.isDirty,
                touched: fieldState.invalid,
                error: errors?.[field.name]?.message,
              }}
              label={
                <FormattedMessage
                  id={passwordLabelId}
                  defaultMessage={passwordLabelId}
                />
              }
            />
          );
        }}
      />

      <Styled.ValidationIndicatorRow>
        <Styled.ValidationIndicatorLine />
        <Styled.ValidationIndicatorLine />
        <Styled.ValidationIndicatorLine />
      </Styled.ValidationIndicatorRow>

      <Controller
        name="repeatPassword"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState }) => {
          return (
            <PasswordField
              input={field}
              meta={{
                dirty: fieldState.isDirty,
                touched: fieldState.invalid,
                error: errors?.[field.name]?.message,
              }}
              label={
                <FormattedMessage
                  id={repeatPasswordLabelId}
                  defaultMessage={repeatPasswordLabelId}
                />
              }
            />
          );
        }}
      />

      <PasswordRoleBasedInfo />
      <PasswordOtherInfo />

      <Styled.CardBodyWrapper>
        <FormGroup>
          {isSubmitting ? (
            <ButtonLoader block size="lg" />
          ) : (
            <Button
              type="submit"
              size="lg"
              block
              onClick={handleSubmit(onSubmit)}
            >
              <FormattedMessage
                id={submitMessageId}
                defaultMessage={submitMessageId}
              />
            </Button>
          )}
        </FormGroup>
      </Styled.CardBodyWrapper>
    </Styled.Form>
  );
};

PasswordForm.propTypes = {
  token: PropTypes.object,
  role: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  formConfig: PropTypes.object.isRequired,
};

export default PasswordForm;
