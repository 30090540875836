import { Table as TableModule, Utils } from 'billon-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DOCUMENT_STATUS } from '../../../../constraints';
import * as formatters from '../../../../formatters';
import { DocumentRowWithDetails } from './DocumentRowWithDetails';

const { Table, TableColumn, TableCell } = TableModule;
const { Pagination, helpers } = Utils;
const { Card } = Utils;

const { getNumberOfPages } = helpers;

const TC = styled(TableCell)`
  white-space: nowrap;
  font-weight: ${(props) =>
    props.row.isNewlyPublished
      ? props.theme.fontWeightBold
      : props.theme.fontWeightBase};
`;

const TR = styled.tr`
  font-weight: ${(props) =>
    props.isNewlyPublished
      ? props.theme.fontWeightBold
      : props.theme.fontWeightBase};
  color: ${(props) => (props.isNotAvailable ? '#aaaaaa' : 'inherit')};
`;

const StyledTableCard = styled(Card)`
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  margin-bottom: 80px;
`;

export const DocumentsTable = (props) => {
  return (
    <StyledTableCard>
      <Table
        responsive
        tableData={props.records}
        sortMethod={props.handleSort}
        detailsComponent={(details) => (
          <DocumentRowWithDetails
            openDetailsPage={props.openDetailsPage}
            details={details}
            openPublishModalFromList={props.openPublishModalFromList}
            handleDelete={props.handleDelete}
            onCopied={props.onCopied}
            changeCopyHoverTrue={props.changeCopyHoverTrue}
            changeCopyHoverFalse={props.changeCopyHoverFalse}
            copyHoverState={props.copyHoverState}
            copiedState={props.copiedState}
            settings={props.settings}
            activeDelivery={props.activeDelivery}
          />
        )}
        rowComponent={(row, props) => {
          return (
            <TR
              isNewlyPublished={row.isNewlyPublished}
              isNotAvailable={row.status === DOCUMENT_STATUS.NOT_AVAILABLE}
              {...props}
            />
          );
        }}
      >
        <TableColumn
          fieldName="title"
          formatter={formatters.boundLengthFormatterLong}
          sortable
          asc={props.order && props.order.title && props.order.title === 'ASC'}
          renderCell={(row, props) => <TC row={row} {...props} />}
        >
          <FormattedMessage id="Title" defaultMessage="Title" />
        </TableColumn>
        <TableColumn
          fieldName="publicationDate"
          formatter={formatters.momentFormatter}
          sortable
          asc={
            props.order &&
            props.order.publicationDate &&
            props.order.publicationDate === 'ASC'
          }
          renderCell={(row, props) => <TC row={row} {...props} />}
        >
          <FormattedMessage
            id="Date of transfer"
            defaultMessage="Date of transfer"
          />
        </TableColumn>
        <TableColumn
          fieldName="documentMainCategory"
          formatter={formatters.boundLengthFormatterLong}
          sortable
          asc={
            props.order &&
            props.order.documentMainCategory &&
            props.order.documentMainCategory === 'ASC'
          }
          renderCell={(row, props) => <TC row={row} {...props} />}
        >
          <FormattedMessage id="Category" defaultMessage="Category" />
        </TableColumn>
        <TableColumn
          fieldName="recipients"
          formatter={formatters.recipientsFormatter}
          sortable
          asc={
            props.order &&
            props.order.recipients &&
            props.order.recipients === 'ASC'
          }
          renderCell={(row, props) => <TC row={row} {...props} />}
        >
          <FormattedMessage id="Recipient" defaultMessage="Recipient" />
        </TableColumn>
        <TableColumn
          fieldName="issuedNumber"
          formatter={formatters.boundLengthFormatterLong}
          renderCell={(row, props) => <TC row={row} {...props} />}
        >
          <FormattedMessage id="Recipient id" defaultMessage="Recipient id" />
        </TableColumn>
        {/* <TableColumn
          fieldName="legalValidityStartDate"
          formatter={formatters.momentFormatter}
          renderCell={(row, props) => <TC row={row} {...props} />}
        >
          <FormattedMessage id="Sign date" defaultMessage="Sign date" />
        </TableColumn> */}
        <TableColumn
          fieldName="agreementStatus"
          formatter={formatters.statusFormatter}
          renderCell={(row, props) => <TC row={row} {...props} />}
        >
          <FormattedMessage id="Status" defaultMessage="Status" />
        </TableColumn>
        <TableColumn
          className="text-center fixed-width"
          fieldName="documentBlockchainAddress"
          formatter={props.downloadFormatter}
          renderCell={(row, props) => <TC row={row} {...props} />}
        >
          <FormattedMessage id="Download" defaultMessage="Download" />
        </TableColumn>
      </Table>
      <Pagination
        onClick={props.handlePageChange}
        numberOfPages={getNumberOfPages(
          props.numberOfRecords,
          props.filters.limit,
        )}
        currentPage={props.filters.page}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
      />
    </StyledTableCard>
  );
};
