export enum QueryKeys {
  ME = 'ME',
  SMS_CODE_REQUEST = 'SMS_CODE_REQUEST',
  UsersRequest = 'UsersRequest',
  DOCUMENT_LIST = 'DOCUMENT_LIST',
  USERS_LIST = 'USERS_LIST',
  SINGLE_USER = 'SINGLE_USER',
  SINGLE_DOCUMENT = 'SINGLE_DOCUMENT',
  DOCUMENT_NOTIFICATION_HISTORY = 'DOCUMENT_NOTIFICATION_HISTORY',
  DOCUMENT_RECIPIENTS = 'DOCUMENT_RECIPIENTS',
  SETTINGS = 'settings',
  CATEGORY_LIST = 'CATEGORY_LIST',
  IDENTITY_LIST = 'IDENTITY_LIST',
  IDENTITY_FILE = 'IDENTITY_FILE',
  NOTIFICATION_TEMPLATES = 'NOTIFICATION_TEMPLATES',
  NOTIFICATION_CONTENT = 'NOTIFICATION_CONTENT',
}
