import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ReactInputVerificationCode from 'react-input-verification-code';
import * as Styled from './styled';

const SmsCodeInput = ({ setSmsCodeValue, isInvalid }) => {
  return (
    <>
      <Styled.VerificationCodeLabel>
        <FormattedMessage
          id="Enter the code from SMS"
          defaultMessage="Enter the code from SMS"
        />
      </Styled.VerificationCodeLabel>
      <Styled.StyledReactInputVerificationCode isInvalid={isInvalid}>
        <ReactInputVerificationCode
          placeholder={null}
          length={6}
          onChange={setSmsCodeValue}
        />
      </Styled.StyledReactInputVerificationCode>

      <Styled.VerificationCodeErrorLabel isInvalid={isInvalid}>
        <FormattedMessage
          id="Invalid code, retry"
          defaultMessage="Invalid code, retry"
        />
      </Styled.VerificationCodeErrorLabel>
    </>
  );
};

SmsCodeInput.propTypes = {};

export default SmsCodeInput;
