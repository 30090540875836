import React from 'react';

import { Modal as ModalModule } from 'billon-ui';
import { FormattedMessage } from 'react-intl';

const { SuccessModal, ConfirmModal, ErrorModal } = ModalModule;

const DeleteModalsRemoteSign = (props) => {
  // properties
  const {
    confirmDeleteModalOpened,
    deleteSuccessModalOpened,
    deleteErrorModalOpened,
    deleteErrorMessage,
    isDeleting,
  } = props;

  //methods
  const {
    handleDeleteConfirmed,
    handleCancel,
    handleDeleteSuccessModalClose,
    handleDeleteErrorModalClose,
  } = props;

  return (
    <>
      <ConfirmModal
        isLoading={isDeleting}
        isOpen={confirmDeleteModalOpened}
        title={
          <FormattedMessage
            id="Document deletion confirmation"
            defaultMessage="Document deletion confirmation"
          />
        }
        onAccept={handleDeleteConfirmed}
        onCancel={handleCancel}
      >
        <FormattedMessage
          id="Are You sure You want to delete this document?"
          defaultMessage="Are You sure You want to delete this document?"
        />
      </ConfirmModal>
      <SuccessModal
        isOpen={deleteSuccessModalOpened}
        title={
          <FormattedMessage
            id="Document deleted"
            defaultMessage="Document deleted"
          />
        }
        onClose={handleDeleteSuccessModalClose}
      >
        <FormattedMessage
          id="Document has been deleted successfully."
          defaultMessage="Document has been deleted successfully."
        />
      </SuccessModal>
      <ErrorModal
        isOpen={deleteErrorModalOpened}
        title={
          <FormattedMessage
            id="Document deletion error"
            defaultMessage="Document deletion error"
          />
        }
        onClose={handleDeleteErrorModalClose}
      >
        {deleteErrorMessage && (
          <FormattedMessage
            id={deleteErrorMessage.message}
            defaultMessage={deleteErrorMessage.message}
          />
        )}
      </ErrorModal>
    </>
  );
};

export default DeleteModalsRemoteSign;
