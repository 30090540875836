import { VALIDATION_ERRORS } from '../../../../../constraints';

export default (values) => {
  const errors = {};

  if (!values) {
    return null;
  }

  if (!values.title) {
    errors.title = VALIDATION_ERRORS.REQUIRED;
  } else if (values.title.length > 128) {
    errors.title = VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED.replace(
      '$length',
      128,
    );
  }

  if (!values.signedBy) {
    errors.signedBy = VALIDATION_ERRORS.REQUIRED;
  }

  if (!values.categoryId || !values.categoryId.value) {
    errors.categoryId = VALIDATION_ERRORS.REQUIRED;
  }

  if (!values.recipient || !values.recipient.value) {
    errors.recipient = VALIDATION_ERRORS.REQUIRED;
  }

  if (!values.orderOfSigning || !values.orderOfSigning.value) {
    errors.orderOfSigning = VALIDATION_ERRORS.REQUIRED;
  }

  // * old version with DatePicker
  /*
  if (!values.retainUntil) {
    errors.retainUntil = VALIDATION_ERRORS.REQUIRED;
  } else if (
    !moment(values.retainUntil).isSameOrAfter(moment(values.validUntil))
  ) {
    errors.retainUntil = VALIDATION_ERRORS.AFTER_OR_SAME_VALID_UNTIL_DATE;
  } else if (
    moment(values.retainUntil) < moment("1900-01-01") ||
    moment(values.retainUntil) > moment("2500-12-31")
  ) {
    errors.retainUntil = VALIDATION_ERRORS.DATE_BETWEEN_1900_2500;
  } else if (moment(values.retainUntil) < moment()) {
    errors.retainUntil = VALIDATION_ERRORS.FUTURE_DATE;
  }
  */

  if (!values.retainUntil || !values.retainUntil.value) {
    errors.retainUntil = VALIDATION_ERRORS.REQUIRED;
  }

  if (!values.file) {
    errors.file = VALIDATION_ERRORS.REQUIRED;
  } else if (
    values.file[0] &&
    values.file[0].size &&
    values.file[0].size > 2 * 1024 * 1024
  ) {
    errors.file = VALIDATION_ERRORS.FILE_SIZE_TOO_LARGE;
  }

  return errors;
};
