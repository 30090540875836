import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form as FormModule, Utils } from 'billon-ui';
import SmsCodeInput from './SmsCodeInput';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { usePatchPassword } from '../../hooks/usePatchPassword';
import * as Styled from './styled';

const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { FormGroup } = FormModule;
const { Text } = Utils;

const TypeVerificationCode = ({
  token,
  passwords,
  handleOnSubmitCodeSuccess,
  handleOnBack,
}) => {
  const [smsCode, setSmsCode] = useState('');
  const [isValidationInvalid, setIsValidationInvalid] = useState(false);

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm({});

  const handleError = () => setIsValidationInvalid(true);

  const { mutate: patchPassword } = usePatchPassword({
    token: token,
    onSuccess: handleOnSubmitCodeSuccess,
    onError: handleError,
  });

  const handleSubmitCode = (values) => {
    if (smsCode && smsCode.length === 6) {
      if (isValidationInvalid) {
        setIsValidationInvalid(false);
      }
      const passwordData = {
        ...passwords,
        smsCode: smsCode,
      };

      patchPassword({ passwordData: passwordData });
    } else {
      handleError();
    }
  };

  const handleOnChange = (value) => {
    setSmsCode(value);
  };

  return (
    <Styled.Card>
      <Styled.Title>
        <FormattedMessage
          id="Confirm via SMS"
          defaultMessage="Confirm via SMS"
        />
      </Styled.Title>
      <Text>
        <FormattedMessage
          id="We have sent a 6-digit verification code to your phone number"
          defaultMessage="We have sent a 6-digit verification code to your phone number"
        />
      </Text>
      <Styled.CardBodyWrapper>
        <Styled.Form>
          <SmsCodeInput
            setSmsCodeValue={handleOnChange}
            isInvalid={isValidationInvalid}
          />
          <Styled.CardBodyWrapper>
            <FormGroup>
              {isSubmitting ? (
                <ButtonLoader block size="lg" />
              ) : (
                <Button
                  type="submit"
                  size="lg"
                  block
                  onClick={handleSubmit(handleSubmitCode)}
                >
                  <FormattedMessage id="Next" defaultMessage="Next" />
                </Button>
              )}
              <Text textAlign={'center'} margin={'10px 0 30px 0'}>
                <FormattedMessage
                  id="Got a problem?"
                  defaultMessage="Got a problem?"
                />{' '}
                <Link to="/support">
                  <FormattedMessage
                    id="Contact the support team"
                    defaultMessage="Contact the support team"
                  />
                </Link>
              </Text>
            </FormGroup>
          </Styled.CardBodyWrapper>
        </Styled.Form>
      </Styled.CardBodyWrapper>
      <Styled.ZeroCol md={3}>
        <Styled.BackButton secondary onClick={handleOnBack}>
          <FormattedMessage id="back" defaultMessage="back" />
        </Styled.BackButton>
      </Styled.ZeroCol>
    </Styled.Card>
  );
};

TypeVerificationCode.propTypes = {
  handleOnSubmitCodeSuccess: PropTypes.func.isRequired,
  handleOnBack: PropTypes.func.isRequired,
  passwords: PropTypes.object.isRequired,
  token: PropTypes.object,
};

export default TypeVerificationCode;
