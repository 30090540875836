import React from 'react';
import { Grid, Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import Modal from '../../../../ui/Modal';
import * as Styled from './styled';
import { FilterForm } from '../NotificationHistory/components';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;
const { Col, Row } = Grid;

const ModalHeaderPanel = () => (
  <Row>
    <Styled.TitleContainer md={5}>
      <Styled.ModalTitle>
        <FormattedMessage
          id="Notification history"
          defaultMessage="Notification history"
        />
      </Styled.ModalTitle>
    </Styled.TitleContainer>
    <Styled.FilterCol md={7}>
      <FilterForm />
    </Styled.FilterCol>
  </Row>
);

const NotificationHistoryModal = (props) => {
  return (
    <Modal {...props} closePadding>
      <ModalHeaderPanel />
      <Styled.ModalBody>{props.children}</Styled.ModalBody>
      <Styled.ModalFooter>
        <Styled.ZeroCol md={3}>
          <Styled.BackButton secondary onClick={props.onClose}>
            <FormattedMessage id="back" defaultMessage="back" />
          </Styled.BackButton>
        </Styled.ZeroCol>
        <Col md={6}>
          <Button size="lg" block onClick={props.onClose}>
            <FormattedMessage id="Close" defaultMessage="Close" />
          </Button>
        </Col>
        <Col md={3}></Col>
      </Styled.ModalFooter>
    </Modal>
  );
};

export default NotificationHistoryModal;
