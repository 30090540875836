import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PublishingErrorDetails } from '../../components';
import { EC } from './index';

import { Utils, Grid, Layout } from 'billon-ui';

const { Col } = Grid;

const { Button: ButtonModule } = Utils;
const { Content: ContentModule } = Layout;
const { Button } = ButtonModule;
const { ErrorContent } = ContentModule;

export const CreateError = ({ publicationErrorDetails, setStep }) => {
  return (
    <div>
      <ErrorContent>
        <FormattedMessage
          id="An error occurred while trying to publish document"
          defaultMessage="An error occurred while trying to publish document"
        />
        {publicationErrorDetails && (
          <PublishingErrorDetails
            status={publicationErrorDetails.publicationStatus}
          />
        )}
      </ErrorContent>
      <EC>
        <Col md={{ size: 6, offset: 3 }}>
          <Button
            type="button"
            onClick={() => window.location.reload()}
            size="lg"
            block
          >
            <FormattedMessage id="Try again" defaultMessage="Try again" />
          </Button>
        </Col>
      </EC>
    </div>
  );
};
