import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { merge } from 'lodash';

// Utils
import {
  momentFormatter,
  boundLengthFormatterLong,
  momentFormatterWithoutTime,
} from '../../../../formatters';
import { documentStatusFormatter } from '../../formatters/documentStatusFormatter';
import {
  DOCUMENT_ORDER_BY,
  ORDER_METHOD,
  DOCUMENT_TYPE,
  UserRoles,
} from '../../../../constraints';
import appRoutes from '../../../../app-routes';
// Hooks
import { useDocumentList } from '../../hooks';
import useFilters from '../../../../hooks/useFilters';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import useDocumentType from '../../hooks/useDocumentType';

// Components
import { Layout as LayoutModule, Table as TableModule, Utils } from 'billon-ui';
import { PreviewDownloadButton } from '../PreviewDownloadButton';
import { DocumentTableDetails } from '../DocumentTableDetails';
import { FilterForm } from '../FilterForm';
import { ProtectedComponent } from '../../../../modules/Auth/components';
import * as Styled from './styled';

const { Table, TableColumn } = TableModule;
const { Loader: LoaderModule, Pagination, Button: ButtonModule, Icon } = Utils;
const { Content: ContentModule, PageHeader } = LayoutModule;
const { Content } = ContentModule;
const { Button } = ButtonModule;
const { Loader } = LoaderModule;

export const DocumentTable = ({ handleCreate, handleDelete }) => {
  const { isDiploma } = useConfigContext();
  const { documentType } = useDocumentType();

  const defaultFilters = {
    pagination: {
      limit: 10,
      page: 1,
    },
    filters: {
      documentTypeList: documentType,
    },
  };
  const [areFiltersOpen, setAreFiltersOpen] = useState();

  const toggleFiltersForm = () => setAreFiltersOpen(!areFiltersOpen);

  const {
    filters,
    navigateWithSearchQuery,
    navigateWithNewSearchQuery,
    onClear,
  } = useFilters();

  const fullFilters = merge({}, defaultFilters, filters);

  const { data, isFetching: isDocumentDataFetching } =
    useDocumentList(fullFilters);

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  const isPublic = [
    fullFilters?.documentTypeList,
    filters?.filters?.documentTypeList,
  ].includes(DOCUMENT_TYPE.PUBLIC);

  const onSort = (column, isDescending) => {
    navigateWithSearchQuery({
      sort: {
        sortBy: DOCUMENT_ORDER_BY[column],
        order: isDescending ? ORDER_METHOD.ASC : ORDER_METHOD.DESC,
      },
    });
  };

  const isAscendingOrder = (column) => {
    if (filters?.sort?.sortBy === DOCUMENT_ORDER_BY[column]) {
      return filters?.sort?.order === ORDER_METHOD.ASC;
    }

    return filters?.sort?.order === ORDER_METHOD.ASC;
  };

  const handleCreateClick = () => {
    const { documentTypeList } = fullFilters.filters;
    navigateWithNewSearchQuery(
      { documentTypeList },
      appRoutes.PUBLISH_DOCUMENT,
    );
  };

  return (
    <Content fluid>
      <FormattedMessage id="Search" defaultMessage="Search">
        {([placeholder]) => (
          <PageHeader
            handleSearchBar={() => {}}
            handleReset={() => {}}
            initialValues={{}}
            classic
            searchBarPlaceholder={placeholder}
            after={
              <ProtectedComponent
                roles={[UserRoles.AGENT, UserRoles.ADMIN]}
                render={false}
              >
                <Button
                  onClick={handleCreateClick}
                  fontWeight={500}
                  padding="0.5rem 3.5rem;"
                >
                  {isDiploma && (
                    <FormattedMessage
                      id="Publish diploma"
                      defaultMessage="Publish diploma"
                    />
                  )}
                  {!isDiploma &&
                    (isPublic ? (
                      <FormattedMessage
                        id="Publish public document"
                        defaultMessage="Publish public document"
                      />
                    ) : (
                      <FormattedMessage
                        id="Publish private document"
                        defaultMessage="Publish private document"
                      />
                    ))}
                </Button>
              </ProtectedComponent>
            }
          >
            {isDiploma && (
              <FormattedMessage
                id="Diploma list"
                defaultMessage="Diploma list"
              />
            )}
            {!isDiploma &&
              (isPublic ? (
                <FormattedMessage
                  id="Public documents"
                  defaultMessage="Public documents"
                />
              ) : (
                <FormattedMessage
                  id="Private documents"
                  defaultMessage="Private documents"
                />
              ))}

            <Styled.Button
              fontWeight="700"
              fontSize="12px"
              color="link"
              type="button"
              onClick={toggleFiltersForm}
            >
              <FormattedMessage id="Filters" defaultMessage="Filters" />
              {Object.keys(filters?.filters ?? {}).length > 0 && (
                <Icon name="filter" />
              )}
            </Styled.Button>
          </PageHeader>
        )}
      </FormattedMessage>

      {areFiltersOpen && (
        <FilterForm
          isDiploma={isDiploma}
          initialValues={filters}
          closeFilters={toggleFiltersForm}
          onClear={() => onClear(defaultFilters)}
        />
      )}

      <br />
      <Styled.TableCard>
        {isDocumentDataFetching ? (
          <Loader width={36} />
        ) : (
          <>
            <Table
              tableData={data.rows}
              sortMethod={onSort}
              responsive
              detailsComponent={(record) => (
                <DocumentTableDetails
                  record={record}
                  handleCreate={(initialValues, isPreparedToSign = false) =>
                    handleCreate(initialValues, isPreparedToSign)
                  }
                  handleDelete={(id) => handleDelete(id)}
                />
              )}
            >
              <TableColumn
                fieldName="publicationDate"
                formatter={momentFormatter}
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.PUBLICATION_DATE)}
              >
                <FormattedMessage
                  id="Publication date"
                  defaultMessage="Publication date"
                />
              </TableColumn>
              <TableColumn
                fieldName="title"
                sortable
                formatter={boundLengthFormatterLong}
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.TITLE)}
              >
                <FormattedMessage
                  id={isDiploma ? 'Diploma number' : 'Title'}
                  defaultMessage={isDiploma ? 'Diploma number' : 'Title'}
                />
              </TableColumn>
              <TableColumn
                fieldName="validSince"
                formatter={momentFormatterWithoutTime}
                sortable
                asc={isAscendingOrder(
                  DOCUMENT_ORDER_BY.LEGAL_VALIDITY_START_DATE,
                )}
              >
                <FormattedMessage
                  id={isDiploma ? 'Diploma issuance date' : 'Valid since'}
                  defaultMessage={
                    isDiploma ? 'Diploma issuance date' : 'Valid since'
                  }
                />
              </TableColumn>
              {!isDiploma && isPublic && (
                <TableColumn
                  fieldName="validUntil"
                  formatter={momentFormatterWithoutTime}
                  sortable
                  asc={isAscendingOrder(
                    DOCUMENT_ORDER_BY.LEGAL_VALIDITY_FINISH_DATE,
                  )}
                >
                  <FormattedMessage
                    id="Valid until"
                    defaultMessage="Valid until"
                  />
                </TableColumn>
              )}
              <TableColumn
                fieldName="category"
                formatter={(value) => value?.name}
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.CATEGORY)}
              >
                <FormattedMessage id="Category" defaultMessage="Category" />
              </TableColumn>
              <TableColumn
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.PUBLICATION_STATUS)}
                fieldName="status"
                formatter={documentStatusFormatter}
              >
                <FormattedMessage
                  id="Publication status"
                  defaultMessage="Publication status"
                />
              </TableColumn>
              {isDiploma && (
                <TableColumn
                  fieldName="documentNumber"
                  formatter={(value) => value && value.documentNumber}
                >
                  <FormattedMessage
                    id="Index number"
                    defaultMessage="Index number"
                  />
                </TableColumn>
              )}
              {!isPublic && (
                <TableColumn
                  fieldName="identity"
                  formatter={(value) =>
                    value && `${value.firstName} ${value.lastName}`
                  }
                  sortable
                  asc={isAscendingOrder(DOCUMENT_ORDER_BY.SURNAME)}
                >
                  <FormattedMessage
                    id="Published for"
                    defaultMessage="Published for"
                  />
                </TableColumn>
              )}
              <TableColumn
                className="text-center fixed-width"
                fieldName="Preview"
                formatter={(value, record) => (
                  <PreviewDownloadButton record={record} isPreview />
                )}
              >
                <FormattedMessage id="Preview" defaultMessage="Preview" />
              </TableColumn>
              <TableColumn
                className="text-center fixed-width"
                fieldName="Download"
                formatter={(value, record) => (
                  <PreviewDownloadButton record={record} />
                )}
              >
                <FormattedMessage id="Download" defaultMessage="Download" />
              </TableColumn>
            </Table>
            <Pagination
              onClick={handlePageChange}
              numberOfPages={Math.ceil(
                data.count / fullFilters.pagination.limit,
              )}
              currentPage={Number(fullFilters.pagination.page)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
            />
          </>
        )}
      </Styled.TableCard>
      <br />
      <br />
    </Content>
  );
};
