import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SetPasswordSuccess from '../SetPasswordSuccess';
import PasswordForm from '../PasswordForm';
import SingleCustomCard from '../SingleCustomCard';
import { useAdministrativePatchPassword } from '../../hooks/useAdministrativePatchPassword';

const AdministrativePasswordChange = ({ id, mode, role, settings }) => {
  const history = useHistory();
  const [reasons, setReasons] = useState([]);

  const handleOnValidationSuccess = (passwords) => {
    patchPassword({ id: id, passwordData: passwords });
  };

  const handleOnFailure = ({ response }) => {
    setReasons(response?.data?.reasons || []);
  };

  const handleOnClose = () => {
    history.push(`/user/${id}`);
  };

  const { mutate: patchPassword, isSuccess } = useAdministrativePatchPassword({
    onError: handleOnFailure,
  });

  const formConfig = {
    mode: mode,
    handleSuccess: handleOnValidationSuccess,
    passwordLabelId: 'New password',
    repeatPasswordLabelId: 'Repeat new password',
    submitMessageId: 'Change password',
  };

  if (isSuccess) {
    return (
      <SetPasswordSuccess
        onClose={handleOnClose}
        messageId={'Password successfully changed'}
      />
    );
  }

  return (
    <SingleCustomCard titleId="Password change">
      <PasswordForm
        role={role}
        settings={settings}
        formConfig={formConfig}
        deliveredReasons={reasons}
      />
    </SingleCustomCard>
  );
};

export default AdministrativePasswordChange;
