import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { TechnicalBreak, Utils, Language } from 'billon-ui';
import { createGlobalStyle } from 'styled-components';

import { QueryClient, QueryClientProvider } from 'react-query';

import plTranslations from '../languages/pl.json';
import enTranslations from '../languages/en.json';
import routes from './api-routes';
import cfg from './config.js';

import { requestSmsCode } from './modules/Settings/actions';

import { requestDecodeToken } from './ui/Authentication/actions';
import { WebViewer } from './components/WebViewer';
import { AuthenticatedContextProvider } from './modules/Auth/hooks/useAuthenticated';
import { ConfigContextProvider } from './modules/Config/hooks/useConfig';
import { Routes } from './components/Routes/Routes';

const queryClient = new QueryClient();

const { getDefaultLanguage } = Language;

const translationsData = {
  pl: plTranslations,
  en: enTranslations,
};

const { ThemeProvider } = Utils;

const { TechnicalBreak: TechnicalBreakComponent } = TechnicalBreak;

const GlobalStyle = createGlobalStyle`
  body { 
    background-color: rgb(250,250,250) !important;
  }

  .newly-published {
    font-weight: ${(props) => props.theme.fontWeightMedium};
  }

  .gray-background {
    background: ${(props) => props.theme.palette.grayLightest};
  }
`;

class App extends Component {
  componentDidMount() {
    getDefaultLanguage();
  }

  render() {
    const { language } = this.props;

    return (
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale={language} messages={translationsData[language]}>
          <ThemeProvider theme={cfg.theme}>
            <>
              <WebViewer />
              <GlobalStyle />
              <TechnicalBreakComponent url={routes.CHECK_CONNECTION}>
                <ConfigContextProvider>
                  <AuthenticatedContextProvider>
                    <Routes />
                  </AuthenticatedContextProvider>
                </ConfigContextProvider>
              </TechnicalBreakComponent>
            </>
          </ThemeProvider>
        </IntlProvider>
      </QueryClientProvider>
    );
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isVerifying: PropTypes.bool.isRequired,
  isVerified: PropTypes.bool.isRequired,
  language: PropTypes.string,
  customer: PropTypes.string.isRequired,
  observer: PropTypes.bool,
  activeDelivery: PropTypes.bool,
};

const mapStateToProps = ({ authentication, language, config }) => ({
  isVerifying: authentication.isVerifying,
  isAuthenticated: authentication.isAuthenticated,
  isVerified: authentication.isVerified,
  language: language.language || config.language,
  customer: config.customer,
  observer: config.observer,
  activeDelivery: config.activeDelivery,
  remoteSign: config.remoteSign,
});

const mapDispatchToProps = (dispatch) => ({
  requestMe: () => dispatch(requestDecodeToken()),
  getSmsCode: () => dispatch(requestSmsCode()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
