import { Grid, Modal as ModalModule } from 'billon-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { requestList as requestCategoriesList } from '../../../Categories/actions';
import { CreateForm as CreateFormCategories } from '../../../Categories/containers';
import { requestList as requestRecipientsList } from '../../../Recipients/actions';
import { CreateForm as CreateFormRecipients } from '../../../Recipients/containers';
import { requestDelete } from '../../actions';
import DeleteModalsRemoteSign from '../../components/RemoteSignDocumentsPageComponents/DeleteModalsRemoteSign';
import { PageHeaderForRemoteSignDocs } from '../../components/RemoteSignDocumentsPageComponents/PageHeaderForRemoteSignDocs';
import CreateFormRemoteSign from '../../containers/CreateFormRemoteSign';
import DocumentTableRemoteSignContainer from '../../containers/DocumentTableRemoteSignContainer';
const { Row } = Grid;

const { SuccessModal, AbstractCrudComponent, CreateModal } = ModalModule;

const StyledDocumentsMainWrapper = styled.div`
  padding: 2em;
  width: 100%;
  background-color: #fafafa;
`;

export const StyledDocumentsTypesWrapper = styled(Row)`
  padding: 0px;
  margin: 0px;
  display: flex;
  width: 100%;
  margin-bottom: 40px;
`;

class DocumentsHomePageRemoteSign extends AbstractCrudComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      isModalOpen: false,
      step: 0,
      fileToSign: null,
      fileAddressToSing: null,
      detailsDataFromList: null,
      isModalLarge: true,
      isRecipientModal: true,
      isCategoriesModal: false,
      isCreating: false,
    };
  }

  toggleCreateNewDocument = () => {
    // if (this.state.isModalOpen) {
    if (this.state.isModalOpen || this.state.successModalOpened) {
      window.location.reload();
    } else {
      this.setState({
        isModalOpen: true,
      });
    }
  };

  handleCreateFinished = () => {
    this.setState({
      isModalOpen: false,
      successModalOpened: true,
    });
  };

  handleCreateError = () => {
    this.setState({
      successModalOpened: false,
    });
  };

  setStep = (number) => {
    this.setState({ step: number });
  };

  setFileToSign = (data) => {
    this.setState({ fileToSign: data });
  };

  openPublishModalFromList = (data) => {
    this.setState({
      step: 1,
      isModalOpen: true,
      fileToSign: null,
      detailsDataFromList: data,
      successModalOpened: false,
    });
  };

  handleDeleteSuccessModalClose() {
    this.setState(
      {
        deleteSuccessModalOpened: false,
      },
      () => window.location.reload(),
    );
  }

  handleSuccessModalClose = () => {
    this.toggleCreateNewDocument();
  };

  setIsModalLarge = (bool) => {
    this.setState({ isModalLarge: bool });
  };

  handleCreateRecipientOrCategoriesFinish = () => {
    if (this.state.isRecipientModal) {
      this.props.loadRecipients();
      this.setState({
        createModalOpened: false,
        successAddUserOrCategoryModalOpen: true,
      });
    } else {
      this.setState({
        isCreating: true,
      });

      setTimeout(() => {
        this.setState({
          successAddUserOrCategoryModalOpen: true,
          createModalOpened: false,
          isCreating: false,
        });
        this.props.loadCategories();
      }, 10000);
    }
  };

  handleAddUserOrCategorySuccessModalClose = () => {
    this.setState({ successAddUserOrCategoryModalOpen: false });
  };

  render() {
    const {
      confirmDeleteModalOpened,
      deleteSuccessModalOpened,
      deleteErrorModalOpened,
      deleteErrorMessage,
      isCreating,
    } = this.state;
    const { isDeleting } = this.props;
    return (
      <StyledDocumentsMainWrapper>
        <PageHeaderForRemoteSignDocs
          toggleCreateNewDocument={this.toggleCreateNewDocument}
        ></PageHeaderForRemoteSignDocs>

        {/* Modal for create user or categories from doc create  view */}
        <CreateModal
          isOpen={this.state.createModalOpened}
          onSuccess={this.handleCreateRecipientOrCategoriesFinish}
          onCancel={this.handleCancel}
          initialValues={{}}
          createMessage={
            <>
              {this.state.isRecipientModal && (
                <FormattedMessage
                  id="Adding recipient"
                  defaultMessage="Adding recipient"
                />
              )}
              {this.state.isCategoriesModal && (
                <FormattedMessage
                  id="Adding category"
                  defaultMessage="Adding category"
                />
              )}
            </>
          }
        >
          {this.state.isRecipientModal ? (
            <CreateFormRecipients />
          ) : (
            <CreateFormCategories isCreating={isCreating} />
          )}
        </CreateModal>
        <SuccessModal
          isOpen={this.state.successAddUserOrCategoryModalOpen}
          onClose={this.handleAddUserOrCategorySuccessModalClose}
        >
          <FormattedMessage
            id="Successfully saved"
            defaultMessage="Successfully saved"
          />
        </SuccessModal>

        {/* Modal  doc create  */}
        <CreateModal
          className={this.state.isModalLarge ? 'modal-xl' : ''}
          isOpen={this.state.isModalOpen}
          onFinished={this.handleCreateFinished}
          onCancel={this.toggleCreateNewDocument}
          initialValues={{}}
        >
          <CreateFormRemoteSign
            setStep={this.setStep}
            step={this.state.step}
            isModalLarge={this.state.isModalLarge}
            setIsModalLarge={this.setIsModalLarge}
            setFileToSign={this.setFileToSign}
            fileToSign={this.state.fileToSign}
            detailsDataFromList={this.state.detailsDataFromList}
            isModalOpen={this.state.isModalOpen}
            toggleCreateNewDocument={this.toggleCreateNewDocument}
            handleCreateError={this.handleCreateError}
            handleCreateRecipient={() => {
              this.setState(
                {
                  isRecipientModal: true,
                  isCategoriesModal: false,
                },
                () => this.handleCreate(),
              );
            }}
            handleCreateCategory={() => {
              this.setState(
                {
                  isRecipientModal: false,
                  isCategoriesModal: true,
                },
                () => this.handleCreate(),
              );
            }}
          />
        </CreateModal>
        <SuccessModal
          isOpen={this.state.successModalOpened}
          onClose={this.handleSuccessModalClose}
        >
          <FormattedMessage
            id="Document publication has been initiated."
            defaultMessage="Document publication has been initiated."
          />
        </SuccessModal>

        <DocumentTableRemoteSignContainer
          isFiltersOpen={this.state.isFiltersOpen}
          openPublishModalFromList={this.openPublishModalFromList}
          handleDelete={this.handleDelete}
        />

        <DeleteModalsRemoteSign
          isDeleting={isDeleting}
          confirmDeleteModalOpened={confirmDeleteModalOpened}
          handleDeleteConfirmed={this.handleDeleteConfirmed}
          handleCancel={this.handleCancel}
          deleteSuccessModalOpened={deleteSuccessModalOpened}
          handleDeleteSuccessModalClose={this.handleDeleteSuccessModalClose}
          deleteErrorModalOpened={deleteErrorModalOpened}
          handleDeleteErrorModalClose={this.handleDeleteErrorModalClose}
          deleteErrorMessage={deleteErrorMessage}
        />
      </StyledDocumentsMainWrapper>
    );
  }
}

const mapStateToProps = ({ document, config }) => ({
  isDeleting: document.isDeleting,
  customer: config.customer,
});

const mapDispatchToProps = (dispatch) => ({
  deleteEntry: (id, meta) => dispatch(requestDelete(id, meta)),
  loadCategories: () => dispatch(requestCategoriesList({ isActive: true })),
  loadRecipients: () => dispatch(requestRecipientsList({ isActive: true })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DocumentsHomePageRemoteSign),
);
