export default {
  SELECT_DOCUMENT_TYPE: '/select-document-type',
  PUBLISH_DOCUMENT: '/document/publish',
  DOCUMENTS_BLANK: '/documents',
  DOCUMENTS_DETAILS: '/document/:id',
  DOCUMENTS_DETAILS_NOTIFICATION_HISTORY: '/document/:id/notificationHistory',
  DOCUMENTS_DETAILS_RESEND_NOTIFICATION:
    '/document/:id/resendNotification/:mode',
  DOCUMENTS_DETAILS_UPDATE: '/document/:id/update/:mode',
  DAILY_REPORTS_BLANK: `/dailyReports`,
  DAILY_REPORT_DETAILS: `/dailyReport/:id`,
  LOGIN: '/login',
  ME: '/me',
  USER_DETAILS: '/user/:id',
  USERS: '/users',
  USERS_BLANK: '/users',
  PASSWORD_CHANGE_BLANK: '/passwordChange',
  PASSWORD_CHANGE: '/passwordChange/:token?',
  PASSWORD_SET: '/setPassword/:token?',
  RECIPTIENTS: '/recipients',
  PASSWORD_OBSOLETE_SET: '/setObsoletePassword',
  TEMPORATY_PASSWORD_SET: '/users/:id/setTemporaryPassword',
  ADMIN_PASSWORD_CHANGE: '/user/:id/passwordChange',
  RECIPTIENTS_BLANK: '/recipients',
  SUPPORT: '/support',
};
