import { PUBLICATION_FIELD_NAMES } from '../../../../../../constraints';

export const computeDefaultValues = (document, user) => ({
  [PUBLICATION_FIELD_NAMES.PUBLISHED_BY]: `${user.firstName} ${user.lastName}`,
  [PUBLICATION_FIELD_NAMES.TITLE]: document?.title || '',
  [PUBLICATION_FIELD_NAMES.CATEGORY]: document?.category
    ? {
        value: document?.category.id || document?.category.value,
        label: document?.category.name || document?.category.label,
      }
    : '',

  validSince: document?.validSince || [],
  validUntil: document?.validUntil || [],
  versionName: document?.versionName || '',
  retentionDate: document?.retentionDate || '',
  documentFile: document?.documentFile || [],

  [PUBLICATION_FIELD_NAMES.IDENTITY]: document?.identity
    ? {
        value: document.identity.id,
        label: `${document.identity.documentNumber} ${document.identity.firstName} ${document.identity.lastName}`,
      }
    : '',
});
