import React, { useState } from 'react';
import { Utils } from 'billon-ui';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';

const { Icon, Text } = Utils;

const CopyId = ({ id }) => {
  const [copied, setcopied] = useState(false);
  const [copyHover, setcopyHover] = useState(false);

  const onCopied = () => {
    setcopied(true);
    setTimeout(() => setcopied(false), 2000);
  };

  return (
    <Text fontSize={'13px'} margin={'7px 0 20px 0 '} fontWeight={700}>
      <FormattedMessage id="ID" defaultMessage="ID" />
      {': '}
      <Styled.LightWeight>{id}</Styled.LightWeight>

      <CopyToClipboard text={id} onCopy={onCopied}>
        <Styled.MyButton
          onMouseOver={() => setcopyHover(true)}
          onMouseOut={() => setcopyHover(false)}
        >
          <Styled.CopiedInfo show={copied}>
            <FormattedMessage id="Copied" defaultMessage="Copied" />
          </Styled.CopiedInfo>

          <Styled.CopiedInfo show={copied ? false : copyHover}>
            <FormattedMessage id="Copy" defaultMessage="Copy" />
          </Styled.CopiedInfo>
          <Icon name="copy" regular color="#7e7f7f" />
        </Styled.MyButton>
      </CopyToClipboard>
    </Text>
  );
};

export default CopyId;
