import React, { createContext, useContext, useCallback } from 'react';
import { useLogin } from './useLogin';
import { useLogout } from './useLogout';
import { useCurrentUser } from './useCurrentUser';
import { FormattedMessage } from 'react-intl';
import { Utils } from 'billon-ui';

const { PageLoader } = Utils.Loader;

const AuthenticatedContext = createContext(false);

export const useAuthenticatedContext = () => useContext(AuthenticatedContext);

export const AuthenticatedContextProvider = ({ children }) => {
  const token = sessionStorage.getItem('token');

  const { data: user, isLoading: isLoadingUser } = useCurrentUser({
    enabled: !!token,
  });

  const {
    mutate: login,
    error: loginError,
    isLoading: isLoggingIn,
  } = useLogin({
    onSuccess: (data) => {
      sessionStorage.setItem('token', data.token);
    },
  });

  const { mutate: logout } = useLogout({
    onSuccess: () => {
      sessionStorage.removeItem('token');
    },
  });

  const handleLogin = useCallback(
    (data) => {
      login(data);
    },
    [login],
  );

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const isLoading = isLoadingUser || isLoggingIn;

  return (
    <AuthenticatedContext.Provider
      value={{
        user,
        isAuthenticated: !!(user && token),
        handleLogin,
        handleLogout,
        loginError,
      }}
    >
      {isLoading ? (
        <PageLoader
          message={
            <FormattedMessage
              id="Token verification. Please wait..."
              defaultMessage="Token verification. Please wait..."
            />
          }
        />
      ) : (
        children
      )}
    </AuthenticatedContext.Provider>
  );
};
