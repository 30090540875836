import React from 'react';
import PropTypes from 'prop-types';
import { Utils } from 'billon-ui';

import { Header } from '../../containers';

const { PoweredBy } = Utils;

const Layout = ({ children }) => (
  <>
    <Header />
    {children}
    <PoweredBy fixed />
  </>
);

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
