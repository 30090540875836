import React from 'react';
import StatusTooltip from '.';
import StatusWithIcon from 'js/ui/StatusWithIcon';
import { USER_STATUS_MESSAGES, mapUSER_STATUSES } from 'js/constraints';
import { FormattedMessage } from 'react-intl';
import { generateRecordBasedId } from 'js/utils';

export const StatusWithToolTipFormatter = (value, record) => {
  const label = (
    <FormattedMessage
      id={mapUSER_STATUSES[value]}
      defaultMessage={mapUSER_STATUSES[value]}
    />
  );

  return (
    <StatusWithIcon label={label}>
      <StatusTooltip
        label={USER_STATUS_MESSAGES[value]}
        id={generateRecordBasedId(record)}
        delay={false}
      />
    </StatusWithIcon>
  );
};
