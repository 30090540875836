import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { merge } from 'lodash';

// Utils
import {
  momentFormatter,
  boundLengthFormatterLong,
} from '../../../../formatters';
import { documentStatusFormatter } from '../../../DocumentsNew/formatters/documentStatusFormatter';
import {
  CUSTOMER,
  DOCUMENT_ORDER_BY,
  ORDER_METHOD,
  DOCUMENT_TYPE,
} from '../../../../constraints';

// Components
import { Layout as LayoutModule, Table as TableModule, Utils } from 'billon-ui';
import {
  DailyReportTableDetails,
  FilterForm,
  PreviewDailyReportButton,
} from '..';
import * as Styled from './styled';

// Hooks
import { useDailyReports } from '../../hooks/useDailyReports';
import useFilters from '../../../../hooks/useFilters';

const { Table, TableColumn } = TableModule;
const { Loader: LoaderModule, Pagination, Button: ButtonModule, Icon } = Utils;
const { Content: ContentModule, PageHeader } = LayoutModule;
const { Content } = ContentModule;
const { Button } = ButtonModule;
const { Loader } = LoaderModule;

const DailyReportTable = ({ handleCreate, handleDelete }) => {
  const defaultFilters = {
    pagination: {
      limit: 10,
      page: 1,
    },
    sort: {
      order: ORDER_METHOD.DESC,
      sortBy: DOCUMENT_ORDER_BY.publicationDate,
    },
    filters: {
      documentTypeList: DOCUMENT_TYPE.REPORT,
    },
  };

  const [areFiltersOpen, setAreFiltersOpen] = useState();

  const toggleFiltersForm = () => setAreFiltersOpen(!areFiltersOpen);

  const { filters, navigateWithSearchQuery, onClear } = useFilters();

  const fullFilters = merge({}, defaultFilters, filters);

  const { data, isFetching: isDocumentDataFetching } =
    useDailyReports(fullFilters);

  const { customer } = useSelector(({ config }) => ({
    customer: config.customer,
    enablePrivate: config.enablePrivate,
  }));

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  const isDiploma = customer === CUSTOMER.DIPLOMA;

  const onSort = (column, isDescending) => {
    navigateWithSearchQuery({
      sort: {
        sortBy: DOCUMENT_ORDER_BY[column],
        order: isDescending ? ORDER_METHOD.ASC : ORDER_METHOD.DESC,
      },
    });
  };

  const isAscendingOrder = (column) => {
    if (filters?.sort?.sortBy === DOCUMENT_ORDER_BY[column]) {
      return filters?.sort?.order === ORDER_METHOD.ASC;
    }

    return filters?.sort?.order === ORDER_METHOD.ASC;
  };

  return (
    <Content fluid>
      <FormattedMessage id="Search" defaultMessage="Search">
        {([placeholder]) => (
          <PageHeader
            handleSearchBar={() => {}}
            handleReset={() => {}}
            initialValues={{}}
            classic
            searchBarPlaceholder={placeholder}
          >
            <FormattedMessage
              id="Daily reports"
              defaultMessage="Daily reports"
            />

            <Styled.Button
              fontWeight="700"
              fontSize="12px"
              color="link"
              type="button"
              onClick={toggleFiltersForm}
            >
              <FormattedMessage id="Filters" defaultMessage="Filters" />
              {Object.keys(filters?.filters ?? {}).length > 0 && (
                <Icon name="filter" />
              )}
            </Styled.Button>
          </PageHeader>
        )}
      </FormattedMessage>

      {areFiltersOpen && (
        <FilterForm
          isDiploma={isDiploma}
          initialValues={filters}
          closeFilters={toggleFiltersForm}
          onClear={() => onClear(defaultFilters)}
        />
      )}

      <br />
      <Styled.TableCard>
        {isDocumentDataFetching ? (
          <Loader width={36} />
        ) : (
          <>
            <Table
              tableData={data.rows}
              sortMethod={onSort}
              responsive
              detailsComponent={(record) => (
                <DailyReportTableDetails
                  record={record}
                  handleCreate={(initialValues, isPreparedToSign = false) =>
                    handleCreate(initialValues, isPreparedToSign)
                  }
                  handleDelete={(id) => handleDelete(id)}
                />
              )}
            >
              <TableColumn
                fieldName="publicationDate"
                formatter={momentFormatter}
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.PUBLICATION_DATE)}
              >
                <FormattedMessage
                  id="Publication date"
                  defaultMessage="Publication date"
                />
              </TableColumn>
              <TableColumn
                fieldName="title"
                formatter={boundLengthFormatterLong}
              >
                <FormattedMessage
                  id={isDiploma ? 'Diploma number' : 'Title'}
                  defaultMessage={isDiploma ? 'Diploma number' : 'Title'}
                />
              </TableColumn>
              <TableColumn
                fieldName="category"
                formatter={(value) => value?.name}
                sortable
                asc={isAscendingOrder(DOCUMENT_ORDER_BY.CATEGORY)}
              >
                <FormattedMessage id="Category" defaultMessage="Category" />
              </TableColumn>
              <TableColumn
                fieldName="status"
                formatter={documentStatusFormatter}
              >
                <FormattedMessage
                  id="Publication status"
                  defaultMessage="Publication status"
                />
              </TableColumn>

              <TableColumn
                className="text-center fixed-width"
                fieldName="Preview"
                formatter={(value, record) => (
                  <PreviewDailyReportButton record={record} isPreview />
                )}
              >
                <FormattedMessage id="Preview" defaultMessage="Preview" />
              </TableColumn>
              <TableColumn
                className="text-center fixed-width"
                fieldName="jobId"
                formatter={(value, record) => (
                  <PreviewDailyReportButton record={record} />
                )}
              >
                <FormattedMessage id="Download" defaultMessage="Download" />
              </TableColumn>
            </Table>
            <Pagination
              onClick={handlePageChange}
              numberOfPages={Math.ceil(
                data.count / fullFilters.pagination.limit,
              )}
              currentPage={Number(fullFilters.pagination.page)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
            />
          </>
        )}
      </Styled.TableCard>
      <br />
      <br />
    </Content>
  );
};

export default DailyReportTable;
