import { ajax } from 'rxjs/observable/dom/ajax';
import { Observable } from 'rxjs/Rx';
import { Utils } from 'billon-ui';

import {
  requestList,
  requestListQuiet,
  receiveListSuccess,
  receiveListFailure,
} from './actions';
import routes from '../../api-routes';

import { parseFilters } from './helpers';

const { helpers } = Utils;
const { request, encodeQueryString } = helpers;
const {
  METHOD_GET,
  HEADER_CONTENT_TYPE,
  CONTENT_TYPE_APPLICATION_JSON,
  X_ACCESS_TOKEN,
} = request;

const getDocumentsListEpic = (actions$) =>
  actions$
    .ofType(requestList.getType(), requestListQuiet.getType())
    .mergeMap((action) =>
      ajax({
        url: `${routes.DOCUMENT}?options=${encodeQueryString(
          parseFilters(action.payload.filters),
        )}`,
        headers: {
          [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
          [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
        },
        method: METHOD_GET,
      })
        .map(({ response }) => receiveListSuccess(response))
        .takeUntil(
          actions$.ofType(requestList.getType(), requestListQuiet.getType()),
        )
        .catch((err) => Observable.of(receiveListFailure(err))),
    );

export default [getDocumentsListEpic];
