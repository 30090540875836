import { VALIDATION_ERRORS } from '../../../../../constraints';

export default (values) => {
  const errors = {};

  if (!values) {
    return null;
  }

  if (!values.title) {
    errors.title = 'Incorrect or not provided payer number.';
  }

  if (!values.user) {
    errors.user = VALIDATION_ERRORS.REQUIRED;
  }

  if (!values.file) {
    errors.file = VALIDATION_ERRORS.REQUIRED;
  } else if (values.file.size && values.file.size > 2 * 1024 * 1024) {
    errors.file = VALIDATION_ERRORS.FILE_SIZE_TOO_LARGE;
  }

  return errors;
};
