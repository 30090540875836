import {
  Grid,
  Layout as LayoutModule,
  Table as TableModule,
  Utils,
} from 'billon-ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import {
  DOCUMENT_STATUS,
  FILTERS_MAP,
  REQUEST_STATUS,
} from '../../../../constraints';
import * as formatters from '../../../../formatters';
import { requestList as requestRecipientsList } from '../../../Recipients/actions';
// Actions
import {
  applyFilters as applyFiltersAction,
  requestDownload,
  requestList,
} from '../../actions';
import { requestFontColor } from '../../helpers';
import FilterFormRequests from './../FilterFormRequests';

const { Row, Col } = Grid;
const { Table, TableColumn, AbstractTable, TableCell } = TableModule;
const { Content: ContentModule, PageHeader } = LayoutModule;
const {
  Loader: LoaderModule,
  Pagination,
  Button: ButtonModule,
  helpers,
  Icon,
  Card,
} = Utils;

const { Button } = ButtonModule;
const { Loader, PageLoader } = LoaderModule;
const { Content } = ContentModule;

const { getNumberOfPages } = helpers;

const MyButton = styled.button`
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  margin-left: 1rem;
  /* padding-left: 1rem; */
  /* border-left: 1px solid ${(props) => props.theme.palette.grayLighter}; */

  &:hover,
  &:focus {
    outline: none;
  }
`;

const CopiedInfo = styled.div`
  position: absolute;
  font-size: ${(props) => props.theme.fontSize12};
  background-color: #acbbc7;
  color: #fff;
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 0.25rem 1.5rem;
  top: -2.5rem;
  left: 50%;
  margin-left: -57px;
  transition: ${(props) => props.theme.transitionBase};
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

const DocumentInfo = styled.div`
  .btn {
    font-size: 13px;
    height: 100%;

    ${down('sm')} {
      width: 300px;
    }
  }

  .info-col {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .border-right {
    border-right: 1px solid ${(props) => props.theme.palette.grayLight};
  }

  label.large {
    display: block;
    margin-bottom: 0;
  }

  .blockchain-address-span {
    word-wrap: break-word;
    font-weight: 600;
  }

  .status-span {
    font-weight: 600;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      font-size: ${(props) => props.theme.fontSizeSm};
      margin-bottom: 1rem;

      label {
        display: block;
        font-weight: ${(props) => props.theme.fontWeightBold};
        margin-bottom: 0;
      }
    }
  }
`;

const TC = styled(TableCell)`
  white-space: nowrap;
  font-weight: ${(props) =>
    props.row.isNewlyPublished
      ? props.theme.fontWeightBold
      : props.theme.fontWeightBase};

  ${({ statusField, row }) =>
    statusField &&
    css`
      color: ${requestFontColor(row.requestStatus)};
    `}
`;

const TR = styled.tr`
  font-weight: ${(props) =>
    props.isNewlyPublished
      ? props.theme.fontWeightBold
      : props.theme.fontWeightBase};
  color: ${(props) => (props.isNotAvailable ? '#aaaaaa' : 'inherit')};
`;

const StyledTableCard = styled(Card)`
  padding: 0 0 20px 0;
  box-shadow: none;
`;

const StyledFiltersWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  margin-bottom: 30px;
  margin-right: -3rem;

  ${down('md')} {
    margin-bottom: 10px;
    margin-right: -1rem;
  }
`;

const StyledCloseFilterButton = styled(Button)`
  /* height: 34px;  */
  border-radius: 65px;
  padding: 0.5rem 0 0.5rem 1.5rem;
  margin: 0.5rem 0.5rem 0 0;

  svg {
    opacity: 0;
    margin-left: 24px;
    font-size: 20px;
    transition: all 0.3s;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

class RequestTable extends AbstractTable {
  state = {
    order: {},
    isFiltersOpen: false,
    copied: false,
    copyHover: false,
  };

  constructor(props) {
    super(props);

    this.onCopied = this.onCopied.bind(this);
  }

  componentDidMount() {
    const { loadRecipients } = this.props;

    loadRecipients();
  }

  onCopied() {
    this.setState({
      copied: true,
    });

    setTimeout(() => this.setState({ copied: false }), 2000);
  }

  closeFilters = () => {
    this.setState({ isFiltersOpen: false });
  };

  toggleFilters = () => {
    this.setState({ isFiltersOpen: !this.state.isFiltersOpen });
  };

  applyFilters(filters) {
    const { history } = this.props;

    this.props.applyFilters(history, filters);
  }

  handleRemoveFilter = (filter) => {
    const filters = this.getFilters();

    filters[filter] = undefined;

    this.applyFilters({
      ...filters,
      page: undefined,
      limit: undefined,
    });
  };

  downloadFormatter = (address, row) => {
    const { fileDownload, isDownloading } = this.props;

    if (isDownloading.includes(address || row.requestJobId)) {
      return <Loader width={36} />;
    }

    return (
      <Button
        color="link"
        className="ignore-row-click"
        onClick={() => fileDownload(address)}
      >
        <Icon billon name="pdf" />
      </Button>
    );
  };

  details = (record) => {
    const { history } = this.props;

    // if (!record.documentBlockchainAddress && !record.requestJobId) {
    //   return null;
    // }
    if (!record.documentBlockchainAddress || !record.requestJobId) {
      return null;
    }

    return (
      <DocumentInfo>
        <Row margin={0} className="align-items-center" noGutters>
          <Col xl={7} lg={7} md={7} className="info-col">
            <label className="large">
              <FormattedMessage
                id="Blockchain address"
                defaultMessage="Blockchain address"
              />
              :
              <CopyToClipboard
                text={record.documentBlockchainAddress}
                onCopy={this.onCopied}
              >
                <MyButton
                  onMouseOver={() => this.setState({ copyHover: true })}
                  onMouseOut={() => this.setState({ copyHover: false })}
                >
                  <CopiedInfo show={this.state.copied}>
                    <FormattedMessage id="Copied" defaultMessage="Copied" />
                  </CopiedInfo>

                  <CopiedInfo
                    show={this.state.copied ? false : this.state.copyHover}
                  >
                    <FormattedMessage id="Copy" defaultMessage="Copy" />
                  </CopiedInfo>
                  <Icon name="copy" regular color="#7e7f7f" />
                </MyButton>
              </CopyToClipboard>
            </label>
            <span class="blockchain-address-span">
              {record.documentBlockchainAddress}
            </span>
          </Col>
          <Col xl={5} lg={5} md={5} className="info-col">
            <Row css="justify-content: flex-end">
              <Col md={6}>
                <Button
                  block
                  outline
                  onClick={() =>
                    history.push(`/request/${record.requestJobId}`)
                  }
                >
                  <FormattedMessage
                    id="Request details"
                    defaultMessage="Request details"
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </DocumentInfo>
    );
  };

  render() {
    const { records, isFetching, numberOfRecords, customer } = this.props;

    if (isFetching) {
      return (
        <Content fluid>
          <PageLoader />
        </Content>
      );
    }

    const filters = this.getFilters();

    const importantFilters = Object.keys(filters).filter(
      (filter) =>
        filter !== 'limit' &&
        filter !== 'page' &&
        filter !== 'documentType' &&
        filter !== 'order',
    );

    // let initialValues = {
    //   customer,
    // };

    const renderFilterValue = (filter) => {
      switch (filter) {
        case 'requestStatus':
          return formatters.requestStatusFormatter(filters[filter].value);
        case 'requestCreationTime':
          return (
            moment(filters[filter][0]).format('DD.MM.YYYY') +
            ' - ' +
            moment(filters[filter][1]).format('DD.MM.YYYY')
          );
        case 'requestValidityDateRange':
          return (
            moment(filters[filter][0]).format('DD.MM.YYYY') +
            ' - ' +
            moment(filters[filter][1]).format('DD.MM.YYYY')
          );
        default:
          return filters[filter];
      }
    };

    return (
      <>
        <Content fluid>
          <PageHeader classic>
            <FormattedMessage
              id="Documents requests list"
              defaultMessage="Documents requests list"
            />
            <Button
              fontWeight="700"
              fontSize="12px"
              color="link"
              type="button"
              underlineHover
              onClick={() => this.toggleFilters()}
            >
              <FormattedMessage id="Filters" defaultMessage="Filters" />
            </Button>
          </PageHeader>
          {this.state.isFiltersOpen && (
            <FilterFormRequests
              initialValues={filters}
              // categories={categories}
              statuses={[
                REQUEST_STATUS.ACCESS_GRANTED,
                REQUEST_STATUS.REJECTED,
                REQUEST_STATUS.REQUESTED,
                REQUEST_STATUS.INITIATED,
                REQUEST_STATUS.EXPIRED,
              ]}
              customer={customer}
              closeFilters={this.closeFilters}
            />
          )}

          {importantFilters.length > 0 && filters && (
            <StyledFiltersWrapper>
              {importantFilters.map(
                (filter) =>
                  filters[filter] && (
                    <StyledCloseFilterButton
                      outline
                      fontSize="12px"
                      fontWeight={600}
                      key={filter}
                      onClick={() => this.handleRemoveFilter(filter)}
                    >
                      <FormattedMessage
                        id={FILTERS_MAP[filter]}
                        defaultMessage={FILTERS_MAP[filter]}
                      />
                      {': '}
                      {renderFilterValue(filter)}
                      <Icon name="times-circle" regular />
                    </StyledCloseFilterButton>
                  ),
              )}
            </StyledFiltersWrapper>
          )}

          <StyledTableCard>
            <Table
              responsive
              tableData={records || []}
              sortMethod={this.handleSort}
              detailsComponent={this.details}
              rowComponent={(row, props) => (
                <TR
                  isNewlyPublished={row.isNewlyPublished}
                  isNotAvailable={row.status === DOCUMENT_STATUS.NOT_AVAILABLE}
                  {...props}
                />
              )}
            >
              <TableColumn
                sortable
                asc={this.state.order.requestJobId === 'ASC'}
                fieldName="requestJobId"
                renderCell={(row, props) => <TC row={row} {...props} />}
                // formatter={formatters.breakWordsFormatter}
              >
                <FormattedMessage id="Request ID" defaultMessage="Request ID" />
              </TableColumn>
              {/* <TableColumn
                fieldName="observer"
                renderCell={(row, props) => <TC row={row} {...props} />}
              >
                <FormattedMessage
                  id="Observer"
                  defaultMessage="Observer"
                />
              </TableColumn> */}
              <TableColumn
                sortable
                asc={this.state.order.requestCreationTime === 'ASC'}
                fieldName="requestCreationTime"
                formatter={formatters.momentFormatter}
                renderCell={(row, props) => <TC row={row} {...props} />}
              >
                <FormattedMessage id="Request issued" defaultMessage="Issued" />
              </TableColumn>
              <TableColumn
                sortable
                asc={this.state.order.requestValidity === 'ASC'}
                fieldName="requestValidity"
                formatter={formatters.momentFormatterWithoutTime}
                renderCell={(row, props) => <TC row={row} {...props} />}
              >
                <FormattedMessage
                  id="Valid until"
                  defaultMessage="Valid until"
                />
              </TableColumn>
              <TableColumn
                sortable
                asc={this.state.order.requestStatus === 'ASC'}
                fieldName="requestStatus"
                renderCell={(row, props) => (
                  <TC row={row} {...props} statusField />
                )}
                formatter={formatters.requestStatusFormatter}
              >
                <FormattedMessage id="Status" defaultMessage="Status" />
              </TableColumn>
            </Table>
            <Pagination
              onClick={this.handlePageChange}
              numberOfPages={getNumberOfPages(
                numberOfRecords,
                this.props.limit,
              )}
              currentPage={filters.page}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
            />{' '}
          </StyledTableCard>
          <br />
          <br />
        </Content>
      </>
    );
  }
}

RequestTable.propTypes = {
  records: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  loadRecipients: PropTypes.func.isRequired,
  isDownloading: PropTypes.array.isRequired,
  fileDownload: PropTypes.func,
  load: PropTypes.func.isRequired,
};

const mapStateToProps = ({ requests, config, category }) => ({
  records: requests.records,
  isFetching: requests.isFetching,
  numberOfRecords: requests.numberOfRecords,
  isDownloading: requests.isDownloading,
  limit: config.limit,
  // categories: category.records.map((v) => ({ label: v.path, value: v.path })),
});

const mapDispatchToProps = (dispatch) => ({
  load: (filters) =>
    dispatch(
      requestList({
        ...filters,
      }),
    ),
  loadRecipients: () => dispatch(requestRecipientsList({ isActive: true })),
  applyFilters: (history, filters) => applyFiltersAction(history, filters),
  fileDownload: (address) => dispatch(requestDownload(address)),
});

export default withRouter(
  reduxForm({
    form: 'requestTableForm',
  })(connect(mapStateToProps, mapDispatchToProps)(RequestTable)),
);
