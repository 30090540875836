import React from 'react';
import StatusTooltip from '../../components/StatusTooltip';
import StatusWithIcon from 'js/ui/StatusWithIcon';
import {
  NOTIFICATION_HISTORY_STATUSES,
  NOTIFICATION_HISTORY_ERRORS_STATUSES,
} from 'js/constraints';
import { mapNotificationHistoryStatuses } from 'js/formatters';
import { mapERRORS } from 'js/constraints';

import { generateRecordBasedId } from 'js/utils';

export const StatusWithToolTipFormatter = (value, record) => {
  const label = mapNotificationHistoryStatuses(value);
  const errorCheck =
    [NOTIFICATION_HISTORY_STATUSES.ERROR].includes(value) ||
    NOTIFICATION_HISTORY_ERRORS_STATUSES[value] !== undefined;

  if (errorCheck) {
    return (
      <StatusWithIcon label={label}>
        <StatusTooltip
          label={mapERRORS[value]}
          id={generateRecordBasedId(record)}
          delay={false}
        />
      </StatusWithIcon>
    );
  } else return label;
};
