/* global window */
import React from 'react';
import { Layout as LayoutModule, Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import config from 'js/config';
import { useHistory } from 'react-router-dom';
import { useSingleDocument } from '../../hooks/useSingleDocument';
import DocumentDetails from '../../components/DocumentDetails';
import { DOCUMENT_TYPE, SETTINGS_CONSTANTS } from 'js/constraints';
import useSettingsRequest from 'js/modules/Settings/hooks/useSettingsRequest';
import DocumentHistory from '../../components/DocumentHistory';
import { useParams } from 'react-router-dom';
import * as Styled from './styled';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { ContentContainer, Content } = ContentModule;
const { customer, activeDelivery } = config;

const DocumentDetailsPage = () => {
  const history = useHistory();
  const { id } = useParams();

  const { data: settings, isSettingsLoading } = useSettingsRequest({});
  const { data: document, isLoading } = useSingleDocument({ id });

  const publisherName = settings?.find(
    (item) => item.key === SETTINGS_CONSTANTS.PUBLISHER_NAME,
  );

  const handleOnBack = () => {
    if (document?.documentType === DOCUMENT_TYPE.PUBLIC) {
      history.push('/documents?filters.documentTypeList=PUBLIC');
    } else {
      history.push('/documents?filters.documentTypeList=PRIVATE');
    }
  };

  if (isSettingsLoading || isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <ContentContainer fluid>
      <Content fluid>
        <DocumentDetails
          document={document}
          customer={customer}
          onBackClick={handleOnBack}
          publisherName={publisherName?.value}
          backButtonLabel={
            <FormattedMessage id="Documents" defaultMessage="Documents" />
          }
        />
      </Content>
      {document?.history && (
        <ContentContainer fluid>
          <Styled.HistoryWrapper activeDelivery={activeDelivery}>
            <DocumentHistory
              historyRecords={document.history}
              currentId={document.jobId}
              title={document.title}
              customer={customer}
            />
          </Styled.HistoryWrapper>
        </ContentContainer>
      )}
    </ContentContainer>
  );
};

export default DocumentDetailsPage;
