import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { Layout, Utils } from 'billon-ui';
import { DocumentErrorExporter } from '../DocumentErrorExporter';
import { mapERRORS } from 'js/constraints';
import * as Styled from './styled';

const { Content: ContentModule } = Layout;
const { ErrorContent } = ContentModule;

const { Text } = Utils;

const ExportableErrorContent = ({
  headerTitleId,
  statusCode,
  jobId,
  title,
}) => {
  const intl = useIntl();

  const label = mapERRORS[statusCode];
  const message = label !== '' && intl.formatMessage({ id: label });

  return (
    <ErrorContent>
      <FormattedMessage id={headerTitleId} defaultMessage={headerTitleId} />
      <Text textAlign={'center'} margin={'15px 0 15px 0'}>
        {message}
        {'. '}
        <DocumentErrorExporter label={label} jobId={jobId} title={title}>
          <Styled.UnderlinedSpan>
            <FormattedMessage
              id="Export the error message"
              defaultMessage="Export the error message"
            />
          </Styled.UnderlinedSpan>
        </DocumentErrorExporter>
      </Text>
    </ErrorContent>
  );
};

export default ExportableErrorContent;
