import apiRoutes from '../../../api-routes';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';
import { getRequest } from 'js/helpers/apiClient';

export const useDownloadIdentityFile = (options) =>
  useMutationWithLogout(async () => {
    const response = await getRequest({
      url: apiRoutes.DOWNLOAD_RECIPIENT_LIST,
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });
    return response;
  }, options);
