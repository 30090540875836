import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Modal as ModalModule } from 'billon-ui';

import { PUBLICATION_TYPE } from 'js/constraints';

// Components
import { OneStepPublication, TwoStepPublication } from './componnets';

const { SuccessModal, CreateModal } = ModalModule;

export const PublishDocumentModal = ({ isOpen, toggle, ...props }) => {
  const [isSuccess, setIsSuccess] = React.useState(false);

  const { isOneStepPublication } = useSelector(({ config }) => ({
    isOneStepPublication: config.publicationType === PUBLICATION_TYPE.ONE_STEP,
  }));

  const onOpenSuccessModal = () => {
    setIsSuccess(true);
  };

  const onCloseSuccessModal = () => {
    setIsSuccess(false);
    toggle();
  };

  if (!isOpen) return null;

  if (isSuccess) {
    return (
      <SuccessModal isOpen onClose={onCloseSuccessModal}>
        <FormattedMessage
          id="Document publication has been initiated."
          defaultMessage="Document publication has been initiated."
        />
      </SuccessModal>
    );
  }

  return (
    <CreateModal
      isOpen
      onCancel={toggle}
      onSuccess={onOpenSuccessModal}
      initialValues={{}}
      className="modal-xl"
    >
      {isOneStepPublication ? (
        <OneStepPublication {...props} onCancel={toggle} />
      ) : (
        <TwoStepPublication {...props} onCancel={toggle} />
      )}
    </CreateModal>
  );
};
