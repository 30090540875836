import React, { useState } from 'react';
import { useCategoryList } from '../../hooks/useCategoryList';
import * as Styled from './styled';
import { ExpandButton } from '../ExpandButton';
import { ActiveSwitch } from '../ActiveSwitch';
import { AddCategoryCell } from '../AddCategoryCell';
import useFilters from '../../../../hooks/useFilters';
import { categoryStatusFormatter } from '../../../../formatters';
import { CATEGORY_STATUSES, UserRoles } from '../../../../constraints';
import { ProtectedComponent } from '../../../Auth/components';
import DocumentNavigator from '../DocumentNavigator';
import { useConfigContext } from '../../../Config/hooks/useConfig';

export const CategoryList = ({ parentId, depth = 0, onCreate }) => {
  const { filters, navigateWithSearchQuery } = useFilters();

  const { customer } = useConfigContext();

  const [openCategories, setOpenCategories] = useState([]);
  const { data, isLoading } = useCategoryList({
    parentId,
    ...filters.filters,
  });

  const onNavigateToDocuments = (categoryId, documentType) => {
    navigateWithSearchQuery(
      {
        filters: {
          categoryId,
          documentTypeList: documentType,
        },
      },
      '/documents',
    );
  };

  const isCategoryOpen = (categoryId) =>
    openCategories.some((cat) => cat === categoryId);

  const toggleOpenCategory = (categoryId) => {
    const alreadyOpened = isCategoryOpen(categoryId);

    if (alreadyOpened) {
      setOpenCategories(openCategories.filter((cat) => cat !== categoryId));
    } else {
      setOpenCategories([categoryId, ...openCategories]);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      {(data?.rows || []).map((category) => (
        <div key={category.id}>
          <Styled.Row bold={!parentId}>
            {Array.from(Array(depth)).map(() => (
              <Styled.FirstCell />
            ))}
            <Styled.FirstCell>
              {category.hasChildren && (
                <ExpandButton
                  isOpen={isCategoryOpen(category.id)}
                  onClick={() => toggleOpenCategory(category.id)}
                />
              )}
            </Styled.FirstCell>
            <Styled.NameCell>
              <ProtectedComponent
                roles={[UserRoles.VISITOR, UserRoles.DPI]}
                render={false}
              >
                {category.name}
              </ProtectedComponent>
              <ProtectedComponent
                roles={[UserRoles.AGENT, UserRoles.ADMIN]}
                render={false}
              >
                <AddCategoryCell
                  id={category.id}
                  name={category.name}
                  onClick={() => onCreate(category)}
                />
              </ProtectedComponent>
            </Styled.NameCell>
            <Styled.Cell>
              {categoryStatusFormatter(category.status)}
            </Styled.Cell>
            <Styled.Cell>
              <ProtectedComponent
                roles={[UserRoles.AGENT, UserRoles.ADMIN]}
                render={false}
              >
                <ActiveSwitch
                  isActive={category.isActive}
                  categoryId={category.id}
                  parentId={parentId}
                  disabled={category.status === CATEGORY_STATUSES.IN_PROGRESS}
                />
              </ProtectedComponent>
            </Styled.Cell>
            <Styled.Cell>{category.noOfDocsInPath}</Styled.Cell>
            <Styled.Cell>
              <DocumentNavigator
                customer={customer}
                onNavigate={(documentType) => {
                  onNavigateToDocuments(category.id, documentType);
                }}
              />
            </Styled.Cell>
          </Styled.Row>
          {isCategoryOpen(category.id) && (
            <CategoryList
              parentId={category.id}
              depth={depth + 1}
              onCreate={onCreate}
            />
          )}
        </div>
      ))}
    </>
  );
};
