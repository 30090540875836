/* global window */
import React from 'react';
import { Layout as LayoutModule, Utils } from 'billon-ui';
import { useQueryClient } from 'react-query';
import MeDetails from '../../components/MeDetails';
import useMeRequest from 'js/modules/Settings/hooks/useMeRequest';
import ContentContainerGrey from 'js/ui/ContentContainerGrey';
import useSettingsRequest from '../../../Settings/hooks/useSettingsRequest';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

const MePage = () => {
  const queryClient = useQueryClient();
  const { data: me, isLoading } = useMeRequest();
  const { data: settings, isLoading: areSettingsLoading } =
    useSettingsRequest();

  if (isLoading || areSettingsLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <ContentContainerGrey>
      <MeDetails me={me} settings={settings} />
    </ContentContainerGrey>
  );
};

export default MePage;
