import styled from 'styled-components';

export const RenderedNotificationContainer = styled.div`
  border: 1px solid #cad5dd;
  border-radius: 3px;
  padding: 0.6rem 1rem;
  font-size: 0.875rem;
  background-color: #eaeef0;
  min-height: 7rem;
  word-break: break-all;
`;
