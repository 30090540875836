import { Grid } from 'billon-ui';
import styled from 'styled-components';

const { Col } = Grid;

export const DetailsCol = styled(Col)`
  max-height: 146px;
`;

export const DetailsHeader = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
