import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

const StyledTooltip = styled.div`
  background: #ffffff;
  padding: 4px;
  box-shadow: 2px 2px 9px 2px #cecece;

  p {
    padding: 0px;
    margin: 0px;
  }
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <StyledTooltip>
        <p>
          <FormattedMessage
            id="% of disc space usage"
            defaultMessage="% of disc space usage"
          />
          : {payload[0].value}%
        </p>

        {payload.length > 0 && (
          <p>
            <FormattedMessage
              id="total resources available"
              defaultMessage="total resources available"
            />
            : {payload[0].payload.availableMemory} GB
          </p>
        )}
      </StyledTooltip>
    );
  }

  return null;
};

const ConsumptionBarChart = (props) => {
  const [size, setSize] = useState([0, 0]);

  const useWindowSize = () => {
    useLayoutEffect(() => {
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  };

  const updateSize = () => {
    setSize([window.innerWidth, window.innerHeight]);
  };

  const formatTicks = (v) => {
    if (v === 'TO-DATE' && props.language === 'pl') {
      return 'Aktualne';
    } else {
      return v;
    }
  };

  const [width] = useWindowSize();

  return (
    <div
      style={{
        width: width > 1000 ? '98%' : '1700px',
        height: 300,
      }}
    >
      <ResponsiveContainer>
        <BarChart width={1700} height={250} data={props.data.report}>
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={0}
            tickFormatter={(v) => formatTicks(v)}
          />
          <YAxis padding={{ top: 30 }} />
          <Tooltip content={CustomTooltip} />
          <Bar dataKey="memoryUsagePercent" fill="#990054">
            {props.data.report.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  index === props.data.report.length - 1 ? '#ff9200' : '#990054'
                }
              />
            ))}
            <LabelList
              dataKey="memoryUsagePercent"
              formatter={(v) => parseFloat(v).toFixed(1) + '%'}
              position="top"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const mapStateToProps = ({ language, config }) => ({
  language: language.language || config.language,
});

export default connect(mapStateToProps, {})(ConsumptionBarChart);
