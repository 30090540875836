import React from 'react';
import * as formatters from '../../../../formatters';
import { Column } from 'js/components/CommonTable/helpers';
import { contentFormatter } from './formatters';

import { StatusWithToolTipFormatter } from './statusFormatter';

export const NotificationHistoryColumns: Array<Column | false> = [
  {
    name: 'deliveryDate',
    message: 'Date of delivery',
    className: 'fixed-width-200',
    formatter: formatters.momentInTimezoneFormatter,
  },
  {
    name: 'clientId',
    message: 'Recipient id',
    className: 'fixed-width-200',
    formatter: (value: any) => value,
  },
  {
    name: 'contactDetails',
    message: 'Email/Phone number',
    formatter: (value: any) => value,
  },
  {
    name: 'status',
    message: 'Status',
    className: 'fixed-width-200',
    formatter: StatusWithToolTipFormatter,
  },
  {
    name: 'Content',
    message: 'Content',
    className: 'fixed-width-56',
    formatter: contentFormatter,
  },
];
