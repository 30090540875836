import { Grid, Modal as ModalModule, Utils } from 'billon-ui';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { BlockchainVisual, CopiedInfo } from '../../../../components';
import {
  PUBLICATION_MODES,
  RESEND_NOTIFICATION_MODES,
  UserRoles,
} from '../../../../constraints';
import {
  useDownloadSingleDocument,
  useResendAuthorizationCode,
} from '../../hooks';
import { useForgetDocumentRequest } from '../../../../hooks/useForgetDocumentRequest';
import { useDownloadRecipientsList } from '../../hooks/useDownloadRecipientsList';
import {
  AdvancedFeatures,
  BadgedTitle,
  DetailsSection,
  ManageSection,
} from './components';
import { downloadQrCode } from '../../helpers/utils';
import { useConfigContext } from '../../../Config/hooks/useConfig';
import { ProtectedComponent } from '../../../Auth/components';

const { Button: ButtonModule } = Utils;
const { BackButton } = ButtonModule;
const { Row, Col } = Grid;
const { SuccessModal, ConfirmModal } = ModalModule;

const DocumentDetails = ({
  document,
  onBackClick,
  publisherName,
  backButtonLabel,
}) => {
  const history = useHistory();
  const { customer } = useConfigContext();

  const {
    mutate: resendAuthorizationCode,
    isLoading: isResendingAuthorizationCode,
  } = useResendAuthorizationCode(document.documentBlockchainAddress);

  const { mutate: download, isLoading: isDownloading } =
    useDownloadSingleDocument(document.jobId, document.title);

  const { mutate: forgetDocument, isLoading: isForgetting } =
    useForgetDocumentRequest(document.documentBlockchainAddress);

  const {
    mutate: downloadRecipientsList,
    isLoading: isDownloadingRecipientsList,
  } = useDownloadRecipientsList(document.jobId, document.title);

  const [
    resendAuthorizationCodesModalOpened,
    setresendAuthorizationCodesModalOpened,
  ] = useState(false);
  const [
    resendAuthorizationCodesSuccessModalOpened,
    setresendAuthorizationCodesSuccessModalOpened,
  ] = useState(false);
  const [forgetDocumentModalOpened, setforgetDocumentModalOpened] =
    useState(false);
  const [
    forgetDocumentSuccessModalOpened,
    setforgetDocumentSuccessModalOpened,
  ] = useState(false);
  const [callbackBlockchainAddress, setCallbackBlockchainAddress] =
    useState('');

  const handleNotificationHistoryModalOpen = () => {
    history.push(`/document/${document.jobId}/notificationHistory`);
  };

  const handleResendAuthorizationCodes = (blockchainAddress) => {
    setresendAuthorizationCodesModalOpened(true);
    setCallbackBlockchainAddress(blockchainAddress);
  };

  const handleResendAuthorizationCodesFinished = () => {
    resendAuthorizationCode(callbackBlockchainAddress);
    setresendAuthorizationCodesModalOpened(false);
    setresendAuthorizationCodesSuccessModalOpened(true);
  };

  const handleCancelResendAuthorizationCodes = () => {
    setresendAuthorizationCodesSuccessModalOpened(false);
    setresendAuthorizationCodesModalOpened(false);
  };

  const handleForgetDocument = (blockchainAddress) => {
    setforgetDocumentModalOpened(true);
    setCallbackBlockchainAddress(blockchainAddress);
  };

  const handleForgetDocumentFinished = () => {
    forgetDocument(callbackBlockchainAddress);
    setforgetDocumentModalOpened(false);
    setforgetDocumentSuccessModalOpened(true);
  };

  const handleCancelForgetDocument = () => {
    setforgetDocumentSuccessModalOpened(false);
    setforgetDocumentModalOpened(false);
  };

  const handleForgetDocumentSuccessFinished = () => {
    history.push('/documents/{"type":"PRIVATE"}');
  };

  const handleResendNotificationClick = () => {
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.SELECT_TYPE}`,
    );
  };

  const handleErratumClick = () => {
    history.push(
      `/document/${document.jobId}/update/${PUBLICATION_MODES.CORRECTED}`,
    );
  };

  const handleIncidentClick = () => {
    history.push(
      `/document/${document.jobId}/update/${PUBLICATION_MODES.INCIDENT}`,
    );
  };

  const handleNevVersionClick = () => {
    history.push(`/document/${document.jobId}/update/${PUBLICATION_MODES.NEW}`);
  };

  const handleDownloadQRClick = () =>
    downloadQrCode(
      document.documentPreviewUrl,
      document.documentBlockchainAddress,
    );

  const handleDownloadRecipientsList = () => {
    downloadRecipientsList();
  };

  return (
    <>
      <Row margin={0}>
        <Col xl={9}>
          <Row margin={'0 0 20px 0 '}>
            <BackButton secondary onClick={onBackClick}>
              {backButtonLabel}
            </BackButton>
          </Row>
          <Row margin={0}>
            <BadgedTitle
              title={document.title}
              errata={document.errata}
              publicationMode={document.publicationMode}
              status={document.status}
            />
          </Row>
          <Row>
            <Col lg={6}>
              <Col margin={'0 0 20px 0'} padding={0}>
                <CopiedInfo
                  label={'Document blockchain address'}
                  alt={'Document does not yet have a blockchain address'}
                  value={document?.documentBlockchainAddress}
                  emphasisedHeader={true}
                  scale={'section'}
                />
              </Col>
              <ManageSection
                documentType={document.documentType}
                customer={customer}
                handleNotificationHistoryModalOpen={
                  handleNotificationHistoryModalOpen
                }
                handleDownloadRecipientsList={handleDownloadRecipientsList}
                isDownloadingRecipientsList={isDownloadingRecipientsList}
                handleResendNotificationClick={handleResendNotificationClick}
                resendAuthorizationCode={resendAuthorizationCode}
                isResendingAuthorizationCode={isResendingAuthorizationCode}
                onDownload={download}
                isDownloading={isDownloading}
              />

              <ProtectedComponent
                roles={[UserRoles.AGENT, UserRoles.ADMIN]}
                render={false}
              >
                <AdvancedFeatures
                  documentType={document.documentType}
                  handleErratumClick={handleErratumClick}
                  handleIncidentClick={handleIncidentClick}
                  handleNevVersionClick={handleNevVersionClick}
                  handleDownloadQRClick={handleDownloadQRClick}
                />
              </ProtectedComponent>
            </Col>
            <Col lg={{ size: 5, offset: 1 }}>
              <DetailsSection
                document={document}
                publisherName={publisherName}
                customer={customer}
              />
            </Col>
          </Row>
        </Col>

        <Col
          xl={{ size: 3, offset: 0 }}
          lg={{ size: 6, offset: 3 }}
          sm={{ size: 8, offset: 2 }}
        >
          <BlockchainVisual remoteSignAnimation size="100%" fixed />
        </Col>
      </Row>

      <ConfirmModal
        isLoading={isResendingAuthorizationCode}
        isOpen={resendAuthorizationCodesModalOpened}
        onAccept={handleResendAuthorizationCodesFinished}
        onCancel={handleCancelResendAuthorizationCodes}
      >
        <FormattedMessage
          id="Are You sure You want to resend link and authorization code for document?"
          defaultMessage="Are You sure You want to resend link and authorization code for document?"
        />
      </ConfirmModal>
      <SuccessModal
        isOpen={resendAuthorizationCodesSuccessModalOpened}
        title={
          <FormattedMessage
            id="Authorization codes sent"
            defaultMessage="Authorization codes sent"
          />
        }
        onClose={handleCancelResendAuthorizationCodes}
      >
        <FormattedMessage
          id="Authorization codes has been sent successfully."
          defaultMessage="Authorization codes has been sent successfully."
        />
      </SuccessModal>

      <ConfirmModal
        isLoading={isForgetting}
        isOpen={forgetDocumentModalOpened}
        onAccept={handleForgetDocumentFinished}
        onCancel={handleCancelForgetDocument}
      >
        <FormattedMessage
          id="Are You sure You want to forget this document?"
          defaultMessage="Are You sure You want to forget this document?"
        />
      </ConfirmModal>
      <SuccessModal
        isOpen={forgetDocumentSuccessModalOpened}
        onClose={handleForgetDocumentSuccessFinished}
      >
        <FormattedMessage
          id="Forgetting document started."
          defaultMessage="Forgetting document started."
        />
      </SuccessModal>
    </>
  );
};

export default DocumentDetails;
