import React from 'react';
import { Utils, Grid } from 'billon-ui';
import { SignatureStepDiagramItem } from './SignatureStepDiagramItem';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { AGREEMENT_STATUS } from '../../../../constraints';

const { Row, Col } = Grid;
const { Text } = Utils;

const SignatureStepsWrapper = styled.div`
  background-color: #f0f9f5;
  border: 1px solid #abc4c661;
  margin-left: -3rem;
  margin-right: -3rem;
  margin-top: 3rem;
  @media (max-width: 1199px) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
`;

const LineContainer = styled.div`
  width: 20%;
  position: relative;
  top: 0;
  left: -3%;
  @media (max-width: 1199px) {
    display: none;
  }
`;

const Line = styled.div`
  width: 130%;
  position: absolute;
  border-top: 1px solid #abc4c6;
  margin-top: 1rem;
`;

const SignatureStepsDiagram = (props) => {
  const { agreementStatus, history } = props.document;

  return (
    <SignatureStepsWrapper>
      <Row
        margin={'1.5rem 3rem'}
        css={`
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: 767px) {
            margin-left: 1rem;
            margin-right: 1rem;
          }
        `}
      >
        <Col xl={2}>
          <Text margin={'0 0 30px 0'} fontWeight={700} as="h5">
            <FormattedMessage
              id="Signing steps"
              defaultMessage="Signing steps"
            />{' '}
          </Text>
        </Col>
        <Col xl={10}>
          <Row
            css={`
              @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
            `}
          >
            <SignatureStepDiagramItem
              stepNumber={1}
              date={
                history.find(
                  (el) =>
                    el.agreementStatus ===
                    AGREEMENT_STATUS.WAITING_FOR_RECEIVER,
                )
                  ? history.find(
                      (el) =>
                        el.agreementStatus ===
                        AGREEMENT_STATUS.WAITING_FOR_RECEIVER,
                    ).publicationDate
                  : ''
              }
              backgroundColor="#00b769"
            >
              <FormattedMessage
                id="Sent over to the recipient for signing"
                defaultMessage="Sent over to the recipient for signing"
              />
              .
            </SignatureStepDiagramItem>
            <LineContainer>
              <Line />
            </LineContainer>

            {agreementStatus === AGREEMENT_STATUS.PUBLISHED && (
              <SignatureStepDiagramItem
                stepNumber={2}
                date={
                  history.find(
                    (el) => el.agreementStatus === AGREEMENT_STATUS.PUBLISHED,
                  )
                    ? history.find(
                        (el) =>
                          el.agreementStatus === AGREEMENT_STATUS.PUBLISHED,
                      ).publicationDate
                    : ''
                }
                backgroundColor="#00b769"
              >
                <FormattedMessage id="Confirmed2" defaultMessage="Confirmed" />.{' '}
                <br />
                <FormattedMessage
                  id="Document signed"
                  defaultMessage="Document signed"
                />
                .
              </SignatureStepDiagramItem>
            )}

            {agreementStatus === AGREEMENT_STATUS.WAITING_FOR_RECEIVER && (
              <SignatureStepDiagramItem
                stepNumber={2}
                backgroundColor="#fff"
                fontColor="#00b769"
              >
                <FormattedMessage id="Confirmed2" defaultMessage="Confirmed" />.{' '}
                <br />
                <FormattedMessage
                  id="Document signed"
                  defaultMessage="Document signed"
                />
                .
              </SignatureStepDiagramItem>
            )}

            {agreementStatus === AGREEMENT_STATUS.REJECTED && (
              <SignatureStepDiagramItem
                stepNumber={2}
                date={
                  history.find(
                    (el) => el.agreementStatus === AGREEMENT_STATUS.REJECTED,
                  )
                    ? history.find(
                        (el) =>
                          el.agreementStatus === AGREEMENT_STATUS.REJECTED,
                      ).publicationDate
                    : ''
                }
                backgroundColor="#fc5151"
              >
                <FormattedMessage id="Rejected2" defaultMessage="Rejected" />.{' '}
                <br />
                <FormattedMessage
                  id="Document rejected by the recipient"
                  defaultMessage="Document rejected by the recipient"
                />
                .
              </SignatureStepDiagramItem>
            )}
          </Row>
        </Col>
      </Row>
    </SignatureStepsWrapper>
  );
};

SignatureStepsDiagram.propTypes = {
  document: PropTypes.object.isRequired,
};

export default SignatureStepsDiagram;
