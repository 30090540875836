import React, { Component } from 'react';
import { Modal as ModalModule } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

const { SuccessModal, ConfirmModal } = ModalModule;

class RemoteSignResendAuthCodeModal extends Component {
  render() {
    const {
      isResendingAuthorizationCodes,
      resendAuthorizationCodesModalOpened,
      resendAuthorizationCodesSuccessModalOpened,
      handleResendAuthorizationCodesFinished,
      handleCancelResendAuthorizationCodes,
      // resendAuthorizationCodesErrorModalOpened
    } = this.props;

    return (
      <>
        <ConfirmModal
          isLoading={isResendingAuthorizationCodes}
          isOpen={resendAuthorizationCodesModalOpened}
          onAccept={handleResendAuthorizationCodesFinished}
          onCancel={handleCancelResendAuthorizationCodes}
        >
          <FormattedMessage
            id="Are You sure You want to resend link and authorization code for document?"
            defaultMessage="Are You sure You want to resend link and authorization code for document?"
          />
        </ConfirmModal>
        <SuccessModal
          isOpen={resendAuthorizationCodesSuccessModalOpened}
          title={
            <FormattedMessage
              id="Authorization codes sent"
              defaultMessage="Authorization codes sent"
            />
          }
          onClose={handleCancelResendAuthorizationCodes}
        >
          <FormattedMessage
            id="Authorization codes has been sent successfully."
            defaultMessage="Authorization codes has been sent successfully."
          />
        </SuccessModal>

        {/* <ErrorModal
          isOpen={resendAuthorizationCodesErrorModalOpened}
          title={<FormattedMessage id="Authorization codes sending error" defaultMessage="Authorization codes sending error" />}
          onClose={handleCancelResendAuthorizationCodes}
        >
          <FormattedMessage
            id="There was some error while sending authorization codes"
            defaultMessage="There was some error while sending authorization codes"
          />.
        </ErrorModal> */}
      </>
    );
  }
}

RemoteSignResendAuthCodeModal.propTypes = {
  isResendingAuthorizationCodes: PropTypes.bool.isRequired,
  resendAuthorizationCodesModalOpened: PropTypes.bool.isRequired,
  resendAuthorizationCodesSuccessModalOpened: PropTypes.bool.isRequired,
  handleResendAuthorizationCodesFinished: PropTypes.func.isRequired,
  handleCancelResendAuthorizationCodes: PropTypes.func.isRequired,
};

export default withRouter(RemoteSignResendAuthCodeModal);
