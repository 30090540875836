import React from 'react';
import apiRoutes from 'js/api-routes';
import { getRequest } from 'js/helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';

const useSmsCodeRequest = (options) => {
  const token = options?.token;

  return useMutationWithLogout(async () => {
    const { data } = await getRequest({
      url: apiRoutes.SMS_CODE,
      headers: {
        Authorization: token ? token : sessionStorage.getItem('token'),
      },
    });
    return data;
  }, options);
};

export default useSmsCodeRequest;
