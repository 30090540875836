import React from 'react';
import { Redirect } from 'react-router-dom';
import { CUSTOMER } from '../../../../constraints';
import { useConfigContext } from '../../../Config/hooks/useConfig';

export const RootRedirectPage = () => {
  const { customer } = useConfigContext();

  console.log(customer);

  return ({
    [CUSTOMER.DIPLOMA]: <Redirect to='/documents?filters.documentTypeList=PRIVATE' />,
    [CUSTOMER.DEFAULT]: <Redirect to='/documents?filters.documentTypeList=PUBLIC' />,
    [CUSTOMER.TAURON]: <Redirect to='/documents?filters.documentTypeList=PUBLIC' />
  }[customer])
}
