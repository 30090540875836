import { Form as FormModule, Utils } from 'billon-ui';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

const { Button: ButtonModule, Card } = Utils;
const { Button, IconButton } = ButtonModule;
const { SwitchField: SwitchFieldOriginal } = FormModule;

export const StyledFilterButton = styled.button`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-shrink: 0;
  flex-basis: 220px;
  min-height: 100px;
  background: #ffffff;
  border: 2px solid #c9d4de;
  border-radius: 15px;
  cursor: ${(props) => (props.clickable ? 'pointer' : '')};
  padding: 16px;
  margin-right: 20px;
  font-size: 12px;
  text-align: left;

  background-color: ${({ isSelected, hoverColor }) => isSelected && hoverColor};

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    border: 2px solid ${(props) => (props.color ? props.color : '#c9d4de')};
    background-color: ${({ hoverColor }) => hoverColor};
  }

  p {
    margin: 0;
    font-size: 12px;
    white-space: break-spaces;

    &.title {
      font-weight: 600;
    }

    &.numberOfDocuments {
      font-size: 14px;
    }

    &.see {
      align-self: flex-end;
    }
  }
`;

export const StyledFiltersWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px 0;
  margin-bottom: 20px;
  margin-right: -3rem;
  margin-left: -3rem;
  padding-left: 3rem;

  ${down('md')} {
    margin-bottom: 10px;
    margin-right: -1rem;
    margin-left: -1rem;
    padding-left: 1rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
    margin: 0 3rem;

    ${down('md')} {
      margin: 0 1rem;
    }
  }

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #c9d4de;
  }
`;

export const StyledCloseFilterButton = styled(Button)`
  height: 34px;
  border-radius: 65px;
  padding: 0.5rem 0 0.5rem 1.5rem;

  svg {
    opacity: 0;
    margin-left: 24px;
    font-size: 20px;
    transition: all 0.3s;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  font-size: 20px;
  color: #cad5dd;
`;

export const StyledTableCard = styled(Card)`
  padding: 0 0 20px 0;
  box-shadow: none;
`;

export const TR = styled.tr`
  font-weight: ${(props) =>
    props.isInProgress
      ? props.theme.fontWeightLight
      : props.theme.fontWeightBase};
  color: ${(props) => (props.isInProgress ? '#aaa' : 'inherit')};
`;

export const SwitchField = styled(SwitchFieldOriginal)`
  &.disabled {
    .slider {
      cursor: not-allowed;
    }
  }
`;
