import React from 'react';
import { useQueryClient } from 'react-query';
import { HelpCard } from '../../components';
import { Layout as LayoutModule, Utils } from 'billon-ui';
import ContentContainerGrey from 'js/ui/ContentContainerGrey';
import useSettingsRequest from 'js/modules/Settings/hooks/useSettingsRequest';
import { SETTINGS_CONSTANTS } from 'js/constraints';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

const HelpPage = () => {
  const queryClient = useQueryClient();
  const { data: settings, isSettingsLoading } = useSettingsRequest({});
  const supportEmail = settings?.find(
    (item) => item.key === SETTINGS_CONSTANTS.SUPPORT_EMAIL,
  )?.value;

  if (isSettingsLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <ContentContainerGrey>
      <HelpCard supportEmail={supportEmail} />
    </ContentContainerGrey>
  );
};

export default HelpPage;
