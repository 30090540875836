import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Utils } from 'billon-ui';

import * as Styled from './styled';

import { DOCUMENT_STATUS, DOCUMENT_PUBLICATION_STATUS } from 'js/constraints';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const ActionButtons = ({
  record,
  onDeleteDocument,
  onPublishDocument,
}) => {
  const { push } = useHistory();

  const deleteDocumentButton = (
    <Styled.Col md={6}>
      <Styled.RedButton outline block onClick={() => onDeleteDocument(record)}>
        <FormattedMessage
          id="Discard document"
          defaultMessage="Discard document"
        />
      </Styled.RedButton>
    </Styled.Col>
  );

  return (
    <Styled.Row>
      {record.status === DOCUMENT_STATUS.PREPARED_TO_SIGN && (
        <>
          {deleteDocumentButton}

          <Styled.Col md={6}>
            <Button
              outline
              onClick={() => onPublishDocument(record, true)}
              block
            >
              <FormattedMessage
                id="Publish document"
                defaultMessage="Publish document"
              />
            </Button>
          </Styled.Col>
        </>
      )}
      {record.status === DOCUMENT_STATUS.CURRENT && (
        <Styled.Col md={6}>
          <Button
            outline
            onClick={() => push(`/document/${record.jobId}`)}
            block
          >
            <FormattedMessage
              id="Document details"
              defaultMessage="Document details"
            />
          </Button>
        </Styled.Col>
      )}
      {record.status === DOCUMENT_STATUS.DEPRECATED && (
        <Styled.Col md={6}>
          <Button
            outline
            onClick={() =>
              push(`/document/${record.documentBlockchainAddress}`)
            }
            block
          >
            <FormattedMessage
              id="Document details"
              defaultMessage="Document details"
            />
          </Button>
        </Styled.Col>
      )}
      {record.documentPublicationStatus ===
        DOCUMENT_PUBLICATION_STATUS.PUBLISHING_ERROR && (
        <>{deleteDocumentButton}</>
      )}
    </Styled.Row>
  );
};
