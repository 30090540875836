import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Utils } from 'billon-ui';

const { Card: BillonCard, Icon, Text } = Utils;

export const Card = styled(BillonCard)`
  margin: 0;
`;

export const Title = styled.span`
  text-align: left;
  padding-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export const CardBodyWrapper = styled.div`
  padding-top: 35px;
`;
