import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { Utils } from 'billon-ui';

import * as Styled from './styled';

const { Icon } = Utils;

export const BlockchainAddress = ({ record }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isCopyHover, setIsCopyHover] = useState(false);

  if (!record.documentBlockchainAddress) {
    return (
      <label className="large">
        <FormattedMessage
          id="Document does not yet have a blockchain address"
          defaultMessage="Document does not yet have a blockchain address"
        />
      </label>
    );
  }

  return (
    <>
      <label className="large">
        <FormattedMessage
          id="Blockchain address"
          defaultMessage="Blockchain address"
        />
        :
        <CopyToClipboard
          text={record.documentBlockchainAddress}
          onCopy={setIsCopied}
        >
          <Styled.CopyButton
            onMouseOver={() => setIsCopyHover(true)}
            onMouseOut={() => setIsCopyHover(false)}
          >
            <Styled.CopiedInfo show={isCopied}>
              <FormattedMessage id="Copied" defaultMessage="Copied" />
            </Styled.CopiedInfo>

            <Styled.CopiedInfo show={isCopied ? false : isCopyHover}>
              <FormattedMessage id="Copy" defaultMessage="Copy" />
            </Styled.CopiedInfo>
            <Icon name="copy" regular color="#7e7f7f" />
          </Styled.CopyButton>
        </CopyToClipboard>
      </label>
      <span className="blockchain-address-span">
        {record.documentBlockchainAddress}
      </span>
    </>
  );
};
