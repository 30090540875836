import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Layout, Grid, Utils, Form as FormModule } from 'billon-ui';
import Modal from '../../../../../../ui/Modal';
import * as Styled from './styled';
import { ModalBody } from '../../../../../../ui/CustomConfirmModal/styled';
import RecipientsTable from '../RecipientsTable';
import RecipientsFilterForm from '../RecipientsFilterForm';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { DOCUMENT_RECIPIENTS_GROUPS } from '../../../../../../constraints';
import { useIntl } from 'react-intl';

const { CheckboxField } = FormModule;
const { Col, Row } = Grid;
const { Button: ButtonModule, Loader: LoaderModule } = Utils;
const { Button } = ButtonModule;
const { Content: ContentModule } = Layout;
const { PageLoader } = LoaderModule;
const { Content } = ContentModule;

const ModalHeaderPanel = () => (
  <Row>
    <Styled.TitleContainer md={6}>
      <Styled.ModalTitle>
        <FormattedMessage
          id="Select a group of recipients"
          defaultMessage="Select a group of recipients"
        />
      </Styled.ModalTitle>
    </Styled.TitleContainer>
    <Col md={6}>
      <RecipientsFilterForm />
    </Col>
  </Row>
);

const SelectRecipientsGroup = ({
  isOpen,
  isLoading,
  onClose,
  onBack,
  onNext,
  documentBlockchainAddress,
  isNoGroupSelected,
}) => {
  const { formatMessage } = useIntl();

  const groupsOptions = [
    {
      label: formatMessage({
        id: 'New recipients',
      }),
      value: DOCUMENT_RECIPIENTS_GROUPS.NEW,
      checked: true,
    },
    {
      label: formatMessage({
        id: 'Incorrectly notified',
      }),
      value: DOCUMENT_RECIPIENTS_GROUPS.ERROR,
      checked: true,
    },
  ];

  const formMethods = useForm({
    defaultValues: {
      groups: groupsOptions,
    },
  });

  const handleFormSubmit = (values) => {
    onNext(values);
  };

  const { handleSubmit, control } = formMethods;

  const GroupsControllers = () =>
    groupsOptions.map((option, i) => (
      <Styled.CheckBoxWrapper>
        <Controller
          name={`groups[${i}].checked`}
          control={control}
          render={({ field, fieldState }) => (
            <CheckboxField
              input={{
                ...field,
                checked: field.value,
              }}
              meta={{
                touched: fieldState.invalid,
                error: fieldState.error,
              }}
              label={option.label}
            />
          )}
        />
      </Styled.CheckBoxWrapper>
    ));

  if (isLoading) {
    return (
      <Modal
        isOpen={true}
        closeIconEnabled={true}
        onClose={onClose}
        toggle={onClose}
      >
        <Content fluid>
          <PageLoader />
        </Content>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      onClose={onClose}
      closeIconEnabled={true}
      closePadding
    >
      <ModalHeaderPanel />

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(() => {})}>
          <GroupsControllers />
          {isNoGroupSelected && (
            <Styled.WarningMessage>
              <FormattedMessage
                id="Select at least one group to continue"
                defaultMessage="Select at least one group to continue"
              />
            </Styled.WarningMessage>
          )}

          <ModalBody>
            <RecipientsTable
              documentBlockchainAddress={documentBlockchainAddress}
            />
          </ModalBody>

          <Styled.ModalFooter>
            <Styled.ZeroCol md={3}>
              <Styled.BackButton secondary onClick={onBack}>
                <FormattedMessage id="back" defaultMessage="back" />
              </Styled.BackButton>
            </Styled.ZeroCol>
            <Col md={6}>
              <Button size="lg" block onClick={handleSubmit(handleFormSubmit)}>
                <FormattedMessage id="Next" defaultMessage="Close" />
              </Button>
            </Col>
            <Col md={3}></Col>
          </Styled.ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default SelectRecipientsGroup;
