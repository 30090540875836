import { createReducer } from 'redux-act';
import * as actions from './actions';

export default createReducer(
  {
    [actions.requestList]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.receiveListSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      records: payload.rows,
      numberOfRecords: payload.count,
    }),
    [actions.receiveListFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),
    // Reports
    [actions.requestYearlyReport]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.requestYearlyReportSuccess]: (state, payload) => ({
      ...state,
      yearlyReport: payload,
      isFetching: false,
    }),
    [actions.requestYearlyReportFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),

    [actions.requestFairUsageReport]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.requestFairUsageReportSuccess]: (state, payload) => ({
      ...state,
      fairUsageReport: payload,
      isFetching: false,
    }),
    [actions.requestFairUsageReportFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),

    [actions.requestCustomReport]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.requestCustomReportSuccess]: (state, payload) => ({
      ...state,
      customReport: payload,
      isFetching: false,
    }),
    [actions.requestCustomReportFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),

    [actions.requestDownloadExcelReport]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.receiveDownloadExcelReportSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
    }),
    [actions.receiveDownloadExcelReportFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),
    [actions.requestDownloadStatisticsExcelReport]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.receiveDownloadStatisticsExcelReportSuccess]: (
      state,
      payload,
    ) => ({
      ...state,
      isFetching: false,
    }),
    [actions.receiveDownloadStatisticsExcelReportFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),
  },

  {
    isFetching: false,
    isSaving: false,
    didFetched: false,
    isDownloading: [],
    isDownloadingRecipientsList: false,
    isDeleting: false,
    records: [],
  },
);
