import { Layout as LayoutModule, Modal as ModalModule, Utils } from 'billon-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Analytics from '../../containers/Analytics';
import Statistics from '../../containers/Statistics';

const { Content: ContentModule, PageHeader } = LayoutModule;
const { Content } = ContentModule;
const { Text } = Utils;

const { ContentContainer } = ContentModule;
const { AbstractCrudComponent } = ModalModule;

export const StyledDropDownDiv = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: flex-end;
  margin-bottom: 40px;
  margin-right: 40px;
  white-space: nowrap;
`;

const StyledFiltersWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const StyledFilterButton = styled.button`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-shrink: 0;
  flex-basis: 220px;
  min-height: 100px;
  background: #ffffff;
  border: 2px solid #c9d4de;
  border-radius: 15px;
  cursor: ${(props) => (props.clickable ? 'pointer' : '')};
  padding: 16px;
  margin-right: 20px;
  font-size: 12px;
  text-align: left;
  background-color: ${({ isSelected, hoverColor }) => isSelected && hoverColor};
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    min-height: 50px;
    flex-basis: 100px;
    margin-bottom: 20px;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    border: 2px solid ${(props) => (props.color ? props.color : '#c9d4de')};
    background-color: ${({ hoverColor }) => hoverColor};
  }
  &.selected {
    border: 2px solid ${(props) => (props.color ? props.color : '#c9d4de')};
    background-color: ${({ hoverColor }) => hoverColor};
  }

  p {
    margin: 0;
    font-size: 12px;
    white-space: break-spaces;

    &.title {
      font-weight: 600;
    }

    &.numberOfDocuments {
      font-size: 14px;
    }

    &.see {
      align-self: flex-end;
    }
  }
`;

class HomePage extends AbstractCrudComponent {
  state = {
    selectedReportType: 'statistics',
  };

  handleChangeReportType = (type) => {
    this.props.history.push('/reports');
    this.setState({ selectedReportType: type });
  };

  render() {
    return (
      <ContentContainer fluid>
        <Content fluid>
          <PageHeader classic>
            <FormattedMessage
              id="Select report"
              defaultMessage="Select report"
            />
          </PageHeader>
          <StyledFiltersWrapper>
            <StyledFilterButton
              className={
                this.state.selectedReportType === 'analytics' ? 'selected' : ''
              }
              onClick={() => {
                this.handleChangeReportType('analytics');
              }}
              color={'#acbbc7'}
              hoverColor={'#F9FBFC'}
            >
              <Text as="p" className="title">
                <FormattedMessage id="Analytics" defaultMessage="Analytics" />
              </Text>
              {this.state.selectedReportType !== 'analytics' ? (
                <Text as="p" className="see">
                  <FormattedMessage id="See" defaultMessage="See" />
                </Text>
              ) : (
                <></>
              )}
            </StyledFilterButton>
            <StyledFilterButton
              className={
                this.state.selectedReportType === 'statistics' ? 'selected' : ''
              }
              onClick={() => {
                this.handleChangeReportType('statistics');
              }}
              color={'#acbbc7'}
              hoverColor={'#F9FBFC'}
            >
              <Text as="p" className="title">
                <FormattedMessage id="Statistics" defaultMessage="Statistics" />
              </Text>
              {this.state.selectedReportType !== 'statistics' ? (
                <Text as="p" className="see">
                  <FormattedMessage id="See" defaultMessage="See" />
                </Text>
              ) : (
                <></>
              )}
            </StyledFilterButton>
          </StyledFiltersWrapper>
          {this.state.selectedReportType === 'statistics' ? (
            <Statistics />
          ) : (
            <Analytics />
          )}
        </Content>
      </ContentContainer>
    );
  }
}

const mapStateToProps = ({ reports, form }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePage),
);
