import React from 'react';
import { useQueryClient } from 'react-query';
import { FormattedMessage } from 'react-intl';

import { Layout, Modal as ModalModule } from 'billon-ui';

// Components
import {
  CreateUniversityForm as CreateForm,
  CommonDocumentTable,
} from '../../containers';
import { CreateForm as RecipientCreateFrom } from 'js/modules/Recipients/containers';
import { CreateForm as CategoryCreateFrom } from 'js/modules/CategoriesNew/components/CreateForm';
import ContentContainerGrey from 'js/ui/ContentContainerGrey';
import { ExportableErrorContent } from 'js/components/ExportableErrorContent';
import { ErrorModal } from 'js/components/ErrorModal';

// Hooks
import { useCreateIdentity } from '../../../Recipients/hooks/useCreateIdentity';
import { useDeleteDocument } from './hooks';

import { QueryKeys } from '../../../../queryKeys';

import { mapDOCUMENT_ACTIONS_ERROR } from 'js/constraints';

const { Content: ContentModule } = Layout;
const { SuccessModal, CreateModal, ConfirmModal } = ModalModule;
const { Content } = ContentModule;

const DocumentsPage = () => {
  const queryClient = useQueryClient();
  const [error, setError] = React.useState(null);

  const [initialValues, setInitialValues] = React.useState({});
  const [isPreparedToSign, setPreparedToSign] = React.useState(false);

  const [isPublishDocumentModalOpen, setPublishDocumentModalOpen] =
    React.useState(false);
  const [
    isSuccessPublishDocumentModalOpen,
    setSuccessPublishDocumentModalOpen,
  ] = React.useState(false);

  const [isCreateRecipientModalOpen, setCreateRecipientModalOpen] =
    React.useState(false);
  const [
    isSuccessCreateRecipientModalOpen,
    setSuccessCreateRecipientModalOpen,
  ] = React.useState(false);

  const [isCreateCategoryModalOpen, setCreateCategoryModalOpen] =
    React.useState(false);
  const [isSuccessCreateCategoryModalOpen, setSuccessCreateCategoryModalOpen] =
    React.useState(false);

  const [isDeleteDocumentModalOpen, setDeleteDocumentModalOpen] =
    React.useState(false);
  const [isSuccessDeleteDocumentModalOpen, setSuccessDeleteDocumentModalOpen] =
    React.useState(false);

  const { mutate: createIdentity, isLoading: isCreatingIdentity } =
    useCreateIdentity({
      onSuccess: () => {
        onCreateRecipientFinished();
        queryClient.invalidateQueries([QueryKeys.IDENTITY_LIST]);
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const { mutate: deleteDocument, isLoading: isDeletingDocument } =
    useDeleteDocument({
      onSuccess: () => {
        onDeleteDocumentFinished();
        // queryClient.invalidateQueries([QueryKeys.DOCUMENT_LIST]); // should be used when document table will be refactored
      },
      onError: (error) => setError(error.response.data),
    });

  const onCreateDocument = (initialValues, callback, isPreparedToSign) => {
    setInitialValues(initialValues);
    setPublishDocumentModalOpen(true);
    setPreparedToSign(isPreparedToSign);
  };

  const onCloseCreateDocument = () => {
    setPublishDocumentModalOpen(false);
  };

  const toggleSuccessPublishDocument = () => {
    setSuccessPublishDocumentModalOpen(!isSuccessPublishDocumentModalOpen);
  };

  const toggleCreateCategoryModal = () => {
    setCreateCategoryModalOpen(!isCreateCategoryModalOpen);
  };

  const onCreateCategoryFinished = () => {
    toggleCreateCategoryModal();
    toggleSuccessModalCategoryClose();
  };

  const toggleSuccessModalCategoryClose = () => {
    setSuccessCreateCategoryModalOpen(!isSuccessCreateCategoryModalOpen);
  };

  const toggleCreateRecipientModal = () => {
    setCreateRecipientModalOpen(!isCreateRecipientModalOpen);
  };

  const onCreateRecipientFinished = () => {
    toggleCreateRecipientModal();
    toggleSuccessModalRecipientClose();
  };

  const toggleSuccessModalRecipientClose = () => {
    setSuccessCreateRecipientModalOpen(!isSuccessCreateRecipientModalOpen);
  };

  const onDeleteDocument = (initialValues) => {
    toggleConfirmDeleteDocumentsModal();
    setInitialValues(initialValues);
  };

  const toggleConfirmDeleteDocumentsModal = () => {
    setDeleteDocumentModalOpen(!isDeleteDocumentModalOpen);
  };

  const onDeleteDocumentFinished = () => {
    toggleConfirmDeleteDocumentsModal();
    toggleSuccessModalDeleteDocumentClose();
  };

  const toggleSuccessModalDeleteDocumentClose = () => {
    setSuccessDeleteDocumentModalOpen(!isSuccessDeleteDocumentModalOpen);
  };

  const onCloseDeleteDocumentModal = () => {
    toggleConfirmDeleteDocumentsModal();
    setError(null);
  };

  return (
    <ContentContainerGrey fluid>
      <Content fluid>
        <CommonDocumentTable
          handleCreate={onCreateDocument}
          handleDelete={onDeleteDocument}
        />
        <CreateModal
          isOpen={isPublishDocumentModalOpen}
          onCancel={onCloseCreateDocument}
          onSuccess={() => {
            onCloseCreateDocument();
            toggleSuccessPublishDocument();
          }}
          initialValues={initialValues}
          className="modal-xl"
          editMessage={
            <FormattedMessage
              id="Publish diploma"
              defaultMessage="Publish diploma"
            />
          }
        >
          <CreateForm
            onCreateIdentity={toggleCreateRecipientModal}
            onCreateCategory={toggleCreateCategoryModal}
            isPreparedToSign={isPreparedToSign}
            onCancel={onCloseCreateDocument}
          />
        </CreateModal>

        <SuccessModal
          isOpen={isSuccessPublishDocumentModalOpen}
          onClose={() => {
            toggleSuccessPublishDocument();
            window.location.reload(); // should be removed when document table will be refactored
          }}
        >
          <FormattedMessage
            id="Document publication has been initiated."
            defaultMessage="Document publication has been initiated."
          />
        </SuccessModal>

        <CreateModal
          isOpen={isCreateRecipientModalOpen}
          onSuccess={() => {}}
          onCancel={toggleCreateRecipientModal}
          initialValues={{}}
          createMessage={
            <FormattedMessage
              id="Adding student"
              defaultMessage="Adding student"
            />
          }
        >
          <RecipientCreateFrom
            onSubmit={(data) => createIdentity({ data })}
            isSaving={isCreatingIdentity}
          />
        </CreateModal>
        <SuccessModal
          isOpen={isSuccessCreateRecipientModalOpen}
          onClose={toggleSuccessModalRecipientClose}
        >
          <FormattedMessage
            id="Successfully saved"
            defaultMessage="Successfully saved"
          />
        </SuccessModal>

        <CreateModal
          isOpen={isCreateCategoryModalOpen}
          onSuccess={onCreateCategoryFinished}
          onCancel={toggleCreateCategoryModal}
          initialValues={{}}
          createMessage={
            <FormattedMessage
              id="Adding category"
              defaultMessage="Adding category"
            />
          }
        >
          <CategoryCreateFrom onSuccess={onCreateCategoryFinished} />
        </CreateModal>
        <SuccessModal
          isOpen={isSuccessCreateCategoryModalOpen}
          onClose={toggleSuccessModalCategoryClose}
        >
          <FormattedMessage
            id="Successfully saved"
            defaultMessage="Successfully saved"
          />
        </SuccessModal>

        <ConfirmModal
          isLoading={isDeletingDocument}
          isOpen={isDeleteDocumentModalOpen}
          title={
            <FormattedMessage
              id="Document deletion confirmation"
              defaultMessage="Document deletion confirmation"
            />
          }
          onAccept={() => deleteDocument({ id: initialValues.jobId })}
          onCancel={toggleConfirmDeleteDocumentsModal}
        >
          <FormattedMessage
            id="Are You sure You want to delete this document?"
            defaultMessage="Are You sure You want to delete this document?"
          />
        </ConfirmModal>
        <SuccessModal
          isOpen={isSuccessDeleteDocumentModalOpen}
          title={
            <FormattedMessage
              id="Document deleted"
              defaultMessage="Document deleted"
            />
          }
          onClose={() => {
            toggleSuccessModalDeleteDocumentClose();
            window.location.reload(); // should be removed when document table will be refactored
          }}
        >
          <FormattedMessage
            id="Document has been deleted successfully."
            defaultMessage="Document has been deleted successfully."
          />
        </SuccessModal>
        {!!error && (
          <ErrorModal
            isOpen
            toggle={onCloseDeleteDocumentModal}
            onClose={onCloseDeleteDocumentModal}
            closeIconEnabled={false}
          >
            <ExportableErrorContent
              headerTitleId={mapDOCUMENT_ACTIONS_ERROR.DELETE}
              statusCode={error?.statusCode}
              jobId={initialValues?.jobId}
              title={initialValues?.documentTitle}
            />
          </ErrorModal>
        )}
      </Content>
    </ContentContainerGrey>
  );
};

export default DocumentsPage;
