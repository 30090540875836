import { Grid } from 'billon-ui';
import styled from 'styled-components';

const { Col } = Grid;

export const DetailsCol = styled(Col)`
  max-height: 300px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 15px;
  margin-bottom: 40px;
`;
