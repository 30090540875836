import styled from 'styled-components';
import { Form as FormModule } from 'billon-ui';

export const SwitchField = styled(FormModule.SwitchField)`
  ${(props) =>
    props.disabled &&
    `
 .slider {
    border-color: #c9d4de !important;

    &:before {
      background-color: #c9d4de !important;
    }
  }`}
`;
