import moment from 'moment';
import { dateToRequestDataFormatter } from 'js/formatters';

export const parseDateRange = (dates) => {
  if (!dates?.[0] && !dates?.[1]) {
    return;
  }

  const from = dates?.[0] && dateToRequestDataFormatter(dates[0]);

  let to = dates?.[1] && dateToRequestDataFormatter(dates[1]);

  if (from && to && from === to) {
    to = moment(dates[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
  }

  return {
    from,
    to,
  };
};
