import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Utils } from 'billon-ui';
import { ProtectedComponent } from 'js/modules/Auth/components';
import { UserRoles } from 'js/constraints';
import * as Styled from './styled';

import {
  DOCUMENT_STATUS,
  DOCUMENT_PUBLICATION_STATUS,
  PUBLICATION_TYPE,
} from 'js/constraints';

// Components
import { DeleteDocumentButton } from '../DeleteDocumentButton';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

export const ActionButtons = ({ record, ...props }) => {
  const { push } = useHistory();

  const { isOneStepPublication } = useSelector(({ config }) => ({
    isOneStepPublication: config.publicationType === PUBLICATION_TYPE.ONE_STEP,
  }));

  const onDeleteDocument = () => props.onDeleteDocument(record);

  const onPublishDocument = () => props.onPublishDocument(record, true);

  const onMoveToDocumentDetails = () => push(`/document/${record.jobId}`);

  const onMoveToDeprecatedDocumentDetails = () =>
    push(`/document/${record.documentBlockchainAddress}`);

  return (
    <Styled.Row>
      {record.status === DOCUMENT_STATUS.PREPARED_TO_SIGN && (
        <>
          <DeleteDocumentButton onDeleteDocument={onDeleteDocument} />
          {!isOneStepPublication && (
            <ProtectedComponent
              roles={[UserRoles.AGENT, UserRoles.ADMIN]}
              render={false}
            >
              <Styled.Col md={6}>
                <Button outline onClick={onPublishDocument} block>
                  <FormattedMessage
                    id="Publish document"
                    defaultMessage="Publish document"
                  />
                </Button>
              </Styled.Col>
            </ProtectedComponent>
          )}
        </>
      )}
      {record.status === DOCUMENT_STATUS.CURRENT && (
        <Styled.Col md={6}>
          <Button outline onClick={onMoveToDocumentDetails} block>
            <FormattedMessage
              id="Document details"
              defaultMessage="Document details"
            />
          </Button>
        </Styled.Col>
      )}
      {record.status === DOCUMENT_STATUS.DEPRECATED && (
        <Styled.Col md={6}>
          <Button outline onClick={onMoveToDeprecatedDocumentDetails} block>
            <FormattedMessage
              id="Document details"
              defaultMessage="Document details"
            />
          </Button>
        </Styled.Col>
      )}
      {record.documentPublicationStatus ===
        DOCUMENT_PUBLICATION_STATUS.PUBLISHING_ERROR && (
        <DeleteDocumentButton onDeleteDocument={onDeleteDocument} />
      )}
    </Styled.Row>
  );
};
