import {
  Grid,
  Layout as LayoutModule,
  Utils,
  Modal as ModalModule,
} from 'billon-ui';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
// Utils
import {
  CUSTOMER,
  DOCUMENT_TYPE,
  SETTINGS_CONSTANTS,
} from '../../../../constraints';
// Actions
import {
  requestDownload as fileDownloadAction,
  requestSingle,
  requestSingleNotificationHistory,
} from '../../actions';
import { requestSettings } from 'js/modules/Settings/actions';
// Components
import { ActiveDeliveryStepsDiagram, DocumentHistory } from '../../components';
import { DocumentDetails } from '../../containers';

import NotificationHistoryModal from '../../components/NotificationHistoryModal';
import NotificationHistory from '../../containers/NotificationHistory';

const { Content: ContentModule } = LayoutModule;
const { Content, ContentContainer } = ContentModule;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Col } = Grid;

const {
  SuccessModal,
  AbstractCrudComponent,
  CreateModal,
  ConfirmModal,
  ErrorModal,
} = ModalModule;

const HistoryWrapper = styled(Col)`
  margin-top: ${({ activeDelivery }) => (activeDelivery ? '-3rem' : 0)};

  @media (max-width: 1199px) {
    margin-top: 0;
  }
`;

class DocumentsDetailsPage extends AbstractCrudComponent {
  componentDidMount() {
    const {
      match,
      requestSingleDocument,
      getSettings,
      getNotificationHistory,
    } = this.props;
    const { id } = match.params;
    getSettings();
    requestSingleDocument(id);
  }

  componentDidUpdate(prevProps) {
    const { match, requestSingleDocument, getNotificationHistory } = this.props;
    const { id } = match.params;
    if (prevProps.match.params.id !== id) {
      requestSingleDocument(id);
    }
  }

  render() {
    const {
      document,
      isFetching,
      didFetched,
      customer,
      history,
      settings,
      isFetchingSettings,
      isDownloading,
      fileDownload,
      activeDelivery,
    } = this.props;

    if (isFetching || isFetchingSettings || !didFetched) {
      return (
        <Content fluid>
          <PageLoader />
        </Content>
      );
    }

    let documentPreviewUrl = settings[SETTINGS_CONSTANTS.DOCUMENT_PREVIEW_URL];
    let backBtnRedirect = '/select-document-type';

    if (customer === CUSTOMER.DIPLOMA) {
      documentPreviewUrl = `${documentPreviewUrl}/diploma`;
      backBtnRedirect = '/documents';
    } else if (
      document.isPrivate ||
      document.documentTypeList === DOCUMENT_TYPE.PRIVATE
    ) {
      documentPreviewUrl = `${documentPreviewUrl}/p`;
      backBtnRedirect = '/documents?filters.documentType=PRIVATE';
    } else if (
      !document.isPrivate ||
      document.documentTypeList === DOCUMENT_TYPE.PUBLIC
    ) {
      backBtnRedirect = '/documents?filters.documentType=PUBLIC';
    }

    let publisherName = settings?.find(
      (item) => item.key === SETTINGS_CONSTANTS.PUBLISHER_NAME,
    );

    return (
      <>
        <Content fluid>
          <DocumentDetails
            document={document}
            customer={customer}
            onBackClick={() => history.push(backBtnRedirect)}
            backButtonLabel={
              <FormattedMessage id="Documents" defaultMessage="Documents" />
            }
            publisherName={publisherName?.value}
            documentPreviewUrl={documentPreviewUrl}
          />

          {activeDelivery &&
            (document.isPrivate || document.type === DOCUMENT_TYPE.PRIVATE) && (
              <ActiveDeliveryStepsDiagram document={document} />
            )}
        </Content>
        <ContentContainer fluid>
          <HistoryWrapper padding="0" activeDelivery={activeDelivery}>
            <DocumentHistory
              historyRecords={document.history}
              currentId={document.jobId}
              customer={customer}
              isDownloading={isDownloading}
              fileDownload={fileDownload}
            />
          </HistoryWrapper>
        </ContentContainer>
      </>
    );
  }
}

DocumentsDetailsPage.propTypes = {
  match: PropTypes.object.isRequired,
  requestSingleDocument: PropTypes.func.isRequired,
  getNotificationHistory: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  didFetched: PropTypes.bool.isRequired,
  document: PropTypes.object,
  customer: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  settings: PropTypes.object,
  isFetchingSettings: PropTypes.bool.isRequired,
  isDownloading: PropTypes.array.isRequired,
  fileDownload: PropTypes.func.isRequired,
  activeDelivery: PropTypes.bool,
};

const mapStateToProps = ({ document, config, settings }) => ({
  document: document.singleRecord,
  isFetching: document.isFetching,
  didFetched: document.didFetched,
  customer: config.customer,
  settings: settings.settings,
  isFetchingSettings: settings.isFetching,
  isDownloading: document.isDownloading,
  activeDelivery: config.activeDelivery,
});

const mapDispatchToProps = (dispatch) => ({
  requestSingleDocument: (id) => dispatch(requestSingle(id)),
  getSettings: () => dispatch(requestSettings()),
  fileDownload: (id, title, returnOnlyBlob) =>
    dispatch(fileDownloadAction(id, returnOnlyBlob, title)),
  getNotificationHistory: (id) =>
    dispatch(requestSingleNotificationHistory(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DocumentsDetailsPage),
);
