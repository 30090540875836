import React from 'react';
import * as Styled from './styled';
import { NOTIFICATION_HISTORY_STATUSES } from 'js/constraints';
import { ReactComponent as RawNotifyIcon } from 'js/ui/Icons/message.svg';
import { ReactComponent as RawChatIcon } from 'js/ui/Icons/chat.svg';

export const contentFormatter = (value, record) => {
  return (
    <Styled.ActionButton>
      {record.status === NOTIFICATION_HISTORY_STATUSES.DELIVERED ? (
        <RawNotifyIcon />
      ) : record.status === NOTIFICATION_HISTORY_STATUSES.IN_PROGRESS ? (
        <RawChatIcon />
      ) : (
        record.status === NOTIFICATION_HISTORY_STATUSES.ERROR && <RawChatIcon />
      )}
    </Styled.ActionButton>
  );
};
