import { removeUndefinedValues } from 'js/helpers/removeUndefinedValues';

export const parseFilters = (filters) => {
  const output = removeUndefinedValues(filters);

  if (filters && filters.pagination && filters.pagination.page > 0) {
    output.pagination.page = filters.pagination.page - 1;
  }

  return output;
};

export const parseSave = (values) => values;
