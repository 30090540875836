import React from 'react';
import WithValueMessage from 'js/helpers/WithValueMessage';
import { MAP_VALIDATION_LOGIN_ERRORS } from 'js/constraints';

export const BlockedMessage = ({ rawDate }) => {
  const diff = Math.abs(new Date() - new Date(rawDate));
  const delayInMinutes = Math.floor(diff / 1000 / 60);
  return (
    <WithValueMessage
      messageId={MAP_VALIDATION_LOGIN_ERRORS.ACCOUNT_TEMPORARY_BLOCKED}
      name="n"
      value={delayInMinutes}
    />
  );
};
