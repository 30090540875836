import styled from 'styled-components';

export const DropzonePlaceholder = styled.div`
  width: 100%;
  height: 15rem;
  border: 1px dashed ${({ theme }) => theme.palette.grayLighter};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 3.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizeSm};
  line-height: 2;
  text-align: center;
  justify-content: space-between;
  position: relative;

  &:focus {
    outline: 0;
  }
`;
