import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { merge } from 'lodash';

import { Form, Grid, Utils } from 'billon-ui';

// Hooks
import { useCategoriesOptions } from '../../../../hooks/useCategoriesOptions';
import { useIdentitiesOptions } from '../../../../hooks/useIdentitiesOptions';
import useFilters from '../../../../hooks/useFilters';
import useDocumentType from '../../hooks/useDocumentType';

// Components
import { IconInCircle } from '../../../../components/IconInCircle';
import { SelectField as AsyncSelectField } from '../../../../components';
import { CalendarField } from '../../../../containers';
import * as Styled from './styled';

import {
  DOCUMENT_STATUS,
  MAP_DOCUMENT_STATUSES,
  DOCUMENT_TYPE,
} from '../../../../constraints';
import { parseFilters } from '../../../../modules/DocumentsNew/helpers';

const { TextField, SelectField } = Form;
const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { Col } = Grid;

export const FilterForm = ({ initialValues, closeFilters, isDiploma }) => {
  const { formatMessage } = useIntl();
  const { navigateWithSearchQuery, onClear } = useFilters();
  const { categories, isCategoriesFetching, onChangeCategoryInput } =
    useCategoriesOptions();
  const { identities, isIdentitiesFetching, onChangeIdentityInput } =
    useIdentitiesOptions('documentNumber');

  const { documentType } = useDocumentType();

  const {
    categoryId,
    identity,
    documentStatusList,
    publicationDateRange,
    validSinceDateRange,
  } = initialValues.filters || {};

  const { documentNumber } = identity || {};

  const documentStatusOptions = [
    DOCUMENT_STATUS.CURRENT,
    DOCUMENT_STATUS.DEPRECATED,
    DOCUMENT_STATUS.NOT_EXIST,
    DOCUMENT_STATUS.PREPARED_TO_SIGN,
    DOCUMENT_STATUS.UPLOADING,
  ].map((status) => ({
    label: formatMessage({
      id: MAP_DOCUMENT_STATUSES[status],
    }),
    value: status,
  }));

  const formInitialValues = {
    blockchainAddress: '',
    publicationDateRange: '',
    documentStatusList: null,
    documentTypeList: documentType,
    publishedBy: '',
    title: '',
    validSinceDateRange: '',
    category: '',
    identity: null,
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues: merge({}, formInitialValues, {
      ...initialValues.filters,
      documentStatusList:
        documentStatusList &&
        documentStatusOptions.filter(
          (option) => option.value === documentStatusList,
        ),
      publicationDateRange: [
        publicationDateRange?.from,
        publicationDateRange?.to,
      ],
      validSinceDateRange: [validSinceDateRange?.from, validSinceDateRange?.to],
    }),
  });

  const onClearFilters = () => {
    onClear({
      filters: { documentTypeList: formInitialValues?.documentTypeList },
    });
    reset(formInitialValues);
  };

  const onSubmit = (values) => {
    navigateWithSearchQuery({
      filters: parseFilters(values),
      pagination: {
        page: 1,
      },
    });
  };

  React.useEffect(() => {
    if (!isIdentitiesFetching && documentNumber) {
      setValue(
        'identity',
        identities.find((s) => s.value === documentNumber),
      );
    }
  }, [isIdentitiesFetching, documentNumber, setValue, identities]);

  React.useEffect(() => {
    if (!isCategoriesFetching && categoryId) {
      setValue(
        'category',
        categories.find((c) => c.value === Number(categoryId)),
      );
    }
  }, [isCategoriesFetching, categoryId, setValue, categories]);

  return (
    <Styled.FilterCard>
      <Styled.AbsoluteDivForExitIcon onClick={closeFilters}>
        <IconInCircle
          iconName="rejected_no_fill"
          size="31px"
          color="#cad5dd"
          hoverColor="#ACBBC7"
        />
      </Styled.AbsoluteDivForExitIcon>
      <Styled.Header>
        <FormattedMessage id="Filter by" defaultMessage="Filter by" />
      </Styled.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Styled.FlexStartRow>
          <Col lg={6} xl={3}>
            <Styled.Label>
              {isDiploma ? (
                <FormattedMessage
                  id="Diploma number"
                  defaultMessage="Diploma number"
                />
              ) : (
                <FormattedMessage
                  id="Document title"
                  defaultMessage="Document title"
                />
              )}
            </Styled.Label>

            <Controller
              name="title"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                />
              )}
            />
          </Col>
          <Col lg={6} xl={3}>
            {' '}
            <Styled.Label>
              <FormattedMessage id="Category" defaultMessage="Category" />
            </Styled.Label>
            <Controller
              name="category"
              control={control}
              render={({ field, fieldState }) => (
                <AsyncSelectField
                  input={{
                    ...field,
                    onInputChange: (inputText) =>
                      onChangeCategoryInput(inputText),
                  }}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState?.error,
                  }}
                  options={categories}
                  isClearable
                  isLoading={isCategoriesFetching}
                />
              )}
            />
          </Col>
          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage
                id="Publication date from-to"
                defaultMessage="Publication date from-to"
              />
            </Styled.Label>
            <Controller
              name="publicationDateRange"
              control={control}
              render={({ field, fieldState }) => (
                <CalendarField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState?.message,
                  }}
                  enableTime={false}
                  dateFormat="Y-m-d"
                  showMonths={2}
                  range
                />
              )}
            />
          </Col>
          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage id="Transfer by" defaultMessage="Transfer by" />
              {' ('}
              <FormattedMessage id="User name" defaultMessage="User name" />
              {')'}
            </Styled.Label>
            <Controller
              name="publishedBy"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                />
              )}
            />
          </Col>

          <Col lg={6} xl={3}>
            <Styled.Label>
              {isDiploma ? (
                <FormattedMessage
                  id="Date of issue of the diploma from-to"
                  defaultMessage="Date of issue of the diploma from-to"
                />
              ) : (
                <FormattedMessage
                  id="Valid from-to"
                  defaultMessage="Valid from-to"
                />
              )}
            </Styled.Label>
            <Controller
              name="validSinceDateRange"
              control={control}
              render={({ field, fieldState }) => (
                <CalendarField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState?.message,
                  }}
                  enableTime={false}
                  dateFormat="Y-m-d"
                  showMonths={2}
                  range
                />
              )}
            />
          </Col>

          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage
                id="Document blockchain address"
                defaultMessage="Document blockchain address"
              />
            </Styled.Label>
            <Controller
              name="blockchainAddress"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                />
              )}
            />
          </Col>
          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage
                id="Publication status"
                defaultMessage="Publication status"
              />
            </Styled.Label>

            <Controller
              name="documentStatusList"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                  options={documentStatusOptions}
                  isClearable
                />
              )}
            />
          </Col>

          {documentType === DOCUMENT_TYPE.PRIVATE && (
            <Col lg={6} xl={3}>
              <Styled.Label>
                <FormattedMessage
                  id="Published for"
                  defaultMessage="Published for"
                />
              </Styled.Label>
              <Controller
                name="identity"
                control={control}
                render={({ field, fieldState }) => (
                  <AsyncSelectField
                    input={{
                      ...field,
                      onInputChange: (inputText) =>
                        onChangeIdentityInput(inputText),
                    }}
                    meta={{
                      touched: fieldState.invalid,
                      error: fieldState?.message,
                    }}
                    options={identities}
                    isClearable
                    isLoading={isIdentitiesFetching}
                    placeholder={formatMessage({
                      id: 'Index number',
                      defaultMessage: 'Index number',
                    })}
                  />
                )}
              />
            </Col>
          )}
        </Styled.FlexStartRow>

        <Styled.FlexEndRow>
          <Col lg={6} xl={3}>
            <Button onClick={onClearFilters} block outline type="button">
              <FormattedMessage id="Clear" defaultMessage="Clear" />
            </Button>
          </Col>

          <Col lg={6} xl={3}>
            {!isSubmitting ? (
              <Button type="submit" block>
                <FormattedMessage id="Filter" defaultMessage="Filter" />
              </Button>
            ) : (
              <ButtonLoader size="lg" block />
            )}
          </Col>
        </Styled.FlexEndRow>
      </form>
    </Styled.FilterCard>
  );
};
