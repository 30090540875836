import { createAction } from 'redux-act';
import { Utils } from 'billon-ui';

const { helpers } = Utils;
const { encodeQueryString } = helpers;

// Apply Filters
export const applyFilters = (history, filters) =>
  history.push(`/recipients/${encodeQueryString(filters)}`);

// Request category list
export const requestList = createAction(
  'Request recipients list',
  (filters = {}, ...rest) => ({ filters, ...rest }),
);
export const requestListSuccess = createAction(
  'Request recipients list success',
);
export const requestListFailure = createAction(
  'Request recipients list failure',
);

// Save category
export const requestSave = createAction(
  'Save recipient',
  (values, meta, ...rest) => ({
    values,
    meta,
    ...rest,
  }),
);
export const receiveSaveSuccess = createAction('Save recipient success');
export const receiveSaveFailure = createAction('Save recipient failure');

// Delete category
export const requestDelete = createAction(
  'Delete recipient',
  (id, meta, ...rest) => ({
    id,
    meta,
    ...rest,
  }),
);
export const receiveDeleteSuccess = createAction('Delete recipient success');
export const receiveDeleteFailure = createAction('Delete recipient failure');

// Load recipient from a file
export const requestUploadRecipientFile = createAction(
  'Upload recipient file',
  (values, meta, ...rest) => ({
    values,
    meta,
    ...rest,
  }),
);
export const receiveUploadRecipientFileSuccess = createAction(
  'Upload recipient file success',
);
export const receiveUploadRecipientFileFailure = createAction(
  'Upload recipient file failure',
);

// Download recipient list
export const requestDownloadRecipientList = createAction(
  'Download recipient list',
  (meta, ...rest) => ({
    meta,
    ...rest,
  }),
);
export const receiveDownloadRecipientListSuccess = createAction(
  'Download recipient list success',
);
export const receiveDownloadRecipientListFailure = createAction(
  'Download recipient list failure',
);
