import { createAction } from 'redux-act';
import { Utils } from 'billon-ui';

const { helpers } = Utils;
const { encodeQueryString } = helpers;

// Apply Filters
export const applyFilters = (history, filters) =>
  history.push(`/agreements/${encodeQueryString(filters)}`);

// Request documents list
export const requestList = createAction(
  'Request documents list',
  (filters, ...rest) => ({ filters, ...rest }),
);
export const receiveListSuccess = createAction(
  'Request documents list success',
);
export const receiveListFailure = createAction(
  'Request documents list failure',
);

// Request single documents
export const requestSingle = createAction(
  'Request single document',
  (id, ...rest) => ({ id, ...rest }),
);
export const receiveSingleSuccess = createAction(
  'Request single document success',
);
export const receiveSingleFailure = createAction(
  'Request single document failure',
);

// Publishing document
export const requestPrepare = createAction(
  'Save document',
  (values, meta, ...rest) => ({
    values,
    meta,
    ...rest,
  }),
);
export const receivePrepareSuccess = createAction('Save document success');
export const receivePrepareFailure = createAction('Save document failure');

export const requestPublish = createAction(
  'Save document',
  (values, meta, ...rest) => ({
    values,
    meta,
    ...rest,
  }),
);
export const receivePublishSuccess = createAction('Save document success');
export const receivePublishFailure = createAction('Save document failure');

// Download document recipients file
export const requestDownloadRecipientsFile = createAction(
  'Download recipients file document',
  (id, name, ...rest) => ({
    id,
    name,
    ...rest,
  }),
);
export const receiveDownloadRecipientsFileSuccess = createAction(
  'Download recipients file success',
);
export const receiveDownloadRecipientsFileFailure = createAction(
  'Download recipients file failure',
);

// Download document
export const requestDownload = createAction(
  'Download document',
  (blockchainAddress, jobId, returnOnlyBlob, ...rest) => ({
    blockchainAddress,
    jobId,
    returnOnlyBlob,
    ...rest,
  }),
);
export const receiveDownloadSuccess = createAction('Download document success');
export const receiveDownloadFailure = createAction('Download document failure');

// Delete document
export const requestDelete = createAction(
  'Delete document',
  (values, meta, ...rest) => ({
    values,
    meta,
    ...rest,
  }),
);
export const receiveDeleteSuccess = createAction('Delete document success');
export const receiveDeleteFailure = createAction('Delete document failure');

// Resend authorization codes
export const requestResendAuthorizationCodes = createAction(
  'Resend authorization codes',
  (blockchainAddress, ...rest) => ({
    blockchainAddress,
    ...rest,
  }),
);
export const receiveResendAuthorizationCodesSuccess = createAction(
  'Resend authorization codes success',
);
export const receiveResendAuthorizationCodesFailure = createAction(
  'Resend authorization codes failure',
);

// Forget document
export const requestForgetDocument = createAction(
  'Forget document',
  (blockchainAddress, ...rest) => ({
    blockchainAddress,
    ...rest,
  }),
);
export const receiveForgetDocumentSuccess = createAction(
  'Forget document success',
);
export const receiveForgetDocumentFailure = createAction(
  'Forget document failure',
);
