import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Utils } from 'billon-ui';

import * as Styled from './styled';

const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { Col } = Grid;

export const FormFooter = ({ onBack, isLoading }) => (
  <Styled.CenterRow>
    <Col md={12} xl={3}>
      <Styled.BackButton secondary onClick={onBack}>
        <FormattedMessage id="back" defaultMessage="back" />
      </Styled.BackButton>
    </Col>

    <Col xl={6}>
      {!isLoading ? (
        <Button type="submit" size="lg" block>
          <FormattedMessage
            id="Next"
            defaultMessage="Next"
          />
        </Button>
      ) : (
        <ButtonLoader size="lg" block />
      )}
    </Col>
  </Styled.CenterRow>
)
