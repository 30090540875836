import React from 'react';
import styled from 'styled-components';
import { Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';

import { momentFormatter } from '../../../../../formatters';

const { Text } = Utils;

const ActiveDeliveryStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  /* height: 116px; */
`;

const CircleWitNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#000'};
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: ${({ borderColor }) =>
    borderColor ? `2px solid ${borderColor}` : 'none'};
  margin-bottom: 1rem;
`;

export const ActiveDeliveryStepsDiagramItem = (props) => {
  return (
    <ActiveDeliveryStepWrapper>
      <CircleWitNumber
        backgroundColor={props.backgroundColor}
        borderColor={props.fontColor}
      >
        <Text
          fontColor={props.fontColor ? props.fontColor : '#fff'}
          fontWeight={'600'}
          margin={0}
        >
          {props.stepNumber}
        </Text>
      </CircleWitNumber>
      <Text
        fontSize={'12px'}
        fontWeight={'600'}
        margin={'0'}
        css="text-align: center; height: 24px;"
      >
        {props.children}
      </Text>
      {props.date ? (
        <Text fontSize={'12px'}>
          {' '}
          <FormattedMessage id="Date" defaultMessage="Date" />:{' '}
          {momentFormatter(props.date)}
        </Text>
      ) : (
        <Text fontSize={'12px'}>
          {' '}
          <FormattedMessage id="Waiting3" defaultMessage="Waiting" />
        </Text>
      )}
    </ActiveDeliveryStepWrapper>
  );
};
