import React, { useState } from 'react';
import {
  Dropdown as DropdownModule,
  Language,
  Layout,
  Nav as NavModule,
  Utils,
} from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, NavLink as RouterNavLink, useHistory } from 'react-router-dom';
// Components
import { ProtectedComponent } from 'js/modules/Auth/components';
import { Brand } from '../../components';
import { UserRoles } from 'js/userRoles';
import * as Styled from './styled';
// Utils
import {
  countryToLanguage,
  CUSTOMER,
  DOCUMENT_TYPE,
  languageToCountry,
  SETTINGS_CONSTANTS,
} from '../../constraints';
import { useAuthenticatedContext } from '../../modules/Auth/hooks/useAuthenticated';
import appRoutes from 'js/app-routes';
import useFeatureEnabled from '../../modules/Config/hooks/useFeatureEnabled';
import useFilters from '../../hooks/useFilters';

const { ChangeLanguage } = Language;
const { NavItem } = NavModule;
const { Header: HeaderModule } = Layout;
const { Navbar } = HeaderModule;
const { UncontrolledDropdown, DropdownItem } = DropdownModule;
const { Icon } = Utils;

const NewHeader = () => {
  const [isOpen, setIsOpen] = useState();
  const history = useHistory();
  const { handleLogout, isAuthenticated } = useAuthenticatedContext();
  const { filters } = useFilters();

  const { featureEnabled } = useFeatureEnabled();

  const { customer, settings, enablePrivate } = useSelector(
    ({ config, settings }) => ({
      settings: settings.settings,
      enablePrivate: config.enablePrivate,
      customer: config.customer,
      observer: config.observer,
      remoteSign: config.remoteSign,
    }),
  );

  const handleMe = () => {
    history.push('/me');
  };

  const handlePasswordChange = () => {
    history.push(appRoutes.PASSWORD_CHANGE_BLANK);
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const customLogo = settings[SETTINGS_CONSTANTS.CUSTOM_LOGO];
  const customDescription = settings[SETTINGS_CONSTANTS.DESCRIPTION];

  return (
    <Styled.Header>
      <Navbar expand="lg">
        <Styled.NavbarBrand
          tag={Link}
          to="/"
          customer={customer}
          onClick={handleClose}
        >
          <Brand logoUrl={customLogo} />
          {customDescription && (
            <Styled.Description customer={customer}>
              {customDescription}
            </Styled.Description>
          )}
        </Styled.NavbarBrand>
        <Styled.Collapse isOpen={isOpen} navbar>
          <Styled.Nav navbar>
            <ProtectedComponent
              roles={[
                UserRoles.VISITOR,
                UserRoles.AGENT,
                UserRoles.ADMIN,
                UserRoles.DPI,
              ]}
              render={false}
            >
              {featureEnabled(null, [CUSTOMER.DIPLOMA]) && (
                <NavItem onClick={handleClose}>
                  <Styled.NavLink tag={RouterNavLink} to="/documents">
                    <FormattedMessage id="Diploma" defaultMessage="Diploma" />
                  </Styled.NavLink>
                </NavItem>
              )}

              {featureEnabled('publicDocuments.enableHeaderLink', [
                CUSTOMER.DEFAULT,
                CUSTOMER.TAURON,
              ]) && (
                <NavItem onClick={handleClose}>
                  <Styled.NavLink
                    tag={RouterNavLink}
                    to={'/documents?filters.documentTypeList=PUBLIC'}
                    isActive={(match) => {
                      if (
                        !match ||
                        filters?.filters?.documentTypeList !==
                          DOCUMENT_TYPE.PUBLIC
                      ) {
                        return false;
                      }

                      return true;
                    }}
                  >
                    <FormattedMessage
                      id="Public documents"
                      defaultMessage="Public documents"
                    />
                  </Styled.NavLink>
                </NavItem>
              )}

              {featureEnabled('privateDocuments.enableHeaderLink', [
                CUSTOMER.DEFAULT,
              ]) && (
                <NavItem onClick={handleClose}>
                  <Styled.NavLink
                    tag={RouterNavLink}
                    to={'/documents?filters.documentTypeList=PRIVATE'}
                    isActive={(match) => {
                      if (
                        !match ||
                        filters?.filters?.documentTypeList !==
                          DOCUMENT_TYPE.PRIVATE
                      ) {
                        return false;
                      }

                      return true;
                    }}
                  >
                    <FormattedMessage
                      id="Private documents"
                      defaultMessage="Private documents"
                    />
                  </Styled.NavLink>
                </NavItem>
              )}
            </ProtectedComponent>

            <ProtectedComponent
              roles={[
                UserRoles.VISITOR,
                UserRoles.AGENT,
                UserRoles.ADMIN,
                UserRoles.DPI,
              ]}
              render={false}
            >
              <NavItem onClick={handleClose}>
                <Styled.NavLink tag={RouterNavLink} to="/categories">
                  <FormattedMessage
                    id="Categories"
                    defaultMessage="Categories"
                  />
                </Styled.NavLink>
              </NavItem>
            </ProtectedComponent>

            {featureEnabled(null, [CUSTOMER.TAURON]) && (
              <NavItem onClick={handleClose}>
                <Styled.NavLink
                  tag={RouterNavLink}
                  to={appRoutes.DAILY_REPORTS_BLANK}
                >
                  <FormattedMessage
                    id="Daily reports"
                    defaultMessage="Daily reports"
                  />
                </Styled.NavLink>
              </NavItem>
            )}

            {enablePrivate &&
              featureEnabled(null, [CUSTOMER.DIPLOMA, CUSTOMER.DEFAULT]) && (
                <NavItem onClick={handleClose}>
                  <Styled.NavLink tag={RouterNavLink} to="/recipients">
                    {
                      {
                        [CUSTOMER.DIPLOMA]: (
                          <FormattedMessage
                            id="Students"
                            defaultMessage="Students"
                          />
                        ),
                        [CUSTOMER.DEFAULT]: (
                          <FormattedMessage
                            id="Recipients"
                            defaultMessage="Recipients"
                          />
                        ),
                      }[customer]
                    }
                  </Styled.NavLink>
                </NavItem>
              )}
            {customer === CUSTOMER.DEFAULT && (
              <ProtectedComponent roles={[UserRoles.DPI]} render={false}>
                <NavItem onClick={handleClose}>
                  <Styled.NavLink tag={RouterNavLink} to="/rodo">
                    <FormattedMessage id="Rodo" defaultMessage="Rodo" />
                  </Styled.NavLink>
                </NavItem>
              </ProtectedComponent>
            )}
            <ProtectedComponent roles={[UserRoles.ADMIN]} render={false}>
              <NavItem onClick={handleClose}>
                <Styled.NavLink tag={RouterNavLink} to="/users">
                  <FormattedMessage
                    id="Administrators"
                    defaultMessage="Administrators"
                  />
                </Styled.NavLink>
              </NavItem>
            </ProtectedComponent>
            {featureEnabled(null, [CUSTOMER.DEFAULT]) && (
              <NavItem onClick={handleClose}>
                <Styled.NavLink tag={RouterNavLink} to="/reports">
                  <FormattedMessage id="Reports" defaultMessage="Reports" />
                </Styled.NavLink>
              </NavItem>
            )}
            {featureEnabled('observer', null) && (
              <NavItem onClick={handleClose}>
                <Styled.NavLink tag={RouterNavLink} to="/requests">
                  <FormattedMessage id="Requests" defaultMessage="Requests" />
                </Styled.NavLink>
              </NavItem>
            )}
          </Styled.Nav>

          {isAuthenticated ? (
            <UncontrolledDropdown>
              <Styled.DropdownToggle caret>
                <Icon name="user-circle" fontSize="32px" />
              </Styled.DropdownToggle>
              <Styled.DropdownMenu right>
                <DropdownItem onClick={handlePasswordChange}>
                  <FormattedMessage
                    id="Change password"
                    defaultMessage="Change password"
                  />
                </DropdownItem>
                <DropdownItem onClick={handleMe}>
                  <FormattedMessage
                    id="User details"
                    defaultMessage="User details"
                  />
                </DropdownItem>
                <DropdownItem onClick={handleLogout}>
                  <FormattedMessage id="Logout" defaultMessage="Logout" />
                </DropdownItem>
              </Styled.DropdownMenu>
            </UncontrolledDropdown>
          ) : null}
          <ChangeLanguage
            countryToLanguage={countryToLanguage}
            languageToCountry={languageToCountry}
          />

          {isOpen ? (
            <Styled.Icon
              name="times"
              fontSize="28px"
              color="#cad5dd"
              onClick={handleOpen}
            />
          ) : (
            <Styled.Icon
              name="bars"
              fontSize="28px"
              color="#cad5dd"
              onClick={handleOpen}
            />
          )}
        </Styled.Collapse>
      </Navbar>
    </Styled.Header>
  );
};

export default NewHeader;
