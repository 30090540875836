import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Utils } from 'billon-ui';

import CustomToolTip from 'js/ui/CustomToolTip';

const { Icon } = Utils;

const ExecuteRodoTooltip = () => (
  <CustomToolTip
    id="CustomToolTip-fair-usage-1"
    trigger={<Icon name="info-circle" color="grey" />}
  >
    <FormattedMessage
      id="Execute RODO definition"
      defaultMessage="Execute RODO definition"
    />
  </CustomToolTip>
);

export default ExecuteRodoTooltip;
