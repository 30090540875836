import { Utils, Table as TableModule } from 'billon-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import CommonTable, {
  CommonTableContext,
} from '../../../../components/CommonTable';
import { CUSTOMER, DOCUMENT_TYPE } from '../../../../constraints';
import * as formatters from '../../../../formatters';
import { requestList as requestCategoriesList } from '../../../Categories/actions';
import {
  requestDownload,
  requestList,
  requestListQuiet,
} from '../../../Documents/actions';
import { mapStatuses } from '../../../Documents/helpers';
import { requestList as requestRecipientsList } from '../../../Recipients/actions';

import { getSearchParams } from './helpers';
import { applyFilters as applyFiltersAction } from '../../actions';

import CustomConfirmModal from 'js/ui/CustomConfirmModal';
import CheckboxField from 'js/ui/CheckBoxField';
import FileActionButtonFormatter from 'js/ui/FileActionButtonFormatter';

import * as Styled from './styled';
import { useForm } from 'react-hook-form';
import { useForgetDocumentRequest } from 'js/hooks/useForgetDocumentRequest';

const { TableColumn } = TableModule;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

const CommonDocumentTable: React.FC = () => {
  const dispatch = useDispatch();
  const {
    customer,
    isDownloading,
    isFetching,
    isFetchingQuietly,
    limit,
    numberOfRecords,
    records,
  } = useSelector(({ document, config, category }: any) => ({
    categories: category.records.map((v) => ({ label: v.path, value: v.path })),
    customer: config.customer as CUSTOMER,
    enablePrivate: config.enablePrivate,
    isDownloading: document.isDownloading,
    isFetching: document.isFetching,
    isFetchingQuietly: document.isFetchingQuietly,
    limit: config.limit as number,
    numberOfRecords: document.numberOfRecords,
    records: document.records,
  }));
  const { filters: rawSearchParams } = useParams<{ filters: string }>();
  const searchParams = getSearchParams(rawSearchParams, limit);
  const history = useHistory();

  const [confirmRodoModalIsActive, setConfirmRodoModal] = useState(false);

  const { mutate: forget } = useForgetDocumentRequest();

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { rodoCheckbox: [] },
  });

  const onSubmit = async (data) => {
    await Promise.all(data.rodoCheckbox.map((id) => forget(id))).then(() => {
      setConfirmRodoModal(false);
      reset();
    });
  };

  const toogleConfirmRodoModal = () => {
    setConfirmRodoModal(!confirmRodoModalIsActive);
  };

  const applyFilters = (params: any, path?: string) =>
    applyFiltersAction(history, params, path);

  const load = useCallback(
    (searchParamsReload) =>
      dispatch(
        requestList({
          ...searchParamsReload,
          include: ['category', 'publishedBy', 'recipients'],
        }),
      ),
    [dispatch],
  );

  const loadQuiet = useCallback(
    (searchParamsReload) =>
      dispatch(
        requestListQuiet({
          ...searchParamsReload,
          include: ['category', 'publishedBy', 'recipients'],
        }),
      ),
    [dispatch],
  );

  const fileDownload = useCallback(
    (title, jobId?, returnOnlyBlob?: boolean) =>
      dispatch(requestDownload(jobId, returnOnlyBlob, title)),
    [dispatch],
  );

  const loadCategories = useCallback(
    () => dispatch(requestCategoriesList({ isActive: true })),
    [dispatch],
  );

  const loadRecipients = useCallback(
    () => dispatch(requestRecipientsList({ isActive: true })),
    [dispatch],
  );

  useEffect(() => {
    loadRecipients();
  }, [loadCategories, loadRecipients]);

  type Column = {
    name: string;
    message: string;
    formatter: Function;
    sortable?: boolean;
  };

  const columns: Array<Column | false> = [
    {
      name: 'publicationDate',
      message: 'Publication date',
      formatter: formatters.momentFormatter,
      sortable: true,
    },
    {
      name: 'title',
      message: customer === CUSTOMER.DIPLOMA ? 'Diploma number' : 'Title',
      formatter: formatters.boundLengthFormatterLong,
      sortable: true,
    },
    {
      name: 'category.path',
      message: 'Category',
      formatter: formatters.boundLengthFormatterLong,
    },
    customer === CUSTOMER.DIPLOMA && {
      name: 'recipients',
      message: 'Index number',
      formatter: formatters.indexNumberFormatter,
    },
    searchParams.type === DOCUMENT_TYPE.PRIVATE && {
      name: 'recipients',
      message: 'Published to',
      formatter: formatters.recipientsFormatter,
      sortable: true,
    },
    {
      name: 'status',
      message: 'Status',
      formatter: mapStatuses,
    },
  ];

  const SwitchFormatter = (value, row) => {
    return (
      <Styled.CheckboxContainer>
        <CheckboxField
          meta={{
            dirty: true,
            submitting: row.isInProgress,
          }}
        >
          <input
            {...register('rodoCheckbox')}
            type="checkbox"
            value={row.jobId}
            className="custom-control-input"
          />
        </CheckboxField>
      </Styled.CheckboxContainer>
    );
  };

  return (
    <Styled.UseForm onSubmit={handleSubmit(onSubmit)}>
      <CommonTableContext.Provider
        value={{
          load,
          refresh: loadQuiet,
          isFetching: false, // !
          isFetchingQuietly: false, // !
          numberOfRecords,
          records,
        }}
      >
        <Styled.ExecuteBtnContainer>
          {watch('rodoCheckbox').length > 0 && (
            <Button
              type="button"
              outline
              fontSize="10px"
              fontWeight={500}
              padding="5px 20px"
              onClick={toogleConfirmRodoModal}
            >
              <FormattedMessage id="Execute" defaultMessage="Execute" />
            </Button>
          )}
        </Styled.ExecuteBtnContainer>

        <CommonTable columns={columns} customPageHeader={true} limit={10}>
          <TableColumn
            className="text-center fixed-width-140"
            fieldName="Execute RODO"
            formatter={SwitchFormatter}
            renderCell={(row, props) => (
              <Styled.TableCell row={row} {...props} />
            )}
          >
            <FormattedMessage id="Execute RODO" defaultMessage="Execute RODO" />
          </TableColumn>

          <TableColumn
            className="text-center fixed-width"
            fieldName="Preview"
            formatter={FileActionButtonFormatter(
              isDownloading,
              fileDownload,
              true,
            )}
            renderCell={(row, props) => (
              <Styled.TableCell row={row} {...props} />
            )}
          >
            <FormattedMessage id="Preview" defaultMessage="Preview" />
          </TableColumn>
          <TableColumn
            className="text-center fixed-width"
            fieldName="documentBlockchainAddress"
            formatter={FileActionButtonFormatter(isDownloading, fileDownload)}
            renderCell={(row, props) => (
              <Styled.TableCell row={row} {...props} />
            )}
          >
            <FormattedMessage id="Download" defaultMessage="Download" />
          </TableColumn>
        </CommonTable>

        <CustomConfirmModal
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit(onSubmit)}
          isOpen={confirmRodoModalIsActive}
          toggle={toogleConfirmRodoModal}
          title={
            <FormattedMessage id="Execute RODO" defaultMessage="Execute RODO" />
          }
        >
          <FormattedMessage
            id="Execute RODO confirm description p1"
            defaultMessage="Execute RODO confirm description p1"
          />{' '}
          {watch('rodoCheckbox').length}{' '}
          <FormattedMessage
            id="Execute RODO confirm description p2"
            defaultMessage="Execute RODO confirm description p2"
          />
        </CustomConfirmModal>
      </CommonTableContext.Provider>
    </Styled.UseForm>
  );
};

export default CommonDocumentTable;
