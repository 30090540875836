import * as yup from 'yup';
import { useIntl } from 'react-intl';

import { VALIDATION_ERRORS } from '../../../../../../constraints';

export const useSchema = () => {
  const { formatMessage } = useIntl();

  return yup.object({
    file: yup
      .mixed()
      .test(
        'file',
        formatMessage({
          id: VALIDATION_ERRORS.REQUIRED,
          defaultMessage: VALIDATION_ERRORS.REQUIRED,
        }),
        (value) => !!value && value.length > 0,
      )
      .test(
        'fileSize',
        formatMessage({
          id: VALIDATION_ERRORS.FILE_SIZE_TOO_LARGE,
          defaultMessage: VALIDATION_ERRORS.FILE_SIZE_TOO_LARGE,
        }),
        (value) => {
          const FILE_SIZE = 2 * 1024 * 1024;
          return value?.[0] ? value[0].size <= FILE_SIZE : true;
        },
      ),
  });
};
