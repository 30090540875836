import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import useSettingsRequest from 'js/modules/Settings/hooks/useSettingsRequest';
import { useSingleUser } from '../../hooks/useSingleUser';
import { Layout as LayoutModule, Utils } from 'billon-ui';
import * as Styled from './styled';
import appRoutes from 'js/app-routes';
import { PASSWORD_FORM_MODES } from 'js/constraints';
import AdministrativePasswordChange from '../../components/AdministrativePasswordChange';

const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const AdministrativePasswordChangePage = () => {
  const { params } = useRouteMatch(appRoutes.ADMIN_PASSWORD_CHANGE);
  const { id } = params;

  const { data: settings, isLoading } = useSettingsRequest();
  const { data: user, isLoading: isUserLoading } = useSingleUser({ id });

  if (isLoading || isUserLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <Styled.ContentContainerGrey fluid>
      <Styled.ContentRow>
        <Styled.ContentCol
          sm={{ size: 12, offset: 0 }}
          md={{ size: 9, offset: 0 }}
          lg={{ size: 5, offset: 0 }}
        >
          <AdministrativePasswordChange
            id={id}
            mode={PASSWORD_FORM_MODES.ADMINISTRATIVE_PASSWORD_CHANGE}
            role={user.role}
            settings={settings}
          />
        </Styled.ContentCol>
      </Styled.ContentRow>
    </Styled.ContentContainerGrey>
  );
};

export default AdministrativePasswordChangePage;
