/* global sessionStorage */
import { ajax } from 'rxjs/observable/dom/ajax';
import { Observable } from 'rxjs/Rx';
import { Utils } from 'billon-ui';
import download from 'downloadjs';
import {
  requestList,
  requestListQuiet,
  receiveListSuccess,
  receiveListFailure,
  requestSingle,
  receiveSingleSuccess,
  receiveSingleFailure,
  requestPrepare,
  receivePrepareSuccess,
  receivePrepareFailure,
  requestPublish,
  receivePublishSuccess,
  receivePublishFailure,
  requestDownload,
  receiveDownloadSuccess,
  receiveDownloadFailure,
  requestDelete,
  receiveDeleteSuccess,
  receiveDeleteFailure,
  requestDownloadRecipientsFile,
  receiveDownloadRecipientsFileSuccess,
  receiveDownloadRecipientsFileFailure,
  requestResendAuthorizationCodes,
  receiveResendAuthorizationCodesSuccess,
  receiveResendAuthorizationCodesFailure,
  requestForgetDocument,
  receiveForgetDocumentSuccess,
  receiveForgetDocumentFailure,
  requestSingleNotificationHistory,
  requestSingleNotificationHistoryQuiet,
  receiveSingleNotificationHistorySuccess,
  receiveSingleNotificationHistoryFailure,
  requestUpdate,
  receiveUpdateSuccess,
  receiveUpdateFailure,
} from './actions';
import routes from '../../api-routes';
import qs from 'qs';
import {
  parseFilters2 as parseFilters,
  parsePublish,
  parseSave,
  parseUpdate,
} from './helpers';
import { initializeWebViewerFromBlob } from '../../webviewer';

import { downloadHandler } from './helpers';

const { helpers } = Utils;
const { request, encodeQueryString } = helpers;
const {
  METHOD_GET,
  HEADER_CONTENT_TYPE,
  CONTENT_TYPE_APPLICATION_JSON,
  X_ACCESS_TOKEN,
  METHOD_POST,
} = request;

const getDocumentsListEpic = (actions$) =>
  actions$
    .ofType(requestList.getType(), requestListQuiet.getType())
    .mergeMap((action) =>
      ajax({
        url: `${routes.DOCUMENT}?${qs.stringify(
          parseFilters(action.payload.filters),
          { allowDots: true },
        )}`,
        headers: {
          [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
          Authorization: sessionStorage.getItem('token'),
        },
        method: METHOD_GET,
      })
        .map(({ response }) => receiveListSuccess(response))
        .takeUntil(
          actions$.ofType(requestList.getType(), requestListQuiet.getType()),
        )
        .catch((err) => Observable.of(receiveListFailure(err))),
    );

const getDocumentsSingleEpic = (actions$) =>
  actions$.ofType(requestSingle.getType()).mergeMap((action) =>
    ajax({
      url: routes.SINGLE_DOCUMENT.replace('{id}', action.payload.id),
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => receiveSingleSuccess(response))
      .takeUntil(actions$.ofType(requestSingle.getType()))
      .catch((err) => Observable.of(receiveSingleFailure(err))),
  );

const prepareDocumentEpic = (actions$) =>
  actions$.ofType(requestPrepare.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { values } = action.payload;

    return ajax({
      url: routes.PREPARE_DOCUMENT,
      body: parseSave(values),
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_POST,
    })
      .map(({ response }) => {
        const blob = new Uint8Array(response.file.fileBuffer.data);
        if (!response.isAgreement && !values.prepareWithoutAutoDownload) {
          download(blob, `${values.title}.pdf`);
        }

        if (resolve) {
          resolve(response);
        }
        return receivePrepareSuccess(response.jobId);
      })
      .takeUntil(actions$.ofType(requestPrepare.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }
        return Observable.of(receivePrepareFailure(err));
      });
  });

const publishDocumentEpic = (actions$) =>
  actions$.ofType(requestPublish.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { values } = action.payload;

    return ajax({
      url: routes.PUBLISH_DOCUMENT,
      body: parsePublish(values),
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },

      method: METHOD_POST,
    })
      .map(({ response }) => {
        if (
          response &&
          response.res &&
          response.res.type === 'DURABLE_MEDIA_ERROR'
        ) {
          reject(response);
        } else {
          resolve(response);
        }
        return receivePublishSuccess(response);
      })
      .takeUntil(actions$.ofType(requestPublish.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }
        return Observable.of(receivePublishFailure(err));
      });
  });

const updateDocumentEpic = (actions$) =>
  actions$.ofType(requestUpdate.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { values } = action.payload;

    return ajax({
      url: routes.DOCUMENT_PUBLIC,
      body: parseUpdate(values),
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_POST,
      contentType: 'application/json',
    })
      .map(({ response }) => {
        if (resolve) resolve(response);
        return receiveUpdateSuccess(response);
      })
      .takeUntil(actions$.ofType(requestUpdate.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }
        return Observable.of(receiveUpdateFailure(err));
      });
  });

const downloadDocumentEpic = (actions$) =>
  actions$.ofType(requestDownload.getType()).mergeMap((action) =>
    ajax({
      url: routes.SINGLE_DOCUMENT_DOWNLOAD.replace(
        '{id}',
        action.payload.jobId,
      ),

      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
      responseType: 'blob',
    })
      .map((resp) => {
        const { response, xhr } = resp;
        const filename = xhr
          .getResponseHeader('content-disposition')
          .split('filename=')[1]
          .split(';')[0];
        if (!action.payload.returnOnlyBlob) {
          downloadHandler(response, filename ? filename : action.payload.title);
        } else {
          initializeWebViewerFromBlob(response);
        }

        return receiveDownloadSuccess(action.payload.jobId);
      })
      .takeUntil(actions$.ofType(requestDownload.getType()))
      .catch((err) => Observable.of(receiveDownloadFailure(err))),
  );

const deleteDocumentEpic = (actions$) =>
  actions$.ofType(requestDelete.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { jobId } = action.payload.values;

    return ajax({
      url: routes.SINGLE_DOCUMENT_DISCARD.replace('{id}', jobId),
      body: JSON.stringify(action.payload.values),
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_POST,
    })
      .map(({ response }) => {
        if (resolve) {
          resolve(response);
        }
        return receiveDeleteSuccess(response);
      })
      .takeUntil(actions$.ofType(requestDelete.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }
        return Observable.of(receiveDeleteFailure(err));
      });
  });

const downloadRecipientsListEpic = (actions$) =>
  actions$.ofType(requestDownloadRecipientsFile.getType()).mergeMap((action) =>
    ajax({
      url: routes.SINGLE_DOCUMENT_DOWNLOAD_RECIPIENTS_LIST.replace(
        '{id}',
        action.payload.id,
      ),
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
      responseType: 'blob',
    })
      .map((resp) => {
        const { response, xhr } = resp;
        const filename = xhr
          .getResponseHeader('content-disposition')
          .split('filename=')[1]
          .split(';')[0];
        downloadHandler(response, filename);
        return receiveDownloadRecipientsFileSuccess(response);
      })
      .takeUntil(actions$.ofType(requestDownloadRecipientsFile.getType()))
      .catch((err) => Observable.of(receiveDownloadRecipientsFileFailure(err))),
  );

const resendAuthorizationCodesEpic = (actions$) =>
  actions$
    .ofType(requestResendAuthorizationCodes.getType())
    .mergeMap((action) =>
      ajax({
        url: routes.SINGLE_DOCUMENT_RESEND_AUTHORIZATION_CODES.replace(
          '{id}',
          action.payload.blockchainAddress,
        ),
        headers: {
          Authorization: sessionStorage.getItem('token'),
        },
        method: METHOD_POST,
      })
        .map(({ response }) => receiveResendAuthorizationCodesSuccess(response))
        .takeUntil(actions$.ofType(requestResendAuthorizationCodes.getType()))
        .catch((err) =>
          Observable.of(receiveResendAuthorizationCodesFailure(err)),
        ),
    );

const forgetDocumentEpic = (actions$) =>
  actions$.ofType(requestForgetDocument.getType()).mergeMap((action) =>
    ajax({
      url: routes.SINGLE_DOCUMENT_FORGET.replace(
        '{id}',
        action.payload.blockchainAddress,
      ),
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
      method: METHOD_POST,
    })
      .map(({ response }) => receiveForgetDocumentSuccess(response))
      .takeUntil(actions$.ofType(requestForgetDocument.getType()))
      .catch((err) => Observable.of(receiveForgetDocumentFailure(err))),
  );

const getSingleHistoryEpic = (actions$) =>
  actions$
    .ofType(
      requestSingleNotificationHistory.getType(),
      requestSingleNotificationHistoryQuiet.getType(),
    )
    .mergeMap((action) =>
      ajax({
        url: `${routes.DOCUMENT_HISTORY.replace(
          '{id}',
          action.payload.id,
        )}?${qs.stringify(parseFilters(action.payload.filters), {
          allowDots: true,
        })}`,
        headers: {
          [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
          Authorization: sessionStorage.getItem('token'),
        },
        method: METHOD_GET,
      })
        .map(({ response }) => {
          return receiveSingleNotificationHistorySuccess(response);
        })
        .takeUntil(
          actions$.ofType(
            requestSingleNotificationHistory.getType(),
            requestSingleNotificationHistoryQuiet.getType(),
          ),
        )
        .catch((err) =>
          Observable.of(receiveSingleNotificationHistoryFailure(err)),
        ),
    );

export default [
  getDocumentsListEpic,
  getDocumentsSingleEpic,
  downloadDocumentEpic,
  prepareDocumentEpic,
  publishDocumentEpic,
  deleteDocumentEpic,
  downloadRecipientsListEpic,
  resendAuthorizationCodesEpic,
  forgetDocumentEpic,
  getSingleHistoryEpic,
  updateDocumentEpic,
];
