import React, { useState } from 'react';
import { useNotificationContent } from '../../Notifications/hooks/useNotificationContent';

const useNotificationPreview = () => {
  const [isPreviewOpened, setIsPreviewOpened] = useState(false);
  const [params, setParams] = useState({});
  const [jobId, setJobId] = useState({});

  const showContent = (jobId, clientId, contactDetails) => {
    setParams({ clientId, contactDetails });
    setJobId(jobId);
    setIsPreviewOpened(true);
  };

  const closeContent = () => {
    setIsPreviewOpened(false);
  };

  const { data: content, isLoading: isPreviewLoading } = useNotificationContent(
    { params },
    jobId,
    { enabled: isPreviewOpened },
  );

  return {
    content,
    setIsPreviewOpened,
    isPreviewOpened,
    isPreviewLoading,
    showContent,
    closeContent,
  };
};

export default useNotificationPreview;
