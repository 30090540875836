import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { IconInCircle } from '../../../../components/IconInCircle';
import { Grid } from 'billon-ui';
const { Row } = Grid;

const StyledDocumentsTypeH6 = styled.h6`
   {
    font-size: 12px;
    font-weight: ${(props) => (props.bold ? 700 : 600)};
    text-align: ${(props) => (props.right ? 'right' : 'left')};
    margin-bottom: 0px;
    margin-left: 10px;
  }
`;
const StyledDocumentsTypeH5 = styled.h5`
   {
    font-size: 14px;
    font-weight: ${(props) => (props.bold ? 600 : 500)};
    color: ${(props) => (props.color ? props.color : '#000')};
    margin-top: 10px;
    margin-left: 32px;
  }
`;

const StyledCenteredRow = styled(Row)`
   {
    display: flex;
    align-items: center;
  }
`;

const StyledDocumentsTypeSelectWrapper = styled.div`
   {
    width: 100%;
    height: 108px;
    background: #ffffff;
    border: 1px solid ${(props) => (props.isSelected ? props.color : '#c9d4de')};
    border-radius: 15px;
    cursor: ${(props) => (props.clickable ? 'pointer' : '')};
    padding: 16px;
    margin: 5px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
      border: 1px solid ${(props) => (props.color ? props.color : '#c9d4de')};
    }
  }
`;

export const DocumentTypeSelect = (props) => {
  DocumentTypeSelect.propTypes = {
    iconName: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
  };
  return (
    <StyledDocumentsTypeSelectWrapper
      onClick={props.onClick}
      isSelected={props.isSelected}
      clickable
      color={props.color}
    >
      <StyledCenteredRow margin={0}>
        <IconInCircle color={props.color} iconName={props.iconName} />
        <StyledDocumentsTypeH6>
          <FormattedMessage id={props.type} defaultMessage={props.type} />
        </StyledDocumentsTypeH6>
      </StyledCenteredRow>
      {props.count && (
        <StyledDocumentsTypeH5 color={props.color}>
          <FormattedMessage id={'Documents'} defaultMessage={'Documents'} /> :{' '}
          {props.count}
        </StyledDocumentsTypeH5>
      )}
      <StyledDocumentsTypeH6 right>
        <FormattedMessage id={'See'} defaultMessage={'See'} />
      </StyledDocumentsTypeH6>
    </StyledDocumentsTypeSelectWrapper>
  );
};
