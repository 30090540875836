import base from './base';
import { CUSTOMER, PUBLICATION_TYPE } from '../constraints';

const config = {
  ...base,
  backendUrl: 'https://apimvppublication.uat.tdm.bybillon.com',
  enablePrivate: true,
  customer: CUSTOMER.DEFAULT,
  language: 'pl',
  manageRecipients: true,
  publicationType: PUBLICATION_TYPE.TWO_STEP,
  privateDocuments: {
    enableHeaderLink: true,
    enableNewVersion: true,
    enableErratum: true,
    enableIncident: false,
    enableDownloadQR: false,
  },
  publicDocuments: {
    enableHeaderLink: true,
    enableNewVersion: true,
    enableErratum: true,
    enableIncident: false,
    enableDownloadQR: false,
  },
  SMS_CODE_STATUSES: null,
  enableDocumentDetails: {
    publicationStartDate: false,
    publicationDate: true,
    categoryFullPath: false,
    status: true,
    publisherName: true,
    publishedBy: true,
    versionName: true,
    retainUntil: true,
    validSince: true,
    validUntil: true,
    identity: false,
    documentNumber: false,
  },
};

export default config;
