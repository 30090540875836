import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { VALIDATION_ERRORS, mapVALIDATION_USER_ERRORS } from 'js/constraints';
import { Utils } from 'billon-ui';
import validatePESEL from './validators/validatePESEL';
import config from 'js/config';
import { CUSTOMER } from 'js/constraints';

const { customer } = config;
const { validators } = Utils;

export const useSchema = () => {
  const { formatMessage } = useIntl();
  return yup.object({
    firstName: yup.string().required(
      formatMessage({
        id: mapVALIDATION_USER_ERRORS.EMPTY_FIRST_NAME,
        defaultMessage: mapVALIDATION_USER_ERRORS.EMPTY_FIRST_NAME,
      }),
    ),
    lastName: yup.string().required(
      formatMessage({
        id: mapVALIDATION_USER_ERRORS.EMPTY_LAST_NAME,
        defaultMessage: mapVALIDATION_USER_ERRORS.EMPTY_LAST_NAME,
      }),
    ),
    phoneNumber: yup
      .string()
      .test(
        'phoneNumber',
        formatMessage({
          id: VALIDATION_ERRORS.PHONE_INVALID,
          defaultMessage: VALIDATION_ERRORS.PHONE_INVALID,
        }),
        (v) => !validators.phoneVerification(v),
      )
      .required(
        formatMessage({
          id: mapVALIDATION_USER_ERRORS.EMPTY_PHONE_NUMBER,
          defaultMessage: mapVALIDATION_USER_ERRORS.EMPTY_PHONE_NUMBER,
        }),
      ),
    email: yup
      .string()
      .test(
        'email',
        formatMessage({
          id: VALIDATION_ERRORS.EMAIL_INVALID,
          defaultMessage: VALIDATION_ERRORS.EMAIL_INVALID,
        }),
        (v) => !validators.emailValidator(v),
      )
      .required(
        formatMessage({
          id: mapVALIDATION_USER_ERRORS.EMPTY_EMAIL,
          defaultMessage: mapVALIDATION_USER_ERRORS.EMPTY_EMAIL,
        }),
      ),
    documentNumber: yup
      .string()
      .test(
        'documentNumber',
        customer === CUSTOMER.DIPLOMA
          ? formatMessage({
              id: mapVALIDATION_USER_ERRORS.INVALID_INDEX,
              defaultMessage: mapVALIDATION_USER_ERRORS.INVALID_INDEX,
            })
          : formatMessage({
              id: mapVALIDATION_USER_ERRORS.INVALID_PESEL,
              defaultMessage: mapVALIDATION_USER_ERRORS.INVALID_PESEL,
            }),
        (v) => (customer === CUSTOMER.DIPLOMA ? true : !validatePESEL(v)),
      )
      .required(
        formatMessage({
          id: mapVALIDATION_USER_ERRORS.EMPTY_DOCUMENT_NUMBER,
          defaultMessage: mapVALIDATION_USER_ERRORS.EMPTY_DOCUMENT_NUMBER,
        }),
      ),
  });
};
