/* global window */
import React, { useState, useEffect } from 'react';
import { Layout as LayoutModule, Utils } from 'billon-ui';
import NotificationHistoryModal from '../../components/NotificationHistoryModal';
import NotificationHistory from '../../components/NotificationHistory';
import { QueryKeys } from '../../../../queryKeys';
import { useQueryClient } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';
import appRoutes from '../../../../app-routes';
import { useSingleDocument } from '../../hooks/useSingleDocument';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

const NotificationHistoryPage = () => {
  const history = useHistory();

  const [notificationHistoryModalOpened, setNotificationHistoryModalOpened] =
    useState(true);

  const { params } = useRouteMatch(
    appRoutes.DOCUMENTS_DETAILS_NOTIFICATION_HISTORY,
  );

  const handleNotificationHistoryModalClose = () => {
    setNotificationHistoryModalOpened(false);
    history.push(`/document/${params?.id}`);
  };

  const queryClient = useQueryClient();

  const { data: document, isLoading } = useSingleDocument({ id: params?.id });

  const handleSuccess = () => {
    queryClient.invalidateQueries([QueryKeys.SINGLE_DOCUMENT, params?.id]);
  };

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <NotificationHistoryModal
      isOpen={notificationHistoryModalOpened}
      toggle={handleNotificationHistoryModalClose}
      onClose={handleNotificationHistoryModalClose}
      closeIconEnabled
    >
      <NotificationHistory
        jobId={params?.id}
        documentBlockchainAddress={document.documentBlockchainAddress}
      />
    </NotificationHistoryModal>
  );
};

export default NotificationHistoryPage;
