import React from 'react';
import styled from 'styled-components';

import { Form as FormModule } from 'billon-ui';

export const Permissions = styled.div`
  margin-bottom: 2rem;
`;

export const FieldFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
`;

export const FC = styled.div`
  align-self: center;
`;

export const FC2 = styled.div`
  padding-top: 2px;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  margin-right: 17px;
`;

export const Form = styled(FormModule.Form)`
  h2 {
    font-size: 2rem;
    margin-bottom: 2.5rem;
    margin-top: -3.5rem;
    width: calc(100% - 35px);
  }
`;
