/* global window */
import React from 'react';
import { Layout as LayoutModule, Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import config from 'js/config';
import { useHistory, useRouteMatch } from 'react-router-dom';
import appRoutes from 'js/app-routes';
import { useDailyReport } from '../../hooks';
import { SETTINGS_CONSTANTS } from 'js/constraints';
import useSettingsRequest from 'js/modules/Settings/hooks/useSettingsRequest';
import { DailyReportDetails } from '../../components';

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;
const { Content: ContentModule } = LayoutModule;
const { ContentContainer, Content } = ContentModule;
const { customer } = config;

const DailyReportDetailsPage = () => {
  const history = useHistory();

  const { params } = useRouteMatch(appRoutes.DAILY_REPORT_DETAILS);
  const id = params?.id;

  const { data: settings, isSettingsLoading } = useSettingsRequest({});
  const { data: document, isLoading } = useDailyReport({ id: id });

  const publisherName = settings?.find(
    (item) => item.key === SETTINGS_CONSTANTS.PUBLISHER_NAME,
  );

  const handleOnBack = () => {
    history.push(appRoutes.DAILY_REPORTS_BLANK);
  };

  if (isSettingsLoading || isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  return (
    <ContentContainer fluid>
      <Content fluid>
        <DailyReportDetails
          document={document}
          customer={customer}
          onBackClick={handleOnBack}
          publisherName={publisherName?.value}
          backButtonLabel={
            <FormattedMessage
              id="Daily reports"
              defaultMessage="Daily reports"
            />
          }
        />
      </Content>
    </ContentContainer>
  );
};

export default DailyReportDetailsPage;
