import React, { useState } from 'react';
import { useCompileNotificationMessages } from '../../../../hooks/useCompileNotificationMessages';
import {
  SelectEditTypeModal,
  EmailNotificationModal,
  SmsNotificationModal,
} from './components';
import { useForm, FormProvider } from 'react-hook-form';

const EnterNotificationContent = ({
  isOpen,
  isLoading,
  onBack,
  onClose,
  onNext,
  defaultValues = {},
}) => {
  const formMethods = useForm({
    defaultValues: {
      smsContentInput: '',
      emailContentInput: '',
      ...defaultValues,
    },
  });

  const handleFormSubmit = (values) => {
    onNext(values);
  };

  const { handleSubmit, watch } = formMethods;

  const emailContentInput = watch('emailContentInput');
  const smsContentInput = watch('smsContentInput');

  const {
    emailPreviewValue,
    smsContentPreviewValue,
    isLoading: isTemplateLoading,
    error,
  } = useCompileNotificationMessages(emailContentInput, smsContentInput);

  const [isEmailContentOpened, setIsEmailContentOpened] = useState(false);
  const [isSmsContentOpened, setIsSmsContentOpened] = useState(false);

  const onEditEmailNotification = () => {
    setIsEmailContentOpened(true);
  };
  const onEditSmsNotification = () => {
    setIsSmsContentOpened(true);
  };
  const onEditEmailNotificationClose = () => {
    setIsEmailContentOpened(false);
  };
  const onEditSmsNotificationClose = () => {
    setIsSmsContentOpened(false);
  };

  if (error) {
    return <div> Error while loading templates...</div>;
  }

  return (
    <FormProvider {...formMethods}>
      <form>
        <SelectEditTypeModal
          isOpen={isOpen}
          isLoading={isLoading || isTemplateLoading}
          onBack={onBack}
          onClose={onClose}
          onNext={handleSubmit(handleFormSubmit)}
          onEditSmsNotification={onEditSmsNotification}
          onEditEmailNotification={onEditEmailNotification}
        />
        <EmailNotificationModal
          isOpen={isEmailContentOpened}
          isLoading={isLoading || isTemplateLoading}
          onBack={onEditEmailNotificationClose}
          onClose={onEditEmailNotificationClose}
          onNext={onEditEmailNotificationClose}
          emailPreviewValue={emailPreviewValue}
        />
        <SmsNotificationModal
          isOpen={isSmsContentOpened}
          isLoading={isLoading || isTemplateLoading}
          onBack={onEditSmsNotificationClose}
          onClose={onEditSmsNotificationClose}
          onNext={onEditSmsNotificationClose}
          smsContentPreviewValue={smsContentPreviewValue}
        />
      </form>
    </FormProvider>
  );
};

export default EnterNotificationContent;
