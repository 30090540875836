import { Grid, Utils } from 'billon-ui';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
// Utils
import { SETTINGS_CONSTANTS } from '../../../../constraints';
import * as formatters from '../../../../formatters';

const { Button: ButtonModule, Icon } = Utils;
const { Button } = ButtonModule;

const { Col, Row } = Grid;

const DocumentInfo = styled.div`
  .btn {
    font-size: 13px;
    height: 100%;

    ${down('sm')} {
      width: 250px;
    }
  }

  .info-col {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  label.large {
    display: block;
    margin-bottom: 0;
  }

  .blockchain-address-span {
    word-wrap: break-word;
    font-weight: 600;
  }

  .status-span {
    font-weight: 600;
  }
`;

const MyButton = styled.button`
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  margin-left: 1rem;
  /* padding-left: 1rem; */
  /* border-left: 1px solid ${(props) => props.theme.palette.grayLighter}; */

  &:hover,
  &:focus {
    outline: none;
  }
`;

const CopiedInfo = styled.div`
  position: absolute;
  font-size: ${(props) => props.theme.fontSize12};
  background-color: #acbbc7;
  color: #fff;
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 0.25rem 1.5rem;
  top: -2.5rem;
  left: 50%;
  margin-left: -57px;
  transition: ${(props) => props.theme.transitionBase};
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

export const DocumentRowWithDetails = (props) => {
  let documentPreviewUrl = `${
    props.settings[SETTINGS_CONSTANTS.DOCUMENT_PREVIEW_URL]
  }/p`;

  return (
    <DocumentInfo>
      <Row margin={0}>
        <Col
          padding={0}
          lg={props.activeDelivery ? 4 : 5}
          css="margin-top: 1rem; margin-bottom: 1rem"
        >
          {props.details.documentBlockchainAddress ? (
            <>
              <label className="large">
                <FormattedMessage
                  id="Blockchain address"
                  defaultMessage="Blockchain address"
                />
                :
                <CopyToClipboard
                  text={props.details.documentBlockchainAddress}
                  onCopy={() => props.onCopied()}
                >
                  <MyButton
                    onMouseOver={() => props.changeCopyHoverTrue()}
                    onMouseOut={() => props.changeCopyHoverFalse()}
                  >
                    <CopiedInfo show={props.copiedState}>
                      <FormattedMessage id="Copied" defaultMessage="Copied" />
                    </CopiedInfo>

                    <CopiedInfo
                      show={props.copiedState ? false : props.copyHoverState}
                    >
                      <FormattedMessage id="Copy" defaultMessage="Copy" />
                    </CopiedInfo>
                    <Icon name="copy" regular color="#7e7f7f" />
                  </MyButton>
                </CopyToClipboard>
              </label>
              <span class="blockchain-address-span">
                {props.details.documentBlockchainAddress}
              </span>
            </>
          ) : (
            <label className="large">
              <FormattedMessage
                id="Document does not yet have a blockchain address"
                defaultMessage="Document does not yet have a blockchain address"
              />
            </label>
          )}
        </Col>
        {props.activeDelivery && (
          <Col lg={2} css="margin-top: 1rem; margin-bottom: 1rem">
            <label className="large">
              <FormattedMessage
                id="e-Delivery Status"
                defaultMessage="e-Delivery Status"
              />{' '}
              :
            </label>
            <span class="status-span">
              <FormattedMessage
                id="Confirmation of reading"
                defaultMessage="Confirmation of reading"
              />
            </span>
            <br />
            <span>
              <FormattedMessage id="Date" defaultMessage="Date" />:{' '}
              {formatters.momentFormatter(new Date(2021, 3, 16, 17, 0, 0))}
            </span>
          </Col>
        )}
        <Col
          padding={0}
          lg={
            props.activeDelivery
              ? { size: 4, offset: 2 }
              : { size: 4, offset: 3 }
          }
        >
          <Row margin={0}>
            {props.details.documentBlockchainAddress && (
              <Col lg={{ size: 6, offset: 6 }} css="margin-bottom: 0.3rem;">
                <Button
                  width="100%"
                  outline
                  onClick={() =>
                    props.openDetailsPage(
                      props.details.documentBlockchainAddress,
                    )
                  }
                >
                  Szczegóły
                </Button>
              </Col>
            )}
            {!props.details.documentBlockchainAddress &&
              props.details.agreementStatus === 'WAITING-FOR-SENDER' && (
                <>
                  <Col lg={{ size: 6, offset: 2 }} css="margin-bottom: 0.3rem;">
                    {/* <Col sm={{ size: 6, offset: 6 }}> */}
                    <Button
                      width="100%"
                      outline
                      onClick={() => {
                        props.openPublishModalFromList(props.details);
                      }}
                    >
                      <FormattedMessage id="Publish" defaultMessage="Publish" />
                    </Button>
                  </Col>
                  <Col lg={4} css="margin-bottom: 0.3rem;">
                    <Button
                      width="100%"
                      color="error"
                      outline
                      onClick={() =>
                        props.handleDelete({ jobId: props.details.jobId })
                      }
                    >
                      <FormattedMessage id="Delete" defaultMessage="Delete" />
                    </Button>
                  </Col>
                </>
              )}
          </Row>
        </Col>
      </Row>
    </DocumentInfo>
  );
};
