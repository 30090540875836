import React from 'react';
import moment from 'moment';

import { mapDOCUMENT_ACTIONS_ERROR, PUBLICATION_MODES } from 'js/constraints';

// Hooks
import { usePublishDocument } from '../../hooks';

// Components
import { DocumentAtrributesForm } from '../DocumentAtrributesForm';
import { ErrorModal } from 'js/components/ErrorModal';
import { ExportableErrorContent } from 'js/components/ExportableErrorContent';

export const OneStepPublication = ({
  onCreateIdentity,
  onCreateCategory,
  isPreparedToSign,
  initialValues,
  onSuccess,
  onCancel,
}) => {
  const [step, setStep] = React.useState(isPreparedToSign ? 2 : 1);
  const [error, setError] = React.useState(null);
  const [documentTitle, setDocumentTitle] = React.useState(
    isPreparedToSign ? initialValues.title : null,
  );

  const { mutate: publishDocument } = usePublishDocument({
    onSuccess: () => {
      onSuccess();
      // queryClient.invalidateQueries([QueryKeys.DOCUMENT_LIST]); // should be used when document table will be refactored
    },
    onError: (error) => setError(error.response.data),
  });

  if (error) {
    return (
      <ErrorModal
        isOpen
        toggle={onCancel}
        onClose={onCancel}
        closeIconEnabled={false}
      >
        <ExportableErrorContent
          headerTitleId={mapDOCUMENT_ACTIONS_ERROR.PUBLISH}
          statusCode={error.statusCode}
          jobId={initialValues.jobId}
          title={documentTitle}
        />
      </ErrorModal>
    );
  }

  const stepProperties = {
    1: {
      submitButtonText: 'Next',
      onSuccess: () => setStep(step + 1),
    },
    2: {
      submitButtonText: 'Publish',
      onSuccess: (values) => {
        setDocumentTitle(values.title);

        publishDocument({
          data: {
            publicationMode: PUBLICATION_MODES.NEW,
            title: values.title,
            identity: {
              id: values.identity.value,
            },
            validSince: moment(values.validSince).format('YYYY-MM-DDTHH:mm:ss'),
            category: {
              id: values.category.value,
            },
            file: values.file,
          },
        });
      },
    },
  }[step];

  return (
    <DocumentAtrributesForm
      step={step}
      initialValues={initialValues}
      onSuccess={stepProperties.onSuccess}
      onCreateIdentity={onCreateIdentity}
      onCreateCategory={onCreateCategory}
      submitButtonText={stepProperties.submitButtonText}
      onBack={() => setStep(step - 1)}
    />
  );
};
