import React from 'react';
import { connect } from 'react-redux';

import { PUBLICATION_TYPE } from '../../../../constraints';

// Components
import { OneStepPublication, TwoStepPublication } from './componnets';

export const CreateForm = (props) => {
  if (props.isOneStepPublication) {
    return <OneStepPublication {...props} />;
  }

  return <TwoStepPublication {...props} />;
};

const mapStateToProps = ({ config }) => ({
  isOneStepPublication: config.publicationType === PUBLICATION_TYPE.ONE_STEP,
});

export default connect(mapStateToProps)(CreateForm);
