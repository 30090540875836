import { Grid, Utils } from 'billon-ui';
import styled from 'styled-components';
import React from 'react';
const { Col } = Grid;
const { Button, ButtonLoader } = Utils.Button;

export const MyButton = styled.button`
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  margin-left: 1rem;

  &:hover,
  &:focus {
    outline: none;
  }
`;

export const CopiedInfo = styled.div`
  position: absolute;
  font-size: ${(props) => props.theme.fontSize12};
  background-color: #acbbc7;
  color: #fff;
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 0.25rem 1.5rem;
  top: -2.5rem;
  left: 50%;
  margin-left: -57px;
  transition: ${(props) => props.theme.transitionBase};
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

export const DetailsCol = styled(Col)`
  max-height: 300px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 15px;
  margin-bottom: 40px;
`;

const LineHeaderRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  margin-right: 15px;
`;

const LineHeaderRowTitle = styled.div`
  flex-grow: 0;
`;

const LineHeaderRowLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: ${(props) => props.theme.palette.grayLighter};
`;

export const LineHeaderRow = (props) => (
  <LineHeaderRowContainer {...props}>
    <LineHeaderRowTitle>{props.children}</LineHeaderRowTitle>
    <LineHeaderRowLine />
  </LineHeaderRowContainer>
);

const FullHeightBtn = styled(Button)`
  height: 100%;
  overflow: hidden;
`;

const FullHeightButtonLoader = styled(ButtonLoader)`
  height: 100%;
`;

export const OneThirdColBtn = (props) => (
  <Col lg={4} paddingLeft={0} margin={'0 0 15px 0'}>
    {props.isLoading ? (
      <FullHeightButtonLoader block />
    ) : (
      <FullHeightBtn block {...props}>
        {props.children}
      </FullHeightBtn>
    )}
  </Col>
);
