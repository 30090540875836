import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Utils } from 'billon-ui';
import Modal from 'js/ui/Modal';
import * as Styled from './styled';
import { Link } from 'react-router-dom';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

const BlockedModal = ({
  isOpen,
  toggle,
  onClose,
  closeIconEnabled,
  children,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onClose={onClose}
      closeIconEnabled={closeIconEnabled}
    >
      <Styled.Content>
        <Styled.Title>
          <FormattedMessage
            id={'Account has been blocked'}
            defaultMessage={'Account has been blocked'}
          />
        </Styled.Title>

        <Styled.Description>
          <FormattedMessage
            id={
              'You have exceeded the allowed number of failed login attempts.'
            }
            defaultMessage={
              'You have exceeded the allowed number of failed login attempts.'
            }
          />{' '}
          {children}
        </Styled.Description>

        <Button size="lg" block onClick={onClose}>
          <FormattedMessage id="Close" defaultMessage="Close" />
        </Button>

        <Styled.Feedback>
          <FormattedMessage
            id="Forgot your password?"
            defaultMessage="Forgot your password?"
          />{' '}
          <Link to="/support">
            <FormattedMessage
              id="Contact the support team"
              defaultMessage="Contact the support team"
            />
          </Link>
        </Styled.Feedback>
      </Styled.Content>
    </Modal>
  );
};

export default BlockedModal;
