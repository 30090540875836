import apiRoutes from 'js/api-routes';
import { postRequest } from 'js/helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';
import { DOCUMENT_TYPE } from '../../../constraints';

const transformFromFileParams = (params) => {
  const { documentFile, contactDetails, ...documentInfo } = params;
  const formData = new FormData();

  formData.append('documentFile', documentFile[0]);

  if(contactDetails) {
    formData.append('contactDetails', contactDetails[0]);
  }

  const newBlob = new Blob([JSON.stringify(documentInfo)], {
    type: 'application/json',
  });

  formData.append('documentInfo', newBlob);

  return formData;
};

export const usePublishDocument = (type, options) =>
  useMutationWithLogout(async ({ data }) => {
    const { data: publishedDocumentData } = await postRequest({
      url: type === DOCUMENT_TYPE.PUBLIC ? apiRoutes.DOCUMENT_PUBLIC_PUBLISH :apiRoutes.DOCUMENT_PUBLISH ,
      data: transformFromFileParams(data),
      headers: {
        Authorization: sessionStorage.getItem('token'),
        'Content-Type': 'multipart/form-data',
      },
    });

    return publishedDocumentData;
  }, options);
