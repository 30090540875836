import React from 'react';
import styled from 'styled-components';

import {
  Dropdown as DropdownModule,
  Layout,
  Nav as NavModule,
  Utils,
} from 'billon-ui';

import { down, up } from 'styled-breakpoints';
import { CUSTOMER } from '../../constraints';

const { Nav: BillonNav } = NavModule;
const { Header: HeaderModule } = Layout;
const {
  NavbarBrand: NB,
  Collapse: BillonCollapse,
  NavbarNavLink: NL,
} = HeaderModule;
const { DropdownToggle: DT, DropdownMenu: DM } = DropdownModule;
const { Icon: BillonIcon } = Utils;

export const NavbarBrand = styled(NB)`
  display: inline-flex;
  align-items: center;

  .custom-logo {
    ${(props) => {
      if (props.customer === CUSTOMER.TAURON) {
        return `
          height: 60px;
      `;
      }
      return null;
    }}
  }
`;

export const Description = styled.h3`
  color: ${(props) => props.theme.bodyColor};
  width: 10rem;
  font-size: ${(props) => props.theme.fontSizeSm};
  font-weight: ${(props) => props.theme.fontWeightBase};
  white-space: pre;
  margin-bottom: 0;
`;

export const Header = styled.header`
  .navbar {
    height: 71px;
  }
`;

export const Collapse = styled(BillonCollapse)`
  ${down('lg')} {
    justify-content: flex-end;
  }

  .navbar-nav {
    ${down('lg')} {
      display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    }
  }
`;

export const Nav = styled(BillonNav)`
  ${up('xl')} {
    margin: 0 0 0 auto !important;
  }

  ${down('lg')} {
    border-top: 3px solid #fafafa;
    margin-left: 2rem !important;
    background-color: rgb(250, 250, 250);
    position: absolute;
    top: 71px;
    left: -2rem;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 3px 5px rgb(0 0 0 / 15%);
  }
`;

export const NavLink = styled(NL)`
  padding: 1rem 2rem;
  font-size: 0.75rem;
`;

export const Icon = styled(BillonIcon)`
  margin-left: 1.5rem;
  cursor: pointer;
  width: 2rem !important;

  ${up('xl')} {
    display: none;
  }
`;

export const DropdownToggle = styled(DT)`
  &::after {
    display: none;
  }

  svg {
    color: #cad5dd;
    transition: all 0.3s;

    &:hover {
      color: #acbbc7;
    }
  }
`;

export const DropdownMenu = styled(DM)`
  margin-top: 11px;
`;
