import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Layout, Modal as ModalModule } from 'billon-ui';

// Components
import { Topbar } from '../components/TopBar/TopBar';
import { CategoriesContainer } from '../components/CategoriesContainer';
import { CreateForm } from '../components/CreateForm/CreateForm';

const { CreateModal } = ModalModule;
const { Content: ContentModule } = Layout;
const { ContentContainer, Content } = ContentModule;

const StyledCreateModal = styled(CreateModal)`
  max-width: 600px;
`;

const Title = styled.h2`
  margin-top: 40px;
  font-size: 20px;
  font-width: 700;
  color: #011627;
  font-weight: 700;
`;

const HomePage = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const [parentCategory, setParentCategory] = useState();
  const handleCreate = (category) => {
    setParentCategory(category);
    toggleCreateCategoryModal();
  };

  const toggleCreateCategoryModal = () => {
    setCreateModalOpen(!createModalOpen);
  };

  return (
    <ContentContainer fluid>
      <Content fluid>
        <Topbar onCreate={handleCreate} />
        <Title>
          <FormattedMessage id="Category" defaultMessage="Category" />
        </Title>
        <CategoriesContainer onCreate={handleCreate} />
        <StyledCreateModal
          isOpen={createModalOpen}
          onSuccess={toggleCreateCategoryModal}
          onCancel={toggleCreateCategoryModal}
          initialValues={{ isActive: true }}
          createMessage={
            <FormattedMessage
              id="Adding category"
              defaultMessage="Adding category"
            />
          }
        >
          <CreateForm
            parentCategory={parentCategory}
            toggle={toggleCreateCategoryModal}
          />
        </StyledCreateModal>
      </Content>
    </ContentContainer>
  );
};

export default HomePage;
