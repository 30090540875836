import React from 'react';
import { useIntl } from 'react-intl';
import * as Styled from './styled';
import useSettingsRequest from '../../modules/Settings/hooks/useSettingsRequest';
import { SETTINGS_CONSTANTS } from '../../constraints';

const SourceSystemsExporter = ({ onClick, children }) => {
  const { formatMessage } = useIntl();

  const { data: settings, isSettingsLoading } = useSettingsRequest({});

  const fileName = `${formatMessage({ id: 'SourceSystems' })}.csv`;

  const sourceSystems = settings?.find(
    (item) => item.key === SETTINGS_CONSTANTS.RECIPIENT_SOURCE_SYSTEM_NAMES,
  )?.value;

  let sourceSystemsArray = sourceSystems?.split(',');
  let sourceSystemsRows =
    sourceSystemsArray?.map((value, index) => [index + 1, value]) || [];

  let rows = [
    ['#', formatMessage({ id: 'Source systems available' })],
    ...sourceSystemsRows,
  ];

  if (isSettingsLoading) {
    return { children };
  }

  return (
    <Styled.CustomCSVLink data={rows} filename={fileName} onClick={onClick}>
      {children}
    </Styled.CustomCSVLink>
  );
};

export default SourceSystemsExporter;
