import styled from 'styled-components';
import { Modal as ModalModule } from 'billon-ui';
import { Grid, Utils } from 'billon-ui';

const { Button: ButtonModule } = Utils;
const { BackButton: BillonBackButton } = ButtonModule;
const { ModalFooter: BillonModalFooter } = ModalModule;
const { Col } = Grid;

export const ModalFooter = styled(BillonModalFooter)`
  padding-left: 0;
  padding-right: 0;
`;

export const BackButton = styled(BillonBackButton)`
  padding: 0;
`;

export const ZeroCol = styled(Col)`
  padding-left: 11.2px;
`;

export const TitleContainer = styled(Col)`
  align-self: center;
`;

export const ModalTitle = styled.span`
  text-align: left;
  padding-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
`;
