/* global sessionStorage */
import { ajax } from 'rxjs/observable/dom/ajax';
import { Observable } from 'rxjs/Rx';
import { Utils } from 'billon-ui';
import download from 'downloadjs';
import {
  requestList,
  receiveListSuccess,
  receiveListFailure,
  requestSingle,
  receiveSingleSuccess,
  receiveSingleFailure,
  requestPrepare,
  receivePrepareSuccess,
  receivePrepareFailure,
  requestPublish,
  receivePublishSuccess,
  receivePublishFailure,
  requestDownload,
  receiveDownloadSuccess,
  receiveDownloadFailure,
  requestDelete,
  receiveDeleteSuccess,
  receiveDeleteFailure,
  requestDownloadRecipientsFile,
  receiveDownloadRecipientsFileSuccess,
  receiveDownloadRecipientsFileFailure,
  requestResendAuthorizationCodes,
  receiveResendAuthorizationCodesSuccess,
  receiveResendAuthorizationCodesFailure,
  requestForgetDocument,
  receiveForgetDocumentSuccess,
  receiveForgetDocumentFailure,
} from './actions';
import routes from '../../api-routes';

import { parseFilters, parsePublish, parseSave } from './helpers';
import { initializeWebViewerFromBlob } from '../../webviewer';

const { helpers } = Utils;
const { request, encodeQueryString } = helpers;
const {
  METHOD_GET,
  HEADER_CONTENT_TYPE,
  CONTENT_TYPE_APPLICATION_JSON,
  X_ACCESS_TOKEN,
  METHOD_POST,
} = request;

const getDocumentsListEpic = (actions$) => {
  return actions$.ofType(requestList.getType()).mergeMap((action) =>
    ajax({
      url: `${routes.AGREEMENT}?options=${encodeQueryString(
        parseFilters(action.payload.filters),
      )}`,
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => receiveListSuccess(response))
      .takeUntil(actions$.ofType(requestList.getType()))
      .catch((err) => Observable.of(receiveListFailure(err))),
  );
};

const getDocumentsSingleEpic = (actions$) =>
  actions$.ofType(requestSingle.getType()).mergeMap((action) =>
    ajax({
      url: routes.SINGLE_AGREEMENT.replace('{id}', action.payload.id),
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => receiveSingleSuccess(response))
      .takeUntil(actions$.ofType(requestSingle.getType()))
      .catch((err) => Observable.of(receiveSingleFailure(err))),
  );

const prepareDocumentEpic = (actions$) =>
  actions$.ofType(requestPrepare.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { values } = action.payload;

    return ajax({
      url: routes.PREPARE_AGREEMENT,
      body: parseSave(values),
      headers: {
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_POST,
    })
      .map(({ response }) => {
        const blob = new Uint8Array(response.file.fileBuffer.data);
        if (!response.isAgreement && !values.prepareWithoutAutoDownload) {
          download(blob, `${values.title}.pdf`);
        }

        if (resolve) {
          resolve(response);
        }
        return receivePrepareSuccess(response.jobId);
      })
      .takeUntil(actions$.ofType(requestPrepare.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }
        return Observable.of(receivePrepareFailure(err));
      });
  });

const publishDocumentEpic = (actions$) =>
  actions$.ofType(requestPublish.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { values } = action.payload;

    return ajax({
      url: routes.PUBLISH_AGREEMENT,
      body: parsePublish(values),
      headers: {
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_POST,
    })
      .map(({ response }) => {
        if (
          response &&
          response.res &&
          response.res.type === 'DURABLE_MEDIA_ERROR'
        ) {
          reject(response);
        } else {
          resolve(response);
        }
        return receivePublishSuccess(response);
      })
      .takeUntil(actions$.ofType(requestPublish.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }
        return Observable.of(receivePublishFailure(err));
      });
  });

const downloadDocumentEpic = (actions$) =>
  actions$.ofType(requestDownload.getType()).mergeMap((action) =>
    ajax({
      url: `${routes.SINGLE_AGREEMENT_DOWNLOAD}?blockchainAddress=${action.payload.blockchainAddress}&jobId=${action.payload.jobId}`,
      headers: {
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => {
        const blob = new Uint8Array(response.fileBuffer.data);
        if (!action.payload.returnOnlyBlob) {
          download(
            blob,
            response.fileName.includes('.pdf')
              ? response.fileName
              : `${response.fileName}.pdf`,
          );
        } else {
          initializeWebViewerFromBlob(blob);
        }

        return receiveDownloadSuccess(response);
      })
      .takeUntil(actions$.ofType(requestDownload.getType()))
      .catch((err) => Observable.of(receiveDownloadFailure(err))),
  );

const deleteDocumentEpic = (actions$) =>
  actions$.ofType(requestDelete.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { jobId } = action.payload.values;

    return ajax({
      url: routes.SINGLE_AGREEMENT_DISCARD.replace('{id}', jobId),
      body: JSON.stringify(action.payload.values),
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_POST,
    })
      .map(({ response }) => {
        if (resolve) {
          resolve(response);
        }
        return receiveDeleteSuccess(response);
      })
      .takeUntil(actions$.ofType(requestDelete.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }
        return Observable.of(receiveDeleteFailure(err));
      });
  });

const downloadRecipientsListEpic = (actions$) =>
  actions$.ofType(requestDownloadRecipientsFile.getType()).mergeMap((action) =>
    ajax({
      url: routes.SINGLE_AGREEMENT_DOWNLOAD_RECIPIENTS_LIST.replace(
        '{id}',
        action.payload.id,
      ),
      headers: {
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => {
        const blob = new Uint8Array(response.fileBuffer.data);
        download(blob, response.fileName, 'text/csv');
        return receiveDownloadRecipientsFileSuccess(response);
      })
      .takeUntil(actions$.ofType(requestDownloadRecipientsFile.getType()))
      .catch((err) => Observable.of(receiveDownloadRecipientsFileFailure(err))),
  );

const resendAuthorizationCodesEpic = (actions$) =>
  actions$
    .ofType(requestResendAuthorizationCodes.getType())
    .mergeMap((action) =>
      ajax({
        url: routes.SINGLE_AGREEMENT_RESEND_AUTHORIZATION_CODES.replace(
          '{id}',
          action.payload.blockchainAddress,
        ),
        headers: {
          [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
        },
        method: METHOD_POST,
      })
        .map(({ response }) => receiveResendAuthorizationCodesSuccess(response))
        .takeUntil(actions$.ofType(requestResendAuthorizationCodes.getType()))
        .catch((err) =>
          Observable.of(receiveResendAuthorizationCodesFailure(err)),
        ),
    );

const forgetDocumentEpic = (actions$) =>
  actions$.ofType(requestForgetDocument.getType()).mergeMap((action) =>
    ajax({
      url: routes.SINGLE_AGREEMENT_FORGET.replace(
        '{id}',
        action.payload.blockchainAddress,
      ),
      headers: {
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_POST,
    })
      .map(({ response }) => receiveForgetDocumentSuccess(response))
      .takeUntil(actions$.ofType(requestForgetDocument.getType()))
      .catch((err) => Observable.of(receiveForgetDocumentFailure(err))),
  );

export default [
  getDocumentsListEpic,
  getDocumentsSingleEpic,
  downloadDocumentEpic,
  prepareDocumentEpic,
  publishDocumentEpic,
  deleteDocumentEpic,
  downloadRecipientsListEpic,
  resendAuthorizationCodesEpic,
  forgetDocumentEpic,
];
