import * as yup from 'yup';
import { useIntl } from 'react-intl';

import { VALIDATION_ERRORS } from '../../../../constraints';

export const useSchema = () => {
  const { formatMessage } = useIntl();

  return yup.object({
    name: yup
      .string()
      .test(
        'nameMinLength',
        formatMessage(
          {
            id: VALIDATION_ERRORS.MINIMUM_LENGTH_REQUIRED,
            defaultMessage: VALIDATION_ERRORS.MINIMUM_LENGTH_REQUIRED,
          },
          {
            length: 3,
          },
        ),
        (value) => (value ? value.length > 2 : true),
      )
      .required(VALIDATION_ERRORS.REQUIRED),
  });
};
