import React, { Component, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { Layout as LayoutModule, Modal as ModalModule } from 'billon-ui';

// Utils
import { CUSTOMER } from '../../../../constraints';

// Components
import { CreateForm, RecipientTable, ManageForm } from '../../containers';

// Actions
import { isFunction } from 'lodash';
import { useCreateIdentity } from '../../hooks/useCreateIdentity';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../../../queryKeys';
import { useUpdateIdentity } from '../../hooks/useUpdateIdentity';
import useFilters from '../../../../hooks/useFilters';
import { useDownloadIdentityFile } from '../../hooks/useDownloadIdentityFile';
import download from 'downloadjs';

const { Content: ContentModule } = LayoutModule;
const { ContentContainer } = ContentModule;
const {
  CreateModal,
  SuccessModal,
  ConfirmModal,
  ErrorModal,
} = ModalModule;

const NewHomePage = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [manageModalOpen, setManageModalOpen] = useState(false);
  const [manageSuccessModalOpen, setSuccessManageModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const queryClient = useQueryClient();
  const { filters } = useFilters();

  const handleSuccess = () => {
    setCreateModalOpen(false);
    setSuccessModalOpen(true);
    queryClient.invalidateQueries([QueryKeys.IDENTITY_LIST, filters]);
  }

  const { mutate: createIdentity, isLoading: isCreating } = useCreateIdentity({
    onSuccess: handleSuccess,
    onError: (error) => {
      console.log(error);
    }
  });

  const { mutate: editIdentity, isLoading: isUpdating } = useUpdateIdentity({
    onSuccess: handleSuccess,
  });

  const { customer, manageRecipients } = useSelector(({ config }) => ({
    customer: config.customer,
    manageRecipients: config.manageRecipients,
  }));

  const handleOpenCreateModal = () => {
    setCreateModalOpen(true);
    setInitialValues({});
  }

  const handleOpenEditModal = (id, values) => {
    setCreateModalOpen(true);
    setInitialValues(values);
  }

  const handleCreate = (data) => {
    if(initialValues && initialValues.id) {
      editIdentity({
        id: initialValues.id,
        data,
      })
    } else {
      createIdentity({ data });
    }
  }

  const handleManage = () => {
    setManageModalOpen(true);
  }

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  }

  const handleCloseCreateModal = () => {
    setCreateModalOpen(false);
  }

  const handleCloseManage = () => {
    setManageModalOpen(false);
  }

  const handleCloseSuccessManage = () => {
    setSuccessManageModalOpen(false);
  }

  const { mutate: downloadIdentityFile } = useDownloadIdentityFile({
    onSuccess: (response) => {
      const blob = new Uint8Array(response.data);
      download(blob, `IdentityList_${(new Date()).toISOString()}`, 'text/csv');
    },
  });

  const handleDownloadIdentityFile = () => {
    downloadIdentityFile();
  }

  const editMessage = customer === CUSTOMER.DIPLOMA ? 'Editing student' : 'Editing recipient';
  const createMessage = customer === CUSTOMER.DIPLOMA ? 'Adding student' : 'Adding recipient';
  const successMessage = customer === CUSTOMER.DIPLOMA ? 'Student has been successfully saved.' : 'Recipient has been successfully saved.';
  const manageTitle = customer === CUSTOMER.DIPLOMA ? 'Manage students' : 'Manage recipients';
  const manageSuccessMessage = customer === CUSTOMER.DIPLOMA ? 'Successfully added student list' : 'Successfully added recipient list';

  return (
    <ContentContainer fluid>
      <RecipientTable
        handleCreate={handleOpenCreateModal}
        handleEdit={handleOpenEditModal}
        handleManage={handleManage}
        editIdentity={editIdentity}
      />
      <CreateModal
        isOpen={createModalOpen}
        onSuccess={() => {}}
        onCancel={handleCloseCreateModal}
        initialValues={initialValues}
        editMessage={(
          <FormattedMessage
            id={editMessage}
            defaultMessage={editMessage}
          />
        )}
        createMessage={(
          <FormattedMessage
            id={createMessage}
            defaultMessage={createMessage}
          />
        )}
      >
        <CreateForm onSubmit={handleCreate} isSaving={isCreating || isUpdating} />
      </CreateModal>
      <SuccessModal
        title={
          <FormattedMessage
            id="Successfully saved"
            defaultMessage="Successfully saved"
          />
        }
        isOpen={successModalOpen}
        onClose={handleCloseSuccessModal}
      >
        <FormattedMessage
          id={successMessage}
          defaultMessage={successMessage}
        />
      </SuccessModal>
      {manageRecipients && (
        <>
          <CreateModal
            isOpen={manageModalOpen}
            onSuccess={() => {}}
            onCancel={handleCloseManage}
            initialValues={initialValues}
            createMessage={(
              <FormattedMessage
                id={manageTitle}
                defaultMessage={manageTitle}
              />
            )}
          >
            <ManageForm onDownloadIdentities={handleDownloadIdentityFile} handleCancel={handleCloseManage} />
          </CreateModal>
          <SuccessModal
            isOpen={manageSuccessModalOpen}
            onClose={handleCloseSuccessManage}
          >
            <FormattedMessage
              id={manageSuccessMessage}
              defaultMessage={manageSuccessMessage}
            />
          </SuccessModal>
        </>
      )}
    </ContentContainer>
  )
}

export default NewHomePage;
