import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CopiedInfo } from '../CopiedInfo';

export const BlockchainAddressDetails = ({ record }) => {
  if (!record.documentBlockchainAddress) {
    return (
      <label className="large">
        <FormattedMessage
          id="Document does not yet have a blockchain address"
          defaultMessage="Document does not yet have a blockchain address"
        />
      </label>
    );
  }

  return (
    <CopiedInfo
      label="Blockchain address"
      value={record.documentBlockchainAddress}
    />
  );
};
