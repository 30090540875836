import React from 'react';
import moment from 'moment';

import { mapDOCUMENT_ACTIONS_ERROR, PUBLICATION_MODES } from 'js/constraints';

// Components
import { DocumentAtrributesForm } from '../DocumentAtrributesForm';
import { DocumentSigningForm } from '../DocumentSigningForm';
import { ErrorModal } from 'js/components/ErrorModal';
import { ExportableErrorContent } from 'js/components/ExportableErrorContent';

// Hooks
import { usePublishDocument, usePublishSignedDocument } from '../../hooks';

export const TwoStepPublication = ({
  onCreateIdentity,
  onCreateCategory,
  isPreparedToSign,
  onCancel,
  initialValues,
  onSuccess,
}) => {
  const [step, setStep] = React.useState(isPreparedToSign ? 2 : 1);
  const [jobId, setJobId] = React.useState(
    isPreparedToSign ? initialValues.jobId : null,
  );
  const [documentTitle, setDocumentTitle] = React.useState(
    isPreparedToSign ? initialValues.title : null,
  );

  const [error, setError] = React.useState(null);

  const { mutate: prepareDocument } = usePublishDocument({
    onSuccess: (data) => {
      setJobId(data.jobId);
      setStep(step + 1);
    },
    onError: (error) => setError(error.response.data),
  });

  const { mutate: publishDocument } = usePublishSignedDocument({
    onSuccess: () => {
      onSuccess();
      // queryClient.invalidateQueries([QueryKeys.DOCUMENT_LIST]); // should be used when document table will be refactored
    },
    onError: (error) => setError(error.response.data),
  });

  if (error) {
    return (
      <ErrorModal
        isOpen
        toggle={onCancel}
        onClose={onCancel}
        closeIconEnabled={false}
      >
        <ExportableErrorContent
          headerTitleId={
            step === 1
              ? mapDOCUMENT_ACTIONS_ERROR.PREPARE
              : mapDOCUMENT_ACTIONS_ERROR.PUBLISH
          }
          statusCode={error.statusCode}
          jobId={jobId}
          title={documentTitle}
        />
      </ErrorModal>
    );
  }

  const onPrepareDocument = (values) => {
    setDocumentTitle(values.title);

    prepareDocument({
      data: {
        publicationMode: PUBLICATION_MODES.NEW,
        title: values.title,
        identity: {
          id: values.identity.value,
        },
        validSince: moment(values.validSince).format('YYYY-MM-DDTHH:mm:ss'),
        category: {
          id: values.category.value,
        },
        file: values.file,
      },
    });
  };

  const onPublishDocument = ({ file }) => {
    publishDocument({
      id: jobId,
      data: {
        file,
      },
    });
  };

  return (
    <>
      {
        {
          1: (
            <DocumentAtrributesForm
              step={step}
              initialValues={initialValues}
              onSuccess={onPrepareDocument}
              onCreateIdentity={onCreateIdentity}
              onCreateCategory={onCreateCategory}
              submitButtonText="Prepare document"
            />
          ),
          2: (
            <DocumentSigningForm
              initialValues={initialValues}
              onSuccess={onPublishDocument}
              documentTitle={documentTitle}
              jobId={jobId}
            />
          ),
        }[step]
      }
    </>
  );
};
