import React from 'react';
import { Modal as ModalModule } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { map_PUBLICATION_MODES } from 'js/constraints';

const { Modal } = ModalModule;

const UpdateModal = (props) => {

  const title = map_PUBLICATION_MODES[props.mode];

  return (
    <Modal
      {...props}
      title={title && <FormattedMessage id={title} defaultMessage={title} />}
    >
      {props.children}
    </Modal>
  );
};

export default UpdateModal;
