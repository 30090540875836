import * as yup from 'yup';
import { useIntl } from 'react-intl';

import { VALIDATION_ERRORS, mapVALIDATION_USER_ERRORS } from 'js/constraints';

import { Utils } from 'billon-ui';
import { validateEmptyPhoneNumber } from '../../../../helpers/validateEmptyPhoneNumber';

const { validators } = Utils;

export const useSchema = ({ isPhoneNumberRequired }) => {
  const { formatMessage } = useIntl();

  return yup.object({
    username: yup.string().required(
      formatMessage({
        id: mapVALIDATION_USER_ERRORS.EMPTY_USERNAME,
        defaultMessage: mapVALIDATION_USER_ERRORS.EMPTY_USERNAME,
      }),
    ),
    firstName: yup.string().required(
      formatMessage({
        id: mapVALIDATION_USER_ERRORS.EMPTY_FIRST_NAME,
        defaultMessage: mapVALIDATION_USER_ERRORS.EMPTY_FIRST_NAME,
      }),
    ),
    lastName: yup.string().required(
      formatMessage({
        id: mapVALIDATION_USER_ERRORS.EMPTY_LAST_NAME,
        defaultMessage: mapVALIDATION_USER_ERRORS.EMPTY_LAST_NAME,
      }),
    ),
    phoneNumber: yup
      .string()
      .test(
        'emptyPhoneNumber',
        formatMessage({
          id: VALIDATION_ERRORS.PHONE_INVALID,
          defaultMessage: VALIDATION_ERRORS.PHONE_INVALID,
        }),
        (v) => {
          const isEmpty = validateEmptyPhoneNumber(v);
          if (isEmpty) {
            return true;
          } else {
            return !validators.phoneVerification(v);
          }
        },
      )
      .when('isPhoneNumberRequired', {
        is: isPhoneNumberRequired,
        then: yup.string().required(
          formatMessage({
            id: mapVALIDATION_USER_ERRORS.EMPTY_PHONE_NUMBER,
            defaultMessage: mapVALIDATION_USER_ERRORS.EMPTY_PHONE_NUMBER,
          }),
        ),
      }),

    email: yup
      .string()
      .test(
        'email',
        formatMessage({
          id: VALIDATION_ERRORS.EMAIL_INVALID,
          defaultMessage: VALIDATION_ERRORS.EMAIL_INVALID,
        }),
        (v) => !validators.emailValidator(v),
      )
      .required(
        formatMessage({
          id: mapVALIDATION_USER_ERRORS.EMPTY_EMAIL,
          defaultMessage: mapVALIDATION_USER_ERRORS.EMPTY_EMAIL,
        }),
      ),
  });
};
