import React from 'react';
import _ from 'lodash';

// Hooks
import { useIdentityList } from 'js/modules/Recipients/hooks/useIdentityList';

// Helpers
import { removeUndefinedValues } from 'js/helpers/removeUndefinedValues';

export const useIdentitiesOptions = (valueName = 'id') => {
  const [identityDocumentNumber, setIdentityDocumentNumber] =
    React.useState(undefined);

  const setSearchTextDebounced = React.useRef(
    _.debounce((callback) => callback(), 700),
  ).current;

  const { data: identitiesData, isFetching: isIdentitiesFetching } =
    useIdentityList(
      {
        filters: removeUndefinedValues({
          documentNumber: identityDocumentNumber,
        }),
        pagination: {
          limit: 20,
        },
      },
      {
        refetchOnMount: false,
      },
    );

  const { rows: identitiesRows } = identitiesData || {};

  const identities = React.useMemo(
    () =>
      (identitiesRows || []).reduce((arr, student) => {
        if (student.isActive && !student.isGdprSuspended) {
          arr.push({
            value: student[valueName],
            label: `${student.documentNumber} ${student.firstName} ${student.lastName}`,
          });
        }
        return arr;
      }, []),
    [identitiesRows, valueName],
  );

  const onChangeIdentityInput = (inputText) => {
    if (inputText !== identityDocumentNumber) {
      setSearchTextDebounced(() => setIdentityDocumentNumber(inputText));
    }
  };
  return { identities, isIdentitiesFetching, onChangeIdentityInput };
};
