import React, { useEffect } from 'react';
import { Layout as LayoutModule } from 'billon-ui';
import { getSearchParams } from 'js/components/CommonTable/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { requestList } from '../../../Documents/actions';
import { FormattedMessage } from 'react-intl';
import DocumentTable from '../../containers/DocumentTable';
import ContentContainerGrey from 'js/ui/ContentContainerGrey';
import PageHeader from 'js/ui/PageHeader';
import SearchBar from 'js/ui/SearchBar';
import ExecuteRodoTooltip from '../../components/ExecuteRodoTooltip';

const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

const HomePage = () => {
  const dispatch = useDispatch();

  const { limit } = useSelector(({ config }: any) => ({
    limit: config.limit as number,
  }));
  const { filters: rawSearchParams } = useParams<{ filters: string }>();
  const searchParams = getSearchParams(rawSearchParams, limit);

  useEffect(() => {
    dispatch(
      requestList({
        searchParams,
        include: ['category', 'publishedBy', 'recipients'],
      }),
    );
  }, [dispatch]);

  return (
    <ContentContainerGrey fluid>
      <Content fluid>
        <PageHeader
          searchBar={<SearchBar />}
          searchBarLabel={
            <FormattedMessage
              id="Search for person"
              defaultMessage="Search for person"
            />
          }
          title={
            <FormattedMessage id="Execute RODO" defaultMessage="Execute RODO" />
          }
          toolTip={<ExecuteRodoTooltip />}
        />
        <DocumentTable />
      </Content>
    </ContentContainerGrey>
  );
};

export default HomePage;
