import { Grid, Utils } from 'billon-ui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { BlockchainVisual } from '../../../../components';
import { AGREEMENT_STATUS } from '../../../../constraints';
import {
  breakWordsFormatter,
  momentFormatter,
  statusFormatter,
} from '../../../../formatters';

const { Row, Col } = Grid;
const { Button: ButtonModule, Text, Icon } = Utils;
const { Button, BackButton, ButtonLoader } = ButtonModule;

const MyButton = styled.button`
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  margin-left: 1rem;

  &:hover,
  &:focus {
    outline: none;
  }
`;

const CopiedInfo = styled.div`
  position: absolute;
  font-size: ${(props) => props.theme.fontSize12};
  background-color: #acbbc7;
  color: #fff;
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 0.25rem 1.5rem;
  top: -2.5rem;
  left: 50%;
  margin-left: -57px;
  transition: ${(props) => props.theme.transitionBase};
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

class RemoteSignDetailsData extends Component {
  state = {
    copied: false,
    copyHover: false,
  };

  constructor(props) {
    super(props);

    this.onCopied = this.onCopied.bind(this);
  }

  onCopied() {
    this.setState({
      copied: true,
    });

    setTimeout(() => this.setState({ copied: false }), 2000);
  }

  render() {
    const {
      document,
      fileDownload,
      isDownloading,
      isResendingAuthorizationCodes,
      handleResendAuthorizationCodes,
      documentPreviewUrl,
      transferedBy,
      publisherName,
    } = this.props;

    if (!document) {
      return null;
    }

    // const publishedByData = administrators.filter((el) => el.login === document.publishedBy)[0];

    return (
      <Row margin={0}>
        <Col xl={9}>
          <Row margin={'0 0 20px 0 '}>
            <Link to="/documents">
              <BackButton>
                <FormattedMessage id="Agreements" defaultMessage="Agreements" />
              </BackButton>
            </Link>
          </Row>
          <Row margin={0}>
            <Col
              padding={'0 0 40px 0 '}
              xl={5}
              css={`
                border-right: 1px solid #abc4c661;
                @media (max-width: 1199px) {
                  border-right: 0px;
                }
              `}
            >
              <Text
                as={'h1'}
                fontSize={'20px'}
                margin={'0 0 40px 0'}
                fontWeight={700}
              >
                {document.title}
              </Text>
              <Text fontSize={'13px'} margin={0} fontWeight={700}>
                <FormattedMessage
                  id="Document blockchain address"
                  defaultMessage="Document blockchain address"
                />
                <CopyToClipboard
                  text={document.documentBlockchainAddress}
                  onCopy={this.onCopied}
                >
                  <MyButton
                    onMouseOver={() => this.setState({ copyHover: true })}
                    onMouseOut={() => this.setState({ copyHover: false })}
                  >
                    <CopiedInfo show={this.state.copied}>
                      <FormattedMessage id="Copied" defaultMessage="Copied" />
                    </CopiedInfo>

                    <CopiedInfo
                      show={this.state.copied ? false : this.state.copyHover}
                    >
                      <FormattedMessage id="Copy" defaultMessage="Copy" />
                    </CopiedInfo>
                    <Icon name="copy" regular color="#7e7f7f" />
                  </MyButton>
                </CopyToClipboard>
              </Text>
              <Text fontSize={'13px'} fontWeight={500} padding="0 32px 0 0">
                {breakWordsFormatter(document.documentBlockchainAddress)}
              </Text>
              <br />

              <Col padding={0} xl={11}>
                {!isDownloading.includes(document.documentBlockchainAddress) ? (
                  <Button
                    billonIcon
                    icon="download"
                    block
                    onClick={() =>
                      fileDownload(
                        document.documentBlockchainAddress,
                        document.filePath,
                      )
                    }
                  >
                    {' '}
                    <FormattedMessage id="Download" defaultMessage="Download" />
                  </Button>
                ) : (
                  <ButtonLoader block />
                )}
              </Col>

              {document.agreementStatus ===
                AGREEMENT_STATUS.WAITING_FOR_RECEIVER && (
                <Row margin={'10px 0'}>
                  <Col margin={'0 0 10px 0'} padding={0} xl={11}>
                    {!isResendingAuthorizationCodes ? (
                      <Button
                        block
                        outline
                        onClick={() =>
                          handleResendAuthorizationCodes(
                            document.documentBlockchainAddress,
                          )
                        }
                      >
                        <FormattedMessage
                          id="Send access code and link to the document"
                          defaultMessage="Send access code and link to the document"
                        />
                      </Button>
                    ) : (
                      <ButtonLoader block />
                    )}
                  </Col>
                </Row>
              )}
            </Col>
            <Col
              padding={'0 0 40px 10px'}
              xl={4}
              lg={6}
              md={6}
              css={`
                border-right: 1px solid #abc4c661;
                @media (max-width: 1199px) {
                  border-right: 0px;
                }
              `}
            >
              <Row margin={0}>
                <Text margin={'0 10px 0 0'} fontSize={'12px'} fontWeight={700}>
                  <FormattedMessage
                    id="Date of transfer"
                    defaultMessage="Date of transfer"
                  />
                  :
                </Text>
                <Text fontSize={'12px'}>
                  {momentFormatter(document.publicationDate)}
                </Text>
              </Row>
              <Row margin={0}>
                <Text margin={'0 10px 0 0'} fontSize={'12px'} fontWeight={700}>
                  <FormattedMessage id="Category" defaultMessage="Category" />:
                </Text>
                <Text fontSize={'12px'}>{document.category.path}</Text>
              </Row>
              <Row margin={0}>
                <Text margin={'0 10px 0 0'} fontSize={'12px'} fontWeight={700}>
                  <FormattedMessage
                    id="Transfer by"
                    defaultMessage="Transfer by"
                  />
                  :
                </Text>
                <Text fontSize={'12px'}>{transferedBy}</Text>
                {/* <Text fontSize={"12px"}>{ publishedByData && publishedByData.firstName} { publishedByData && publishedByData.lastName}</Text> */}
              </Row>
              <Row margin={0}>
                <Text margin={'0 10px 0 0'} fontSize={'12px'} fontWeight={700}>
                  <FormattedMessage
                    id="Published by"
                    defaultMessage="Published by"
                  />
                  :
                </Text>
                <Text fontSize={'12px'}>{publisherName}</Text>
              </Row>
              <Row margin={0}>
                <Text margin={'0 10px 0 0'} fontSize={'12px'} fontWeight={700}>
                  <FormattedMessage id="Signed by" defaultMessage="Signed by" />
                  :
                </Text>
                <Text fontSize={'12px'}>{document.signedBy}</Text>
              </Row>
              <Row margin={0}>
                <Text margin={'0 10px 0 0'} fontSize={'12px'} fontWeight={700}>
                  <FormattedMessage
                    id="Stored for"
                    defaultMessage="Stored for"
                  />
                  :
                </Text>
                <Text fontSize={'12px'}>
                  {momentFormatter(document.retainUntil)}
                </Text>
              </Row>{' '}
            </Col>

            <Col padding={'0 0 40px 10px'} xl={3} lg={6} md={6}>
              <Row margin={0}>
                <Text margin={'0 10px 0 0'} fontSize={'12px'} fontWeight={700}>
                  <FormattedMessage id="Recipient" defaultMessage="Recipient" />
                  :
                </Text>
                <Text fontSize={'12px'}>
                  {document.recipient.name} <br />{' '}
                  {document.recipient.phoneNumber} <br />{' '}
                  {document.recipient.email}
                </Text>
              </Row>{' '}
              <Row margin={0}>
                <Text margin={'0 10px 0 0'} fontSize={'12px'} fontWeight={700}>
                  <FormattedMessage
                    id="Recipient id"
                    defaultMessage="Recipient id"
                  />
                  :
                </Text>
                <Text fontSize={'12px'}>{document.recipient.id}</Text>
              </Row>
              <Row margin={0}>
                <Text margin={'0 10px 0 0'} fontSize={'12px'} fontWeight={700}>
                  <FormattedMessage
                    id="Process status"
                    defaultMessage="Process status"
                  />
                  :
                </Text>
                <Text fontSize={'12px'}>
                  {statusFormatter(document.agreementStatus)}
                </Text>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col
          xl={{ size: 3, offset: 0 }}
          lg={{ size: 6, offset: 3 }}
          sm={{ size: 8, offset: 2 }}
        >
          <BlockchainVisual remoteSignAnimation size="100%" fixed />
        </Col>{' '}
      </Row>
    );
  }
}

RemoteSignDetailsData.propTypes = {
  document: PropTypes.object.isRequired,
  fileDownload: PropTypes.func.isRequired,
  isDownloading: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  isResendingAuthorizationCodes: PropTypes.bool.isRequired,
  handleResendAuthorizationCodes: PropTypes.func.isRequired,
  transferedBy: PropTypes.string.isRequired,
  publisherName: PropTypes.string.isRequired,
};

export default withRouter(RemoteSignDetailsData);
