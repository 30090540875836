import React from 'react';
import { DailyReportTable } from '../../components';
import ContentContainerGrey from 'js/ui/ContentContainerGrey';

const HomePage = () => {
  return (
    <ContentContainerGrey fluid>
      <DailyReportTable />
    </ContentContainerGrey>
  );
};

export default HomePage;
