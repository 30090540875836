import {
  Grid,
  Layout as LayoutModule,
  Table as TableModule,
  Utils,
} from 'billon-ui';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';
import {
  FILTERS_MAP_MVP as FILTERS_MAP,
  SETTINGS_CONSTANTS,
} from '../../../../constraints';
import * as formatters from '../../../../formatters';
import { requestList as requestCategoriesList } from '../../../Categories/actions';
import { requestList as requestRecipientsList } from '../../../Recipients/actions';
import {
  applyFilters as applyFiltersAction,
  requestDownload,
  requestDownloadExcelReport,
  requestList,
} from '../../actions';
import AnalyticsFilterForm from '../AnalyticsFilterForm';

const { Table, TableColumn, TableCell, AbstractTable } = TableModule;

const { Row, Col } = Grid;
const { Content: ContentModule, PageHeader } = LayoutModule;
const { Content } = ContentModule;
const {
  Loader: LoaderModule,
  Pagination,
  helpers,
  Button: ButtonModule,
  Icon,
  Card,
} = Utils;
const { Loader, PageLoader } = LoaderModule;
const { getNumberOfPages } = helpers;
const { Button } = ButtonModule;

const StyledCloseFilterButton = styled(Button)`
  /* height: 34px;  */
  border-radius: 65px;
  padding: 0.5rem 0 0.5rem 1.5rem;
  margin: 0.5rem 0.5rem 0;
  svg {
    margin-left: 1rem;
  }
`;

const StyledFiltersWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  margin-bottom: 30px;
  margin-right: -3rem;
`;

const StyledMyButton = styled.button`
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  margin-left: 1rem;
  /* padding-left: 1rem; */
  /* border-left: 1px solid ${(props) => props.theme.palette.grayLighter}; */

  &:hover,
  &:focus {
    outline: none;
  }
`;

const CopiedInfo = styled.div`
  position: absolute;
  font-size: ${(props) => props.theme.fontSize12};
  background-color: #acbbc7;
  color: #fff;
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 0.25rem 1.5rem;
  top: -2.5rem;
  left: 50%;
  margin-left: -57px;
  transition: ${(props) => props.theme.transitionBase};
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

const DocumentInfo = styled.div`
  .info-col {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .border-right {
    border-right: 1px solid ${(props) => props.theme.palette.grayLight};
  }

  label.large {
    display: block;
    margin-bottom: 0;
  }

  .blockchain-address-span {
    word-wrap: break-word;
    font-weight: 600;
  }

  .status-span {
    font-weight: 600;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      font-size: ${(props) => props.theme.fontSizeSm};
      margin-bottom: 1rem;

      label {
        display: block;
        font-weight: ${(props) => props.theme.fontWeightBold};
        margin-bottom: 0;
      }
    }
  }
`;

const TC = styled(TableCell)`
  white-space: nowrap;
  font-weight: ${(props) =>
    props.row.isNewlyPublished
      ? props.theme.fontWeightBold
      : props.theme.fontWeightBase};
`;

const StyledPrevButton = styled(Button)`
  padding: 0;
  padding-top: 9px;
  padding-left: 20px;
  svg {
    color: #212122;
  }
`;

class Analytics extends AbstractTable {
  componentDidMount() {
    const { loadCategories, loadRecipients } = this.props;

    loadCategories();
    loadRecipients();
  }

  getDefaultFilters() {
    return {
      ...super.getDefaultFilters(),
      type: { value: 'ALL' },
    };
  }

  handleRemoveFilter = (filter) => {
    const filters = this.getFilters();

    filters[filter] = undefined;

    this.applyFilters({
      ...filters,
      page: undefined,
      limit: undefined,
    });
  };

  handleDownloadReport = () => {
    const filters = this.getFilters();

    this.props.downloadExcelReport(filters);
  };

  makePreview = (documentBlockchainAddress, jobId, onlyBlob) => {
    this.props.fileDownload(documentBlockchainAddress, jobId, onlyBlob);
  };

  downloadFormatter = (address, row) => {
    const { fileDownload, isDownloading } = this.props;

    if (isDownloading.includes(address || row.jobId)) {
      return <Loader width={36} />;
    }

    return (
      <Button
        color="link"
        className="ignore-row-click"
        onClick={() => fileDownload(address, row.jobId)}
      >
        <Icon billon name="pdf" />
      </Button>
    );
  };

  previewFormatter = (address, row) => {
    const { isDownloading } = this.props;

    if (isDownloading.includes(address || row.jobId)) {
      return <Loader width={36} />;
    }
    if (row && row.jobId) {
    }
    return (
      <StyledPrevButton
        color="link"
        className="ignore-row-click"
        onClick={() =>
          this.makePreview(row.documentBlockchainAddress, row.jobId, true)
        }
      >
        <Icon name="eye" />
      </StyledPrevButton>
    );
  };

  renderDetails = (record) => {
    const { settings } = this.props;

    if (!record.documentBlockchainAddress && !record.jobId) {
      return null;
    }
    let documentPreviewUrl = settings[SETTINGS_CONSTANTS.DOCUMENT_PREVIEW_URL];

    return (
      <DocumentInfo>
        <Row noGutters margin={0}>
          <Col lg={4} css="margin-top: 1rem; margin-bottom: 1rem">
            {record.documentBlockchainAddress ? (
              <>
                <label className="large">
                  <FormattedMessage
                    id="Blockchain address"
                    defaultMessage="Blockchain address"
                  />
                  :
                  <CopyToClipboard
                    text={record.documentBlockchainAddress}
                    onCopy={this.onCopied}
                  >
                    <StyledMyButton
                      onMouseOver={() => this.setState({ copyHover: true })}
                      onMouseOut={() => this.setState({ copyHover: false })}
                    >
                      <CopiedInfo show={this.state.copied}>
                        <FormattedMessage id="Copied" defaultMessage="Copied" />
                      </CopiedInfo>

                      <CopiedInfo
                        show={this.state.copied ? false : this.state.copyHover}
                      >
                        <FormattedMessage id="Copy" defaultMessage="Copy" />
                      </CopiedInfo>
                      <Icon name="copy" regular color="#7e7f7f" />
                    </StyledMyButton>
                  </CopyToClipboard>
                </label>
                <span class="blockchain-address-span">
                  {record.documentBlockchainAddress}
                </span>
              </>
            ) : (
              <label className="large">
                <FormattedMessage
                  id="Document does not yet have a blockchain address"
                  defaultMessage="Document does not yet have a blockchain address"
                />
              </label>
            )}
          </Col>
        </Row>
      </DocumentInfo>
    );
  };

  render() {
    const filters = this.getFilters();

    const importantFilters = Object.keys(filters).filter(
      (filter) => filter !== 'limit' && filter !== 'page' && filter !== 'type',
    );

    const { records, isFetching, numberOfRecords, categories, customer } =
      this.props;

    if (isFetching) {
      return (
        <Content fluid>
          <PageLoader />
        </Content>
      );
    }
    return (
      <>
        <AnalyticsFilterForm
          initialValues={filters}
          categories={categories}
          customer={customer}
        />
        {importantFilters.length > 0 && filters && (
          <StyledFiltersWrapper>
            {importantFilters.map(
              (filter) =>
                filters[filter] && (
                  <StyledCloseFilterButton
                    outline
                    fontSize="12px"
                    fontWeight={600}
                    key={filter}
                    onClick={() => this.handleRemoveFilter(filter)}
                  >
                    {filter === 'category' ||
                    filter === 'statusList' ||
                    filter === 'type' ? (
                      <>
                        <FormattedMessage
                          id={FILTERS_MAP[filter]}
                          defaultMessage={FILTERS_MAP[filter]}
                        />
                        {': '}
                        {filters[filter].label}
                      </>
                    ) : filter === 'validFromTo' ||
                      filter === 'publicationDate' ? (
                      <>
                        <FormattedMessage
                          id={FILTERS_MAP[filter]}
                          defaultMessage={FILTERS_MAP[filter]}
                        />
                        {': '} &nbsp;
                        {formatters.momentFormatterWithoutTime(
                          filters[filter][0],
                        )}{' '}
                        &nbsp;-&nbsp;{' '}
                        {formatters.momentFormatterWithoutTime(
                          filters[filter][1],
                        )}
                      </>
                    ) : (
                      <>
                        <FormattedMessage
                          id={FILTERS_MAP[filter]}
                          defaultMessage={FILTERS_MAP[filter]}
                        />
                        {': '}
                        {filters[filter]}
                      </>
                    )}
                    <Icon name="times-circle" regular />
                  </StyledCloseFilterButton>
                ),
            )}
          </StyledFiltersWrapper>
        )}
        <Row>
          <Col md={6}>
            {' '}
            <PageHeader classic>
              <FormattedMessage
                id="Generated report"
                defaultMessage="Generated report"
              />
            </PageHeader>
          </Col>
          <Col md={3}></Col>
          <Col
            md={3}
            css={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button
              width="100%"
              className="button-static"
              fontWeight={500}
              padding="0.5rem 3.5rem;"
              onClick={this.handleDownloadReport}
            >
              <FormattedMessage
                id="Download report in xls"
                defaultMessage="Download report in xls"
              />
            </Button>
          </Col>
        </Row>

        <Card>
          <Table
            responsive
            tableData={records}
            detailsComponent={this.renderDetails}
          >
            <TableColumn
              fieldName="title"
              formatter={formatters.boundLengthFormatterLong}
              renderCell={(row, props) => <TC row={row} {...props} />}
            >
              <FormattedMessage id="Title" defaultMessage="Title" />
            </TableColumn>
            <TableColumn
              fieldName="publicationDate"
              formatter={formatters.momentFormatter}
              renderCell={(row, props) => <TC row={row} {...props} />}
            >
              <FormattedMessage
                id="Publication date"
                defaultMessage="Publication date"
              />
            </TableColumn>
            <TableColumn
              fieldName="category.path"
              formatter={formatters.boundLengthFormatterLong}
              renderCell={(row, props) => <TC row={row} {...props} />}
            >
              <FormattedMessage id="Category" defaultMessage="Category" />
            </TableColumn>
            <TableColumn
              fieldName="validSince"
              formatter={formatters.momentFormatterWithoutTime}
              renderCell={(row, props) => <TC row={row} {...props} />}
            >
              <FormattedMessage id="Valid since" defaultMessage="Valid since" />
            </TableColumn>
            <TableColumn
              fieldName="validUntil"
              formatter={formatters.momentFormatterWithoutTime}
              renderCell={(row, props) => <TC row={row} {...props} />}
            >
              <FormattedMessage id="Valid until" defaultMessage="Valid until" />
            </TableColumn>

            <TableColumn
              fieldName="recipients"
              formatter={formatters.recipientsFormatter}
              renderCell={(row, props) => <TC row={row} {...props} />}
            >
              <FormattedMessage
                id="Published to"
                defaultMessage="Published to"
              />
            </TableColumn>
            <TableColumn
              fieldName="status"
              formatter={formatters.statusFormatterMVP}
              renderCell={(row, props) => <TC row={row} {...props} />}
            >
              <FormattedMessage id="Status" defaultMessage="Status" />
            </TableColumn>

            <TableColumn
              className="text-center fixed-width"
              fieldName="Preview"
              formatter={this.previewFormatter}
              renderCell={(row, props) => <TC row={row} {...props} />}
            >
              <FormattedMessage id="Preview" defaultMessage="Preview" />
            </TableColumn>
            <TableColumn
              className="text-center fixed-width"
              fieldName="documentBlockchainAddress"
              formatter={this.downloadFormatter}
              renderCell={(row, props) => <TC row={row} {...props} />}
            >
              <FormattedMessage id="Download" defaultMessage="Download" />
            </TableColumn>
          </Table>
          <Pagination
            onClick={this.handlePageChange}
            numberOfPages={getNumberOfPages(numberOfRecords, filters.limit)}
            currentPage={filters.page}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
          />
        </Card>
        <br />
      </>
    );
  }
}

const mapStateToProps = ({ reports, config, category, settings }) => ({
  records: reports.records,
  isFetching: reports.isFetching,
  numberOfRecords: reports.numberOfRecords,
  isDownloading: reports.isDownloading,
  customer: config.customer,
  limit: config.limit,
  enablePrivate: config.enablePrivate,
  categories: category.records.map((v) => ({ label: v.path, value: v.path })),
  settings: settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
  load: (filters) =>
    dispatch(
      requestList({
        ...filters,
        order: { publicationDate: 'DESC' },
        include: ['category', 'publishedBy', 'recipients'],
      }),
    ),
  loadCategories: () => dispatch(requestCategoriesList({ isActive: true })),
  loadRecipients: () => dispatch(requestRecipientsList({ isActive: true })),
  applyFilters: (history, filters) => applyFiltersAction(history, filters),
  fileDownload: (address, jobId, returnOnlyBlob) =>
    dispatch(requestDownload(address, jobId, returnOnlyBlob)),
  downloadExcelReport: (data) => dispatch(requestDownloadExcelReport(data)),
});

export default withRouter(
  reduxForm({
    form: 'documentTableForm',
  })(connect(mapStateToProps, mapDispatchToProps)(Analytics)),
);
