import { Utils } from 'billon-ui';
import { isUndefined } from 'lodash';

const { helpers } = Utils;
const { parseFilters: superParseFilters } = helpers;

// eslint-disable-next-line import/prefer-default-export
export const parseFilters = (filters) => {
  const output = superParseFilters(filters);

  if (filters.include) {
    output.include = filters.include;
  }

  if (filters.searchBar) {
    output.where['Op.or'] = [
      {
        externalId: filters.searchBar,
      },
      {
        phoneNumber: {
          'Op.like': `%${filters.searchBar}%`,
        },
      },
      {
        firstName: {
          'Op.like': `%${filters.searchBar}%`,
        },
      },
      {
        lastName: {
          'Op.like': `%${filters.searchBar}%`,
        },
      },
      {
        email: {
          'Op.like': `%${filters.searchBar}%`,
        },
      },
    ];
  }

  if (!isUndefined(filters.isActive)) {
    output.where.isActive = filters.isActive;
  }

  return output;
};

export const parseSave = (values) => {
  const output = values;

  if (values.phoneNumber) {
    output.phoneNumber = values.phoneNumber.replace(/[\s]+/g, '');
  } else {
    output.phoneNumber = undefined;
  }

  return output;
};

export const parseUpload = (values) => {
  const formData = new FormData();

  formData.append('file', values.file[0]);

  return formData;
};
