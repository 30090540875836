import styled from 'styled-components';
import { Utils, Grid } from 'billon-ui';
const { Button, ButtonLoader } = Utils.Button;
const { Col } = Grid;

export const FullHeightBtn = styled(Button)`
  height: 100%;
  overflow: hidden;
`;

export const FullHeightButtonLoader = styled(ButtonLoader)`
  height: 100%;
`;

export const BtnCol = styled(Col)`
  margin-bottom: 16px;
`;
