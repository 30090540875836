import moment from 'moment';
import { DOCUMENT_TYPE, VALIDATION_ERRORS } from '../../../../../constraints';

export default (values, props) => {
  const errors = {};

  if (
    values.validFromTo &&
    values.validFromTo.length &&
    values.validFromTo[0]
  ) {
    values.validSince = values.validFromTo[0];
  }
  if (
    values.validFromTo &&
    values.validFromTo.length &&
    values.validFromTo[1]
  ) {
    values.validUntil = values.validFromTo[1];
  }

  if (!values) {
    return null;
  }

  if (!values.title) {
    errors.title = VALIDATION_ERRORS.REQUIRED;
  } else if (values.title.length > 128) {
    errors.title = VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED.replace(
      '$length',
      128,
    );
  }

  if (!values.user) {
    errors.user = VALIDATION_ERRORS.REQUIRED;
  }

  if (!values.categoryId || !values.categoryId.value) {
    errors.categoryId = VALIDATION_ERRORS.REQUIRED;
  }

  if (
    !values.validFromTo ||
    !values.validFromTo.length ||
    !values.validFromTo[0] ||
    !values.validFromTo[1]
  ) {
    errors.validFromTo = VALIDATION_ERRORS.REQUIRED;
  }

  if (!values.validSince) {
    errors.validSince = VALIDATION_ERRORS.REQUIRED;
  } else if (
    moment(values.validSince) < moment('1900-01-01') ||
    moment(values.validSince) > moment('2500-12-31')
  ) {
    errors.validSince = VALIDATION_ERRORS.DATE_BETWEEN_1900_2500;
  }

  if (!values.validUntil) {
    errors.validUntil = VALIDATION_ERRORS.REQUIRED;
  } else if (
    !moment(values.validUntil).isSameOrAfter(moment(values.validSince))
  ) {
    errors.validUntil = VALIDATION_ERRORS.AFTER_OR_SAME_VALID_SINCE_DATE;
  } else if (
    moment(values.validUntil) < moment('1900-01-01') ||
    moment(values.validUntil) > moment('2500-12-31')
  ) {
    errors.validUntil = VALIDATION_ERRORS.DATE_BETWEEN_1900_2500;
  }

  // * old version with DatePicker
  /*
  if (!values.retainUntil) {
    errors.retainUntil = VALIDATION_ERRORS.REQUIRED;
  } else if (
    !moment(values.retainUntil).isSameOrAfter(moment(values.validUntil))
  ) {
    errors.retainUntil = VALIDATION_ERRORS.AFTER_OR_SAME_VALID_UNTIL_DATE;
  } else if (
    moment(values.retainUntil) < moment("1900-01-01") ||
    moment(values.retainUntil) > moment("2500-12-31")
  ) {
    errors.retainUntil = VALIDATION_ERRORS.DATE_BETWEEN_1900_2500;
  } else if (moment(values.retainUntil) < moment()) {
    errors.retainUntil = VALIDATION_ERRORS.FUTURE_DATE;
  }
  */

  if (!values.retainUntil || !values.retainUntil.value) {
    errors.retainUntil = VALIDATION_ERRORS.REQUIRED;
  }

  if (!values.file) {
    errors.file = VALIDATION_ERRORS.REQUIRED;
  } else if (
    values.file[0] &&
    values.file[0].size &&
    values.file[0].size > 2 * 1024 * 1024
  ) {
    errors.file = VALIDATION_ERRORS.FILE_SIZE_TOO_LARGE;
  }

  if (
    values.recipientsToNotify &&
    values.recipientsToNotify[0] &&
    values.recipientsToNotify[0].size &&
    values.recipientsToNotify[0].size > 2 * 1024 * 1024
  ) {
    errors.recipientsToNotify = VALIDATION_ERRORS.FILE_SIZE_TOO_LARGE;
  }

  if (!values.documentType) {
    errors.documentType = VALIDATION_ERRORS.REQUIRED;
  } else if (values.documentType.value === DOCUMENT_TYPE.PRIVATE) {
    if (!values.recipient) {
      errors.recipient = VALIDATION_ERRORS.REQUIRED;
    }
  }

  if (
    values.versionName &&
    values.versionName === props.initialValues.versionName &&
    !props.initialValues.errata
  ) {
    errors.versionName = VALIDATION_ERRORS.SAME_AS_PREVIOUS;
  }

  if (props.initialValues.versionName && !values.versionName) {
    errors.versionName = VALIDATION_ERRORS.REQUIRED;
  }

  return errors;
};
