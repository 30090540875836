import { createReducer } from 'redux-act';
import * as actions from './actions';

export default createReducer(
  {
    // List
    [actions.requestList]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.receiveListSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      records: payload.rows,
      numberOfRecords: payload.count,
      agreementsCountByStatus: payload.agreementsCountByStatus,
    }),
    [actions.receiveListFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),

    // Single
    [actions.requestSingle]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.receiveSingleSuccess]: (state, payload) => ({
      ...state,
      singleRecord: payload,
      isFetching: false,
      didFetched: true,
    }),
    [actions.receiveSingleFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),

    // prepare&publish
    [actions.requestPrepare]: (state) => ({
      ...state,
      isSaving: true,
    }),
    [actions.receivePrepareSuccess]: (state) => ({
      ...state,
      isSaving: false,
    }),
    [actions.receivePrepareFailure]: (state) => ({
      ...state,
      isSaving: false,
    }),

    [actions.requestPublish]: (state) => ({
      ...state,
      isSaving: true,
    }),
    [actions.receivePublishSuccess]: (state) => ({
      ...state,
      isSaving: false,
    }),
    [actions.receivePublishFailure]: (state) => ({
      ...state,
      isSaving: false,
    }),

    // download
    // [actions.requestDownload]: (state, payload) => ({
    //   ...state,
    //   isDownloading: state.isDownloading.concat([payload.id]),
    // }),
    // [actions.receiveDownloadSuccess]: (state, payload) => ({
    //   ...state,
    //   isDownloading: state.isDownloading.filter((v) => payload.id === v),
    // }),
    // [actions.receiveDownloadFailure]: (state, payload) => ({
    //   ...state,
    //   isDownloading: state.isDownloading.filter((v) => payload.id === v),
    // }),

    // blockchain address version
    [actions.requestDownload]: (state, payload) => ({
      ...state,
      isDownloading: state.isDownloading.concat([payload.blockchainAddress]),
    }),
    [actions.receiveDownloadSuccess]: (state, payload) => ({
      ...state,
      isDownloading: state.isDownloading.filter(
        (v) => payload.blockchainAddress === v,
      ),
    }),
    [actions.receiveDownloadFailure]: (state, payload) => ({
      ...state,
      isDownloading: state.isDownloading.filter(
        (v) => payload.blockchainAddress === v,
      ),
    }),

    [actions.requestDownloadRecipientsFile]: (state) => ({
      ...state,
      isDownloadingRecipientsList: true,
    }),
    [actions.receiveDownloadRecipientsFileSuccess]: (state) => ({
      ...state,
      isDownloadingRecipientsList: false,
    }),
    [actions.receiveDownloadRecipientsFileFailure]: (state) => ({
      ...state,
      isDownloadingRecipientsList: false,
    }),

    [actions.requestDelete]: (state) => ({
      ...state,
      isDeleting: true,
    }),
    [actions.receiveDeleteSuccess]: (state) => ({
      ...state,
      isDeleting: false,
    }),
    [actions.receiveDeleteFailure]: (state) => ({
      ...state,
      isDeleting: false,
    }),

    [actions.requestResendAuthorizationCodes]: (state) => ({
      ...state,
      isResendingAuthorizationCodes: true,
    }),
    [actions.receiveResendAuthorizationCodesSuccess]: (state) => ({
      ...state,
      isResendingAuthorizationCodes: false,
    }),
    [actions.receiveResendAuthorizationCodesFailure]: (state) => ({
      ...state,
      isResendingAuthorizationCodes: false,
    }),

    [actions.requestForgetDocument]: (state) => ({
      ...state,
      isForgetting: true,
    }),
    [actions.receiveForgetDocumentSuccess]: (state) => ({
      ...state,
      isForgetting: false,
    }),
    [actions.receiveForgetDocumentFailure]: (state) => ({
      ...state,
      isForgetting: false,
    }),
  },
  {
    isFetching: false,
    isSaving: false,
    didFetched: false,
    isDownloading: [],
    isDownloadingRecipientsList: false,
    isDeleting: false,
    records: [],
    agreementsCountByStatus: [],
    singleRecord: {},
    numberOfRecords: 0,
    isResendingAuthorizationCodes: false,
    isForgetting: false,
  },
);
