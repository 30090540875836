import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Utils, Grid } from 'billon-ui';
import { ProtectedComponent } from '../../../../../../modules/Auth/components';
import {
  UserRoles,
  CUSTOMER,
  DOCUMENT_TYPE,
} from '../../../../../../constraints';
import { LoadingActionButton } from '../../../../../../components';
import { BtnCol } from '../../../../../../components/LoadingActionButton/styled';
import { UpdateRecipientListButton } from '../UpdateRecipientListButton/UpdateRecipientListButton';

const { Text } = Utils;
const { Row } = Grid;

const ManageSection = ({
  documentType,
  customer,
  handleResendNotificationClick,
  handleNotificationHistoryModalOpen,
  handleDownloadRecipientsList,
  resendAuthorizationCode,
  isResendingAuthorizationCode,
  isDownloadingRecipientsList,
  isDownloading,
  onDownload,
}) => {
  return (
    <>
      <Text fontSize={'18px'} margin={'0 0 11px 0'} fontWeight={700}>
        <FormattedMessage
          id="Manage document"
          defaultMessage="Manage document"
        />
      </Text>
      <Row>
        <BtnCol lg={4}>
          <LoadingActionButton isLoading={isDownloading} onClick={onDownload}>
            <FormattedMessage
              id="Download document"
              defaultMessage="Download document"
            />
          </LoadingActionButton>
        </BtnCol>

        {documentType === DOCUMENT_TYPE.PRIVATE && (
          <ProtectedComponent
            roles={[UserRoles.AGENT, UserRoles.ADMIN]}
            render={false}
          >
            <BtnCol lg={4}>
              <LoadingActionButton
                isLoading={isResendingAuthorizationCode}
                onClick={resendAuthorizationCode}
                outline
              >
                <FormattedMessage
                  id="Send link and access code"
                  defaultMessage="Send link and access code"
                />
              </LoadingActionButton>
            </BtnCol>
          </ProtectedComponent>
        )}

        {documentType === DOCUMENT_TYPE.PUBLIC && (
          <BtnCol lg={4}>
            <LoadingActionButton
              isLoading={isDownloadingRecipientsList}
              onClick={handleDownloadRecipientsList}
              outline
            >
              <FormattedMessage
                id="Download the list of recipients"
                defaultMessage="Download the list of recipients"
              />
            </LoadingActionButton>
          </BtnCol>
        )}
      </Row>

      {documentType === DOCUMENT_TYPE.PUBLIC && (
        <Row>
          {customer !== CUSTOMER.DIPLOMA && (
            <BtnCol lg={4}>
              <LoadingActionButton
                onClick={handleNotificationHistoryModalOpen}
                outline
              >
                <FormattedMessage
                  id="Notification history"
                  defaultMessage="Notification history"
                />
              </LoadingActionButton>
            </BtnCol>
          )}
          <ProtectedComponent
            roles={[UserRoles.AGENT, UserRoles.ADMIN]}
            render={false}
          >
            <BtnCol lg={4}>
              <LoadingActionButton
                onClick={handleResendNotificationClick}
                outline
              >
                <FormattedMessage
                  id="Resend notification"
                  defaultMessage="Resend notification"
                />
              </LoadingActionButton>
            </BtnCol>
          </ProtectedComponent>
          <ProtectedComponent
            roles={[UserRoles.AGENT, UserRoles.ADMIN]}
            render={false}
          >
            <BtnCol lg={4}>
              <UpdateRecipientListButton />
            </BtnCol>
          </ProtectedComponent>
        </Row>
      )}
    </>
  );
};

export default ManageSection;
