import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import {
  Form as FormModule,
  Utils,
  Grid,
  Modal as ModalModule,
} from 'billon-ui';

import * as Styled from './styled';
import { QueryKeys } from '../../../../queryKeys';
import { mapERRORS } from '../../../../constraints';

// Hooks
import { useCategoryCreate } from '../../hooks/useCategoryCreate';

// Schema
import { useSchema } from './useSchema';

const { TextField, SwitchField } = FormModule;
const { Button: ButtonModule, Text } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { SuccessModal } = ModalModule;
const { Col } = Grid;

export const CreateForm = ({ parentCategory, toggle }) => {
  const queryClient = useQueryClient();
  const schema = useSchema();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      isActive: true,
      name: '',
      parentId: parentCategory?.id ?? null,
    },
    resolver: yupResolver(schema),
  });

  const {
    mutate: createCategory,
    isSuccess,
    reset,
    isLoading,
    error,
  } = useCategoryCreate({
    onSuccess: () => {
      if (parentCategory) {
        queryClient.invalidateQueries([
          QueryKeys.CATEGORY_LIST,
          { parentId: parentCategory.id },
        ]);
      } else {
        queryClient.invalidateQueries([QueryKeys.CATEGORY_LIST]);
      }
    },
  });

  const onSubmit = (values) => {
    createCategory({
      data: values,
    });
  };

  const onCloseSuccessModal = () => {
    toggle();
    reset();
  };

  let responseErrorMessage

  if(error){
    responseErrorMessage = mapERRORS[error.response.data.statusCode]
  }


  if (isSuccess) {
    return (
      <SuccessModal
        isOpen
        onClose={onCloseSuccessModal}
        title={
          <FormattedMessage
            id="Successfully saved"
            defaultMessage="Successfully saved"
          />
        }
      >
        <FormattedMessage
          id="The process of adding a category has started"
          defaultMessage="The process of adding a category has started"
        />
      </SuccessModal>
    );
  }

  return (
    <Styled.Form>
      <Text as="p" fontSize="14px" fontWeight={600} margin="-1.5rem 0 1rem 0">
        {parentCategory ? (
          <FormattedMessage
            id="Create a subcategory for existing category"
            defaultMessage="Create a subcategory for existing category"
          />
        ) : (
          <FormattedMessage
            id="Create new parent category"
            defaultMessage="Create new parent category"
          />
        )}
      </Text>
      {parentCategory && (
        <TextField
          disabled
          input={{
            value: parentCategory.name,
          }}
          meta={{
            dirty: true,
            touched: true,
          }}
          label={
            <FormattedMessage
              id="Chosen category"
              defaultMessage="Chosen category"
            />
          }
        />
      )}
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            input={field}
            meta={{
              touched: fieldState.invalid || !!responseErrorMessage,
              error: errors?.[field.name]?.message || responseErrorMessage,
            }}
            label={
              <FormattedMessage
                id="Enter category name"
                defaultMessage="Enter category name"
              />
            }
          />
        )}
      />
      <Controller
        name="isActive"
        control={control}
        render={({ field, fieldState }) => (
          <SwitchField
            inline
            input={{
              ...field,
              checked: field.value,
            }}
            label={
              <FormattedMessage
                id="Added subcategory will be (off/on)"
                defaultMessage="Added subcategory will be (off/on)"
              />
            }
            slim
            meta={{
              dirty: fieldState.isDirty,
              touched: fieldState.isTouched,
            }}
          />
        )}
      />
      <Styled.BottomWrapper>
        <Col md={{ size: 8, offset: 2 }}>
          {!isLoading ? (
            <Button
              type="submit"
              size="lg"
              block
              onClick={handleSubmit(onSubmit)}
            >
              <FormattedMessage id="Save" defaultMessage="Save" />
            </Button>
          ) : (
            <ButtonLoader size="lg" block />
          )}
        </Col>
      </Styled.BottomWrapper>
    </Styled.Form>
  );
};
