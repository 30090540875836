import React from 'react';
import { Grid, Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';

import { NotificationFilterReduxForm } from '../../containers/NotificationHistory';

import Modal from 'js/ui/Modal';
import { ModalBody } from 'js/ui/CustomConfirmModal/styled';

const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;
const { Col, Row } = Grid;

const ModalHeaderPanel = () => (
  <Row>
    <Styled.TitleContainer md={6}>
      <Styled.ModalTitle>
        <FormattedMessage
          id="Notification history"
          defaultMessage="Notification history"
        />
      </Styled.ModalTitle>
    </Styled.TitleContainer>
    <Col md={6}>
      <NotificationFilterReduxForm />
    </Col>
  </Row>
);

const NotificationHistoryModal = (props) => {
  return (
    <Modal {...props} closePadding>
      <ModalHeaderPanel />
      <ModalBody>{props.children}</ModalBody>
      <Styled.ModalFooter>
        <Styled.ZeroCol md={3}>
          <Styled.BackButton secondary onClick={props.onClose}>
            <FormattedMessage id="back" defaultMessage="back" />
          </Styled.BackButton>
        </Styled.ZeroCol>
        <Col md={6}>
          <Button size="lg" block onClick={props.onClose}>
            <FormattedMessage id="Close" defaultMessage="Close" />
          </Button>
        </Col>
        <Col md={3}></Col>
      </Styled.ModalFooter>
    </Modal>
  );
};

export default NotificationHistoryModal;
