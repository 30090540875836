import React, { useState } from 'react';
import { Layout as LayoutModule, Grid } from 'billon-ui';
import { useHistory } from 'react-router-dom';
import * as Styled from './styled';
import { FormattedMessage } from 'react-intl';
import WithValueMessage from 'js/helpers/WithValueMessage';
import { CUSTOMER, SETTINGS_CONSTANTS, USER_STATUSES } from 'js/constraints';
import { getExpirationDays } from 'js/modules/UsersNew/heplers';
import { LoadingActionButton } from '../../../../../../components';

import appRoutes from 'js/app-routes';
import config from 'js/config';
import { usePasswordExpired } from 'js/modules/UsersNew/hooks/usePasswordExpired';
import { SuccessModal as CustomSuccessModal } from 'js/components/SuccessModal';
import { ErrorModal } from 'js/components/ErrorModal';
import { BtnCol } from '../../../../../../components/LoadingActionButton/styled';

const { customer } = config;
const { Content: ContentModule } = LayoutModule;
const { ErrorContent } = ContentModule;
const { Row } = Grid;

const PasswordManager = ({ user, settings }) => {
  const history = useHistory();

  const [expSuccessModal, setExpSuccessModal] = useState(false);
  const [expErrorModal, setExpErrorModal] = useState(false);

  const passwordExpirationTimeInDays = settings?.find(
    (item) => item.key === SETTINGS_CONSTANTS.PASSWORD_EXPIRATION_TIME,
  )?.value;

  const daysToExpiration = getExpirationDays(
    user.passwordUpdatedAt,
    passwordExpirationTimeInDays,
  );

  const onPasswordChange = () => {
    if (customer === CUSTOMER.TAURON && user.id) {
      history.push(appRoutes.ADMIN_PASSWORD_CHANGE.replace(':id', user.id));
    }
  };

  const onPasswordSet = () => {
    if (customer === CUSTOMER.TAURON && user.id) {
      history.push(appRoutes.TEMPORATY_PASSWORD_SET.replace(':id', user.id));
    }
  };

  const onForcePasswordSet = () => {
    expirePassword({ id: user.id });
  };

  const handleExpSuccessClose = () => {
    setExpSuccessModal(false);
  };

  const handleExpFailureClose = () => {
    setExpErrorModal(false);
  };

  const handleEnforcePasswordExpSuccess = () => {
    setExpSuccessModal(true);
  };

  const handleEnforcePasswordExpFailure = () => {
    setExpErrorModal(true);
  };

  const { mutate: expirePassword } = usePasswordExpired({
    onSuccess: handleEnforcePasswordExpSuccess,
    onError: handleEnforcePasswordExpFailure,
  });

  return (
    <>
      <Styled.SectionHeaderContainer>
        <Styled.TextColumnContainer>
          <Styled.SectionHeaderLabel>
            <FormattedMessage
              id="Password change"
              defaultMessage="Password change"
            />
            {':'}
          </Styled.SectionHeaderLabel>
        </Styled.TextColumnContainer>
        <Styled.TextColumnContainer>
          <Styled.ExpirationLabel>
            <WithValueMessage
              messageId={'Password expires in {n} days'}
              name="n"
              value={daysToExpiration}
            />
          </Styled.ExpirationLabel>
        </Styled.TextColumnContainer>
      </Styled.SectionHeaderContainer>

      <Row>
        {user.status === USER_STATUSES.SHELL ? (
          <BtnCol lg={6}>
            <LoadingActionButton onClick={onPasswordSet} outline={true}>
              <FormattedMessage
                id="Set password"
                defaultMessage="Set password"
              />
            </LoadingActionButton>
          </BtnCol>
        ) : (
          <BtnCol lg={6}>
            <LoadingActionButton
              lg={6}
              onClick={onPasswordChange}
              outline={true}
            >
              <FormattedMessage
                id="Password change"
                defaultMessage="Password change"
              />
            </LoadingActionButton>
          </BtnCol>
        )}
        <BtnCol lg={6}>
          <LoadingActionButton
            lg={6}
            onClick={onForcePasswordSet}
            outline={true}
          >
            <FormattedMessage
              id="Force password change"
              defaultMessage="Force password change"
            />
          </LoadingActionButton>
        </BtnCol>
      </Row>

      <CustomSuccessModal
        isOpen={expSuccessModal}
        toggle={handleExpSuccessClose}
        onClose={handleExpSuccessClose}
        closeIconEnabled={false}
      >
        <FormattedMessage
          id={'Password change forced'}
          defaultMessage={'Password change forced'}
        />
      </CustomSuccessModal>

      <ErrorModal
        isOpen={expErrorModal}
        toggle={handleExpFailureClose}
        onClose={handleExpFailureClose}
        closeIconEnabled={false}
      >
        <ErrorContent>
          <FormattedMessage
            id={'Error forcing password change'}
            defaultMessage={'Error forcing password change'}
          />
        </ErrorContent>
      </ErrorModal>
    </>
  );
};

export default PasswordManager;
