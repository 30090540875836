import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Form as FormModule, Grid } from 'billon-ui';
import { Controller } from 'react-hook-form';
import * as Styled from './styled';
import config from 'js/config';
import { CUSTOMER } from 'js/constraints';

const { FormGroup, TextField, PhoneNumberField, SwitchField } = FormModule;
const { Row, Col } = Grid;
const { customer } = config;

const DataTab = ({ initialValues, control, errors }) => (
  <>
    <FormGroup>
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              input={field}
              meta={{
                dirty: fieldState.isDirty,
                touched: fieldState.invalid,
                error: errors?.[field.name]?.message,
              }}
              label={<FormattedMessage id="Email" defaultMessage="Email" />}
              placeholder="jan.kowalski@adres.com"
            />
          );
        }}
      />
    </FormGroup>
    <FormGroup>
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <PhoneNumberField
              input={field}
              meta={{
                dirty: fieldState.isDirty,
                touched: fieldState.invalid,
                error: errors?.[field.name]?.message,
              }}
              label={
                <FormattedMessage
                  id="Phone number"
                  defaultMessage="Phone number"
                />
              }
            />
          );
        }}
      />
    </FormGroup>
    <FormGroup>
      <Controller
        name="documentNumber"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              input={field}
              meta={{
                dirty: fieldState.isDirty,
                touched: fieldState.invalid,
                error: errors?.[field.name]?.message,
              }}
              label={
                {
                  [CUSTOMER.DIPLOMA]: (
                    <FormattedMessage
                      id="Index number"
                      defaultMessage="Index number"
                    />
                  ),
                  [CUSTOMER.DEFAULT]: (
                    <FormattedMessage id="Pesel" defaultMessage="Pesel" />
                  ),
                }[customer]
              }
              placeholder="88903332983"
              disabled={!!initialValues?.externalId}
            />
          );
        }}
      />
    </FormGroup>
    <FormGroup>
      <Controller
        name="firstName"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              input={field}
              meta={{
                dirty: fieldState.isDirty,
                touched: fieldState.invalid,
                error: errors?.[field.name]?.message,
              }}
              label={
                <FormattedMessage id="First name" defaultMessage="First name" />
              }
              placeholder="Jan"
            />
          );
        }}
      />
    </FormGroup>
    <FormGroup>
      <Controller
        name="lastName"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              input={field}
              meta={{
                dirty: fieldState.isDirty,
                touched: fieldState.invalid,
                error: errors?.[field.name]?.message,
              }}
              label={
                <FormattedMessage id="Last name" defaultMessage="Last name" />
              }
              placeholder="Kowalski"
            />
          );
        }}
      />
    </FormGroup>
    <FormGroup>
      <Styled.FieldFlexRow>
        <Styled.FC2>
          <FormattedMessage id="Is active?2" defaultMessage="Is active?2" />
        </Styled.FC2>
        <Styled.FC>
          <Controller
            name="isActive"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <SwitchField
                  input={{
                    ...field,
                    checked: field.value,
                  }}
                  meta={{
                    dirty: fieldState.isDirty,
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  type="checkbox"
                />
              );
            }}
          />
        </Styled.FC>
      </Styled.FieldFlexRow>
    </FormGroup>
  </>
);

DataTab.propTypes = {
  initialValues: PropTypes.object,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
};

export default DataTab;
