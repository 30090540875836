import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Layout, Grid, Utils, Table as TableModule } from 'billon-ui';
import Modal from '../../../../../../ui/Modal';
import * as Styled from './styled';
import { ModalBody } from '../../../../../../ui/CustomConfirmModal/styled';
import RecipientsFilterForm from '../RecipientsFilterForm';
import RecipientsTable from '../RecipientsTable';
import { chooseRecipientFormatter } from '../../formatters/chooseRecipientFormatter';
import { removeUndefinedValues } from '../../../../../../helpers/removeUndefinedValues';

const { Col, Row } = Grid;
const { Button: ButtonModule, Loader: LoaderModule } = Utils;
const { Button } = ButtonModule;
const { Content: ContentModule } = Layout;
const { PageLoader } = LoaderModule;
const { Content } = ContentModule;
const { TableColumn } = TableModule;

const ModalHeaderPanel = () => (
  <Row>
    <Styled.TitleContainer md={6}>
      <Styled.ModalTitle>
        <FormattedMessage
          id="Search from list"
          defaultMessage="Search from list"
        />
      </Styled.ModalTitle>
    </Styled.TitleContainer>
    <Col md={6}>
      <RecipientsFilterForm />
    </Col>
  </Row>
);

const SearchFromList = ({
  isOpen,
  isLoading,
  onClose,
  onBack,
  onNext,
  documentBlockchainAddress,
  onAppendRecipients,
  unRegisterRecipient,
  defaultValues,
  latelyRemovedRecipient,
  areStoredRecipientsEmpty,
}) => {
  const formMethods = useForm({
    defaultValues: { defaultValues, recipients: {} },
  });

  const handleFormSubmit = () => {};

  const { handleSubmit, control, watch, getValues, setValue } = formMethods;

  useEffect(() => {
    if (isEmpty(defaultValues)) {
      const keys = Object.keys(removeUndefinedValues(getValues('recipients')));
      keys.forEach((uniqueId) =>
        setValue(`recipients[${uniqueId}]`, undefined),
      );
    }
  }, [defaultValues]);

  useEffect(() => {
    if (latelyRemovedRecipient?.uniqueId) {
      setValue(`recipients[${latelyRemovedRecipient.uniqueId}]`, undefined);
    }
  }, [latelyRemovedRecipient]);

  if (isLoading) {
    return (
      <Modal
        isOpen={true}
        closeIconEnabled={true}
        onClose={onClose}
        toggle={onClose}
      >
        <Content fluid>
          <PageLoader />
        </Content>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      onClose={onClose}
      closeIconEnabled={true}
      closePadding
    >
      <ModalHeaderPanel />

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalBody>
            <RecipientsTable
              documentBlockchainAddress={documentBlockchainAddress}
            >
              <TableColumn
                fieldName="choose"
                formatter={(value, record) =>
                  chooseRecipientFormatter(
                    value,
                    record,
                    control,
                    onAppendRecipients,
                    unRegisterRecipient,
                    watch,
                  )
                }
                className="fixed-width-200"
              >
                <FormattedMessage id="Choose" defaultMessage="Choose" />
              </TableColumn>
            </RecipientsTable>
          </ModalBody>
          <Styled.ModalFooter>
            <Styled.ZeroCol md={3}>
              <Styled.BackButton secondary onClick={onBack}>
                <FormattedMessage id="back" defaultMessage="back" />
              </Styled.BackButton>
            </Styled.ZeroCol>
            <Col md={6}>
              <Button
                size="lg"
                block
                onClick={onNext}
                disabled={areStoredRecipientsEmpty}
              >
                <FormattedMessage id="Next" defaultMessage="Close" />
              </Button>
            </Col>
            <Col md={3}></Col>
          </Styled.ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default SearchFromList;
