import { isFunction } from 'lodash';
import React, { Component } from 'react';
import {
  Utils,
  Grid,
  Modal as ModalModule,
  Form as FormModule,
} from 'billon-ui';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Field, reduxForm } from 'redux-form';

import { REQUEST_STATUS } from '../../../../constraints';

// Components
import { BlockchainVisual } from '../../../../components';

// Actions
import { sendRequestDecision } from '../../actions';

// Helpers
import { requestFontColor } from '../../helpers';

// Formatters
import {
  momentFormatterWithoutTime,
  breakWordsFormatter,
  requestStatusFormatter,
} from '../../../../formatters';

const { Button: ButtonModule, Icon, Text, ErrorIcon, validators } = Utils;
const { Button, ButtonLoader, BackButton } = ButtonModule;
const { Row, Col } = Grid;
const { SuccessModal, ConfirmModal, ErrorModal, Modal, ModalFooter } =
  ModalModule;
const { TextAreaField, FormGroup } = FormModule;
const { required } = validators;

const MyButton = styled.button`
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  margin-left: 1rem;

  &:hover,
  &:focus {
    outline: none;
  }
`;

const CopiedInfo = styled.div`
  position: absolute;
  font-size: ${(props) => props.theme.fontSize12};
  background-color: #acbbc7;
  color: #fff;
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 0.25rem 1.5rem;
  top: -2.5rem;
  left: 50%;
  margin-left: -57px;
  transition: ${(props) => props.theme.transitionBase};
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

const CommentWrapper = styled.div`
  display: block;
  width: 100%;
  max-width: 310px;
  height: 100%;
  min-height: 250px;
  padding: 10px;
  border: 1px dashed #cad5dd;
  border-radius: 10px;
`;

const ErrorIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 25px 0;
`;

class RequestDetails extends Component {
  state = {
    copied: false,
    copyHover: false,

    approveModalOpened: false,
    approveSuccessModalOpened: false,
    approveErrorModalOpened: false,
    approveCallback: undefined,
    approvingErrors: undefined,

    denyModalOpened: false,
    denySuccessModalOpened: false,
    denyErrorModalOpened: false,
    denyCallback: undefined,
    denyingErrors: undefined,
  };

  constructor(props) {
    super(props);

    this.onCopied = this.onCopied.bind(this);
  }

  onCopied() {
    this.setState({
      copied: true,
    });

    setTimeout(() => this.setState({ copied: false }), 2000);
  }

  handleApprove = () => {
    this.setState({
      approveModalOpened: true,
      approveCallback: undefined,
    });
  };

  handleApproveFinished = (requestId) => {
    const { sendRequestDecision } = this.props;

    if (isFunction(this.state.approveCallback)) {
      this.state.approveCallback();
    }

    return new Promise((resolve, reject) => {
      sendRequestDecision(requestId, true, '', {
        resolve,
        reject,
      });
    })
      .then(() => {
        this.setState({
          approveModalOpened: false,
          approveSuccessModalOpened: true,
          approveCallback: undefined,
        });
      })
      .catch((err) => {
        this.setState({
          approvingErrors: true,
          approveModalOpened: false,
          approveErrorModalOpened: true,
          approveCallback: undefined,
        });

        return false;
      });
  };

  handleCancelApprove = () => {
    this.setState({
      approveSuccessModalOpened: false,
      approveErrorModalOpened: false,
      approveModalOpened: false,
    });
  };

  handleDeny = () => {
    this.setState({
      denyModalOpened: true,
      denyCallback: () => undefined,
    });
  };

  handleDenyFinished = (requestId) => {
    const { sendRequestDecision, denyForm } = this.props;

    if (isFunction(this.state.denyCallback)) {
      this.state.denyCallback();
    }

    return new Promise((resolve, reject) => {
      sendRequestDecision(requestId, false, denyForm.values.reason, {
        resolve,
        reject,
      });
    })
      .then(() => {
        this.setState({
          denyModalOpened: false,
          denySuccessModalOpened: true,
          denyCallback: undefined,
        });
      })
      .catch((err) => {
        this.setState({
          denyingErrors: true,
          denyModalOpened: false,
          denyErrorModalOpened: true,
          denyCallback: undefined,
        });

        return false;
      });
  };

  handleCancelDeny = () => {
    this.setState({
      denySuccessModalOpened: false,
      denyErrorModalOpened: false,
      denyModalOpened: false,
    });
  };

  handleDenySuccessFinished = () => {
    const { history } = this.props;

    history.push('/requests');
  };

  render() {
    const {
      onBackClick,
      backButtonLabel,
      fileDownload,
      isDownloading,
      request,
      isSendingRequestDecision,
    } = this.props;

    const {
      approveModalOpened,
      approveSuccessModalOpened,
      approveErrorModalOpened,
      denyModalOpened,
      denySuccessModalOpened,
      denyErrorModalOpened,
    } = this.state;

    if (!request) {
      return null;
    }

    return (
      <>
        <Row margin={0}>
          <Col xl={9}>
            <Row margin={'0 0 20px 0 '}>
              <BackButton onClick={onBackClick}>{backButtonLabel}</BackButton>
            </Row>
            <Row margin={0}>
              <Col padding={'0 0 40px 0 '} xl={5}>
                <Text
                  as={'h1'}
                  fontSize={'20px'}
                  margin={'0 0 40px 0'}
                  fontWeight={700}
                >
                  {request.documentTitle}
                </Text>
                <Text fontSize={'13px'} margin={0} fontWeight={700}>
                  <FormattedMessage
                    id="Document blockchain address"
                    defaultMessage="Document blockchain address"
                  />
                  <CopyToClipboard
                    text={request.documentBlockchainAddress}
                    onCopy={this.onCopied}
                  >
                    <MyButton
                      onMouseOver={() => this.setState({ copyHover: true })}
                      onMouseOut={() => this.setState({ copyHover: false })}
                    >
                      <CopiedInfo show={this.state.copied}>
                        <FormattedMessage id="Copied" defaultMessage="Copied" />
                      </CopiedInfo>

                      <CopiedInfo
                        show={this.state.copied ? false : this.state.copyHover}
                      >
                        <FormattedMessage id="Copy" defaultMessage="Copy" />
                      </CopiedInfo>
                      <Icon name="copy" regular color="#7e7f7f" />
                    </MyButton>
                  </CopyToClipboard>
                </Text>
                <Text fontSize={'13px'} fontWeight={500} padding="0 32px 0 0">
                  {breakWordsFormatter(request.documentBlockchainAddress)}
                </Text>
                <br />
                <Col padding={0} xl={11}>
                  {!isDownloading.includes(
                    request.documentBlockchainAddress,
                  ) ? (
                    <Button
                      billonIcon
                      icon="download"
                      block
                      onClick={() =>
                        fileDownload(
                          request.documentBlockchainAddress,
                          request.requestJobId,
                        )
                      }
                    >
                      <FormattedMessage
                        id="Download"
                        defaultMessage="Download"
                      />
                    </Button>
                  ) : (
                    <ButtonLoader block />
                  )}
                </Col>

                {request.requestStatus === REQUEST_STATUS.REQUESTED && (
                  <Row margin={'10px 0'}>
                    <Col margin={'0 0 10px 0'} padding={0} xl={5.25}>
                      {!isSendingRequestDecision ? (
                        <Button
                          block
                          color="success"
                          outline
                          onClick={this.handleApprove}
                        >
                          <FormattedMessage
                            id="Approve"
                            defaultMessage="Approve"
                          />
                        </Button>
                      ) : (
                        <ButtonLoader block />
                      )}
                    </Col>
                    <Col
                      margin={'0 0 10px 0'}
                      padding={0}
                      xl={{ size: 5.25, offset: 0.5 }}
                    >
                      {!isSendingRequestDecision ? (
                        <Button
                          block
                          color="error"
                          outline
                          onClick={this.handleDeny}
                        >
                          <FormattedMessage id="Deny" defaultMessage="Deny" />
                        </Button>
                      ) : (
                        <ButtonLoader block />
                      )}
                    </Col>
                  </Row>
                )}
              </Col>

              <Col
                padding={'0 0 40px 10px'}
                xl={4}
                lg={6}
                md={6}
                css={`
                  border-left: 1px solid #abc4c661;
                  @media (max-width: 1199px) {
                    border-left: 0px;
                  }
                `}
              >
                {request.requestStatus === REQUEST_STATUS.ACCESS_GRANTED && (
                  <Row margin={0}>
                    <Text
                      margin={'0 10px 0 0'}
                      fontSize={'12px'}
                      fontWeight={700}
                    >
                      <FormattedMessage
                        id="Access granted on"
                        defaultMessage="Access granted on"
                      />
                      :
                    </Text>{' '}
                    <Text fontSize={'12px'}>
                      {momentFormatterWithoutTime(
                        request.requestCompletionTime,
                      )}
                    </Text>
                  </Row>
                )}
                {request.requestStatus === REQUEST_STATUS.REJECTED && (
                  <Row margin={0}>
                    <Text
                      margin={'0 10px 0 0'}
                      fontSize={'12px'}
                      fontWeight={700}
                    >
                      <FormattedMessage
                        id="Denied on"
                        defaultMessage="Denied on"
                      />
                      :
                    </Text>{' '}
                    <Text fontSize={'12px'}>
                      {momentFormatterWithoutTime(
                        request.requestCompletionTime,
                      )}
                    </Text>
                  </Row>
                )}
                <Row margin={0}>
                  <Text
                    margin={'0 10px 0 0'}
                    fontSize={'12px'}
                    fontWeight={700}
                  >
                    <FormattedMessage
                      id="Request ID"
                      defaultMessage="Request ID"
                    />
                    :
                  </Text>{' '}
                  <Text fontSize={'12px'}>{request.requestJobId}</Text>
                </Row>
                {/* <Row margin={0}>
                    <Text margin={"0 10px 0 0"} fontSize={"12px"} fontWeight={700}><FormattedMessage id="Observer" defaultMessage="Observer" />:</Text>{' '}
                    <Text fontSize={"12px"}>{request.observer}</Text>
                  </Row> */}
                <Row margin={0}>
                  <Text
                    margin={'0 10px 0 0'}
                    fontSize={'12px'}
                    fontWeight={700}
                  >
                    <FormattedMessage
                      id="Request Issued on"
                      defaultMessage="Request Issued on"
                    />
                    :
                  </Text>{' '}
                  <Text fontSize={'12px'}>
                    {momentFormatterWithoutTime(request.requestCreationTime)}
                  </Text>
                </Row>
                <Row margin={0}>
                  <Text
                    margin={'0 10px 0 0'}
                    fontSize={'12px'}
                    fontWeight={700}
                  >
                    <FormattedMessage
                      id="Request Valid until"
                      defaultMessage="Request Valid until"
                    />
                    :
                  </Text>{' '}
                  <Text fontSize={'12px'}>
                    {momentFormatterWithoutTime(request.requestValidity)}
                  </Text>
                </Row>
                <Row margin={0}>
                  <Text
                    margin={'0 10px 0 0'}
                    fontSize={'12px'}
                    fontWeight={700}
                  >
                    <FormattedMessage id="Status" defaultMessage="Status" />:
                  </Text>{' '}
                  <Text
                    fontSize={'12px'}
                    fontColor={requestFontColor(request.requestStatus)}
                  >
                    {requestStatusFormatter(request.requestStatus)}
                  </Text>
                </Row>
              </Col>

              {request.requestDenialReason && (
                <Col
                  padding={'0 0 40px 10px'}
                  xl={3}
                  lg={6}
                  md={6}
                  css={`
                    border-left: 1px solid #abc4c661;
                    @media (max-width: 1199px) {
                      border-left: 0px;
                    }
                  `}
                >
                  <Row margin={0}>
                    <Text
                      margin={'0 10px 0 0'}
                      fontSize={'12px'}
                      fontWeight={700}
                    >
                      <FormattedMessage id="Comment" defaultMessage="Comment" />
                      :
                    </Text>{' '}
                    <CommentWrapper>
                      <Text fontSize={'12px'}>
                        {request.requestDenialReason}
                      </Text>
                    </CommentWrapper>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col
            xl={{ size: 3, offset: 0 }}
            lg={{ size: 6, offset: 3 }}
            sm={{ size: 8, offset: 2 }}
          >
            <BlockchainVisual remoteSignAnimation size="100%" fixed />
          </Col>{' '}
        </Row>

        {/* approve */}
        <ConfirmModal
          isLoading={isSendingRequestDecision}
          isOpen={approveModalOpened}
          onAccept={() => this.handleApproveFinished(request.requestJobId)}
          onCancel={this.handleCancelApprove}
        >
          <FormattedMessage
            id="Are You sure You want to approve this request?"
            defaultMessage="Are You sure You want to approve this request?"
          />
        </ConfirmModal>
        <SuccessModal
          isOpen={approveSuccessModalOpened}
          title={
            <FormattedMessage
              id="Request has been approved"
              defaultMessage="Request has been approved"
            />
          }
          onClose={this.handleCancelApprove}
        >
          <FormattedMessage
            id="Approved successfully"
            defaultMessage="Approved successfully"
          />
        </SuccessModal>

        <ErrorModal
          isOpen={approveErrorModalOpened}
          onClose={this.handleCancelApprove}
        >
          <FormattedMessage
            id="There was some error while approving."
            defaultMessage="There was some error while approving."
          />
        </ErrorModal>

        {/* deny */}
        <Modal
          isLoading={isSendingRequestDecision}
          isOpen={denyModalOpened}
          // onAccept={this.handleDenyFinished}
          toggle={this.handleCancelDeny}
          title={
            <FormattedMessage
              id="Request will be denied"
              defaultMessage="Request will be denied"
            />
          }
        >
          <ErrorIconWrapper>
            <ErrorIcon />
          </ErrorIconWrapper>

          <FormGroup>
            <Field
              component={TextAreaField}
              name="reason"
              rows={10}
              validate={[required]}
              label={
                <FormattedMessage
                  id="Give a reason"
                  defaultMessage="Give a reason"
                />
              }
            />
          </FormGroup>

          <ModalFooter>
            <Col md={8}>
              <Button
                size="lg"
                block
                onClick={() => this.handleDenyFinished(request.requestJobId)}
              >
                <FormattedMessage id="Confirm" defaultMessage="Confirm" />
              </Button>
            </Col>
          </ModalFooter>
        </Modal>

        <SuccessModal
          isOpen={denySuccessModalOpened}
          onClose={this.handleDenySuccessFinished}
        >
          <FormattedMessage
            id="Denied successfully"
            defaultMessage="Denied successfully"
          />
        </SuccessModal>

        <ErrorModal
          isOpen={denyErrorModalOpened}
          onClose={this.handleCancelDeny}
        >
          <FormattedMessage
            id="There was some error while denying."
            defaultMessage="There was some error while denying."
          />
        </ErrorModal>
      </>
    );
  }
}

RequestDetails.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  backButtonLabel: PropTypes.object.isRequired,
  requestPreviewUrl: PropTypes.string.isRequired,
  publisherName: PropTypes.string.isRequired,

  sendRequestDecision: PropTypes.func.isRequired,
  isSendingRequestDecision: PropTypes.bool.isRequired,

  history: PropTypes.object.isRequired,
};

const mapStateToProps = ({ requests, form }) => ({
  isSendingRequestDecision: requests.isSendingRequestDecision,
  denyForm: form.denyForm,
});

const mapDispatchToProps = (dispatch) => ({
  sendRequestDecision: (requestId, approved, reason, meta) =>
    dispatch(sendRequestDecision({ requestId, approved, reason }, meta)),
});

export default withRouter(
  reduxForm({
    form: 'denyForm',
  })(connect(mapStateToProps, mapDispatchToProps)(RequestDetails)),
);
