import React from 'react';
import styled from 'styled-components';
import { Utils, Grid } from 'billon-ui';

export const AbsoluteDivForExitIcon = styled.div`
  position: absolute;
  right: 5%;
  top: 5%;
  cursor: pointer;
`;

export const FilterCard = styled(Utils.Card)`
  position: relative;
  box-shadow: none;
  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

export const Header = ({ children }) => (
  <Utils.Text
    as="h3"
    fontSize={'14px'}
    fontWeight={700}
    margin={'0 0 0.75rem 0'}
  >
    {children}
  </Utils.Text>
);

export const Label = ({ children }) => (
  <Utils.Text
    as="label"
    fontSize={'12px'}
    fontWeight={700}
    margin={'0 0 5px 0'}
  >
    {children}
  </Utils.Text>
);

export const Row = styled(Grid.Row)`
  justify-content: space-between;
`;

export const ButtonsGroupRow = styled(Grid.Row)`
  justify-content: flex-end;
`;
