import * as yup from 'yup';
import { useIntl } from 'react-intl';

import {
  VALIDATION_ERRORS,
  VALIDATION_ERROR_TYPES,
  MAP_VALIDATION_ERROR_TYPES,
} from '../../../../constraints';

import { passwordStructureValidator } from '../../heplers/passwordStructureValidator';

export const useSchema = ({ validationConstraints }) => {
  const { formatMessage } = useIntl();

  const requiredMessage = formatMessage({
    id: VALIDATION_ERRORS.REQUIRED,
    defaultMessage: VALIDATION_ERRORS.REQUIRED,
  });

  const emptyMessage = formatMessage({
    id: VALIDATION_ERRORS.EMPTY,
    defaultMessage: VALIDATION_ERRORS.EMPTY,
  });

  return yup.object({
    oldPassword:
      validationConstraints?.oldPasswordEnabled &&
      yup.string().required(requiredMessage),
    password: yup
      .string()
      .test(VALIDATION_ERROR_TYPES.TOO_SHORT, emptyMessage, function (value) {
        return value ? value.length > validationConstraints.minLength : true;
      })
      .test(
        VALIDATION_ERROR_TYPES.NO_UNIQUE_CHARACTERS,
        emptyMessage,
        function (value) {
          return value
            ? passwordStructureValidator(
                value,
                validationConstraints?.complexity,
              )
            : true;
        },
      )
      .test('doesVaryRepeatPassword', emptyMessage, function (value) {
        const { repeatPassword } = this.parent;
        return value === repeatPassword;
      })
      .test(
        VALIDATION_ERROR_TYPES.PREVIOUSLY_USED,
        emptyMessage,
        function (value) {
          const { oldPassword } = this.parent;
          return value !== oldPassword;
        },
      )
      .required(emptyMessage),
    repeatPassword: yup
      .string()
      .test(VALIDATION_ERROR_TYPES.TOO_SHORT, emptyMessage, function (value) {
        return value ? value.length > validationConstraints.minLength : true;
      })
      .test(
        VALIDATION_ERROR_TYPES.NO_UNIQUE_CHARACTERS,
        emptyMessage,
        function (value) {
          return value
            ? passwordStructureValidator(
                value,
                validationConstraints?.complexity,
              )
            : true;
        },
      )
      .test(
        'doesVaryPassword',
        formatMessage({
          id: MAP_VALIDATION_ERROR_TYPES.VARY,
          defaultMessage: MAP_VALIDATION_ERROR_TYPES.VARY,
        }),
        function (value) {
          const { password } = this.parent;
          return value === password;
        },
      )
      .test(
        VALIDATION_ERROR_TYPES.PREVIOUSLY_USED,
        emptyMessage,
        function (value) {
          const { oldPassword } = this.parent;
          return value !== oldPassword;
        },
      )
      .required(requiredMessage),
  });
};
