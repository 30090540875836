import React from 'react';
import { useQueryClient } from 'react-query';

import { mapDOCUMENT_ACTIONS_ERROR } from 'js/constraints';
import { QueryKeys } from 'js/queryKeys';

// Components
import { DocumentAtrributesForm } from '../DocumentAtrributesForm';
import { DocumentSigningForm } from '../DocumentSigningForm';
import { ErrorModal } from 'js/components/ErrorModal';
import { ExportableErrorContent } from 'js/components/ExportableErrorContent';

// Hooks
import {
  usePublishDocument,
  usePublishSignedDocument,
} from '../../../../hooks';

// Helpers
import { parsePublishRequestData } from 'js/modules/DocumentsNew/helpers';

export const TwoStepPublication = ({
  onCreateIdentity,
  onCreateCategory,
  isPreparedToSign,
  publicationMode,
  onCancel,
  document,
  onSuccess,
}) => {
  const queryClient = useQueryClient();
  const [step, setStep] = React.useState(isPreparedToSign ? 2 : 1);
  const [jobId, setJobId] = React.useState(
    isPreparedToSign ? document.jobId : null,
  );
  const [documentTitle, setDocumentTitle] = React.useState(
    isPreparedToSign ? document.title : null,
  );

  const {
    mutate: prepareDocument,
    error: prepareError,
    isLoading: isPrepareLoading,
  } = usePublishDocument({
    onSuccess: (data) => {
      setJobId(data.jobId);
      setStep(step + 1);
      queryClient.invalidateQueries([QueryKeys.DOCUMENT_LIST]);
    },
  });

  const {
    mutate: publishDocument,
    error: publishError,
    isLoading: isPublishLoading,
  } = usePublishSignedDocument({
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries([QueryKeys.DOCUMENT_LIST]);
    },
  });

  if (prepareError || publishError) {
    const statusCode = (prepareError || publishError).response.data.statusCode;

    return (
      <ErrorModal
        isOpen
        toggle={onCancel}
        onClose={onCancel}
        closeIconEnabled={false}
      >
        <ExportableErrorContent
          headerTitleId={
            step === 1
              ? mapDOCUMENT_ACTIONS_ERROR.PREPARE
              : mapDOCUMENT_ACTIONS_ERROR.PUBLISH
          }
          statusCode={statusCode}
          jobId={jobId}
          title={documentTitle}
        />
      </ErrorModal>
    );
  }

  const onPrepareDocument = (values) => {
    setDocumentTitle(values.title);

    prepareDocument({
      data: parsePublishRequestData(
        values,
        publicationMode,
        document?.documentBlockchainAddress,
      ),
    });
  };

  const onPublishDocument = ({ file }) => {
    publishDocument({
      id: jobId,
      data: {
        file,
      },
    });
  };

  return (
    <>
      {
        {
          1: (
            <DocumentAtrributesForm
              step={step}
              document={document}
              publicationMode={publicationMode}
              onSuccess={onPrepareDocument}
              onCreateIdentity={onCreateIdentity}
              onCreateCategory={onCreateCategory}
              submitButtonText="Prepare document"
              isLoading={isPrepareLoading}
            />
          ),
          2: (
            <DocumentSigningForm
              document={document}
              onSuccess={onPublishDocument}
              documentTitle={documentTitle}
              jobId={jobId}
              isLoading={isPublishLoading}
            />
          ),
        }[step]
      }
    </>
  );
};
