import {
  Dropdown as DropdownModule,
  Form as FormModule,
  Table as TableModule,
  Utils,
} from 'billon-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { CalendarField } from '../../../../containers';
import { StyledDropDownDiv } from '../../scenes/HomePage';

const { FormGroup } = FormModule;
const { Table, TableColumn } = TableModule;

const { Pagination, Button: ButtonModule, Text } = Utils;
const { Button } = ButtonModule;
const { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } =
  DropdownModule;

const StyledSelectHistoryType = styled.div`
  display: flex;
`;

const StyledHistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .dropdown {
    height: 100%;
    margin-left: 20px;
    margin-top: 10px;
  }
  .form-group {
    width: 180px;
    .form-control {
      font-size: 0.65rem;
    }
  }
  @media (max-width: 1023px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const StyledButton = styled(Button)`
  &.btn-link {
    height: 50%;
    margin-top: 13px;
    margin-bottom: 30px;
    color: ${({ active }) => (active ? '#00aeff' : '#011627')};
    font-weight: ${({ active }) => active && 700};
    font-size: 12px;
    border-radius: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    &:not(:first-of-type) {
      border-left: 2px solid #cad5dd;
    }
  }
`;

const StyledHistoryTableWrapper = styled.div`
  table {
    width: 100%;
    tbody tr td:first-of-type {
      white-space: nowrap;
    }
  }
`;

const ReportsHistory = (props) => {
  return (
    <>
      <StyledHistoryHeader>
        <Text fontWeight={600} fontSize="20px" margin="0 0 30px 0 ">
          <FormattedMessage id="History" defaultMessage="History" />
        </Text>
        <StyledSelectHistoryType>
          <StyledButton
            color="link"
            active={props.reportType === 'DAILY'}
            onClick={() => props.handleChangeHistoryPeriod('DAILY')}
          >
            <FormattedMessage id="Daily" defaultMessage="Daily" />
          </StyledButton>
          <StyledButton
            color="link"
            active={props.reportType === 'MONTHLY'}
            onClick={() => props.handleChangeHistoryPeriod('MONTHLY')}
          >
            <FormattedMessage id="Monthly" defaultMessage="Monthly" />
          </StyledButton>
          <StyledButton
            color="link"
            active={props.reportType === 'YEARLY'}
            onClick={() => props.handleChangeHistoryPeriod('YEARLY')}
          >
            <FormattedMessage id="Yearly" defaultMessage="Yearly" />
          </StyledButton>
          <FormGroup>
            <Field
              component={CalendarField}
              showMonths={2}
              name="dateRange"
              range
              enableTime={false}
              dateFormat="Y-m-d"
            />
          </FormGroup>
          <UncontrolledDropdown>
            <StyledDropDownDiv>
              <FormattedMessage
                id="Document type"
                defaultMessage="Document type"
              />
              <DropdownToggle />
            </StyledDropDownDiv>
            <DropdownMenu>
              <DropdownItem onClick={() => props.changeHistoryDocsType(0)}>
                <FormattedMessage id="All" defaultMessage="All" />
              </DropdownItem>
              <DropdownItem onClick={() => props.changeHistoryDocsType(1)}>
                <FormattedMessage id="Private" defaultMessage="Private" />
              </DropdownItem>
              <DropdownItem onClick={() => props.changeHistoryDocsType(2)}>
                <FormattedMessage id="Public" defaultMessage="Public" />
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </StyledSelectHistoryType>
      </StyledHistoryHeader>
      {props.historyDocumentType === 0 && (
        <FormattedMessage id="All documents" />
      )}
      {props.historyDocumentType === 1 && (
        <FormattedMessage id="Private documents" />
      )}
      {props.historyDocumentType === 2 && (
        <FormattedMessage id="Public documents" />
      )}
      <br />
      <br />
      {props.customReport ? (
        <StyledHistoryTableWrapper>
          {props.historyDocumentType === 0 && (
            <Table tableData={props.customReport}>
              <TableColumn fieldName="reportName">
                <FormattedMessage id="Date" defaultMessage="Date" />
              </TableColumn>

              <TableColumn fieldName="allDocumentsPublished">
                <FormattedMessage
                  id="Published documents number"
                  defaultMessage="Published documents number"
                />
              </TableColumn>
              <TableColumn fieldName="allDocumentsSize">
                <FormattedMessage
                  id="Published documents size [MB]"
                  defaultMessage="Published documents size [MB]"
                />
              </TableColumn>
              <TableColumn fieldName="documentSystemSize">
                <FormattedMessage
                  id="Published documents size in the system [MB]"
                  defaultMessage="Published documents size in the system [MB]"
                />
              </TableColumn>
              <TableColumn fieldName="averagePublicationTimePrivateDocuments">
                <FormattedMessage
                  id="Average publish time [s]"
                  defaultMessage="Average publish time [s]"
                />
              </TableColumn>
            </Table>
          )}
          {props.historyDocumentType === 1 && (
            <Table tableData={props.customReport}>
              <TableColumn fieldName="reportName">
                <FormattedMessage id="Date" defaultMessage="Date" />
              </TableColumn>
              <TableColumn fieldName="privateDocumentPublished">
                <FormattedMessage
                  id="Published documents number"
                  defaultMessage="Published documents number"
                />
              </TableColumn>
              <TableColumn fieldName="privateDocumentsSize">
                <FormattedMessage
                  id="Published documents size [MB]"
                  defaultMessage="Published documents size [MB]"
                />
              </TableColumn>
              <TableColumn fieldName="averagePublicationTimePrivateDocuments">
                <FormattedMessage
                  id="Average publish time [s]"
                  defaultMessage="Average publish time [s]"
                />
              </TableColumn>
            </Table>
          )}
          {props.historyDocumentType === 2 && (
            <Table tableData={props.customReport}>
              <TableColumn fieldName="reportName">
                <FormattedMessage id="Date" defaultMessage="Date" />
              </TableColumn>
              <TableColumn fieldName="publicDocumentPublished">
                <FormattedMessage
                  id="Published documents number"
                  defaultMessage="Published documents number"
                />
              </TableColumn>
              <TableColumn fieldName="publicDocumentsSize">
                <FormattedMessage
                  id="Published documents size [MB]"
                  defaultMessage="Published documents size [MB]"
                />
              </TableColumn>
              <TableColumn fieldName="averagePublicationTimePublicDocuments">
                <FormattedMessage
                  id="Average publish time [s]"
                  defaultMessage="Average publish time [s]"
                />
              </TableColumn>
            </Table>
          )}
          <Pagination
            onClick={props.handleHistoryPageChange}
            numberOfPages={props.numberOfHistoryPages}
            currentPage={props.historyPage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
          />
        </StyledHistoryTableWrapper>
      ) : (
        <FormattedMessage id="No data" defaultMessage="No data" />
      )}
    </>
  );
};

const mapStateToProps = ({ form }) => ({});

export default reduxForm({
  form: 'reportsHistoryForm',
})(connect(mapStateToProps)(ReportsHistory));
