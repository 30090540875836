import React, { useState } from 'react';
import {
  SearchFromList,
  SearchFromListSummary,
  SelectRecipientsGroup,
  SelectResendMode,
  EnterNotificationContent,
} from './components';
import { useHistory } from 'react-router-dom';
import { RESEND_NOTIFICATION_MODES } from 'js/constraints';
import { parseResendNotificationData } from '../../helpers/parseResendNotificationData';
import { useResendDocumentNotification } from '../../hooks/useResendDocumentNotification';
import { SuccessModal } from '../../../../components/SuccessModal';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { removeUndefinedValues } from '../../../../helpers/removeUndefinedValues';

const ResendNotification = ({
  document,
  resendMode,
  handleClose,
  isLoading,
}) => {
  const history = useHistory();

  const [storedRecipients, setStoredRecipients] = useState([]);
  const [latelyRemovedRecipient, setLatelyRemovedRecipient] = useState({});

  const handleAppendRecipients = (recipient) => {
    if (
      !storedRecipients.find((record) => record.uniqueId === recipient.uniqueId)
    ) {
      setStoredRecipients([...storedRecipients, recipient]);
    }
  };

  const handleRemoveRecipient = (recipient) => {
    setStoredRecipients([
      ...storedRecipients.filter(
        ({ uniqueId }) => uniqueId !== recipient.uniqueId,
      ),
    ]);
    setLatelyRemovedRecipient(recipient);
  };

  const [formData, setFormData] = useState({});
  const handleAppendFormData = (moreData) => {
    setFormData({
      ...formData,
      ...moreData,
    });
  };

  const onSelectResendMode = () => {
    setFormData({});
    setStoredRecipients([]);
    setIsNoGroupSelected(false);
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.SELECT_TYPE}`,
    );
  };

  const onSelectGroup = () => {
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.SELECT_GROUP}`,
    );
  };

  const onSearchList = () => {
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.SEARCH_LIST}`,
    );
  };

  const onSummaryList = (subFormData) => {
    handleAppendFormData(subFormData);
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.SUMMARY_LIST}`,
    );
  };

  const onSelectEditContent = (subFormData) => {
    handleAppendFormData(subFormData);
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.EDIT_TYPE_VIEW}`,
    );
  };

  const [isNoGroupSelected, setIsNoGroupSelected] = useState(false);

  const onSelectEditContentAfterSelectGroup = (subFormData) => {
    if (
      !!{ ...formData, ...subFormData }?.groups?.find((v) => v.checked === true)
    ) {
      setIsNoGroupSelected(false);
      onSelectEditContent(subFormData);
    } else {
      setIsNoGroupSelected(true);
    }
  };

  const onDistinguishableBack = () => {
    history.goBack();
  };

  const onResendSuccess = () => {
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.SUCCESS}`,
    );
  };

  const onResendFailure = () => {
    history.push(
      `/document/${document.jobId}/resendNotification/${RESEND_NOTIFICATION_MODES.FAILURE}`,
    );
  };

  const {
    mutate: resendNotification,
    error,
    isLoading: isProcessing,
    isSuccess,
  } = useResendDocumentNotification({
    id: document.documentBlockchainAddress,
    onSuccess: onResendSuccess,
    onFailure: onResendFailure,
  });

  const onSubmitForm = (subFormData) => {
    handleAppendFormData(subFormData);
    resendNotification({
      data: removeUndefinedValues(
        parseResendNotificationData(
          { ...formData, ...subFormData },
          storedRecipients,
        ),
      ),
    });
  };

  const areStoredRecipientsEmpty = isEmpty(storedRecipients);

  return (
    <>
      <SelectResendMode
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.SELECT_TYPE}
        isLoading={false}
        onClose={handleClose}
        onSelectGroup={onSelectGroup}
        onSearchList={onSearchList}
      />
      <SelectRecipientsGroup
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.SELECT_GROUP}
        isLoading={false}
        onClose={handleClose}
        onBack={onSelectResendMode}
        onNext={onSelectEditContentAfterSelectGroup}
        documentBlockchainAddress={document.documentBlockchainAddress}
        isNoGroupSelected={isNoGroupSelected}
      />
      <SearchFromList
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.SEARCH_LIST}
        isLoading={false}
        onClose={handleClose}
        onBack={onSelectResendMode}
        onNext={onSummaryList}
        documentBlockchainAddress={document.documentBlockchainAddress}
        resendMode={resendMode}
        onAppendRecipients={handleAppendRecipients}
        unRegisterRecipient={handleRemoveRecipient}
        defaultValues={formData}
        latelyRemovedRecipient={latelyRemovedRecipient}
        areStoredRecipientsEmpty={areStoredRecipientsEmpty}
      />
      <SearchFromListSummary
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.SUMMARY_LIST}
        isLoading={false}
        onClose={handleClose}
        onBack={onSearchList}
        onNext={onSelectEditContent}
        documentBlockchainAddress={document.documentBlockchainAddress}
        resendMode={resendMode}
        storedRecipients={storedRecipients}
        onRemoveRecipient={handleRemoveRecipient}
        areStoredRecipientsEmpty={areStoredRecipientsEmpty}
      />
      <EnterNotificationContent
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.EDIT_TYPE_VIEW}
        isLoading={isProcessing}
        onClose={handleClose}
        onBack={onDistinguishableBack}
        onNext={onSubmitForm}
      />
      <SuccessModal
        isOpen={resendMode === RESEND_NOTIFICATION_MODES.SUCCESS}
        onClose={handleClose}
        toggle={handleClose}
        closeIconEnabled={true}
      >
        <FormattedMessage
          id="Notifications already resent"
          defaultMessage="Notifications already resent"
        />
      </SuccessModal>
    </>
  );
};

export default ResendNotification;
