/* global window */
import React from 'react';
import { Modal as ModalModule, Layout } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { isFunction } from 'lodash';
import { CUSTOMER } from '../../../../constraints';

// Components
import ContentContainerGrey from 'js/ui/ContentContainerGrey';
import { CreateForm, CommonDocumentTable } from '../../containers';
import { CreateForm as CreateFormRecipients } from '../../../Recipients/containers';
import { CreateForm as CreateFormCategories } from '../../../Categories/containers';

// Actions
import { requestDelete, requestResendAuthorizationCodes } from '../../actions';
import { requestList as requestRecipientsList } from '../../../Recipients/actions';
import { requestList as requestCategoriesList } from '../../../Categories/actions';

const { Content: ContentModule } = Layout;
const { Content } = ContentModule;
const {
  SuccessModal,
  AbstractCrudComponent,
  CreateModal,
  ConfirmModal,
  ErrorModal,
} = ModalModule;

class DocumentsPage extends AbstractCrudComponent {
  constructor(props) {
    super(props);

    this.state.createModalOpened = false;
    this.state.recipientCallback = undefined;
    this.state.resendAuthorizationCodesModalOpened = false;
    this.state.resendAuthorizationCodesSuccessModalOpened = false;
    this.state.resendAuthorizationCodesCallback = undefined;
    this.state.isRecipientModal = false;
    this.state.isCategoriesModal = false;
    this.state.isCreating = false;
  }

  handleCreate = (initialValues, callback) => {
    const finalInitialValues = {
      ...initialValues,
      publishedBy: this.props.user.login,
    };

    this.setState({
      createModalOpened: true,
      initialValues: finalInitialValues,
      callback,
    });
  };

  handleSuccessModalClose = () => {
    this.setState({
      successModalOpened: false,
    });
  };

  handleDeleteSuccessModalClose = () => {
    this.setState(
      {
        deleteSuccessModalOpened: false,
      },
      () => window.location.reload(),
    );
  };

  handleCreateRecipientOrCategoriesFinish = () => {
    if (this.state.isRecipientModal) {
      this.setState({
        successAddUserOrCategoryModalOpen: true,
      });
      this.handleCancelRecipientOrCategories();
      this.props.loadRecipients();
    } else {
      this.setState({
        isCreating: true,
      });

      setTimeout(() => {
        this.setState({
          successAddUserOrCategoryModalOpen: true,
          isCreating: false,
        });
        this.handleCancelRecipientOrCategories();
        this.props.loadCategories();
      }, 10000);
    }
  };

  handleCancelRecipientOrCategories = () => {
    this.setState({
      isRecipientModal: false,
      isCategoriesModal: false,
    });
  };

  handleResendAuthorizationCodes = (blockchainAddress) => {
    const { resendAuthorizationCodes } = this.props;
    this.setState({
      resendAuthorizationCodesModalOpened: true,
      resendAuthorizationCodesCallback: () => {
        resendAuthorizationCodes(blockchainAddress);
      },
    });
  };

  handleResendAuthorizationCodesFinished = () => {
    if (isFunction(this.state.resendAuthorizationCodesCallback)) {
      this.state.resendAuthorizationCodesCallback();
    }

    this.setState({
      resendAuthorizationCodesModalOpened: false,
      resendAuthorizationCodesSuccessModalOpened: true,
      resendAuthorizationCodesCallback: undefined,
    });
  };

  handleCancelResendAuthorizationCodes = () => {
    this.setState({
      resendAuthorizationCodesSuccessModalOpened: false,
      resendAuthorizationCodesModalOpened: false,
    });
  };

  handleAddUserOrCategorySuccessModalClose = () => {
    this.setState({ successAddUserOrCategoryModalOpen: false });
  };

  render() {
    const {
      createModalOpened,
      successModalOpened,
      initialValues,
      confirmDeleteModalOpened,
      deleteSuccessModalOpened,
      deleteErrorModalOpened,
      deleteErrorMessage,
      resendAuthorizationCodesModalOpened,
      resendAuthorizationCodesSuccessModalOpened,
      isRecipientModal,
      isCategoriesModal,
      isCreating,
    } = this.state;
    const { isDeleting, customer, isResendingAuthorizationCodes } = this.props;

    return (
      <ContentContainerGrey fluid>
        <Content fluid>
          <CommonDocumentTable
            handleCreate={this.handleCreate}
            handleDelete={this.handleDelete}
            handleResendAuthorizationCodes={this.handleResendAuthorizationCodes}
          />
          <CreateModal
            isOpen={createModalOpened}
            onFinished={this.handleCreateFinished}
            onCancel={this.handleCancel}
            initialValues={initialValues}
            className="modal-xl"
          >
            <CreateForm
              handleCreateRecipient={() => {
                this.setState(
                  {
                    isRecipientModal: true,
                    isCategoriesModal: false,
                  },
                  () => this.handleCreate(),
                );
              }}
              handleCreateCategory={() => {
                this.setState(
                  {
                    isRecipientModal: false,
                    isCategoriesModal: true,
                  },
                  () => this.handleCreate(),
                );
              }}
              onCancel={this.handleCancel}
            />
          </CreateModal>
          <SuccessModal
            isOpen={this.state.successAddUserOrCategoryModalOpen}
            onClose={this.handleAddUserOrCategorySuccessModalClose}
          >
            <FormattedMessage
              id="Successfully saved"
              defaultMessage="Successfully saved"
            />
          </SuccessModal>
          <SuccessModal
            isOpen={successModalOpened}
            onClose={this.handleSuccessModalClose}
          >
            <FormattedMessage
              id="Document publication has been initiated."
              defaultMessage="Document publication has been initiated."
            />
          </SuccessModal>
          {(isRecipientModal || isCategoriesModal) && (
            <CreateModal
              isOpen={isRecipientModal || isCategoriesModal}
              onSuccess={this.handleCreateRecipientOrCategoriesFinish}
              onCancel={this.handleCancelRecipientOrCategories}
              initialValues={{}}
              createMessage={
                {
                  [CUSTOMER.DEFAULT]: (
                    <>
                      {isRecipientModal && (
                        <FormattedMessage
                          id="Adding recipient"
                          defaultMessage="Adding recipient"
                        />
                      )}
                      {isCategoriesModal && (
                        <FormattedMessage
                          id="Adding category"
                          defaultMessage="Adding category"
                        />
                      )}
                    </>
                  ),
                }[customer]
              }
            >
              {isRecipientModal ? (
                <CreateFormRecipients />
              ) : (
                <CreateFormCategories isCreating={isCreating} />
              )}
            </CreateModal>
          )}
          <ConfirmModal
            isLoading={isDeleting}
            isOpen={confirmDeleteModalOpened}
            title={
              <FormattedMessage
                id="Document deletion confirmation"
                defaultMessage="Document deletion confirmation"
              />
            }
            onAccept={this.handleDeleteConfirmed}
            onCancel={this.handleCancel}
          >
            <FormattedMessage
              id="Are You sure You want to delete this document?"
              defaultMessage="Are You sure You want to delete this document?"
            />
          </ConfirmModal>
          <SuccessModal
            isOpen={deleteSuccessModalOpened}
            title={
              <FormattedMessage
                id="Document deleted"
                defaultMessage="Document deleted"
              />
            }
            onClose={this.handleDeleteSuccessModalClose}
          >
            <FormattedMessage
              id="Document has been deleted successfully."
              defaultMessage="Document has been deleted successfully."
            />
          </SuccessModal>
          <ErrorModal
            isOpen={deleteErrorModalOpened}
            title={
              <FormattedMessage
                id="Document deletion error"
                defaultMessage="Document deletion error"
              />
            }
            onClose={this.handleDeleteErrorModalClose}
          >
            {deleteErrorMessage && (
              <FormattedMessage
                id={deleteErrorMessage.message}
                defaultMessage={deleteErrorMessage.message}
              />
            )}
          </ErrorModal>
          <ConfirmModal
            isLoading={isResendingAuthorizationCodes}
            isOpen={resendAuthorizationCodesModalOpened}
            title={
              <FormattedMessage
                id="Resending authorization codes confirmation"
                defaultMessage="Resending authorization codes confirmation"
              />
            }
            onAccept={this.handleResendAuthorizationCodesFinished}
            onCancel={this.handleCancelResendAuthorizationCodes}
          >
            <FormattedMessage
              id="Are You sure You want to resend link and authorization code for document?"
              defaultMessage="Are You sure You want to resend link and authorization code for document?"
            />
          </ConfirmModal>
          <SuccessModal
            isOpen={resendAuthorizationCodesSuccessModalOpened}
            title={
              <FormattedMessage
                id="Authorization codes sent"
                defaultMessage="Authorization codes sent"
              />
            }
            onClose={this.handleCancelResendAuthorizationCodes}
          >
            <FormattedMessage
              id="Authorization codes has been sent successfully."
              defaultMessage="Authorization codes has been sent successfully."
            />
          </SuccessModal>
        </Content>
      </ContentContainerGrey>
    );
  }
}

const mapStateToProps = ({ authentication, document, config }) => ({
  user: authentication.user,
  isDeleting: document.isDeleting,
  isResendingAuthorizationCodes: document.isResendingAuthorizationCodes,
  customer: config.customer,
});

const mapDispatchToProps = (dispatch) => ({
  deleteEntry: (values, meta) => dispatch(requestDelete(values, meta)),
  resendAuthorizationCodes: (blockchainAddres) =>
    dispatch(requestResendAuthorizationCodes(blockchainAddres)),
  loadCategories: () => dispatch(requestCategoriesList({ isActive: true })),
  loadRecipients: () => dispatch(requestRecipientsList({ isActive: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPage);
