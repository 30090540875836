import { Form as FormModule, Grid, Utils } from 'billon-ui';
import download from 'downloadjs';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { change, reduxForm, SubmissionError } from 'redux-form';
import styled from 'styled-components';
import { PUBLICATION_TYPE, retainOptionsList } from '../../../../constraints';
import { retainOptionToString } from '../../../../formatters';
import { requestList as requestCategoriesList } from '../../../Categories/actions';
import { requestList as requestRecipientsList } from '../../../Recipients/actions';
// Actions
import { requestDownload, requestPrepare, requestPublish } from '../../actions';
// Components
import { PublishingDocument } from '../../components';
import CreateFormError from './CreateFormError';
import CreateFormStep2 from './CreateFormStep2';
import CreateFormStep1 from './CreateFromStep1';
import { formValidator } from './validators';
import keyBy from 'lodash/keyBy';

const { Form } = FormModule;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;
const { Row, Col } = Grid;

const F = styled(Form)`
  h2 {
    font-size: 2rem;
    margin-bottom: 2.5rem;
    margin-top: -3.5rem;
    width: calc(100% - 35px);
  }
`;

class CreateForm extends Component {
  state = {
    step: 1,
    jobId: null,
    publicationError: false,
    publicationErrorDetails: null,
    isPrivate: false,
  };

  componentDidMount() {
    const { initialValues } = this.props;

    if (initialValues.step) {
      this.setState({ step: initialValues.step });
    }

    if (initialValues.jobId) {
      this.setState({ jobId: initialValues.jobId });
    }
    this.getTypeFromURL();

    if (this.props.initialValues.errata) {
      const errataLabel = this.props.intl.formatMessage({
        id: 'Erratum',
        defaultMessage: 'Erratum',
      });
      this.props.change(
        'title',
        `${this.props.initialValues.title} - ${errataLabel}`,
      );
    }
    if (!this.props.initialValues.errata) {
      this.props.change('versionName', '');
    }
  }

  handlePrepare = (values) => {
    const { prepareDocument, resetFileValue } = this.props;
    const { parentId } = this.state;

    return new Promise((resolve, reject) => {
      prepareDocument(
        {
          ...values,
          parentId,
          prepareWithoutAutoDownload: true,
        },
        {
          resolve,
          reject,
        },
      );
    })
      .then((res) => {
        this.setState({
          step: 2,
          jobId: res.jobId,
          file: res.file,
          fileName: values.title,
        });

        resetFileValue();
      })
      .catch((err) => {
        if (err.response && err.response.errors) {
          if (err.response.errors.additionalData) {
            const errors = {};

            err.response.errors.additionalData.forEach((errorData) => {
              errors[errorData.fieldName] = (
                <FormattedMessage
                  id={errorData.message}
                  defaultMessage={errorData.message}
                  values={errorData.values}
                />
              );
            });

            throw new SubmissionError(errors);
          } else if (err.response.errors.publicationStatus) {
            if (err.response.error) {
              this.setState({ publicationError: true });
            }

            if (err.response.errors) {
              this.setState({ publicationErrorDetails: err.response.errors });
            }
          }
        }

        return false;
      });
  };

  handlePublish = (values) => {
    const { onFinished, publishDocument, isOneStepPublication } = this.props;
    const { jobId } = this.state;

    const body = { ...values, isOneStepPublication, jobId };

    if (!jobId || isOneStepPublication) {
      delete body.jobId;
    }

    return new Promise((resolve, reject) => {
      publishDocument(body, {
        resolve,
        reject,
      });
    })
      .then(() => {
        this.setState({ step: 1, file: null, fileName: null }, () => {
          onFinished();
        });
      })
      .catch((err) => {
        if (err.response.error) {
          this.setState({ publicationError: true });
        }

        if (err.response.errors) {
          this.setState({ publicationErrorDetails: err.response.errors });
        }

        return false;
      });
  };

  nextStep = () => {
    this.setState({
      step: this.state.step + 1,
    });
  };

  previousStep = () => {
    if (this.state.step > 1) {
      this.setState({
        step: this.state.step - 1,
      });
    }
  };

  downloadFileToSign = () => {
    if (this.state.file) {
      const blob = new Uint8Array(this.state.file.fileBuffer.data);
      download(blob, this.state.fileName + '.pdf');
    } else {
      this.props.fileDownload(
        this.props.initialValues.documentBlockchainAddress,
        this.props.initialValues.jobId,
      );
    }
  };

  reset = () => {
    const { initialValues } = this.props;

    this.setState({
      step: initialValues.step || this.state.step || 1,
      publicationError: false,
    });
  };

  getTypeFromURL = () => {
    this.setState({
      isPrivate: window.location.pathname.toLowerCase().includes('private'),
    });
  };

  render() {
    const {
      handleSubmit,
      isSaving,
      categories,
      enableSigning,
      initialValues,
      handleCreateRecipient,
      handleCreateCategory,
      loadRecipients,
      loadCategories,
      recipients,
      isOneStepPublication,
      intl,
    } = this.props;

    const { step, publicationError, publicationErrorDetails, isPrivate } =
      this.state;

    const retainOptionsFormatted = retainOptionsList.map((option) => ({
      label: intl.formatMessage({
        id: retainOptionToString(option),
        defaultMessage: retainOptionToString(option),
      }),
      value: option,
    }));

    if (publicationError) {
      return (
        <CreateFormError
          isOneStepPublication={isOneStepPublication}
          step={step}
          reset={this.reset}
          publicationErrorDetails={publicationErrorDetails}
        />
      );
    }

    if (isSaving) {
      return <PublishingDocument />;
    }

    let submitButton;
    switch (step) {
      case 1:
        submitButton = (
          <Col md={{ size: 8, offset: 2 }}>
            {isOneStepPublication ? (
              <Button
                type="button"
                onClick={handleSubmit(this.handlePublish)}
                size="lg"
                block
              >
                <FormattedMessage
                  id="Publish document"
                  defaultMessage="Publish document"
                />
              </Button>
            ) : (
              <Button
                type="button"
                onClick={handleSubmit(this.handlePrepare)}
                size="lg"
                block
              >
                <FormattedMessage
                  id="Prepare document"
                  defaultMessage="Prepare document"
                />
              </Button>
            )}
          </Col>
        );
        break;
      case 2:
        submitButton = (
          <Col md={{ size: 6, offset: 3 }}>
            <Button
              type="button"
              onClick={handleSubmit(this.handlePublish)}
              size="lg"
              block
            >
              <FormattedMessage
                id="Publish document"
                defaultMessage="Publish document"
              />
            </Button>
          </Col>
        );
        break;
      default:
        break;
    }

    return (
      <F>
        {this.state.step === 1 && (
          <CreateFormStep1
            categories={categories}
            isPrivate={isPrivate}
            enableSigning={enableSigning}
            handleCreateRecipient={handleCreateRecipient}
            handleCreateCategory={handleCreateCategory}
            loadRecipients={loadRecipients}
            loadCategories={loadCategories}
            recipients={recipients}
            initialValues={initialValues}
            retainOptions={retainOptionsFormatted}
          />
        )}
        {this.state.step === 2 && (
          <CreateFormStep2 downloadFileToSign={this.downloadFileToSign} />
        )}

        <Row className="form-submit">{submitButton}</Row>
      </F>
    );
  }
}

CreateForm.propTypes = {
  handleSubmit: PropTypes.func,
  prepareDocument: PropTypes.func.isRequired,
  publishDocument: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onFinished: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  categories: PropTypes.array,
  initialValues: PropTypes.object,
  isPrivate: PropTypes.bool,
  enablePrivate: PropTypes.bool,
  enableSigning: PropTypes.bool,
  handleCreateRecipient: PropTypes.func.isRequired,
  loadRecipients: PropTypes.func.isRequired,
  recipients: PropTypes.array,
  isOneStepPublication: PropTypes.bool.isRequired,
  resetFileValue: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  document,
  category,
  config,
  recipient,
  ...state
}) => {
  return {
    isSaving: document.isSaving,
    jobId: document.jobId,
    categories: category.records.reduce((arr, value) => {
      if (!value.isActive || value.isInProgress) {
        return arr;
      }

      arr.push({
        value: value.path,
        label: value.path,
      });

      return arr;
    }, []),
    enablePrivate: config.enablePrivate,
    enableSigning: config.enableSigning,
    isOneStepPublication: config.publicationType === PUBLICATION_TYPE.ONE_STEP,
    recipients: recipient.records.reduce((arr, value) => {
      arr.push({
        value: value.id,
        label: `${value.firstName} ${value.lastName}, ${value.phoneNumber}`,
      });

      return arr;
    }, []),
  };
};

const mapDispatchToProps = (dispatch) => ({
  prepareDocument: (values, meta) => dispatch(requestPrepare(values, meta)),
  publishDocument: (values, meta) => dispatch(requestPublish(values, meta)),
  loadRecipients: () => dispatch(requestRecipientsList({ isActive: true })),
  loadCategories: () => dispatch(requestCategoriesList({ isActive: true })),

  resetFileValue: () => dispatch(change('saveDocumentForm', 'file', null)),
  fileDownload: (address, jobId) => dispatch(requestDownload(address, jobId)),
});

export default withRouter(
  reduxForm({
    form: 'saveDocumentForm',
    validate: formValidator,
  })(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateForm))),
);
