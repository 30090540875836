import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  SecondaryBadge,
  SecondaryDarkerBadge,
  SuccessBadge,
  FailureBadge,
} from '../../../../../../ui/Badge';
import { PUBLICATION_MODES, DOCUMENT_STATUS } from 'js/constraints';
import * as Styled from './styled';
import { Utils } from 'billon-ui';

const { Text } = Utils;

const BadgedTitle = ({ title, errata, publicationMode, status }) => {
  return (
    <Styled.HeaderRow>
      <Text as={'h1'} fontSize={'20px'} margin={'0 0 0 0'} fontWeight={700}>
        {title}
      </Text>
      {publicationMode === PUBLICATION_MODES.CORRECTED &&
        status === DOCUMENT_STATUS.CURRENT && (
          <SecondaryBadge margin={'0 0 0 15px'}>
            <FormattedMessage id="Erratum" defaultMessage="Erratum" />
          </SecondaryBadge>
        )}
      {publicationMode === PUBLICATION_MODES.UPDATED && (
        <SuccessBadge margin={'0 0 0 15px'}>
          <FormattedMessage id="New version" defaultMessage="New version" />
        </SuccessBadge>
      )}
      {publicationMode === PUBLICATION_MODES.INCIDENT &&
        status === DOCUMENT_STATUS.CURRENT && (
          <FailureBadge margin={'0 0 0 15px'}>
            <FormattedMessage id="Incident" defaultMessage="Incident" />
          </FailureBadge>
        )}
      {status === DOCUMENT_STATUS.DEPRECATED && (
        <SecondaryDarkerBadge margin={'0 0 0 15px'}>
          <FormattedMessage
            id="Previous Version"
            defaultMessage="Previous Version"
          />
        </SecondaryDarkerBadge>
      )}
    </Styled.HeaderRow>
  );
};

export default BadgedTitle;
