import React from 'react';
import { Grid, Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';
import * as formatters from '../../../../formatters';

const { Text } = Utils;
const { Row } = Grid;

const DetailsSection = ({ user }) => {
  const enabledDetailsCol1 = [
    {
      title: 'Login',
      data: user.username,
    },
    {
      title: 'First name',
      data: user.firstName,
    },
    {
      title: 'Last name',
      data: user.lastName,
    },
    {
      title: 'Status',
      data: formatters.userStatusFormatter(user.status),
    },
  ];

  const enabledDetailsCol2 = [
    {
      title: 'Email',
      data: user.email,
    },
    {
      title: 'Phone number',
      data: user.phoneNumber,
    },
    {
      title: 'Department',
      data: user.department,
    },
  ];

  const enabledDetailsCol3 = [
    {
      title: 'Account type',
      data: formatters.roleFormatter('', user),
    },
    {
      title: 'Created at',
      data: formatters.createdAtFormatter('', user),
    },
    {
      title: 'Created by',
      data: `${user.createdBy.firstName} ${user.createdBy.lastName}`,
    },
  ];

  return (
    <>
      <Row margin={0} md={12}>
        <Styled.DetailsHeader md={12}>
          <Text as={'h1'} fontSize={'18px'} fontWeight={700}>
            <FormattedMessage id="Details" defaultMessage="Details" />:
          </Text>
          <Text as={'h1'} fontSize={'18px'} fontWeight={700}>
            <FormattedMessage id="Active3" defaultMessage="Active3" />
            {': '}
            {user.isActive ? (
              <FormattedMessage id="Yes" defaultMessage="Yes" />
            ) : (
              <FormattedMessage id="No" defaultMessage="No" />
            )}
          </Text>
        </Styled.DetailsHeader>
      </Row>
      <Row margin={0} md={12}>
        <Styled.DetailsCol md={4}>
          {enabledDetailsCol1.map((v) => (
            <Row margin={0}>
              <Text margin="0 10px 0 0" fontSize="13px" fontWeight={700}>
                <FormattedMessage id={v.title} defaultMessage={v.title} />:
              </Text>
              <Text fontSize="13px">{v.data}</Text>
            </Row>
          ))}
        </Styled.DetailsCol>

        <Styled.DetailsCol md={4}>
          {enabledDetailsCol2.map((v) => (
            <Row margin={0}>
              <Text margin="0 10px 0 0" fontSize="13px" fontWeight={700}>
                <FormattedMessage id={v.title} defaultMessage={v.title} />:
              </Text>
              <Text fontSize="13px">{v.data}</Text>
            </Row>
          ))}
        </Styled.DetailsCol>

        <Styled.DetailsCol md={4}>
          {enabledDetailsCol3.map((v) => (
            <Row margin={0}>
              <Text margin="0 10px 0 0" fontSize="13px" fontWeight={700}>
                <FormattedMessage id={v.title} defaultMessage={v.title} />:
              </Text>
              <Text fontSize="13px">{v.data}</Text>
            </Row>
          ))}
        </Styled.DetailsCol>
      </Row>
    </>
  );
};

export default DetailsSection;
