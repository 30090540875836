import React, { useState } from 'react';
import * as Styled from './styled';
import { Utils, Modal as ModalModule } from 'billon-ui';
import { useCompileNotificationMessages } from '../../hooks/useCompileNotificationMessages';
import { FormattedMessage } from 'react-intl';
import { RenderedNotificationContainer } from '../RenderedNotificationContainer/RenderedNotificationContainer';
import renderHTML from 'react-render-html';

const { Button: ButtonModule } = Utils;
const { IconButton } = ButtonModule;
const { Modal } = ModalModule;

export const SummaryNotifications = ({ smsContentInput, emailContentInput }) => {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isSmsModalOpen, setIsSmsModalOpen] = useState(false);

  const {
    emailPreviewValue,
    smsContentPreviewValue,
    isLoading,
    error,
  } = useCompileNotificationMessages(emailContentInput, smsContentInput);

  const handleToggleEmailModal = () => {
    setIsEmailModalOpen(!isEmailModalOpen);
  }

  const handleToggleSmsModal = () => {
    setIsSmsModalOpen(!isSmsModalOpen);
  }

  if(isLoading || error) {
    return null;
  }

  return (
    <>
      <Styled.SummaryContainer>
        <Styled.IconButtonWrapper>
          <IconButton icon="envelope" iconSize="2x" onClick={handleToggleEmailModal} />
        </Styled.IconButtonWrapper>
        <Styled.IconButtonWrapper>
          <IconButton icon="comment-alt" iconSize="2x" onClick={handleToggleSmsModal} />
        </Styled.IconButtonWrapper>
      </Styled.SummaryContainer>
      <Modal
        toggle={handleToggleEmailModal}
        isOpen={isEmailModalOpen}
        title={(<FormattedMessage id="Email notification" defaultMessage="Email notification" /> )}
      >
        <RenderedNotificationContainer>
          { renderHTML(emailPreviewValue) }
        </RenderedNotificationContainer>
      </Modal>
      <Modal
        toggle={handleToggleSmsModal}
        isOpen={isSmsModalOpen}
        title={(<FormattedMessage id="SMS notification" defaultMessage="SMS notification" /> )}
      >
        <RenderedNotificationContainer>
          { smsContentPreviewValue }
        </RenderedNotificationContainer>
      </Modal>
    </>
  );
}
