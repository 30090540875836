import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';
import apiRoutes from '../../../api-routes';
import { postRequest } from '../../../helpers/apiClient';

export const useValidatePassword = (options) => {
  const token = options?.token;

  return useMutationWithLogout(
    async ({ data }) =>
      postRequest({
        url: apiRoutes.VALIDATE_PASSWORD,
        data,
        headers: {
          Authorization: token ? token : sessionStorage.getItem('token'),
        },
      }),

    options,
  );
};
