import { Grid, Utils } from 'billon-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const { Row, Col } = Grid;

const { Button: ButtonModule, Text } = Utils;
const { Button } = ButtonModule;

export const PageHeaderForRemoteSignDocs = (props) => {
  return (
    <Row margin={0}>
      <Col padding={0} xl={2}>
        <Text as="h4" fontWeight="700">
          <FormattedMessage
            id="Documents status"
            defaultMessage="Documents status"
          />
        </Text>
      </Col>
      <Col
        padding={0}
        margin={'0 0 20px 0'}
        xl={{
          size: 2,
          offset: 8,
        }}
      >
        <Button width={'100%'} onClick={props.toggleCreateNewDocument}>
          <FormattedMessage
            id="New document to sign"
            defaultMessage="New document to sign"
          />
        </Button>
      </Col>
    </Row>
  );
};
