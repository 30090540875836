import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Form as FormModule } from 'billon-ui';
import { useIntl } from 'react-intl';

import styled from 'styled-components';

const { Form, SearchBarField } = FormModule;

const StyledForm = styled(Form)`
  max-height: '42px';
`;

const SearchBar = () => {
  const intl = useIntl();
  return (
    <StyledForm>
      <Field
        component={SearchBarField}
        name="searchBar"
        className="search-bar"
        classic
        placeholder={intl.formatMessage({
          id: 'Name and surname',
          defaultMessage: 'Name and surname',
        })}
      />
    </StyledForm>
  );
};

export default reduxForm({
  form: 'searchBarForm',
})(SearchBar);
