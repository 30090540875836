import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PUBLICATION_ERROR_DETAILS } from '../../../../constraints';

const PublishingErrorDetails = ({ status }) => (
  <h6>
    {
      {
        [PUBLICATION_ERROR_DETAILS.DOCUMENT_CONTENT_EMPTY]: (
          <FormattedMessage
            id="The document must have content."
            defaultMessage="The document must have content."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.DOCUMENT_NOT_SIGNED]: (
          <FormattedMessage
            id="Document not signed."
            defaultMessage="Document not signed."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.SIGNATURE_CERTIFICATE_EXPIRED]: (
          <FormattedMessage
            id="Signature certificate expired."
            defaultMessage="Signature certificate expired."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.DOCUMENT_CONTENT_WRONG]: (
          <FormattedMessage
            id="Document content wrong."
            defaultMessage="Document content wrong."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.SIGNATURE_DOCUMENT_ALTERED]: (
          <FormattedMessage
            id="Document edited after signing."
            defaultMessage="Document edited after signing."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.DOCUMENT_PROTECTED]: (
          <FormattedMessage
            id="The document is damaged."
            defaultMessage="The document is damaged."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.DOCUMENT_DUPLICATED]: (
          <FormattedMessage
            id="Document is already published on blockchain."
            defaultMessage="Document is already published on blockchain."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.DOCUMENT_EXTENSION_WRONG]: (
          <FormattedMessage
            id="Document extension is invalid."
            defaultMessage="Document extension is invalid."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.DOCUMENT_NOT_PDF]: (
          <FormattedMessage
            id="Incorrect format of transmitted document. The document must be in pdf format."
            defaultMessage="Incorrect format of transmitted document. The document must be in pdf format."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.DOCUMENT_TOO_LARGE]: (
          <FormattedMessage
            id="Allowed file size exceeded."
            defaultMessage="Allowed file size exceeded."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.DOCUMENT_NOT_IDENTICAL]: (
          <FormattedMessage
            id="Document not identical."
            defaultMessage="Document not identical."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.WRONG_AGREEMENT_TYPE]: (
          <FormattedMessage
            id="Incorrect or not provided contract type."
            defaultMessage="Incorrect or not provided contract type."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.WRONG_REGISTRATION_NUMBER]: (
          <FormattedMessage
            id="Incorrect or not provided registration number."
            defaultMessage="Incorrect or not provided registration number."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.WRONG_PAYER_NUMBER]: (
          <FormattedMessage
            id="Incorrect or not provided payer number."
            defaultMessage="Incorrect or unsubscribed payer number."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.EMPTY_CONTACT_DATA]: (
          <FormattedMessage
            id="Customer's phone number or email address not provided."
            defaultMessage="Customer's phone number or email address not provided."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.WRONG_HTML_FILE]: (
          <FormattedMessage
            id="From the uploaded HTML file it is not possible to create a PDF."
            defaultMessage="From the uploaded HTML file it is not possible to create a PDF."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.CLIENT_CONTACT_DATA_NOT_VALID]: (
          <FormattedMessage
            id="Invalid email address or phone number."
            defaultMessage="Invalid email address or phone number."
          />
        ),
        [PUBLICATION_ERROR_DETAILS.WRONG_FACSIMILE_FILENAME]: (
          <FormattedMessage
            id="Invalid facsimile file name."
            defaultMessage="Invalid facsimile file name."
          />
        ),
      }[status]
    }
  </h6>
);

PublishingErrorDetails.propTypes = {
  status: PropTypes.string.isRequired,
  details: PropTypes.object,
};

export default PublishingErrorDetails;
