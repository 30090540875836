import React from 'react';

import * as Styled from './styled';

const UserInfo = ({ name, scale, iconDisabled, children }) => {
  return (
    <Styled.InfoContainer>
      {!iconDisabled && (
        <Styled.InfoIconContainer>
          <Styled.InfoIcon scale={scale} name="user-circle" />
        </Styled.InfoIconContainer>
      )}
      <Styled.UserNameContainer>
        <Styled.UserName>
          <Styled.Strong>{name}</Styled.Strong>
        </Styled.UserName>
        {children}
      </Styled.UserNameContainer>
    </Styled.InfoContainer>
  );
};

export default UserInfo;
