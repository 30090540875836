/* global sessionStorage */
import { ajax } from 'rxjs/observable/dom/ajax';
import { Observable } from 'rxjs/Rx';
import { Utils } from 'billon-ui';
import download from 'downloadjs';
import {
  requestList,
  receiveListSuccess,
  receiveListFailure,
  requestSingle,
  receiveSingleSuccess,
  receiveSingleFailure,
  sendRequestDecision,
  receiveSendRequestDecisionSuccess,
  receiveSendRequestDecisionFailure,
  requestDownload,
  receiveDownloadSuccess,
  receiveDownloadFailure,
} from './actions';
import routes from '../../api-routes';

import { parseFilters, parseSendDecision } from './helpers';

const { helpers } = Utils;
const { request, encodeQueryString } = helpers;
const {
  METHOD_GET,
  HEADER_CONTENT_TYPE,
  CONTENT_TYPE_APPLICATION_JSON,
  X_ACCESS_TOKEN,
  METHOD_POST,
} = request;

const getRequestsListEpic = (actions$) =>
  actions$.ofType(requestList.getType()).mergeMap((action) =>
    ajax({
      url: `${routes.REQUEST}?options=${encodeQueryString(
        parseFilters(action.payload.filters),
      )}`,
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => receiveListSuccess(response))
      .takeUntil(actions$.ofType(requestList.getType()))
      .catch((err) => Observable.of(receiveListFailure(err))),
  );

const getRequestsSingleEpic = (actions$) =>
  actions$.ofType(requestSingle.getType()).mergeMap((action) =>
    ajax({
      url: routes.SINGLE_REQUEST.replace('{id}', action.payload.id),
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => receiveSingleSuccess(response))
      .takeUntil(actions$.ofType(requestSingle.getType()))
      .catch((err) => Observable.of(receiveSingleFailure(err))),
  );

const sendRequestDecisionEpic = (actions$) =>
  actions$.ofType(sendRequestDecision.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { values } = action.payload;

    return ajax({
      url: routes.SEND_REQUEST_DECISION,
      body: parseSendDecision(values),
      headers: {
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_POST,
    })
      .map(({ response }) => {
        if (resolve) {
          resolve(response);
        }
        return receiveSendRequestDecisionSuccess(response.jobId);
      })
      .takeUntil(actions$.ofType(sendRequestDecision.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }
        return Observable.of(receiveSendRequestDecisionFailure(err));
      });
  });

const downloadDocumentEpic = (actions$) =>
  actions$.ofType(requestDownload.getType()).mergeMap((action) =>
    ajax({
      url: `${routes.SINGLE_DOCUMENT_DOWNLOAD}?blockchainAddress=${action.payload.blockchainAddress}&jobId=${action.payload.jobId}`,
      headers: {
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => {
        const blob = new Uint8Array(response.fileBuffer.data);
        download(
          blob,
          response.fileName.includes('.pdf')
            ? response.fileName
            : `${response.fileName}.pdf`,
        );
        return receiveDownloadSuccess(response);
      })
      .takeUntil(actions$.ofType(requestDownload.getType()))
      .catch((err) => Observable.of(receiveDownloadFailure(err))),
  );

export default [
  getRequestsListEpic,
  getRequestsSingleEpic,
  downloadDocumentEpic,
  sendRequestDecisionEpic,
];
