import { createReducer } from 'redux-act';
import * as actions from './actions';

export default createReducer(
  {
    // List
    [actions.requestList]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.requestListQuiet]: (state) => ({
      ...state,
      isFetchingQuietly: true,
    }),
    [actions.receiveListSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      isFetchingQuietly: false,
      records: payload.rows,
      numberOfRecords: payload.count,
      agreementsCountByStatus: payload.agreementsCountByStatus,
    }),
    [actions.receiveListFailure]: (state) => ({
      ...state,
      isFetchingQuietly: false,
      isFetching: false,
    }),

    // Single
    [actions.requestSingle]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.receiveSingleSuccess]: (state, payload) => ({
      ...state,
      singleRecord: payload,
      isFetching: false,
      didFetched: true,
    }),
    [actions.receiveSingleFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),

    [actions.requestDownload]: (state, payload) => ({
      ...state,
      isDownloading: state.isDownloading.concat(
        [payload.documentBlockchainAddress, payload.jobId].filter((v) => !!v),
      ),
    }),
    [actions.receiveDownloadSuccess]: (state, payload) => ({
      ...state,
      isDownloading: state.isDownloading.filter(
        (v) => payload.blockchainAddress === v,
      ),
    }),
    [actions.receiveDownloadFailure]: (state, payload) => ({
      ...state,
      isDownloading: state.isDownloading.filter(
        (v) => payload.blockchainAddress === v,
      ),
    }),

    [actions.requestDownloadRecipientsFile]: (state) => ({
      ...state,
      isDownloadingRecipientsList: true,
    }),
    [actions.receiveDownloadRecipientsFileSuccess]: (state) => ({
      ...state,
      isDownloadingRecipientsList: false,
    }),
    [actions.receiveDownloadRecipientsFileFailure]: (state) => ({
      ...state,
      isDownloadingRecipientsList: false,
    }),
  },
  {
    isFetching: false,
    isSaving: false,
    didFetched: false,
    isDownloading: [],
    isDownloadingRecipientsList: false,
    isDeleting: false,
    records: [],
    agreementsCountByStatus: [],
    singleRecord: {},
    numberOfRecords: 0,
    isResendingAuthorizationCodes: false,
  },
);
