import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { screens as HomePageScreens } from '../../modules/HomePage';
import { scenes as LoginScenes } from 'js/modules/Login';
import * as UserScreens from 'js/modules/UsersNew/screens';
import appRoutes from 'js/app-routes';

const { HelpPage } = HomePageScreens;
const { HomePage: LoginPage } = LoginScenes;
const { SetFirstPasswordPage } = UserScreens;

const UnauthorizedRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route path={appRoutes.SUPPORT} component={HelpPage} />
        <Route path={appRoutes.PASSWORD_SET} component={SetFirstPasswordPage} />
        <Route path={appRoutes.LOGIN} component={LoginPage} />
        <Route path="/" component={LoginPage} />
      </Switch>
    </Router>
  );
};

export default UnauthorizedRoutes;
