import { createReducer } from 'redux-act';
import * as actions from './actions';

export default createReducer(
  {
    // List
    [actions.requestList]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.receiveListSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      records: payload.rows,
      numberOfRecords: payload.count,
    }),
    [actions.receiveListFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),

    // Single
    [actions.requestSingle]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.receiveSingleSuccess]: (state, payload) => ({
      ...state,
      singleRecord: payload,
      isFetching: false,
      didFetched: true,
    }),
    [actions.receiveSingleFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),

    // prepare&publish
    [actions.sendRequestDecision]: (state) => ({
      ...state,
      isSendingRequestDecision: true,
    }),
    [actions.sendRequestDecision]: (state) => ({
      ...state,
      isSendingRequestDecision: false,
    }),
    [actions.sendRequestDecision]: (state) => ({
      ...state,
      isSendingRequestDecision: false,
    }),

    // blockchain address version
    [actions.requestDownload]: (state, payload) => ({
      ...state,
      isDownloading: state.isDownloading.concat([payload.blockchainAddress]),
    }),
    [actions.receiveDownloadSuccess]: (state, payload) => ({
      ...state,
      isDownloading: state.isDownloading.filter(
        (v) => payload.blockchainAddress === v,
      ),
    }),
    [actions.receiveDownloadFailure]: (state, payload) => ({
      ...state,
      isDownloading: state.isDownloading.filter(
        (v) => payload.blockchainAddress === v,
      ),
    }),
  },
  {
    isFetching: false,
    isSendingRequestDecision: false,
    didFetched: false,
    isDownloading: [],
    records: [],
    singleRecord: {},
    numberOfRecords: 0,
  },
);
