import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import { Form as FormModule, Utils, Grid } from 'billon-ui';
const { Button: ButtonModule, Text } = Utils;
const { Row } = Grid;

export const FormHeader = styled(Text)`
  font-size: 2rem;
  margin-bottom: 22px;
  margin-top: -3.5rem;
  width: calc(100% - 35px);
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

export const FormGroup = styled(FormModule.FormGroup)`
  position: relative;
`;

export const IconButton = styled(ButtonModule.IconButton)`
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
`;

export const CenterRow = styled(Row)`
  margin: 0;
  align-items: center;
  padding: 2rem 0 1rem;
  flex-direction: row;
  justify-content: center;
`;

export const BackButton = styled(ButtonModule.BackButton)`
  ${down('lg')} {
    width: 100%;
    margin: 0.5rem 0;
  }
`;

export const TextDanger = styled(Text)`
  color: #ad113b;
`;

export const EditPanel = styled.div`
  border: 1px solid #c9d4de;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow-y: auto;
`;
