import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';

import * as Styled from './styled';

import { Form as FormModule, Grid, Utils } from 'billon-ui';

// Components
import { CalendarField } from '../../../../../../containers';
import { DropzonePlaceholder } from '../DropzonePlaceholder';
import { SelectField } from '../../../../../../components';

// Hooks
import { useAuthenticatedContext } from 'js/modules/Auth/hooks/useAuthenticated';
import { useIdentityList } from '../../../../../Recipients/hooks/useIdentityList';
import { useCategoryList } from '../../../../../CategoriesNew/hooks/useCategoryList';

// Schema
import { useSchema } from './useSchema';

const { FormGroup, TextField, UploadField } = FormModule;
const { Button: ButtonModule, Text } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { Row, Col } = Grid;

export const DocumentAtrributesForm = ({
  step,
  initialValues,
  onSuccess,
  onCreateIdentity,
  onCreateCategory,
  submitButtonText,
  onBack,
}) => {
  const { formatMessage } = useIntl();
  const schema = useSchema();

  const { user } = useAuthenticatedContext();
  const [categoryName, setCategoryName] = React.useState('');
  const [identityDocumentNumber, setIdentityDocumentNumber] =
    React.useState('');
  const setSearchTextDebounced = React.useRef(
    _.debounce((callback) => callback(), 700),
  ).current;

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch,
  } = useForm({
    defaultValues: {
      publishedBy: `${user.firstName} ${user.lastName}`,
      title: '',
      category: '',
      validSince: '',
      identity: '',
      file: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    data: studentsData,
    refetch: refetchStudents,
    isFetching: isStudentDataFetching,
  } = useIdentityList({
    filters: {
      ...(identityDocumentNumber
        ? { documentNumber: identityDocumentNumber }
        : undefined),
    },
    pagination: {
      limit: 20,
    },
  });
  const {
    data: categoriesData,
    refetch: refetchCategories,
    isFetching: isCategoriesDataFetching,
  } = useCategoryList({
    filters: {
      ...(categoryName ? { name: categoryName } : undefined),
    },
    singleLevel: false,
    pagination: {
      limit: 20,
    },
  });

  const { rows: studentsRows } = studentsData || {};
  const { rows: categoriesRows } = categoriesData || {};

  const students = React.useMemo(
    () =>
      (studentsRows || []).reduce((arr, student) => {
        if (student.isActive && !student.isGdprSuspended) {
          arr.push({
            value: student.id,
            label: `${student.documentNumber} ${student.firstName} ${student.lastName}`,
          });
        }
        return arr;
      }, []),
    [studentsRows],
  );

  const categories = React.useMemo(
    () =>
      (categoriesRows || []).reduce((arr, category) => {
        if (category.isActive) {
          arr.push({
            value: category.id,
            label: category.fullPath,
          });
        }
        return arr;
      }, []),
    [categoriesRows],
  );

  const onChangeCategoryInput = (inputText) => {
    if (inputText !== categoryName) {
      setSearchTextDebounced(() => setCategoryName(inputText));
    }
  };

  const onChangeIdentityInput = (inputText) => {
    if (inputText !== identityDocumentNumber) {
      setSearchTextDebounced(() => setIdentityDocumentNumber(inputText));
    }
  };

  return (
    <Styled.Form onSubmit={handleSubmit((values) => onSuccess(values))}>
      <Text as="h2" fontWeight={800}>
        <FormattedMessage
          id="Publish diploma"
          defaultMessage="Publish diploma"
        />
      </Text>
      <br />

      <Row>
        <Col xl={4}>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Published by" defaultMessage="Published by" />
          </Text>
          <FormGroup>
            <Controller
              name="publishedBy"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  disabled
                />
              )}
            />
          </FormGroup>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage
              id="Diploma number"
              defaultMessage="Diploma number"
            />
          </Text>
          <FormGroup>
            <Controller
              name="title"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  component={TextField}
                  disabled={step > 1 || watch('errata')}
                  placeholder={formatMessage({
                    id: 'Document number',
                    defaultMessage: 'Document number',
                  })}
                />
              )}
            />
          </FormGroup>

          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Category" defaultMessage="Category" />
          </Text>

          <Styled.FormGroup>
            <Controller
              name="category"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  input={{
                    ...field,
                    onInputChange: (inputText) =>
                      onChangeCategoryInput(inputText),
                  }}
                  meta={{
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  options={categories}
                  disabled={step > 1}
                  placeholder={formatMessage({
                    id: 'Select category',
                    defaultMessage: 'Select category',
                  })}
                  isLoading={isCategoriesDataFetching}
                />
              )}
            />
            {step === 1 && (
              <Styled.IconButton
                icon="folder-plus"
                onClick={() => onCreateCategory(refetchCategories)}
              />
            )}
          </Styled.FormGroup>
        </Col>

        <Col xl={4}>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage
              id="Date of issue of the diploma"
              defaultMessage="Date of issue of the diploma"
            />
          </Text>
          <FormGroup>
            <Controller
              name="validSince"
              control={control}
              render={({ field, fieldState }) => (
                <CalendarField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  enableTime={false}
                  dateFormat="Y-m-d"
                  minDate={undefined}
                  maxDate={new Date()}
                  disabled={step > 1}
                  key={step}
                  placeholder={formatMessage({
                    id: 'Select date of issue of the diploma',
                    defaultMessage: 'Select date of issue of the diploma',
                  })}
                />
              )}
            />
          </FormGroup>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Student" defaultMessage="Student" />
          </Text>
          <Styled.FormGroup>
            <Controller
              name="identity"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  input={{
                    ...field,
                    onInputChange: (inputText) =>
                      onChangeIdentityInput(inputText),
                  }}
                  meta={{
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  options={students}
                  disabled={step > 1 || !!initialValues.identity}
                  placeholder={formatMessage({
                    id: "Select student's document number",
                    defaultMessage: "Select student's document number",
                  })}
                  isLoading={isStudentDataFetching}
                />
              )}
            />
            {step === 1 && (
              <Styled.IconButton
                icon="user-plus"
                onClick={() => onCreateIdentity(refetchStudents)}
              />
            )}
          </Styled.FormGroup>
        </Col>

        <Col xl={4}>
          <FormGroup>
            <Controller
              name="file"
              control={control}
              render={({ field, fieldState }) => (
                <UploadField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: errors?.[field.name]?.message,
                  }}
                  accept={['application/pdf']}
                  disabled={step === 2}
                  placeholder={() => (
                    <DropzonePlaceholder initialValues={initialValues}>
                      <div>
                        <FormattedMessage
                          id="Drag and drop here PDF max 2MB"
                          defaultMessage="Drag and drop here PDF max 2MB"
                        />
                      </div>
                      <div>
                        <FormattedMessage id="or" defaultMessage="or" />
                      </div>
                      <Button outline type="button">
                        <FormattedMessage
                          id="Select from disk"
                          defaultMessage="Select from disk"
                        />
                      </Button>
                    </DropzonePlaceholder>
                  )}
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>

      <Styled.CenterRow>
        <Col md={12} xl={3}>
          {step === 2 && (
            <Styled.BackButton secondary onClick={onBack}>
              <FormattedMessage id="back" defaultMessage="back" />
            </Styled.BackButton>
          )}
        </Col>

        <Col xl={6}>
          {!isSubmitting ? (
            <Button type="submit" size="lg" block>
              <FormattedMessage
                id={submitButtonText}
                defaultMessage={submitButtonText}
              />
            </Button>
          ) : (
            <ButtonLoader size="lg" block />
          )}
        </Col>
      </Styled.CenterRow>
    </Styled.Form>
  );
};
