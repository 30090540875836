import React from 'react';
import { featureEnabled as featureEnabledHelper } from '../helpers/featureEnabled';
import { useConfigContext } from './useConfig';

const useFeatureEnabled = () => {
  const { customer, config } = useConfigContext();

  const featureEnabled = (path, customers) =>
    featureEnabledHelper(config, path, customer, customers);

  return { featureEnabled };
};

export default useFeatureEnabled;
