import React, { createContext, useContext } from 'react';
import config from 'js/config';
import { CUSTOMER } from '../../../constraints';

const ConfigContext = createContext(false);

export const useConfigContext = () => useContext(ConfigContext);

export const ConfigContextProvider = ({ children }) => {
  const { customer } = config;

  const isDiploma = customer === CUSTOMER.DIPLOMA;

  return (
    <ConfigContext.Provider
      value={{
        customer,
        config,
        isDiploma,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
