import styled from 'styled-components';

import { Form as FormModule, Grid } from 'billon-ui';
const { Row } = Grid;

export const Form = styled(FormModule.Form)`
  h2 {
    font-size: 2rem;
    margin-bottom: 2.5rem;
    margin-top: -3.5rem;
    width: calc(100% - 35px);
  }
`;

export const CenterRow = styled(Row)`
  justify-content: center;
  width: 100%;
`;

export const SubmitButtonRow = styled(CenterRow)`
  padding: 2rem 0 1rem;
`;
