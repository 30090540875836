import {
  Dropdown as DropdownModule,
  Authentication,
  Table as TableModule,
  Utils,
} from 'billon-ui';
import keyBy from 'lodash/keyBy';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import CommonTable, {
  CommonTableContext,
} from '../../../../components/CommonTable';
import { getSearchParams } from '../../../../components/CommonTable/helpers';
import * as formatters from '../../../../formatters';
import { applyFilters as applyFiltersAction } from '../../../Categories/actions';
import { requestList, requestListQuiet, requestToggle } from '../../actions';
import {
  StyledCloseFilterButton,
  StyledFilterButton,
  StyledFiltersWrapper,
  StyledIconButton,
  SwitchField,
} from './components';
import { CategoryAutocompleteEntry } from './types';
import styled from 'styled-components';
import { ProtectedComponent } from '../../../../ui/Authentication';
import { UserRoles } from '../../../../userRoles';

const { TableColumn } = TableModule;
const { Icon, Text } = Utils;
const { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } =
  DropdownModule;

export const StyledDropDownDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const MyDropdownToggle = styled(DropdownToggle)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 0 0 0;
  &:after {
    display: none;
  }
`;

const CommonDocumentTable: React.FC<{
  handleCreate: (initialValues, callback) => void;
  handleDelete: (id, callback) => void;
}> = ({ handleCreate, handleDelete }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const applyFilters = (params: any, path?: string) =>
    applyFiltersAction(history, params, path);

  const {
    isFetching,
    isFetchingQuietly,
    limit,
    numberOfRecords,
    records,
    allParentCategoriesDetails,
    allParentCategoriesListFiltered,
  } = useSelector(({ config, category }: any) => ({
    isFetching: category.isFetching,
    isFetchingQuietly: category.isFetchingQuietly,
    limit: config.limit as number,
    numberOfRecords: category.numberOfRecords,
    records: category.records,
    allParentCategoriesListFiltered: category.allParentCategoriesListFiltered,
    allParentCategoriesDetails: keyBy(
      category.allParentCategoriesDetails as CategoryAutocompleteEntry,
      'name',
    ) as unknown as {
      [categoryName: string]: CategoryAutocompleteEntry | null;
    },
  }));

  const { filters: rawSearchParams } = useParams<{ filters: string }>();

  const searchParams = getSearchParams(rawSearchParams, limit);

  const load = useCallback(
    (searchParamsReload) =>
      dispatch(
        requestList({
          ...searchParamsReload,
          include: ['category', 'publishedBy', 'recipients'],
        }),
      ),
    [dispatch],
  );

  const loadQuiet = useCallback(
    (searchParamsReload) =>
      dispatch(
        requestListQuiet({
          ...searchParamsReload,
          include: ['category', 'publishedBy', 'recipients'],
        }),
      ),
    [dispatch],
  );

  const onToggle = useCallback(
    (value, callback) => dispatch(requestToggle(value, callback)),
    [dispatch],
  );

  const handleToggle = (values) => {
    return new Promise((resolve, reject) => {
      onToggle(values, {
        resolve,
        reject,
      });
    })
      .then(() => {
        load(searchParams);
      })
      .catch(() => {});
  };
  const switchFormat = (value, row) => {
    const isParentActive = !!(row.parentPath &&
    allParentCategoriesDetails[row.parentPath]
      ? allParentCategoriesDetails[row.parentPath]?.isActive
      : true);

    return (
      <>
        <ProtectedComponent
          // @ts-ignore
          roles={[
            UserRoles.VISITOR,
            UserRoles.AGENT,
            UserRoles.ADMIN,
            UserRoles.DPI,
          ]}
          render={false}
        >
          <SwitchField
            value={value}
            className={!isParentActive || row.isInProgress ? 'disabled' : ''}
            input={{
              checked: value,
              onChange: () => {
                if (!isParentActive) {
                  alert(
                    intl.formatMessage({
                      id: 'Parent category must be active',
                      defaultMessage: 'Parent category must be active',
                    }),
                  );
                  return;
                }
                if (!row.isInProgress) {
                  handleToggle(row);
                }
              },
            }}
            slim
            meta={{
              dirty: true,
              submitting: row.isInProgress,
            }}
          />
        </ProtectedComponent>
        <ProtectedComponent
          //@ts-ignore
          roles={[
            UserRoles.VISITOR,
            UserRoles.AGENT,
            UserRoles.ADMIN,
            UserRoles.DPI,
          ]}
          render={false}
          reverse
        >
          {value ? <Icon name="check" /> : <Icon name="times" />}
        </ProtectedComponent>
      </>
    );
  };

  const changePublishedDocsType = (value, row) => {
    applyFilters(
      {
        type: value,
        category: {
          label: row.path,
          value: row.id || row.path,
          id: row.id,
        },
      },
      'documents',
    );
  };

  const detailsFormat = (id, row) => (
    <UncontrolledDropdown>
      <StyledDropDownDiv>
        <MyDropdownToggle children={<StyledIconButton icon="folder-open" />} />
      </StyledDropDownDiv>
      <DropdownMenu>
        <DropdownItem onClick={() => changePublishedDocsType('PRIVATE', row)}>
          <FormattedMessage id="Private2" defaultMessage="Private" />
        </DropdownItem>
        <DropdownItem onClick={() => changePublishedDocsType('PUBLIC', row)}>
          <FormattedMessage id="Public2" defaultMessage="Public" />
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  type Column = {
    name: string;
    message: string;
    formatter: Function;
    sortable?: boolean;
  };

  const columns: Array<Column | false> = [
    {
      name: 'name',
      message: 'Category name',
      formatter: formatters.boundLengthFormatterLong,
      sortable: true,
    },
    {
      name: 'path',
      message: 'Path',
      formatter: formatters.boundLengthFormatterLong,
    },
    {
      name: 'isInProgress',
      message: 'Status',
      formatter: (val) =>
        val ? (
          <FormattedMessage id="Updating" defaultMessage="Updating" />
        ) : (
          <FormattedMessage id="Completed" defaultMessage="Completed" />
        ),
    },
  ];

  return (
    <CommonTableContext.Provider
      // responsible for table dynamic data
      value={{
        load,
        refresh: loadQuiet,
        isFetching,
        isFetchingQuietly,
        numberOfRecords,
        records,
      }}
    >
      <CommonTable
        limit={10}
        addButtonLabel="Add category"
        title="Categories list"
        columns={columns}
        handleCreate={(initialValues) => handleCreate(initialValues, load)}
        withSearchBar={(values) =>
          applyFilters({
            searchBar: values ? values.searchBar : undefined,
          })
        }
        searchBarPlaceholder={intl.formatMessage({
          id: 'Search for category',
          defaultMessage: 'Search for category',
        })}
        currentSearchBar={searchParams.searchBar}
        filterAddonRenderer={() => (
          <StyledFiltersWrapper>
            {searchParams.parent && (
              <StyledCloseFilterButton
                outline
                fontSize="12px"
                fontWeight={600}
                onClick={() => applyFilters({ parent: undefined })}
              >
                {searchParams.parent}
                <Icon name="times-circle" regular />
              </StyledCloseFilterButton>
            )}

            {allParentCategoriesListFiltered &&
              !searchParams.parent &&
              allParentCategoriesListFiltered.map((record) => (
                <StyledFilterButton
                  key={record}
                  onClick={() => applyFilters({ parent: `${record}`, page: 1 })}
                  color={'#acbbc7'}
                  hoverColor={'#F9FBFC'}
                >
                  <Text as="p" className="title">
                    {record}
                  </Text>
                  {/* <Text as="p"  className="numberOfDocuments"><FormattedMessage id="Document" defaultMessage="Documents"/>: </Text> */}
                  <Text as="p" className="see">
                    <FormattedMessage id="See" defaultMessage="See" />
                  </Text>
                </StyledFilterButton>
              ))}
          </StyledFiltersWrapper>
        )}
      >
        <TableColumn
          className="fixed-width-140"
          fieldName="isActive"
          formatter={switchFormat}
        >
          <FormattedMessage id="Active" defaultMessage="Active" />
        </TableColumn>
        <TableColumn
          className="text-center fixed-width-140"
          fieldName="id"
          formatter={detailsFormat}
        >
          <FormattedMessage
            id="Show documents"
            defaultMessage="Show documents"
          />
        </TableColumn>
      </CommonTable>
    </CommonTableContext.Provider>
  );
};

export default CommonDocumentTable;
