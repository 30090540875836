import React, { useLayoutEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';
import { Utils } from 'billon-ui';

import base from 'js/config/base';

const StyledTooltip = styled.div`
   {
    background: #ffffff;
    padding: 4px;
    box-shadow: 2px 2px 9px 2px #cecece;
    p {
      padding: 0px;
      margin: 0px;
    }
  }
`;

const CustomTooltip = ({ active, payload, label, publishedDocumentType }) => {
  const { Icon } = Utils;
  const { theme } = base;
  if (active) {
    if (publishedDocumentType === 0)
      return (
        <StyledTooltip>
          {payload.length > 0 && (
            <p>
              <Icon name="square" color={theme.palette.billonGreen} />{' '}
              <FormattedMessage id="public2" default="public2" />:{' '}
              {payload[0].value}
            </p>
          )}
          {payload.length > 1 && (
            <p>
              <Icon name="square" color={theme.palette.billonBlue} />{' '}
              <FormattedMessage id="private2" default="private2" />:{' '}
              {payload[1].value}{' '}
            </p>
          )}
        </StyledTooltip>
      );
    else if (publishedDocumentType === 1) {
      return (
        <StyledTooltip>
          {payload.length > 0 && (
            <p>
              <Icon name="square" color={theme.palette.billonBlue} />{' '}
              <FormattedMessage id="private2" default="private2" />:{' '}
              {payload[0].value}{' '}
            </p>
          )}
        </StyledTooltip>
      );
    } else if (publishedDocumentType === 2) {
      return (
        <StyledTooltip>
          {payload.length > 0 && (
            <p>
              <Icon name="square" color={theme.palette.billonGreen} />{' '}
              <FormattedMessage id="public2" default="public2" />:{' '}
              {payload[0].value}{' '}
            </p>
          )}
        </StyledTooltip>
      );
    }
  }

  return null;
};

const PublishedDocsBarChart = (props) => {
  const { theme } = base;
  const [size, setSize] = useState([0, 0]);

  const useWindowSize = () => {
    useLayoutEffect(() => {
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  };

  const updateSize = () => {
    setSize([window.innerWidth, window.innerHeight]);
  };

  const [width] = useWindowSize();

  return (
    <div
      style={{
        width: width > 1400 ? '98%' : 600,
        height: 300,
      }}
    >
      <ResponsiveContainer>
        <BarChart width={600} height={440} data={props.data.report}>
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis dataKey="name" interval={0} />
          <YAxis allowDecimals={false} angle={1} />
          <Tooltip
            content={
              <CustomTooltip
                publishedDocumentType={props.publishedDocumentType}
              />
            }
          />
          {(props.publishedDocumentType === 0 ||
            props.publishedDocumentType === 2) && (
            <Bar
              dataKey="publicDocumentPublished"
              fill={theme.palette.billonGreen}
            />
          )}
          {(props.publishedDocumentType === 0 ||
            props.publishedDocumentType === 1) && (
            <Bar
              dataKey="privateDocumentPublished"
              fill={theme.palette.billonBlue}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PublishedDocsBarChart;
