import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Form as FormModule, Utils, Grid } from 'billon-ui';

import * as Styled from './styled';
import { useIntl } from 'react-intl';

import { PUBLICATION_MODES } from '../../../../constraints';
import { retainOptionsList } from '../../../../constraints';
import { retainOptionToString } from '../../../../formatters';

const { FormGroup, TextField, SelectField, UploadField, CalendarField } =
  FormModule;
const { validators, Text, Button: ButtonModule } = Utils;
const { Row, Col } = Grid;

const { Button, IconButton } = ButtonModule;

const DataTab = ({ initialValues, mode }) => {
  const intl = useIntl();

  const disableCondition = (modes) => {
    let modeArray = modes;
    if (!Array.isArray(modes)) {
      modeArray = [modes];
    }
    return modeArray.includes(mode);
  };

  const retainOptionsFormatted = retainOptionsList.map((option) => ({
    label: intl.formatMessage({
      id: retainOptionToString(option),
      defaultMessage: retainOptionToString(option),
    }),
    value: option,
  }));

  return (
    <>
      <Row>
        <Col xl={4} lg={4} md={4}>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Published by" defaultMessage="Published by" />
          </Text>
          <FormGroup>
            <Field
              name="publisherName"
              component={TextField}
              validate={[validators.required]}
              disabled={disableCondition([
                PUBLICATION_MODES.CORRECTED,
                PUBLICATION_MODES.UPDATED,
              ])}
            />
          </FormGroup>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Title" defaultMessage="Title" />
          </Text>
          <FormGroup>
            <Field
              name="title"
              component={TextField}
              validate={[validators.required]}
              disabled={disableCondition([PUBLICATION_MODES.CORRECTED])}
            />
          </FormGroup>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Category" defaultMessage="Category" />
          </Text>
          <FormGroup>
            <Field
              component={SelectField}
              name="selectedCategory"
              validate={[validators.required]}
              disabled={disableCondition([
                PUBLICATION_MODES.CORRECTED,
                PUBLICATION_MODES.UPDATED,
              ])}
            />
          </FormGroup>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage
              id="Document version"
              defaultMessage="Document version"
            />
          </Text>
          <FormGroup>
            <Field
              name="versionName"
              component={TextField}
              validate={[validators.required]}
              disabled={disableCondition([PUBLICATION_MODES.CORRECTED])}
            />
          </FormGroup>
        </Col>

        <Col xl={4} lg={4} md={4}>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage
              id="Valid from to"
              defaultMessage="Valid from to"
            />
          </Text>
          <FormGroup>
            <Field
              component={CalendarField}
              showMonths={2}
              name="validSinceUntil"
              range
              enableTime={false}
              dateFormat="Y-m-d"
              validate={[validators.required]}
              disabled={disableCondition([PUBLICATION_MODES.CORRECTED])}
            />
          </FormGroup>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Stored for" defaultMessage="Stored for" />
          </Text>
          <FormGroup>
            <Field
              component={SelectField}
              name="selectedStoredBy"
              options={retainOptionsFormatted}
              validate={[validators.required]}
              disabled={disableCondition([PUBLICATION_MODES.CORRECTED])}
            />
          </FormGroup>
        </Col>

        <Col xl={4} lg={4} md={4}>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Document2" defaultMessage="Document" />
          </Text>
          <FormGroup>
            <Field
              name="file"
              component={UploadField}
              accept={['application/pdf']}
              validate={[validators.required]}
              placeholder={(props) => (
                <Styled.DropzonePlaceholder {...props}>
                  <div>
                    <FormattedMessage
                      id="Drag and drop here PDF max 2MB"
                      defaultMessage="Drag and drop here PDF max 2MB"
                    />
                  </div>
                  <div>
                    <FormattedMessage id="or" defaultMessage="or" />
                  </div>
                  <Button outline type="button">
                    <FormattedMessage
                      id="Select from disk"
                      defaultMessage="Select from disk"
                    />
                  </Button>
                </Styled.DropzonePlaceholder>
              )}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

DataTab.propTypes = {
  initialValues: PropTypes.object,
};

export default DataTab;
