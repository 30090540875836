import React from 'react';

import { DOCUMENT_PUBLICATION_STATUS } from 'js/constraints';

import { CopiedInfo } from '../CopiedInfo';

export const JobIdDetails = ({ record }) => {
  if (
    ![
      DOCUMENT_PUBLICATION_STATUS.PREPARED_TO_SIGN,
      DOCUMENT_PUBLICATION_STATUS.PUBLISHING_ERROR,
      DOCUMENT_PUBLICATION_STATUS.PUBLISHING_INITIATED,
    ].includes(record.documentPublicationStatus)
  ) {
    return null;
  }

  return <CopiedInfo label="ID" value={record.jobId} />;
};
