import styled from 'styled-components';

export const SummaryContainer = styled.div`
  border: 1px dashed #c9d4de;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

export const IconButtonWrapper = styled.div`
  padding: 0.5rem;
`;
