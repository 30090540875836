import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import renderHTML from 'react-render-html';
import * as Styled from './styled';
import { Form as FormModule, Grid, Utils } from 'billon-ui';
import { ControlledTextAreaField } from '../../../../../../components/ControlledTextAreaField';
import { useCompileNotificationMessages } from '../../../../hooks/useCompileNotificationMessages';
import { FormFooter } from '../../../../../../components/FormFooter';
import { RenderedNotificationContainer } from '../../../RenderedNotificationContainer/RenderedNotificationContainer';

const { FormGroup } = FormModule;
const { Text } = Utils;
const { Col, Row } = Grid;

export const NotificationContentForm = ({
  onSubmit,
  onBack,
  disabledTitle,
  isSubmitting,
  defaultValues = {},
}) => {
  const formMethods = useForm({
    defaultValues: {
      smsContentInput: '',
      emailContentInput: '',
      ...defaultValues,
    },
  });

  const handleFormSubmit = (values) => {
    onSubmit(values);
  };

  const { handleSubmit, watch } = formMethods;

  const emailContentInput = watch('emailContentInput');
  const smsContentInput = watch('smsContentInput');

  const { emailPreviewValue, smsContentPreviewValue, isLoading, error } =
    useCompileNotificationMessages(emailContentInput, smsContentInput);

  if (isLoading) {
    return null;
  }

  if (error) {
    return <div> Error while loading templates...</div>;
  }

  return (
    <FormProvider {...formMethods}>
      <Styled.Form onSubmit={handleSubmit(handleFormSubmit)}>
        {!disabledTitle && (
          <Text as="h2" fontWeight={800}>
            <FormattedMessage
              id="Notification messages"
              defaultMessage="Notification messages"
            />
            <br />
          </Text>
        )}

        <Text as="h4" fontWeight={800}>
          <FormattedMessage
            id="Email notification"
            defaultMessage="Email notification"
          />
        </Text>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Text as="h6" fontSize="12px" fontWeight="600">
                <FormattedMessage
                  id="Enter message"
                  defaultMessage="Enter message"
                />
              </Text>
              <ControlledTextAreaField name="emailContentInput" />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Text as="h6" fontSize="12px" fontWeight="600">
                <FormattedMessage id="Preview" defaultMessage="Preview" />
              </Text>
              <RenderedNotificationContainer>
                {renderHTML(emailPreviewValue)}
              </RenderedNotificationContainer>
            </FormGroup>
          </Col>
        </Row>
        <Text as="h4" fontWeight={800}>
          <FormattedMessage
            id="SMS notification"
            defaultMessage="SMS notification"
          />
        </Text>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Text as="h6" fontSize="12px" fontWeight="600">
                <FormattedMessage
                  id="Enter message"
                  defaultMessage="Enter message"
                />
              </Text>
              <ControlledTextAreaField name="smsContentInput" />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Text as="h6" fontSize="12px" fontWeight="600">
                <FormattedMessage id="Preview" defaultMessage="Preview" />
              </Text>
              <RenderedNotificationContainer>
                {smsContentPreviewValue}
              </RenderedNotificationContainer>
            </FormGroup>
          </Col>
        </Row>
        <FormFooter onBack={onBack} isLoading={isSubmitting} />
      </Styled.Form>
    </FormProvider>
  );
};
