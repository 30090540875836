import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { merge } from 'lodash';
import { Form, Grid, Utils } from 'billon-ui';
import useFilters from 'js/hooks/useFilters';
import { UserRoles } from '../../../../constraints';
import { IconInCircle } from '../../../../components/IconInCircle';
import * as Styled from './styled';
import { parseFilters } from '../../heplers';
import { removeUndefinedValues } from 'js/helpers/removeUndefinedValues';
import { mapUSER_STATUSES, USER_STATUSES } from '../../../../constraints';
import { CalendarField } from 'js/containers';

const { TextField, SelectField } = Form;
const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { Col } = Grid;

export const FilterForm = ({ initialValues, closeFilters }) => {
  const { formatMessage } = useIntl();
  const { navigateWithSearchQuery, onClear } = useFilters();

  const {
    createdAt,
    createdBy,
    firstName,
    id,
    isActive,
    lastName,
    role,
    username,
    status,
    email,
    phoneNumber,
  } = initialValues.filters || {};

  const roleOptions = [
    ...Object.values(UserRoles).map((key) => {
      const msgId = `Role: ${key}`;
      return {
        label: formatMessage({
          id: msgId,
          defaultMessage: msgId,
        }),
        value: key,
      };
    }),
  ];

  const isActiveOptions = [
    {
      label: formatMessage({
        id: 'Active2',
        defaultMessage: 'Active2',
      }),
      value: true,
    },
    {
      label: formatMessage({
        id: 'Inactive',
        defaultMessage: 'Inactive',
      }),
      value: false,
    },
  ];

  const statusOptions = [
    ...Object.values(USER_STATUSES)
      .filter((key) => ![USER_STATUSES.ACCOUNT_TEMPORARY_BLOCKED].includes(key))
      .map((key) => {
        const msgId = mapUSER_STATUSES[key];
        return {
          label: formatMessage({
            id: msgId,
            defaultMessage: msgId,
          }),
          value: key,
        };
      }),
  ];

  const formInitialValues = {
    createdAt: '',
    createdBy: '',
    firstName: '',
    id: '',
    isActive: null,
    lastName: '',
    role: null,
    username: '',
    status: null,
    email: '',
    phoneNumber: '',
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
  } = useForm({
    defaultValues: merge({}, formInitialValues, {
      ...initialValues.filters,
      isActive:
        `${isActive}` !== 'null' &&
        isActiveOptions.filter((option) => `${option.value}` === `${isActive}`),
      role: role && roleOptions.filter((option) => option.value === role),
      createdAt: [createdAt?.from, createdAt?.to],
      status:
        status && statusOptions.filter((option) => option.value === status),
    }),
  });

  const onClearFilters = () => {
    onClear();
    reset(formInitialValues);
  };

  const onSubmit = (values) => {
    navigateWithSearchQuery({
      filters: removeUndefinedValues(parseFilters(values)),
    });
  };

  return (
    <Styled.FilterCard>
      <Styled.AbsoluteDivForExitIcon onClick={closeFilters}>
        <IconInCircle
          iconName="rejected_no_fill"
          size="31px"
          color="#cad5dd"
          hoverColor="#ACBBC7"
        />
      </Styled.AbsoluteDivForExitIcon>
      <Styled.Header>
        <FormattedMessage id="Filter by" defaultMessage="Filter by" />
      </Styled.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Styled.FlexStartRow>
          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage id="Login" defaultMessage="Login" />
            </Styled.Label>

            <Controller
              name="username"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                />
              )}
            />
          </Col>

          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage id="First name" defaultMessage="First name" />
            </Styled.Label>

            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                />
              )}
            />
          </Col>

          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage id="Last name" defaultMessage="Last name" />
            </Styled.Label>

            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                />
              )}
            />
          </Col>

          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage id="ID" defaultMessage="ID" />
            </Styled.Label>

            <Controller
              name="id"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                />
              )}
            />
          </Col>

          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage
                id="Account type"
                defaultMessage="Account type"
              />
            </Styled.Label>

            <Controller
              name="role"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                  options={roleOptions}
                  isClearable
                />
              )}
            />
          </Col>

          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage
                id="Created by (user name)"
                defaultMessage="Created by (user name)"
              />
            </Styled.Label>

            <Controller
              name="createdBy"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                />
              )}
            />
          </Col>

          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage id="Is active" defaultMessage="Is active" />
            </Styled.Label>

            <Controller
              name="isActive"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                  options={isActiveOptions}
                  isClearable
                />
              )}
            />
          </Col>

          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage
                id="Phone number"
                defaultMessage="Phone number"
              />
            </Styled.Label>

            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                />
              )}
            />
          </Col>

          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage
                id="Creation date from-to"
                defaultMessage="Creation date from-to"
              />{' '}
            </Styled.Label>

            <Controller
              name="createdAt"
              control={control}
              render={({ field, fieldState }) => (
                <CalendarField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                  enableTime={false}
                  dateFormat="Y-m-d"
                  showMonths={2}
                  range
                />
              )}
            />
          </Col>

          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage id="Email" defaultMessage="Email" />
            </Styled.Label>

            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                />
              )}
            />
          </Col>

          <Col lg={6} xl={3}>
            <Styled.Label>
              <FormattedMessage id="Status" defaultMessage="Status" />
            </Styled.Label>

            <Controller
              name="status"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  input={field}
                  meta={{
                    touched: fieldState.invalid,
                    error: fieldState.error,
                  }}
                  options={statusOptions}
                  isClearable
                />
              )}
            />
          </Col>
        </Styled.FlexStartRow>

        <Styled.FlexEndRow>
          <Col lg={6} xl={3}>
            <Button onClick={onClearFilters} block outline type="button">
              <FormattedMessage id="Clear" defaultMessage="Clear" />
            </Button>
          </Col>

          <Col lg={6} xl={3}>
            {!isSubmitting ? (
              <Button type="submit" block>
                <FormattedMessage id="Filter" defaultMessage="Filter" />
              </Button>
            ) : (
              <ButtonLoader size="lg" block />
            )}
          </Col>
        </Styled.FlexEndRow>
      </form>
    </Styled.FilterCard>
  );
};
