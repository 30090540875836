import React from 'react';
import * as Styled from './styled';

const CustomToolTip = ({
  id,
  placement = 'right',
  trigger,
  delay,
  children,
}) => (
  <>
    <Styled.TriggerWrapper id={id}>{trigger}</Styled.TriggerWrapper>
    <Styled.Tooltip
      placement={placement}
      target={id}
      delay={{ show: 10, hide: delay ? 1000 : 10 }}
    >
      <Styled.JustifiedContent>{children}</Styled.JustifiedContent>
    </Styled.Tooltip>
  </>
);

export default CustomToolTip;
