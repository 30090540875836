import { toNumber } from 'lodash';
import { REQUEST_STATUS } from '../../constraints';

export const parseFilters = (filters) => {
  // const output = superParseFilters(filters);
  const output = {
    where: {},
  };

  //pagination
  if (filters.limit && filters.page) {
    output.limit = toNumber(filters.limit);
    output.offset = toNumber((filters.page - 1) * filters.limit);
  }

  //sort
  if (filters.order) {
    output.order = filters.order;
  }

  //filters
  if (filters.requestStatus) {
    output.where.requestStatus = filters.requestStatus.value;
  }

  if (filters.requestJobId) {
    output.where.requestJobId = filters.requestJobId;
  }

  if (filters.requestCreationTime) {
    output.where.requestCreationTime = {};

    if (Array.isArray(filters.requestCreationTime)) {
      output.where.requestCreationTime.from = filters.requestCreationTime[0];
      output.where.requestCreationTime.to = filters.requestCreationTime[1];
    }
    if (filters.requestCreationTime.from) {
      output.where.requestCreationTime.from = filters.requestCreationTime.from;
    }

    if (filters.requestCreationTime.to) {
      output.where.requestCreationTime.to = filters.requestCreationTime.to;
    }
  }

  if (filters.requestValidityDateRange) {
    output.where.requestValidityDateRange = {};

    if (Array.isArray(filters.requestValidityDateRange)) {
      output.where.requestValidityDateRange.from =
        filters.requestValidityDateRange[0];
      output.where.requestValidityDateRange.to =
        filters.requestValidityDateRange[1];
    }
    if (filters.requestValidityDateRange.from) {
      output.where.requestValidityDateRange.from =
        filters.requestValidityDateRange.from;
    }

    if (filters.requestValidityDateRange.to) {
      output.where.requestValidityDateRange.to =
        filters.requestValidityDateRange.to;
    }
  }

  return output;
};

export const parseSendDecision = (values) => {
  const data = {};

  if (values.requestId) {
    data.requestJobId = values.requestId;
  }

  data.accessApproved = values.approved ? 'true' : 'false';

  if (values.reason) {
    data.requestDenialReason = values.reason;
  }

  return data;
};

export const requestFontColor = (status) => {
  if (status === REQUEST_STATUS.ACCESS_GRANTED) {
    return '#00b769';
  }
  if (status === REQUEST_STATUS.REJECTED) {
    return '#ad113b';
  }

  // if(status === REQUEST_STATUS.ERROR) {
  //   return '#fc5151'
  // }

  if (
    status === REQUEST_STATUS.INITIATED ||
    status === REQUEST_STATUS.REQUESTED
  ) {
    return '#006181';
  }

  return '#011627';
};
