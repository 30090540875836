import { Table as TableModule, Utils } from 'billon-ui';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const { TableCell } = TableModule;
const { Button: ButtonModule } = Utils;

const { Button } = ButtonModule;

export const HistoryContainer = styled.div`
  margin-bottom: 2rem;
  background: #fff;
  padding: 4rem;

  ${down('md')} {
    padding: 2rem;
  }

  h3 {
    padding-bottom: 2rem;
  }

  .current-row {
    font-weight: ${(props) => props.theme.fontWeightMedium} !important;
    background-color: ${(props) => props.theme.palette.grayLightest} !important;

    &:hover,
    &:focus,
    &:active {
      background-color: ${(props) =>
        props.theme.palette.grayLightest} !important;
      font-weight: ${(props) => props.theme.fontWeightMedium} !important;
    }

    td {
      font-weight: ${(props) => props.theme.fontWeightMedium} !important;
      background-color: ${(props) =>
        props.theme.palette.grayLightest} !important;

      &:hover,
      &:focus,
      &:active {
        background-color: ${(props) =>
          props.theme.palette.grayLightest} !important;
        font-weight: ${(props) => props.theme.fontWeightMedium} !important;
      }
    }
  }
`;

export const TC = styled(TableCell)`
  height: 80px;
  font-weight: ${(props) =>
    props.row.isNewlyPublished
      ? props.theme.fontWeightBold
      : props.theme.fontWeightBase};

  button {
    font-size: 20px;
  }
`;

export const TCCentered = styled(TC)`
  text-align: center;
`;

export const PrevButton = styled(Button)`
  padding: 0;
  padding-top: 9px;
  svg {
    color: #212122;
  }
`;
