import { Grid, Utils } from 'billon-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { AGREEMENT_STATUS, FILTERS_MAP } from '../../../../constraints';
import { StyledDocumentsTypesWrapper } from '../../scenes/DocumentsHomePageRemoteSign/index';
import DocumentFilters from './DocumentFilters';
import { DocumentTypeSelect } from './DocumentTypeSelect';

const { Row, Col } = Grid;
const { Button: ButtonModule, Text, Icon } = Utils;
const { Button } = ButtonModule;

const StyledFiltersWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  margin-bottom: 30px;
  margin-right: -3rem;

  ${down('md')} {
    margin-bottom: 10px;
    margin-right: -1rem;
  }
`;

const StyledCloseFilterButton = styled(Button)`
  /* height: 34px;  */
  border-radius: 65px;
  padding: 0.5rem 0 0.5rem 1.5rem;
  margin: 0.5rem 0.5rem 0 0;

  svg {
    opacity: 0;
    margin-left: 24px;
    font-size: 20px;
    transition: all 0.3s;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

const StatusesAndFiltersDocumentTable = (props) => {
  const importantFilters = Object.keys(props.filters).filter(
    (filter) =>
      filter !== 'limit' &&
      filter !== 'page' &&
      filter !== 'documentType' &&
      filter !== 'order',
  );

  return (
    <form onSubmit={props.handleSubmit(props.handleFilterSubmit)}>
      <StyledDocumentsTypesWrapper margin={0}>
        <Col xl={2} css="padding-left: 0;">
          <DocumentTypeSelect
            isSelected={props.selectedDocumentType === 1}
            onClick={() => props.clickDocumentType(1)}
            color={'#2e75b5'}
            iconName={'prepared_no_fill'}
            type="Ready"
            count={
              props.agreementsCountByStatus[AGREEMENT_STATUS.WAITING_FOR_SENDER]
            }
          />
        </Col>
        <Col xl={2} css="padding-left: 0;">
          <DocumentTypeSelect
            isSelected={props.selectedDocumentType === 2}
            onClick={() => props.clickDocumentType(2)}
            color={'#006181'}
            iconName={'waiting_no_fill'}
            type="Waiting"
            count={
              props.agreementsCountByStatus[
                AGREEMENT_STATUS.WAITING_FOR_RECEIVER
              ]
            }
          />
        </Col>
        <Col xl={2} css="padding-left: 0;">
          <DocumentTypeSelect
            isSelected={props.selectedDocumentType === 3}
            onClick={() => props.clickDocumentType(3)}
            color={'#00b769'}
            type="Confirmed"
            iconName={'confirmed_no_fill'}
            count={props.agreementsCountByStatus[AGREEMENT_STATUS.PUBLISHED]}
          />
        </Col>
        <Col xl={2} css="padding-left: 0;">
          <DocumentTypeSelect
            isSelected={props.selectedDocumentType === 5}
            onClick={() => props.clickDocumentType(5)}
            iconName={'incorrect_no_fill'}
            color={'#ff9200'}
            type="Incorrect2"
            count={props.agreementsCountByStatus[AGREEMENT_STATUS.ERROR]}
          />
        </Col>
        <Col xl={2} css="padding-left: 0;">
          <DocumentTypeSelect
            isSelected={props.selectedDocumentType === 4}
            onClick={() => props.clickDocumentType(4)}
            iconName={'rejected_no_fill'}
            color={'#ad113b'}
            type="Rejected"
            count={props.agreementsCountByStatus[AGREEMENT_STATUS.REJECTED]}
          />
        </Col>
      </StyledDocumentsTypesWrapper>
      <Row margin={0}>
        <Text as="h4" fontWeight="700">
          <FormattedMessage
            id="Documents list"
            defaultMessage="Documents list"
          />
        </Text>
        <Button
          fontWeight="700"
          fontSize="12px"
          color="link"
          type="button"
          onClick={() => props.toggleFilters()}
        >
          <FormattedMessage id="Filters" defaultMessage="Filters" />
        </Button>
      </Row>
      {props.isFiltersOpen ? (
        <DocumentFilters
          closeFilters={props.closeFilters}
          categories={props.categories}
          recipients={props.recipientsSelect}
          filters={props.filtersFromForm}
        />
      ) : null}

      {importantFilters.length > 0 && props.filters && (
        <StyledFiltersWrapper>
          {importantFilters.map(
            (filter) =>
              props.filters[filter] && (
                <StyledCloseFilterButton
                  outline
                  fontSize="12px"
                  fontWeight={600}
                  key={filter}
                  onClick={() => props.handleRemoveFilter(filter)}
                >
                  {['recipient', 'category'].includes(filter) ? (
                    <>
                      <FormattedMessage
                        id={FILTERS_MAP[filter]}
                        defaultMessage={FILTERS_MAP[filter]}
                      />
                      {': '}
                      {props.filters[filter].label}
                    </>
                  ) : (
                    <>
                      <FormattedMessage
                        id={FILTERS_MAP[filter]}
                        defaultMessage={FILTERS_MAP[filter]}
                      />
                      {': '}
                      {props.filters[filter]}
                    </>
                  )}
                  <Icon name="times-circle" regular />
                </StyledCloseFilterButton>
              ),
          )}
        </StyledFiltersWrapper>
      )}
    </form>
  );
};

export default reduxForm({
  form: 'filterRemoteDocumentTableForm',
})(StatusesAndFiltersDocumentTable);
