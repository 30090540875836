import React from 'react';
import * as Styled from './styled';
import { Utils, Grid, Layout } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { SubtitleToolTip } from '..';
import Modal from '../../../../../../../../ui/Modal';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';

const { Col, Row } = Grid;
const { Loader: LoaderModule, Text } = Utils;
const { Content: ContentModule } = Layout;
const { PageLoader } = LoaderModule;
const { Content } = ContentModule;

const SubtitlePanel = ({ children }) => (
  <Text as="h4" fontWeight={600}>
    {children}
  </Text>
);

const SelectEditTypeModal = ({
  isOpen,
  isLoading,
  onBack,
  onClose,
  onNext,
  onEditEmailNotification,
  onEditSmsNotification,
}) => {
  const ModalContent = () => (
    <>
      <Styled.ModalBodyWrapper>
        <Row>
          <Col md={6}>
            <SubtitlePanel>
              {'1. '}
              <FormattedMessage
                id="Email notification"
                defaultMessage="Email notification"
              />{' '}
              <SubtitleToolTip />
            </SubtitlePanel>
            <Styled.EditPanel>
              <Styled.EditButton outline onClick={onEditEmailNotification}>
                <FormattedMessage id="Edit" defaultMessage="Edit" />
              </Styled.EditButton>
            </Styled.EditPanel>
          </Col>
          <Col md={6}>
            <SubtitlePanel>
              {'2. '}
              <FormattedMessage
                id="SMS notification"
                defaultMessage="SMS notification"
              />
            </SubtitlePanel>
            <Styled.EditPanel>
              <Styled.EditButton outline onClick={onEditSmsNotification}>
                <FormattedMessage id="Edit" defaultMessage="Edit" />
              </Styled.EditButton>
            </Styled.EditPanel>
          </Col>
        </Row>
      </Styled.ModalBodyWrapper>
      <ModalFooter onBack={onBack} onNext={onNext} isLoading={isLoading} />
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      onClose={onClose}
      closeIconEnabled={true}
    >
      <ModalHeader>
        <FormattedMessage
          id="Enter the message content"
          defaultMessage="Enter the message content"
        />
      </ModalHeader>

      {isLoading ? (
        <Content fluid>
          <PageLoader />
        </Content>
      ) : (
        <ModalContent />
      )}
    </Modal>
  );
};

export default SelectEditTypeModal;
