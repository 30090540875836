import React from 'react';
import { merge } from 'lodash';
import { ORDER_METHOD } from '../../../../../../constraints';
import useFilters from '../../../../../../hooks/useFilters';
import { Layout as LayoutModule, Utils, Table as TableModule } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { mapNotificationHistoryStatuses } from '../../../../../../formatters';
import { unSelectRecipientFormatter } from '../../formatters/unSelectRecipientFormatter';

const { Content: ContentModule } = LayoutModule;
const { Loader: LoaderModule, Text, Pagination } = Utils;
const { PageLoader } = LoaderModule;
const { Content } = ContentModule;
const { Table, TableColumn } = TableModule;

const SelectedRecipientsTable = ({ data, onRemoveRecipient }) => {
  const defaultFilters = {
    pagination: {
      limit: 5,
      page: 1,
    },
    sort: {
      order: ORDER_METHOD.DESC,
    },
  };

  const { filters, navigateWithSearchQuery, onClear } = useFilters();
  const fullFilters = merge({}, defaultFilters, filters);

  let rows = data;
  let numberOfRecords = data.length;
  let numberOfPages = Math.ceil(numberOfRecords / fullFilters.pagination.limit);

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      },
    });
  };

  return (
    <>
      <Table tableData={rows} sortMethod={() => {}} responsive>
        <TableColumn fieldName="externalId" className="fixed-width-200">
          <FormattedMessage id="Recipient id" defaultMessage="Recipient id" />
        </TableColumn>
        <TableColumn fieldName="contactDetails">
          <FormattedMessage
            id="Email/Phone number"
            defaultMessage="Email/Phone number"
          />
        </TableColumn>
        <TableColumn
          fieldName="status"
          formatter={mapNotificationHistoryStatuses}
          className="fixed-width-200"
        >
          <FormattedMessage id="Status" defaultMessage="Status" />
        </TableColumn>

        <TableColumn
          fieldName="unselect"
          formatter={(value, record) =>
            unSelectRecipientFormatter(value, record, onRemoveRecipient)
          }
          className="fixed-width-200"
        >
          <FormattedMessage id="Remove" defaultMessage="Remove" />
        </TableColumn>
      </Table>
      <Pagination
        onClick={handlePageChange}
        numberOfPages={numberOfPages}
        currentPage={fullFilters.pagination.page}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
      />
    </>
  );
};

export default SelectedRecipientsTable;
