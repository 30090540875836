import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  label {
    padding-top: 0.5em;
    padding-left: 3em;
  }
`;

export const Checkbox = styled.label`
  margin: 0 0 1rem 0;
  min-height: 1.5rem;
  cursor: pointer;
  padding-left: 2rem;
  display: inline-flex;
  font-size: ${(props) => props.theme.fontSizeSm};

  .custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked ~ .custom-control-label {
      &:before {
        border-color: ${(props) => props.theme.palette.primary};
      }
      &:after {
        display: block;
      }
    }
  }

  &.text-danger {
    .custom-control-label {
      &:before {
        border-color: ${(props) => props.theme.palette.error};
      }
    }
  }

  &.small {
    min-height: 1rem;
    height: auto;
    padding-left: 1.5rem;

    .custom-control-label {
      font-size: 0.7rem;
      padding-top: 0.1rem;

      &:before,
      &:after {
        left: -1.5rem;
        width: 1.1rem;
        height: 1.1rem;
      }
    }
  }

  &.disabled {
    cursor: ${(props) => props.theme.disabledCursor};

    .custom-control-label {
      cursor: ${(props) => props.theme.disabledCursor};

      &:before,
      &:after {
        cursor: ${(props) => props.theme.disabledCursor};
        background-color: ${(props) => props.theme.palette.grayLighter};
      }
    }
  }
`;

export const CheckboxLabel = styled.span`
  font-weight: ${(props) => props.theme.fontWeightBase};
  padding-top: 0.14rem;
  position: relative;

  &:before {
    position: absolute;
    top: 0rem;
    left: -2rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
    content: '';
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.palette.grayLighter};
    border-radius: ${(props) => props.theme.borderRadius};
  }

  &:after {
    background: no-repeat center center
      url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIG1lZXQiIHZpZXdCb3g9IjAgLTkuNDkyNjczOTkyNjczOTkzIDcxIDcxIiBjbGFzcz0ibmctZWxlbWVudCIgZGF0YS1pZD0iMzRhMzk2Y2VmZDQxNGQ3ZmFjYWVmZTQwMTMzZDVlMmEiIHN0eWxlPSJmaWxsOiByZ2IoMCwgMTczLCAyNTUpOyB3aWR0aDogMTJweDsgaGVpZ2h0OiAxMnB4OyBmaWx0ZXI6IG5vbmU7IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnPjxwb2x5Z29uIHhtbG5zOmRlZmF1bHQ9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBpZD0iRG9uZV8xXyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIHBvaW50cz0iNzAuNSw0LjEgNjYuNCwwIDIzLjcsNDMuMSA0LjEsMjMuNyAwLDI3LjggMjMuNyw1MS40ICAgMjcuOCw0Ny4zIDI3LjgsNDcuMiAiIHN0eWxlPSJmaWxsOiByZ2IoMCwgMTczLCAyNTUpOyIvPjwvZz48L3N2Zz4=');
    cursor: pointer;
    left: -2rem;
    position: absolute;
    top: 0rem;
    display: none;
    width: 1.5rem;
    height: 1.5rem;
    content: '';
    background-size: 50%;
  }
`;
