import React from 'react';

export const formatStudent = (identity) => (
  <div>
    {`${identity.firstName} ${identity.lastName}`}
    <br />
    {identity.phoneNumber}
    <br />
    {identity.email}
  </div>
);
