/* global sessionStorage */
import { ajax } from 'rxjs/observable/dom/ajax';
import { Observable } from 'rxjs/Rx';
import { Utils } from 'billon-ui';
import {
  requestList,
  receiveListSuccess,
  receiveListFailure,
  requestYearlyReport,
  requestYearlyReportSuccess,
  requestYearlyReportFailure,
  requestFairUsageReport,
  requestFairUsageReportSuccess,
  requestFairUsageReportFailure,
  requestCustomReport,
  requestCustomReportSuccess,
  requestCustomReportFailure,
  requestDownload,
  receiveDownloadSuccess,
  receiveDownloadFailure,
  requestDownloadExcelReport,
  receiveDownloadExcelReportSuccess,
  receiveDownloadExcelReportFailure,
  requestDownloadStatisticsExcelReport,
  receiveDownloadStatisticsExcelReportSuccess,
  receiveDownloadStatisticsExcelReportFailure,
} from './actions';
import routes from '../../api-routes';
import { parseFilters, parseSave } from './helpers';
import download from 'downloadjs';
import { initializeWebViewerFromBlob } from '../../webviewer';

const { helpers } = Utils;
const { request, encodeQueryString } = helpers;
const {
  METHOD_GET,
  HEADER_CONTENT_TYPE,
  CONTENT_TYPE_APPLICATION_JSON,
  X_ACCESS_TOKEN,
  METHOD_POST,
} = request;

const getDocumentsListEpic = (actions$) =>
  actions$.ofType(requestList.getType()).mergeMap((action) =>
    ajax({
      url: `${routes.DOCUMENT}?options=${encodeQueryString(
        parseFilters(action.payload.filters),
      )}`,
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => receiveListSuccess(response))
      .takeUntil(actions$.ofType(requestList.getType()))
      .catch((err) => Observable.of(receiveListFailure(err))),
  );

const downloadDocumentEpic = (actions$) =>
  actions$.ofType(requestDownload.getType()).mergeMap((action) =>
    ajax({
      url: `${routes.SINGLE_DOCUMENT_DOWNLOAD}?blockchainAddress=${action.payload.blockchainAddress}&jobId=${action.payload.jobId}`,
      headers: {
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => {
        const blob = new Uint8Array(response.fileBuffer.data);
        if (!action.payload.returnOnlyBlob) {
          download(
            blob,
            response.fileName.includes('.pdf')
              ? response.fileName
              : `${response.fileName}.pdf`,
          );
        } else {
          initializeWebViewerFromBlob(blob);
        }

        return receiveDownloadSuccess(response);
      })
      .takeUntil(actions$.ofType(requestDownload.getType()))
      .catch((err) => Observable.of(receiveDownloadFailure(err))),
  );

const getYearlyReportEpic = (actions$) => {
  return actions$.ofType(requestYearlyReport.getType()).mergeMap((action) =>
    ajax({
      url: `${routes.REPORTS}/getYearlyReport`,
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => requestYearlyReportSuccess(response))
      .takeUntil(actions$.ofType(requestYearlyReport.getType()))
      .catch((err) => Observable.of(requestYearlyReportFailure(err))),
  );
};

const getFairUsageReportEpic = (actions$) => {
  return actions$.ofType(requestFairUsageReport.getType()).mergeMap((action) =>
    ajax({
      url: `${routes.REPORTS}/getFairUsageReport`,
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => requestFairUsageReportSuccess(response))
      .takeUntil(actions$.ofType(requestFairUsageReport.getType()))
      .catch((err) => Observable.of(requestFairUsageReportFailure(err))),
  );
};

const getCustomReportEpic = (actions$) => {
  return actions$.ofType(requestCustomReport.getType()).mergeMap((action) => {
    const data = action.payload;

    return ajax({
      url: `${routes.REPORTS}/getCustomReport`,
      body: parseSave(data),
      headers: {
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_POST,
    })
      .map(({ response }) => requestCustomReportSuccess(response))
      .takeUntil(actions$.ofType(requestCustomReport.getType()))
      .catch((err) => Observable.of(requestCustomReportFailure(err)));
  });
};

const downloadReportInExcelEpic = (actions$) =>
  actions$.ofType(requestDownloadExcelReport.getType()).mergeMap((action) => {
    const data = action.payload.meta;
    delete data.limit;

    return ajax({
      url: `${routes.DOWNLOAD_EXCEL_REPORT}?options=${encodeQueryString(
        parseFilters(data),
      )}&language=${
        sessionStorage.getItem('language')
          ? sessionStorage.getItem('language')
          : 'pl'
      }`,
      headers: {
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_GET,
    })
      .map(({ response }) => {
        const blob = new Uint8Array(response.fileBuffer.data);
        download(blob, response.fileName, 'text/csv');
        return receiveDownloadExcelReportSuccess(response);
      })
      .takeUntil(actions$.ofType(requestDownloadExcelReport.getType()))
      .catch((err) => Observable.of(receiveDownloadExcelReportFailure(err)));
  });

const downloadStatisticsReportInExcelEpic = (actions$) =>
  actions$
    .ofType(requestDownloadStatisticsExcelReport.getType())
    .mergeMap((action) => {
      const data = action.payload.meta;
      delete data.limit;

      return ajax({
        url: routes.DOWNLOAD_STATISTICS_EXCEL_REPORT,
        body: parseSave(data),
        headers: {
          [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
        },
        method: METHOD_POST,
      })
        .map(({ response }) => {
          const blob = new Uint8Array(response.fileBuffer.data);
          download(blob, response.fileName, 'text/csv');
          return receiveDownloadStatisticsExcelReportSuccess(response);
        })
        .takeUntil(
          actions$.ofType(requestDownloadStatisticsExcelReport.getType()),
        )
        .catch((err) =>
          Observable.of(receiveDownloadStatisticsExcelReportFailure(err)),
        );
    });

export default [
  downloadDocumentEpic,
  getDocumentsListEpic,
  getYearlyReportEpic,
  getFairUsageReportEpic,
  getCustomReportEpic,
  downloadReportInExcelEpic,
  downloadStatisticsReportInExcelEpic,
];
