import React, { useState } from 'react';
import {
  mapDOCUMENT_ACTIONS_ERROR,
  mapDOCUMENT_ACTIONS_SUCCESS,
  UNKNOWN_ERRORS,
} from '../../../../constraints';
import { parsePublishRequestData } from '../../helpers';
import { usePublishDocument } from '../../hooks';
import { DocumentAtrributesForm } from '../PublishDocumentModal/componnets';
import { NotificationContentForm } from '../PublishDocumentModal/componnets/NotificationContentForm/NotificationContentForm';
import { DocumentPublicationSummary } from '../DocumentPublicationSummary';

const UpdateForm = ({
  initialValues,
  mode,
  onSuccess: handleSuccess,
  onError: handleError,
}) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    ...initialValues,
  });

  const handleAppendFormData = (moreData) => {
    setFormData({
      ...formData,
      ...moreData,
    });
  };

  const { mutate: updateDocument, isLoading: isSaving } = usePublishDocument(
    initialValues.documentType,
    {
      onSuccess: () => {
        handleSuccess(mapDOCUMENT_ACTIONS_SUCCESS.UPDATE);
      },
      onError: ({ response }) => {
        const { statusCode } = response?.data;
        handleError(
          mapDOCUMENT_ACTIONS_ERROR.UPDATE,
          statusCode ? statusCode : UNKNOWN_ERRORS.UNKNOWN_ERROR,
        );
      },
    },
  );

  const handleFormSubmit = (values) => {
    updateDocument({
      data: parsePublishRequestData(
        values,
        mode,
        initialValues?.documentBlockchainAddress,
      ),
    });
  };

  const handleSubmitStep = (stepData) => {
    handleAppendFormData(stepData);
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  return (
    <>
      {step === 1 && (
        <DocumentAtrributesForm
          step={2} // first step allows to add identity and category
          document={formData}
          publicationMode={mode}
          onSubmit={handleSubmitStep}
          submitButtonText="Next"
          isLoading={isSaving}
        />
      )}
      {step === 2 && (
        <NotificationContentForm
          onSubmit={handleSubmitStep}
          onBack={handleBack}
          defaultValues={formData}
          disabledTitle={true}
        />
      )}
      {step === 3 && (
        <DocumentPublicationSummary
          onSubmit={handleFormSubmit}
          onBack={handleBack}
          isLoading={isSaving}
          defaultValues={formData}
        />
      )}
    </>
  );
};

export default UpdateForm;
