import { isUndefined } from 'lodash';
import { DOCUMENT_TYPE } from '../../../../constraints';

interface DocumentTableSearchParams {
  type: DOCUMENT_TYPE;
  limit: number;
  page: number;
  order?: {
    [key: string]: 'ASC' | 'DESC';
  };
}

export const getSearchParams = (
  rawFilters: string,
  defaultLimit: number,
): DocumentTableSearchParams => ({
  type: DOCUMENT_TYPE.PRIVATE,
  limit: defaultLimit,
  page: 1,
  ...(!isUndefined(rawFilters)
    ? JSON.parse(decodeURIComponent(rawFilters))
    : {}),
});