import { Form as FormModule, Grid, Utils } from 'billon-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { IconInCircle } from '../../../../components/IconInCircle';

const { Row, Col } = Grid;
const { Button: ButtonModule, Card, Text } = Utils;
const { Button } = ButtonModule;
const { FormGroup, TextField, SelectField } = FormModule;

const StyledFiltersCard = styled(Card)`
  position: relative;
`;

const StyledAbsoluteDivForExitIcon = styled.div`
  position: absolute;
  right: 4%;
  top: 10%;
  cursor: pointer;
`;

const DocumentFilters = (props) => {
  return (
    <StyledFiltersCard>
      <StyledAbsoluteDivForExitIcon onClick={props.closeFilters}>
        <IconInCircle
          iconName="rejected_no_fill"
          size="36px"
          color="#cad5dd"
          hoverColor="#ACBBC7"
        />
      </StyledAbsoluteDivForExitIcon>
      <Text as="h5" fontSize="14px" fontWeight="700">
        <FormattedMessage id="Filter by" defaultMessage="Filter by" />
      </Text>
      <Row margin={0}>
        <Col lg={3}>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage
              id="Document title"
              defaultMessage="Document title"
            />
          </Text>
          <FormGroup>
            <Field component={TextField} name="title" />
          </FormGroup>
        </Col>
        <Col lg={3}>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Category" defaultMessage="Category" />
          </Text>
          <FormGroup>
            <Field
              component={SelectField}
              name="category"
              options={props.categories}
              isClearable
            />
          </FormGroup>
        </Col>
        {/* <Col lg={3}> */}
        {/* <Text as="h6" fontSize="13px" fontWeight="600">
            <FormattedMessage
              id="Sign date from to"
              defaultMessage="Sign date from to"
            />
          </Text>
          <FormGroup>
            <Field
              dateFormat="Y-m-d"
              component={CalendarField}
              enableTime={false}
              name="signDate"
              range
            />
          </FormGroup> */}
        {/* </Col> */}
        <Col lg={3}>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Recipient" defaultMessage="Recipient" />
          </Text>
          <FormGroup>
            <Field
              component={SelectField}
              name="recipient"
              options={props.recipients}
              disabled={props.filters.recipientId}
              isClearable
            />
          </FormGroup>
        </Col>
        <Col lg={3}>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Recipient id" defaultMessage="Recipient id" />
          </Text>
          <FormGroup>
            <Field
              component={TextField}
              name="recipientId"
              disabled={props.filters.recipient}
              range
            />
          </FormGroup>
        </Col>
      </Row>
      <Row margin={0}>
        {/* <Col lg={3}>
          <Text as="h6" fontSize="12px" fontWeight="600">
            <FormattedMessage id="Recipient" defaultMessage="Recipient" />
          </Text>
          <FormGroup>
            <Field
              component={TextField}
              icon="search"
              iconRight
              name="recipient"
              range
            />
          </FormGroup>
        </Col> */}

        <Col
          lg={{
            size: 2,
            offset: 10,
          }}
          css={{
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
          }}
        >
          <Button type="submit" width={'100%'} outline>
            <FormattedMessage id="Filter" defaultMessage="Filter" />
          </Button>
        </Col>
      </Row>
    </StyledFiltersCard>
  );
};

export default DocumentFilters;
