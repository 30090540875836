import React from 'react';
import styled from 'styled-components';

import { Utils } from 'billon-ui';
const { Icon } = Utils;

export const TH = styled.th`
  padding: 1rem 0.7rem;
  border-top: 0;
  border-bottom: 1px solid ${(props) => props.theme.palette.grayLight};
  vertical-align: middle;
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-size: ${(props) => props.theme.fontSize12};

  &.sortable {
    cursor: pointer;

    &:hover {
      svg[data-icon] {
        color: ${(props) => props.theme.bodyColor};
      }
    }

    svg[data-icon] {
      /* font-size: ${(props) => props.theme.fontSize11}; */
      font-size: 8px;
      transition: ${(props) => props.theme.transitionBase};
    }
  }

  &.fixed-width {
    width: 100px;
  }

  &.fixed-width-56 {
    width: 56px;
  }

  &.fixed-width-140 {
    width: 140px;
  }

  &.fixed-width-200 {
    width: 200px;
  }

  &:hover {
    svg[data-icon] {
      opacity: 1;
    }
  }
`;

export const SortableColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SortActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 1rem;
`;

export const MyIcon = styled(Icon)`
  /* color: ${(props) =>
    props.active
      ? props.theme.palette.primary
      : props.theme.palette.primaryLight}; */
  color: ${(props) => props.theme.palette.grayLight};
  position: absolute;

  /* &:hover {
    color: ${(props) => props.theme.bodyColor};
  } */
`;
