import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table as TableModule, Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import {
  CUSTOMER,
  PUBLICATION_MODES,
  DOCUMENT_STATUS,
} from '../../../../constraints';

import * as formatters from '../../../../formatters';

import {
  SecondaryBadge,
  SuccessBadge,
  SecondaryDarkerBadge,
} from 'js/ui/Badge';

import { PublicationWithToolTipFormatter } from '../../containers/CommonDocumentTable/statusFormatter';

import * as Styled from './styled';

const { Table, TableColumn } = TableModule;
const { Button: ButtonModule, Loader: LoaderModule, Icon } = Utils;

const { IconButton } = ButtonModule;
const { Loader } = LoaderModule;

class DocumentHistory extends Component {
  constructor(props) {
    super(props);
    this.onClickRow = this.onClickRow.bind(this);
    this.formatRow = this.formatRow.bind(this);
  }

  onClickRow(record) {
    const { currentId, history } = this.props;
    if (record.documentBlockchainAddress !== currentId)
      history.push(`/document/${record.documentBlockchainAddress}`);
  }

  formatRow(row) {
    const { currentId } = this.props;

    return row.documentBlockchainAddress === currentId ? 'current-row' : null;
  }

  makePreview = (documentBlockchainAddress, jobId, onlyBlob) => {
    this.props.fileDownload(documentBlockchainAddress, jobId, onlyBlob);
  };

  previewFormatter = (address, row) => {
    if (row && row.jobId) {
    }
    return (
      <Styled.PrevButton
        color="link"
        className="ignore-row-click"
        onClick={() =>
          this.makePreview(row.documentBlockchainAddress, row.jobId, true)
        }
      >
        <Icon name="eye" />
      </Styled.PrevButton>
    );
  };

  downloadFormatter = (address, row) => {
    const { fileDownload, isDownloading, currentId } = this.props;

    if (isDownloading.includes(address || currentId)) {
      return <Loader width={36} />;
    }

    return (
      <IconButton
        icon="pdf"
        billonIcon={true}
        className="ignore-row-click"
        onClick={() => fileDownload(currentId, row.title)}
      />
    );
  };

  badgeFormatter = (value, row) => (
    <>
      {value}
      {row.publicationMode === PUBLICATION_MODES.CORRECTED && (
        <SecondaryBadge margin={'0 0 0 15px'}>
          <FormattedMessage id="Erratum" defaultMessage="Erratum" />
        </SecondaryBadge>
      )}
      {row.publicationMode === PUBLICATION_MODES.UPDATED && (
        <SuccessBadge margin={'0 0 0 15px'}>
          <FormattedMessage id="New version" defaultMessage="New version" />
        </SuccessBadge>
      )}
      {row.status === DOCUMENT_STATUS.DEPRECATED && (
        <SecondaryDarkerBadge margin={'0 0 0 15px'}>
          <FormattedMessage
            id="Previous Version"
            defaultMessage="Previous Version"
          />
        </SecondaryDarkerBadge>
      )}
    </>
  );

  render() {
    const { historyRecords, customer } = this.props;

    if (!historyRecords.length) {
      return null;
    }

    return (
      <Styled.HistoryContainer>
        <h3>
          <FormattedMessage id="History" defaultMessage="History" />
        </h3>
        <Table
          responsive
          tableData={historyRecords}
          onRowClick={this.onClickRow}
          formatRow={this.formatRow}
        >
          <TableColumn
            fieldName="publicationDate"
            formatter={formatters.momentFormatterWithoutTime}
          >
            <FormattedMessage
              id="Publication date"
              defaultMessage="Publication date"
            />
          </TableColumn>
          <TableColumn fieldName="title" formatter={this.badgeFormatter}>
            {
              {
                [CUSTOMER.DIPLOMA]: (
                  <FormattedMessage
                    id="Diploma number"
                    defaultMessage="Diploma number"
                  />
                ),
                [CUSTOMER.DEFAULT]: (
                  <FormattedMessage id="Title" defaultMessage="Title" />
                ),
              }[customer]
            }
          </TableColumn>
          <TableColumn fieldName="documentBlockchainAddress">
            <FormattedMessage
              id="Blockchain address"
              defaultMessage="Blockchain address"
            />
          </TableColumn>
          {customer !== CUSTOMER.DIPLOMA && (
            <TableColumn fieldName="versionName">
              <FormattedMessage
                id="Document version"
                defaultMessage="Document version"
              />
            </TableColumn>
          )}
          <TableColumn
            fieldName="status"
            formatter={PublicationWithToolTipFormatter}
          >
            <FormattedMessage
              id="Publication status"
              defaultMessage="Publication status"
            />
          </TableColumn>

          {customer === CUSTOMER.DEFAULT && (
            <TableColumn
              className="text-center fixed-width"
              fieldName="documentBlockchainAddress"
              formatter={this.previewFormatter}
              renderCell={(row, props) => (
                <Styled.TCCentered row={row} {...props} />
              )}
            >
              <FormattedMessage id="Preview" defaultMessage="Preview" />
            </TableColumn>
          )}
          {(customer === CUSTOMER.DIPLOMA || customer === CUSTOMER.DEFAULT) && (
            <TableColumn
              className="text-center fixed-width"
              fieldName="documentBlockchainAddress"
              formatter={this.downloadFormatter}
              renderCell={(row, props) => (
                <Styled.TCCentered row={row} {...props} />
              )}
            >
              <FormattedMessage id="Download" defaultMessage="Download" />
            </TableColumn>
          )}
        </Table>
      </Styled.HistoryContainer>
    );
  }
}

DocumentHistory.propTypes = {
  historyRecords: PropTypes.array,
  history: PropTypes.object,
  currentId: PropTypes.string,
  customer: PropTypes.string.isRequired,
  fileDownload: PropTypes.func,
  isDownloading: PropTypes.array.isRequired,
};

export default withRouter(DocumentHistory);
