import { Grid } from 'billon-ui';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

const { Row } = Grid;

export const MyButton = styled.button`
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  margin-left: 1rem;

  &:hover,
  &:focus {
    outline: none;
  }
`;

export const CopiedInfo = styled.div`
  position: absolute;
  font-size: ${(props) => props.theme.fontSize12};
  background-color: #acbbc7;
  color: #fff;
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 0.25rem 1.5rem;
  top: -2.5rem;
  left: 50%;
  margin-left: -57px;
  transition: ${(props) => props.theme.transitionBase};
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

export const DocumentInfo = styled.div`
  .btn {
    font-size: 13px;
    height: 100%;

    ${down('sm')} {
      width: 300px;
    }
  }

  .info-col {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .border-right {
    border-right: 1px solid ${(props) => props.theme.palette.grayLight};
  }

  label.large {
    display: block;
    margin-bottom: 0;
  }

  .blockchain-address-span {
    word-wrap: break-word;
    font-weight: 600;
  }

  .status-span {
    font-weight: 600;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      font-size: ${(props) => props.theme.fontSizeSm};
      margin-bottom: 1rem;

      label {
        display: block;
        font-weight: ${(props) => props.theme.fontWeightBold};
        margin-bottom: 0;
      }
    }
  }
`;

export const FlexEndRow = styled(Row)`
  justify-content: flex-end;
`;
