import { createAction } from 'redux-act';
import { Utils } from 'billon-ui';

const { helpers } = Utils;
const { encodeQueryString } = helpers;

export const applyFilters = (history, filters) =>
  history.push(`/user/${encodeQueryString(filters)}`);


export const requestListQuiet = createAction(
  'Request documents list quietly',
  (filters, ...rest) => ({ filters, ...rest }),
);


// Request category list
export const requestList = createAction(
  'Request users list',
  (filters, ...rest) => ({ filters, ...rest }),
);
export const requestListSuccess = createAction('Request users list success');
export const requestListFailure = createAction('Request users list failure');

// Request single documents
export const requestSingle = createAction(
  'Request single user',
  (id, ...rest) => ({ id, ...rest }),
);
export const receiveSingleSuccess = createAction(
  'Request single user success',
);
export const receiveSingleFailure = createAction(
  'Request single user failure',
);
// Save user
export const requestSave = createAction(
  'Save user',
  (values, meta, ...rest) => ({
    ...rest,
    values,
    meta,
  }),
);
export const receiveSaveSuccess = createAction('Save user success');
export const receiveSaveFailure = createAction('Save user failure');

// Delete user
export const requestDelete = createAction(
  'Delete user',
  (id, meta, ...rest) => ({
    ...rest,
    id,
    meta,
  }),
);
export const receiveDeleteSuccess = createAction('Delete user success');
export const receiveDeleteFailure = createAction('Delete user failure');
