import { createReducer } from 'redux-act';
import * as actions from './actions';

export default createReducer(
  {
    // List
    [actions.requestList]: (state) => ({ ...state, isFetching: true }),
    [actions.requestListQuiet]: (state) => ({
      ...state,
      isFetchingQuietly: true,
    }),
    [actions.requestListSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      records: payload.rows,
      numberOfRecords: payload.count,
      allParentCategoriesList: payload.allParentCategoriesList,
      allParentCategoriesListFiltered: payload.allParentCategoriesListFiltered,
      isFetchingQuietly: false,
      allParentCategoriesDetails: payload.allParentCategoriesDetails,
    }),
    [actions.requestListFailure]: (state) => ({
      ...state,
      isFetching: false,
      isFetchingQuietly: false,
    }),
    // create
    [actions.requestSave]: (state) => ({
      ...state,
      isSaving: true,
    }),
    [actions.receiveSaveSuccess]: (state) => ({
      ...state,
      isSaving: false,
    }),
    [actions.receiveSaveFailure]: (state) => ({
      ...state,
      isSaving: false,
      createModalOpen: false,
    }),
    [actions.requestDelete]: (state) => ({
      ...state,
      isDeleting: true,
    }),
    [actions.receiveDeleteSuccess]: (state) => ({
      ...state,
      isDeleting: false,
    }),
    [actions.receiveDeleteFailure]: (state) => ({
      ...state,
      isDeleting: false,
    }),
    [actions.requestToggle]: (state) => ({
      ...state,
      isSaving: true,
    }),
    [actions.receiveToggleSuccess]: (state) => ({
      ...state,
      isSaving: false,
    }),
    [actions.receiveToggleFailure]: (state) => ({
      ...state,
      isSaving: false,
    }),
  },
  {
    records: [],
    isSaving: false,
    isFetching: false,
    isDeleting: false,
    numberOfRecords: 0,
    allParentCategoriesList: [],
    allParentCategoriesListFiltered: [],
    allParentCategoriesDetails: [],
  },
);
