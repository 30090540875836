import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Layout as LayoutModule, Utils } from 'billon-ui';
import { SETTINGS_CONSTANTS } from '../../../../constraints';

import { DocumentDetails } from '../../containers';

// Actions
import { requestSingle } from '../../actions';

const { Content: ContentModule } = LayoutModule;
const { Content, ContentContainer } = ContentModule;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const DocumentsDetailsContainer = styled(ContentContainer)`
  background-color: ${(props) => props.theme.palette.grayLightest};
`;

class DocumentsDetailsPage extends Component {
  componentDidMount() {
    const { match, requestSingleDocument } = this.props;

    const { id } = match.params;

    requestSingleDocument(id);
  }

  componentDidUpdate(prevProps) {
    const { match, requestSingleDocument } = this.props;
    const { id } = match.params;

    if (prevProps.match.params.id !== id) {
      requestSingleDocument(id);
    }
  }

  render() {
    const { document, isFetching, didFetched, customer, history, settings } =
      this.props;

    if (isFetching || !didFetched) {
      return (
        <Content fluid>
          <PageLoader />
        </Content>
      );
    }

    return (
      <>
        <DocumentsDetailsContainer fluid>
          <Content fluid>
            <DocumentDetails
              document={document}
              customer={customer}
              onBackClick={() => history.push('/documents/tariffs')}
              backButtonLabel={
                <FormattedMessage id="Tariffs" defaultMessage="Tariffs" />
              }
              publisherName={settings[SETTINGS_CONSTANTS.PUBLICATOR_NAME]}
              documentPreviewUrl={`${
                settings[SETTINGS_CONSTANTS.DOCUMENT_PREVIEW_URL]
              }/tariff`}
            />
          </Content>
        </DocumentsDetailsContainer>
      </>
    );
  }
}

DocumentsDetailsPage.propTypes = {
  match: PropTypes.object.isRequired,
  requestSingleDocument: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  didFetched: PropTypes.bool.isRequired,
  document: PropTypes.object,
  customer: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  settings: PropTypes.object,
};

const mapStateToProps = ({ document, config, settings }) => ({
  document: document.singleRecord,
  isFetching: document.isFetching,
  didFetched: document.didFetched,
  customer: config.customer,
  settings: settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
  requestSingleDocument: (id) => dispatch(requestSingle(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DocumentsDetailsPage),
);
