import React from 'react';
import { Utils } from 'billon-ui';
import * as Styled from '../../modules/Rodo/containers/DocumentTable/styled';

const { Icon, Loader: LoaderModule } = Utils;
const { Loader } = LoaderModule;

const FileActionButtonFormatter =
  (
    currentDownload: Array<string>,
    fileDownload: (jobId: string) => void,
    preview: boolean = false,
  ) =>
  (
    address: string,
    {
      jobId,
      documentBlockchainAddress,
    }: { jobId: string; documentBlockchainAddress: string },
  ) => {
    if (
      currentDownload.includes(address || jobId || documentBlockchainAddress)
    ) {
      return (
        <Styled.LoadingButton color="link" className="ignore-row-click">
          <Loader width={36} />
        </Styled.LoadingButton>
      );
    }
    return (
      <Styled.PrevButton
        color="link"
        className="ignore-row-click"
        onClick={() => fileDownload(jobId)}
      >
        <Icon billon={!preview} name={preview ? 'eye' : 'pdf'} />
      </Styled.PrevButton>
    );
  };

export default FileActionButtonFormatter;
