import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { Utils, Form, Grid } from 'billon-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import CalendarField from 'js/ui/CalendarField';

// Actions
import { applyFilters } from '../../actions';

const { SelectField, TextField } = Form;
const { Button: ButtonModule, Text, Card } = Utils;
const { Button } = ButtonModule;
const { Row, Col } = Grid;

const StyledFilterCard = styled(Card)`
  position: relative;
  box-shadow: none;
  background: #fafafa;
  padding: 0px;
  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

class AnalyticsFilterForm extends Component {
  constructor(props) {
    super(props);

    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
  }

  applyFilters(filters) {
    const { history } = this.props;
    applyFilters(history, filters);
  }

  handleFilterSubmit(values) {
    const { initialValues } = this.props;
    if (values.publicationDate?.length === 0) {
      values.publicationDate = undefined;
    }
    if (values.validFromTo?.length === 0) {
      values.validFromTo = undefined;
    }
    this.applyFilters({
      ...initialValues,
      ...values,
      page: undefined,
      limit: undefined,
    });
  }

  render() {
    const { handleSubmit, categories, intl } = this.props;

    const publishedStatus = intl.formatMessage({
      id: 'Published',
      defaultMessage: 'Published',
    });

    const preparedStatus = intl.formatMessage({
      id: 'Prepared',
      defaultMessage: 'Prepared',
    });
    const uploadingStatus = intl.formatMessage({
      id: 'Uploading',
      defaultMessage: 'Uploading',
    });
    const invalidStatus = intl.formatMessage({
      id: 'Invalid',
      defaultMessage: 'Invalid',
    });

    const privateStatus = intl.formatMessage({
      id: 'Private',
      defaultMessage: 'Private',
    });

    const publicStatus = intl.formatMessage({
      id: 'Public',
      defaultMessage: 'Public',
    });

    const statusOptions = [
      { label: publishedStatus, value: 'PUBLISHING-OK' },
      {
        label: uploadingStatus,
        value: 'PUBLISHING-INITIATED',
      },
      {
        label: preparedStatus,
        value: 'PREPARED-TO-SIGN',
      },
      { label: invalidStatus, value: 'PUBLISHING-ERROR' },
    ];

    const documentTypeOptions = [
      { label: privateStatus, value: 'PRIVATE' },
      { label: publicStatus, value: 'PUBLIC' },
    ];
    return (
      <StyledFilterCard>
        <Text
          as="p"
          fontSize={'16px'}
          fontWeight={700}
          margin={'10px 0 25px 0'}
        >
          <FormattedMessage
            id="Generate report by"
            defaultMessage="Generate report by"
          />
        </Text>
        <form onSubmit={handleSubmit(this.handleFilterSubmit)}>
          <Row>
            <Col md={3}>
              <Text
                as="p"
                fontSize={'12px'}
                fontWeight={700}
                margin={'0 0 5px 0'}
              >
                <FormattedMessage
                  id="Document title"
                  defaultMessage="Document title"
                />
              </Text>
              <Field component={TextField} name="title" />
            </Col>
            <Col md={3}>
              {' '}
              <Text
                as="p"
                fontSize={'12px'}
                fontWeight={700}
                margin={'0 0 5px 0'}
              >
                <FormattedMessage id="Category" defaultMessage="Category" />
              </Text>
              <Field
                component={SelectField}
                name="category"
                searchable
                options={categories}
                isClearable
              />
            </Col>
            <Col md={3}>
              <Text
                as="p"
                fontSize={'12px'}
                fontWeight={700}
                margin={'0 0 5px 0'}
              >
                <FormattedMessage
                  id="Publication day from to"
                  defaultMessage="Publication day from-to"
                />
              </Text>
              <Field
                component={CalendarField}
                showMonths={2}
                name="publicationDate"
                range
                enableTime={false}
                dateFormat="Y-m-d"
                isClearable
              />
            </Col>
            <Col md={3}>
              {' '}
              <Text
                as="p"
                fontSize={'12px'}
                fontWeight={700}
                margin={'0 0 5px 0'}
              >
                <FormattedMessage id="Status" defaultMessage="Status" />
              </Text>
              <Field
                component={SelectField}
                name="statusList"
                searchable
                options={statusOptions}
                isClearable
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Text
                as="p"
                fontSize={'12px'}
                fontWeight={700}
                margin={'0 0 5px 0'}
              >
                <FormattedMessage
                  id="Valid from to"
                  defaultMessage="Valid from-to"
                />
              </Text>

              <Field
                component={CalendarField}
                showMonths={2}
                name="validFromTo"
                range
                enableTime={false}
                dateFormat="Y-m-d"
                isClearable
              />
            </Col>
            <Col md={3}>
              {' '}
              <Text
                as="p"
                fontSize={'12px'}
                fontWeight={700}
                margin={'0 0 5px 0'}
              >
                <FormattedMessage
                  id="Document type"
                  defaultMessage="Document type"
                />
              </Text>
              <Field
                component={SelectField}
                name="type"
                searchable
                options={documentTypeOptions}
                isClearable
              />
            </Col>
            <Col md={3}></Col>
            <Col
              md={3}
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: ' 5px',
              }}
            >
              <Button outline width="100%" className="button-static">
                <FormattedMessage
                  id="Generate report"
                  defaultMessage="Generate report"
                />
              </Button>
            </Col>
          </Row>
        </form>
      </StyledFilterCard>
    );
  }
}

AnalyticsFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  categories: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  customer: PropTypes.string.isRequired,
};

const mapStateToProps = ({ config, ...state }) => {
  return {
    customer: config.customer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetField: (field) => dispatch(change('filterForm', field, '')),
});

export default withRouter(
  injectIntl(
    reduxForm({
      form: 'filterForm',
    })(connect(mapStateToProps, mapDispatchToProps)(AnalyticsFilterForm)),
  ),
);
