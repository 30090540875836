import { Form as FormModule, Table as TableModule, Utils } from 'billon-ui';
import React from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

const { TableCell: TC } = TableModule;
const { Button: ButtonModule,  } = Utils;
const { Button } = ButtonModule;

export const MyButton = styled.button`
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  margin-left: 1rem;

  &:hover,
  &:focus {
    outline: none;
  }
`;

export const CopiedInfo = styled.div`
  position: absolute;
  font-size: ${(props) => props.theme.fontSize12};
  background-color: #acbbc7;
  color: #fff;
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 0.25rem 1.5rem;
  top: -2.5rem;
  left: 50%;
  margin-left: -57px;
  transition: ${(props) => props.theme.transitionBase};
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

export const DocumentInfo = styled.div`
  .btn {
    font-size: 13px;
    height: 100%;

    ${down('sm')} {
      width: 300px;
    }
  }

  .info-col {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .border-right {
    border-right: 1px solid ${(props) => props.theme.palette.grayLight};
  }

  label.large {
    display: block;
    margin-bottom: 0;
  }

  .blockchain-address-span {
    word-wrap: break-word;
    font-weight: 600;
  }

  .status-span {
    font-weight: 600;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      font-size: ${(props) => props.theme.fontSizeSm};
      margin-bottom: 1rem;

      label {
        display: block;
        font-weight: ${(props) => props.theme.fontWeightBold};
        margin-bottom: 0;
      }
    }
  }
`;

export const TableCell = styled(TC)`
  white-space: nowrap;
  font-weight: ${(props) =>
    props.row.isNewlyPublished
      ? props.theme.fontWeightBold
      : props.theme.fontWeightBase};
`;

export const PrevButton = styled(Button)`
  padding: 0;
  padding-top: 0.5em;
  padding-left: 1em;
  svg {
    color: #212122;
  }
`;

export const LoadingButton = styled(Button)`
  padding: 0;
  padding-top: 0.8em;
  padding-left: 1em;
  svg {
    color: #212122;
  }
`;

export const CheckboxContainer = styled.div`
  label {
    padding-top: 0.5em;
    padding-left: 3em;
  }
`;

export const UseForm = styled.form`
  width:100%;
`;

export const ExecuteBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2rem;
  @media (max-width: 1024px) {
    justify-content: flex-start;
  }
`;

