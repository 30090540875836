import React from 'react';
import { mapPublicationStatuses } from '../../helpers';
import StatusTooltip from '../../components/StatusTooltip';
import StatusWithIcon from 'js/ui/StatusWithIcon';
import { mapERRORS } from 'js/constraints';
import generateId from 'js/utils/generateId';

export const PublicationWithToolTipFormatter = (value, record) => {
  const label = mapPublicationStatuses(value);

  if (mapERRORS[value]) {
    const { jobId, title } = record;
    return (
      <StatusWithIcon label={mapPublicationStatuses(value)}>
        <StatusTooltip
          id={generateId(record)}
          label={mapERRORS[value]}
          jobId={jobId}
          title={title}
          delay={true}
        />
      </StatusWithIcon>
    );
  } else return label;
};
