import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form as FormModule } from 'billon-ui';
const { FormGroup, TextAreaField } = FormModule;

export const ControlledTextAreaField = ({ name, disabled, placeholder, rows = 3 }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <FormGroup>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <TextAreaField
            input={field}
            meta={{
              touched: fieldState.invalid,
              error: errors?.[field.name]?.message,
            }}
            rows={rows}
            disabled={disabled}
            placeholder={placeholder}
          />
        )}
      />
    </FormGroup>
  )
}
