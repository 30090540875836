import { createReducer } from 'redux-act';
import * as actions from './actions';

export default createReducer(
  {
    // List
    [actions.requestList]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.requestListQuiet]: (state) => ({
      ...state,
      isFetchingQuietly: true,
    }),
    [actions.receiveListSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      isFetchingQuietly: false,
      records: payload.rows,
      numberOfRecords: payload.count,
      agreementsCountByStatus: payload.agreementsCountByStatus,
    }),
    [actions.receiveListFailure]: (state) => ({
      ...state,
      isFetchingQuietly: false,
      isFetching: false,
    }),

    // Single
    [actions.requestSingle]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [actions.receiveSingleSuccess]: (state, payload) => ({
      ...state,
      singleRecord: payload,
      isFetching: false,
      didFetched: true,
    }),
    [actions.receiveSingleFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),

    // prepare&publish
    [actions.requestPrepare]: (state) => ({
      ...state,
      isSaving: true,
    }),
    [actions.receivePrepareSuccess]: (state) => ({
      ...state,
      isSaving: false,
    }),
    [actions.receivePrepareFailure]: (state) => ({
      ...state,
      isSaving: false,
    }),

    [actions.requestPublish]: (state) => ({
      ...state,
      isSaving: true,
    }),
    [actions.receivePublishSuccess]: (state) => ({
      ...state,
      isSaving: false,
    }),
    [actions.receivePublishFailure]: (state) => ({
      ...state,
      isSaving: false,
    }),

    [actions.requestUpdate]: (state) => ({
      ...state,
      isSaving: true,
    }),
    [actions.receiveUpdateSuccess]: (state) => ({
      ...state,
      isSaving: false,
    }),
    [actions.receiveUpdateFailure]: (state) => ({
      ...state,
      isSaving: false,
    }),

    // blockchain address version
    [actions.requestDownload]: (state, payload) => ({
      ...state,
      isDownloading: state.isDownloading.concat(
        [payload.documentBlockchainAddress, payload.jobId].filter((v) => !!v),
      ),
    }),
    [actions.receiveDownloadSuccess]: (state, payload) => ({
      ...state,
      isDownloading: state.isDownloading.filter((v) => payload.jobId === v),
    }),
    [actions.receiveDownloadFailure]: (state, payload) => ({
      ...state,
      isDownloading: state.isDownloading.filter((v) => payload.jobId === v),
    }),

    [actions.requestDownloadRecipientsFile]: (state) => ({
      ...state,
      isDownloadingRecipientsList: true,
    }),
    [actions.receiveDownloadRecipientsFileSuccess]: (state) => ({
      ...state,
      isDownloadingRecipientsList: false,
    }),
    [actions.receiveDownloadRecipientsFileFailure]: (state) => ({
      ...state,
      isDownloadingRecipientsList: false,
    }),

    [actions.requestDelete]: (state) => ({
      ...state,
      isDeleting: true,
    }),
    [actions.receiveDeleteSuccess]: (state) => ({
      ...state,
      isDeleting: false,
    }),
    [actions.receiveDeleteFailure]: (state) => ({
      ...state,
      isDeleting: false,
    }),

    [actions.requestResendAuthorizationCodes]: (state) => ({
      ...state,
      isResendingAuthorizationCodes: true,
    }),
    [actions.receiveResendAuthorizationCodesSuccess]: (state) => ({
      ...state,
      isResendingAuthorizationCodes: false,
    }),
    [actions.receiveResendAuthorizationCodesFailure]: (state) => ({
      ...state,
      isResendingAuthorizationCodes: false,
    }),

    [actions.requestForgetDocument]: (state) => ({
      ...state,
      isForgetting: true,
    }),
    [actions.receiveForgetDocumentSuccess]: (state) => ({
      ...state,
      isForgetting: false,
    }),
    [actions.receiveForgetDocumentFailure]: (state) => ({
      ...state,
      isForgetting: false,
    }),

    [actions.requestSingleNotificationHistory]: (state) => ({
      ...state,
      isFetchingNotificationHistory: true,
    }),
    [actions.requestSingleNotificationHistoryQuiet]: (state) => ({
      ...state,
      isFetchingNotificationHistoryQuietly: true,
    }),
    [actions.receiveSingleNotificationHistorySuccess]: (state, payload) => ({
      ...state,
      singleNotificationHistory: payload.rows,
      numberOfNotificationHistoryRecords: payload.count,
      isFetchingNotificationHistoryQuietly: false,
      isFetchingNotificationHistory: false,
      didNotificationHistoryFetched: true,
    }),
    [actions.receiveSingleNotificationHistoryFailure]: (state) => ({
      ...state,
      isFetchingNotificationHistory: false,
      isFetchingNotificationHistoryQuietly: false,
    }),
  },
  {
    isFetching: false,
    isSaving: false,
    didFetched: false,
    isDownloading: [],
    isDownloadingRecipientsList: false,
    isDeleting: false,
    records: [],
    agreementsCountByStatus: [],
    singleRecord: {},
    numberOfRecords: 0,
    isResendingAuthorizationCodes: false,
    isForgetting: false,

    //single notification history
    singleNotificationHistory: {},
    numberOfNotificationHistoryRecords: 0,
    isFetchingNotificationHistory: false,
    isFetchingNotificationHistoryQuietly: false,
    didNotificationHistoryFetched: false,
  },
);
