/* global sessionStorage */
import { ajax } from 'rxjs/observable/dom/ajax';
import { Observable } from 'rxjs/Rx';
import { Utils } from 'billon-ui';
import {
  requestList,
  requestListSuccess,
  requestListFailure,
  requestSave,
  receiveSaveSuccess,
  receiveSaveFailure,
  requestDelete,
  receiveDeleteSuccess,
  receiveDeleteFailure,
  requestToggle,
  receiveToggleSuccess,
  receiveToggleFailure,
  requestListQuiet,
} from './actions';
import routes from '../../api-routes';

import { parseCreateValues, parseFilters } from './helpers';

const { helpers } = Utils;
const { request, encodeQueryString } = helpers;
const {
  METHOD_GET,
  HEADER_CONTENT_TYPE,
  CONTENT_TYPE_APPLICATION_JSON,
  X_ACCESS_TOKEN,
  METHOD_POST,
  METHOD_DELETE,
  METHOD_PUT,
} = request;

const getCategoryListEpic = (actions$) =>
  actions$
    .ofType(requestList.getType(), requestListQuiet.getType())
    .mergeMap((action) =>
      ajax({
        url: `${routes.CATEGORY}`,
        headers: {
          [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
          Authorization: sessionStorage.getItem('token'),
        },
        method: METHOD_GET,
      })
        .map(({ response }) => requestListSuccess(response))
        .takeUntil(
          actions$.ofType(requestList.getType(), requestListQuiet.getType()),
        )
        .catch((err) => Observable.of(requestListFailure(err))),
    );

const saveCategoryEpic = (actions$) =>
  actions$.ofType(requestSave.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { values } = action.payload;

    return ajax({
      url: values.id
        ? routes.SINGLE_CATEGORY.replace('{id}', values.id)
        : routes.CATEGORY,
      body: JSON.stringify(parseCreateValues(values)),
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: values.id ? METHOD_PUT : METHOD_POST,
    })
      .map(({ response }) => {
        if (resolve) {
          resolve(response);
        }
        return receiveSaveSuccess(response);
      })
      .takeUntil(actions$.ofType(requestSave.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }

        return Observable.of(receiveSaveFailure(err));
      });
  });

const toggleCategoryActivityEpic = (actions$) =>
  actions$.ofType(requestToggle.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { value } = action.payload;

    return ajax({
      url: routes.TOGGLE_CATEGORY,
      body: JSON.stringify(value),
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_POST,
    })
      .map(({ response }) => {
        if (resolve) {
          resolve(response);
        }
        return receiveToggleSuccess(value);
      })
      .takeUntil(actions$.ofType(requestToggle.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }
        return Observable.of(receiveToggleFailure(value));
      });
  });

const deleteCategoryEpic = (actions$) =>
  actions$.ofType(requestDelete.getType()).mergeMap((action) => {
    const { resolve, reject } = action.payload.meta;
    const { id } = action.payload;

    return ajax({
      url: routes.SINGLE_CATEGORY.replace('{id}', id),
      body: JSON.stringify(action.payload.params),
      headers: {
        [HEADER_CONTENT_TYPE]: CONTENT_TYPE_APPLICATION_JSON,
        [X_ACCESS_TOKEN]: sessionStorage.getItem('token'),
      },
      method: METHOD_DELETE,
    })
      .map(({ response }) => {
        if (resolve) {
          resolve(response);
        }
        return receiveDeleteSuccess(response);
      })
      .takeUntil(actions$.ofType(requestDelete.getType()))
      .catch((err) => {
        if (reject) {
          reject(err);
        }
        return Observable.of(receiveDeleteFailure(err));
      });
  });

export default [
  getCategoryListEpic,
  saveCategoryEpic,
  deleteCategoryEpic,
  toggleCategoryActivityEpic,
];
