import { Table as TableModule, Utils } from 'billon-ui';
import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';

import { StyledTableCard } from './components';
import { CommonTableSearchFilterParams, getSearchParams } from './helpers';
import { CommonTableContext } from './index';

import useFilters from 'js/hooks/useFilters';

const { Table } = TableModule;
const { Pagination, helpers } = Utils;

const { getNumberOfPages } = helpers;

const CommonTableWrapper: React.FC<{
  continous?: boolean;
  detailsComponent?: (details) => JSX.Element;
  sortMethod: (column, order) => void;
  applyFilters: (
    filter: Partial<CommonTableSearchFilterParams<unknown>>,
  ) => void;
  limit: number;
}> = ({
  children,
  sortMethod,
  detailsComponent,
  applyFilters,
  continous,
  limit,
}) => {
  const { numberOfRecords, records } = useContext(CommonTableContext);

  const { filters } = useFilters();

  const [searchParams, setSearchParams] = useState(
    getSearchParams(filters, limit),
  );

  const numberOfPages = useMemo(
    () =>
      getNumberOfPages(numberOfRecords, searchParams.pagination?.limit || 10),
    [numberOfRecords, searchParams],
  );

  const handlePageClick = useCallback(
    (page) =>
      applyFilters({
        ...searchParams,
        pagination: { page: page, limit: searchParams.pagination.limit },
      }),
    [applyFilters, searchParams],
  );

  useEffect(() => {
    setSearchParams(getSearchParams(filters, limit));
  }, [filters, limit]);

  const sliceTableData = () => {
    let start = searchParams.pagination?.page || 1;
    start = (start - 1) * limit;
    return records.slice(start, start + limit);
  };

  return (
    <StyledTableCard>
      <Table
        responsive
        tableData={continous ? sliceTableData() : records}
        sortMethod={sortMethod}
        detailsComponent={detailsComponent}
      >
        {
          React.Children.map(
            children,
            (Child) => Child,
          ) /* we have to flat child array to force Table calculate colspan properly */
        }
      </Table>
      <Pagination
        onClick={handlePageClick}
        numberOfPages={numberOfPages}
        currentPage={searchParams.pagination?.page || 1}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
      />
    </StyledTableCard>
  );
};

export default CommonTableWrapper;
