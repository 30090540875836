import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form as FormModule, Utils, Layout } from 'billon-ui';
import * as Styled from './styled';

const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { FormGroup } = FormModule;
const { Content: ContentModule } = Layout;
const { SuccessContent } = ContentModule;

const SetPasswordSuccess = ({ onClose, messageId }) => {
  return (
    <Styled.Card>
      <Styled.Title>
        <FormattedMessage id={messageId} defaultMessage={messageId} />
      </Styled.Title>
      <Styled.CardBodyWrapper>
        <SuccessContent height="20rem" />
        <Styled.CardBodyWrapper>
          <FormGroup>
            <Button type="submit" size="lg" block onClick={onClose}>
              <FormattedMessage id="Close" defaultMessage="Close" />
            </Button>
          </FormGroup>
        </Styled.CardBodyWrapper>
      </Styled.CardBodyWrapper>
    </Styled.Card>
  );
};

SetPasswordSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
  messageId: PropTypes.string.isRequired,
};

export default SetPasswordSuccess;
