import { parseDateRange } from 'js/helpers/parseDateRange';

export const parseFilters = ({
  blockchainAddress,
  publicationDateRange,
  documentStatusList,
  publishedBy,
  title,
  validSinceDateRange,
  category,
  identity,
}) => ({
  blockchainAddress: blockchainAddress || undefined,
  categoryId: category?.value || undefined,
  identity: identity ? { documentNumber: identity.value } : undefined,
  publicationDateRange: parseDateRange(publicationDateRange),
  documentStatusList: documentStatusList && documentStatusList.value,
  publishedBy: publishedBy ? publishedBy.toLowerCase() : undefined,
  title: title ? title.toLowerCase() : undefined,
  validSinceDateRange: parseDateRange(validSinceDateRange),
});
