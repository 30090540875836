import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Layout as LayoutModule, Utils } from 'billon-ui';

// Utils
import { SETTINGS_CONSTANTS } from '../../../../constraints';

// Components
import { RequestDetails } from '../../containers';

// Actions
import {
  requestSingle,
  requestDownload as fileDownloadAction,
} from '../../actions';
// import { requestSingle, requestSingleRequest } from '../../actions';

const { Content: ContentModule } = LayoutModule;
const { Content, ContentContainer } = ContentModule;
const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

const RequestDetailsContainer = styled(ContentContainer)`
  background-color: ${(props) => props.theme.palette.grayLightest};
`;

class RequestDetailsPage extends Component {
  //delete unnecessary things when done
  componentDidMount() {
    const { match, requestSingleRequest } = this.props;

    const { id } = match.params;

    requestSingleRequest(id);
  }

  componentDidUpdate(prevProps) {
    const { match, requestSingleRequest } = this.props;
    const { id } = match.params;

    if (prevProps.match.params.id !== id) {
      requestSingleRequest(id);
    }
  }

  render() {
    const {
      request,
      isFetching,
      didFetched,
      customer,
      history,
      settings,
      isDownloading,
      fileDownload,
    } = this.props;

    if (isFetching || !didFetched) {
      return (
        <Content fluid>
          <PageLoader />
        </Content>
      );
    }

    return (
      <RequestDetailsContainer fluid>
        <Content fluid>
          <RequestDetails
            request={request}
            customer={customer}
            onBackClick={() => history.push('/requests')}
            backButtonLabel={
              <FormattedMessage id="Requests" defaultMessage="Requests" />
            }
            publisherName={settings[SETTINGS_CONSTANTS.PUBLICATOR_NAME]}
            isDownloading={isDownloading}
            fileDownload={fileDownload}
          />
        </Content>
      </RequestDetailsContainer>
    );
  }
}

RequestDetailsPage.propTypes = {
  match: PropTypes.object.isRequired,
  requestSingleRequest: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  didFetched: PropTypes.bool.isRequired,
  request: PropTypes.object,
  customer: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  settings: PropTypes.object,
  isDownloading: PropTypes.array.isRequired,
  fileDownload: PropTypes.func.isRequired,
};

const mapStateToProps = ({ requests, config, settings }) => ({
  request: requests.singleRecord,
  isFetching: requests.isFetching,
  didFetched: requests.didFetched,
  customer: config.customer,
  settings: settings.settings,
  isDownloading: requests.isDownloading,
});

const mapDispatchToProps = (dispatch) => ({
  requestSingleRequest: (id) => dispatch(requestSingle(id)),
  fileDownload: (blockchainAddress, jobId) =>
    dispatch(fileDownloadAction(blockchainAddress, jobId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestDetailsPage),
);
