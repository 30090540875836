import { Utils } from 'billon-ui';
import { isUndefined } from 'lodash';

const { helpers } = Utils;
const { parseFilters: superParseFilters } = helpers;

export const parseSave = (values) => {
  const formData = new FormData();
  if (values.reportStartDate) {
    formData.append('reportStartDate', values.reportStartDate);
  }
  if (values.reportEndDate) {
    formData.append('reportEndDate', values.reportEndDate);
  }
  if (values.reportType) {
    formData.append('reportType', values.reportType);
  }
  if (values.pageSize) {
    formData.append('pageSize', values.pageSize);
  }
  if (values.pageOffset) {
    formData.append('pageOffset', values.pageOffset);
  }

  if (sessionStorage.getItem('language')) {
    formData.append('language', sessionStorage.getItem('language'));
  } else {
    formData.append('language', 'pl');
  }

  return formData;
};

export const parseFilters = (filters) => {
  const output = superParseFilters(filters);

  if (filters.include) {
    output.include = filters.include;
  }

  if (filters.searchBar) {
    output.where.title = filters.searchBar;
  }

  if (filters.recipientId) {
    output.where.publisherCif = filters.recipientId;
  }

  if (filters.title) {
    output.where.title = filters.title;
  }

  if (filters.nameAndSurname) {
    output.where.nameAndSurname = filters.nameAndSurname;
  }
  if (filters.indexNumber) {
    output.where.issuedNumber = filters.indexNumber;
  }

  if (filters.type) {
    output.where.type = filters.type.value;
  }

  // if (filters.signDate && filters.signDate.length) {

  // }

  if (filters.category) {
    output.where.categoryId = filters.category.value;
  }

  if (filters.publicationDate) {
    output.where.publicationDate = {};

    if (Array.isArray(filters.publicationDate)) {
      output.where.publicationDate.from = filters.publicationDate[0];
      output.where.publicationDate.to = filters.publicationDate[1];
    }
    if (filters.publicationDate.from) {
      output.where.publicationDate.from = filters.publicationDate.from;
    }

    if (filters.publicationDate.to) {
      output.where.publicationDate.to = filters.publicationDate.to;
    }
  }

  if (filters.issueDate && filters.issueDate.length) {
    output.where.issueDate = {};

    if (Array.isArray(filters.issueDate)) {
      output.where.issueDate.from = filters.issueDate[0];
      output.where.issueDate.to = filters.issueDate[1];
    }
  }

  if (filters.validFromTo && filters.validFromTo.length) {
    output.where.validSince = {};
    output.where.validUntil = {};

    if (Array.isArray(filters.validFromTo)) {
      output.where.validSince.from = filters.validFromTo[0];
      output.where.validUntil.to = filters.validFromTo[1];
    }
  }

  if (filters.validSince) {
    output.where.validSince = {};
    if (filters.validSince.from) {
      output.where.validSince.from = filters.validSince.from;
    }

    if (filters.validSince.to) {
      output.where.validSince.to = filters.validSince.to;
    }
  }

  if (filters.validUntil) {
    output.where.validUntil = {};
    if (filters.validUntil.from) {
      output.where.validUntil.from = filters.validUntil.from;
    }
    if (filters.validUntil.to) {
      output.where.validUntil.to = filters.validUntil.to;
    }
  }

  if (!isUndefined(filters.isOld)) {
    output.where.isOld = filters.isOld;
  }

  if (filters.status) {
    output.where.status = filters.status.value;
  }

  if (filters.statusList) {
    output.where.statusList = filters.statusList.value;
  }

  if (filters.latest) {
    output.where.latest = filters.latest;
  }

  return output;
};
