/* global window */
import React from 'react';
import { Layout, Modal as ModalModule } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

// Components
import {
  CreateAgreementForm as CreateForm,
  AgreementTable as DocumentTable,
} from '../../containers';

// Actions
import { requestDelete } from '../../actions';

const { Content: ContentModule } = Layout;
const { ContentContainer } = ContentModule;
const {
  SuccessModal,
  AbstractCrudComponent,
  CreateModal,
  ConfirmModal,
  ErrorModal,
} = ModalModule;

class DocumentsPage extends AbstractCrudComponent {
  handleCreate(initialValues, callback) {
    const finalInitialValues = {
      ...initialValues,
      publishedBy: this.props.user.login,
    };

    this.setState({
      createModalOpened: true,
      initialValues: finalInitialValues,
      callback,
    });
  }

  handleSuccessModalClose() {
    this.setState({
      successModalOpened: false,
    });
  }

  handleDeleteSuccessModalClose() {
    this.setState(
      {
        deleteSuccessModalOpened: false,
      },
      () => window.location.reload(),
    );
  }

  render() {
    const {
      createModalOpened,
      successModalOpened,
      initialValues,
      confirmDeleteModalOpened,
      deleteSuccessModalOpened,
      deleteErrorModalOpened,
      deleteErrorMessage,
    } = this.state;
    const { isDeleting } = this.props;

    return (
      <ContentContainer fluid>
        <DocumentTable
          handleCreate={this.handleCreate}
          handleDelete={this.handleDelete}
        />
        <CreateModal
          isOpen={createModalOpened}
          onFinished={this.handleCreateFinished}
          onCancel={this.handleCancel}
          initialValues={initialValues}
          className="modal-xl"
          createMessage={
            <FormattedMessage
              id="Publishing agreement"
              defaultMessage="Publishing agreement"
            />
          }
          editMessage={
            <FormattedMessage
              id="Publishing agreement"
              defaultMessage="Publishing agreement"
            />
          }
        >
          <CreateForm
            handleCreateRecipient={this.handleCreateRecipient}
            onCancel={this.handleCancel}
          />
        </CreateModal>
        <SuccessModal
          isOpen={successModalOpened}
          onClose={this.handleSuccessModalClose}
        >
          <FormattedMessage
            id="Document publication has been initiated."
            defaultMessage="Document publication has been initiated."
          />
        </SuccessModal>

        <ConfirmModal
          isLoading={isDeleting}
          isOpen={confirmDeleteModalOpened}
          title={
            <FormattedMessage
              id="Document deletion confirmation"
              defaultMessage="Document deletion confirmation"
            />
          }
          onAccept={this.handleDeleteConfirmed}
          onCancel={this.handleCancel}
        >
          <FormattedMessage
            id="Are You sure You want to delete this document?"
            defaultMessage="Are You sure You want to delete this document?"
          />
        </ConfirmModal>
        <SuccessModal
          isOpen={deleteSuccessModalOpened}
          title={
            <FormattedMessage
              id="Document deleted"
              defaultMessage="Document deleted"
            />
          }
          onClose={this.handleDeleteSuccessModalClose}
        >
          <FormattedMessage
            id="Document has been deleted successfully."
            defaultMessage="Document has been deleted successfully."
          />
        </SuccessModal>
        <ErrorModal
          isOpen={deleteErrorModalOpened}
          title={
            <FormattedMessage
              id="Document deletion error"
              defaultMessage="Document deletion error"
            />
          }
          onClose={this.handleDeleteErrorModalClose}
        >
          {deleteErrorMessage && (
            <FormattedMessage
              id={deleteErrorMessage.message}
              defaultMessage={deleteErrorMessage.message}
            />
          )}
        </ErrorModal>
      </ContentContainer>
    );
  }
}

const mapStateToProps = ({ authentication, document }) => ({
  user: authentication.user,
  isDeleting: document.isDeleting,
});

const mapDispatchToProps = (dispatch) => ({
  deleteEntry: (values, meta) => dispatch(requestDelete(values, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPage);
