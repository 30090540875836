import React from 'react';
import * as Styled from './styled';
import { Grid, Utils } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
const { Col } = Grid;
const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;

const ModalFooter = ({
  onBack,
  onNext,
  isLoading,
  isBlocked,
  nextAlignLeft,
}) => (
  <Styled.ModalFooter>
    <Styled.ZeroCol md={3}>
      <Styled.BackButton secondary onClick={onBack}>
        <FormattedMessage id="back" defaultMessage="back" />
      </Styled.BackButton>
    </Styled.ZeroCol>
    {nextAlignLeft && <Col md={3}></Col>}
    <Col md={6}>
      {isLoading ? (
        <ButtonLoader />
      ) : (
        <Button
          size="lg"
          block
          onClick={onNext}
          type="submit"
          disabled={isBlocked}
        >
          <FormattedMessage id="Next" defaultMessage="Next" />
        </Button>
      )}
    </Col>
    {!nextAlignLeft && <Col md={3}></Col>}
  </Styled.ModalFooter>
);

export default ModalFooter;
