import base from './base';
import { CUSTOMER, PUBLICATION_TYPE } from '../constraints';

const config = {
  ...base,
  backendUrl: 'https://apirspublication.uat.bybillon.com',
  enablePrivate: true,
  customer: CUSTOMER.DEFAULT,
  publicationType: PUBLICATION_TYPE.TWO_STEP,
  manageRecipients: true,
  remoteSign: true,
  observer: false,
  activeDelivery: false,
  language: 'pl',
};

export default config;
