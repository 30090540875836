import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const PageHeader = ({ title, toolTip, searchBar, searchBarLabel }) => (
  <Styled.PageHeaderWrapper>
    <Styled.TitleHeaderWrapper>
      {title}
      {toolTip && <> {toolTip}</>}
    </Styled.TitleHeaderWrapper>
    {searchBar && (
      <Styled.SearchBarSection>
        <Styled.TitleHeaderWrapper>{searchBarLabel}</Styled.TitleHeaderWrapper>
        <Styled.SearchBarWrapper>{searchBar}</Styled.SearchBarWrapper>
      </Styled.SearchBarSection>
    )}
  </Styled.PageHeaderWrapper>
);

PageHeader.propTypes = {
  title: PropTypes.any,
  toolTip: PropTypes.any,
  searchBar: PropTypes.any,
  searchBarLabel: PropTypes.any,
};

export default PageHeader;
