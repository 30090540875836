import { Layout, Modal as ModalModule } from 'billon-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { requestDelete, requestToggle } from '../../actions';
import { CategoryTable, CreateForm } from '../../containers';

const { Content: ContentModule } = Layout;
const { ContentContainer, Content } = ContentModule;
const {
  CreateModal,
  SuccessModal,
  ConfirmModal,
  ErrorModal,
  AbstractCrudComponent,
} = ModalModule;

class HomePage extends AbstractCrudComponent {
  render() {
    const {
      createModalOpened,
      successModalOpened,
      initialValues,
      confirmDeleteModalOpened,
      deleteSuccessModalOpened,
      deleteErrorModalOpened,
      deleteErrorMessage,
    } = this.state;
    const { isDeleting } = this.props;

    return (
      <ContentContainer fluid>
        <Content fluid>
          <CategoryTable
            handleCreate={this.handleCreate}
            handleDelete={this.handleDelete}
          />
          <CreateModal
            isOpen={createModalOpened}
            onSuccess={this.handleCreateFinished}
            onCancel={this.handleCancel}
            initialValues={{ isActive: true, ...initialValues }}
            editMessage={
              <FormattedMessage
                id="Editing category"
                defaultMessage="Editing category"
              />
            }
            createMessage={
              <FormattedMessage
                id="Adding category"
                defaultMessage="Adding category"
              />
            }
          >
            <CreateForm />
          </CreateModal>
          <SuccessModal
            title={
              <FormattedMessage
                id="Successfully saved"
                defaultMessage="Successfully saved"
              />
            }
            isOpen={successModalOpened}
            onClose={this.handleSuccessModalClose}
          >
            <FormattedMessage
              id="The process of adding a category has started"
              defaultMessage="The process of adding a category has started"
            />
          </SuccessModal>
          <ConfirmModal
            isLoading={isDeleting}
            isOpen={confirmDeleteModalOpened}
            title={
              <FormattedMessage
                id="Category deletion confirmation"
                defaultMessage="Category deletion confirmation"
              />
            }
            onAccept={this.handleDeleteConfirmed}
            onCancel={this.handleCancel}
          >
            <FormattedMessage
              id="Are You sure You want to delete this category?"
              defaultMessage="Are You sure You want to delete this category?"
            />
          </ConfirmModal>
          <SuccessModal
            isOpen={deleteSuccessModalOpened}
            title={
              <FormattedMessage
                id="Category deleted"
                defaultMessage="Category deleted"
              />
            }
            onClose={this.handleDeleteSuccessModalClose}
          >
            <FormattedMessage
              id="Category has been deleted successfully."
              defaultMessage="Category has been deleted successfully."
            />
          </SuccessModal>
          <ErrorModal
            isOpen={deleteErrorModalOpened}
            title={
              <FormattedMessage
                id="Category deletion error"
                defaultMessage="Category deletion error"
              />
            }
            onClose={this.handleDeleteErrorModalClose}
          >
            {deleteErrorMessage && (
              <FormattedMessage
                id={deleteErrorMessage.message}
                defaultMessage={deleteErrorMessage.message}
              />
            )}
          </ErrorModal>
        </Content>
      </ContentContainer>
    );
  }
}

HomePage.propTypes = {
  isDeleting: PropTypes.bool,
};

const mapStateToProps = ({ category }) => ({
  isDeleting: category.isDeleting,
});

const mapDispatchToProps = (dispatch) => ({
  deleteEntry: (id, meta) => dispatch(requestDelete(id, meta)),
  toggleCategory: (id) => dispatch(requestToggle(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
