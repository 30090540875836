import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Grid } from 'billon-ui';
import * as Styled from './styled';
import { DOCUMENT_STATUS } from '../../../../constraints';
import * as formatters from '../../../../formatters';
import { ActionButtons } from './components';
import { CopiedInfo } from '../../../../components';

const { Row } = Grid;

const DailyReportTableDetails = ({ record, handleCreate, handleDelete }) => {
  const { activeDelivery } = useSelector(({ config, settings }) => ({
    settings: settings.settings,
    activeDelivery: config.activeDelivery,
    customer: config.customer,
  }));

  if (!record.documentBlockchainAddress && !record.jobId) {
    return null;
  }

  return (
    <Styled.DocumentInfo>
      <Row noGutters margin={0}>
        <Styled.Col lg={activeDelivery ? 4 : 5}>
          <CopiedInfo
            label={'Blockchain address'}
            alt={'Document does not yet have a blockchain address'}
            value={record?.documentBlockchainAddress}
            emphasisedValue={true}
          />
        </Styled.Col>
        <Styled.Col lg={activeDelivery ? 3 : 2}>
          {activeDelivery && (
            <>
              <label className="large">
                <FormattedMessage
                  id="e-Delivery Status"
                  defaultMessage="e-Delivery Status"
                />{' '}
                :
              </label>
              <span className="status-span">
                <FormattedMessage
                  id="Confirmation of reading"
                  defaultMessage="Confirmation of reading"
                />
              </span>
              <br />
              <span>
                <FormattedMessage id="Date" defaultMessage="Date" />:{' '}
                {formatters.momentFormatter(new Date(2021, 3, 16, 17, 0, 0))}
              </span>
            </>
          )}
        </Styled.Col>
        <Styled.Col lg={5} className="info-col">
          {record.status !== DOCUMENT_STATUS.NOT_AVAILABLE && (
            <ActionButtons
              record={record}
              onDeleteDocument={handleDelete}
              onPublishDocument={handleCreate}
            />
          )}
        </Styled.Col>
      </Row>
    </Styled.DocumentInfo>
  );
};

export default DailyReportTableDetails;
