import React, { useCallback, useEffect } from 'react';
import { Layout as LayoutModule, Utils } from 'billon-ui';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { requestSingleNotificationHistory } from '../../actions';
import { NOTIFICATION_HISTORY_STATUSES } from 'js/constraints';

import CommonTable, {
  CommonTableContext,
} from '../../../../components/CommonTable';

import { NotificationHistoryColumns } from './columns';
import { NotificationHistoryDetails } from './NotificationHistoryDetails';

const { Content: ContentModule } = LayoutModule;
const { Loader: LoaderModule, Text } = Utils;

const { PageLoader } = LoaderModule;
const { Content } = ContentModule;

const NotificationHistory: React.FC<{
  jobId: string;
  documentBlockchainAddress: string;
}> = ({ jobId, documentBlockchainAddress }) => {
  const {
    records,
    numberOfRecords,
    isFetching,
    isFetchingQuietly,
    didFetched,
  } = useSelector(({ document }: any) => {
    return {
      records: document.singleNotificationHistory,
      numberOfRecords: document.numberOfNotificationHistoryRecords,
      isFetching: document.isFetchingNotificationHistory,
      isFetchingQuietly: document.isFetchingNotificationHistoryQuietly,
      didFetched: document.didNotificationHistoryFetched,
    };
  });

  const dispatch = useDispatch();

  const load = useCallback(
    (searchParamsReload) =>
      dispatch(
        requestSingleNotificationHistory(documentBlockchainAddress, {
          ...searchParamsReload,
        }),
      ),
    [dispatch, documentBlockchainAddress],
  );

  useEffect(() => {
    load({});
  }, [load]);

  const detailsComponentRenderer = (record) => {
    let errorsHistory = record.errorsHistory;
    if (
      ![
        NOTIFICATION_HISTORY_STATUSES.UNDELIVERED,
        NOTIFICATION_HISTORY_STATUSES.ERROR,
      ].includes(record.status)
    ) {
      errorsHistory = [
        ...errorsHistory,
        {
          errorDate: record.deliveryDate,
          reason: record.status,
        },
      ];
    }
    return <NotificationHistoryDetails errorsHistory={errorsHistory} />;
  };

  if (isFetching && !didFetched) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  if ((!isFetching && !didFetched) || numberOfRecords === 0) {
    return (
      <Content fluid>
        <Text textAlign={'center'}>
          <FormattedMessage
            id="No data to display"
            defaultMessage="No data to display"
          ></FormattedMessage>
        </Text>
      </Content>
    );
  }

  return (
    <>
      <CommonTableContext.Provider
        value={{
          load,
          isFetching,
          isFetchingQuietly: false,
          numberOfRecords,
          records,
        }}
      >
        <CommonTable
          limit={5}
          customPageHeader={true}
          handleCreate={() => {}}
          columns={NotificationHistoryColumns}
          detailsComponentRenderer={detailsComponentRenderer}
        ></CommonTable>
      </CommonTableContext.Provider>
    </>
  );
};

export default NotificationHistory;
