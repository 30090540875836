import { Grid, Utils } from 'billon-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ActiveDeliveryStepsDiagramItem } from './ActiveDeliveryStepsDiagramItem';

const { Row, Col } = Grid;
const { Text, Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

const ActiveDeliveryStepsWrapper = styled.div`
  background-color: #eaf1f7;
  border: 1px solid #abc4c661;
  margin-left: -3rem;
  margin-right: -3rem;
  margin-top: 3rem;
  @media (max-width: 1199px) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
`;

const LineContainer = styled.div`
  width: ${({ threeElement }) => (threeElement ? '10%' : '20%')};
  position: relative;
  top: 0;
  left: -3%;
  @media (max-width: 1461px) {
    display: none;
  }
`;

const Line = styled.div`
  width: 130%;
  position: absolute;
  border-top: 1px solid #abc4c6;
  margin-top: 1rem;
`;

const E_DELIVERY_TYPE = {
  READ: 'read',
  ACQUAINTANCE: 'acquaintance',
};

const E_DELIVERY_STATUS = {
  DELIVERED: 'delivered',
  READ: 'read',
  ACQUAINTED: 'acquainted',
};

const ActiveDeliveryStepsDiagram = (props) => {
  // const {agreementStatus, history} = props.document;

  const deliveryType = 'read';
  // const deliveryType = "acquaintance";

  // const deliveryStatus = "delivered";
  const deliveryStatus = 'read';
  // const deliveryStatus = "acquainted";

  return (
    <ActiveDeliveryStepsWrapper>
      <Row
        margin={'1.5rem 3rem'}
        css={`
          display: flex;
          justify-content: center;
          align-items: flex-start;

          @media (max-width: 767px) {
            margin-left: 1rem;
            margin-right: 1rem;
          }
        `}
      >
        <Col
          xl={3.75}
          css={`
            border-right: 1px solid #abc4c661;
            @media (max-width: 1199px) {
              border-right: 0px;
            }
          `}
        >
          <Text margin={'0 0 30px 0'} fontWeight={700} fontSize="20px" as="h5">
            <FormattedMessage id="e-Delivery" defaultMessage="e-Delivery" />
          </Text>
          <Text margin={'0 0 30px 0'} fontSize="13px" as="p">
            <b>
              <FormattedMessage
                id="Type of e-Delivery"
                defaultMessage="Type of e-Delivery"
              />
              :
            </b>{' '}
            {deliveryType === 'read' ? (
              <FormattedMessage
                id="Confirmation of reading"
                defaultMessage="Confirmation of reading"
              />
            ) : (
              <FormattedMessage
                id="Confirmation of acquaintance"
                defaultMessage="Confirmation of acquaintance"
              />
            )}
          </Text>
          <Col padding={0} xl={11}>
            <Button outline block color="custom" fontColor="#2e75b5">
              <FormattedMessage
                id="e-Delivery Report"
                defaultMessage="e-Delivery Report"
              />
            </Button>
          </Col>
        </Col>
        <Col xl={8.25}>
          <Text
            margin={'0 0 30px 0'}
            fontWeight={700}
            fontSize="20px"
            as="h5"
            css={`
              @media (max-width: 1199px) {
                margin-top: 1.5rem;
              }
            `}
          >
            <FormattedMessage
              id="e-Delivery Status"
              defaultMessage="e-Delivery Status"
            />
          </Text>
          <Row
            css={`
              @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
            `}
          >
            <ActiveDeliveryStepsDiagramItem
              stepNumber={1}
              // date={history.find(el => el.agreementStatus === AGREEMENT_STATUS.PUBLISHED) ? history.find(el => el.agreementStatus === AGREEMENT_STATUS.PUBLISHED).publicationDate : ''}
              date={new Date(2021, 3, 15, 16, 0, 0)}
              backgroundColor="#2e75b5"
            >
              <FormattedMessage
                id="Delivery confirmation"
                defaultMessage="Delivery confirmation"
              />
            </ActiveDeliveryStepsDiagramItem>

            <LineContainer
              threeElement={deliveryType === E_DELIVERY_TYPE.ACQUAINTANCE}
            >
              <Line />
            </LineContainer>

            <ActiveDeliveryStepsDiagramItem
              stepNumber={2}
              date={new Date(2021, 3, 16, 17, 0, 0)}
              // backgroundColor="#fff"
              // fontColor="#2e75b5"
              backgroundColor={
                deliveryStatus === E_DELIVERY_STATUS.DELIVERED
                  ? '#fff'
                  : '#2e75b5'
              }
              fontColor={
                deliveryStatus === E_DELIVERY_STATUS.DELIVERED
                  ? '#2e75b5'
                  : '#fff'
              }
            >
              <FormattedMessage
                id="Confirmation of reading"
                defaultMessage="Confirmation of reading"
              />
            </ActiveDeliveryStepsDiagramItem>

            {deliveryType === E_DELIVERY_TYPE.ACQUAINTANCE && (
              <>
                <LineContainer
                  threeElement={deliveryType === E_DELIVERY_TYPE.ACQUAINTANCE}
                >
                  <Line />
                </LineContainer>
                <ActiveDeliveryStepsDiagramItem
                  stepNumber={3}
                  // date={history.find(el => el.agreementStatus === AGREEMENT_STATUS.WAITING_FOR_RECEIVER) ? history.find(el => el.agreementStatus === AGREEMENT_STATUS.WAITING_FOR_RECEIVER).publicationDate : ''}
                  // backgroundColor="#fff"
                  // fontColor="#2e75b5"
                  backgroundColor={
                    deliveryStatus === E_DELIVERY_STATUS.ACQUAINTED
                      ? '#2e75b5'
                      : '#fff'
                  }
                  fontColor={
                    deliveryStatus === E_DELIVERY_STATUS.ACQUAINTED
                      ? '#fff'
                      : '#2e75b5'
                  }
                >
                  <FormattedMessage
                    id="Confirmation of acquaintance"
                    defaultMessage="Confirmation of acquaintance"
                  />
                </ActiveDeliveryStepsDiagramItem>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </ActiveDeliveryStepsWrapper>
  );
};

ActiveDeliveryStepsDiagram.propTypes = {
  document: PropTypes.object.isRequired,
};

export default ActiveDeliveryStepsDiagram;
