import base from './base';
import { CUSTOMER, PUBLICATION_TYPE } from '../constraints';

const config = {
  ...base,
  backendUrl: 'https://apidiplomapublication.tdm.bybillon.com',
  enablePrivate: true,
  enableSigning: false,
  manageRecipients: true,
  customer: CUSTOMER.DIPLOMA,
  publicationType: PUBLICATION_TYPE.ONE_STEP,
};

export default config;
