import { Utils, Grid } from 'billon-ui';
import styled from 'styled-components';
import React from 'react';

export const AbsoluteDivForExitIcon = styled.div`
  position: absolute;
  right: 4%;
  top: 10%;
  cursor: pointer;
`;

export const FilterCard = styled(Utils.Card)`
  position: relative;
  box-shadow: none;
  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

export const Text = ({ children }) => (
  <Utils.Text as="p" fontSize={'12px'} fontWeight={700} margin={'0 0 5px 0'}>
    {children}
  </Utils.Text>
);

export const FilterBtnCol = styled(Grid.Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
