import React from 'react';
import { Layout } from '../index';
import { CUSTOMER } from '../../constraints';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CategoryHomePage from '../../modules/CategoriesNew/scenes/Home';

import { screens as HomePageScreens } from '../../modules/HomePage';
import * as DailyReportsScreens from '../../modules/DailyReports/screens';
import * as DocumentsNewScreens from '../../modules/DocumentsNew/screens';
import * as UserScreens from '../../modules/UsersNew/screens';
import { scenes as RecipientsNewScenes } from '../../modules/RecipientsNew';
import { scenes as ReportsScenes } from '../../modules/Reports';
import { scenes as RodoScenes } from '../../modules/Rodo';
import { useAuthenticatedContext } from '../../modules/Auth/hooks/useAuthenticated';
import { UnauthorizedRoutes } from '../UnauthorizedRoutes';
import { PasswordlessRoutes } from '../PasswordlessRoutes';
import appRoutes from '../../app-routes';
import { PublishDocumentPage } from '../../modules/DocumentsNew/screens/PublishDocumentPage/PublishDocumentPage';
import { decodeJwtToken } from 'js/modules/Login/hooks';
import { USER_STATUSES } from '../../constraints';
import { ProtectedComponent } from '../../modules/Auth/components';
import { UserRoles } from 'js/constraints';

const { HomePage, HelpPage } = HomePageScreens;
const { HomePage: ReportsHomePage } = ReportsScenes;
const {
  ResendNotificationPage,
  NotificationHistoryPage,
  DocumentDetailsPage: DocumentDetailsNewPage,
  DocumentUpdatePage,
  HomePage: DocumentNewHomePage,
  RootRedirectPage,
} = DocumentsNewScreens;
const { HomePage: DailyReportsHomePage, DailyReportDetailsPage } =
  DailyReportsScreens;
const {
  HomePage: UsersHomePage,
  UserDetailsPage,
  MePage,
  ChangePasswordPage,
  SetTemporaryPasswordPage,
  AdministrativePasswordChangePage,
} = UserScreens;
const { HomePage: RecipientsNewHomePage } = RecipientsNewScenes;
const { HomePage: RodoHomePage } = RodoScenes;

export const Routes = () => {
  const { customer } = useSelector(({ config }) => ({
    customer: config.customer,
    remoteSign: config.remoteSign,
    observer: config.observer,
  }));

  const { isAuthenticated } = useAuthenticatedContext();

  if (!isAuthenticated) {
    return <UnauthorizedRoutes />;
  }

  const decodedTokenData = decodeJwtToken(sessionStorage.getItem('token'));

  if (
    [USER_STATUSES.PENDING, USER_STATUSES.PASSWORD_EXPIRED].includes(
      decodedTokenData?.status,
    )
  ) {
    return <PasswordlessRoutes />;
  }

  return (
    <Router>
      <Layout>
        {
          {
            [CUSTOMER.TAURON]: (
              <>
                <Switch>
                  <Route path={appRoutes.SUPPORT} component={HelpPage} />
                  <Route path="/documents" component={DocumentNewHomePage} />
                  <Route
                    path={appRoutes.PUBLISH_DOCUMENT}
                    component={PublishDocumentPage}
                  />
                  <Route
                    path={appRoutes.DOCUMENTS_DETAILS}
                    component={DocumentDetailsNewPage}
                  />
                  <Route path="/me" component={MePage} />
                  <Route
                    path={appRoutes.PASSWORD_CHANGE}
                    component={ChangePasswordPage}
                  />
                  <Route path="/categories" component={CategoryHomePage} />
                  <Route
                    path={appRoutes.ADMIN_PASSWORD_CHANGE}
                    component={AdministrativePasswordChangePage}
                  />
                  <Route path={appRoutes.USERS} component={UsersHomePage} />
                  {/*<ProtectedComponent roles={[UserRoles.ADMIN]} render={false}>*/}
                  {/*  <Route*/}
                  {/*    path={appRoutes.USER_DETAILS}*/}
                  {/*    component={UserDetailsPage}*/}
                  {/*  />*/}
                  {/*</ProtectedComponent>*/}
                  <Route
                    path={appRoutes.USER_DETAILS}
                    component={UserDetailsPage}
                  />
                  <Route path="/reports" component={ReportsHomePage} />
                  <Route
                    path={appRoutes.RECIPTIENTS}
                    component={RecipientsNewHomePage}
                  />
                  {/*<ProtectedComponent roles={[UserRoles.DPI]} render={false}>*/}
                  {/*  <Route path="/rodo" component={RodoHomePage} />*/}
                  {/*</ProtectedComponent>*/}
                  <Route
                    path={appRoutes.DAILY_REPORTS_BLANK}
                    component={DailyReportsHomePage}
                  />
                  <Route
                    path={appRoutes.DAILY_REPORT_DETAILS}
                    component={DailyReportDetailsPage}
                  />
                  <Route path="/" component={RootRedirectPage} />
                </Switch>
                <Route
                  path={appRoutes.DOCUMENTS_DETAILS_NOTIFICATION_HISTORY}
                  component={NotificationHistoryPage}
                />
                <Route
                  path={appRoutes.DOCUMENTS_DETAILS_RESEND_NOTIFICATION}
                  component={ResendNotificationPage}
                />
                <Route
                  path={appRoutes.DOCUMENTS_DETAILS_UPDATE}
                  component={DocumentUpdatePage}
                />
                <Route
                  path={appRoutes.TEMPORATY_PASSWORD_SET}
                  component={SetTemporaryPasswordPage}
                />
              </>
            ),
            [CUSTOMER.DIPLOMA]: (
              <>
                <Switch>
                  <Route
                    path={appRoutes.PASSWORD_CHANGE_BLANK}
                    component={ChangePasswordPage}
                  />
                  <Route
                    path={appRoutes.PUBLISH_DOCUMENT}
                    component={PublishDocumentPage}
                  />
                  <Route path="/documents" component={DocumentNewHomePage} />
                  <Route
                    path={appRoutes.DOCUMENTS_DETAILS}
                    component={DocumentDetailsNewPage}
                  />
                  <Route path="/categories" component={CategoryHomePage} />
                  <Route
                    path={appRoutes.RECIPTIENTS}
                    component={RecipientsNewHomePage}
                  />
                  <Route path={appRoutes.USERS} component={UsersHomePage} />
                  <Route
                    path={appRoutes.USER_DETAILS}
                    component={UserDetailsPage}
                  />
                  <Route path={appRoutes.ME} component={MePage} />
                  <Route path={appRoutes.SUPPORT} component={HelpPage} />
                  <Route component={RootRedirectPage} />
                </Switch>
                <Route
                  path={appRoutes.DOCUMENTS_DETAILS_NOTIFICATION_HISTORY}
                  component={NotificationHistoryPage}
                />
                <Route
                  path={appRoutes.DOCUMENTS_DETAILS_RESEND_NOTIFICATION}
                  component={ResendNotificationPage}
                />
                <Route
                  path={appRoutes.DOCUMENTS_DETAILS_UPDATE}
                  component={DocumentUpdatePage}
                />
              </>
            ),
            [CUSTOMER.DEFAULT]: (
              <>
                <Switch>
                  <Route path={appRoutes.SUPPORT} component={HelpPage} />
                  <Route path="/documents" component={DocumentNewHomePage} />
                  <Route
                    path={appRoutes.PUBLISH_DOCUMENT}
                    component={PublishDocumentPage}
                  />
                  <Route
                    path={appRoutes.DOCUMENTS_DETAILS}
                    component={DocumentDetailsNewPage}
                  />
                  <Route path="/me" component={MePage} />
                  <Route
                    path={appRoutes.PASSWORD_CHANGE}
                    component={ChangePasswordPage}
                  />
                  <Route path="/categories" component={CategoryHomePage} />
                  <Route path={appRoutes.USERS} component={UsersHomePage} />
                  <ProtectedComponent roles={[UserRoles.ADMIN]} render={false}>
                    <Route
                      path={appRoutes.USER_DETAILS}
                      component={UserDetailsPage}
                    />
                  </ProtectedComponent>
                  <Route path="/reports" component={ReportsHomePage} />
                  <Route
                    path={appRoutes.RECIPTIENTS}
                    component={RecipientsNewHomePage}
                  />
                  <ProtectedComponent roles={[UserRoles.DPI]} render={false}>
                    <Route path="/rodo" component={RodoHomePage} />
                  </ProtectedComponent>
                  <Route path="/" component={RootRedirectPage} />
                </Switch>
                <Route
                  path={appRoutes.DOCUMENTS_DETAILS_NOTIFICATION_HISTORY}
                  component={NotificationHistoryPage}
                />
                <Route
                  path={appRoutes.DOCUMENTS_DETAILS_RESEND_NOTIFICATION}
                  component={ResendNotificationPage}
                />
                <Route
                  path={appRoutes.DOCUMENTS_DETAILS_UPDATE}
                  component={DocumentUpdatePage}
                />
              </>
            ),
          }[customer]
        }
      </Layout>
    </Router>
  );
};
