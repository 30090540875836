import React from 'react';
import _ from 'lodash';

// Hooks
import { useCategoryList } from 'js/modules/CategoriesNew/hooks/useCategoryList';

// Helpers
import { removeUndefinedValues } from 'js/helpers/removeUndefinedValues';

export const useCategoriesOptions = () => {
  const [categoryName, setCategoryName] = React.useState(undefined);

  const setSearchTextDebounced = React.useRef(
    _.debounce((callback) => callback(), 700),
  ).current;

  const { data: categoriesData, isFetching: isCategoriesFetching } =
    useCategoryList(
      {
        filters: removeUndefinedValues({
          name: categoryName,
        }),
        singleLevel: false,
        pagination: {
          limit: 20,
        },
      },
      {
        refetchOnMount: false,
      },
    );

  const { rows: categoriesRows } = categoriesData || {};

  const categories = React.useMemo(
    () =>
      (categoriesRows || []).reduce((arr, category) => {
        if (category.isActive) {
          arr.push({
            value: category.id,
            label: category.fullPath,
          });
        }
        return arr;
      }, []),
    [categoriesRows],
  );

  const onChangeCategoryInput = (inputText) => {
    if (inputText !== categoryName) {
      setSearchTextDebounced(() => setCategoryName(inputText));
    }
  };

  return { categories, isCategoriesFetching, onChangeCategoryInput };
};
