import React from 'react';
import { MAP_VALIDATION_ERROR_TYPES } from 'js/constraints';
import WithValueMessage from 'js/helpers/WithValueMessage';
import * as Styled from './styled';

const ValidationInfo = ({
  settingsKey,
  requirement,
  settings,
  isErrorActive,
}) => {
  const settingsRecord = settings?.find((item) => item.key === settingsKey);
  if (!settingsRecord) return null;
  const message = MAP_VALIDATION_ERROR_TYPES[requirement];
  return (
    settingsRecord?.value && (
      <Styled.ErrorLabel isErrorActive={isErrorActive}>
        <WithValueMessage
          messageId={message}
          name="n"
          value={settingsRecord.value}
        />
      </Styled.ErrorLabel>
    )
  );
};

export default ValidationInfo;
