import apiRoutes from 'js/api-routes';
import { useMutationWithLogout } from './useMutationWithLogout';
import { postRequest } from 'js/helpers/apiClient';

export const useForgetDocumentRequest = (options) =>
  useMutationWithLogout(
    (blockchainAddress) =>
      postRequest({
        url: apiRoutes.SINGLE_DOCUMENT_FORGET,
        data: { blockchainAddress: blockchainAddress },

        headers: {
          'x-access-token': sessionStorage.getItem('token'),
        },
      }),
    options,
  );
