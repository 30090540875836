import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Form as FormModule, Utils, Grid } from 'billon-ui';
import { requestUpdate } from '../../actions';
import DataTab from './DataTab';
import { DocumentData } from '../DocumentDetails/types';
import {
  PUBLICATION_MODES,
  RETAIN_OPTIONS,
  RETAIN_OPTIONS_VALUES,
  mapDOCUMENT_ACTIONS_ERROR,
  mapDOCUMENT_ACTIONS_SUCCESS,
} from '../../../../constraints';
import { retainOptionToString } from '../../../../formatters';

const { Col } = Grid;
const { Form, FormGroup } = FormModule;
const { Button: ButtonModule } = Utils;
const { Button, ButtonLoader } = ButtonModule;

type FormProps = {
  mode: string;
  publisherName: string;
  onSuccess: (label: string) => void;
  onError: (label: string, statusCode: string) => void;
};

const UpdateForm: React.FC<
  InjectedFormProps<DocumentData, FormProps> & FormProps
> = ({ initialValues, handleSubmit, mode, onSuccess, onError }) => {
  const { isSaving } = useSelector(({ document }: any) => ({
    isSaving: document.isSaving,
  }));

  const dispatch = useDispatch();

  const save = ({ values, meta }) => dispatch(requestUpdate(values, meta));

  const calculateRetainUntil = (selectedStoredBy) => {
    if (mode === PUBLICATION_MODES.CORRECTED) return undefined;
    else {
      const { value: optionValue } = selectedStoredBy;
      const value = RETAIN_OPTIONS_VALUES[optionValue];
      return new Date(new Date().setFullYear(new Date().getFullYear() + value))
        .toISOString()
        .replace('Z', '');
    }
  };

  const handleSave = async (values) => {
    const {
      file,
      documentBlockchainAddress,
      title,
      validSinceUntil,
      versionName,
      selectedCategory,
      selectedStoredBy,
    } = values;

    const { value: selectedCategoryObject } = selectedCategory;

    const documentInfo = {
      category: selectedCategoryObject,
      emailContentInput: '',
      previousDocumentBlockchainAddress: documentBlockchainAddress,
      publicationMode: mode,
      retainUntil: calculateRetainUntil(selectedStoredBy),
      smsContentInput: '',
      title: title,
      validSince: validSinceUntil[0],
      validUntil: validSinceUntil[1],
      versionName: versionName,
    };

    const body = {
      documentFile: file,
      documentInfo: documentInfo,
    };

    try {
      await new Promise((resolve, reject) =>
        save({
          values: body,
          meta: {
            resolve,
            reject,
          },
        }),
      );
      onSuccess(mapDOCUMENT_ACTIONS_SUCCESS.UPDATE);
    } catch (err: any) {
      const { statusCode } = err.response;
      onError(mapDOCUMENT_ACTIONS_ERROR.UPDATE, statusCode);
    }
  };

  return (
    <Form inline onSubmit={handleSubmit(handleSave)}>
      <DataTab initialValues={initialValues} mode={mode} />
      <FormGroup>
        <Col md={{ size: 8, offset: 2 }}>
          {isSaving ? (
            <ButtonLoader block size="lg" />
          ) : (
            <Button type="submit" size="lg" block>
              <FormattedMessage id="Save" defaultMessage="Save" />
            </Button>
          )}
        </Col>
      </FormGroup>
    </Form>
  );
};

const mapStateToProps = ({ document }, props) => {
  const { mode, publisherName } = props;

  const selectedDocument = document.singleRecord;

  return {
    initialValues: {
      ...selectedDocument,
      selectedCategory: {
        label: selectedDocument.category.name,
        value: selectedDocument.category,
      },
      publisherName: publisherName,
      validSinceUntil: [
        selectedDocument.validSince,
        selectedDocument.validUntil,
      ],
      selectedStoredBy: {
        label: (
          <FormattedMessage
            id={retainOptionToString(RETAIN_OPTIONS.YEARS_1)}
            defaultMessage={retainOptionToString(RETAIN_OPTIONS.YEARS_1)}
          />
        ),
        value: RETAIN_OPTIONS.YEARS_1,
      },
      versionName:
        mode === PUBLICATION_MODES.UPDATED
          ? undefined
          : selectedDocument.versionName,
    },
  };
};

const UpdateReduxForm = connect(mapStateToProps)(
  reduxForm<DocumentData, FormProps>({
    form: 'updateDocForm',
  })(UpdateForm),
);

export default UpdateReduxForm;
