import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Styled from './styled';

const CheckboxField = ({
  disabled,
  label,
  small,
  meta: { dirty, submitting },
  children,
}) => (
  <Styled.Checkbox
    className={classNames({
      disabled,
      small,
      'text-danger': submitting && dirty,
    })}
  >
    {children}
    <Styled.CheckboxLabel className="custom-control-label">
      {label}
    </Styled.CheckboxLabel>
  </Styled.Checkbox>
);

CheckboxField.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  small: PropTypes.bool,
  meta: PropTypes.object.isRequired,
};

CheckboxField.defaultProps = {
  small: false,
};

export default CheckboxField;
