import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isFunction } from 'lodash';

// Utils
import { SETTINGS_CONSTANTS } from '../../../../constraints';

// Actions
import {
  requestSingle,
  requestDownload as fileDownloadAction,
  requestResendAuthorizationCodes,
} from '../../actions';

import {
  RemoteSignDetailsData,
  SignatureStepsDiagram,
  SignatureHistory,
  RemoteSignResendAuthCodeModal,
  ActiveDeliveryStepsDiagram,
} from '../../components';

import { requestList } from '../../../Users/actions';

import { Layout as LayoutModule, Utils } from 'billon-ui';

const { Content: ContentModule } = LayoutModule;
const { Content } = ContentModule;

const { Loader: LoaderModule } = Utils;
const { PageLoader } = LoaderModule;

class DetailsPageRemoteSign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      resendAuthorizationCodesModalOpened: false,
      resendAuthorizationCodesSuccessModalOpened: false,
      // resendAuthorizationCodesErrorModalOpened: false,
      resendAuthorizationCodesCallback: undefined,
    };

    // this.onCopied = this.onCopied.bind(this);
  }

  componentWillMount() {
    this.props.requestSingleDocument(this.props.match.params.id);
    this.props.loadUsers();
  }

  // onCopied() {
  //   this.setState({
  //     copied: true,
  //   });

  //   setTimeout(() => this.setState({ copied: false }), 2000);
  // }

  handleResendAuthorizationCodes = (blockchainAddress) => {
    const { resendAuthorizationCodes } = this.props;
    this.setState({
      resendAuthorizationCodesModalOpened: true,
      resendAuthorizationCodesCallback: () => {
        resendAuthorizationCodes(blockchainAddress);
      },
    });
  };

  handleResendAuthorizationCodesFinished = () => {
    if (isFunction(this.state.resendAuthorizationCodesCallback)) {
      this.state.resendAuthorizationCodesCallback();
    }

    this.setState({
      resendAuthorizationCodesModalOpened: false,
      resendAuthorizationCodesSuccessModalOpened: true,
      resendAuthorizationCodesCallback: undefined,
    });
  };

  handleCancelResendAuthorizationCodes = () => {
    this.setState({
      resendAuthorizationCodesSuccessModalOpened: false,
      resendAuthorizationCodesModalOpened: false,
    });
  };

  render() {
    const {
      isFetching,
      didFetched,
      customer,
      isDownloading,
      fileDownload,
      isResendingAuthorizationCodes,
      users,
      document,
      settings,
      activeDelivery,
      transferedBy,
    } = this.props;

    if (isFetching || !didFetched) {
      return (
        <Content fluid>
          <PageLoader />
        </Content>
      );
    }
    let documentPreviewUrl = `${
      settings[SETTINGS_CONSTANTS.DOCUMENT_PREVIEW_URL]
    }/p`;

    return (
      <Content fluid>
        <RemoteSignDetailsData
          document={document}
          administrators={users}
          isDownloading={isDownloading}
          fileDownload={fileDownload}
          isResendingAuthorizationCodes={isResendingAuthorizationCodes}
          handleResendAuthorizationCodes={this.handleResendAuthorizationCodes}
          documentPreviewUrl={documentPreviewUrl}
          transferedBy={transferedBy}
          publisherName={settings[SETTINGS_CONSTANTS.PUBLICATOR_NAME]}
        />
        {activeDelivery && <ActiveDeliveryStepsDiagram document={document} />}
        <SignatureStepsDiagram document={document} />
        <SignatureHistory
          historyRecords={document.history}
          currentId={document.documentBlockchainAddress}
          customer={customer}
          isDownloading={isDownloading}
          fileDownload={fileDownload}
        />
        <RemoteSignResendAuthCodeModal
          isResendingAuthorizationCodes={isResendingAuthorizationCodes}
          resendAuthorizationCodesModalOpened={
            this.state.resendAuthorizationCodesModalOpened
          }
          resendAuthorizationCodesSuccessModalOpened={
            this.state.resendAuthorizationCodesSuccessModalOpened
          }
          handleResendAuthorizationCodesFinished={
            this.handleResendAuthorizationCodesFinished
          }
          handleCancelResendAuthorizationCodes={
            this.handleCancelResendAuthorizationCodes
          }
        />
      </Content>
    );
  }
}

DetailsPageRemoteSign.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  didFetched: PropTypes.bool.isRequired,
  customer: PropTypes.string.isRequired,
  document: PropTypes.object.isRequired,
  isDownloading: PropTypes.array.isRequired,
  fileDownload: PropTypes.func.isRequired,
  settings: PropTypes.object,

  isResendingAuthorizationCodes: PropTypes.bool.isRequired,
  resendAuthorizationCodes: PropTypes.func.isRequired,

  activeDelivery: PropTypes.bool,
};

const mapStateToProps = ({
  agreement,
  config,
  user,
  settings,
  authentication,
}) => ({
  document: agreement.singleRecord,
  isFetching: agreement.isFetching,
  didFetched: agreement.didFetched,
  customer: config.customer,
  isDownloading: agreement.isDownloading,
  users: user.records,
  settings: settings.settings,
  activeDelivery: config.activeDelivery,

  isResendingAuthorizationCodes: agreement.isResendingAuthorizationCodes,
  transferedBy: `${authentication.user.firstName} ${authentication.user.lastName}`,
});

const mapDispatchToProps = (dispatch) => ({
  requestSingleDocument: (id) => dispatch(requestSingle(id)),
  fileDownload: (id, name) => dispatch(fileDownloadAction(id, name)),
  loadUsers: () => dispatch(requestList()),
  resendAuthorizationCodes: (blockchainAddres) =>
    dispatch(requestResendAuthorizationCodes(blockchainAddres)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailsPageRemoteSign),
);
