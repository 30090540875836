import React from 'react';
import { useIntl } from 'react-intl';

import { CSVLink } from 'react-csv';

const DocumentErrorExporter = ({ label, jobId, title, children }) => {
  const intl = useIntl();

  const formattedLabel =
    label && label !== '' && intl.formatMessage({ id: label });

  const messageColumnName =
    label && label !== '' && intl.formatMessage({ id: 'message' });

  const fileName = `${title}-${formattedLabel}.csv`;

  const rows = [
    ['jobId', messageColumnName],
    [jobId, formattedLabel],
  ];

  return (
    <CSVLink data={rows} filename={fileName}>
      {children}
    </CSVLink>
  );
};

export default DocumentErrorExporter;
