import React from 'react';
import { useIntl } from 'react-intl';
import CustomToolTip from 'js/ui/CustomToolTip';
import { DocumentErrorExporter } from 'js/components/DocumentErrorExporter';
import { ReactComponent as ExportIcon } from '../../../../ui/Icons/exportError.svg';
import * as Styled from './styled';

const StatusTooltip = ({ id, label, jobId, title, delay }) => {
  const intl = useIntl();

  const formattedLabel =
    label && label !== '' && intl.formatMessage({ id: label });

  return (
    <CustomToolTip
      id={'StatusTooltip-' + id}
      trigger={
        jobId &&
        title && (
          <DocumentErrorExporter label={label} jobId={jobId} title={title}>
            <Styled.ExportIconWrapper>
              <ExportIcon />
            </Styled.ExportIconWrapper>
          </DocumentErrorExporter>
        )
      }
    >
      <Styled.ContentWrapper>{formattedLabel}</Styled.ContentWrapper>
    </CustomToolTip>
  );
};

export default StatusTooltip;
