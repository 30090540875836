import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, SubmissionError, change } from 'redux-form';

import { Grid } from 'billon-ui';

import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { requestList as requestRecipientsList } from '../../../Recipients/actions';
import { requestList as requestCategoriesList } from '../../../Categories/actions';
import { requestDownload, requestPrepare, requestPublish } from '../../actions';

import { retainOptionsList } from '../../../../constraints';
import { retainOptionToString } from '../../../../formatters';

import { PublishingDocument } from '../../components';
import { formValidator } from './validators';
import download from 'downloadjs';
import { CreateStep1 } from './CreateStep1';
import { CreateStep0 } from './CreateStep0';
import { CreateError } from './CreateError';

const { Row } = Grid;

const F = styled.form`
  h2 {
    font-size: 2rem;
    margin-bottom: 2.5rem;
    margin-top: -3.5rem;
    width: calc(100% - 35px);
  }
`;

export const DropzonePlaceholder = styled.div`
  width: 100%;
  border: 1px dashed ${(props) => props.theme.palette.grayLighter};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: ${(props) => (props.padding ? props.padding : '2rem')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizeSm};
  line-height: 2;
  text-align: center;

  &:focus {
    outline: 0;
  }
`;

export const StyledTitleContainer = styled.div`
  width: 100%;
  margin-bottom: 60px;
`;

export const EC = styled(Row)`
  margin-top: 4rem;
`;

const CreateFormRemoteSign = (props) => {
  const [publicationError, setPublicationError] = React.useState();
  const [publicationErrorDetails, setPublicationErrorDetails] =
    React.useState();
  const [jobIdInState, setJobId] = React.useState();

  const { loadRecipients } = props;

  React.useEffect(() => {
    loadRecipients();
  }, [loadRecipients]);

  const handlePublish = (values, jobId) => {
    const { publishDocument } = props;
    let jobIdToPublish = jobId;

    if (!jobId) {
      jobIdToPublish = jobIdInState;
    }

    return new Promise((resolve, reject) => {
      publishDocument(
        {
          ...values,
          jobId: jobIdToPublish,
        },
        {
          resolve,
          reject,
        },
      );
    })
      .then(() => {
        props.onFinished();
        setJobId('');
      })
      .catch((err) => {
        setJobId('');
        props.handleCreateError();
        props.setIsModalLarge(false);
        if (err.response.error) {
          setPublicationError(true);
        }
        if (err.response.errors) {
          setPublicationErrorDetails(err.response.errors);
        }

        return false;
      });
  };

  const handlePrepare = (values) => {
    const { prepareDocument } = props;
    const parentId = props.parentId || '';

    const jobId = values.jobId || '';

    if (values.orderOfSigning.value === 'RECEIVER-ONLY') {
      handlePublish(values, jobId);
      return;
    }

    return new Promise((resolve, reject) => {
      prepareDocument(
        {
          ...values,
          parentId,
        },
        {
          resolve,
          reject,
        },
      );
    })
      .then((res) => {
        setJobId(res.jobId);
        props.setStep(1);
        props.setFileToSign(res.file);

        props.resetFileValue();
      })
      .catch((err) => {
        props.handleCreateError();
        if (err.response && err.response.errors) {
          if (err.response.errors.additionalData) {
            const errors = {};

            err.response.errors.additionalData.forEach((errorData) => {
              errors[errorData.fieldName] = (
                <FormattedMessage
                  id={errorData.message}
                  defaultMessage={errorData.message}
                  values={errorData.values}
                />
              );
            });

            throw new SubmissionError(errors);
          } else if (err.response.errors.publicationStatus) {
            if (err.response.error) {
              setPublicationError(true);
            }
            if (err.response.errors) {
              setPublicationErrorDetails(err.response.errors);
            }
          }
        }

        return false;
      });
  };

  const downloadFileToSign = () => {
    if (props.fileToSign) {
      const blob = new Uint8Array(props.fileToSign.fileBuffer.data);
      download(blob, props.fileToSign.fileName);
    } else {
      props.fileDownload(
        props.detailsDataFromList.documentBlockchainAddress,
        props.detailsDataFromList.jobId,
      );
    }
  };

  const {
    handleSubmit,
    isSaving,
    categories,
    recipients,
    handleCreateRecipient,
    handleCreateCategory,
    loadCategories,
    intl,
  } = props;

  const signingOrderOptions = [
    {
      label: intl.formatMessage({
        id: 'Receiver only',
        defaultMessage: 'Receiver only',
      }),
      value: 'RECEIVER-ONLY',
    },
    {
      label: intl.formatMessage({
        id: 'Sender first',
        defaultMessage: 'Sender first',
      }),
      value: 'SENDER-FIRST',
    },
  ];

  const retainOptionsFormatted = retainOptionsList.map((option) => ({
    label: intl.formatMessage({
      id: retainOptionToString(option),
      defaultMessage: retainOptionToString(option),
    }),
    value: option,
  }));

  if (publicationError) {
    return (
      <CreateError
        publicationErrorDetails={publicationErrorDetails}
        setStep={props.setStep}
      />
    );
  }

  if (isSaving) {
    return <PublishingDocument />;
  }

  return (
    <F>
      {props.step === 0 && (
        <CreateStep0
          recipients={recipients}
          signingOrderOptions={signingOrderOptions}
          categories={categories}
          handleSubmit={handleSubmit}
          handlePrepare={handlePrepare}
          handleCreateRecipient={handleCreateRecipient}
          loadRecipients={loadRecipients}
          handleCreateCategory={handleCreateCategory}
          loadCategories={loadCategories}
          retainOptions={retainOptionsFormatted}
        />
      )}
      {props.step === 1 && (
        <CreateStep1
          downloadFileToSign={downloadFileToSign}
          handleSubmit={handleSubmit}
          handlePublish={handlePublish}
          detailsDataFromList={props.detailsDataFromList}
        />
      )}
    </F>
  );
};

const mapStateToProps = ({ recipient, category, document }) => ({
  recipients: recipient.records.reduce((arr, value) => {
    arr.push({
      value: value.id,
      label: `${value.firstName} ${value.lastName}, ${value.phoneNumber}`,
    });

    return arr;
  }, []),
  categories: category.records.map((v) => ({ label: v.path, value: v.path })),
  isSaving: document.isSaving,
});

const mapDispatchToProps = (dispatch) => ({
  prepareDocument: (values, meta) => dispatch(requestPrepare(values, meta)),
  publishDocument: (values, meta) => dispatch(requestPublish(values, meta)),
  loadRecipients: () => dispatch(requestRecipientsList({ isActive: true })),
  loadCategories: () => dispatch(requestCategoriesList({ isActive: true })),
  fileDownload: (address, jobId) => dispatch(requestDownload(address, jobId)),
  resetFileValue: () =>
    dispatch(change('createNewDocumentRemoteSign', 'file', null)),
});

export default withRouter(
  reduxForm({
    form: 'createNewDocumentRemoteSign',
    validate: formValidator,
  })(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(injectIntl(CreateFormRemoteSign)),
  ),
);
