import { Table as TableModule } from 'billon-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as formatters from '../../../../formatters';
import * as Styled from './styled';

import { StatusWithToolTipFormatter } from './statusFormatter';

import TableColumn from 'js/ui/TableColumn';

const { Table } = TableModule;

export const NotificationHistoryDetails = ({ errorsHistory }) => {
  return (
    <Styled.EmbededTableWrapper>
      <Styled.DetailsTitle>
        <FormattedMessage
          id="Attempts at automatic renewal"
          defaultMessage="Attempts at automatic renewal"
        />
        {':'}
      </Styled.DetailsTitle>

      <Table responsive tableData={errorsHistory} padding={0}>
        <TableColumn
          className="fixed-width-200"
          fieldName="#"
          formatter={(value, row) => errorsHistory.indexOf(row) + 1}
        >
          <FormattedMessage id="Attempt" defaultMessage="Attempt" />
        </TableColumn>

        <TableColumn
          className="fixed-width-200"
          fieldName="sendingStartDate"
          formatter={formatters.momentInTimezoneFormatter}
        >
          <FormattedMessage
            id="Date of delivery"
            defaultMessage="Date of delivery"
          />
        </TableColumn>
        <TableColumn
          fieldName="errorDate"
          formatter={formatters.momentInTimezoneFormatter}
        >
          <FormattedMessage
            id="End date of the action"
            defaultMessage="End date of the action"
          />
        </TableColumn>
        <TableColumn
          className="fixed-width-200"
          fieldName="reason"
          formatter={StatusWithToolTipFormatter}
        >
          <FormattedMessage id="Status" defaultMessage="Status" />
        </TableColumn>
        <TableColumn className="fixed-width-56" fieldName="empty-1" />
      </Table>
    </Styled.EmbededTableWrapper>
  );
};
