import React from 'react';
import { FormattedMessage } from 'react-intl';
import renderHTML from 'react-render-html';
import { Form as FormModule, Grid, Utils } from 'billon-ui';
import { ControlledTextAreaField } from '../../../../../../../../components/ControlledTextAreaField';
import { RenderedNotificationContainer } from '../../../../../RenderedNotificationContainer/RenderedNotificationContainer';
import * as Styled from './styled';
import Modal from '../../../../../../../../ui/Modal';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import { SubtitleToolTip } from '..';

const { FormGroup } = FormModule;
const { Text } = Utils;
const { Col, Row } = Grid;

const EmailNotificationModal = ({ isOpen, onClose, emailPreviewValue }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      onClose={onClose}
      closeIconEnabled={true}
    >
      <ModalHeader>
        <FormattedMessage
          id="Email notification message"
          defaultMessage="Email notification message"
        />
      </ModalHeader>

      <Styled.ModalBodyWrapper>
        <Row>
          <Col md={6}>
            <Text as="h5" fontWeight="600">
              <FormattedMessage
                id="Edit message"
                defaultMessage="Edit message"
              />{' '}
              <SubtitleToolTip />
            </Text>
            <FormGroup>
              <Text as="h6" fontSize="12px" fontWeight="600">
                <FormattedMessage
                  id="Enter message"
                  defaultMessage="Enter message"
                />
              </Text>
              <ControlledTextAreaField name="emailContentInput" />
            </FormGroup>
          </Col>
          <Col md={6}>
            <Text as="h5" fontWeight="600">
              <FormattedMessage id="Preview" defaultMessage="Preview" />
            </Text>
            <FormGroup>
              <Text as="h6" fontSize="12px" fontWeight="600">
                <FormattedMessage id="Preview" defaultMessage="Preview" />
              </Text>
              <RenderedNotificationContainer>
                {renderHTML(emailPreviewValue)}
              </RenderedNotificationContainer>
            </FormGroup>
          </Col>
        </Row>
      </Styled.ModalBodyWrapper>
      <ModalFooter onBack={onClose} onNext={onClose} nextAlignLeft={true} />
    </Modal>
  );
};

export default EmailNotificationModal;
