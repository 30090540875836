import {
  Form as FormModule,
  Layout as LayoutModule,
  Table as TableModule,
  Utils,
} from 'billon-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { merge } from 'lodash';
// Utils
import { CUSTOMER } from '../../../../constraints';
// Actions
import { useIdentityList } from '../../hooks/useIdentityList';
import { useUpdateIdentity } from '../../hooks/useUpdateIdentity';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../../../queryKeys';
import useFilters from '../../../../hooks/useFilters';

const { Table, TableColumn } = TableModule;
const {
  Loader: LoaderModule,
  Pagination,
  Button: ButtonModule,
  Card,
} = Utils;
const { Content: ContentModule, PageHeader } = LayoutModule;
const { Content } = ContentModule;
const { Button } = ButtonModule;
const { PageLoader } = LoaderModule;
const { SwitchField } = FormModule;

const StyledAfterWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;

  @media (max-width: 768px) {
    margin-top: 12px;
  }

  @media (min-width: 769px) {
    margin-left: 12px;
  }
`;

const StyledTableCard = styled(Card)`
  padding: 0 0 20px 0;
  box-shadow: none;
`;

const NewRecipientTable = ({ handleCreate, handleManage }) => {
  const defaultFilters = {
    pagination: {
      limit: 10,
      page: 1,
    }
  }

  const { filters, navigateWithSearchQuery } = useFilters();
  const fullFilters = merge(defaultFilters, filters);

  const { data, isLoading } = useIdentityList(fullFilters);
  const queryClient = useQueryClient();

  const { mutate: editIdentity } = useUpdateIdentity({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.IDENTITY_LIST, fullFilters])
    },
  });

  const { customer } = useSelector(({ config }) => ({
    customer: config.customer,
  }));

  const handlePageChange = (page) => {
    navigateWithSearchQuery({
      pagination: {
        page,
      }
    })
  }

  if (isLoading) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  const switchFormat = (value, row) => {
    return (
      <SwitchField
        input={{
          value,
          name: `isActive[${row.id}]`,
          checked: value,
          onClick: () => editIdentity({
            id: row.id,
            data: {
              isActive: !row.isActive,
            }
          }),
        }}
        slim
        type="checkbox"
        meta={{}}
      />
    );
  }

  const addText = customer === CUSTOMER.DIPLOMA ? 'Add student' : 'Add recipient';
  const manageText = customer === CUSTOMER.DIPLOMA ? 'Manage students' : 'Manage recipients';
  const titleText = customer === CUSTOMER.DIPLOMA ? 'Students' : 'Recipients';
  const externalIdText = customer === CUSTOMER.DIPLOMA ? 'Index number' : 'Pesel';

  const numberOfPages = Math.ceil(data.count / fullFilters.pagination.limit);

  return (
    <Content fluid>
      <FormattedMessage id="Search" defaultMessage="Search">
        {([placeholder]) => (
          <PageHeader
            handleSearchBar={() => {}}
            handleReset={() => {}}
            initialValues={{}}
            classic
            searchBarPlaceholder={placeholder}
            after={
              <StyledAfterWrapper>
                {/*<Button*/}
                {/*  onClick={handleManage}*/}
                {/*  fontWeight={500}*/}
                {/*  padding="0.5rem 3.5rem;"*/}
                {/*>*/}
                {/*  <FormattedMessage*/}
                {/*    id={manageText}*/}
                {/*    defaultMessage={manageText}*/}
                {/*  />*/}
                {/*</Button>*/}
                <Button
                  onClick={handleCreate}
                  fontWeight={500}
                  padding="0.5rem 3.5rem;"
                >
                  <FormattedMessage
                    id={addText}
                    defaultMessage={addText}
                  />
                </Button>
              </StyledAfterWrapper>
            }
          >
            <FormattedMessage id={titleText} defaultMessage={titleText} />
          </PageHeader>
        )}
      </FormattedMessage>

      <StyledTableCard>
        <Table
          tableData={data.rows}
          sortMethod={() => {}}
          responsive
        >
          <TableColumn fieldName="id">
            <FormattedMessage id="ID" defaultMessage="ID" />
          </TableColumn>
          <TableColumn fieldName="documentNumber">
            <FormattedMessage
              id={externalIdText}
              defaultMessage={externalIdText}
            />
          </TableColumn>
          <TableColumn fieldName="phoneNumber">
            <FormattedMessage
              id="Phone number"
              defaultMessage="Phone number"
            />
          </TableColumn>
          <TableColumn fieldName="firstName">
            <FormattedMessage id="First name" defaultMessage="First name" />
          </TableColumn>
          <TableColumn fieldName="lastName">
            <FormattedMessage id="Last name" defaultMessage="Last name" />
          </TableColumn>
          <TableColumn fieldName="email">
            <FormattedMessage id="Email" defaultMessage="Email" />
          </TableColumn>
          <TableColumn
            className="fixed-width"
            fieldName="isActive"
            formatter={switchFormat}
          >
            <FormattedMessage id="Active2" defaultMessage="Active" />
          </TableColumn>
        </Table>
        <Pagination
          onClick={handlePageChange}
          numberOfPages={numberOfPages}
          currentPage={fullFilters.pagination.page}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
        />
      </StyledTableCard>
      <br />
      <br />
    </Content>
  );
}

export default NewRecipientTable;
