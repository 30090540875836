import React from 'react';
import {
  LineHeaderRow,
  LoadingActionButton,
} from '../../../../../../components';
import { FormattedMessage } from 'react-intl';
import CustomToolTip from 'js/ui/CustomToolTip';
import { Utils, Grid } from 'billon-ui';
import { CUSTOMER, DOCUMENT_TYPE } from 'js/constraints';
import useFeatureEnabled from '../../../../../Config/hooks/useFeatureEnabled';
import { BtnCol } from '../../../../../../components/LoadingActionButton/styled';

const { Text, Icon } = Utils;
const { Row } = Grid;

const AdvancedFeatures = ({
  documentType,
  customer,
  handleNevVersionClick,
  handleErratumClick,
  handleIncidentClick,
  handleDownloadQRClick,
}) => {
  const { featureEnabled } = useFeatureEnabled();
  const documentTypeBasedSetting =
    documentType === DOCUMENT_TYPE.PUBLIC
      ? 'publicDocuments'
      : 'privateDocuments';

  return (
    <>
      <LineHeaderRow>
        <Text fontSize={'13px'} margin={0} fontWeight={300}>
          <FormattedMessage
            id="Advanced features"
            defaultMessage="Advanced features"
          />
        </Text>
      </LineHeaderRow>

      <Row>
        {featureEnabled(`${documentTypeBasedSetting}.enableNewVersion`, [
          CUSTOMER.TAURON,
          CUSTOMER.DEFAULT,
        ]) && (
          <BtnCol lg={4}>
            <CustomToolTip
              id="NewVersionTooltip"
              trigger={
                <LoadingActionButton onClick={handleNevVersionClick} outline>
                  <FormattedMessage
                    id="New version"
                    defaultMessage="New version"
                  />
                </LoadingActionButton>
              }
              delay={false}
              placement="bottom-start"
            >
              <h4>
                <FormattedMessage
                  id="New version"
                  defaultMessage="New version"
                />
              </h4>
              <FormattedMessage
                id="If there is a subsequent version of a document, a new version linked to the previous version may be published"
                defaultMessage="If there is a subsequent version of a document, a new version linked to the previous version may be published"
              />
              {'.'}
            </CustomToolTip>
          </BtnCol>
        )}

        {featureEnabled(`${documentTypeBasedSetting}.enableErratum`, [
          CUSTOMER.DEFAULT,
          CUSTOMER.TAURON,
          CUSTOMER.DIPLOMA,
        ]) && (
          <BtnCol lg={4}>
            <CustomToolTip
              id="ErratumTooltip"
              trigger={
                <LoadingActionButton outline onClick={handleErratumClick}>
                  <FormattedMessage id="Erratum" defaultMessage="Erratum" />
                </LoadingActionButton>
              }
              delay={false}
              placement="bottom-start"
            >
              <h4>
                <FormattedMessage id="Erratum" defaultMessage="Erratum" />
              </h4>
              <FormattedMessage
                id="If there are minor errors in the text of the document, which were noticed only after publication, it is possible to correct these errors by publishing Errata to the original document."
                defaultMessage="If there are minor errors in the text of the document, which were noticed only after publication, it is possible to correct these errors by publishing Errata to the original document."
              />
            </CustomToolTip>
          </BtnCol>
        )}
        {(featureEnabled(`${documentTypeBasedSetting}.enableIncident`, null) ||
          (customer === CUSTOMER.DIPLOMA &&
            documentType === DOCUMENT_TYPE.PRIVATE)) && (
          <BtnCol lg={4}>
            <CustomToolTip
              id="IncidentTooltip"
              trigger={
                <LoadingActionButton outline onClick={handleIncidentClick}>
                  <FormattedMessage id="Incident" defaultMessage="Incident" />
                </LoadingActionButton>
              }
              delay={false}
              placement="auto-end"
            >
              <h4>
                <FormattedMessage id="Incident" defaultMessage="Incident" />
              </h4>
              <FormattedMessage
                id="If you have mistakenly published a document to the wrong recipient revealing sensitive data, you can publish the correct document for that recipient."
                defaultMessage="If you have mistakenly published a document to the wrong recipient revealing sensitive data, you can publish the correct document for that recipient."
              />
            </CustomToolTip>
          </BtnCol>
        )}
      </Row>
      <Row>
        {featureEnabled(`${documentTypeBasedSetting}.enableDownloadQR`, null) &&
          customer !== CUSTOMER.DIPLOMA && (
            <BtnCol lg={4}>
              <LoadingActionButton
                lg={4}
                outline
                onClick={handleDownloadQRClick}
              >
                <Icon name="qrcode" />
                <FormattedMessage id="QR Code" defaultMessage="QR Code" />
              </LoadingActionButton>
            </BtnCol>
          )}
      </Row>
    </>
  );
};

export default AdvancedFeatures;
