import { Form as FormModule, Grid, Utils } from 'billon-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
// Utils
import { CUSTOMER } from '../../../../constraints';
// Actions
import {
  requestDownloadRecipientList,
  requestUploadRecipientFile,
} from '../../actions';
import validate from './validators/createValidation';

const { Form, FormGroup, AbstractForm, UploadField } = FormModule;

const { Button: ButtonModule, Text } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { Row, Col } = Grid;

const StyledFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 25px;
  border: 1px dashed #c9d4de;
  border-radius: 8px;
  min-height: 220px;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

class ManageForm extends AbstractForm {
  render() {
    const {
      handleSubmit,
      isDownloading,
      isUploading,
      isFileLoaded,
      handleCancel,
      fileName,
      downloadRecipientList,
      customer,
      onDownloadIdentities,
    } = this.props;

    return (
      <>
        <Row margin={0}>
          <Text
            as="p"
            fontSize="14px"
            fontWeight={600}
            margin="-1.5rem 0 1rem 0"
          >
            {
              {
                [CUSTOMER.DIPLOMA]: (
                  <FormattedMessage
                    id="To add or download a student list, select one of the options below"
                    defaultMessage="To add or download a student list, select one of the options below"
                  />
                ),
                [CUSTOMER.DEFAULT]: (
                  <FormattedMessage
                    id="To add or download a recipient list, select one of the options below"
                    defaultMessage="To add or download a recipient list, select one of the options below"
                  />
                ),
              }[customer]
            }
          </Text>
          <Col md={6} css="margin-top: 30px;">
            <Text as="h4" fontSize="18px" fontWeight={700}>
              <FormattedMessage id="Adding" defaultMessage="Adding" />
            </Text>
            <Form inline onSubmit={handleSubmit(this.handleSave)}>
              <StyledFieldWrapper>
                {!isFileLoaded ? (
                  <FormGroup>
                    <Field
                      component={UploadField}
                      accept={[
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.ms-excel',
                      ]}
                      name="file"
                      placeholder={() =>
                        !isFileLoaded && (
                          <StyledInputWrapper>
                            <Text css={{ 'text-align': 'center' }}>
                              {
                                {
                                  [CUSTOMER.DIPLOMA]: (
                                    <FormattedMessage
                                      id="Drag and drop here CSV file with student list max 2MB"
                                      defaultMessage="Drag and drop here CSV file with student list max 2MB"
                                    />
                                  ),
                                  [CUSTOMER.DEFAULT]: (
                                    <FormattedMessage
                                      id="Drag and drop here CSV file with recipient list max 2MB"
                                      defaultMessage="Drag and drop here CSV file with recipient list max 2MB"
                                    />
                                  ),
                                }[customer]
                              }
                              <br />
                              <FormattedMessage id="or" defaultMessage="or" />
                            </Text>

                            <Button outline type="button">
                              <FormattedMessage
                                id="Select from disk"
                                defaultMessage="Select from disk"
                              />
                            </Button>
                          </StyledInputWrapper>
                        )
                      }
                    />
                  </FormGroup>
                ) : (
                  <FormGroup>
                    {/* <FormGroup className="form-submit"> */}
                    <Text css={{ 'text-align': 'center' }}>
                      <b>
                        <FormattedMessage
                          id="File name"
                          defaultMessage="File name"
                        />{' '}
                      </b>
                      <br />
                      {fileName}
                    </Text>
                    <Col md={{ size: 8, offset: 2 }}>
                      {isUploading ? (
                        <ButtonLoader outline block />
                      ) : (
                        <Button type="submit" outline block>
                          <FormattedMessage id="Add" defaultMessage="Add" />
                        </Button>
                      )}
                    </Col>
                  </FormGroup>
                )}
              </StyledFieldWrapper>
            </Form>
          </Col>
          <Col md={6} css="margin-top: 30px;">
            <Text as="h4" fontSize="18px" fontWeight={700}>
              <FormattedMessage id="Downloading" defaultMessage="Downloading" />
            </Text>
            <StyledFieldWrapper>
              <FormGroup>
                <Text css="text-align: center">
                  {
                    {
                      [CUSTOMER.DIPLOMA]: (
                        <FormattedMessage
                          id="Download the list of students"
                          defaultMessage="Download the list of students"
                        />
                      ),
                      [CUSTOMER.DEFAULT]: (
                        <FormattedMessage
                          id="Download the list of recipients"
                          defaultMessage="Download the list of recipients"
                        />
                      ),
                    }[customer]
                  }
                </Text>
                <Col md={{ size: 8, offset: 2 }}>
                  {isDownloading ? (
                    <ButtonLoader outline block />
                  ) : (
                    <Button outline block onClick={onDownloadIdentities}>
                      <FormattedMessage
                        id="Download"
                        defaultMessage="Download"
                      />
                    </Button>
                  )}
                </Col>
              </FormGroup>
            </StyledFieldWrapper>
          </Col>
        </Row>
        <Row margin={0}>
          <Col md={{ size: 8, offset: 2 }}>
            <Button
              size="lg"
              block
              onClick={handleCancel}
              css="margin-top: 75px; margin-bottom: 15px;"
            >
              <FormattedMessage id="Close" defaultMessage="Close" />
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

ManageForm.propTypes = {
  handleSubmit: PropTypes.func,
  save: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  customer: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  onDownloadIdentities: PropTypes.func.isRequired,
};

const mapStateToProps = ({ recipient, config, form }) => ({
  isSaving: recipient.isSaving,
  isDownloading: recipient.isDownloading,
  customer: config.customer,
  isFileLoaded: form.manageRecipientForm.values.file ? true : false,
  fileName: form.manageRecipientForm.values.file
    ? form.manageRecipientForm.values.file[0].path
    : '',
});

const mapDispatchToProps = (dispatch) => ({
  save: (values, meta) => dispatch(requestUploadRecipientFile(values, meta)),
  downloadRecipientList: (meta) => dispatch(requestDownloadRecipientList(meta)),
});

export default withRouter(
  reduxForm({
    form: 'manageRecipientForm',
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(ManageForm)),
);
