import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import { mapDOCUMENT_ACTIONS_ERROR, RECIPIENT_ACTIONS } from 'js/constraints';
import { QueryKeys } from 'js/queryKeys';

// Hooks
import { usePublishDocument } from '../../../../hooks';

// Components
import { DocumentAtrributesForm } from '../DocumentAtrributesForm';
import { ErrorModal } from 'js/components/ErrorModal';
import { ExportableErrorContent } from 'js/components/ExportableErrorContent';

// Helpers
import { parsePublishRequestData } from '../../../../helpers';
import { DOCUMENT_TYPE } from '../../../../../../constraints';
import useFilters from '../../../../../../hooks/useFilters';
import { RecipientsFileForm } from '../RecipientsFileForm';
import { NotificationContentForm } from '../NotificationContentForm/NotificationContentForm';
import { DocumentPublicationSummary } from '../../../DocumentPublicationSummary';
import { RECIPIENT_VALIDATION_PURPOSE } from '../../../../../../constants';
import { FormattedMessage } from 'react-intl';
import { Modal as ModalModule } from 'billon-ui';

const { SuccessModal } = ModalModule;

export const OneStepPublication = ({
  onCreateIdentity,
  onCreateCategory,
  publicationMode,
}) => {
  const queryClient = useQueryClient();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const [documentTitle, setDocumentTitle] = useState(null);

  const { filters, navigateWithNewSearchQuery } = useFilters();
  const documentType = filters.documentTypeList || DOCUMENT_TYPE.PRIVATE;

  const handleAppendFormData = (moreData) => {
    setFormData({
      ...formData,
      ...moreData,
    });
  };

  const handleSubmitStep = (stepData) => {
    handleAppendFormData(stepData);

    if (documentType === DOCUMENT_TYPE.PRIVATE) {
      setStep(4);
    } else {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (documentType === DOCUMENT_TYPE.PRIVATE) {
      setStep(1);
    } else {
      setStep(step - 1);
    }
  };

  const {
    mutate: publishDocument,
    error,
    isLoading,
    isSuccess,
  } = usePublishDocument(documentType, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.DOCUMENT_LIST]);
    },
  });

  const handlePublishDocument = (values) => {
    setDocumentTitle(values.title);

    publishDocument({
      data: parsePublishRequestData(
        values,
        publicationMode,
        document?.documentBlockchainAddress,
      ),
    });
  };

  const handleCloseSuccessModal = () => {
    navigateWithNewSearchQuery({ filters }, '/documents');
  };

  if (error) {
    return (
      <ErrorModal
        isOpen
        toggle={handleCloseSuccessModal}
        onClose={handleCloseSuccessModal}
        closeIconEnabled={false}
      >
        <ExportableErrorContent
          headerTitleId={mapDOCUMENT_ACTIONS_ERROR.PUBLISH}
          statusCode={error.response?.data.statusCode}
          jobId={document.jobId}
          title={documentTitle}
        />
      </ErrorModal>
    );
  }

  return (
    <>
      {step === 1 && (
        <DocumentAtrributesForm
          step={step}
          document={formData}
          publicationMode={publicationMode}
          onSubmit={handleSubmitStep}
          onCreateIdentity={onCreateIdentity}
          onCreateCategory={onCreateCategory}
          submitButtonText="Next"
          isLoading={isLoading}
        />
      )}
      {step === 2 && (
        <RecipientsFileForm
          onSubmit={handleSubmitStep}
          onBack={handleBack}
          defaultValues={formData}
          validationPurpose={RECIPIENT_VALIDATION_PURPOSE.PUBLISH}
          mode={RECIPIENT_ACTIONS.INSERT_LIST}
        />
      )}
      {step === 3 && (
        <NotificationContentForm
          onSubmit={handleSubmitStep}
          onBack={handleBack}
          defaultValues={formData}
        />
      )}
      {step === 4 && (
        <DocumentPublicationSummary
          onSubmit={handlePublishDocument}
          onBack={handleBack}
          isLoading={isLoading}
          defaultValues={formData}
        />
      )}
      {isSuccess && (
        <SuccessModal isOpen onClose={handleCloseSuccessModal}>
          <FormattedMessage
            id="Document publication has been initiated."
            defaultMessage="Document publication has been initiated."
          />
        </SuccessModal>
      )}
    </>
  );
};
