import styled from 'styled-components';
import { Badge as BadgeRS } from 'reactstrap';

const Badge = styled(BadgeRS)`
  padding: 5px 14px 5px 14px;
  font-size: 11px;
  align-self: center;
  border-radius: ${(props) => props.theme.borderRadius};
  font-weight: bold;
  color: white;
  margin: ${(props) => props.margin};
  white-space: nowrap;
`;

export const SuccessBadge = styled(Badge)`
  background-color: ${(props) => props.theme.palette.billonGreen};
  opacity: 0.8;
`;

export const SecondaryBadge = styled(Badge)`
  background-color: ${(props) => props.theme.palette.secondaryGrey};
`;

export const SecondaryDarkerBadge = styled(Badge)`
  background-color: ${(props) => props.theme.palette.gray};
`;

export const FailureBadge = styled(Badge)`
  background-color: ${(props) => props.theme.palette.error};
`;
