import React from 'react';
import { useIntl } from 'react-intl';
import { Utils } from 'billon-ui';
import CustomToolTip from 'js/ui/CustomToolTip';
import * as Styled from './styled';

const { Icon } = Utils;

const StatusTooltip = ({ id, label, delay }) => {
  const intl = useIntl();

  const formattedLabel =
    label && label !== '' && intl.formatMessage({ id: label });

  return (
    <CustomToolTip
      id={'StatusTooltip-' + id}
      trigger={<Icon name="info-circle" color="grey" fontSize="18px" />}
      delay={delay}
    >
      <Styled.ToolTipContentWrapper>
        {formattedLabel}
      </Styled.ToolTipContentWrapper>
    </CustomToolTip>
  );
};

export default StatusTooltip;
