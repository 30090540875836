import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Styled
  from '../../modules/DocumentsNew/components/PublishDocumentModal/componnets/DocumentAtrributesForm/styled';
import { ControlledSelectField } from '../ControlledSelectField/ControlledSelectField';
import { Modal as ModalModule, Utils } from 'billon-ui';
import { useIdentitiesOptions } from '../../hooks/useIdentitiesOptions';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../queryKeys';
import { useCreateIdentity } from '../../modules/RecipientsNew/hooks/useCreateIdentity';
import { CreateForm as RecipientCreateFrom } from '../../modules/Recipients/containers';
import { useSelector } from 'react-redux';
import { CUSTOMER } from '../../constraints';

const { SuccessModal, CreateModal } = ModalModule;
const { Text } = Utils;

export const SelectIdentityField = ({
  createIdentityEnabled,
  disabled,
  name = 'identity'
}) => {
  const queryClient = useQueryClient();
  const { identities, isIdentitiesFetching, onChangeIdentityInput } =
    useIdentitiesOptions();

  const { customer } = useSelector(({ config }) => ({
    customer: config.customer,
  }));
  const isDiploma = customer === CUSTOMER.DIPLOMA;

  const [isCreateRecipientModalOpen, setCreateRecipientModalOpen] =
    React.useState(false);
  const [
    isSuccessCreateRecipientModalOpen,
    setSuccessCreateRecipientModalOpen,
  ] = React.useState(false);

  const { mutate: createIdentity, isLoading: isCreatingIdentity } =
    useCreateIdentity({
      onSuccess: () => {
        onCreateRecipientFinished();
        queryClient.invalidateQueries([QueryKeys.IDENTITY_LIST]);
      },
      onError: (error) => {
        console.log(error);
      },
    });


  const toggleCreateRecipientModal = () => {
    setCreateRecipientModalOpen(!isCreateRecipientModalOpen);
  };

  const onCreateRecipientFinished = () => {
    toggleCreateRecipientModal();
    toggleSuccessModalRecipientClose();
  };

  const toggleSuccessModalRecipientClose = () => {
    setSuccessCreateRecipientModalOpen(!isSuccessCreateRecipientModalOpen);
  };

  return (
    <>
      <Text as="h6" fontSize="12px" fontWeight="600">
        { isDiploma ? (
          <FormattedMessage id="Student" defaultMessage="Student" />
        ) : (
          <FormattedMessage id="Recipient" defaultMessage="Recipient" />
        )}
      </Text>
      <Styled.FormGroup>
        <ControlledSelectField
          name={name}
          onInputChange={onChangeIdentityInput}
          options={identities}
          disabled={disabled}
          isLoading={isIdentitiesFetching}
        />
        {createIdentityEnabled && (
          <Styled.IconButton icon="user-plus" onClick={toggleCreateRecipientModal} />
        )}
      </Styled.FormGroup>
      <CreateModal
        isOpen={isCreateRecipientModalOpen}
        onSuccess={() => {}}
        onCancel={toggleCreateRecipientModal}
        initialValues={{}}
        createMessage={
          <FormattedMessage
            id="Adding student"
            defaultMessage="Adding student"
          />
        }
      >
        <RecipientCreateFrom
          onSubmit={(data) => createIdentity({ data })}
          isSaving={isCreatingIdentity}
        />
      </CreateModal>
      <SuccessModal
        isOpen={isSuccessCreateRecipientModalOpen}
        onClose={toggleSuccessModalRecipientClose}
      >
        <FormattedMessage
          id="Successfully saved"
          defaultMessage="Successfully saved"
        />
      </SuccessModal>
    </>
  )
}
