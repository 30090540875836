import { QueryKeys } from '../../../queryKeys';
import { getRequest } from '../../../helpers/apiClient';
import apiRoutes from '../../../api-routes';
import { useQueryWithLogout } from '../../../hooks/useQueryWithLogout';

export const useNotificationTemplates = (params, options) => {
  return useQueryWithLogout([QueryKeys.NOTIFICATION_TEMPLATES], async () => {
      const { data } = await getRequest({
        url: apiRoutes.NOTIFICATION_TEMPLATES,
        params,
      });

      return data;
    },
    options,
  );
};
