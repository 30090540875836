import { Layout as LayoutModule, Table as TableModule, Utils } from 'billon-ui';

import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { CUSTOMER, FILTERS_MAP } from '../../constraints';
import * as formatters from '../../formatters';
// Actions
import CommonTableWrapper from './CommonTableWrapper';
import {
  StyledCloseFilterButton,
  StyledFiltersWrapper,
  TC,
} from './components';
import {
  Column,
  CommonTableSearchFilterParams,
  generateFilters,
  getSearchParams,
} from './helpers';

import { UserRoles } from 'js/userRoles';
import ProtectedComponent from '../../modules/Auth/components/ProtectedComponent/ProtectedComponent';

import useFilters from 'js/hooks/useFilters';

import { filterFormatters } from '../../formatters';

import TableColumn from 'js/ui/TableColumn';

const { Content: ContentModule, PageHeader } = LayoutModule;
const { Loader: LoaderModule, Icon, Button: ButtonModule } = Utils;
const { Button } = ButtonModule;
const { PageLoader } = LoaderModule;
const { Content } = ContentModule;

export const CommonTableContext = React.createContext<{
  isFetching: boolean;
  isFetchingQuietly: boolean;
  numberOfRecords: number;
  records: Array<unknown>;
  load?: (filters: unknown) => void;
  refresh?: (filters: unknown) => void;
}>({
  records: [],
  isFetching: false,
  isFetchingQuietly: false,
  numberOfRecords: 0,
  load: () => {},
  refresh: () => {},
});

const CommonTable: React.FC<{
  continous?: boolean;
  title?: string;
  toolTip?: any;
  addButtonLabel?: string;
  columns: Array<Column | false>;
  handleCreate?: (initialValues: unknown, isPreparedToSign?: boolean) => void;
  filterAddonRenderer?: () => JSX.Element;
  filterFormRenderer?: <T>(
    searchParams: T,
    closeFilters: () => void,
  ) => JSX.Element;
  detailsComponentRenderer?: (details) => JSX.Element;
  withSearchBar?: (values) => void;
  currentSearchBar?: string;
  searchBarPlaceholder?: string;
  customPageHeader?: boolean;
  limit: number;
}> = ({
  continous,
  addButtonLabel,
  children,
  columns,
  currentSearchBar,
  detailsComponentRenderer,
  filterAddonRenderer,
  filterFormRenderer,
  handleCreate,
  searchBarPlaceholder,
  title,
  withSearchBar,
  customPageHeader,
  limit,
}) => {
  const { isFetching, isFetchingQuietly, load, refresh } =
    useContext(CommonTableContext);

  const history = useHistory();
  const { customer } = useSelector(({ config }: any) => ({
    customer: config.customer as CUSTOMER,
  }));

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const { filters } = useFilters();

  const [searchParams, setSearchParams] = useState(
    getSearchParams(filters, limit),
  );

  useEffect(() => {
    load && setSearchParams(getSearchParams(filters, limit));
  }, [filters, limit]);

  useEffect(() => {
    load && load(searchParams);
  }, [load, searchParams]);

  const applyFilters = (
    filters: Partial<CommonTableSearchFilterParams<unknown>>,
  ) => {
    history.push(history.location.pathname + '?' + generateFilters(filters));
  };

  useEffect(() => {
    if (!refresh || isFetching || isFetchingQuietly) {
      return;
    }

    const refreshTimeout = setTimeout(() => {
      refresh(searchParams);
    }, 5000);

    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    };
  }, [searchParams, isFetching, isFetchingQuietly, refresh]);

  if (isFetching) {
    return (
      <Content fluid>
        <PageLoader />
      </Content>
    );
  }

  const initialValues = {
    customer,
  };

  function onClickCreate() {
    return handleCreate && handleCreate(initialValues);
  }

  const handleRemoveFilter = (filter) => {
    applyFilters({
      ...searchParams,
      filters: {
        ...searchParams.filters,
        [filter]: undefined,
      },
    });
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      {!customPageHeader && (
        <PageHeader
          classic
          withSearchBar={!!withSearchBar}
          handleSearchBar={withSearchBar}
          handleReset={
            withSearchBar ? () => withSearchBar(undefined) : () => {}
          }
          searchBarPlaceholder={searchBarPlaceholder}
          after={
            addButtonLabel && (
              <ProtectedComponent roles={[UserRoles.ADMIN]} render={false}>
                <Button padding="0.5rem 3.5rem;" onClick={onClickCreate}>
                  <FormattedMessage
                    id={addButtonLabel}
                    defaultMessage={addButtonLabel}
                  />
                </Button>
              </ProtectedComponent>
            )
          }
        >
          {title && <FormattedMessage id={title} defaultMessage={title} />}

          {filterFormRenderer && (
            <Button
              fontWeight="700"
              fontSize="12px"
              color="link"
              type="button"
              onClick={() => setIsFiltersOpen(!isFiltersOpen)}
            >
              <FormattedMessage id="Filters" defaultMessage="Filters" />
            </Button>
          )}
        </PageHeader>
      )}

      {filterFormRenderer &&
        isFiltersOpen &&
        filterFormRenderer(searchParams, () => setIsFiltersOpen(false))}

      {filterFormRenderer && searchParams && searchParams.filters && (
        <StyledFiltersWrapper>
          {Object.keys(searchParams.filters).map((key, index) => {
            const column = columns
              .filter((v): v is Column => !!v)
              .find((c) => c.name === key);

            const value = searchParams.filters[key];

            if (value) {
              const codeValue = column?.decodeRequired
                ? `${capitalizeFirstLetter(key)}: ${value}`
                : value;
              return (
                <StyledCloseFilterButton
                  outline
                  fontSize="12px"
                  fontWeight={600}
                  key={searchParams.filters[0]}
                  onClick={() => handleRemoveFilter(key)}
                >
                  <span>
                    {column ? (
                      <FormattedMessage
                        id={column.message}
                        defaultMessage={column.message}
                      />
                    ) : FILTERS_MAP[key] ? (
                      <FormattedMessage
                        id={FILTERS_MAP[key]}
                        defaultMessage={FILTERS_MAP[key]}
                      />
                    ) : (
                      key
                    )}

                    {': '}

                    {key === 'createdAt' ||
                    key === 'validSinceDateRange' ||
                    key === 'publicationDateRange' ? (
                      <>
                        {formatters.momentFormatterWithoutTime(value.from)}
                        {' - '}
                        {formatters.momentFormatterWithoutTime(value.to)}
                      </>
                    ) : column?.code ? (
                      <FormattedMessage
                        id={codeValue}
                        defaultMessage={codeValue}
                      />
                    ) : filterFormatters[key] ? (
                      Array.isArray(value) ? (
                        value.map((i) => (
                          <span>{filterFormatters[key](i)} </span>
                        ))
                      ) : (
                        <FormattedMessage
                          id={filterFormatters[key](value)}
                          defaultMessage={filterFormatters[key](value)}
                        />
                      )
                    ) : key === 'categoryId' ? (
                      columns
                        ?.filter((v): v is Column => !!v)
                        .find((c) => c.name === 'category')
                        ?.selectMap?.find((i) => i.value == value)?.label
                    ) : (
                      value
                    )}
                  </span>

                  <Icon name="times-circle" regular />
                </StyledCloseFilterButton>
              );
            }
          })}
        </StyledFiltersWrapper>
      )}

      {filterAddonRenderer && filterAddonRenderer()}

      <CommonTableWrapper
        sortMethod={(column, order) => {
          const columnCode = columns
            .filter((v): v is Column => !!v)
            .find((c) => c.name === column)?.code;
          applyFilters({
            ...searchParams,
            ...(column
              ? {
                  sort: {
                    sortBy: columnCode,
                    order: order ? 'ASCENDING' : 'DESCENDING',
                  },
                }
              : {}),
          });
        }}
        applyFilters={applyFilters}
        detailsComponent={detailsComponentRenderer}
        continous={continous}
        limit={limit}
      >
        {columns
          .filter((v): v is Column => !!v)
          .map((column, k) => (
            <TableColumn
              sortable={column.sortable}
              asc={
                searchParams.sort?.sortBy === column.code &&
                searchParams.sort?.order === 'ASCENDING'
              }
              fieldName={column.name}
              className={column?.className}
              formatter={column.formatter}
              renderCell={(row, props) => <TC row={row} {...props} />}
              key={column.name + '_column_' + k}
            >
              <FormattedMessage
                id={column.message}
                defaultMessage={column.message}
              />
            </TableColumn>
          ))}
        {children}
      </CommonTableWrapper>
    </>
  );
};

export default CommonTable;
