import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';

import { Modal as ModalModule } from 'billon-ui';

import { QueryKeys } from 'js/queryKeys';

// Hooks
import { useDeleteDocument } from '../../hooks';

// Components
import { ExportableErrorContent } from 'js/components/ExportableErrorContent';
import { ErrorModal } from 'js/components/ErrorModal';

import { mapDOCUMENT_ACTIONS_ERROR } from 'js/constraints';

const { SuccessModal, ConfirmModal } = ModalModule;

export const DeleteDocumentModal = ({ isOpen, toggle, document }) => {
  const queryClient = useQueryClient();

  const {
    mutate: deleteDocument,
    isLoading: isDeletingDocument,
    isSuccess,
    error,
    reset,
  } = useDeleteDocument({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.DOCUMENT_LIST]);
    },
  });

  const onAcceptDeleteDocument = () => deleteDocument({ id: document.jobId });

  const onCloseSuccessErrorModal = () => {
    toggle();
    reset();
  };

  if (!isOpen || !document) return null;

  if (!!error) {
    return (
      <ErrorModal
        isOpen
        toggle={onCloseSuccessErrorModal}
        onClose={onCloseSuccessErrorModal}
        closeIconEnabled={false}
      >
        <ExportableErrorContent
          headerTitleId={mapDOCUMENT_ACTIONS_ERROR.DELETE}
          statusCode={error.response.data.statusCode}
          jobId={document.jobId}
          title={document.documentTitle}
        />
      </ErrorModal>
    );
  }

  if (isSuccess) {
    return (
      <SuccessModal
        isOpen
        title={
          <FormattedMessage
            id="Document deleted"
            defaultMessage="Document deleted"
          />
        }
        onClose={onCloseSuccessErrorModal}
      >
        <FormattedMessage
          id="Document has been deleted successfully."
          defaultMessage="Document has been deleted successfully."
        />
      </SuccessModal>
    );
  }

  return (
    <ConfirmModal
      isLoading={isDeletingDocument}
      isOpen
      title={
        <FormattedMessage
          id="Document deletion confirmation"
          defaultMessage="Document deletion confirmation"
        />
      }
      onAccept={onAcceptDeleteDocument}
      onCancel={toggle}
    >
      <FormattedMessage
        id="Are You sure You want to delete this document?"
        defaultMessage="Are You sure You want to delete this document?"
      />
    </ConfirmModal>
  );
};
