import React from 'react';

import { PublishingErrorDetails } from '../../components';
import { FormattedMessage } from 'react-intl';
import { Layout, Grid, Utils } from 'billon-ui';
const { Row, Col } = Grid;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

const { Content: ContentModule } = Layout;

const { ErrorContent } = ContentModule;

const CreateFormError = (props) => {
  return (
    <>
      <ErrorContent>
        {(props.isOneStepPublication && props.step === 1) ||
        props.step === 2 ? (
          <FormattedMessage
            id="An error occurred while trying to publish document"
            defaultMessage="An error occurred while trying to publish document"
          />
        ) : (
          <FormattedMessage
            id="An error occurred while trying to prepare document"
            defaultMessage="An error occurred while trying to prepare document"
          />
        )}
        {props.publicationErrorDetails && (
          <PublishingErrorDetails
            status={props.publicationErrorDetails.publicationStatus}
          />
        )}
      </ErrorContent>
      <Row margin={'10px 0px 0px 0px'}>
        <Col md={{ size: 6, offset: 3 }}>
          <Button type="button" onClick={props.reset} size="lg" block>
            <FormattedMessage id="Try again" defaultMessage="Try again" />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default CreateFormError;
