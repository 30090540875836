import React, { useState } from 'react';
import {
  CUSTOMER,
  DOCUMENT_TYPE,
  RECIPIENT_ACTIONS,
} from '../../../../../../constraints';
import { LoadingActionButton } from '../../../../../../components';
import { FormattedMessage } from 'react-intl';
import { RecipientsFileForm } from '../../../PublishDocumentModal/componnets/RecipientsFileForm';
import { Modal as ModalModule } from 'billon-ui';
import { usePatchRecipientsFile } from '../../../../hooks/usePatchRecipientsFile';
import { useParams } from 'react-router-dom';
import UpdateRecipientListToolTip from '../UpdateRecipientListToolTip';

const { Modal, SuccessModal } = ModalModule;

export const UpdateRecipientListButton = ({ documentType, customer }) => {
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const { id } = useParams();

  const handleToggleUpdateModal = () => {
    setIsUpdateModalOpen(!isUpdateModalOpen);
  };

  const {
    mutate: patchRecipientsFile,
    isSuccess: isPatchSuccess,
    reset,
  } = usePatchRecipientsFile({
    onSuccess: () => {
      setIsUpdateModalOpen(false);
    },
  });

  const handleRecipientListSubmit = (data) => {
    patchRecipientsFile({ id, data });
  };

  const handleCloseSuccessModal = () => {
    reset();
  };

  if (documentType === DOCUMENT_TYPE.PUBLIC && customer !== CUSTOMER.DIPLOMA) {
    return null;
  }

  return (
    <>
      <LoadingActionButton onClick={handleToggleUpdateModal} outline>
        <FormattedMessage
          id="Update recipient list"
          defaultMessage="Update recipient list"
        />
      </LoadingActionButton>
      <Modal
        isOpen={isUpdateModalOpen}
        toggle={handleToggleUpdateModal}
        onClose={handleToggleUpdateModal}
      >
        <RecipientsFileForm
          onSubmit={handleRecipientListSubmit}
          mode={RECIPIENT_ACTIONS.UPDATE_LIST}
          customTitle={
            <>
              <FormattedMessage
                id="Updating the list of recipients"
                defaultMessage="Updating the list of recipients"
              />{' '}
              <UpdateRecipientListToolTip />
            </>
          }
        />
      </Modal>
      <SuccessModal
        isOpen={isPatchSuccess}
        title={
          <FormattedMessage
            id="Recipients file updated"
            defaultMessage="Recipients file updated"
          />
        }
        onClose={handleCloseSuccessModal}
      >
        <FormattedMessage
          id="Recipients file updated successfully"
          defaultMessage="Recipients file updated successfully"
        />
      </SuccessModal>
    </>
  );
};
