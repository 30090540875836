/* global window */
import React, { useState } from 'react';
import { Layout as LayoutModule, Modal as ModalModule } from 'billon-ui';
import UsersTable from '../../components/UsersTable';
import { FormattedMessage } from 'react-intl';
import { QueryKeys } from '../../../../queryKeys';
import useFilters from '../../../../hooks/useFilters';
import { useQueryClient } from 'react-query';
import CreateForm from '../../components/CreateForm';
import { USER_ACTIONS, CUSTOMER } from 'js/constraints';
import config from 'js/config';
import { SuccessModal as CustomSuccessModal } from 'js/components/SuccessModal';
import { useHistory } from 'react-router-dom';
import appRoutes from 'js/app-routes';

const { Content: ContentModule } = LayoutModule;
const { ContentContainer } = ContentModule;
const { CreateModal } = ModalModule;
const { enablePrivate, customer } = config;

const HomePage = () => {
  const history = useHistory();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const queryClient = useQueryClient();
  const { filters } = useFilters();

  const handleSuccess = (data) => {
    setCreateModalOpen(false);
    if (customer === CUSTOMER.TAURON && data?.id) {
      history.push(appRoutes.TEMPORATY_PASSWORD_SET.replace(':id', data.id));
    } else {
      setSuccessModalOpen(true);
    }
    queryClient.invalidateQueries([QueryKeys.USERS_LIST, filters]);
  };

  const handleFailure = () => {};

  const handleOpenCreateModal = () => {
    setCreateModalOpen(true);
    setInitialValues({});
  };

  const handleCloseCreateModal = () => {
    setCreateModalOpen(false);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  return (
    <ContentContainer fluid>
      <UsersTable handleCreate={handleOpenCreateModal} />
      <CreateModal
        isOpen={createModalOpen}
        onSuccess={handleSuccess}
        onCancel={handleCloseCreateModal}
        initialValues={{
          ...initialValues,
        }}
        editMessage={
          <FormattedMessage
            id="Editing administrator"
            defaultMessage="Editing administrator"
          />
        }
        createMessage={
          <FormattedMessage
            id="Adding administrator"
            defaultMessage="Adding administrator"
          />
        }
      >
        <CreateForm
          mode={USER_ACTIONS.CREATE}
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          enablePrivate={enablePrivate}
          customer={customer}
        />
      </CreateModal>
      <CustomSuccessModal
        isOpen={successModalOpen}
        toggle={handleCloseSuccessModal}
        onClose={handleCloseSuccessModal}
        closeIconEnabled={false}
      >
        <FormattedMessage
          id={'Administrator added successfully'}
          defaultMessage={'Administrator added successfully'}
        />
      </CustomSuccessModal>
    </ContentContainer>
  );
};

export default HomePage;
