import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Form as FormModule } from 'billon-ui';
const { FormControl } = FormModule;

const MySelect = styled(Select)`
  width: 100%;
  transition: ${(props) => props.theme.transitionBase};

  svg {
    width: auto;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    background-color: transparent;
  }

  > div {
    border-color: ${(props) => props.theme.palette.grayLighter} !important;
    box-shadow: none !important;
    border-radius: ${(props) => props.theme.borderRadius};
  }

  .billon-select__menu-list {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }

  .billon-select__control--is-disabled {
    cursor: ${(props) => props.theme.disabledCursor};
    background-color: ${(props) => props.theme.disabledBackground};
  }

  .billon-select__placeholder {
    color: ${(props) => props.theme.palette.grayLight} !important;
  }
`;

const colourStyles = {
  control: (styles) => ({
    ...styles,
    fontSize: '.875rem',
    padding: '0.2rem 0.1rem',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 500,
  }),
};

const SelectField = (
  {
    input,
    className,
    meta: { touched, active, dirty, error, warning, submitting },
    options,
    disabled,
    multi,
    searchable,
    clean,
    label,
    placeholder,
    floatLabel,
    isLoading,
    isClearable,
  },
  ...rest
) => {
  const select = (
    <MySelect
      value={input.value}
      onChange={(value) => input.onChange(value)}
      options={options}
      isDisabled={disabled || submitting}
      isMulti={multi}
      isSearchable={searchable}
      placeholder={placeholder}
      styles={colourStyles}
      className="billon-select"
      classNamePrefix="billon-select"
      onInputChange={(value) => input.onInputChange(value)}
      isLoading={isLoading}
      isClearable={isClearable}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );

  if (clean) {
    return select;
  }

  return (
    <FormControl
      active={active}
      touched={touched}
      dirty={dirty || !!input.value}
      error={error}
      warning={warning}
      className={className}
      label={label}
      floatLabel={floatLabel}
      {...rest}
    >
      {select}
    </FormControl>
  );
};

SelectField.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  searchable: PropTypes.bool,
  className: PropTypes.string,
  floatLabel: PropTypes.bool,
  clean: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.string,
};

SelectField.defaultProps = {
  multi: false,
  type: 'select',
  searchable: true,
  clean: false,
  floatLabel: false,
  placeholder: '',
};

export default SelectField;
