import { Form as FormModule, Grid, Utils } from 'billon-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
// Actions
import { requestSave } from '../../actions';
import validate from './validators/categoryValidator';

const { Form, FormGroup, AbstractForm, TextField, SelectField, SwitchField } =
  FormModule;

const { Col, Row } = Grid;
const { Button: ButtonModule, validators, Text } = Utils;
const { Button, ButtonLoader } = ButtonModule;

const { required } = validators;

const StyledSelectCategory = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid ${(props) => (props.isSelected ? props.color : '#c9d4de')};
  border-radius: 15px;
  cursor: ${(props) => (props.clickable ? 'pointer' : '')};
  padding: 16px;
  margin: 5px;
  text-align: center;
`;

class CreateForm extends AbstractForm {
  state = {
    opened: null,
  };

  render() {
    const {
      handleSubmit,
      isSaving,
      allParentCategoriesDetails,
      isCreating,
      currentCategory,
    } = this.props;

    const isCurrentCategoryActive = allParentCategoriesDetails.find(
      (v) => v.name === currentCategory,
    )?.isActive;

    return (
      <Form
        onSubmit={handleSubmit((values, ...args) => {
          if (isCurrentCategoryActive === false) {
            values.isActive = false;
          }
          this.handleSave(values, ...args);
        })}
        inline
      >
        <Text as="p" fontSize="14px" fontWeight={600} margin="-1.5rem 0 1rem 0">
          <FormattedMessage
            id="Here you can create a new category or add a subcategory to an existing category"
            defaultMessage="Here you can create a new category or add a subcategory to an existing category"
          />
        </Text>
        <Row css="min-height: 435px;">
          <Col md={6}>
            <Text
              as="h4"
              fontSize="18px"
              fontWeight={700}
              margin="0.5rem 0 0 0"
            >
              <FormattedMessage
                id="Add new category"
                defaultMessage="Add new category"
              />
            </Text>
            <StyledSelectCategory>
              <Text as="p" fontSize="12px" margin="0">
                <FormattedMessage id="Example" defaultMessage="Example" />
              </Text>
              <Text as="h5" fontSize="14px" margin="0.5rem 0 0.75rem 0">
                <b>
                  <i>
                    <FormattedMessage
                      id="Press documents"
                      defaultMessage="Press documents"
                    />
                  </i>
                </b>
              </Text>
              <Button
                type="button"
                outline
                fontSize="12px"
                fontWeight={600}
                onClick={() => this.setState({ opened: 1 })}
              >
                <FormattedMessage id="Choose" defaultMessage="Choose" />
              </Button>
            </StyledSelectCategory>
            {this.state.opened === 1 && (
              <>
                <FormGroup>
                  <Field
                    name="name"
                    component={TextField}
                    // label={<FormattedMessage id="Category name" defaultMessage="Category name" />}
                    label={
                      <FormattedMessage
                        id="Enter category name"
                        defaultMessage="Enter category name"
                      />
                    }
                    validate={[required]}
                    maxLength={150}
                  />
                </FormGroup>

                <FormGroup>
                  <Field
                    name="isActive"
                    component={SwitchField}
                    type="checkbox"
                    // label={<FormattedMessage id="Is active?" defaultMessage="Is active?" />}
                    label={
                      <FormattedMessage
                        id="Added category will be (off/on)"
                        defaultMessage="Added category will be (off/on)"
                      />
                    }
                  />
                </FormGroup>
              </>
            )}
          </Col>
          <Col md={6}>
            <Text
              as="h4"
              fontSize="18px"
              fontWeight={700}
              margin="0.5rem 0 0 0"
            >
              <FormattedMessage
                id="Add subcategory"
                defaultMessage="Add subcategory"
              />
            </Text>
            <StyledSelectCategory>
              <Text as="p" fontSize="12px" margin="0">
                <FormattedMessage id="Example" defaultMessage="Example" />
              </Text>
              <Text as="h5" fontSize="14px" margin="0.5rem 0 0.75rem 0">
                <i>
                  <FormattedMessage
                    id="Agreements"
                    defaultMessage="Agreements"
                  />
                  <b>
                    {' '}
                    /{' '}
                    <FormattedMessage
                      id="confirmed"
                      defaultMessage="confirmed"
                    />
                  </b>
                </i>
              </Text>
              <Button
                type="button"
                outline
                fontSize="12px"
                fontWeight={600}
                onClick={() => this.setState({ opened: 2 })}
              >
                <FormattedMessage id="Choose" defaultMessage="Choose" />
              </Button>
            </StyledSelectCategory>
            {this.state.opened === 2 && (
              <>
                <FormGroup>
                  <Field
                    name="parentId"
                    component={SelectField}
                    options={allParentCategoriesDetails.map((value) => ({
                      value: value.name,
                      label: value.name,
                    }))}
                    validate={[required]}
                    // label={<FormattedMessage id="Category parent" defaultMessage="Category parent" />}
                    label={
                      <FormattedMessage
                        id="Select a category"
                        defaultMessage="Select a category"
                      />
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Field
                    name="name"
                    component={TextField}
                    // label={<FormattedMessage id="Category name" defaultMessage="Category name" />}
                    label={
                      <FormattedMessage
                        id="Enter subcategory name"
                        defaultMessage="Enter subcategory name"
                      />
                    }
                    validate={[required]}
                    maxLength={150}
                  />
                </FormGroup>

                <FormGroup>
                  {isCurrentCategoryActive !== false ? (
                    <Field
                      name="isActive"
                      component={SwitchField}
                      type="checkbox"
                      label={
                        <FormattedMessage
                          id="Added subcategory will be (off/on)"
                          defaultMessage="Added subcategory will be (off/on)"
                        />
                      }
                      disabled={!isCurrentCategoryActive}
                    />
                  ) : (
                    <SwitchField
                      value={false}
                      input={{
                        checked: false,
                      }}
                      label={
                        <FormattedMessage
                          id="To change status, parent category needs to be active"
                          defaultMessage="To change status, parent category needs to be active"
                        />
                      }
                      slim
                      meta={{ dirty: true }}
                    />
                  )}
                </FormGroup>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <FormGroup>
            <Col md={{ size: 8, offset: 2 }}>
              {!isSaving && !isCreating && (
                <Button type="submit" size="lg" block>
                  <FormattedMessage id="Save" defaultMessage="Save" />
                </Button>
              )}
              {(isSaving || isCreating) && <ButtonLoader size="lg" block />}
            </Col>
          </FormGroup>
        </Row>
      </Form>
    );
  }
}

CreateForm.propTypes = {
  handleSubmit: PropTypes.func,
  save: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  allParentCategoriesDetails: PropTypes.array.isRequired,
};

const mapStateToProps = ({ form, category }) => ({
  isSaving: category.isSaving,
  allParentCategoriesDetails: category.allParentCategoriesDetails,
  currentCategory: form.saveCategoryForm.values.parentId?.value,
});

const mapDispatchToProps = (dispatch) => ({
  save: (values, meta) => dispatch(requestSave(values, meta)),
});

export default withRouter(
  reduxForm({
    form: 'saveCategoryForm',
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(CreateForm)),
);
