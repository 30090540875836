import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage } from 'react-intl';
import * as Styled from './styled';
import { Form as FormModule, Grid, Utils } from 'billon-ui';
// Hooks
import { useAuthenticatedContext } from 'js/modules/Auth/hooks/useAuthenticated';
// Schema
import { useSchema } from './useSchema';
// Helpers
import { getPublicationModeProperties } from '../../../../helpers';
import { PUBLICATION_FIELD_NAMES } from 'js/constraints';
import { ControlledTextField } from '../../../../../../components/ControlledTextField';
import { computeDefaultValues } from './helpers';
import { ControlledUploadField } from '../../../../../../components/ControlledUploadField/ControlledUploadField';
import { ControlledCalendarField } from '../../../../../../components/ControlledCalendarField/ControlledCalendarField';
import { SelectCategoryField } from '../../../../../../components/SelectCategoryField/SelectCategoryField';
import { SelectIdentityField } from '../../../../../../components/SelectIdentityField/SelectIdentityField';
import useFilters from '../../../../../../hooks/useFilters';
import { CUSTOMER, DOCUMENT_TYPE } from '../../../../../../constraints';
import { SelectRetentionField } from '../../../../../../components/SelectRetentionField/SelectRetentionField';
import { useConfigContext } from '../../../../../Config/hooks/useConfig';
import { useDownloadRecipientsList } from '../../../../hooks/useDownloadRecipientsList';
import { SummaryFileDownload } from '../../../SummaryFileDownload/SummaryFileDownload';

const { FormGroup } = FormModule;
const { Button: ButtonModule, Text } = Utils;
const { Button, ButtonLoader } = ButtonModule;
const { Row, Col } = Grid;

export const DocumentAtrributesForm = ({
  step,
  document,
  publicationMode,
  onSubmit,
  submitButtonText,
  onBack,
  isLoading,
}) => {
  const { filters } = useFilters();

  const isPublicDocument = [
    document?.documentType,
    filters?.documentTypeList,
  ].includes(DOCUMENT_TYPE.PUBLIC);

  const { customer } = useConfigContext();
  const isDiploma = customer === CUSTOMER.DIPLOMA;

  const schema = useSchema(isPublicDocument, isDiploma);
  const { user } = useAuthenticatedContext();

  const formMethods = useForm({
    defaultValues: computeDefaultValues(document, user),
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = (values) => {
    onSubmit(values);
  };

  const { handleSubmit } = formMethods;

  const { title, disabledFormFields } =
    getPublicationModeProperties(publicationMode);

  const isDisabledField = (keyName) => {
    return disabledFormFields.includes(keyName);
  };

  const { mutate: downloadRecipientsList } = useDownloadRecipientsList(
    document.jobId,
    document.title,
  );

  return (
    <FormProvider {...formMethods}>
      <Styled.Form onSubmit={handleSubmit(handleFormSubmit)}>
        {title && (
          <>
            <Text as="h5" fontWeight={800}>
              <FormattedMessage id={title} defaultMessage={title} />
            </Text>
            <br />
          </>
        )}
        <Row>
          <Col xl={4}>
            <Text as="h6" fontSize="12px" fontWeight="600">
              <FormattedMessage
                id="Published by"
                defaultMessage="Published by"
              />
            </Text>
            <ControlledTextField
              name={PUBLICATION_FIELD_NAMES.PUBLISHED_BY}
              disabled={isDisabledField(PUBLICATION_FIELD_NAMES.PUBLISHED_BY)}
            />
            <Text as="h6" fontSize="12px" fontWeight="600">
              {isDiploma ? (
                <FormattedMessage
                  id="Diploma number"
                  defaultMessage="Diploma number"
                />
              ) : (
                <FormattedMessage id="Title" defaultMessage="Title" />
              )}
            </Text>
            <ControlledTextField
              name={PUBLICATION_FIELD_NAMES.TITLE}
              disabled={isDisabledField(PUBLICATION_FIELD_NAMES.TITLE)}
            />
            <SelectCategoryField
              createCategoryEnabled={step === 1}
              disabled={step !== 1}
              name="category"
            />
            {isPublicDocument && (
              <>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Document version"
                    defaultMessage="Document version"
                  />
                </Text>
                <ControlledTextField
                  name="versionName"
                  disabled={isDisabledField('versionName')}
                />
              </>
            )}
          </Col>

          <Col xl={4}>
            <Text as="h6" fontSize="12px" fontWeight="600">
              {isDiploma ? (
                <FormattedMessage
                  id="Date of issue of the diploma"
                  defaultMessage="Date of issue of the diploma"
                />
              ) : (
                <FormattedMessage
                  id="Valid since"
                  defaultMessage="Valid since"
                />
              )}
            </Text>
            <FormGroup>
              <ControlledCalendarField
                name="validSince"
                disabled={isDisabledField('validSince')}
                maxDate={isDiploma ? new Date() : undefined}
              />
            </FormGroup>
            {!isDiploma && (
              <>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Valid until"
                    defaultMessage="Valid until"
                  />
                </Text>
                <FormGroup>
                  <ControlledCalendarField
                    name="validUntil"
                    disabled={isDisabledField('validUntil')}
                  />
                </FormGroup>
              </>
            )}
            {!isPublicDocument && (
              <SelectIdentityField
                createIdentityEnabled={step === 1}
                disabled={step !== 1}
              />
            )}
            {![CUSTOMER.TAURON, CUSTOMER.DIPLOMA].includes(customer) && (
              <SelectRetentionField />
            )}
          </Col>

          <Col xl={4}>
            <>
              <Text as="h6" fontSize="12px" fontWeight="600">
                <FormattedMessage id="Document" defaultMessage="Document" />
              </Text>
              <FormGroup>
                <ControlledUploadField
                  name="documentFile"
                  disabled={isDisabledField('documentFile')}
                />
              </FormGroup>
            </>
            {isPublicDocument && step !== 1 && (
              <>
                <Text as="h6" fontSize="12px" fontWeight="600">
                  <FormattedMessage
                    id="Recipients"
                    defaultMessage="Recipients"
                  />
                </Text>
                <FormGroup>
                  <SummaryFileDownload onClick={downloadRecipientsList} />
                </FormGroup>
              </>
            )}
          </Col>
        </Row>

        <Styled.CenterRow>
          <Col md={12} xl={3}>
            {onBack && (
              <Styled.BackButton secondary onClick={onBack}>
                <FormattedMessage id="back" defaultMessage="back" />
              </Styled.BackButton>
            )}
          </Col>
          <Col xl={6}>
            {!isLoading ? (
              <Button type="submit" size="lg" block>
                <FormattedMessage
                  id={submitButtonText}
                  defaultMessage={submitButtonText}
                />
              </Button>
            ) : (
              <ButtonLoader size="lg" block />
            )}
          </Col>
        </Styled.CenterRow>
      </Styled.Form>
    </FormProvider>
  );
};
