import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';

export const Tooltip = styled(UncontrolledTooltip)`
  & .tooltip-inner {
    background-color: #acbbc7 !important;
    color: white;
    padding: 0px;
    border-radius: 5px;
  }
`;

export const JustifiedContent = styled.p`
  text-align: justify;
  text-justify: inter-word;
  word-break: keep-all;
  padding: 0.5rem;
`;

export const TriggerWrapper = styled.span`
  width: 100%;
  height: 100%;
`;
