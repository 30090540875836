import styled from 'styled-components';
import { Utils, Grid } from 'billon-ui';

const { Button: ButtonModule } = Utils;

export const Row = styled(Grid.Row)`
  justify-content: flex-end;
`;

export const Col = styled(Grid.Col)`
  padding: 0.5rem;
`;

export const RedButton = styled(ButtonModule.Button)`
  color: ${({ theme }) => theme.palette.error} !important;
  border-color: ${({ theme }) => theme.palette.error} !important;
`;
