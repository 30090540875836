import { Utils } from 'billon-ui';

const { validators } = Utils;

export default (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Field required';
  }

  if (values.phoneNumber) {
    errors.phoneNumber = validators.phoneVerification(values.phoneNumber);
  }

  return errors;
};
