import { createAction } from 'redux-act';
import { Utils } from 'billon-ui';

const { helpers } = Utils;
const { encodeQueryString } = helpers;

// Apply Filters
export const applyFilters = (history, filters, page = 'categories') =>
  history.push(`/${page}/${encodeQueryString(filters)}`);

// Request category list
export const requestList = createAction(
  'Request category list',
  (filters = {}, ...rest) => ({ filters, ...rest }),
);
export const requestListQuiet = createAction(
  'Request category list quietly',
  (filters = {}, ...rest) => ({ filters, ...rest }),
);
export const requestListSuccess = createAction('Request category list success');
export const requestListFailure = createAction('Request category list failure');

// Save category
export const requestSave = createAction(
  'Save category',
  (values, meta, ...rest) => ({
    ...rest,
    values,
    meta,
  }),
);
export const receiveSaveSuccess = createAction('Save category success');
export const receiveSaveFailure = createAction('Save category failure');

// Delete category
export const requestDelete = createAction(
  'Delete category',
  (id, meta, ...rest) => ({
    ...rest,
    id,
    meta,
  }),
);
export const receiveDeleteSuccess = createAction('Delete category success');
export const receiveDeleteFailure = createAction('Delete category failure');

// Delete category
export const requestToggle = createAction(
  'Delete category',
  (value, meta, ...rest) => ({
    ...rest,
    value,
    meta,
  }),
);
export const receiveToggleSuccess = createAction('Delete category success');
export const receiveToggleFailure = createAction('Delete category failure');
