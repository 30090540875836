import { createReducer } from 'redux-act';
import * as actions from './actions';

export default createReducer(
  {
    // List
    [actions.requestList]: (state) => ({ ...state, isFetching: true }),
    [actions.requestListSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      records: payload.rows,
      numberOfRecords: payload.count,
    }),
    [actions.requestListFailure]: (state) => ({
      ...state,
      isFetching: false,
    }),
    // create
    [actions.requestSave]: (state) => ({
      ...state,
      isSaving: true,
    }),
    [actions.receiveSaveSuccess]: (state) => ({
      ...state,
      isSaving: false,
    }),
    [actions.receiveSaveFailure]: (state) => ({
      ...state,
      isSaving: false,
      createModalOpen: false,
    }),
    [actions.requestDelete]: (state) => ({
      ...state,
      isDeleting: true,
    }),
    [actions.receiveDeleteSuccess]: (state) => ({
      ...state,
      isDeleting: false,
    }),
    [actions.receiveDeleteFailure]: (state) => ({
      ...state,
      isDeleting: false,
    }),
    // load recipient csv
    [actions.requestUploadRecipientFile]: (state) => ({
      ...state,
      isUploading: true,
    }),
    [actions.receiveUploadRecipientFileSuccess]: (state) => ({
      ...state,
      isUploading: false,
    }),
    [actions.receiveUploadRecipientFileFailure]: (state) => ({
      ...state,
      isUploading: false,
      manageModalOpen: false,
    }),
    // Download list
    [actions.requestDownloadRecipientList]: (state) => ({
      ...state,
      isDownloading: true,
    }),
    [actions.receiveDownloadRecipientListSuccess]: (state) => ({
      ...state,
      isDownloading: false,
    }),
    [actions.receiveDownloadRecipientListFailure]: (state) => ({
      ...state,
      isDownloading: false,
    }),
  },
  {
    records: [],
    isSaving: false,
    isFetching: false,
    isDeleting: false,
    isUploading: false,
    isDownloading: false,
    numberOfRecords: 0,
  },
);
