import React from 'react';
import { Modal as ModalModule } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { Grid, Utils } from 'billon-ui';
import Modal from 'js/ui/Modal';
import * as Styled from './styled';

const { ModalFooter } = ModalModule;
const { Col } = Grid;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

const ErrorModal = (props) => {
  return (
    <Modal {...props} closeIconEnabled={false}>
      <Styled.Content>{props.children}</Styled.Content>
      <ModalFooter>
        <Col md={8}>
          <Button size="lg" block onClick={props.onClose}>
            <FormattedMessage id="Close2" defaultMessage="Close2" />
          </Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default ErrorModal;
