import * as yup from 'yup';
import { useIntl } from 'react-intl';

import {
  PUBLICATION_FIELD_NAMES,
  VALIDATION_ERRORS,
} from '../../../../../../constraints';

export const useSchema = (isPublicDocument = false, isDiploma = false) => {
  const { formatMessage } = useIntl();

  return yup.object({
    [PUBLICATION_FIELD_NAMES.PUBLISHED_BY]: yup
      .string()
      .required(VALIDATION_ERRORS.REQUIRED),

    [PUBLICATION_FIELD_NAMES.TITLE]: yup
      .string()
      .test(
        'nameMaxLength',
        formatMessage({
          id: VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED,
          defaultMessage: VALIDATION_ERRORS.MAXIMUM_LENGTH_EXCEEDED,
        }),
        (value) => (value ? value.length <= 128 : true),
      )
      .required(VALIDATION_ERRORS.REQUIRED),

    [PUBLICATION_FIELD_NAMES.CATEGORY]: yup.mixed().test(
      'category',
      formatMessage({
        id: VALIDATION_ERRORS.REQUIRED,
        defaultMessage: VALIDATION_ERRORS.REQUIRED,
      }),
      (value) => !!value,
    ),

    validSince: yup.mixed().test(
      'validDate',
      formatMessage({
        id: VALIDATION_ERRORS.REQUIRED,
        defaultMessage: VALIDATION_ERRORS.REQUIRED,
      }),
      (value) => !!value,
    ),

    validUntil: isDiploma
      ? yup.mixed().test(
          'validDate',
          formatMessage({
            id: VALIDATION_ERRORS.REQUIRED,
            defaultMessage: VALIDATION_ERRORS.REQUIRED,
          }),
          (value) => !!value,
        )
      : undefined,

    [PUBLICATION_FIELD_NAMES.IDENTITY]: yup.mixed().test(
      'identity',
      formatMessage({
        id: VALIDATION_ERRORS.REQUIRED,
        defaultMessage: VALIDATION_ERRORS.REQUIRED,
      }),
      (value) => isPublicDocument || !!value,
    ),

    documentFile: yup
      .mixed()
      .test(
        'file',
        formatMessage({
          id: VALIDATION_ERRORS.REQUIRED,
          defaultMessage: VALIDATION_ERRORS.REQUIRED,
        }),
        (value) => !!value && value.length > 0,
      )
      .test(
        'fileSize',
        formatMessage({
          id: VALIDATION_ERRORS.FILE_SIZE_TOO_LARGE,
          defaultMessage: VALIDATION_ERRORS.FILE_SIZE_TOO_LARGE,
        }),
        (value) => {
          const FILE_SIZE = 2 * 1024 * 1024;
          return value?.[0] ? value[0].size <= FILE_SIZE : true;
        },
      ),
  });
};
