import apiRoutes from 'js/api-routes';
import { postRequest } from 'js/helpers/apiClient';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';

const transformFromFileParams = (params) => {
  const { file } = params;
  const formData = new FormData();

  formData.append('documentFile', file[0]);

  return formData;
};

export const usePublishSignedDocument = (options) =>
  useMutationWithLogout(async ({ data, id }) => {
    const { data: signedDocumentData } = await postRequest({
      url: apiRoutes.SIGNED_DOCUMENT_PUBLISH.replace('{id}', id),
      data: transformFromFileParams(data),
      headers: {
        Authorization: sessionStorage.getItem('token'),
        'Content-Type': 'multipart/form-data',
      },
    });

    return signedDocumentData;
  }, options);
