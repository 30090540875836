import download from 'downloadjs';
import apiRoutes from '../../../../../api-routes';
import { useMutationWithLogout } from 'js/hooks/useMutationWithLogout';
import { getRequest } from 'js/helpers/apiClient';

export const useDownloadSingleDocument = (id, documentTitle) =>
  useMutationWithLogout(
    async () => {
      const { data } = await getRequest(
        apiRoutes.SINGLE_DOCUMENT_DOWNLOAD.replace('{id}', id),
        {
          headers: {
            Authorization: sessionStorage.getItem('token'),
          },
          responseType: 'arraybuffer',
        },
      );
      return data;
    },
    {
      onSuccess: (response) => {
        const blob = new Uint8Array(response);
        download(blob, documentTitle + '.pdf');
      },
    },
  );
