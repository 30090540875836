import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { Utils, Form } from 'billon-ui';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { CalendarField } from '../../../../containers';

// Actions
import { applyTariffFilters as applyFilters } from '../../actions';

const { TextField, FormGroup } = Form;
const { Button: ButtonModule } = Utils;
const { Button } = ButtonModule;

const FF = styled.div`
  h3 {
    margin-bottom: 3rem;
    height: 2.5rem;
  }
`;

const FG = styled(FormGroup)`
  justify-content: space-between;

  > div {
    width: 47.5%;
  }
`;

const ClearButton = styled(Button)`
  font-size: 0.7rem;
  position: absolute;
  right: 0;
  top: 4px;
  padding: 0;
`;

const H6 = styled.h6`
  position: relative;
`;

class FilterForm extends Component {
  constructor(props) {
    super(props);

    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.handleResetFilters = this.handleResetFilters.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
  }

  applyFilters(filters) {
    const { history } = this.props;

    applyFilters(history, filters);
  }

  handleFilterSubmit(values) {
    const { initialValues } = this.props;
    this.applyFilters({
      ...initialValues,
      ...values,
      page: undefined,
      limit: undefined,
    });
  }

  handleResetFilters() {
    const { reset } = this.props;

    reset();
    this.applyFilters({});
  }

  handleResetField(fieldName) {
    const { filterFormValues, resetField } = this.props;

    resetField(fieldName);
    this.applyFilters({
      ...filterFormValues,
      [fieldName]: undefined,
    });
  }

  render() {
    const { handleSubmit, filterFormValues } = this.props;

    return (
      <FF>
        <h3>
          <FormattedMessage id="Filters" defaultMessage="Filters" />
          <Button
            color="link"
            onClick={this.handleResetFilters}
            icon="times"
            type="button"
            iconRight
          >
            <FormattedMessage id="Clear" defaultMessage="Clear" />
          </Button>
        </h3>
        <form onSubmit={handleSubmit(this.handleFilterSubmit)}>
          <H6>
            <FormattedMessage
              id="Document title"
              defaultMessage="Document title"
            />
            {filterFormValues.title && (
              <ClearButton
                color="link"
                onClick={() => this.handleResetField('title')}
                icon="button_cancelfilter"
                type="button"
                iconRight
              />
            )}
          </H6>
          <Field component={TextField} name="title" />
          <H6>
            <FormattedMessage
              id="Publication date"
              defaultMessage="Publication date"
            />
            {filterFormValues.publicationDate && (
              <ClearButton
                color="link"
                onClick={() => this.handleResetField('publicationDate')}
                icon="button_cancelfilter"
                type="button"
                iconRight
              />
            )}
          </H6>
          <FG>
            <Field
              component={CalendarField}
              name="publicationDate[from]"
              enableTime={false}
              dateFormat="Y-m-d"
              label={<FormattedMessage id="From" defaultMessage="From" />}
              maxDate={
                filterFormValues.publicationDate &&
                filterFormValues.publicationDate.to
                  ? filterFormValues.publicationDate.to
                  : undefined
              }
            />
            <Field
              component={CalendarField}
              name="publicationDate[to]"
              enableTime={false}
              dateFormat="Y-m-d"
              parseDate={(date) =>
                moment(date).hour(23).minute(59).seconds(59).toDate()
              }
              label={<FormattedMessage id="To" defaultMessage="To" />}
              minDate={
                filterFormValues.publicationDate &&
                filterFormValues.publicationDate.from
                  ? filterFormValues.publicationDate.from
                  : undefined
              }
            />
          </FG>
          <H6>
            <FormattedMessage
              id="Is valid from"
              defaultMessage="Is valid from"
            />
            {filterFormValues.validSince && (
              <ClearButton
                color="link"
                onClick={() => this.handleResetField('validSince')}
                icon="button_cancelfilter"
                type="button"
                iconRight
              />
            )}
          </H6>
          <FG>
            <Field
              component={CalendarField}
              name="validSince[from]"
              enableTime={false}
              dateFormat="Y-m-d"
              parseDate={(date) => moment(date).format('YYYY-MM-DD')}
              label={<FormattedMessage id="From" defaultMessage="From" />}
              maxDate={
                filterFormValues.validSince && filterFormValues.validSince.to
                  ? filterFormValues.validSince.to
                  : undefined
              }
            />
            <Field
              component={CalendarField}
              name="validSince[to]"
              enableTime={false}
              dateFormat="Y-m-d"
              parseDate={(date) => moment(date).format('YYYY-MM-DD')}
              minDate={
                filterFormValues.validSince && filterFormValues.validSince.from
                  ? filterFormValues.validSince.from
                  : undefined
              }
              label={<FormattedMessage id="To" defaultMessage="To" />}
            />
          </FG>

          <Button className="button-static">
            <FormattedMessage id="Filter" defaultMessage="Filter" />
          </Button>
        </form>
      </FF>
    );
  }
}

FilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  history: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  filterFormValues: PropTypes.object,
  resetField: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const selector = formValueSelector('filterForm');

  return {
    filterFormValues: selector(state, 'title', 'publicationDate', 'validSince'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetField: (field) => dispatch(change('filterForm', field, '')),
});

export default withRouter(
  reduxForm({
    form: 'filterForm',
  })(connect(mapStateToProps, mapDispatchToProps)(FilterForm)),
);
